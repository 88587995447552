import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import * as actionCreators from '../redux/actions/actionCreators';

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    serverMaintenance: state.appState.serverMaintenance
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

function BoundPublicRoute({ isAuthenticated = false, serverMaintenance = false, redirect = null }) {
  if (serverMaintenance) {
    /* istanbul ignore next */
    return <Navigate to="/serverMaintenance" replace />;
  }
  if (redirect) {
    /* istanbul ignore next */
    return <Navigate to={redirect} replace />;
  }
  return isAuthenticated ? <Navigate to="/" replace /> : <Outlet />;
}

BoundPublicRoute.propTypes = {
  redirect: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  serverMaintenance: PropTypes.bool
};

const PublicRoute = connect(mapStateToProps, mapDispatchToProps)(BoundPublicRoute);

export default PublicRoute;
