import React from 'react';
import PropTypes from 'prop-types';
import { ignoreCase, isBool, isEmpty, isEqual } from '../../_helpers';
import { buildFormComponents } from '../../_formHelpers';
import { Spinner } from '../../Spinner';
import { FormContainer } from '../../FormContainer';
import FormSectionHeader from '../../FormSectionHeader';
import FormCustomSectionPercentages from '../../FormCustomSectionPercentages';
import { FormAssistant } from '../../FormAssistant';

class NeteviaTab extends React.Component {
  constructor(props) {
    super(props);
    const {
      relationship,
      appStatus,
      userType,
      otherTabData,
      validateFields,
      onlyUseFeeData,
      fields,
      isPublicRequest,
      data,
      disableFormFields
    } = props;
    this.tabKey = isPublicRequest ? 'processorFieldsTab' : 'neteviaTab';
    this.defaultBuildOptions = { disableFormFields };
    this.isStandardOrElevatedRisk =
      !isEmpty(relationship) &&
      ['elevated', 'standard'].includes(ignoreCase(relationship.riskProfile || ''));
    this.showPaymentMethodsAccepted =
      !onlyUseFeeData && !isEmpty(fields?.paymentMethodsAcceptedSection);
    this.paymentMethodsAcceptedSection = this.showPaymentMethodsAccepted
      ? buildFormComponents(
          data?.paymentMethodsAcceptedSection || {},
          fields?.paymentMethodsAcceptedSection,
          this.defaultBuildOptions
        )
      : {};
    this.showCardNotPresentSection =
      !onlyUseFeeData && !isEmpty(fields?.neteviaCardNotPresentSection);
    this.neteviaCardNotPresentSection = this.showCardNotPresentSection
      ? buildFormComponents(
          data?.neteviaCardNotPresentSection || {},
          fields?.neteviaCardNotPresentSection,
          this.defaultBuildOptions
        )
      : {};
    this.showBillingMethodsSection =
      !onlyUseFeeData && !isEmpty(fields?.neteviaCardNotPresentSection);
    this.neteviaBillingMethodsSection = this.showBillingMethodsSection
      ? data?.neteviaBillingMethodsSection || {}
      : {};
    this.showRatesAndFees = !isEmpty(fields?.ratesAndFeesSection);
    this.ratesAndFeesSection = this.showRatesAndFees
      ? buildFormComponents(
          data?.ratesAndFeesSection || {},
          fields?.ratesAndFeesSection,
          this.defaultBuildOptions
        )
      : {};
    this.showPinDebitFees = !isEmpty(fields?.pinDebitFeesSection);
    this.pinDebitFeesSection = this.showPinDebitFees
      ? buildFormComponents(
          data?.pinDebitFeesSection || {},
          fields?.pinDebitFeesSection,
          this.defaultBuildOptions
        )
      : {};
    this.showOtherFees = !isEmpty(fields?.otherFeesSection);
    this.otherFeesSection = this.showOtherFees
      ? buildFormComponents(
          data?.otherFeesSection || {},
          {
            ...fields?.otherFeesSection,
            ...(appStatus === 'Draft' &&
              userType === 'partner' &&
              !this.isStandardOrElevatedRisk && {
                nextDayFundingBatchFee: {
                  ...fields?.otherFeesSection?.nextDayFundingBatchFee,
                  initialValue: '0' // Only set for new apps
                }
              })
          },
          {
            ...this.defaultBuildOptions,
            customProps: {
              ...(validateFields &&
                otherTabData?.achInformationAndFundingChoicesSection?.fundingChoice ===
                  'next_day_funding' &&
                !this.isStandardOrElevatedRisk && {
                  nextDayFundingBatchFee: { required: true }
                })
            }
          }
        )
      : {};
    this.showGateway = !isEmpty(fields?.gatewaySection);
    this.gatewaySection = this.showGateway
      ? buildFormComponents(
          data?.gatewaySection || {},
          fields?.gatewaySection,
          this.defaultBuildOptions
        )
      : {};
    this.showWirelessProcessing = !isEmpty(fields?.wirelessProcessingSection);
    this.wirelessProcessingSection = this.showWirelessProcessing
      ? buildFormComponents(
          data?.wirelessProcessingSection || {},
          fields?.wirelessProcessingSection,
          this.defaultBuildOptions
        )
      : {};
    this.showTieredRates = !isEmpty(fields?.tieredRatesSection);
    this.tieredRatesSection = this.showTieredRates
      ? buildFormComponents(
          data?.tieredRatesSection || {},
          fields?.tieredRatesSection,
          this.defaultBuildOptions
        )
      : {};
    this.neteviaFeesSection = buildFormComponents(
      data?.neteviaFeesSection || {},
      fields?.neteviaFeesSection,
      this.defaultBuildOptions
    );
    this.mounted = false;
    this.state = {};
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  handleFormChange = (newFormState, id, options) => {
    const { valueChangedData, valuesForBackend } = options || {};
    const { [id]: currentSectionState = {}, ratesAndFeesSection } = this.state;
    const sectionChanged =
      isEmpty(currentSectionState) || // on load, or if a field changed
      (!isEmpty(valueChangedData) &&
        !isEqual(valueChangedData.frontendValue, currentSectionState?.[valueChangedData.id]));
    if (sectionChanged) {
      const isTieredRates =
        id === 'ratesAndFeesSection'
          ? valuesForBackend?.pricingType === 'tiered_rates'
          : ratesAndFeesSection?.valuesForBackend?.pricingType === 'tiered_rates';
      this.updateState(
        (prevState) => ({
          [id]: {
            ...prevState[id],
            ...newFormState,
            ...(valuesForBackend && { valuesForBackend })
          },
          sectionsValid: {
            ...prevState?.sectionsValid,
            [id]: isBool(newFormState?.fullSectionValid)
              ? newFormState.fullSectionValid
              : newFormState[id],
            ...(!isTieredRates && { tieredRatesSection: true })
          },
          sectionsInProgress: {
            ...prevState?.sectionsInProgress,
            ...(typeof prevState[id] !== 'undefined' && { [id]: newFormState.formInProgress })
          }
        }),
        () => this.handleCallback(id)
      );
    }
  };

  handleCallback = (sectionId) => {
    const { callback } = this.props;
    const { [sectionId]: currentSectionState, sectionsValid, sectionsInProgress } = this.state;
    if (!isEmpty(currentSectionState)) {
      const cbOptions = {
        sectionChangedData: { sectionId, sectionState: currentSectionState },
        tabKey: this.tabKey,
        tabData: { [sectionId]: currentSectionState },
        tabValid:
          !isEmpty(sectionsValid) && Object.values(sectionsValid).every((val) => val === true),
        tabInProgress:
          !isEmpty(sectionsInProgress) &&
          Object.values(sectionsInProgress).some((val) => val === true)
      };
      callback && callback(cbOptions);
    }
  };

  render() {
    const { fields, allowEmpty, wrapperStyle, validateFields, spinnerLoading } = this.props;
    const { ratesAndFeesSection } = this.state;
    const showTieredRates =
      this.showRatesAndFees && ratesAndFeesSection?.pricingType === 'tiered_rates';
    return (
      <FormContainer id={this.tabKey} type="formWithSections" wrapperStyle={wrapperStyle}>
        <Spinner loading={spinnerLoading} />
        {this.showPaymentMethodsAccepted && (
          <>
            <FormSectionHeader title="Payment Card Acceptance *You may select any card type in accordance with Card Networks Operating Regulations" />
            <FormAssistant
              componentLabelInside
              id={fields?.paymentMethodsAcceptedSection?.id}
              formComponents={this.paymentMethodsAcceptedSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showCardNotPresentSection && (
          <>
            <FormSectionHeader title="Card Not Present Information" />
            <FormAssistant
              componentLabelInside
              id={fields?.neteviaCardNotPresentSection?.id}
              formComponents={this.neteviaCardNotPresentSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showBillingMethodsSection && (
          <FormCustomSectionPercentages
            componentLabelInside
            id={fields?.neteviaBillingMethodsSection?.id}
            title="Billing Methods"
            fields={fields?.neteviaBillingMethodsSection}
            callback={this.handleFormChange}
            data={this.neteviaBillingMethodsSection}
            defaultBuildOptions={this.defaultBuildOptions}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
            wrapperStyle={{ marginTop: '2px' }}
          />
        )}
        {this.showRatesAndFees && (
          <>
            <FormSectionHeader title="Fees" />
            <FormAssistant
              componentLabelInside
              id={fields?.ratesAndFeesSection?.id}
              formComponents={this.ratesAndFeesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {showTieredRates && (
          <>
            <FormSectionHeader title="Tiered Rates" />
            <FormAssistant
              componentLabelInside
              id={fields?.tieredRatesSection?.id}
              formComponents={this.tieredRatesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showPinDebitFees && (
          <FormAssistant
            componentLabelInside
            id={fields?.pinDebitFeesSection?.id}
            formComponents={this.pinDebitFeesSection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showGateway && (
          <FormAssistant
            componentLabelInside
            id={fields?.gatewaySection?.id}
            formComponents={this.gatewaySection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showWirelessProcessing && (
          <FormAssistant
            componentLabelInside
            id={fields?.wirelessProcessingSection?.id}
            formComponents={this.wirelessProcessingSection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showOtherFees && (
          <>
            <FormSectionHeader title="Other Fees" />
            <FormAssistant
              componentLabelInside
              id={fields?.otherFeesSection?.id}
              formComponents={this.otherFeesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {!isEmpty(fields?.neteviaFeesSection) && (
          <>
            <FormSectionHeader title="Netevia Fees" />
            <FormAssistant
              componentLabelInside
              id={fields?.neteviaFeesSection?.id}
              formComponents={this.neteviaFeesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
      </FormContainer>
    );
  }
}

NeteviaTab.propTypes = {
  relationship: PropTypes.oneOfType([PropTypes.object]),
  otherTabData: PropTypes.oneOfType([PropTypes.object]),
  userType: PropTypes.string,
  appStatus: PropTypes.string,
  onlyUseFeeData: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]),
  fields: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func,
  disableFormFields: PropTypes.bool,
  validateFields: PropTypes.bool,
  spinnerLoading: PropTypes.bool,
  isPublicRequest: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.object])
};

NeteviaTab.defaultProps = {
  relationship: {},
  otherTabData: {},
  userType: '',
  appStatus: '',
  onlyUseFeeData: false, // MECO -> Merchant -> Fees, Partner -> Merchant Details -> Fees
  allowEmpty: false,
  data: {},
  fields: {},
  callback: () => {},
  disableFormFields: false,
  validateFields: false,
  spinnerLoading: false,
  isPublicRequest: false,
  wrapperStyle: {}
};

export default NeteviaTab;
