export const mccList = [
  {
    value: '0742',
    title: '0742 - Veterinary Services'
  },
  {
    value: '0763',
    title: '0763 - Agricultural Cooperatives'
  },
  {
    value: '0780',
    title: '0780 - Horticultural and Landscaping Services'
  },
  {
    value: '1520',
    title: '1520 - General Contractors-Residential and Commercial'
  },
  {
    value: '1711',
    title: '1711 - Air Conditioning, Heating and Plumbing Contractors'
  },
  {
    value: '1731',
    title: '1731 - Electrical Contractors'
  },
  {
    value: '1740',
    title: '1740 - Insulation, Masonry, Plastering, Stonework and Tile Setting Contractors'
  },
  {
    value: '1750',
    title: '1750 - Carpentry Contractors'
  },
  {
    value: '1761',
    title: '1761 - Roofing and Siding, Sheet Metal Work Contractors'
  },
  {
    value: '1771',
    title: '1771 - Concrete Work Contractors'
  },
  {
    value: '1799',
    title: '1799 - Contractors, Special Trade Contractors-not elsewhere classified'
  },
  {
    value: '2741',
    title: '2741 - Miscellaneous Publishing and Printing'
  },
  {
    value: '2791',
    title: '2791 - Typesetting, Plate Making and Related Services'
  },
  {
    value: '2842',
    title: '2842 - Sanitation, Polishing and Specialty Cleaning Preparations'
  },
  {
    value: '3000',
    title: '3000 - United Airlines'
  },
  {
    value: '3001',
    title: '3001 - American Airlines'
  },
  {
    value: '3002',
    title: '3002 - Pan American'
  },
  {
    value: '3003',
    title: '3003 - Eurofly'
  },
  {
    value: '3004',
    title: '3004 - Dragon Airlines'
  },
  {
    value: '3005',
    title: '3005 - British Airways'
  },
  {
    value: '3006',
    title: '3006 - Japan Air Lines'
  },
  {
    value: '3007',
    title: '3007 - Air France'
  },
  {
    value: '3008',
    title: '3008 - Lufthansa German Airlines'
  },
  {
    value: '3009',
    title: '3009 - Air Canada'
  },
  {
    value: '3010',
    title: '3010 - Royal Dutch Airlines (KLM Airlines)'
  },
  {
    value: '3011',
    title: '3011 - Aeroflot'
  },
  {
    value: '3012',
    title: '3012 - Qantas'
  },
  {
    value: '3013',
    title: '3013 - Alitalia'
  },
  {
    value: '3014',
    title: '3014 - Saudi Arabian Airlines'
  },
  {
    value: '3015',
    title: '3015 - Swiss International Air Lines'
  },
  {
    value: '3016',
    title: '3016 - Scandinavian Airline System (SAS)'
  },
  {
    value: '3017',
    title: '3017 - South African Airways'
  },
  {
    value: '3018',
    title: '3018 - Varig'
  },
  {
    value: '3019',
    title: '3019 - Germanwings'
  },
  {
    value: '3020',
    title: '3020 - Air India'
  },
  {
    value: '3021',
    title: '3021 - Air Algerie'
  },
  {
    value: '3022',
    title: '3022 - Philippine Airlines'
  },
  {
    value: '3023',
    title: '3023 - Mexicana'
  },
  {
    value: '3024',
    title: '3024 - Pakistan International'
  },
  {
    value: '3025',
    title: '3025 - Air New Zealand Ltd. International'
  },
  {
    value: '3026',
    title: '3026 - Emirates Airlines'
  },
  {
    value: '3027',
    title: '3027 - Union De Transports Aeriens (UTA/interair)'
  },
  {
    value: '3028',
    title: '3028 - Air Malta'
  },
  {
    value: '3029',
    title: '3029 - SN Brussels Airlines'
  },
  {
    value: '3030',
    title: '3030 - Aerolineas Argentinas'
  },
  {
    value: '3031',
    title: '3031 - Olympic Airways'
  },
  {
    value: '3032',
    title: '3032 - El Al'
  },
  {
    value: '3033',
    title: '3033 - Ansett Airlines'
  },
  {
    value: '3034',
    title: '3034 - Etihadair'
  },
  {
    value: '3035',
    title: '3035 - TAP Air Portugal (TAP)'
  },
  {
    value: '3036',
    title: '3036 - Viacao Aerea Sao Pãulo (VASP)'
  },
  {
    value: '3037',
    title: '3037 - Egyptair'
  },
  {
    value: '3038',
    title: '3038 - Kuwait Airways'
  },
  {
    value: '3039',
    title: '3039 - Avianca'
  },
  {
    value: '3040',
    title: '3040 - Gulf Air (Bahrain)'
  },
  {
    value: '3041',
    title: '3041 - Balkan-Bulgarian Airlines'
  },
  {
    value: '3042',
    title: '3042 - Finnair'
  },
  {
    value: '3043',
    title: '3043 - Aer Lingus'
  },
  {
    value: '3044',
    title: '3044 - Air Lanka'
  },
  {
    value: '3045',
    title: '3045 - Nigeria Airways'
  },
  {
    value: '3046',
    title: '3046 - Cruzeiro Do Sul'
  },
  {
    value: '3047',
    title: '3047 - Turk Hava Yollari'
  },
  {
    value: '3048',
    title: '3048 - Royal Air Maroc'
  },
  {
    value: '3049',
    title: '3049 - Tunis Air'
  },
  {
    value: '3050',
    title: '3050 - Icelandair'
  },
  {
    value: '3051',
    title: '3051 - Austrian Airlines'
  },
  {
    value: '3052',
    title: '3052 - LAN Airlines'
  },
  {
    value: '3053',
    title: '3053 - Aviacion Y Comercio (AVIACO)'
  },
  {
    value: '3054',
    title: '3054 - Linea Aerea Del Cobre (LADECO)'
  },
  {
    value: '3055',
    title: '3055 - Lloyd Aero Boliviano (LAB)'
  },
  {
    value: '3056',
    title: '3056 - Jet Airways'
  },
  {
    value: '3057',
    title: '3057 - Virgin America'
  },
  {
    value: '3058',
    title: '3058 - Delta'
  },
  {
    value: '3059',
    title: '3059 - DBA Airline'
  },
  {
    value: '3060',
    title: '3060 - Northwest Airlines'
  },
  {
    value: '3061',
    title: '3061 - Continental'
  },
  {
    value: '3062',
    title: '3062 - Hapag-Lloyd Express'
  },
  {
    value: '3063',
    title: '3063 - US Airways'
  },
  {
    value: '3064',
    title: '3064 - Adria Airways'
  },
  {
    value: '3065',
    title: '3065 - Airinter'
  },
  {
    value: '3066',
    title: '3066 - Southwest Airlines'
  },
  {
    value: '3067',
    title: '3067 - Vanguard Airlines'
  },
  {
    value: '3068',
    title: '3068 - Air Astana'
  },
  {
    value: '3069',
    title: '3069 - Sun Country Airlines'
  },
  {
    value: '3071',
    title: '3071 - Air British Columbia'
  },
  {
    value: '3072',
    title: '3072 - Cebu Pacific Airlines'
  },
  {
    value: '3075',
    title: '3075 - Singapore Airlines'
  },
  {
    value: '3076',
    title: '3076 - Aeromexico'
  },
  {
    value: '3077',
    title: '3077 - Thai Airways'
  },
  {
    value: '3078',
    title: '3078 - China Airlines'
  },
  {
    value: '3079',
    title: '3079 - Jetstar Airways'
  },
  {
    value: '3081',
    title: '3081 - NordAir'
  },
  {
    value: '3082',
    title: '3082 - Korean Airlines'
  },
  {
    value: '3083',
    title: '3083 - Air Afrique'
  },
  {
    value: '3084',
    title: '3084 - Eva Airways'
  },
  {
    value: '3085',
    title: '3085 - Midwest Express Airlines'
  },
  {
    value: '3086',
    title: '3086 - Carnival Airlines'
  },
  {
    value: '3087',
    title: '3087 - Metro Airlines'
  },
  {
    value: '3088',
    title: '3088 - Croatia Air'
  },
  {
    value: '3089',
    title: '3089 - Transaero'
  },
  {
    value: '3090',
    title: '3090 - Uni Airways Corporation'
  },
  {
    value: '3094',
    title: '3094 - Zambia Airways'
  },
  {
    value: '3096',
    title: '3096 - Air Zimbabwe Corp'
  },
  {
    value: '3097',
    title: '3097 - Spanair'
  },
  {
    value: '3098',
    title: '3098 - Asiana Airlines'
  },
  {
    value: '3099',
    title: '3099 - Cathay Pacific'
  },
  {
    value: '3100',
    title: '3100 - Malaysian Airline System'
  },
  {
    value: '3102',
    title: '3102 - Iberia'
  },
  {
    value: '3103',
    title: '3103 - Garuda'
  },
  {
    value: '3106',
    title: '3106 - Braathens S.A.F.E.'
  },
  {
    value: '3110',
    title: '3110 - Wings Airways'
  },
  {
    value: '3111',
    title: '3111 - British Midland'
  },
  {
    value: '3112',
    title: '3112 - Windward Island'
  },
  {
    value: '3115',
    title: '3115 - Tower Air'
  },
  {
    value: '3117',
    title: '3117 - Venezolana International De Aviacion'
  },
  {
    value: '3118',
    title: '3118 - Valley Airlines'
  },
  {
    value: '3125',
    title: '3125 - Tan Sahsa'
  },
  {
    value: '3126',
    title: '3126 - Talair PTY Ltd.'
  },
  {
    value: '3127',
    title: '3127 - Taca International'
  },
  {
    value: '3129',
    title: '3129 - Surinam Airways'
  },
  {
    value: '3130',
    title: '3130 - Sunworld International'
  },
  {
    value: '3131',
    title: '3131 - VLM Airlines'
  },
  {
    value: '3132',
    title: '3132 - Frontier Airlines'
  },
  {
    value: '3133',
    title: '3133 - Sunbelt Airlines'
  },
  {
    value: '3135',
    title: '3135 - Sudan Airways'
  },
  {
    value: '3136',
    title: '3136 - Qatar Airways'
  },
  {
    value: '3137',
    title: '3137 - Singleton'
  },
  {
    value: '3138',
    title: '3138 - Simmons Airlines'
  },
  {
    value: '3143',
    title: '3143 - Scenic Airlines'
  },
  {
    value: '3144',
    title: '3144 - Virgin Atlantic'
  },
  {
    value: '3145',
    title: '3145 - San Juan'
  },
  {
    value: '3146',
    title: '3146 - Luxair'
  },
  {
    value: '3148',
    title: '3148 - Air Littoral S.A.'
  },
  {
    value: '3151',
    title: '3151 - Air Zaire'
  },
  {
    value: '3154',
    title: '3154 - Princeville'
  },
  {
    value: '3156',
    title: '3156 - Go Fly Ltd.'
  },
  {
    value: '3159',
    title: '3159 - Provincetown-Boston Airways'
  },
  {
    value: '3161',
    title: '3161 - All Nipon Airways'
  },
  {
    value: '3164',
    title: '3164 - Norontair'
  },
  {
    value: '3165',
    title: '3165 - New York Helicopter'
  },
  {
    value: '3167',
    title: '3167 - Aero Continente'
  },
  {
    value: '3170',
    title: '3170 - Mount Cook'
  },
  {
    value: '3171',
    title: '3171 - Canadian Airlines International'
  },
  {
    value: '3172',
    title: '3172 - Nationair'
  },
  {
    value: '3174',
    title: '3174 - Jetblue Airways'
  },
  {
    value: '3175',
    title: '3175 - Middle East Air'
  },
  {
    value: '3176',
    title: '3176 - Metroflight Airlines'
  },
  {
    value: '3177',
    title: '3177 - AirTran Airways'
  },
  {
    value: '3178',
    title: '3178 - Mesa Air'
  },
  {
    value: '3180',
    title: '3180 - Westjet Airlines'
  },
  {
    value: '3181',
    title: '3181 - Malev'
  },
  {
    value: '3182',
    title: '3182 - LOT (Polish Airlines)'
  },
  {
    value: '3183',
    title: '3183 - Oman Air'
  },
  {
    value: '3184',
    title: '3184 - LIAT'
  },
  {
    value: '3185',
    title: '3185 - Linea Aeropostal Venezolana (LAV)'
  },
  {
    value: '3186',
    title: '3186 - Lineas Aereas Paraguayas (LAP)'
  },
  {
    value: '3187',
    title: '3187 - LACSA (Costa Rica)'
  },
  {
    value: '3188',
    title: '3188 - Virgin Express'
  },
  {
    value: '3190',
    title: '3190 - Jugoslav Air'
  },
  {
    value: '3191',
    title: '3191 - Island Airlines'
  },
  {
    value: '3193',
    title: '3193 - Indian Airlines'
  },
  {
    value: '3196',
    title: '3196 - Hawaiian Air'
  },
  {
    value: '3197',
    title: '3197 - Havasu Airlines'
  },
  {
    value: '3200',
    title: '3200 - Guyana Airways'
  },
  {
    value: '3203',
    title: '3203 - Golden Pacific Air'
  },
  {
    value: '3204',
    title: '3204 - Freedom Airlines'
  },
  {
    value: '3206',
    title: '3206 - China Eastern Airlines'
  },
  {
    value: '3211',
    title: '3211 - Norwegian Air Shuttle'
  },
  {
    value: '3212',
    title: '3212 - Dominicana De Aviacion'
  },
  {
    value: '3213',
    title: '3213 - Malmo Aviation'
  },
  {
    value: '3215',
    title: '3215 - Dan Air Services'
  },
  {
    value: '3216',
    title: '3216 - Cumberland Airlines'
  },
  {
    value: '3217',
    title: '3217 - Ceskoslovenske Aerolinie (CSA)'
  },
  {
    value: '3218',
    title: '3218 - Crown Air'
  },
  {
    value: '3219',
    title: '3219 - Compania Panamena De Aviacion (COPA)'
  },
  {
    value: '3220',
    title: '3220 - Compania Faucett'
  },
  {
    value: '3221',
    title: '3221 - Transportes Aeros Militares'
  },
  {
    value: '3222',
    title: '3222 - Command Airways'
  },
  {
    value: '3223',
    title: '3223 - Comair'
  },
  {
    value: '3226',
    title: '3226 - Skyways'
  },
  {
    value: '3228',
    title: '3228 - Cayman Airways'
  },
  {
    value: '3229',
    title: '3229 - SAETA (Sociedad Ecuatorianos De Transportes Aereos)'
  },
  {
    value: '3231',
    title: '3231 - SAHSA (Servicio Aero De Honduras)'
  },
  {
    value: '3233',
    title: '3233 - Capitol Air'
  },
  {
    value: '3234',
    title: '3234 - Caribbean Airlines'
  },
  {
    value: '3235',
    title: '3235 - Brockway Air'
  },
  {
    value: '3236',
    title: '3236 - Air Arabia Airline'
  },
  {
    value: '3238',
    title: '3238 - Bemidji Aviation'
  },
  {
    value: '3239',
    title: '3239 - Bar Harbor Airlines'
  },
  {
    value: '3240',
    title: '3240 - Bahamasair'
  },
  {
    value: '3241',
    title: '3241 - Aviateca'
  },
  {
    value: '3242',
    title: '3242 - Avensa'
  },
  {
    value: '3243',
    title: '3243 - Austrian Air Service'
  },
  {
    value: '3245',
    title: '3245 - Easyjet Air'
  },
  {
    value: '3246',
    title: '3246 - Ryanair'
  },
  {
    value: '3247',
    title: '3247 - Gol Airlines'
  },
  {
    value: '3248',
    title: '3248 - Tam Airlines'
  },
  {
    value: '3251',
    title: '3251 - Aloha Airlines'
  },
  {
    value: '3252',
    title: '3252 - Antilean Airlines (ALM)'
  },
  {
    value: '3253',
    title: '3253 - America West'
  },
  {
    value: '3254',
    title: '3254 - U.S. Air Shuttle'
  },
  {
    value: '3256',
    title: '3256 - Alaska Airlines Inc.'
  },
  {
    value: '3259',
    title: '3259 - American Trans Air'
  },
  {
    value: '3260',
    title: '3260 - Spirit Airlines'
  },
  {
    value: '3261',
    title: '3261 - Air China'
  },
  {
    value: '3262',
    title: '3262 - Reno Air'
  },
  {
    value: '3263',
    title: '3263 - Aero Servicio Carabobo'
  },
  {
    value: '3266',
    title: '3266 - Air Seychelles'
  },
  {
    value: '3267',
    title: '3267 - Air Panama International'
  },
  {
    value: '3273',
    title: '3273 - Rica Hotels'
  },
  {
    value: '3274',
    title: '3274 - Inter Nor Hotels'
  },
  {
    value: '3280',
    title: '3280 - Air Jamaica'
  },
  {
    value: '3281',
    title: '3281 - Air Djibouti'
  },
  {
    value: '3282',
    title: '3282 - Air Djibouti'
  },
  {
    value: '3284',
    title: '3284 - Aero Virgin Islands'
  },
  {
    value: '3285',
    title: '3285 - Aero Peru'
  },
  {
    value: '3286',
    title: '3286 - Aero Nicaraguensis'
  },
  {
    value: '3287',
    title: '3287 - Aero Coach Aviation'
  },
  {
    value: '3292',
    title: '3292 - Cyprus Airways'
  },
  {
    value: '3293',
    title: '3293 - Ecuatoriana'
  },
  {
    value: '3294',
    title: '3294 - Ethiopian Airlines'
  },
  {
    value: '3295',
    title: '3295 - Kenya Airways'
  },
  {
    value: '3296',
    title: '3296 - Air Berlin'
  },
  {
    value: '3297',
    title: '3297 - Tarom Romanian Air Transport'
  },
  {
    value: '3298',
    title: '3298 - Air Mauritius'
  },
  {
    value: '3299',
    title: '3299 - Wideroes Flyveselskap'
  },
  {
    value: '3351',
    title: '3351 - Affiliated Auto Rental'
  },
  {
    value: '3352',
    title: '3352 - American International'
  },
  {
    value: '3353',
    title: '3353 - Brooks Rent-A-Car'
  },
  {
    value: '3354',
    title: '3354 - Action Auto Rental'
  },
  {
    value: '3355',
    title: '3355 - Sixt Car Rental'
  },
  {
    value: '3357',
    title: '3357 - Hertz'
  },
  {
    value: '3359',
    title: '3359 - Payless Car Rental'
  },
  {
    value: '3360',
    title: '3360 - Snappy Car Rental'
  },
  {
    value: '3361',
    title: '3361 - Airways'
  },
  {
    value: '3362',
    title: '3362 - Altra Auto Rental'
  },
  {
    value: '3364',
    title: '3364 - Agency Rent-A-Car'
  },
  {
    value: '3366',
    title: '3366 - Budget Rent-A-Car'
  },
  {
    value: '3368',
    title: '3368 - Holiday Rent-A-Car'
  },
  {
    value: '3370',
    title: '3370 - Rent-A-Wreck'
  },
  {
    value: '3374',
    title: '3374 - Accent Rent-A-Car'
  },
  {
    value: '3376',
    title: '3376 - Ajax Rent-A-Car'
  },
  {
    value: '3380',
    title: '3380 - Triangle Rent-A-Car'
  },
  {
    value: '3381',
    title: '3381 - Europcar'
  },
  {
    value: '3385',
    title: '3385 - Tropical Rent-A-Car'
  },
  {
    value: '3386',
    title: '3386 - Showcase Rental Cars'
  },
  {
    value: '3387',
    title: '3387 - Alamo Rent-A-Car'
  },
  {
    value: '3388',
    title: '3388 - Merchants Rent-A-Car'
  },
  {
    value: '3389',
    title: '3389 - Avis Rent-A-Car'
  },
  {
    value: '3390',
    title: '3390 - Dollar Rent-A-Car'
  },
  {
    value: '3391',
    title: '3391 - Europe By Car'
  },
  {
    value: '3393',
    title: '3393 - National Car Rental'
  },
  {
    value: '3394',
    title: '3394 - Kemwell Group'
  },
  {
    value: '3395',
    title: '3395 - Thrifty Car Rental'
  },
  {
    value: '3396',
    title: '3396 - Tilden Rent-A-Car'
  },
  {
    value: '3398',
    title: '3398 - Econo-Car Rent-A-Car'
  },
  {
    value: '3400',
    title: '3400 - Auto Host Rental Cars'
  },
  {
    value: '3405',
    title: '3405 - Enterprise Rent-A-Car'
  },
  {
    value: '3409',
    title: '3409 - General Rent-A-Car'
  },
  {
    value: '3412',
    title: '3412 - A1 Rent-A-Car'
  },
  {
    value: '3414',
    title: '3414 - Godfrey National'
  },
  {
    value: '3420',
    title: '3420 - Ansa International'
  },
  {
    value: '3421',
    title: '3421 - Allstate'
  },
  {
    value: '3423',
    title: '3423 - AVCAR'
  },
  {
    value: '3425',
    title: '3425 - Automate'
  },
  {
    value: '3427',
    title: '3427 - Avon Rent-A-Car'
  },
  {
    value: '3428',
    title: '3428 - Carey'
  },
  {
    value: '3429',
    title: '3429 - Insurance Rent-A-Car'
  },
  {
    value: '3430',
    title: '3430 - Major Rent-A-Car'
  },
  {
    value: '3431',
    title: '3431 - Replacement Rent-A-Car'
  },
  {
    value: '3432',
    title: '3432 - Reserve Rent-A-Car'
  },
  {
    value: '3433',
    title: '3433 - Ugly Duckling Rent-A-Car'
  },
  {
    value: '3434',
    title: '3434 - USA'
  },
  {
    value: '3435',
    title: '3435 - Value Rent-A-Car'
  },
  {
    value: '3436',
    title: '3436 - Autohansa'
  },
  {
    value: '3437',
    title: '3437 - Cite'
  },
  {
    value: '3438',
    title: '3438 - Interent'
  },
  {
    value: '3439',
    title: '3439 - Milleville'
  },
  {
    value: '3441',
    title: '3441 - Advantage Rent A Car'
  },
  {
    value: '3501',
    title: '3501 - Holiday Inns'
  },
  {
    value: '3502',
    title: '3502 - Best Western'
  },
  {
    value: '3503',
    title: '3503 - Sheraton'
  },
  {
    value: '3504',
    title: '3504 - Hilton'
  },
  {
    value: '3505',
    title: '3505 - Trusthouse Forte'
  },
  {
    value: '3506',
    title: '3506 - Golden Tulip'
  },
  {
    value: '3507',
    title: '3507 - Friendship Inns International'
  },
  {
    value: '3508',
    title: '3508 - Quality International'
  },
  {
    value: '3509',
    title: '3509 - Marriott'
  },
  {
    value: '3510',
    title: '3510 - Days Inns Of America'
  },
  {
    value: '3511',
    title: '3511 - Arabella Hotels'
  },
  {
    value: '3512',
    title: '3512 - Intercontinental'
  },
  {
    value: '3513',
    title: '3513 - Westin Hotels'
  },
  {
    value: '3514',
    title: '3514 - Amerisuites'
  },
  {
    value: '3515',
    title: '3515 - Rodeway Inns International'
  },
  {
    value: '3516',
    title: '3516 - La Quinta Motor Inns'
  },
  {
    value: '3517',
    title: '3517 - Americana Hotels Corporation'
  },
  {
    value: '3518',
    title: '3518 - Sol Hotels'
  },
  {
    value: '3519',
    title: '3519 - PLM/ETAP International'
  },
  {
    value: '3520',
    title: '3520 - Meridien'
  },
  {
    value: '3521',
    title: '3521 - Crest Hotels'
  },
  {
    value: '3522',
    title: '3522 - Tokyo Group'
  },
  {
    value: '3523',
    title: '3523 - Peninsula Hotels'
  },
  {
    value: '3524',
    title: '3524 - Welcomgroup'
  },
  {
    value: '3525',
    title: '3525 - Dunfey Hotels'
  },
  {
    value: '3526',
    title: '3526 - Prince Hotels'
  },
  {
    value: '3527',
    title: '3527 - Downtowner Passport'
  },
  {
    value: '3528',
    title: '3528 - Thunderbird/Red Lion'
  },
  {
    value: '3529',
    title: '3529 - CP (Canadian Pacific)'
  },
  {
    value: '3530',
    title: '3530 - Renaissance Hotels'
  },
  {
    value: '3531',
    title: '3531 - Kauai Coconut Beach Resort'
  },
  {
    value: '3532',
    title: '3532 - Royal Kona Resort'
  },
  {
    value: '3533',
    title: '3533 - Park Inn by Radisson'
  },
  {
    value: '3534',
    title: '3534 - Southern Pacific Hotel'
  },
  {
    value: '3535',
    title: '3535 - Hilton International'
  },
  {
    value: '3536',
    title: '3536 - AMFAC Hotels'
  },
  {
    value: '3537',
    title: '3537 - Ana Hotels'
  },
  {
    value: '3538',
    title: '3538 - Concorde Hotels'
  },
  {
    value: '3539',
    title: '3539 - Summerfield Suites Hotel'
  },
  {
    value: '3540',
    title: '3540 - Iberotel'
  },
  {
    value: '3541',
    title: '3541 - Hotel Okura'
  },
  {
    value: '3542',
    title: '3542 - Royal Hotels'
  },
  {
    value: '3543',
    title: '3543 - Four Seasons'
  },
  {
    value: '3544',
    title: '3544 - Cigahotels'
  },
  {
    value: '3545',
    title: '3545 - Shangri-La International'
  },
  {
    value: '3546',
    title: '3546 - Hotel Sierra'
  },
  {
    value: '3547',
    title: '3547 - Breakers Resort'
  },
  {
    value: '3548',
    title: '3548 - Hotels Melia'
  },
  {
    value: '3549',
    title: '3549 - Auberge Des Governeurs'
  },
  {
    value: '3550',
    title: '3550 - Regal 8 Inns'
  },
  {
    value: '3551',
    title: '3551 - Mirage Hotel and Casino'
  },
  {
    value: '3552',
    title: '3552 - Coast Hotels'
  },
  {
    value: '3553',
    title: '3553 - Parks Inns International'
  },
  {
    value: '3554',
    title: '3554 - Pinehurst Resort'
  },
  {
    value: '3555',
    title: '3555 - Treasure Island Hotel and Casino'
  },
  {
    value: '3556',
    title: '3556 - Barton Creek Resort'
  },
  {
    value: '3557',
    title: '3557 - Manhattan East Suite Hotels'
  },
  {
    value: '3558',
    title: '3558 - Jolly Hotels'
  },
  {
    value: '3559',
    title: '3559 - Candlewood Suites'
  },
  {
    value: '3560',
    title: '3560 - Aladdin Resort and Casino'
  },
  {
    value: '3561',
    title: '3561 - Golden Nugget'
  },
  {
    value: '3562',
    title: '3562 - Comfort Hotel International'
  },
  {
    value: '3563',
    title: '3563 - Journeys End Motels'
  },
  {
    value: '3564',
    title: '3564 - Sams Town Hotel and Casino'
  },
  {
    value: '3565',
    title: '3565 - Relax Inns'
  },
  {
    value: '3566',
    title: '3566 - Garden Place Hotel'
  },
  {
    value: '3567',
    title: '3567 - Soho Grand Hotel'
  },
  {
    value: '3568',
    title: '3568 - Ladbroke Hotels'
  },
  {
    value: '3569',
    title: '3569 - Tribeca Grand Hotel'
  },
  {
    value: '3570',
    title: '3570 - Grand Met Forum Hotels'
  },
  {
    value: '3571',
    title: '3571 - Grand Wailea Resort'
  },
  {
    value: '3572',
    title: '3572 - Miyako Hotel/Kintetsu'
  },
  {
    value: '3573',
    title: '3573 - Sandman Hotels'
  },
  {
    value: '3574',
    title: '3574 - Venture Inn'
  },
  {
    value: '3575',
    title: '3575 - Vagabond Hotels'
  },
  {
    value: '3576',
    title: '3576 - La Quinta Resort'
  },
  {
    value: '3577',
    title: '3577 - Mandarin International'
  },
  {
    value: '3578',
    title: '3578 - Frankenmuth Bavarian'
  },
  {
    value: '3579',
    title: '3579 - Hotel Mercure'
  },
  {
    value: '3580',
    title: '3580 - Hotel Del Coronado'
  },
  {
    value: '3581',
    title: '3581 - Delta Hotels'
  },
  {
    value: '3582',
    title: '3582 - California Hotel and Casino'
  },
  {
    value: '3583',
    title: '3583 - Radisson BLU'
  },
  {
    value: '3584',
    title: '3584 - Princess Hotels International'
  },
  {
    value: '3585',
    title: '3585 - Hungar Hotels'
  },
  {
    value: '3586',
    title: '3586 - Sokos Hotel'
  },
  {
    value: '3587',
    title: '3587 - Doral Hotels'
  },
  {
    value: '3588',
    title: '3588 - Helmsley Hotels'
  },
  {
    value: '3589',
    title: '3589 - Doral Golf Resort'
  },
  {
    value: '3590',
    title: '3590 - Fairmont Hotels Corporation'
  },
  {
    value: '3591',
    title: '3591 - Sonesta International Hotels'
  },
  {
    value: '3592',
    title: '3592 - Omni International'
  },
  {
    value: '3593',
    title: '3593 - Cunard Hotels'
  },
  {
    value: '3594',
    title: '3594 - Arizona Biltmore'
  },
  {
    value: '3595',
    title: '3595 - Hospitality Inns'
  },
  {
    value: '3596',
    title: '3596 - Wynn Las Vegas'
  },
  {
    value: '3597',
    title: '3597 - Riverside Resort and Casino'
  },
  {
    value: '3598',
    title: '3598 - Regent Hotels'
  },
  {
    value: '3599',
    title: '3599 - Pannonia Hotels'
  },
  {
    value: '3600',
    title: '3600 - Saddlebrook Resort-Tampa'
  },
  {
    value: '3601',
    title: '3601 - Tradewinds Resort'
  },
  {
    value: '3602',
    title: '3602 - Hudson Hotel'
  },
  {
    value: '3603',
    title: '3603 - Noahs Hotel (Melbourne)'
  },
  {
    value: '3604',
    title: '3604 - Hilton Garden Inn'
  },
  {
    value: '3605',
    title: '3605 - Jurys Doyle Hotel Group'
  },
  {
    value: '3606',
    title: '3606 - Jefferson Hotel'
  },
  {
    value: '3607',
    title: '3607 - Fontainebleau Resort'
  },
  {
    value: '3608',
    title: '3608 - Gaylord Opryland'
  },
  {
    value: '3609',
    title: '3609 - Gaylord Palms'
  },
  {
    value: '3610',
    title: '3610 - Gaylord Texan'
  },
  {
    value: '3611',
    title: '3611 - C MON INN'
  },
  {
    value: '3612',
    title: '3612 - Moevenpick'
  },
  {
    value: '3613',
    title: '3613 - Microtel Inn and Suites'
  },
  {
    value: '3614',
    title: '3614 - AmericInn'
  },
  {
    value: '3615',
    title: '3615 - Travelodge Motels'
  },
  {
    value: '3616',
    title: '3616 - Hermitage Hotels'
  },
  {
    value: '3617',
    title: `3617 - America's Best Value Inn`
  },
  {
    value: '3618',
    title: '3618 - Great Wolf'
  },
  {
    value: '3619',
    title: '3619 - Aloft'
  },
  {
    value: '3620',
    title: '3620 - Binions Horseshoe Club'
  },
  {
    value: '3621',
    title: '3621 - Extended Stay'
  },
  {
    value: '3622',
    title: '3622 - Merlin Hotel (Perth)'
  },
  {
    value: '3623',
    title: '3623 - Dorint Hotels'
  },
  {
    value: '3624',
    title: '3624 - Lady Luck Hotel and Casino'
  },
  {
    value: '3625',
    title: '3625 - Hotel Universal'
  },
  {
    value: '3626',
    title: '3626 - Studio Plus'
  },
  {
    value: '3627',
    title: '3627 - Extended Stay America'
  },
  {
    value: '3628',
    title: '3628 - Excalibur Hotel and Casino'
  },
  {
    value: '3629',
    title: '3629 - Dan Hotels'
  },
  {
    value: '3630',
    title: '3630 - Extended Stay Deluxe'
  },
  {
    value: '3631',
    title: '3631 - Sleep Inn'
  },
  {
    value: '3632',
    title: '3632 - The Phoenician'
  },
  {
    value: '3633',
    title: '3633 - Rank Hotels'
  },
  {
    value: '3634',
    title: '3634 - Swissotel'
  },
  {
    value: '3635',
    title: '3635 - Reso Hotels'
  },
  {
    value: '3636',
    title: '3636 - Sarova Hotels'
  },
  {
    value: '3637',
    title: '3637 - Ramada Inns'
  },
  {
    value: '3638',
    title: '3638 - Howard Johnson'
  },
  {
    value: '3639',
    title: '3639 - Mount Charlotte Hotels'
  },
  {
    value: '3640',
    title: '3640 - Hyatt Hotels/International'
  },
  {
    value: '3641',
    title: '3641 - Sofitel Hotels'
  },
  {
    value: '3642',
    title: '3642 - Novotel Sieh (Accor)'
  },
  {
    value: '3643',
    title: '3643 - Steigenberger'
  },
  {
    value: '3644',
    title: '3644 - Econo-Travel Motor Hotel'
  },
  {
    value: '3645',
    title: '3645 - Queens Moat Houses'
  },
  {
    value: '3646',
    title: '3646 - Swallow Hotels'
  },
  {
    value: '3647',
    title: '3647 - Grupo Hotels HUSA SA'
  },
  {
    value: '3648',
    title: '3648 - De Vere Hotels'
  },
  {
    value: '3649',
    title: '3649 - Radisson'
  },
  {
    value: '3650',
    title: '3650 - Red Roof Inns'
  },
  {
    value: '3651',
    title: '3651 - Imperial London Hotels'
  },
  {
    value: '3652',
    title: '3652 - Embassy Hotels'
  },
  {
    value: '3653',
    title: '3653 - Penta Hotels'
  },
  {
    value: '3654',
    title: '3654 - Loews Hotels'
  },
  {
    value: '3655',
    title: '3655 - Scandic Hotels'
  },
  {
    value: '3656',
    title: '3656 - Sara Hotels'
  },
  {
    value: '3657',
    title: '3657 - Oberoi Hotels'
  },
  {
    value: '3658',
    title: '3658 - New Otani Hotels'
  },
  {
    value: '3659',
    title: '3659 - Taj Hotels Intl'
  },
  {
    value: '3660',
    title: '3660 - Knights Inn'
  },
  {
    value: '3661',
    title: '3661 - Metropole Hotels'
  },
  {
    value: '3662',
    title: '3662 - Circus Circus Hotel and Casino'
  },
  {
    value: '3663',
    title: '3663 - Hoteles El Presidente'
  },
  {
    value: '3664',
    title: '3664 - Flag Inns'
  },
  {
    value: '3665',
    title: '3665 - Hampton Inns'
  },
  {
    value: '3666',
    title: '3666 - Stakis Hotels'
  },
  {
    value: '3667',
    title: '3667 - Luxor Hotel and Casino'
  },
  {
    value: '3668',
    title: '3668 - Maritim'
  },
  {
    value: '3669',
    title: '3669 - Eldorado Hotel and Casino'
  },
  {
    value: '3670',
    title: '3670 - Arcade'
  },
  {
    value: '3671',
    title: '3671 - Arctia'
  },
  {
    value: '3672',
    title: '3672 - Campanile'
  },
  {
    value: '3673',
    title: '3673 - Ibusz Hotels'
  },
  {
    value: '3674',
    title: '3674 - Rantasipi Hotels'
  },
  {
    value: '3675',
    title: '3675 - Interhotel Cedok'
  },
  {
    value: '3676',
    title: '3676 - Monte Carlo Hotel and Casino'
  },
  {
    value: '3677',
    title: '3677 - Climat De France'
  },
  {
    value: '3678',
    title: '3678 - Cumulus Hotels'
  },
  {
    value: '3679',
    title: '3679 - Silver Legacy Hotel and Casino'
  },
  {
    value: '3680',
    title: '3680 - Hoteis Othan'
  },
  {
    value: '3681',
    title: '3681 - Adams Mark'
  },
  {
    value: '3682',
    title: '3682 - Sahara Hotel and Casino'
  },
  {
    value: '3683',
    title: '3683 - Bradbury Suites'
  },
  {
    value: '3684',
    title: '3684 - Budget Host Inns'
  },
  {
    value: '3685',
    title: '3685 - Budgetel'
  },
  {
    value: '3686',
    title: '3686 - Suisse Chalet'
  },
  {
    value: '3687',
    title: '3687 - Clarion Hotel'
  },
  {
    value: '3688',
    title: '3688 - Compri Hotel'
  },
  {
    value: '3689',
    title: '3689 - Consort'
  },
  {
    value: '3690',
    title: '3690 - Courtyard by Marriott'
  },
  {
    value: '3691',
    title: '3691 - Dillon Inn'
  },
  {
    value: '3692',
    title: '3692 - Doubletree'
  },
  {
    value: '3693',
    title: '3693 - Drury Inn'
  },
  {
    value: '3694',
    title: '3694 - Economy Inns Of America'
  },
  {
    value: '3695',
    title: '3695 - Embassy Suites'
  },
  {
    value: '3696',
    title: '3696 - Excel Inn'
  },
  {
    value: '3697',
    title: '3697 - Fairfield Hotel'
  },
  {
    value: '3698',
    title: '3698 - Harley Hotel'
  },
  {
    value: '3699',
    title: '3699 - Midway Motor Lodge'
  },
  {
    value: '3700',
    title: '3700 - Motel 6'
  },
  {
    value: '3701',
    title: '3701 - La Mansion Del Rio'
  },
  {
    value: '3702',
    title: '3702 - Registry Hotels'
  },
  {
    value: '3703',
    title: '3703 - Residence Inn'
  },
  {
    value: '3704',
    title: '3704 - Royce Hotel'
  },
  {
    value: '3705',
    title: '3705 - Sandman Inn'
  },
  {
    value: '3706',
    title: '3706 - Shilo Inn'
  },
  {
    value: '3707',
    title: '3707 - Shoneys Inn'
  },
  {
    value: '3708',
    title: '3708 - Virgin River Hotel and Casino'
  },
  {
    value: '3709',
    title: '3709 - Super 8 Motel'
  },
  {
    value: '3710',
    title: '3710 - Ritz-Carlton'
  },
  {
    value: '3711',
    title: '3711 - Flag Inns (Australia)'
  },
  {
    value: '3712',
    title: '3712 - Buffalo Bills Hotel and Casino'
  },
  {
    value: '3713',
    title: '3713 - Quality Pacific Hotel'
  },
  {
    value: '3714',
    title: '3714 - Four Seasons (Australia)'
  },
  {
    value: '3715',
    title: '3715 - Fairfield Inn'
  },
  {
    value: '3716',
    title: '3716 - Carlton Hotels'
  },
  {
    value: '3717',
    title: '3717 - City Lodge Hotels'
  },
  {
    value: '3718',
    title: '3718 - Karos Hotels'
  },
  {
    value: '3719',
    title: '3719 - Protea Hotels'
  },
  {
    value: '3720',
    title: '3720 - Southern Sun Hotels'
  },
  {
    value: '3721',
    title: '3721 - Conrad Hotels'
  },
  {
    value: '3722',
    title: '3722 - Wyndham'
  },
  {
    value: '3723',
    title: '3723 - Rica Hotels'
  },
  {
    value: '3724',
    title: '3724 - Inter Nor Hotels'
  },
  {
    value: '3725',
    title: '3725 - Sea Pines Resort'
  },
  {
    value: '3726',
    title: '3726 - Rio Suites'
  },
  {
    value: '3727',
    title: '3727 - Broadmoor Hotel'
  },
  {
    value: '3728',
    title: '3728 - Ballys Hotel and Casino'
  },
  {
    value: '3729',
    title: '3729 - John Ascuagas Nugget'
  },
  {
    value: '3730',
    title: '3730 - MGM Grand Hotel'
  },
  {
    value: '3731',
    title: `3731 - Harrah's Hotels and Casinos`
  },
  {
    value: '3732',
    title: '3732 - Opryland Hotel'
  },
  {
    value: '3733',
    title: '3733 - Boca Raton Resort'
  },
  {
    value: '3734',
    title: '3734 - Harvey Bristol Hotels'
  },
  {
    value: '3735',
    title: '3735 - Masters Economy Inns'
  },
  {
    value: '3736',
    title: '3736 - Colorado Belle Edgewater Resort'
  },
  {
    value: '3737',
    title: '3737 - Riviera Hotel and Casino'
  },
  {
    value: '3738',
    title: '3738 - Tropicana Resort and Casino'
  },
  {
    value: '3739',
    title: '3739 - Woodside Hotels and Resorts'
  },
  {
    value: '3740',
    title: '3740 - Towneplace Suites'
  },
  {
    value: '3741',
    title: '3741 - Millennium Broadway Hotel'
  },
  {
    value: '3742',
    title: '3742 - Club Med'
  },
  {
    value: '3743',
    title: '3743 - Biltmore Hotel and Suites'
  },
  {
    value: '3744',
    title: '3744 - Carefree Resorts'
  },
  {
    value: '3745',
    title: '3745 - St. Regis Hotel'
  },
  {
    value: '3746',
    title: '3746 - The Eliot Hotel'
  },
  {
    value: '3747',
    title: '3747 - Club Corporation/Club Resorts'
  },
  {
    value: '3748',
    title: '3748 - Wellesley Inns'
  },
  {
    value: '3749',
    title: '3749 - Beverly Hills Hotel'
  },
  {
    value: '3750',
    title: '3750 - Crowne Plaza Hotels'
  },
  {
    value: '3751',
    title: '3751 - Homewood Suites'
  },
  {
    value: '3752',
    title: '3752 - Peabody Hotels'
  },
  {
    value: '3753',
    title: '3753 - Greenbriar Resorts'
  },
  {
    value: '3754',
    title: '3754 - Amelia Island Plantation'
  },
  {
    value: '3755',
    title: '3755 - Homestead'
  },
  {
    value: '3757',
    title: '3757 - Canyon Ranch'
  },
  {
    value: '3758',
    title: '3758 - Kahala Mandarin Oriental Hotel'
  },
  {
    value: '3759',
    title: '3759 - Orchid At Mauna Lai'
  },
  {
    value: '3760',
    title: '3760 - Halekulani Hotel/Waikiki Parc'
  },
  {
    value: '3761',
    title: '3761 - Primadonna Hotel and Casino'
  },
  {
    value: '3762',
    title: `3762 - Whiskey Pete's Hotel and Casino`
  },
  {
    value: '3763',
    title: '3763 - Chateau Elan Winery and Resort'
  },
  {
    value: '3764',
    title: '3764 - Beau Rivage Hotel and Casino'
  },
  {
    value: '3765',
    title: '3765 - Bellagio'
  },
  {
    value: '3766',
    title: '3766 - Fremont Hotel and Casino'
  },
  {
    value: '3767',
    title: '3767 - Main Street Station Hotel and Casino'
  },
  {
    value: '3768',
    title: '3768 - Silver Star Hotel and Casino'
  },
  {
    value: '3769',
    title: '3769 - Stratosphere Hotel and Casino'
  },
  {
    value: '3770',
    title: '3770 - Springhill Suites'
  },
  {
    value: '3771',
    title: '3771 - Caesars Hotel and Casino'
  },
  {
    value: '3772',
    title: '3772 - Nemacolin Woodlands'
  },
  {
    value: '3773',
    title: '3773 - Venetian Resort Hotel and Casino'
  },
  {
    value: '3774',
    title: '3774 - New York-New York Hotel and Casino'
  },
  {
    value: '3775',
    title: '3775 - Sands Resort'
  },
  {
    value: '3776',
    title: '3776 - Nevele Grande Resort and Country Club'
  },
  {
    value: '3777',
    title: '3777 - Mandalay Bay Resort'
  },
  {
    value: '3778',
    title: '3778 - Four Points Hotels'
  },
  {
    value: '3779',
    title: '3779 - W Hotels'
  },
  {
    value: '3780',
    title: '3780 - Disneyland Resorts'
  },
  {
    value: '3781',
    title: '3781 - Patricia Grand Resort Hotels'
  },
  {
    value: '3782',
    title: '3782 - Rosen Hotel and Resorts'
  },
  {
    value: '3783',
    title: '3783 - Town and Country Resort and Convention Center'
  },
  {
    value: '3784',
    title: '3784 - First Hospitality Hotels'
  },
  {
    value: '3785',
    title: '3785 - Outrigger Hotels and Resorts'
  },
  {
    value: '3786',
    title: '3786 - Ohana Hotels of Hawaii'
  },
  {
    value: '3787',
    title: '3787 - Caribe Royal Resort Suite and Villas'
  },
  {
    value: '3788',
    title: '3788 - Ala Moana Hotel'
  },
  {
    value: '3789',
    title: '3789 - Smugglers Notch Resort'
  },
  {
    value: '3790',
    title: '3790 - Raffles Hotels'
  },
  {
    value: '3791',
    title: '3791 - Staybridge Suites'
  },
  {
    value: '3792',
    title: '3792 - Claridge Casino Hotel'
  },
  {
    value: '3793',
    title: '3793 - The Flamingo Hotels'
  },
  {
    value: '3794',
    title: '3794 - Grand Casino Hotels'
  },
  {
    value: '3795',
    title: '3795 - Paris Las Vegas Hotel'
  },
  {
    value: '3796',
    title: '3796 - Peppermill Hotel Casino'
  },
  {
    value: '3797',
    title: '3797 - Atlantic City Hilton'
  },
  {
    value: '3798',
    title: '3798 - Embassy Vacation Resort'
  },
  {
    value: '3799',
    title: '3799 - Hale Koa Hotel'
  },
  {
    value: '3800',
    title: '3800 - Homestead Suites'
  },
  {
    value: '3801',
    title: '3801 - Wilderness Hotel and Resort'
  },
  {
    value: '3802',
    title: '3802 - The Palace Hotel'
  },
  {
    value: '3803',
    title: '3803 - The Wigwam Golf Resort and Spa'
  },
  {
    value: '3804',
    title: '3804 - The Diplomat Country Club and Spa'
  },
  {
    value: '3805',
    title: '3805 - The Atlantic'
  },
  {
    value: '3806',
    title: '3806 - Princeville Resort'
  },
  {
    value: '3807',
    title: '3807 - Element'
  },
  {
    value: '3808',
    title: '3808 - LXR (Luxury Resorts)'
  },
  {
    value: '3809',
    title: '3809 - Settle Inn'
  },
  {
    value: '3810',
    title: '3810 - La Costa Resort'
  },
  {
    value: '3811',
    title: '3811 - Premier Travel Inns'
  },
  {
    value: '3812',
    title: '3812 - Hyatt Place'
  },
  {
    value: '3813',
    title: '3813 - Hotel Indigo'
  },
  {
    value: '3814',
    title: '3814 - The Roosevelt Hotel NY'
  },
  {
    value: '3815',
    title: '3815 - Nickelodeon Family Suites by Holiday Inn'
  },
  {
    value: '3816',
    title: '3816 - Home2Suites'
  },
  {
    value: '3817',
    title: '3817 - Affinia'
  },
  {
    value: '3818',
    title: '3818 - Mainstay Suites'
  },
  {
    value: '3819',
    title: '3819 - Oxford Suites'
  },
  {
    value: '3820',
    title: '3820 - Jumeirah Essex House'
  },
  {
    value: '3821',
    title: '3821 - Caribe Royal'
  },
  {
    value: '3822',
    title: '3822 - Crossland'
  },
  {
    value: '3823',
    title: '3823 - Grand Sierra Resort'
  },
  {
    value: '3824',
    title: '3824 - Aria'
  },
  {
    value: '3825',
    title: '3825 - Vdara'
  },
  {
    value: '3826',
    title: '3826 - Autograph'
  },
  {
    value: '3827',
    title: '3827 - Galt House'
  },
  {
    value: '3828',
    title: '3828 - Cosmopolitan of Las Vegas'
  },
  {
    value: '3829',
    title: '3829 - Country Inn By Carlson'
  },
  {
    value: '3830',
    title: '3830 - Park Plaza Hotel'
  },
  {
    value: '3831',
    title: '3831 - Waldorf'
  },
  {
    value: '4011',
    title: '4011 - Railroads-Freight'
  },
  {
    value: '4111',
    title: '4111 - Transportation-Suburban and Local Commuter Passenger, including Ferries'
  },
  {
    value: '4112',
    title: '4112 - Passenger Railways'
  },
  {
    value: '4119',
    title: '4119 - Ambulance Services'
  },
  {
    value: '4121',
    title: '4121 - Taxicabs and Limousines'
  },
  {
    value: '4131',
    title: '4131 - Bus Lines'
  },
  {
    value: '4214',
    title:
      '4214 - Motor Freight Carriers,Trucking-Local/Long Distance, Moving and Storage Companies, Local Delivery'
  },
  {
    value: '4215',
    title: '4215 - Courier Services-Air and Ground, Freight Forwarders'
  },
  {
    value: '4225',
    title: '4225 - Public Warehousing-Farm Products, Refrigerated Goods, Household Goods Storage'
  },
  {
    value: '4411',
    title: '4411 - Cruise Lines'
  },
  {
    value: '4457',
    title: '4457 - Boat Leases and Boat Rentals'
  },
  {
    value: '4468',
    title: '4468 - Marinas, Marine Service/Supplies'
  },
  {
    value: '4511',
    title: '4511 - Air Carriers, Airlines-not elsewhere classified'
  },
  {
    value: '4582',
    title: '4582 - Airports, Airport Terminals, Flying Fields'
  },
  {
    value: '4722',
    title: '4722 - Travel Agencies and Tour Operators'
  },
  {
    value: '4723',
    title: '4723 - Package Tour Operators (Germany Only)'
  },
  {
    value: '4761',
    title: '4761 - Telemarketing of Travel Related Services and Vitamins'
  },
  {
    value: '4784',
    title: '4784 - Bridge and Road Fees, Tolls'
  },
  {
    value: '4789',
    title: '4789 - Transportation Services Not Elsewhere Classified'
  },
  {
    value: '4812',
    title: '4812 - Telecommunication Equipment Including Telephone Sales'
  },
  {
    value: '4813',
    title:
      '4813 - Key-Entry Telecom Merchant providing single local and long-distance phone calls using a central access number in a non-face-to-face environment using key entry'
  },
  {
    value: '4814',
    title:
      '4814 - Telecommunication Services including but not limited to prepaid phone services and recurring phone services'
  },
  {
    value: '4816',
    title: '4816 - Computer Network/Information Services'
  },
  {
    value: '4821',
    title: '4821 - Telegraph Services'
  },
  {
    value: '4829',
    title: '4829 - Wire Transfer Money Orders (V, D, G, X) Money Transfer (M)'
  },
  {
    value: '4899',
    title: '4899 - Cable, Satellite, and Other Pay Television and Radio Services'
  },
  {
    value: '4900',
    title: '4900 - Utilities-Electric, Gas, Heating Oil, Sanitary, Water'
  },
  {
    value: '5013',
    title: '5013 - Motor Vehicle Supplies and New Parts'
  },
  {
    value: '5021',
    title: '5021 - Office and Commercial Furniture'
  },
  {
    value: '5039',
    title: '5039 - Construction Materials Not Elsewhere Classified'
  },
  {
    value: '5044',
    title: '5044 - Office, Photographic, Photocopy and Microfilm Equipment'
  },
  {
    value: '5045',
    title: '5045 - Computers, Computer Peripheral Equipment, Software'
  },
  {
    value: '5046',
    title: '5046 - Commercial Equipment Not Elsewhere Classified'
  },
  {
    value: '5047',
    title: '5047 - Dental/Laboratory/Medical/Ophthalmic Hospital Equipment and Supplies'
  },
  {
    value: '5051',
    title: '5051 - Metal Service Centers and Offices'
  },
  {
    value: '5065',
    title: '5065 - Electrical Parts and Equipment'
  },
  {
    value: '5072',
    title: '5072 - Hardware Equipment and Supplies'
  },
  {
    value: '5074',
    title: '5074 - Plumbing and Heating Equipment'
  },
  {
    value: '5085',
    title: '5085 - Industrial Supplies Not Elsewhere Classified'
  },
  {
    value: '5094',
    title: '5094 - Precious Stones and Metals, Watches and Jewelry'
  },
  {
    value: '5099',
    title: '5099 - Durable Goods Not Elsewhere Classified'
  },
  {
    value: '5111',
    title: '5111 - Stationery, Office Supplies, Printing and Writing Paper'
  },
  {
    value: '5122',
    title: '5122 - Drugs, Drug Proprietors and Druggists Sundries'
  },
  {
    value: '5131',
    title: '5131 - Piece Goods, Notions, and Other Dry Goods'
  },
  {
    value: '5137',
    title: `5137 - Men's, Women's and Children's Uniforms and Commercial Clothing`
  },
  {
    value: '5139',
    title: '5139 - Commercial Footware'
  },
  {
    value: '5169',
    title: '5169 - Chemicals and Allied Products Not Elsewhere Classified'
  },
  {
    value: '5172',
    title: '5172 - Petroleum and Petroleum Products'
  },
  {
    value: '5192',
    title: '5192 - Books, Periodicals and Newspapers'
  },
  {
    value: '5193',
    title: '5193 - Florists Supplies, Nursery Stock and Flowers'
  },
  {
    value: '5198',
    title: '5198 - Paints, Varnishes and Supplies'
  },
  {
    value: '5199',
    title: '5199 - Non-Durable Goods Not Elsewhere Classified'
  },
  {
    value: '5200',
    title: '5200 - Home Supply Warehouse Stores'
  },
  {
    value: '5211',
    title: '5211 - Building Materials, Lumber Stores'
  },
  {
    value: '5231',
    title: '5231 - Glass, Paint, Wallpaper Stores'
  },
  {
    value: '5251',
    title: '5251 - Hardware Stores'
  },
  {
    value: '5261',
    title: '5261 - Lawn and Garden Supply Stores'
  },
  {
    value: '5271',
    title: '5271 - Mobile Home Dealers'
  },
  {
    value: '5299',
    title: '5299 - Warehouse Club Gas'
  },
  {
    value: '5300',
    title: '5300 - Wholesale Clubs'
  },
  {
    value: '5309',
    title: '5309 - Duty Free Stores'
  },
  {
    value: '5310',
    title: '5310 - Discount Stores'
  },
  {
    value: '5311',
    title: '5311 - Department Stores'
  },
  {
    value: '5331',
    title: '5331 - Variety Stores'
  },
  {
    value: '5399',
    title: '5399 - Miscellaneous General Merchandise Stores'
  },
  {
    value: '5411',
    title: '5411 - Grocery Stores, Supermarkets'
  },
  {
    value: '5422',
    title: '5422 - Freezer and Locker Meat Provisioners'
  },
  {
    value: '5441',
    title: '5441 - Candy, Nut and Confectionery Stores'
  },
  {
    value: '5451',
    title: '5451 - Dairy Products Stores'
  },
  {
    value: '5462',
    title: '5462 - Bakeries'
  },
  {
    value: '5499',
    title:
      '5499 - Miscellaneous Food Stores-Convenience Stores, Markets, Specialty Stores, and Vending Machines'
  },
  {
    value: '5511',
    title: '5511 - Automobile and Truck Dealers-Sales, Service, Repairs, Parts and Leasing'
  },
  {
    value: '5521',
    title: '5521 - Automobile and Truck Dealers-(Used Only)-Sales'
  },
  {
    value: '5532',
    title: '5532 - Automotive Tire Stores'
  },
  {
    value: '5533',
    title: '5533 - Automotive Parts and Accessories Stores'
  },
  {
    value: '5541',
    title: '5541 - Service Stations (With or Without Ancillary Services)'
  },
  {
    value: '5542',
    title: '5542 - Fuel Dispenser, Automated'
  },
  {
    value: '5551',
    title: '5551 - Boat Dealers'
  },
  {
    value: '5552',
    title: '5552 - Electric Vehicle Charging'
  },
  {
    value: '5561',
    title: '5561 - Camper Dealers, Recreational and Utility Trailers'
  },
  {
    value: '5571',
    title: '5571 - Motorcycle Shops and Dealers'
  },
  {
    value: '5592',
    title: '5592 - Motor Home Dealers'
  },
  {
    value: '5598',
    title: '5598 - Snowmobile Dealers'
  },
  {
    value: '5599',
    title:
      '5599 - Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers-Not Elsewhere Classified'
  },
  {
    value: '5611',
    title: `5611 - Men's and Boys' Clothing and Accessories Stores`
  },
  {
    value: '5621',
    title: `5621 - Women's Ready to Wear Stores`
  },
  {
    value: '5631',
    title: `5631 - Women's Accessory and Specialty Stores`
  },
  {
    value: '5641',
    title: `5641 - Children's and Infants' Wear Stores`
  },
  {
    value: '5651',
    title: '5651 - Family Clothing Stores'
  },
  {
    value: '5655',
    title: '5655 - Sports Apparel, and Riding Apparel Stores'
  },
  {
    value: '5661',
    title: '5661 - Shoe Stores'
  },
  {
    value: '5681',
    title: '5681 - Furriers and Fur Shops'
  },
  {
    value: '5691',
    title: '5691 - Men’s and Women’s Clothing Stores'
  },
  {
    value: '5697',
    title: '5697 - Alterations, Mending, Seamstresses, Tailors'
  },
  {
    value: '5698',
    title: '5698 - Wig and Toupee Shops'
  },
  {
    value: '5699',
    title: '5699 - Accessory and Apparel Stores-Miscellaneous'
  },
  {
    value: '5712',
    title: '5712 - Equipment, Furniture and Home Furnishings Stores (except Appliances)'
  },
  {
    value: '5713',
    title: '5713 - Floor Covering Stores'
  },
  {
    value: '5714',
    title: '5714 - Drapery, Upholstery and Window Coverings Stores'
  },
  {
    value: '5718',
    title: '5718 - Fireplace, Fireplace Screens and Accessories Stores'
  },
  {
    value: '5719',
    title: '5719 - Miscellaneous House Furnishing Specialty Shops'
  },
  {
    value: '5722',
    title: '5722 - Household Appliance Stores'
  },
  {
    value: '5732',
    title: '5732 - Electronics Sales'
  },
  {
    value: '5733',
    title: '5733 - Music Stores-Musical Instruments, Pianos and Sheet Music'
  },
  {
    value: '5734',
    title: '5734 - Computer Software Stores'
  },
  {
    value: '5735',
    title: '5735 - Record Shops'
  },
  {
    value: '5811',
    title: '5811 - Caterers'
  },
  {
    value: '5812',
    title: '5812 - Eating Places and Restaurants'
  },
  {
    value: '5813',
    title:
      '5813 - Bars, Cocktail Lounges, Discotheques, Nightclubs and Taverns-Drinking Places (Alcoholic Beverages)'
  },
  {
    value: '5814',
    title: '5814 - Fast Food Restaurants'
  },
  {
    value: '5815',
    title: '5815 - Digital Goods: Books, Movies, Music'
  },
  {
    value: '5816',
    title: '5816 - Digital Goods: Games'
  },
  {
    value: '5817',
    title: '5817 - Digital Goods: Applications (Excludes Games)'
  },
  {
    value: '5818',
    title:
      '5818 - Digital Goods: Large Digital Goods Merchant (V) Digital Goods: Multi-Category (M)'
  },
  {
    value: '5912',
    title: '5912 - Drug Stores and Pharmacies'
  },
  {
    value: '5921',
    title: '5921 - Package Stores-Beer, Wine and Liquor'
  },
  {
    value: '5931',
    title: '5931 - Second Hand Stores, Used Merchandise Stores'
  },
  {
    value: '5932',
    title: '5932 - Antique Shops-Sales, Repairs and Restoration Services'
  },
  {
    value: '5933',
    title: '5933 - Pawn Shops'
  },
  {
    value: '5935',
    title: '5935 - Wrecking and Salvage Yards'
  },
  {
    value: '5937',
    title: '5937 - Antique Reproduction Stores'
  },
  {
    value: '5940',
    title: '5940 - Bicycle Shops-Sales and Service'
  },
  {
    value: '5941',
    title: '5941 - Sporting Goods Stores'
  },
  {
    value: '5942',
    title: '5942 - Book Stores'
  },
  {
    value: '5943',
    title: '5943 - Office, School Supply and Stationery Stores'
  },
  {
    value: '5944',
    title: '5944 - Clock, Jewelry, Watch and Silverware Stores'
  },
  {
    value: '5945',
    title: '5945 - Game, Toy and Hobby Shops'
  },
  {
    value: '5946',
    title: '5946 - Camera and Photographic Supply Stores'
  },
  {
    value: '5947',
    title: '5947 - Card, Gift, Novelty and Souvenir Shops'
  },
  {
    value: '5948',
    title: '5948 - Leather Goods and Luggage Stores'
  },
  {
    value: '5949',
    title: '5949 - Fabric, Needlework, Piece Goods and Sewing Stores'
  },
  {
    value: '5950',
    title: '5950 - Crystal and Glassware Stores'
  },
  {
    value: '5960',
    title: '5960 - Direct Marketing Insurance Services'
  },
  {
    value: '5961',
    title: '5961 - Mail Order Houses Including Catalog Order Stores'
  },
  {
    value: '5962',
    title: '5962 - Direct Marketing-Travel Related Arrangement Services'
  },
  {
    value: '5963',
    title: '5963 - Door-to-Door Sales'
  },
  {
    value: '5964',
    title: '5964 - Direct Marketing-Catalog Merchants'
  },
  {
    value: '5965',
    title: '5965 - Direct Marketing-Combination Catalog and Retail Merchant'
  },
  {
    value: '5966',
    title: '5966 - Direct Marketing-Outbound Telemarketing Merchants'
  },
  {
    value: '5967',
    title: '5967 - Direct Marketing-Inbound Telemarketing Merchants'
  },
  {
    value: '5968',
    title: '5968 - Direct Marketing-Continuity/Subscription Merchants'
  },
  {
    value: '5969',
    title: '5969 - Direct Marketing-Other Direct Marketers-Not Elsewhere Classified'
  },
  {
    value: '5970',
    title: '5970 - Artist Supply Stores, Craft Shops'
  },
  {
    value: '5971',
    title: '5971 - Art Dealers and Galleries'
  },
  {
    value: '5972',
    title: '5972 - Stamp and Coin Stores-Philatelic and Numismatic Supplies'
  },
  {
    value: '5973',
    title: '5973 - Religious Goods Stores'
  },
  {
    value: '5974',
    title: '5974 - Rubber Stamp Store'
  },
  {
    value: '5975',
    title: '5975 - Hearing Aids-Sales, Service, Supply Stores'
  },
  {
    value: '5976',
    title: '5976 - Orthopedic Goods-Artificial Limb Stores'
  },
  {
    value: '5977',
    title: '5977 - Cosmetic Stores'
  },
  {
    value: '5978',
    title: '5978 - Typewriter Stores-Rentals, Sales, Service'
  },
  {
    value: '5983',
    title: '5983 - Fuel Dealers-Coal, Fuel Oil, Liquefied Petroleum, Wood'
  },
  {
    value: '5992',
    title: '5992 - Florists'
  },
  {
    value: '5993',
    title: '5993 - Cigar Stores and Stands'
  },
  {
    value: '5994',
    title: '5994 - News Dealers and Newsstands'
  },
  {
    value: '5995',
    title: '5995 - Pet Shops, Pet Food and Supplies'
  },
  {
    value: '5996',
    title: '5996 - Swimming Pools-Sales and Supplies'
  },
  {
    value: '5997',
    title: '5997 - Electric Razor Stores-Sales and Service'
  },
  {
    value: '5998',
    title: '5998 - Tent and Awning Shops'
  },
  {
    value: '5999',
    title: '5999 - Miscellaneous and Specialty Retail Stores'
  },
  {
    value: '6010',
    title: '6010 - Member Financial Institution-Manual Cash Disbursements'
  },
  {
    value: '6011',
    title: '6011 - Member Financial Institution-Automated Cash Disbursements'
  },
  {
    value: '6012',
    title: '6012 - Member Financial Institution-Merchandise And Services'
  },
  {
    value: '6050',
    title: '6050 - Quasi Cash–Member Financial Institution'
  },
  {
    value: '6051',
    title: '6051 - Quasi Cash-Merchant'
  },
  {
    value: '6211',
    title: '6211 - Securities-Brokers and Dealers'
  },
  {
    value: '6236',
    title: '6236 - Aero Servicio Carabobo'
  },
  {
    value: '6300',
    title: '6300 - Insurance Sales, Underwriting and Premiums'
  },
  {
    value: '6381',
    title: '6381 - Insurance-Premiums'
  },
  {
    value: '6513',
    title: '6513 - Real Estate Agents and Managers-Rentals'
  },
  {
    value: '6529',
    title: '6529 - Remote Stored Value Load — Member Financial Institution'
  },
  {
    value: '6530',
    title: '6530 - Remove Stored Value Load — Merchant'
  },
  {
    value: '6535',
    title: '6535 - Value Purchase–Member Financial Institution'
  },
  {
    value: '6536',
    title: '6536 - MoneySend Intracountry'
  },
  {
    value: '6537',
    title: '6537 - MoneySend Intercountry'
  },
  {
    value: '6538',
    title: '6538 - MoneySend Funding'
  },
  {
    value: '6539',
    title: '6539 - Funding Transaction (Excluding MoneySend)'
  },
  {
    value: '6540',
    title: '6540 - POI (Point of Interaction) Funding Transactions (Excluding MoneySend)'
  },
  {
    value: '6611',
    title: '6611 - Overpayments'
  },
  {
    value: '6760',
    title: '6760 - Savings Bonds'
  },
  {
    value: '7011',
    title: '7011 - Lodging-Hotels, Motels, Resorts-not elsewhere classified'
  },
  {
    value: '7012',
    title: '7012 - Timeshares'
  },
  {
    value: '7032',
    title: '7032 - Sporting and Recreational Camps'
  },
  {
    value: '7033',
    title: '7033 - Campgrounds and Trailer Parks'
  },
  {
    value: '7210',
    title: '7210 - Cleaning, Garment and Laundry Services'
  },
  {
    value: '7211',
    title: '7211 - Laundry Services-Family and Commercial'
  },
  {
    value: '7216',
    title: '7216 - Dry Cleaners'
  },
  {
    value: '7217',
    title: '7217 - Carpet and Upholstery Cleaning'
  },
  {
    value: '7221',
    title: '7221 - Photographic Studios'
  },
  {
    value: '7230',
    title: '7230 - Barber and Beauty Shops'
  },
  {
    value: '7251',
    title: '7251 - Hat Cleaning Shops, Shoe Repair Shops, Shoe Shine Parlors'
  },
  {
    value: '7261',
    title: '7261 - Funeral Service and Crematories'
  },
  {
    value: '7273',
    title: '7273 - Dating Services'
  },
  {
    value: '7276',
    title: '7276 - Tax Preparation Service'
  },
  {
    value: '7277',
    title: '7277 - Debt, Marriage, Personal-Counseling Services'
  },
  {
    value: '7278',
    title: '7278 - Buying/Shopping Clubs, Services'
  },
  {
    value: '7280',
    title: '7280 - Hospital Patient-Personal Funds Withdrawal'
  },
  {
    value: '7295',
    title: '7295 - Babysitting Services'
  },
  {
    value: '7296',
    title: '7296 - Clothing Rental-Costumes, Uniforms and Formal Wear'
  },
  {
    value: '7297',
    title: '7297 - Massage Parlors'
  },
  {
    value: '7298',
    title: '7298 - Health and Beauty Spas'
  },
  {
    value: '7299',
    title: '7299 - Other Services-Not Elsewhere Classified'
  },
  {
    value: '7311',
    title: '7311 - Advertising Services'
  },
  {
    value: '7321',
    title: '7321 - Consumer Credit Reporting Agencies'
  },
  {
    value: '7332',
    title: '7332 - Blueprinting and Photocopying Services'
  },
  {
    value: '7333',
    title: '7333 - Commercial Art, Graphics, Photography'
  },
  {
    value: '7338',
    title: '7338 - Quick Copy, Reproduction and Blueprinting Services'
  },
  {
    value: '7339',
    title: '7339 - Stenographic and Secretarial Support Services'
  },
  {
    value: '7342',
    title: '7342 - Exterminating and Disinfecting Services'
  },
  {
    value: '7349',
    title: '7349 - Cleaning and Maintenance, Janitorial Services'
  },
  {
    value: '7361',
    title: '7361 - Employment Agencies and Temporary Help Services'
  },
  {
    value: '7372',
    title: '7372 - Computer Programming, Data Processing and Integrated System Design Services'
  },
  {
    value: '7375',
    title: '7375 - Information Retrieval Services'
  },
  {
    value: '7379',
    title: '7379 - Computer Maintenance, Repair And Services-Not Elsewhere Classified'
  },
  {
    value: '7392',
    title: '7392 - Consulting, Management and Public Relations Services'
  },
  {
    value: '7393',
    title:
      '7393 - Detective Agencies, Protective Agencies, Security Services including Armored Cars, Guard Dogs'
  },
  {
    value: '7394',
    title: '7394 - Equipment Rental and Leasing Services, Furniture Rental, Tool Rental'
  },
  {
    value: '7395',
    title: '7395 - Photo Developing, Photofinishing Laboratories'
  },
  {
    value: '7399',
    title: '7399 - Business Services Not Elsewhere Classified'
  },
  {
    value: '7512',
    title: '7512 - Automobile Rental Agency-Not Elsewhere Classified'
  },
  {
    value: '7513',
    title: '7513 - Truck Rental'
  },
  {
    value: '7519',
    title: '7519 - Motor Home and Recreational Vehicle Rental'
  },
  {
    value: '7523',
    title: '7523 - Automobile Parking Lots and Garages'
  },
  {
    value: '7524',
    title: '7524 - Express Payment Service Merchants–Parking Lots and Garages'
  },
  {
    value: '7531',
    title: '7531 - Automotive Body Repair Shops'
  },
  {
    value: '7534',
    title: '7534 - Tire Retreading and Repair Shops'
  },
  {
    value: '7535',
    title: '7535 - Automotive Paint Shops'
  },
  {
    value: '7538',
    title: '7538 - Automotive Service Shops'
  },
  {
    value: '7542',
    title: '7542 - Car Washes'
  },
  {
    value: '7549',
    title: '7549 - Towing Services'
  },
  {
    value: '7622',
    title: '7622 - Electronic Repair Shops'
  },
  {
    value: '7623',
    title: '7623 - Air Conditioning and Refrigeration Repair Shops'
  },
  {
    value: '7629',
    title: '7629 - Appliance Repair Shops, Electrical and Small'
  },
  {
    value: '7631',
    title: '7631 - Clock, Jewelry and Watch Repair Shops'
  },
  {
    value: '7641',
    title: '7641 - Furniture-Reupholstery, Repair and Refinishing'
  },
  {
    value: '7692',
    title: '7692 - Welding Repair'
  },
  {
    value: '7699',
    title: '7699 - Miscellaneous Repair Shops and Related Services'
  },
  {
    value: '7800',
    title: '7800 - Government Owned Lottery'
  },
  {
    value: '7801',
    title: '7801 - Government-Licensed Casinos (Online or Internet Gambling)'
  },
  {
    value: '7802',
    title: '7802 - Government-Licensed Horse/Dog Racing'
  },
  {
    value: '7829',
    title: '7829 - Motion Picture and Video Tape Production and Distribution'
  },
  {
    value: '7832',
    title: '7832 - Motion Picture Theaters'
  },
  {
    value: '7833',
    title: '7833 - Express Payment Service — Motion Picture Theater'
  },
  {
    value: '7841',
    title: '7841 - DVD/Video Tape Rental Stores'
  },
  {
    value: '7911',
    title: '7911 - Dance Halls, Schools and Studios'
  },
  {
    value: '7922',
    title: '7922 - Theatrical Producers (Except Motion Pictures), Ticket Agencies'
  },
  {
    value: '7929',
    title: '7929 - Bands, Orchestras and Miscellaneous Entertainers- Not Elsewhere Classified'
  },
  {
    value: '7932',
    title: '7932 - Pool and Billiard Establishments'
  },
  {
    value: '7933',
    title: '7933 - Bowling Alleys'
  },
  {
    value: '7941',
    title: '7941 - Athletic Fields, Commercial Sports, Professional Sports Clubs, Sports Promoters'
  },
  {
    value: '7991',
    title: '7991 - Tourist Attractions and Exhibits'
  },
  {
    value: '7992',
    title: '7992 - Golf Courses, Public'
  },
  {
    value: '7993',
    title: '7993 - Video Amusement Game Supplies'
  },
  {
    value: '7994',
    title: '7994 - Video Game Arcades and Establishments'
  },
  {
    value: '7995',
    title:
      '7995 - Gambling Transactions Betting (Sportsbook, fantasy, social gaming; when regulated and not covered by other MCCs) (D)'
  },
  {
    value: '7996',
    title: '7996 - Amusement Parks, Carnivals, Circuses, Carnivals, Fortune Tellers'
  },
  {
    value: '7997',
    title:
      '7997 - Clubs-Country Clubs, Membership (Athletic, Recreation, Sports), Private Golf Courses'
  },
  {
    value: '7998',
    title: '7998 - Aquariums, Dolphinariums, Zoos and Seaquariums'
  },
  {
    value: '7999',
    title: '7999 - Recreation Services-Not Elsewhere Classified'
  },
  {
    value: '8011',
    title: '8011 - Doctors-not elsewhere classified'
  },
  {
    value: '8021',
    title: '8021 - Dentists and Orthodontists'
  },
  {
    value: '8031',
    title: '8031 - Osteopathic Physicians'
  },
  {
    value: '8041',
    title: '8041 - Chiropractors'
  },
  {
    value: '8042',
    title: '8042 - Optometrists and Ophthalmologists'
  },
  {
    value: '8043',
    title: '8043 - Opticians, Optical Goods and Eyeglasses'
  },
  {
    value: '8044',
    title: '8044 - Optical Goods and Eyeglasses'
  },
  {
    value: '8049',
    title: '8049 - Chiropodists, Podiatrists'
  },
  {
    value: '8050',
    title: '8050 - Nursing and Personal Care Facilities'
  },
  {
    value: '8062',
    title: '8062 - Hospitals'
  },
  {
    value: '8071',
    title: '8071 - Dental and Medical Laboratories'
  },
  {
    value: '8099',
    title: '8099 - Health Practitioners, Medical Services-Not Elsewhere Classified'
  },
  {
    value: '8111',
    title: '8111 - Attorneys, Legal Services'
  },
  {
    value: '8211',
    title: '8211 - Schools, Elementary and Secondary'
  },
  {
    value: '8220',
    title: '8220 - Colleges, Universities, Professional Schools and Junior Colleges'
  },
  {
    value: '8241',
    title: '8241 - Schools, Correspondence'
  },
  {
    value: '8244',
    title: '8244 - Schools, Business and Secretarial'
  },
  {
    value: '8249',
    title: '8249 - Schools, Trade and Vocational'
  },
  {
    value: '8299',
    title: '8299 - Schools And Educational Services-Not Elsewhere Classified'
  },
  {
    value: '8351',
    title: '8351 - Child Care Services'
  },
  {
    value: '8398',
    title: '8398 - Organizations, Charitable and Social Service'
  },
  {
    value: '8641',
    title: '8641 - Associations-Civic, Social and Fraternal'
  },
  {
    value: '8651',
    title: '8651 - Organizations, Political'
  },
  {
    value: '8661',
    title: '8661 - Organizations, Religious'
  },
  {
    value: '8675',
    title: '8675 - Automobile Associations'
  },
  {
    value: '8699',
    title: '8699 - Organizations, Membership-Not Elsewhere Classified'
  },
  {
    value: '8734',
    title: '8734 - Testing Laboratories (Non-Medical)'
  },
  {
    value: '8743',
    title: '8743 - Testing Laboratories (Non-Medical)'
  },
  {
    value: '8911',
    title: '8911 - Architectural, Engineering and Surveying Services'
  },
  {
    value: '8931',
    title: '8931 - Accounting, Auditing and Bookkeeping Services'
  },
  {
    value: '8999',
    title: '8999 - Professional Services-Not Elsewhere Classified'
  },
  {
    value: '9034',
    title: '9034 - I-Purchasing Pilot'
  },
  {
    value: '9211',
    title: '9211 - Court Costs Including Alimony and Child Support'
  },
  {
    value: '9222',
    title: '9222 - Fines'
  },
  {
    value: '9223',
    title: '9223 - Bail and Bond Payments'
  },
  {
    value: '9311',
    title: '9311 - Tax Payments'
  },
  {
    value: '9399',
    title: '9399 - Government Services-Not Elsewhere Classified'
  },
  {
    value: '9401',
    title: '9401 - I-Purchasing Pilot'
  },
  {
    value: '9402',
    title: '9402 - Postal Services-Government Only'
  },
  {
    value: '9405',
    title: '9405 - Intra-Government Purchases-Government Only'
  },
  {
    value: '9700',
    title: '9700 - Automated Referral Service'
  },
  {
    value: '9701',
    title: '9701 - Visa Credential Server'
  },
  {
    value: '9702',
    title: '9702 - GCAS Emergency Services'
  },
  {
    value: '9751',
    title: '9751 - UK Supermarkets, Electronic Hot File'
  },
  {
    value: '9752',
    title: '9752 - UK Petrol Stations, Electronic Hot File'
  },
  {
    value: '9754',
    title: '9754 - Gambling-Horse, Dog Racing, State Lottery'
  },
  {
    value: '9950',
    title: '9950 - Intra-Company Purchases'
  }
];

export default mccList;
