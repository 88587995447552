import { isEmpty } from '@f1/shared/src/_helpers';

const getEmployeeName = (first, last) => {
  const name = `${first || ''} ${last || ''}`;
  return isEmpty(name) ? '' : name;
};

const employeeTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0' && schema instanceof Array) {
      return schema.map((employee) => ({
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        manager: employee.manager,
        employeeId: employee.employeeId,
        groups: employee.groups,
        activated: employee.activated,
        disabled: employee.disabled,
        preferredCrabEscalationEmployee: {
          email: employee?.preferredCrabEscalationEmployee?.email,
          firstName: employee?.preferredCrabEscalationEmployee?.firstName,
          lastName: employee?.preferredCrabEscalationEmployee?.lastName,
          employeeId: employee?.preferredCrabEscalationEmployee?.employeeId
        }
      }));
    }
    return [];
  },
  // former HR page
  forIt: (schema, version) => {
    if (version === '1.0' && schema instanceof Array) {
      return schema.map((employee) => ({
        ...employee,
        managerName: findManagerName(employee.manager, schema),
        deleteDelegate: employee.employeeId,
        edit: employee.employeeId,
        'Crab Escalation Employee': getEmployeeName(
          employee.preferredCrabEscalationEmployee.firstName,
          employee.preferredCrabEscalationEmployee.lastName
        )
      }));
    }
    return [];
  },
  frontendEmployeeDetailsEdit: (schema, version) => {
    if (version === '1.0') {
      const { employee, groups, employeeGroups, employeeToEdit } = schema || {};
      const mappedGroups = (groups || []).map((group) => ({
        title: group.employeeGroupName,
        value: group.employeeGroupId
      }));
      const checkedGroups =
        mappedGroups.reduce((acc, sel) => ({ ...acc, [sel.value]: true }), {}) || {};
      const isTriageEmployee = employeeGroups.some(
        (group) => employeeToEdit.employeeId === group?.triageEmployee?.employeeId
      );
      return {
        firstName: employee?.firstName,
        lastName: employee?.lastName,
        email: employee?.email,
        manager: employee?.manager,
        crabEscalationEmployee: employee?.preferredCrabEscalationEmployee?.employeeId,
        checkedGroups,
        isTriageEmployee
      };
    }
    return {};
  },
  backendRootAndMember: (schema, version) => {
    if (version === '1.0') {
      const { employeeFields, employeeGroupIds, employeeId } = schema;
      return {
        root: {
          employee: {
            email: employeeFields?.email,
            firstName: employeeFields?.firstName,
            lastName: employeeFields?.lastName,
            manager: employeeFields?.manager,
            preferredCrabEscalationEmployee: {
              employeeId: !isEmpty(employeeFields?.crabEscalationEmployee)
                ? employeeFields?.crabEscalationEmployee
                : undefined
            }
          }
        },
        member: {
          employee: {
            employeeId
          },
          triageEmployee: employeeFields?.triageEmployee === true
        },
        employeeGroupIdList: Object.keys(employeeGroupIds || {})
      };
    }
    return {};
  }
};

const findManagerName = (idToMatch, dataSet) => {
  const name = dataSet.find((employee) => employee.employeeId === idToMatch);
  return `${name?.firstName || ''} ${name?.lastName || ''}`;
};

export default employeeTemplate;
