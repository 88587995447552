import { mockEmployees } from '../employee/mockEmployees';
import { ticketGuids } from '../guids';

export const mockTicketHistory = {
  history01: {
    dataChanged: 'Created ticket',
    employeeId: mockEmployees.employee01.employeeId,
    timestamp: '2020-08-17T20:56:19Z'
  },
  history02: {
    dataChanged: 'Assigned ticket',
    employeeId: mockEmployees.employee01.employeeId,
    timestamp: '2020-08-17T20:57:19Z'
  },
  history03: {
    dataChanged: 'Assigned ticket',
    employeeId: mockEmployees.employee02.employeeId,
    timestamp: '2020-08-18T20:56:19Z'
  },
  history04: {
    dataChanged: 'Assigned ticket',
    employeeId: mockEmployees.employee01.employeeId,
    timestamp: '2020-08-19T20:56:19Z'
  },
  history05: {
    dataChanged: 'Assigned ticket',
    employeeId: mockEmployees.employee02.employeeId,
    timestamp: '2020-08-20T20:56:19Z'
  },
  history06: {
    dataChanged: 'Assigned ticket',
    employeeId: mockEmployees.employee01.employeeId,
    timestamp: '2020-08-21T20:56:19Z'
  },
  history07: {
    dataChanged: 'Assigned ticket',
    employeeId: mockEmployees.employee02.employeeId,
    timestamp: '2020-08-22T20:56:19Z'
  },
  history08: {
    dataChanged: 'Assigned ticket',
    employeeId: mockEmployees.employee01.employeeId,
    timestamp: '2020-08-23T20:56:19Z'
  },
  history09: {
    dataChanged: 'Marked ticket in progress',
    employeeId: mockEmployees.employee03.employeeId,
    timestamp: '2020-08-23T21:01:19Z'
  },
  history10: {
    dataChanged: 'Closed ticket',
    employeeId: mockEmployees.employee03.employeeId,
    timestamp: '2020-08-23T21:11:19Z'
  }
};
export const ticketHistoryResponseArray = [
  mockTicketHistory.history01,
  mockTicketHistory.history02,
  mockTicketHistory.history03,
  mockTicketHistory.history04,
  mockTicketHistory.history05,
  mockTicketHistory.history06,
  mockTicketHistory.history07,
  mockTicketHistory.history08,
  mockTicketHistory.history09,
  mockTicketHistory.history10
];

export const mockTicketHistoryRes = {
  [ticketGuids.default]: ticketHistoryResponseArray,
  [ticketGuids.merchantGuid]: ticketHistoryResponseArray,
  [ticketGuids.partnerId]: ticketHistoryResponseArray,
  [ticketGuids.relationshipId]: ticketHistoryResponseArray
};

export const ticketHistoryFormatted = [
  {
    timestamp: '2020-08-17T20:56:19Z',
    date: '08/17/2020, 03:56 PM CDT',
    description: 'Created ticket',
    employeeName: 'Taylor Swift'
  },
  {
    timestamp: '2020-08-17T20:57:19Z',
    date: '08/17/2020, 03:57 PM CDT',
    description: 'Assigned ticket',
    employeeName: 'Taylor Swift'
  },
  {
    timestamp: '2020-08-18T20:56:19Z',
    date: '08/18/2020, 03:56 PM CDT',
    description: 'Assigned ticket',
    employeeName: 'James Taylor'
  },
  {
    timestamp: '2020-08-19T20:56:19Z',
    date: '08/19/2020, 03:56 PM CDT',
    description: 'Assigned ticket',
    employeeName: 'Taylor Swift'
  },
  {
    timestamp: '2020-08-20T20:56:19Z',
    date: '08/20/2020, 03:56 PM CDT',
    description: 'Assigned ticket',
    employeeName: 'James Taylor'
  },
  {
    timestamp: '2020-08-21T20:56:19Z',
    date: '08/21/2020, 03:56 PM CDT',
    description: 'Assigned ticket',
    employeeName: 'Taylor Swift'
  },
  {
    timestamp: '2020-08-22T20:56:19Z',
    date: '08/22/2020, 03:56 PM CDT',
    description: 'Assigned ticket',
    employeeName: 'James Taylor'
  },
  {
    timestamp: '2020-08-23T20:56:19Z',
    date: '08/23/2020, 03:56 PM CDT',
    description: 'Assigned ticket',
    employeeName: 'Taylor Swift'
  },
  {
    timestamp: '2020-08-23T21:01:19Z',
    date: '08/23/2020, 04:01 PM CDT',
    description: 'Marked ticket in progress',
    employeeName: 'Jesse James'
  },
  {
    timestamp: '2020-08-23T21:11:19Z',
    date: '08/23/2020, 04:11 PM CDT',
    description: 'Closed ticket',
    employeeName: 'Jesse James'
  }
];
