import React from 'react';
import PropTypes from 'prop-types';
import { tooltipCSS } from './_styles';
import { getType } from './_helpers';
import { formatDescriptionText } from './_templateHelpers';

export class ToolTipTip extends React.Component {
  constructor(props) {
    super(props);
    this.tip = React.createRef();
    this.state = {};
  }

  componentWillUnmount() {
    this.mounted = false;
    const tip = this.tip.current;
    if (tip) {
      tip.style.height = null;
      tip.style.width = null;
      // needed for mobile when you tap outside of the tooltip
      document.querySelector('body').style.overflow = null;
    }
  }

  render() {
    const { children, tooltipStyle, revealed, isHtml } = this.props;
    return (
      <div
        ref={this.tip}
        className="tip"
        style={{
          ...tooltipCSS().base,
          ...tooltipCSS()[tooltipStyle.type],
          ...(revealed ? tooltipCSS().on : tooltipCSS().off),
          ...(tooltipStyle.height && { width: `${tooltipStyle.height}px` }),
          ...(tooltipStyle.width && { width: `${tooltipStyle.width}px` }),
          ...(tooltipStyle.x && { left: `${tooltipStyle.x}px` }),
          ...(tooltipStyle.y && { top: `${tooltipStyle.y}px` })
        }}>
        <div
          style={{
            ...tooltipCSS().arrow.base,
            ...tooltipCSS().arrow[tooltipStyle.type]
          }}
        />
        {!isHtml && revealed && (
          <div className="tipText" style={tooltipCSS().inner}>
            {React.Children.count(children) > 1 ? (
              children.map((child, i) => <div key={`${i.toString()}`}>{child}</div>)
            ) : (
              <div>
                {getType(children) === 'string'
                  ? formatDescriptionText(children, { useSingleLineBreak: true })
                  : children}
              </div>
            )}
          </div>
        )}
        {isHtml && revealed && <div style={tooltipCSS().htmlWrap}>{children}</div>}
      </div>
    );
  }
}

ToolTipTip.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipStyle: PropTypes.shape({
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string
  }),
  revealed: PropTypes.bool,
  isHtml: PropTypes.bool
};
ToolTipTip.defaultProps = {
  children: null,
  width: null,
  tooltipStyle: {
    x: 0,
    y: 0,
    width: null,
    height: null,
    type: null
  },
  revealed: false,
  isHtml: false
};

export default ToolTipTip;
