import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Button, DataBox, NoData } from '../index';
import DataList from './DataList';
import { getType, isEmpty } from './_helpers';
import { FlexBoxWrap } from '../css/_styledComponents';

export const DataBoxList = (props) => {
  const {
    buttonCallback = null,
    buttonIcon = null,
    children = null,
    className = null,
    collapsible = false,
    data = null,
    hideEmptyValues = false,
    id = null,
    inset = false,
    itemButtonCallback = null,
    itemButtonIcon = null,
    itemStyle = {},
    itemTitle = null,
    minimized = false,
    sort = false,
    style = {},
    title = null,
    type = null
  } = props;

  const isArray = (dataItem) =>
    getType(dataItem) === 'array' || getType(dataItem?.data) === 'array';

  const handleClickItemButton = (item) => {
    let cbData = isEmpty(item) ? {} : item;
    if (isArray(item)) {
      const match = item.find(([key, _value]) => key === 'dataListItem');
      const [key, value] = match || [];
      if (!isEmpty(key) && !isEmpty(value)) {
        cbData = value;
      }
    }
    itemButtonCallback(cbData);
  };

  return (
    <DataBox
      role="article"
      aria-label={`${itemTitle || title} databox list`}
      id={id}
      className={className}
      type={type}
      title={title}
      inset={inset}
      collapsible={collapsible}
      minimized={collapsible ? minimized : false}
      style={{ height: 'fit-content', marginTop: '10px', ...style }}
      buttonCallback={buttonCallback}
      buttonIcon={buttonIcon}>
      {children}
      <FlexBoxWrap>
        {!isEmpty(data) ? (
          data.map((item, index) => (
            <div key={uuidv4()} style={{ flex: '1', ...(item.dataListItemStyle || itemStyle) }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  ...(itemButtonCallback &&
                    itemButtonIcon && {
                      backgroundColor: 'var(--color-air)',
                      borderTopLeftRadius: 'var(--radius-small)',
                      borderTopRightRadius: 'var(--radius-small)',
                      padding: '0 0.3em'
                    })
                }}>
                <strong style={{ fontSize: '1.2rem' }}>
                  {item.dataListItemTitle || `${itemTitle || ''} ${index + 1}`}
                </strong>
                {itemButtonCallback && itemButtonIcon ? (
                  <Button
                    className="dataBoxListItemButton"
                    aria-label="Databox list item button"
                    onClick={() => handleClickItemButton(item)}
                    size="sm"
                    type="text"
                    icon={itemButtonIcon}
                  />
                ) : null}
              </div>
              <DataList
                data={isArray(item) ? item : Object.entries(item.data || item)}
                hideEmptyValues={hideEmptyValues}
                sort={sort}
                style={{
                  ...(itemButtonCallback &&
                    itemButtonIcon && {
                      border: '1px solid var(--color-air)',
                      borderBottomLeftRadius: 'var(--radius-small)',
                      borderBottomRightRadius: 'var(--radius-small)'
                    })
                }}
              />
            </div>
          ))
        ) : (
          <NoData />
        )}
      </FlexBoxWrap>
    </DataBox>
  );
};

DataBoxList.propTypes = {
  buttonCallback: PropTypes.func,
  buttonIcon: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array]),
  hideEmptyValues: PropTypes.bool,
  id: PropTypes.string,
  inset: PropTypes.bool,
  itemButtonCallback: PropTypes.func,
  itemButtonIcon: PropTypes.oneOfType([PropTypes.object]),
  itemStyle: PropTypes.oneOfType([PropTypes.object]),
  itemTitle: PropTypes.string,
  minimized: PropTypes.bool,
  sort: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string,
  type: PropTypes.string
};

export default DataBoxList;
