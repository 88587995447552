import { createMockVarList } from '../merchant/varList';

export const mockMerchantVarGet = (body, params, options) => {
  const { guid, staticMap } = options || {};
  const overridesArray = (staticMap && staticMap[guid]) || undefined;
  const mockData = createMockVarList(overridesArray, options);
  return { [guid]: { varList: mockData } };
};

export const mockVarPut = (body, params, options) => ({
  [options?.guid]: { varId: `mock-var-id-1` }
});

export default mockMerchantVarGet;
