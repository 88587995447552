import { transformData } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import relationshipV2DetailTemplate from './templates/relationshipV2DetailTemplate';
import { relationshipGuids, partnerGuids } from '../../guids';

const formatGETData = (data) =>
  data
    ? transformData({
        data: { data, options: { isInternal: true } },
        toSchema: 'frontend',
        template: relationshipV2DetailTemplate,
        version: '1.0'
      })
    : {};

const mockAgentChain = () =>
  getMockValue({
    type: 'number',
    stringify: true,
    min: 100000,
    max: 999999
  });

// this is the backend response to the GET request
// if a field is does not have a value, the BE will not send an empty or null field
const mockRelationships = {
  [relationshipGuids.default]: {
    relationship: {
      relationshipId: relationshipGuids.default,
      relationshipCode: 123456,
      relationshipName: 'Partner with Transaction Report Data',
      parentRelationshipId: relationshipGuids.relationship05,
      childPartnerId: partnerGuids.partner02,
      bankName: 'CYNERGY',
      processorName: 'FIRST_DATA',
      repaySortCode: null,
      processorFrontEnd: 'test',
      paymentGateway: 'NMI',
      gatewayAffiliateId: 'f1_house',
      riskProfile: 'preferred',
      inactive: false
    }
  },
  [relationshipGuids.relationship02]: {
    // must have processorName === `REPAY` for testing purposes
    relationship: {
      relationshipId: relationshipGuids.relationship02,
      relationshipCode: 189101,
      relationshipName: '0CsKQFgXSYEb4XH',
      parentRelationshipId: relationshipGuids.relationship10,
      childPartnerId: partnerGuids.partner03,
      bankName: 'MVB',
      processorName: 'REPAY',
      repaySortCode: 'G0010001001001',
      processorFrontEnd: 'test',
      paymentGateway: 'PAYEEZY',
      riskProfile: 'standard',
      inactive: false,
      agentChain: mockAgentChain()
    }
  },
  [relationshipGuids.relationship03]: {
    relationship: {
      relationshipId: relationshipGuids.relationship04,
      relationshipCode: 112131,
      relationshipName: 'CrmCreateRelationshipFT',
      parentRelationshipId: relationshipGuids.relationship05,
      childPartnerId: partnerGuids.default,
      bankName: 'CYNERGY',
      processorName: 'FIRST_DATA',
      repaySortCode: null,
      processorFrontEnd: 'test',
      paymentGateway: 'OMAHA',
      riskProfile: 'elevated',
      inactive: false
    }
  },
  [relationshipGuids.relationship11]: {
    relationship: {
      relationshipId: relationshipGuids.relationship11,
      relationshipCode: '20010',
      relationshipName: 'Mock Preferred Risk - Repay Relationship',
      parentRelationshipId: relationshipGuids.relationship05,
      childPartnerId: partnerGuids.partner04,
      bankName: 'MVB',
      processorName: 'REPAY',
      repaySortCode: null,
      processorFrontEnd: 'test',
      paymentGateway: 'OMAHA',
      riskProfile: 'preferred',
      inactive: false,
      agentChain: mockAgentChain()
    }
  },
  [relationshipGuids.relationship12]: {
    relationship: {
      relationshipId: relationshipGuids.relationship12,
      relationshipCode: '20011',
      relationshipName: 'Mock Netevia Evolve Relationship',
      parentRelationshipId: relationshipGuids.relationship05,
      childPartnerId: partnerGuids.partner09, // no crab config options
      bankName: 'EVOLVE',
      processorName: 'NETEVIA',
      repaySortCode: null,
      processorFrontEnd: 'test',
      paymentGateway: 'OMAHA',
      riskProfile: 'preferred',
      inactive: false
    }
  },
  [relationshipGuids.relationship14]: {
    relationship: {
      relationshipId: relationshipGuids.relationship14,
      relationshipCode: '20013',
      relationshipName: 'Mock Elevated Risk - Repay Relationship',
      parentRelationshipId: relationshipGuids.relationship05,
      childPartnerId: partnerGuids.partner04,
      bankName: 'MVB',
      processorName: 'REPAY',
      repaySortCode: null,
      processorFrontEnd: 'test',
      paymentGateway: 'OMAHA',
      riskProfile: 'elevated',
      inactive: false,
      agentChain: mockAgentChain()
    }
  },
  [relationshipGuids.relationship15]: {
    relationship: {
      relationshipId: relationshipGuids.relationship15,
      relationshipCode: '11116',
      relationshipName: 'FE - Netevia Elevated',
      parentRelationshipId: relationshipGuids.relationship05,
      childPartnerId: partnerGuids.partner09, // no crab config options
      bankName: 'EVOLVE',
      processorName: 'NETEVIA',
      repaySortCode: null,
      processorFrontEnd: 'test',
      paymentGateway: 'NMI',
      gatewayAffiliateId: 'f1_house',
      riskProfile: 'elevated',
      inactive: false
    }
  },
  [relationshipGuids.relationship20]: {
    relationship: {
      relationshipId: relationshipGuids.relationship20,
      relationshipCode: '20020',
      relationshipName: 'FE - Repay Elevated - Fluid Pay',
      parentRelationshipId: relationshipGuids.default,
      childPartnerId: partnerGuids.default,
      bankName: 'CYNERGY',
      processorName: 'REPAY',
      repaySortCode: 'G0010001001001',
      processorFrontEnd: null,
      paymentGateway: 'FLUID_PAY',
      gatewayAffiliateId: 'reddex_cbpd9846lr8qk82v43u0',
      riskProfile: 'elevated',
      inactive: false,
      agentChain: mockAgentChain()
    }
  },
  [relationshipGuids.relationship21]: {
    relationship: {
      relationshipId: relationshipGuids.relationship21,
      relationshipCode: '20021',
      relationshipName: 'FE - Micamp Elevated - Maverick',
      parentRelationshipId: relationshipGuids.default,
      childPartnerId: partnerGuids.default,
      bankName: 'MERRICK',
      processorName: 'MICAMP_PROCESSING_MAVERICK',
      processorFrontEnd: null,
      paymentGateway: 'FLUID_PAY',
      gatewayAffiliateId: 'reddex_cbpd9846lr8qk82v43u0',
      riskProfile: 'elevated',
      inactive: false
    }
  },
  [relationshipGuids.relationship23]: {
    relationship: {
      relationshipId: relationshipGuids.relationship23,
      relationshipCode: '244201',
      relationshipName: 'FE - Priority / Axiom / Elevated',
      parentRelationshipId: relationshipGuids.default,
      childPartnerId: partnerGuids.partner09, // no crab config options
      bankName: 'AXIOM',
      processorName: 'PRIORITY',
      processorFrontEnd: null,
      paymentGateway: 'MULTIPASS',
      gatewayAffiliateId: null,
      riskProfile: 'elevated',
      inactive: false
    }
  },
  [relationshipGuids.relationship24]: {
    relationship: {
      relationshipId: relationshipGuids.relationship24,
      relationshipCode: '232426',
      relationshipName: 'FE - Priority / Wells Fargo / Elevated',
      parentRelationshipId: relationshipGuids.default,
      childPartnerId: partnerGuids.partner09, // no crab config options
      bankName: 'WELLS_FARGO',
      processorName: 'PRIORITY',
      processorFrontEnd: null,
      paymentGateway: 'MULTIPASS',
      gatewayAffiliateId: null,
      riskProfile: 'elevated',
      inactive: false
    }
  }
};

// this is the response that the backend sends on GET request
export const mockRelationshipV2DetailGetRes = { ...mockRelationships };

const createMockRelationshipDetail = (guid) => {
  const mockProcessName = getMockValue({ type: 'processName' });
  const mockRelationship = {
    relationship: {
      relationshipId: guid,
      relationshipCode: getMockValue({
        type: 'number',
        stringify: true,
        min: 10000,
        max: 29999
      }),
      relationshipName: getMockValue({ type: 'string' }),
      parentRelationshipId: getMockValue({ type: 'list', list: Object.values(relationshipGuids) }),
      childPartnerId: getMockValue({ type: 'list', list: Object.values(partnerGuids) }),
      bankName: getMockValue({ type: 'bankName' }),
      processorName: mockProcessName,
      repaySortCode:
        mockProcessName === 'REPAY'
          ? getMockValue({ type: 'list', list: ['G0010001001001', 'G0012001001001'] })
          : null,
      processorFrontEnd: getMockValue({ type: 'string' }),
      paymentGateway: getMockValue({ type: 'paymentGateway' }),
      gatewayAffiliateId: getMockValue({ type: 'string' }),
      riskProfile: getMockValue({ type: 'list', list: ['preferred', 'standard', 'elevated'] }),
      inactive: false,
      ...(mockProcessName === 'REPAY' && { agentChain: mockAgentChain() })
    }
  };
  return mockRelationship;
};

export const mockRelationshipV2DetailGet = (body, params, options) => {
  const { guid } = options || {};
  return {
    [guid]: mockRelationshipV2DetailGetRes[guid] || createMockRelationshipDetail(guid)
  };
};

// this is the response for the frontend to use
export const mockRelationshipV2DetailGetResFormatted = {
  [relationshipGuids.default]: formatGETData(
    mockRelationshipV2DetailGetRes[relationshipGuids.default]
  ),
  [relationshipGuids.relationship02]: formatGETData(
    mockRelationshipV2DetailGetRes[relationshipGuids.relationship02]
  ),
  [relationshipGuids.relationship03]: formatGETData(
    mockRelationshipV2DetailGetRes[relationshipGuids.relationship03]
  ),
  [relationshipGuids.relationship12]: formatGETData(
    mockRelationshipV2DetailGetRes[relationshipGuids.relationship12]
  )
};
