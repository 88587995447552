import React from 'react';
import PropTypes from 'prop-types';
import { loader } from './_styles';
import '../css/animations.css';
import { isEqual } from './_helpers';

class Loader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { store } = this.props;
    store.subscribe(this.handleStoreChanges);
    this.handleStoreChanges();
  }

  componentWillUnmount() {
    const { store } = this.props;
    store && store.subscribe && store.subscribe(this.handleStoreChanges);
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  handleStoreChanges = () => {
    const { store } = this.props;
    const { loading: prevLoading } = this.state;
    const { toggleLoader: { loading } = {} } = store.getState();
    if (!isEqual(prevLoading, loading)) {
      this.updateState(
        {
          loading
        },
        () => {
          store.dispatch({
            type: 'TOGGLE_LOADER',
            loading
          });
        }
      );
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div
        id="loader"
        data-testid="box-loader"
        style={{
          ...(!loading && { display: 'none' }),
          ...loader.wrap,
          ...(loading && loader.loading)
        }}>
        <div className="boxLoader" style={loader.loader} />
      </div>
    );
  }
}

Loader.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func,
    getState: PropTypes.func,
    toggleLoader: PropTypes.shape({ loading: PropTypes.bool }),
    subscribe: PropTypes.func
  })
};
Loader.defaultProps = {
  store: {}
};

export default Loader;
