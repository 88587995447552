// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { stringToRef, isEmpty, isEqual, dropdownDirection } from './_helpers';
import { nestedMenuCSS, input } from './_styles';
import { CustomSearch } from './CustomSearch';
import { NestedMenuItem } from './NestedMenuItem';

export class NestedMenu extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.nestedMenu = React.createRef();
    this.nestedMain = React.createRef();
    this.state = {
      currentStringRef: '',
      menuOpen: false,
      currentMainMenu: [],
      activeSubmenu: [],
      activeSubmenuDba: '',
      direction: 'down'
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('resize', this.checkSize);
    window.addEventListener('click', this.outsideClick);
    window.addEventListener('keyup', (event) => this.handleKeyPress(event));
    const { items, id, nestedMenus } = this.props;
    const refString = !isEmpty(nestedMenus) ? nestedMenus[id] || '' : '';
    const newMenu = this.setMenu(items) || [];
    this.updateState(
      {
        currentMainMenu: newMenu,
        mainMenu: newMenu
      },
      () => this.setActiveSubmenu(refString, { setCurrentGuid: true })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { items } = this.props;
    if (isEmpty(prevProps.items) && !isEmpty(items) && !isEqual(prevProps.items, items)) {
      const newMenu = this.setMenu(items) || [];
      this.updateState(
        {
          currentMainMenu: newMenu,
          mainMenu: newMenu
        },
        () => this.setActiveSubmenu(prevState.currentStringRef || '', { setCurrentGuid: true })
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSize);
    window.removeEventListener('click', this.outsideClick);
    window.removeEventListener('keyup', (event) => this.handleKeyPress(event));
    this.mounted = false;
  }

  getStringToRef = (newRefString) => {
    const { items } = this.props;
    const results = !isEmpty(newRefString) ? stringToRef(items, newRefString) : undefined;
    const refStringValid = !isEmpty(newRefString) && typeof results !== 'undefined';
    return refStringValid ? results : undefined;
  };

  setActiveSubmenu = (refString, options) => {
    const { setCurrentGuid } = options || {};
    const { current, title } = this.props;
    const currentActiveSubmenu = this.getStringToRef(refString);
    const submenuValid = typeof currentActiveSubmenu !== 'undefined';
    if (!isEmpty(currentActiveSubmenu)) {
      const newMenu = setCurrentGuid || submenuValid ? this.setMenu(currentActiveSubmenu) : [];
      this.updateState({
        ...(setCurrentGuid && {
          originalActiveSubmenu: newMenu,
          activeSubmenuDba: current.dba,
          prevActiveSubmenu: newMenu || []
        }),
        ...(submenuValid
          ? {
              currentStringRef: refString || '',
              activeSubmenu: newMenu,
              ...(!setCurrentGuid && { activeSubmenuDba: currentActiveSubmenu[title || 'dba'] })
            }
          : {
              currentStringRef: '',
              activeSubmenu: [],
              activeSubmenuDba: ''
            })
      });
    } else {
      this.updateState({
        currentStringRef: '',
        activeSubmenu: [],
        activeSubmenuDba: ''
      });
    }
  };

  setMenu = (objectPath) => {
    const { items, subKey, merchantKey } = this.props;
    const menu = [];
    const path = objectPath || items || {};
    [subKey, merchantKey].forEach((key) => {
      !isEmpty(path) && !isEmpty(path[key]) && menu.push(...path[key]);
    });
    return menu;
  };

  // TODO: MER-1710, can we remove istanbul ignore next
  /* istanbul ignore next */
  outsideClick = (e) => {
    const { menuOpen } = this.state;
    if (
      e &&
      !e.target.classList.contains('nm') &&
      menuOpen &&
      !this.nestedMenu.current.contains(e.target)
    ) {
      this.closeMenu();
    }
  };

  handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      this.closeMenu();
    }
  };

  checkSize = () => {
    const { menuOpen } = this.state;
    const body = document.querySelector('body');
    if (window.getComputedStyle(body).width.replace('px', '') <= 650) {
      if (menuOpen) {
        body.style.overflow = 'hidden';
        const newDirection = dropdownDirection(this.nestedMain.current);
        this.updateState({ direction: newDirection });
      } else {
        body.style.overflow = 'auto';
      }
    } else {
      /* istanbul ignore next */
      body.style.overflow = 'auto';
    }
  };

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  };

  handleClick = (e, item) => {
    const { callback } = this.props;
    const { currentStringRef } = this.state;
    this.closeMenu({ item });
    this.handleNestingCallback(currentStringRef);
    callback(item);
  };

  handleExplore = (e, value, index) => {
    e.preventDefault();
    const { currentStringRef } = this.state;
    const { subKey } = this.props;
    const newRefString = `${currentStringRef}.${subKey}[${index}]`;
    this.setActiveSubmenu(newRefString);
  };

  handleResetMainMenu = () => {
    const { items } = this.props;
    this.handleBackToMain();
    this.searchMenu({ results: items, value: '' });
  };

  handleBackToMain = () => {
    this.updateState({ currentStringRef: '', activeSubmenu: [], activeSubmenuDba: '' });
  };

  handleBackOne = (e) => {
    const { currentStringRef } = this.state;
    e.preventDefault();
    const newRefString = (currentStringRef || '').replace(/\.[^.]*$/, '');
    this.setActiveSubmenu(newRefString);
  };

  handleNestingCallback = (newRefString) => {
    const { id, nestingCallback } = this.props;
    const newActiveSubmenu = this.getStringToRef(newRefString);
    const submenuValid = typeof newActiveSubmenu !== 'undefined';
    !submenuValid && this.handleBackToMain();
    nestingCallback({ nestedMenus: { [id]: submenuValid ? newRefString : '' } });
  };

  openMenu = () => {
    this.updateState(
      {
        menuOpen: true
      },
      () => {
        const body = document.querySelector('body');
        if (body && window.getComputedStyle(body).width.replace('px', '') <= 650) {
          body.style.overflow = 'hidden';
        }
        const newDirection = dropdownDirection(this.nestedMain.current);
        this.updateState({ direction: newDirection });
      }
    );
  };

  closeMenu = (options) => {
    const { item } = options || {};
    this.updateState((prevState) => ({
      menuOpen: false,
      ...(!isEmpty(item) && {
        activeSubmenuDba: item.dba,
        ...(isEmpty(prevState.searchValue) && {
          activeSubmenu: prevState.activeSubmenu,
          originalActiveSubmenu: prevState.activeSubmenu,
          prevActiveSubmenu: prevState.activeSubmenu || []
        })
      })
    }));
    const body = document.querySelector('body');
    body.style.overflow = 'auto';
  };

  searchMenu = ({ results, value }) => {
    const { subKey } = this.props;
    const searchMenu = !isEmpty(value)
      ? (Array.isArray(results) && results) ||
        (Array.isArray(results[subKey]) && results[subKey]) ||
        []
      : [];
    this.updateState(
      (prevState) => ({
        currentStringRef: '',
        activeSubmenuDba: '',
        activeSubmenu: [],
        originalActiveSubmenu: [],
        prevActiveSubmenu: [],
        currentMainMenu: isEmpty(value) ? prevState.mainMenu || [] : searchMenu || [],
        searchValue: value
      }),
      isEmpty(value) ? () => this.handleNestingCallback('') : null
    );
  };

  render() {
    const {
      menuOpen,
      activeSubmenu,
      currentMainMenu,
      searchValue,
      currentStringRef,
      direction,
      activeSubmenuDba
    } = this.state;
    const {
      page,
      current,
      value,
      items,
      merchantKey,
      label,
      required,
      subKey,
      title,
      nestedMainWrapperStyle,
      wrapperStyle
    } = this.props;
    const menu = currentStringRef || '';
    return (
      <div id="nestedMenu" ref={this.nestedMenu} style={{ ...nestedMenuCSS.wrap, ...wrapperStyle }}>
        <div
          style={{
            ...input.wrap
          }}>
          {label && (
            <div
              style={{
                ...input.label,
                height: '14px',
                lineHeight: '20px',
                margin: '5px 0'
              }}>
              {required && <span style={input.label_required}>* </span>}
              {label}
            </div>
          )}
          <div
            id="merchantSelector"
            style={{
              ...input.input,
              ...nestedMenuCSS.inputBox
            }}
            onClick={this.openMenu}
            onKeyUp={this.handleKeyPress}
            role="button"
            aria-label="Select a Merchant"
            tabIndex={0}>
            {current?.dba || 'Select a Merchant'}
          </div>
        </div>
        <div
          id="nestedMain"
          ref={this.nestedMain}
          style={{
            ...nestedMenuCSS.mainmenuWrap,
            padding: '0 0 60px 0',
            ...(menuOpen && nestedMenuCSS.menuOpen),
            ...(direction === 'up' && nestedMenuCSS.inverted),
            ...(direction === 'full' && nestedMenuCSS.fullHeight),
            ...nestedMainWrapperStyle
          }}>
          <div
            id="nestedInnerScroll"
            style={{
              ...nestedMenuCSS.innerScroll,
              maxHeight: direction === 'full' ? '100%' : '340px'
            }}>
            {((currentMainMenu.length === 0 && activeSubmenu.length === 0) ||
              (!isEmpty(searchValue) && isEmpty(currentMainMenu))) && (
              <div
                style={{
                  ...nestedMenuCSS.noItems,
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}>
                No Results
                <div
                  className="nm back-to-main-menu"
                  style={{
                    color: 'var(--color-primary)',
                    cursor: 'pointer',
                    fontSize: '1.4rem',
                    height: 'auto',
                    padding: '0'
                  }}
                  role="button"
                  aria-label="Go back"
                  tabIndex="0"
                  onKeyUp={this.handleKeyPress}
                  onClick={this.handleResetMainMenu}>
                  Go back
                </div>
              </div>
            )}
            <ul
              id="nestedMainmenu"
              className={activeSubmenu.length === 0 ? 'active' : ''}
              style={{
                ...nestedMenuCSS.ul,
                ...nestedMenuCSS.ulMain,
                maxHeight: direction === 'full' ? '100%' : '340px',
                ...(activeSubmenu.length > 0 && nestedMenuCSS.ulMainMin),
                ...(!isEmpty(nestedMainWrapperStyle?.width) && {
                  width: nestedMainWrapperStyle.width
                })
              }}>
              {currentMainMenu.map(
                (item, index) =>
                  item && (
                    <NestedMenuItem
                      initialValue={
                        !isEmpty(current?.guid) && current.guid === item[value] ? current.guid : ''
                      }
                      key={`${item[value]}_${index.toString()}`}
                      index={index}
                      item={item}
                      clickCallback={this.handleClick}
                      exploreCallback={this.handleExplore}
                      keyPressCallback={this.handleKeyPress}
                      title={title}
                      value={value}
                    />
                  )
              )}
            </ul>
            <ul
              id="nestedSubmenu"
              className={activeSubmenu.length > 0 ? 'active' : ''}
              style={{
                ...nestedMenuCSS.ul,
                ...nestedMenuCSS.ulSub,
                maxHeight: direction === 'full' ? '100%' : '340px',
                ...(activeSubmenu.length > 0 && nestedMenuCSS.subActive),
                ...(!isEmpty(nestedMainWrapperStyle?.width) && {
                  width: nestedMainWrapperStyle.width
                })
              }}>
              <li>
                <div
                  className="backToMain nm"
                  style={{
                    ...nestedMenuCSS.liLink,
                    ...nestedMenuCSS.backToTop
                  }}
                  role="button"
                  aria-label="Back to main menu"
                  tabIndex="0"
                  onKeyUp={this.handleKeyPress}
                  onClick={this.handleBackToMain}>
                  Back to main menu
                </div>
              </li>
              {isEmpty(searchValue) && menu.split('.').length > 2 && (
                <li>
                  <div
                    className="backOne nm"
                    role="button"
                    aria-label={`Back to ${activeSubmenuDba}`}
                    tabIndex="0"
                    onKeyUp={this.handleKeyPress}
                    onClick={this.handleBackOne}
                    style={{
                      ...nestedMenuCSS.liLink,
                      ...nestedMenuCSS.backOne
                    }}>
                    {`Back to ${activeSubmenuDba}`}
                  </div>
                </li>
              )}
              {isEmpty(searchValue) &&
                activeSubmenu.map((item, index) => (
                  <NestedMenuItem
                    initialValue={
                      !isEmpty(current?.guid) && current.guid === item[value] ? current.guid : ''
                    }
                    key={`${item[value]}_${index.toString()}`}
                    index={index}
                    item={item}
                    clickCallback={this.handleClick}
                    exploreCallback={this.handleExplore}
                    keyPressCallback={this.handleKeyPress}
                    title={title}
                    value={value}
                  />
                ))}
            </ul>
          </div>
          <CustomSearch
            id="nested-menu-search"
            closeCallback={this.closeMenu}
            searchCallback={this.searchMenu}
            items={items}
            searchKeys={
              page === 'crm'
                ? [
                    { title: 'dba', value: 'dba' },
                    { title: 'legal', value: 'legalName' },
                    { title: 'mid', value: 'mid' },
                    { title: 'contact', value: 'businessContactName' }
                  ]
                : [
                    { title: 'dba', value: 'dba' },
                    { title: 'mid', value: 'mid' }
                  ]
            }
            subKey={subKey}
            merchantKey={merchantKey}
          />
        </div>
      </div>
    );
  }
}

NestedMenu.propTypes = {
  page: PropTypes.string,
  nestedMainWrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.oneOfType([PropTypes.object]),
  current: PropTypes.shape({
    guid: PropTypes.string,
    dba: PropTypes.string
  }),
  subKey: PropTypes.string,
  merchantKey: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  callback: PropTypes.func,
  id: PropTypes.string,
  nestedMenus: PropTypes.shape({
    partnerTree: PropTypes.string
  }),
  nestingCallback: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool
};

NestedMenu.defaultProps = {
  page: '',
  nestedMainWrapperStyle: {},
  wrapperStyle: {},
  items: {},
  current: {
    guid: '',
    dba: ''
  },
  subKey: 'subPartner',
  merchantKey: 'merchant',
  title: '',
  value: '',
  callback: () => {},
  id: null,
  nestedMenus: {
    partnerTree: ''
  },
  nestingCallback: () => {},
  label: '',
  required: false
};

export default NestedMenu;
