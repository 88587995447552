import React from 'react';
import { footerCSS } from '@f1/shared/src/_styles';
import jokeGenerator from '../data/jokeGenerator';

export class SiteFooter extends React.PureComponent {
  render() {
    const currentJoke = jokeGenerator();
    return (
      <footer id="siteFooter" className="footer" style={footerCSS.wrap}>
        <span style={{ flex: '1' }}>{currentJoke.joke}</span>
        {currentJoke.punchline && (
          <span style={{ flex: '1', textAlign: 'right' }}>{currentJoke.punchline}</span>
        )}
      </footer>
    );
  }
}

export default SiteFooter;
