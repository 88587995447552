// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { withRouter } from './routing/withRouter';
import { HorizontalLoader, Icon, TicketBubble } from '../css/_styledComponents';
import { NavBar } from '../css/_styledNavigation';
import { icons } from '../images/_icons';
import { ignoreCase, isInViewport, simulateClick, expandLinks, isBool, isEmpty } from './_helpers';
import { Hamburger, ToolTip } from '../index';

export class BoundSiteNav extends React.Component {
  constructor(props) {
    super(props);
    this.iconMap = {
      notificationsNone: icons.notificationsNone.default,
      notificationsAlert: icons.notificationsAlert.default
    };
    this.state = {
      hovering: false,
      submenu: null,
      isMobile: false,
      links: [],
      navWidth: 0,
      navListWidth: 0,
      notificationDetailsShow: false,
      mouseX: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('click', this.handleOutsideClick);
    this.setLinks();
    setTimeout(() => {
      this.handleResize();
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    const { hideBellTimestamp, isAuthenticated, headerLinks } = this.props;
    if (
      prevProps.isAuthenticated !== isAuthenticated ||
      JSON.stringify(prevProps.headerLinks) !== JSON.stringify(headerLinks)
    ) {
      this.setLinks();
    }
    hideBellTimestamp > prevProps.hideBellTimestamp && this.openDetails();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('click', this.handleOutsideClick);
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  };

  handleResize = (options) => {
    const { resize } = options || {};
    const nav = document.getElementById('siteNav');
    const navList = document.querySelector('#siteNav > .navUl');
    const { width: navWidth } = nav?.getBoundingClientRect() || {};
    const { width: navListWidth } = navList?.getBoundingClientRect() || {};
    !resize &&
      this.updateState(
        {
          notificationDetailsShow: false,
          isMobile: false
        },
        () => this.setLinks({ resize: true })
      );
    resize &&
      this.updateState(
        (prevState) => ({
          navWidth: navWidth || prevState.navWidth,
          navListWidth: navListWidth || prevState.navListWidth
        }),
        this.isMobile
      );
  };

  isMobile = () => {
    const { navWidth, navListWidth } = this.state;
    const hamburger = document.querySelector('.Hamburger');
    const { elemTop, elemBottom } = isInViewport(hamburger, { fullVisibility: true });
    const navLinks = document.querySelectorAll('#siteNav > .navUl > .navLi') || [];
    const linkWrap = Array.from(navLinks).filter(
      (link) => link?.getBoundingClientRect()?.top > 44
    )?.length;
    const burgerVisible = elemTop !== 0 && elemBottom !== 0;
    const navVisible = navWidth !== 0 && navListWidth !== 0;
    let isMobile = burgerVisible && !navVisible;
    if (linkWrap || (burgerVisible && navVisible)) {
      isMobile = true;
    } else if (!linkWrap && isMobile && navWidth) {
      isMobile = false;
    }
    this.updateState({ isMobile }, this.setLinks);
  };

  setLinks = (options) => {
    const { resize } = options || {};
    const { isMobile } = this.state;
    const { headerLinks } = this.props;
    const formatted = isMobile
      ? expandLinks(headerLinks).reduce((acc, link) => {
          const dupe = acc.find((a) => a.text === link.text);
          return dupe
            ? acc
            : acc.concat({
                type: link.type,
                href: link.href,
                text: link.text,
                notifications: link.notifications
              });
        }, [])
      : headerLinks.map((link) => ({ ...link, type: 'parent' }));
    this.updateState({ links: formatted }, resize ? this.handleResize(options) : null);
  };

  handleOutsideClick = (e) => {
    const { isMobile } = this.state;
    if (!isMobile) {
      // this check is only needed when the mobile menu is in use
      return false;
    }
    const container = document.getElementById('siteNavMenu');
    const hamburger = document.querySelector('.Hamburger');
    const hamburgerIsOpen = hamburger.classList.contains('isActive');
    if (container !== null && hamburgerIsOpen && !hamburger.contains(e.target)) {
      if (!container.hidden && hamburgerIsOpen && !container.contains(e.target)) {
        simulateClick(hamburger);
        return true;
      }
    }
    return false;
  };

  handleClick = (e) => {
    this.updateState({ submenu: null });
    const hamburger = document.querySelector('.Hamburger');
    const hamburgerIsOpen = hamburger.classList.contains('isActive');
    e.currentTarget.id !== 'notificationsLink' &&
      this.updateState({
        // when clicking on another site link, close notifications if it's open
        notificationDetailsShow: false
      });
    if (hamburgerIsOpen) {
      simulateClick(hamburger);
    }
    if (e.currentTarget.id === 'notificationsLink') {
      this.openDetails(e);
    }
  };

  handleEnter = (e) => {
    e.currentTarget.getAttribute('data-sub') &&
      this.updateState({ submenu: e.currentTarget.getAttribute('data-sub') });
    this.updateState({ hovering: e.currentTarget.getAttribute('data-sub') });
  };

  handleLeave = () => {
    this.updateState({ hovering: false, submenu: null });
  };

  openDetails = (e) => {
    this.updateState(
      (prevState) => ({
        notificationDetailsShow: !prevState.notificationDetailsShow,
        mouseX: e?.clientX ?? 0
      }),
      this.handleMouseXCallback
    );
  };

  handleMouseXCallback = () => {
    const { mouseXCallback } = this.props;
    const { mouseX } = this.state;
    mouseXCallback && mouseXCallback({ mouseX });
  };

  render() {
    const {
      myPrevetCountLoading,
      myTicketCountLoading,
      signedInAs,
      customNotificationComponent,
      notificationsLoading,
      notificationData
    } = this.props;
    const { hovering, submenu, isMobile, links, notificationDetailsShow } = this.state;
    const navClassNames = [
      'header',
      ...(isMobile ? ['mobile'] : []),
      `navLinks${links.length}`
    ].join(' ');
    return (
      <React.Fragment>
        {!isEmpty(signedInAs) && (
          <ToolTip
            text={signedInAs}
            iconColor="var(--color-danger)"
            wrapperStyle={{
              position: 'absolute',
              top: '-2px',
              right: '72px',
              color: 'var(--color-danger)'
            }}>
            <div>
              You are currently logged in as user
              <br />
              {signedInAs}
              <br />
              and are seeing exactly what they would see
              <br />
              if they logged in using their permissions.
              <br />
              To change this, please log out and log back in.
            </div>
          </ToolTip>
        )}
        <NavBar id="siteNav" className={navClassNames}>
          <ul id="siteNavMenu" className="navUl">
            {links.map((link) => (
              <React.Fragment key={link.text}>
                {link.text === 'Notifications' && isMobile && <React.Fragment key={link.text} />}
                {(link.text !== 'Notifications' || !isMobile) && (
                  <li
                    className={[
                      'navLi',
                      ...(isMobile ? [link.type] : []),
                      ...(link.submenu ? ['hasSub'] : []),
                      ...(link.text === 'Notifications' ? ['notifications'] : []),
                      ...(link.text === 'Account' ? ['account'] : [])
                    ].join(' ')}
                    id={ignoreCase(link.text)}
                    data-testid={`${ignoreCase(link.text)}-link-list-item`}
                    key={link.text}
                    to={link.href}
                    onMouseEnter={this.handleEnter}
                    onMouseLeave={this.handleLeave}
                    data-sub={link.text}>
                    {link.text === 'Notifications' && !isMobile && (
                      <>
                        {((isEmpty(link.data) && isEmpty(customNotificationComponent)) ||
                          (!isEmpty(customNotificationComponent) && notificationsLoading)) && (
                          <HorizontalLoader style={{ zIndex: '3' }} />
                        )}
                        <Icon
                          icon={
                            !isEmpty(customNotificationComponent)
                              ? this.iconMap[
                                  notificationData?.totalCount
                                    ? 'notificationsNone'
                                    : 'notificationsAlert'
                                ]
                              : link.icon
                          }
                          color="var(--color-header)"
                          $useMask
                          $hoverColor="var(--color-warning)"
                          style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                          }}
                        />
                      </>
                    )}
                    {link.text === 'Account' && !isMobile && (
                      <Icon
                        icon={icons.user().src_white}
                        color={!isEmpty(signedInAs) ? 'var(--color-danger)' : 'var(--color-header)'}
                        $useMask
                        $hoverColor="var(--color-warning)"
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%, -50%)'
                        }}
                      />
                    )}
                    <div className="menuItemWrapper">
                      <div
                        className="linkAndSubmenuWrapper"
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Link
                          id={`${link.text.toLowerCase().replace(' ', '_')}Link`}
                          aria-label={link.text}
                          className="mLink"
                          to={link.href}
                          onClick={link.click ? link.click : (e) => this.handleClick(e)}>
                          <div className="vAlign">
                            <span
                              style={{
                                ...((link.text === 'Account' || link.text === 'Notifications') &&
                                  !isMobile && { display: 'none' })
                              }}>
                              {link.text}
                            </span>
                          </div>
                          {(link.notifications ||
                            link.notificationHasError ||
                            (link.text === 'Notifications' &&
                              !isEmpty(notificationData?.totalCount)) ||
                            (link.text === 'Tickets' && isBool(myTicketCountLoading)) ||
                            (link.text === 'Prevet' && isBool(myPrevetCountLoading))) && (
                            <TicketBubble
                              notifications={
                                link.text === 'Notifications' &&
                                !isEmpty(customNotificationComponent)
                                  ? notificationData?.totalCount || null
                                  : link?.notifications
                              }
                              style={{
                                ...(link.text === 'Notifications' && { marginLeft: '1.25em' }),
                                ...(((link.text === 'Tickets' && isBool(myTicketCountLoading)) ||
                                  (link.text === 'Prevet' && isBool(myPrevetCountLoading))) && {
                                  ...(myTicketCountLoading || myPrevetCountLoading
                                    ? {
                                        padding: '0.2em',
                                        backgroundColor: 'var(--color-button-bg-alternate)'
                                      }
                                    : {
                                        ...(((isEmpty(customNotificationComponent) &&
                                          isEmpty(link.notifications)) ||
                                          (!isEmpty(customNotificationComponent) &&
                                            !notificationData?.totalCount)) && {
                                          backgroundColor: 'transparent'
                                        })
                                      })
                                })
                              }}>
                              {(link.text === 'Tickets' && myTicketCountLoading) ||
                              (link.text === 'Prevet' && myPrevetCountLoading) ? (
                                <div style={{ minWidth: '27px', height: '5px' }}>
                                  <HorizontalLoader
                                    style={{
                                      minWidth: '3px',
                                      minHeight: '3px',
                                      width: '3px',
                                      height: '3px',
                                      position: 'relative',
                                      transform: 'translate(0%, 100%)'
                                    }}
                                  />
                                </div>
                              ) : (
                                <>
                                  {!isEmpty(customNotificationComponent) ? (
                                    <>
                                      {notificationData?.hasApiError
                                        ? '!'
                                        : notificationData?.totalCount}
                                    </>
                                  ) : (
                                    <>{link.notificationHasError ? '!' : link.notifications}</>
                                  )}
                                </>
                              )}
                            </TicketBubble>
                          )}
                        </Link>
                        {link.text === 'Notifications' &&
                          !isEmpty(customNotificationComponent) &&
                          notificationDetailsShow &&
                          customNotificationComponent}
                      </div>
                      {hovering === link.text &&
                        link.customSubmenu &&
                        link.href.includes(link.customSubmenu.href) &&
                        !window.location.pathname.includes(link.customSubmenu.href) && (
                          <React.Fragment>{link.customSubmenu.component}</React.Fragment>
                        )}
                    </div>
                    {link.submenu && (
                      <ul className={`navSubmenu${submenu === link.text ? ' active' : ''}`}>
                        {link.submenu.map((sublink, j) => (
                          <li
                            className="navSubmenuLi"
                            key={`${link.text}_${j.toString()}`}
                            to={sublink.href}>
                            <Link
                              id={`${sublink.text.toLowerCase().replace(' ', '_')}Link`}
                              className="sLink"
                              to={sublink.href}
                              onClick={sublink.click ? sublink.click : (e) => this.handleClick(e)}>
                              <span>{sublink.text}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </NavBar>
        <Hamburger />
      </React.Fragment>
    );
  }
}

BoundSiteNav.propTypes = {
  headerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      submenu: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
      notifications: PropTypes.number
    })
  ),
  isAuthenticated: PropTypes.bool,
  navigate: PropTypes.func,
  resetStore: PropTypes.func,
  signedInAs: PropTypes.string,
  customNotificationComponent: PropTypes.node,
  hideBellTimestamp: PropTypes.number,
  mouseXCallback: PropTypes.func,
  notificationData: PropTypes.oneOfType([PropTypes.object]),
  notificationsLoading: PropTypes.bool,
  myTicketCountLoading: PropTypes.bool,
  myPrevetCountLoading: PropTypes.bool
};

BoundSiteNav.defaultProps = {
  headerLinks: [
    {
      text: '',
      href: '',
      submenu: null,
      notifications: null
    }
  ],
  isAuthenticated: false,
  navigate: () => {},
  resetStore: () => {},
  signedInAs: '',
  // portal only (isPartner only)
  customNotificationComponent: null,
  hideBellTimestamp: null,
  mouseXCallback: null,
  notificationData: null,
  notificationsLoading: null,
  // employee only
  myTicketCountLoading: null,
  myPrevetCountLoading: null
};

const SiteNav = withRouter(BoundSiteNav);

export default SiteNav;
