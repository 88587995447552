import { formatDateForFEView } from '@f1/shared/src/_helpers';
import { alertGuids, merchantGuids, partnerGuids, relationshipGuids } from '../../../guids';
import { mockEmployees } from '../../../employee/mockEmployees';
import { mockEmployeeGroups } from '../../../employee/employeeGroup';
import { mockRiskRules } from '../../rule/root';
import { mockRiskCategories } from './riskCategory/mockRiskCategories';
import { mockRiskSubcategories } from './riskSubcategory/mockRiskSubcategories';
import { mockMerchantDetailsGetRes } from '../../../merchant/v2/mockMerchantsV2';
import mockPartner from '../../../partner/mockPartners';
import mockRelationship from '../../../relationship/mockRelationships';

export const mockAlerts = {
  alerts: [
    {
      riskCategory: mockRiskCategories.riskCategory01.riskCategory,
      riskSubcategoryWithTickets: [
        {
          riskSubcategory: mockRiskSubcategories.riskSubcategory01.riskSubcategory,
          tickets: [
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.default,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.default,
                name: mockPartner.partner01.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.default.riskRuleId,
                name: mockRiskRules.default.name,
                primaryKeyType: 'MERCHANT_GUID'
              },
              relationship: {
                relationshipId: relationshipGuids.default,
                name: mockRelationship.relationship01.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'new ticket',
              name: 'Risk Ticket 01',
              priority: 'severity_4',
              status: 'open',
              wakeUpDate: '-',
              snoozeActivatedDate: '-',
              riskAlertPrimaryKeyValue: merchantGuids.default,
              classification: 'true_positive',
              createdBy: {
                employeeId: undefined,
                employeeFirstName: undefined,
                employeeLastName: undefined,
                employeeEmail: null
              },
              ticketId: alertGuids.default,
              ticketBusinessCode: '1',
              ticketClosedBy: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: true
            },
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee02.employeeId,
                employeeFirstName: mockEmployees.employee02.firstName,
                employeeLastName: mockEmployees.employee02.lastName,
                employeeEmail: mockEmployees.employee02.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.default,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.default,
                name: mockPartner.partner01.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.riskRule01.riskRuleId,
                name: mockRiskRules.riskRule01.name,
                primaryKeyType: 'PARTNER_ID'
              },
              relationship: {
                relationshipId: relationshipGuids.default,
                name: mockRelationship.relationship01.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'new ticket',
              name: 'Risk Ticket 02',
              priority: 'severity_4',
              status: 'open',
              wakeUpDate: '-',
              snoozeActivatedDate: '-',
              riskAlertPrimaryKeyValue: partnerGuids.default,
              classification: 'false_positive',
              createdBy: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              ticketId: alertGuids.alert02,
              ticketBusinessCode: '2',
              ticketClosedBy: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: null
            },
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.default,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.default,
                name: mockPartner.partner01.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.riskRule02.riskRuleId,
                name: mockRiskRules.riskRule02.name,
                primaryKeyType: 'MERCHANT_GUID'
              },
              relationship: {
                relationshipId: relationshipGuids.default,
                name: mockRelationship.relationship01.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'new ticket',
              name: 'Risk Ticket 03',
              priority: 'severity_4',
              status: 'open',
              wakeUpDate: '-',
              snoozeActivatedDate: '-',
              riskAlertPrimaryKeyValue: merchantGuids.default,
              classification: null,
              createdBy: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              ticketId: alertGuids.alert03,
              ticketBusinessCode: '3',
              ticketClosedBy: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: false
            }
          ]
        },
        {
          riskSubcategory: mockRiskSubcategories.riskSubcategory02.riskSubcategory,
          tickets: [
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee04.employeeId,
                employeeFirstName: mockEmployees.employee04.firstName,
                employeeLastName: mockEmployees.employee04.lastName,
                employeeEmail: mockEmployees.employee04.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.default,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.default].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.default,
                name: mockPartner.partner01.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.riskRule03.riskRuleId,
                name: mockRiskRules.riskRule03.name,
                primaryKeyType: 'PARTNER_ID'
              },
              relationship: {
                relationshipId: relationshipGuids.default,
                name: mockRelationship.relationship01.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'new ticket',
              name: 'Risk Ticket 04',
              priority: 'severity_4',
              status: 'open',
              wakeUpDate: '-',
              snoozeActivatedDate: '-',
              riskAlertPrimaryKeyValue: partnerGuids.default,
              classification: 'true_positive',
              createdBy: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              ticketId: alertGuids.alert04,
              ticketBusinessCode: '4',
              ticketClosedBy: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: null
            }
          ]
        }
      ]
    },
    {
      riskCategory: mockRiskCategories.riskCategory02.riskCategory,
      riskSubcategoryWithTickets: [
        {
          riskSubcategory: mockRiskSubcategories.riskSubcategory03.riskSubcategory,
          tickets: [
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee05.employeeId,
                employeeFirstName: mockEmployees.employee05.firstName,
                employeeLastName: mockEmployees.employee05.lastName,
                employeeEmail: mockEmployees.employee05.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.closed,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.closed].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.default,
                name: mockPartner.partner01.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.riskRule01.riskRuleId,
                name: mockRiskRules.riskRule01.name,
                primaryKeyType: 'PERSON_PII_SSN_ID'
              },
              relationship: {
                relationshipId: relationshipGuids.default,
                name: mockRelationship.relationship01.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'new ticket',
              name: 'Risk Ticket 05',
              priority: 'severity_4',
              status: 'open',
              wakeUpDate: '-',
              snoozeActivatedDate: '-',
              riskAlertPrimaryKeyValue: '123445678',
              classification: 'false_positive',
              createdBy: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              ticketId: alertGuids.alert05,
              ticketBusinessCode: '5',
              ticketClosedBy: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: null
            }
          ]
        },
        {
          riskSubcategory: mockRiskSubcategories.riskSubcategory04.riskSubcategory,
          tickets: [
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee06.employeeId,
                employeeFirstName: mockEmployees.employee06.firstName,
                employeeLastName: mockEmployees.employee06.lastName,
                employeeEmail: mockEmployees.employee06.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.closed,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.closed].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.partner02,
                name: mockPartner.partner02.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.riskRule02.riskRuleId,
                name: mockRiskRules.riskRule02.name,
                primaryKeyType: 'MERCHANT_EIN_ID'
              },
              relationship: {
                relationshipId: relationshipGuids.relationship02,
                name: mockRelationship.relationship02.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'new ticket',
              name: 'Risk Ticket 06',
              priority: 'severity_4',
              status: 'open',
              wakeUpDate: '-',
              snoozeActivatedDate: '-',
              riskAlertPrimaryKeyValue: '987654321',
              classification: null,
              createdBy: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              ticketId: alertGuids.alert06,
              ticketBusinessCode: '6',
              ticketClosedBy: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: null
            },
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee06.employeeId,
                employeeFirstName: mockEmployees.employee06.firstName,
                employeeLastName: mockEmployees.employee06.lastName,
                employeeEmail: mockEmployees.employee06.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.closed,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.closed].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.partner02,
                name: mockPartner.partner02.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.riskRule02.riskRuleId,
                name: mockRiskRules.riskRule02.name,
                primaryKeyType: 'MERCHANT_EIN_ID'
              },
              relationship: {
                relationshipId: relationshipGuids.relationship02,
                name: mockRelationship.relationship02.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'Snoozed alert',
              name: 'Risk Ticket 07',
              priority: 'severity_4',
              status: 'snoozed',
              wakeUpDate: '2020-08-05',
              snoozeActivatedDate: '2020-08-02',
              riskAlertPrimaryKeyValue: '987654321',
              classification: null,
              createdBy: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              ticketId: alertGuids.alert10,
              ticketBusinessCode: '6',
              ticketClosedBy: {
                employeeId: mockEmployees.employee01.employeeId,
                employeeFirstName: mockEmployees.employee01.firstName,
                employeeLastName: mockEmployees.employee01.lastName,
                employeeEmail: mockEmployees.employee01.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: null
            },
            {
              assignedEmployee: {
                employeeId: mockEmployees.employee07.employeeId,
                employeeFirstName: mockEmployees.employee07.firstName,
                employeeLastName: mockEmployees.employee07.lastName,
                employeeEmail: mockEmployees.employee07.email
              },
              employeeGroups: [mockEmployeeGroups.risk],
              merchant: {
                merchantGuid: merchantGuids.closed,
                dbaName: mockMerchantDetailsGetRes[merchantGuids.closed].merchant.dbaName
              },
              partner: {
                partnerId: partnerGuids.partner02,
                name: mockPartner.partner02.response.partnerName
              },
              riskRule: {
                riskRuleId: mockRiskRules.riskRule02.riskRuleId,
                name: mockRiskRules.riskRule02.name,
                primaryKeyType: 'MERCHANT_EIN_ID'
              },
              relationship: {
                relationshipId: relationshipGuids.relationship02,
                name: mockRelationship.relationship02.response.relationshipName
              },
              category: 'riskUnqueued',
              description: 'Alert Waking up today',
              name: 'Risk Ticket 08',
              priority: 'severity_3',
              status: 'open',
              wakeUpDate: '2020-08-02',
              snoozeActivatedDate: '2020-08-01',
              riskAlertPrimaryKeyValue: '987654321',
              classification: null,
              createdBy: {
                employeeId: mockEmployees.employee03.employeeId,
                employeeFirstName: mockEmployees.employee03.firstName,
                employeeLastName: mockEmployees.employee03.lastName,
                employeeEmail: mockEmployees.employee03.email
              },
              ticketId: alertGuids.alert11,
              ticketBusinessCode: '7',
              ticketClosedBy: {
                employeeId: mockEmployees.employee02.employeeId,
                employeeFirstName: mockEmployees.employee02.firstName,
                employeeLastName: mockEmployees.employee02.lastName,
                employeeEmail: mockEmployees.employee02.email
              },
              createdTimestamp: '2021-04-22 00:23:04Z',
              approvalActionPayload: {
                classToInvoke: 'do-not-show-this',
                payload: 'under-any-circumstances'
              },
              requiresExtraMonitoring: null
            }
          ]
        }
      ]
    }
  ]
};

export const mockAlertsGet = {
  [mockAlerts.alerts[0].riskCategory]: {
    [mockAlerts.alerts[0].riskSubcategoryWithTickets[0].riskSubcategory]: [
      {
        assignedEmployee:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].employeeGroups,
        merchant: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].merchant,
        partner: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].partner,
        riskRule: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].riskRule,
        relationship: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].relationship,
        category: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].category,
        description: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].description,
        name: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].name,
        priority: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].priority,
        status: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].status,
        wakeUpDate: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].classification,
        createdBy: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].createdBy,
        ticketId: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[0].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[0].riskCategory,
        riskSubcategory: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].riskSubcategory
      },
      {
        assignedEmployee:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].employeeGroups,
        merchant: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].merchant,
        partner: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].partner,
        riskRule: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].riskRule,
        relationship: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].relationship,
        category: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].category,
        description: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].description,
        name: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].name,
        priority: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].priority,
        status: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].status,
        wakeUpDate: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].classification,
        createdBy: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].createdBy,
        ticketId: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[1].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[0].riskCategory,
        riskSubcategory: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].riskSubcategory
      },
      {
        assignedEmployee:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].employeeGroups,
        merchant: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].merchant,
        partner: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].partner,
        riskRule: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].riskRule,
        relationship: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].relationship,
        category: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].category,
        description: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].description,
        name: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].name,
        priority: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].priority,
        status: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].status,
        wakeUpDate: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].classification,
        createdBy: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].createdBy,
        ticketId: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[0].tickets[2].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[0].riskCategory,
        riskSubcategory: mockAlerts.alerts[0].riskSubcategoryWithTickets[0].riskSubcategory
      }
    ],
    [mockAlerts.alerts[0].riskSubcategoryWithTickets[1].riskSubcategory]: [
      {
        assignedEmployee:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].employeeGroups,
        merchant: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].merchant,
        partner: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].partner,
        riskRule: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].riskRule,
        relationship: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].relationship,
        category: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].category,
        description: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].description,
        name: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].name,
        priority: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].priority,
        status: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].status,
        wakeUpDate: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].classification,
        createdBy: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].createdBy,
        ticketId: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[0].riskSubcategoryWithTickets[1].tickets[0].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[0].riskCategory,
        riskSubcategory: mockAlerts.alerts[0].riskSubcategoryWithTickets[1].riskSubcategory
      }
    ]
  },
  [mockAlerts.alerts[1].riskCategory]: {
    [mockAlerts.alerts[1].riskSubcategoryWithTickets[0].riskSubcategory]: [
      {
        assignedEmployee:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].employeeGroups,
        merchant: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].merchant,
        partner: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].partner,
        riskRule: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].riskRule,
        relationship: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].relationship,
        category: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].category,
        description: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].description,
        name: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].name,
        priority: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].priority,
        status: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].status,
        wakeUpDate: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].classification,
        createdBy: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].createdBy,
        ticketId: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[0].tickets[0].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[1].riskCategory,
        riskSubcategory: mockAlerts.alerts[1].riskSubcategoryWithTickets[0].riskSubcategory
      }
    ],
    [mockAlerts.alerts[1].riskSubcategoryWithTickets[1].riskSubcategory]: [
      {
        assignedEmployee:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].employeeGroups,
        merchant: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].merchant,
        partner: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].partner,
        riskRule: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].riskRule,
        relationship: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].relationship,
        category: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].category,
        description: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].description,
        name: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].name,
        priority: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].priority,
        status: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].status,
        wakeUpDate: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].classification,
        createdBy: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].createdBy,
        ticketId: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[0].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[1].riskCategory,
        riskSubcategory: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].riskSubcategory
      },
      {
        assignedEmployee:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].employeeGroups,
        merchant: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].merchant,
        partner: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].partner,
        riskRule: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].riskRule,
        relationship: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].relationship,
        category: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].category,
        description: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].description,
        name: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].name,
        priority: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].priority,
        status: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].status,
        wakeUpDate: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].classification,
        createdBy: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].createdBy,
        ticketId: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[1].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[1].riskCategory,
        riskSubcategory: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].riskSubcategory
      },
      {
        assignedEmployee:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].assignedEmployee,
        employeeGroups:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].employeeGroups,
        merchant: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].merchant,
        partner: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].partner,
        riskRule: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].riskRule,
        relationship: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].relationship,
        category: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].category,
        description: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].description,
        name: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].name,
        priority: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].priority,
        status: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].status,
        wakeUpDate: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].wakeUpDate,
        snoozeActivatedDate:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].snoozeActivatedDate,
        riskAlertPrimaryKeyValue:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].riskAlertPrimaryKeyValue,
        classification:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].classification,
        createdBy: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].createdBy,
        ticketId: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].ticketId,
        ticketBusinessCode:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].ticketBusinessCode,
        ticketClosedBy:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].ticketClosedBy,
        createdTimestamp: formatDateForFEView(
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].createdTimestamp,
          { includeTime: true }
        ),
        requiresExtraMonitoring:
          mockAlerts.alerts[1].riskSubcategoryWithTickets[1].tickets[2].requiresExtraMonitoring,
        riskCategory: mockAlerts.alerts[1].riskCategory,
        riskSubcategory: mockAlerts.alerts[1].riskSubcategoryWithTickets[1].riskSubcategory
      }
    ]
  }
};

export default mockAlerts;
