import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  sortData,
  formatDateForFEView,
  getLocaleString,
  capitalize,
  ignoreCase,
  snakeToTitle,
  isEmpty
} from '../../../_helpers';
import { toBackendValue } from '../../../_templateHelpers';
import { crabTaskPendFieldsPost, crabTaskPendFieldsPut } from '../../../_crabFields';

const pendListTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const {
        pends, // BE response
        userType, // one of: partner, employee
        applicationStatus
      } = schema || {};
      const filteredPends =
        userType === 'partner' && !isEmpty(pends)
          ? pends.filter((pend) => pend.published && ignoreCase(pend.pendStatus) !== 'revisit')
          : pends;
      const pendsList = !isEmpty(filteredPends)
        ? filteredPends.map((pend) => {
            const needsNewSignature =
              applicationStatus === 'waiting_on_signature_post_pends' &&
              pend.requiresNewSignature === true &&
              isEmpty(pend.pendClosedTimestamp);
            const pendStatus = ignoreCase(pend.pendStatus || '');
            const createdByDepartmentTitle = snakeToTitle(
              pend.employeeWhoCreated?.department || ''
            );
            return {
              description: needsNewSignature ? (
                <div style={{ color: 'var(--color-stoplight-red)' }}>{pend.description}</div>
              ) : (
                pend.description
              ),
              status: snakeToTitle(pendStatus),
              ...(userType === 'employee' && {
                createdBy: capitalize(ignoreCase(pend.employeeWhoCreated?.firstName || '')) || '-'
              }),
              created: getLocaleString(pend.pendCreatedTimestamp),
              closed: getLocaleString(pend.pendClosedTimestamp) || '-',
              ...(userType === 'partner' && {
                department: createdByDepartmentTitle
              }),
              ...(userType === 'employee' && {
                published: pend.published || false,
                requiresNewSignature: pend.requiresNewSignature || null
              }),
              // hidden columns
              primaryKey: userType === 'employee' ? pend.applicationPendId : uuidv4(), // since applicationPendId may not be unique across pends (partners)
              employeeId: pend.employeeWhoCreated?.employeeId,
              applicationTaskId: pend.applicationTaskId,
              taskNameEnum: ignoreCase(pend.applicationTaskName || ''),
              ...(userType === 'employee' && {
                createdByDepartmentEnum: ignoreCase(pend.employeeWhoCreated?.department || ''),
                createdByDepartmentTitle
              }),
              applicationPendId: pend.applicationPendId,
              addressedByPartner: isEmpty(pend.pendClosedTimestamp) && pendStatus === 'addressed',
              isClosed: !isEmpty(pend.pendClosedTimestamp),
              fileList: pend.fileList,
              originalBackendPend: pend
            };
          })
        : [];
      return pendsList;
    }
    return schema;
  },
  frontendDownloadPackage: (schema, version) => {
    if (version === '1.0') {
      const { backendData } = schema || {};
      const { pends } = backendData || {};
      const formattedData = !isEmpty(pends)
        ? pends.map((backendItem) => {
            const formattedItem = {
              'Application Pend ID': backendItem?.applicationPendId,
              'Application Task ID': backendItem?.applicationTaskId,
              'Application Task Name': backendItem?.applicationTaskName,
              'Created Timestamp': formatDateForFEView(backendItem?.pendCreatedTimestamp, {
                includeTime: true
              }),
              'Closed Timestamp': formatDateForFEView(backendItem?.pendClosedTimestamp, {
                includeTime: true
              }),
              'Pend Description': backendItem?.description,
              'Pend Status': snakeToTitle(backendItem?.pendStatus || ''),
              'Pend Requires New Signature': backendItem?.requiresNewSignature ? 'Yes' : 'No',
              Published: backendItem?.published ? 'Yes' : 'No',
              'Created By - Employee ID': backendItem?.employeeWhoCreated?.employeeId,
              'Created By - Employee Department': snakeToTitle(
                backendItem?.employeeWhoCreated?.department || ''
              ),
              'Created By - Employee First Name': backendItem?.employeeWhoCreated?.firstName,
              'Created By - Employee Email': backendItem?.employeeWhoCreated?.email
            };
            return formattedItem;
          })
        : [{ 'No pends found': '' }];
      return {
        sheets: [
          {
            title: 'Pends',
            data: sortData(formattedData, 'Closed Timestamp', { direction: 'desc' })
          }
        ]
      };
    }
    return schema;
  },
  frontendNeedsSignature: (schema, version) => {
    if (version === '1.0') {
      const { pends } = schema || {};
      const pendsList = !isEmpty(pends)
        ? pends.reduce((acc, pend) => {
            const unpublished = !pend.published;
            const isOpen = isEmpty(pend.pendClosedTimestamp);
            const needsSignature = pend.requiresNewSignature;
            if (unpublished && needsSignature && isOpen) {
              const formattedPend = {
                applicationPendId: pend.applicationPendId,
                applicationTaskId: pend.applicationTaskId,
                description: pend.description,
                pendStatus: ignoreCase(pend.pendStatus || ''),
                requiresNewSignature: pend.requiresNewSignature
              };
              return acc.concat(formattedPend);
            }
            return acc;
          }, [])
        : [];
      return pendsList;
    }
    return schema;
  },
  frontendEmailPends: (schema, version) => {
    // for EmailPends modal
    if (version === '1.0') {
      const pendList = schema.map((pend) => ({
        title: pend.description,
        value: pend.applicationPendId
      }));
      const pendListCheckedMap = schema.reduce(
        (acc, pend) => ({
          ...acc,
          [pend.applicationPendId]: false
        }),
        {}
      );
      return {
        pendList,
        pendListCheckedMap
      };
    }
    return schema;
  },
  frontendGetActivePends: (schema, version) => {
    if (version === '1.0') {
      const { backendData } = schema || {};
      const { pends } = backendData || {};
      return !isEmpty(pends)
        ? pends.filter((pend) => {
            const { pendStatus } = pend || {};
            return ['initial', 'addressed', 'needs_help', 'repended'].includes(
              ignoreCase(pendStatus)
            );
          })
        : [];
    }
    return schema;
  },
  frontendPendsToCompile: (schema, version) => {
    if (version === '1.0') {
      const { selectedAppTaskList, backendData } = schema || {};
      if (!isEmpty(selectedAppTaskList)) {
        return selectedAppTaskList.filter((task) => {
          const taskStatus = ignoreCase(task.status);
          // uses applicationTaskStatus enums
          return ['pended'].includes(taskStatus);
        });
      }
      const { pends } = backendData || {};
      return !isEmpty(pends)
        ? pends.filter((pend) => {
            const { pendStatus } = pend || {};
            // uses pendStatus enums
            return ['initial', 'repended'].includes(ignoreCase(pendStatus));
          })
        : [];
    }
    return schema;
  },
  backendPut: (schema, version) => {
    if (version === '1.0') {
      const { negativeActionList = [] } = schema;
      return {
        pendFields: {
          negativeActionList: negativeActionList.map((values) => ({ ...values.valuesForBackend })),
          description: schema[crabTaskPendFieldsPut.description.id],
          requiresNewSignature: schema[crabTaskPendFieldsPut.requiresNewSignature.id]
        }
      };
    }
    return schema;
  },
  backendPost: (schema, version) => {
    if (version === '1.0') {
      const requestBody = {
        description: toBackendValue(schema.description, crabTaskPendFieldsPost.description),
        ...(schema.userType === 'employee' && {
          // only employee can see this optional field, so partners should not be editing it
          requiresNewSignature: toBackendValue(
            schema.requiresNewSignature,
            crabTaskPendFieldsPost.requiresNewSignature
          )
        }),
        pendStatus: toBackendValue(schema.pendStatus, crabTaskPendFieldsPost.pendStatus)
      };
      return requestBody;
    }
    return schema;
  }
};

// Creates pend details object when a new pend is added or edited
// to use for AppCompletionButtons
export const createPendDetailsFrontend = (data) => {
  const newPendData = {
    taskId: data.taskId,
    pendId: data.pendId,
    pendStatus: data.pendStatus,
    requiresNewSignature: data.requiresNewSignature || false
  };
  return newPendData;
};

export default pendListTemplate;
