import { transformData } from '@f1/shared/src/_helpers';
import { employeeGuids } from '../guids';
import { mockEmployeesGet, mockEmployeesDetails } from './mockEmployees';
import employeeTemplate from './templates/employeeTemplate';

const allEmployees = [];
const allEmployeesDetails = [];
const allEmployeeMenuList = [];

Object.keys(mockEmployeesDetails).forEach((k) => {
  allEmployees.push({
    ...mockEmployeesGet[k],
    groups: mockEmployeesDetails[k].groups
  });
  allEmployeesDetails.push(mockEmployeesDetails[k]);
  allEmployeeMenuList.push({
    ...mockEmployeesDetails[k].employee,
    title: `${mockEmployeesDetails[k].employee.firstName} ${mockEmployeesDetails[k].employee.lastName}`,
    value: mockEmployeesDetails[k].employee.employeeId,
    groups: mockEmployeesDetails[k].groups
  });
});

export const mockEmployeeGetRes = allEmployees;
const formatGetData = (data) =>
  data
    ? transformData({
        data,
        toSchema: 'frontend',
        template: employeeTemplate,
        version: '1.0'
      })
    : [];
export const mockFormattedEmployeeGetRes = formatGetData(allEmployees);

export const mockEmployeePutRes = mockEmployeeGetRes[0];
export const mockEmployeePostRes = {
  [employeeGuids.default]: mockEmployeePutRes,
  [employeeGuids.employee02]: mockEmployeePutRes,
  [employeeGuids.employee03]: mockEmployeePutRes,
  [employeeGuids.employee04]: mockEmployeePutRes,
  [employeeGuids.employee05]: mockEmployeePutRes,
  [employeeGuids.employee06]: mockEmployeePutRes
};
export const mockEmployeeDetailsAllGetRes = allEmployeesDetails;
export const mockEmployeeList = allEmployeeMenuList;

export const mockUserForm = {
  firstName: { required: true, valid: true, value: 'Bob' },
  lastName: { required: true, valid: true, value: 'Burger' },
  email: { required: true, valid: true, value: 'bobBurger@f1payments.com' },
  department: {
    required: true,
    title: 'Risk',
    valid: true,
    value: 'Risk'
  },
  manager: {
    required: true,
    title: 'Jesse James',
    valid: true,
    value: 'Jesse James'
  }
};
