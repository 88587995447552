// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { ComboBox } from '../../ComboBox';
import ComboBoxSelectMonthYear from '../../ComboBoxSelectMonthYear';
import { Button } from '../../Button';
import { MultiSelect } from '../../MultiSelect';
import { NestedMenu } from '../../NestedMenu';
import { withRouter } from '../../routing/withRouter';
import { isEmpty, isEqual, getThisYearMonth, sortData, pad } from '../../_helpers';
import { RadioSwitch } from '../../RadioSwitch';
import { filterBarCSS } from '../../_styles';

export class BoundACHReportFilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { userLocation } = props;
    this.listLoadingStyles = { marginTop: '24px' };
    this.allDropdownOption = {
      title: 'All',
      dba: 'All',
      value: 'all',
      guid: 'all'
    };
    this.defaultAchYearMonth = getThisYearMonth();
    this.state = {
      achYearMonth: this.defaultAchYearMonth,
      achYearMonthIsValid: true,
      isPartner: false,
      viewAsMerchant: false,
      merchOrRelationshipType: userLocation === 'crm' ? 'merchant' : 'relationship',
      merchantGuid: '',
      selectedRelationshipDbaList: '',
      selectedRelationshipIdList: [],
      relationshipList: [],
      allChecked: true,
      merchantList: [],
      nestedMerchants: [],
      selectedNestedMerchant: {},
      filterValid: false,
      paramsLoaded: userLocation === 'crm'
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { store } = this.props;
    store.subscribe(this.handleStoreChanges);
    this.handleStoreChanges({ initialize: true });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state } = location || {};
    if (!isEqual(state, prevProps.location.state)) {
      this.handleParams();
    }
  }

  componentWillUnmount() {
    const { store } = this.props;
    store && store.subscribe && store.subscribe(this.handleStoreChanges);
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  };

  handleStoreChanges = async (options) => {
    const { initialize } = options || {};
    const {
      store,
      userLocation,
      getLocalDbNestedMerchants,
      getMerchantsWithPermissionList,
      getRelationshipsWithPermissionList
    } = this.props;
    const {
      viewAsMerchant: prevViewAsMerchant,
      isPartner: prevIsPartner,
      relationshipList: prevRelationshipList,
      nestedMerchants: prevNestedMerchants,
      merchantList: prevMerchantList
    } = this.state;
    const {
      authenticate: { user: { isPartner, relationshipTree: { subPartner = [] } = {} } } = {},
      dataUpdate: { relationshipData: { allRelationships = [] } = {} } = {},
      filterData: { activeFilters: { viewAsMerchant = false } = {} } = {}
    } = store.getState();
    const isPortalUser = userLocation === 'portal';
    const isMecoUser = userLocation === 'crm';
    const portalMerchantList = isPortalUser
      ? ((await getMerchantsWithPermissionList('reportAccess', { multiSelect: true })) ?? [])
      : [];
    const portalRelationshipList =
      isPortalUser && isPartner && !isEmpty(subPartner)
        ? ((await getRelationshipsWithPermissionList('reportAccess', { multiSelect: true })) ?? [])
        : [];
    const unsortedPortalMerchantList = (portalMerchantList ?? []).map((merchant) => ({
      title: merchant.dba,
      value: merchant.guid
    }));
    const sortedPortalMerchantList = sortData(unsortedPortalMerchantList, 'title');
    const mecoRelationshipList = isMecoUser ? allRelationships : [];
    const nestedMerchants = isMecoUser
      ? await getLocalDbNestedMerchants({ newRequestIfEmpty: true })
      : [];
    if (
      (isPortalUser &&
        (!isEqual(prevIsPartner, isPartner) ||
          !isEqual(prevViewAsMerchant, viewAsMerchant) ||
          !isEqual(prevMerchantList?.length, sortedPortalMerchantList?.length) ||
          !isEqual(prevNestedMerchants?.length, subPartner?.length) ||
          !isEqual(prevRelationshipList?.length, portalRelationshipList?.length))) ||
      (isMecoUser &&
        (!isEqual(prevNestedMerchants?.length, nestedMerchants?.length) ||
          !isEqual(prevRelationshipList?.length, mecoRelationshipList?.length)))
    ) {
      this.updateState(
        {
          ...(isPortalUser && {
            isPartner,
            merchantList: sortedPortalMerchantList,
            ...(isPartner && {
              nestedMerchants: subPartner,
              viewAsMerchant,
              ...(viewAsMerchant && { merchOrRelationshipType: 'merchant' }),
              relationshipList: !isEmpty(portalRelationshipList)
                ? [{ ...this.allDropdownOption }, ...portalRelationshipList]
                : []
            })
          }),
          ...(isMecoUser && {
            nestedMerchants,
            relationshipList: !isEmpty(mecoRelationshipList)
              ? [{ ...this.allDropdownOption }, ...mecoRelationshipList]
              : []
          })
        },
        () => {
          initialize && this.handleParams();
          !isEqual(prevViewAsMerchant, viewAsMerchant) && this.isFilterValid();
        }
      );
    }
  };

  handleParams = () => {
    // on Load, pre-populate filterBar to current year/month
    const { isPartner, viewAsMerchant, relationshipList } = this.state;
    const partnerView = isPartner && !viewAsMerchant;

    const { userLocation, location = {} } = this.props;
    const { state } = location;
    const {
      year,
      month,
      type = partnerView ? 'relationship' : 'merchant',
      relationship = 'All'
    } = state || {};
    // merchants don't have relationship view, always override if a state is being passed.
    const typeToUse = !partnerView ? 'merchant' : type;
    if (userLocation === 'portal') {
      const initYearMonth =
        (!isEmpty(year) && !isEmpty(month) && `${year}-${pad(month)}`) || this.defaultAchYearMonth;
      const validForApiCall = !isEmpty(initYearMonth) && typeToUse && relationship;
      this.updateState(
        (prevState) => ({
          paramsLoaded: false,
          achYearMonth: RegExp(/^[0-9]{4}-[0-9]{2}$/).test(initYearMonth)
            ? initYearMonth
            : this.defaultAchYearMonth,
          achYearMonthIsValid: true,
          ...(typeToUse && { merchOrRelationshipType: typeToUse }),
          ...(relationship === 'all' && {
            allChecked: true,
            selectedRelationshipIdList: !isEmpty(prevState.relationshipList)
              ? prevState.relationshipList?.flatMap((item) =>
                  item.value !== 'all' && isEmpty(item.parent) ? item.guid : []
                )
              : []
          })
        }),
        () => {
          // sometimes the above prevState is empty,
          // in such a case, fallback to the current relationshipList.
          const fallbackList = relationshipList.flatMap((item) =>
            item.value !== 'all' && isEmpty(item.parent) ? item.guid : []
          );
          validForApiCall && this.handleCallback(fallbackList);
          this.updateState({ paramsLoaded: true });
        }
      );
    }
  };

  handleDropdownChange = (options) => {
    const { valid, value, id } = options || {};
    this.updateState(
      {
        [id]: value,
        [`${id}IsValid`]: valid
      },
      this.isFilterValid
    );
  };

  handleNestedMenuChange = (options) => {
    const { guid } = options || {};
    this.updateState(
      {
        merchantGuid: !isEmpty(guid) ? guid : '',
        selectedNestedMerchant: !isEmpty(guid) ? options : {}
      },
      this.isFilterValid
    );
  };

  radioSwitchCallback = (name, value, _checked) => {
    this.updateState(
      (prevState) => ({
        merchOrRelationshipType: value,
        ...(value === 'relationship' && {
          selectedRelationshipIdList: prevState.relationshipList?.flatMap((item) =>
            item.guid !== 'all' ? item.guid : []
          )
        })
      }),
      this.isFilterValid
    );
  };

  setFilterMultiSelect = (data) => {
    const { relationshipList } = this.state;
    if (Array.isArray(data)) {
      const selectedRelationshipList = data?.flatMap((item) =>
        item.guid !== 'all' ? { title: item.dba, value: item.guid } : []
      );
      const selectedRelationshipIdList = data?.flatMap((item) =>
        item.guid !== 'all' ? item.guid : []
      );
      const selectedRelationshipDbaList = data
        ?.flatMap((item) => (item.dba !== 'All' ? item.dba : []))
        .toString();
      this.updateState(
        {
          selectedRelationshipList,
          selectedRelationshipDbaList: selectedRelationshipDbaList.split(',').join(', '),
          selectedRelationshipIdList,
          allChecked: data.length >= relationshipList.length
        },
        this.isFilterValid
      );
    }
  };

  isFilterValid = () => {
    const {
      achYearMonthIsValid,
      isPartner,
      viewAsMerchant,
      merchOrRelationshipType,
      merchantGuid,
      selectedNestedMerchant,
      selectedRelationshipIdList
    } = this.state;
    const { userLocation } = this.props;
    const isPortalUser = userLocation === 'portal';
    const isMecoUser = userLocation === 'crm';
    const nestedMerchantValid =
      merchOrRelationshipType === 'merchant' &&
      ((isPortalUser && isPartner && !viewAsMerchant) || isMecoUser)
        ? !isEmpty(selectedNestedMerchant)
        : true;
    const merchantGuidValid =
      merchOrRelationshipType === 'merchant' &&
      ((isPortalUser && isPartner && viewAsMerchant) || (isPortalUser && !isPartner))
        ? !isEmpty(merchantGuid)
        : true;
    const relationshipListValid =
      merchOrRelationshipType === 'relationship' ? !isEmpty(selectedRelationshipIdList) : true;
    this.updateState({
      filterValid:
        achYearMonthIsValid && nestedMerchantValid && merchantGuidValid && relationshipListValid
    });
  };

  handleCallback = (list = []) => {
    const { achYearMonth, merchOrRelationshipType, merchantGuid, selectedRelationshipIdList } =
      this.state;
    const { callback } = this.props;
    const relationships = isEmpty(selectedRelationshipIdList) ? list : selectedRelationshipIdList;
    const data = {
      yearMonth: achYearMonth,
      merchOrRelationshipType,
      merchantGuid,
      selectedRelationshipIdList: relationships
    };
    callback && callback(data);
  };

  render() {
    const { userLocation } = this.props;
    const {
      achYearMonth,
      isPartner,
      viewAsMerchant,
      merchOrRelationshipType,
      merchantGuid,
      selectedRelationshipDbaList,
      allChecked,
      relationshipList,
      merchantList,
      selectedNestedMerchant,
      nestedMerchants,
      filterValid,
      paramsLoaded,
      selectedRelationshipList
    } = this.state;
    return (
      <div id="ach-report-filter-wrapper" style={{ padding: '0 10px', width: '100%' }}>
        <div
          className="ACHReportFilterBar"
          id="achReportFilterBar"
          data-testid="ach-report-filter-bar"
          style={{
            width: 'auto',
            minWidth: '250px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
            backgroundColor: 'var(--color-filter-bar)',
            borderRadius: 'var(--radius-main)',
            padding: '10px 5px'
          }}>
          <ComboBoxSelectMonthYear
            id="achYearMonth"
            label="Month/Year"
            callback={this.handleDropdownChange}
            value={achYearMonth}
            wrapperStyle={{ minWidth: '150px', maxWidth: '300px' }}
            minDate="2000-12-31"
            required
          />
          {(userLocation === 'crm' || (isPartner && !viewAsMerchant)) && paramsLoaded && (
            <RadioSwitch
              id="merchOrRelationshipType"
              radio={{
                name: 'Select data type',
                options: [
                  {
                    value: 'merchant',
                    text: 'Merchant',
                    checked: merchOrRelationshipType !== 'relationship'
                  },
                  {
                    value: 'relationship',
                    text: 'Relationship',
                    checked: merchOrRelationshipType === 'relationship'
                  }
                ],
                text: 'Select data type'
              }}
              theme={{ backgroundColor: 'var(--color-bg)' }}
              wrapStyle={{
                flex: '1',
                minWidth: '210px',
                marginTop: '24px'
              }}
              callback={this.radioSwitchCallback}
            />
          )}
          {merchOrRelationshipType === 'relationship' && (
            <>
              {!isEmpty(relationshipList) ? (
                <MultiSelect
                  id="relationshipTree"
                  label="Relationship"
                  title={allChecked ? 'All' : selectedRelationshipDbaList}
                  selected={allChecked ? 'All' : selectedRelationshipDbaList}
                  allChecked={allChecked}
                  wrapperStyle={{ ...filterBarCSS.multiSelect, flex: '1' }}
                  height={30}
                  list={relationshipList}
                  checkbox
                  type="guid"
                  required
                  isFormField
                  callback={this.setFilterMultiSelect}
                  searchKeys={[{ title: 'relationship name', value: 'title' }]}
                  checkedItems={allChecked ? relationshipList : selectedRelationshipList}
                />
              ) : (
                <div style={this.listLoadingStyles}>Relationship list loading...</div>
              )}
            </>
          )}
          {merchOrRelationshipType === 'merchant' && (
            <>
              {userLocation === 'portal' && (!isPartner || (isPartner && viewAsMerchant)) ? (
                <>
                  {!isEmpty(merchantList) ? (
                    <ComboBox
                      label="Merchant"
                      id="merchantGuid"
                      placeholder="Select a Merchant"
                      list={merchantList}
                      wrapperStyle={{
                        fontSize: '1.2rem',
                        minWidth: '150px',
                        flex: '1'
                      }}
                      selected={merchantGuid}
                      callback={this.handleDropdownChange}
                      type="merchantGuidDropdown"
                      defaultValue="Select a Merchant"
                      formField
                      required
                      displaySearch
                      searchKeys={[{ title: 'Merchant DBA', value: 'title' }]}
                    />
                  ) : (
                    <div style={this.listLoadingStyles}>Merchant list loading...</div>
                  )}
                </>
              ) : (
                <>
                  {!isEmpty(nestedMerchants) ? (
                    <NestedMenu
                      label="Merchant"
                      id="partnerTree"
                      items={{ subPartner: nestedMerchants }}
                      title="dba"
                      value="guid"
                      current={selectedNestedMerchant}
                      callback={this.handleNestedMenuChange}
                      nestedMainWrapperStyle={{ width: '100%' }}
                      wrapperStyle={{
                        height: '30px',
                        flex: '1',
                        minWidth: '300px',
                        maxWidth: '100%'
                      }}
                      page={userLocation}
                      required
                    />
                  ) : (
                    <div style={this.listLoadingStyles}>Merchant list loading...</div>
                  )}
                </>
              )}
            </>
          )}
          <Button
            id="achSubmit"
            onClick={this.handleCallback}
            disabled={!filterValid}
            style={{
              height: '30px',
              maxWidth: '120px',
              flex: '1',
              marginTop: '24px'
            }}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

BoundACHReportFilterBar.propTypes = {
  callback: PropTypes.func,
  store: PropTypes.oneOfType([PropTypes.object]),
  userLocation: PropTypes.string,
  getLocalDbNestedMerchants: PropTypes.func,
  getMerchantsWithPermissionList: PropTypes.func,
  getRelationshipsWithPermissionList: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      status: PropTypes.string
    })
  })
};
BoundACHReportFilterBar.defaultProps = {
  callback: () => {},
  store: {},
  userLocation: '',
  getLocalDbNestedMerchants: () => {}, // meco
  getMerchantsWithPermissionList: () => {}, // portal
  getRelationshipsWithPermissionList: () => {}, // portal
  location: {
    state: {}
  }
};

const ACHReportFilterBar = withRouter(BoundACHReportFilterBar);

export default withRouter(ACHReportFilterBar);
