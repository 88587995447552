import { camelToTitle, isEmpty } from '@f1/shared/src/_helpers';
import { toBackendValue } from '@f1/shared/src/_templateHelpers';
import { riskRulesFormFields } from '../../../../pages/components/forms/formHelpers';
import { nullIfOptional, toReadFriendlyCron } from '../../../_templateHelpers';

const riskRuleTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const { rules } = schema;
      if (!(rules instanceof Array)) return [];
      return (
        rules.map((rule) => {
          const currentFrequency =
            !isEmpty(rule?.eventDriven?.processorName) && !isEmpty(rule?.eventDriven?.dataType)
              ? 'eventDriven'
              : 'frequencyExpression';
          return {
            id: rule?.riskRuleId,
            name: rule?.name,
            description: rule?.description,
            category: rule?.riskCategory?.trim(),
            subcategory: nullIfOptional(rule?.riskSubcategory?.trim()),
            employeeGroupId: rule?.employeeGroupId,
            status: rule?.status,
            priority: nullIfOptional(rule?.priority ? camelToTitle(rule?.priority) : null),
            sandboxRule: rule?.sandbox,
            closeAuto: rule?.autoCloseDays,
            createdBy: `${rule?.createdBy?.employeeFirstName} ${rule?.createdBy?.employeeLastName}`,
            frequencyType: currentFrequency,
            ...(!isEmpty(rule?.frequencyExpression) &&
              currentFrequency === 'frequencyExpression' && {
                readableFrequencyExpression: nullIfOptional(
                  toReadFriendlyCron(rule?.frequencyExpression)
                ),
                frequencyExpression: nullIfOptional(rule?.frequencyExpression)
              }),
            ...(!isEmpty(rule?.eventDriven?.processorName) && {
              eventProcessor: nullIfOptional(rule?.eventDriven?.processorName)
            }),
            ...(!isEmpty(rule?.eventDriven?.dataType) && {
              eventDataType: nullIfOptional(rule?.eventDriven?.dataType)
            }),
            sql: rule?.sql,
            primaryKeyType: rule?.primaryKeyType
          };
        }) || []
      );
    }
    return schema;
  },
  editableRule: (rule, version) => {
    if (version === '1.0') {
      return {
        name: rule.name || '',
        description: rule.description || '',
        sql: rule.sql || '',
        ruleStatus: (rule?.status || 'disabled').toLowerCase(),
        priority: (rule?.priority || '').toLowerCase(),
        employeeGroupId: rule.employeeGroupId || '',
        autoCloseDays: rule.closeAuto || 7,
        frequencyExpression: rule.frequencyExpression || '',
        processorName: rule.eventProcessor || 'ALL',
        dataType: rule.eventDataType || 'ALL',
        primaryKeyType: rule?.primaryKeyType || '',
        riskCategory: rule.category?.trim() || '',
        riskSubcategory: rule.subcategory?.trim() || '',
        riskRuleId: rule.id || '',
        sandbox: !!rule.sandboxRule,
        frequencyType:
          !isEmpty(rule?.eventProcessor) || !isEmpty(rule?.eventDataType)
            ? 'eventDriven'
            : 'frequencyExpression'
      };
    }
    return rule;
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      return {
        name: toBackendValue(
          schema[riskRulesFormFields.name.id],
          riskRulesFormFields[riskRulesFormFields.name.id]
        ),
        description: toBackendValue(
          schema[riskRulesFormFields.description.id],
          riskRulesFormFields[riskRulesFormFields.description.id]
        ),
        sql: toBackendValue(
          schema[riskRulesFormFields.sql.id],
          riskRulesFormFields[riskRulesFormFields.sql.id]
        ),
        status: toBackendValue(
          schema[riskRulesFormFields.ruleStatus.id],
          riskRulesFormFields[riskRulesFormFields.ruleStatus.id]
        ),
        priority: toBackendValue(
          schema[riskRulesFormFields.priority.id],
          riskRulesFormFields[riskRulesFormFields.priority.id]
        ),
        employeeGroupId: schema.employeeGroupId,
        autoCloseDays: toBackendValue(
          schema[riskRulesFormFields.autoCloseDays.id],
          riskRulesFormFields[riskRulesFormFields.autoCloseDays.id]
        ),
        ...(schema.typeOfFrequency === 'frequencyExpression'
          ? {
              frequencyExpression: toBackendValue(
                schema[riskRulesFormFields.frequencyExpression.id],
                riskRulesFormFields[riskRulesFormFields.frequencyExpression.id]
              )
            }
          : {
              eventDriven: {
                processorName:
                  schema?.[riskRulesFormFields.processorName.id] !== 'ALL'
                    ? toBackendValue(
                        schema?.[riskRulesFormFields.processorName.id],
                        riskRulesFormFields[riskRulesFormFields.processorName.id]
                      )
                    : null,
                dataType:
                  schema?.[riskRulesFormFields.dataType.id] !== 'ALL'
                    ? toBackendValue(
                        schema?.[riskRulesFormFields.dataType.id],
                        riskRulesFormFields[riskRulesFormFields.dataType.id]
                      )
                    : null
              }
            }),
        riskCategory: toBackendValue(
          schema[riskRulesFormFields.riskCategory.id],
          riskRulesFormFields[riskRulesFormFields.riskCategory.id]
        ),
        ...(schema?.[riskRulesFormFields.riskSubcategory.id] && {
          riskSubcategory: toBackendValue(
            schema[riskRulesFormFields.riskSubcategory.id],
            riskRulesFormFields[riskRulesFormFields.riskSubcategory.id]
          )
        }),
        primaryKeyType: toBackendValue(
          schema[riskRulesFormFields.primaryKeyType.id],
          riskRulesFormFields[riskRulesFormFields.primaryKeyType.id]
        ),
        ...(schema?.action === 'editRiskRule' && { sandbox: toBackendValue(schema?.sandbox, {}) })
      };
    }
    return schema;
  }
};

export default riskRuleTemplate;
