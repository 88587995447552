import React from 'react';
import { PropTypes } from 'prop-types';
import { CustomApiErr, Spinner, AlertBar } from '../../../index';
import DataBoxExternalCommunication from '../../DataBoxExternalCommunication';
import {
  isEqual,
  transformData,
  isEmpty,
  endpoint,
  sharedGetInnerAlertBarState
} from '../../_helpers';
import { Button } from '../../Button';
import { ToolTip } from '../../ToolTip';
import { icons } from '../../../images/_icons';
import taskPendDetailTemplate from '../../data/sharedBoarding/templates/taskPendDetailTemplate';
import PendDetailsForm from './PendDetailsForm';
import pendListTemplate from '../../data/sharedBoarding/templates/pendListTemplate';
import { DataBoxFilesWithTags } from '../../DataBoxFilesWithTags';
import { FlexBoxWrap } from '../../../css/_styledComponents';
import { ModalV2 } from '../../ModalV2';
import RequestHelpModal from './RequestHelpModal';

class PendDetails extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.appIsWithPartnerStatuses = [
      'draft',
      'waiting_on_initial_signature',
      'waiting_on_partner',
      'waiting_on_signature_post_pends'
    ];
    this.closedAppStatuses = ['approved', 'declined', 'withdrawn'];
    this.resetModalData = {
      modalAction: '',
      modalTitle: null,
      showModal: false
    };
    this.state = {
      ...this.resetModalData,
      spinnerLoading: false,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      status: null,
      err: false,
      disableAddFile: false,
      hasEditAccess: false,
      disableDeleteFile: false,
      externalCommTimestamp: 0,
      formFields: {},
      infoFields: {},
      otherData: {},
      showRequestHelp: false,
      showModal: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.setDetails();
  }

  componentDidUpdate(prevProps) {
    const { appStatus, pendId, originalBackendPend, userType, currentEmployeeCanUpdate } =
      this.props;
    if (
      !isEqual(prevProps.appStatus, appStatus) ||
      !isEqual(prevProps.pendId, pendId) ||
      !isEqual(originalBackendPend, prevProps.originalBackendPend) ||
      (userType === 'employee' &&
        !isEqual(currentEmployeeCanUpdate, prevProps.currentEmployeeCanUpdate))
    ) {
      this.setDetails();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  setDetails = () => {
    const { currentEmployeeCanUpdate, appStatus, userType, originalBackendPend } = this.props;
    const formattedData = !isEmpty(originalBackendPend)
      ? transformData({
          data: { pendFields: originalBackendPend, userType },
          toSchema: 'frontend',
          template: taskPendDetailTemplate,
          version: '1.0'
        })
      : { formFields: {}, infoFields: {}, otherData: {} };
    const appIsClosed = this.closedAppStatuses.includes(appStatus);
    const showRequestHelp =
      userType === 'partner' &&
      this.appIsWithPartnerStatuses.includes(appStatus) &&
      formattedData?.formFields?.pendStatus !== 'resolved';
    this.updateState({
      ...formattedData,
      ...(userType === 'employee' && {
        currentEmployeeCanUpdate,
        disableDeleteFile: appIsClosed ? true : currentEmployeeCanUpdate !== true,
        disableAddFile: appIsClosed,
        hasEditAccess: appIsClosed ? false : currentEmployeeCanUpdate === true
      }),
      ...(userType === 'partner' && { disableDeleteFile: appIsClosed || appStatus !== 'draft' }),
      showRequestHelp
    });
  };

  handleInnerAlertBar = (options) => {
    const { type, data, additionalState = {} } = options || {};
    const { axiosRequest } = this.props;
    const alertBarState = sharedGetInnerAlertBarState({ type, data, axiosRequest });
    this.updateState({ ...alertBarState, ...additionalState });
  };

  requestHelpHandler = async () => {
    this.updateState({ showModal: true, modalAction: 'helpRequest' });
  };

  sendRequestHelp = async (data) => {
    const { axiosRequest, pendId, userType, updateSidebarAlert } = this.props;
    this.handleCloseModal();
    if (!isEmpty(pendId) && !isEmpty(data)) {
      const requestBody = transformData({
        data: {
          userType,
          description: data,
          pendStatus: 'needs_help'
        },
        toSchema: 'backendPost',
        template: pendListTemplate,
        version: '1.0'
      });
      this.handleInnerAlertBar({ type: 'closed', data: '' });
      this.updateState({ spinnerLoading: true });
      const res = await axiosRequest(
        {
          fullPageLoad: false,
          method: 'post',
          url: `${endpoint.crab.v1.application.taskPend}`,
          requestGuid: { applicationPendId: pendId }
        },
        requestBody
      );
      this.updateState((prev) => ({
        ...prev,
        ...res?.state
      }));
      if (res?.errorDetails instanceof Error) {
        updateSidebarAlert({ alertBarType: 'warning', alertBarMessage: res.errorDetails });
      } else {
        const successOptions = {
          refreshAppDetails: true,
          refreshAppList: true,
          partnerRequestedHelp: true,
          needToValidateSubmit: true,
          updateAppDetailsScrollPos: true,
          closeSidebar: true,
          showPageAlertBar: true,
          successMessage:
            'Success! Your request has been sent. Once all open pends have been updated to Addressed or Needs Help, please Resubmit the application to continue.'
        };
        this.updateState({ externalCommTimestamp: Date.now() });
        this.externalCommunicationCallback(successOptions);
      }
    }
  };

  externalCommunicationCallback = (options) => {
    const cbOptions = {
      ...options,
      // If adding a new PEND external communication, also update the APP external communications
      refreshExternalComm: true
    };
    this.handleCallback(cbOptions);
  };

  pendDetailsFormCallback = (options) => {
    const {
      needToValidateSubmit,
      updateAppDetailsScrollPos,
      partnerRequestedHelp,
      updatedPendDetails,
      refreshPendDetails,
      refreshAppFiles,
      refreshExternalComm,
      refreshAppDetails,
      refreshAppList
    } = options || {};
    // If updating the pend details form should also refresh external communications
    refreshExternalComm && this.updateState({ externalCommTimestamp: Date.now() });
    const cbOptions = {
      needToValidateSubmit,
      updateAppDetailsScrollPos,
      partnerRequestedHelp,
      updatedPendDetails,
      refreshPendDetails,
      refreshAppFiles,
      refreshExternalComm,
      refreshAppDetails,
      refreshAppList
    };
    this.handleCallback(cbOptions);
  };

  handleCallback = (options) => {
    const { callback } = this.props;
    callback && callback(options);
  };

  handleCloseModal = () => {
    this.updateState({ ...this.resetModalData });
    const { siteModal } = this.props;
    siteModal(false);
  };

  render() {
    const {
      appStatus,
      pendId,
      timestamp,
      axiosRequest,
      isFormDirty,
      siteModal,
      options,
      userData,
      userType,
      isReadOnlyInternalUser,
      isReadOnlyBankUser,
      currentEmployeeCanUpdate,
      originalBackendPend
    } = this.props;
    const {
      spinnerLoading,
      alertBarType,
      alertBarMessage,
      alertBarTimeout,
      err,
      status,
      disableAddFile,
      hasEditAccess,
      disableDeleteFile,
      externalCommTimestamp,
      formFields,
      infoFields,
      showRequestHelp,
      otherData,
      modalAction,
      showModal
    } = this.state;
    return (
      <>
        <ModalV2
          axiosRequest={axiosRequest}
          showModal={showModal}
          modalTitle="Request Help"
          options={{ size: 'medium' }}
          closeModalCallback={this.updateState}
          resetModalData={this.resetModalData}>
          {modalAction === 'helpRequest' ? (
            <RequestHelpModal callback={this.sendRequestHelp} />
          ) : (
            <></>
          )}
        </ModalV2>
        <div id="pend-details" style={{ width: '100%' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <AlertBar
              options={{
                barStyle: alertBarType,
                message: alertBarMessage,
                timeout: alertBarTimeout,
                customWarnStyle: { width: '100%', height: '100%' }
              }}
              callback={this.updateState}
              useInnerAlertBar
            />
          </div>
          <Spinner loading={spinnerLoading} />
          <div
            className="title-wrapper"
            style={{
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              width: '100%'
            }}>
            <h3>Pend Details</h3>
            {showRequestHelp && (
              <div style={{ display: 'inline-flex' }}>
                <Button id="requestHelp" type="text" onClick={this.requestHelpHandler}>
                  Request Help
                </Button>
                <div style={{ position: 'relative', marginLeft: '.5em' }}>
                  <ToolTip
                    infoTip
                    infoTipDisplay={{
                      ...icons.centered,
                      transform: 'translate(-50%, -100%)'
                    }}>
                    Requesting Help will notify us to review this pend&apos;s external
                    communications
                  </ToolTip>
                </div>
              </div>
            )}
          </div>
          {err ? (
            <CustomApiErr customErr={err} status={status} loading={spinnerLoading} />
          ) : (
            <div className="cards-wrapper" style={{ display: 'flex', flexFlow: 'row wrap' }}>
              <div style={{ flex: '1 1 100%' }}>
                <PendDetailsForm
                  pendData={{
                    formFields,
                    infoFields,
                    otherData
                  }}
                  appStatus={appStatus}
                  axiosRequest={axiosRequest}
                  siteModal={siteModal}
                  isFormDirty={isFormDirty}
                  callback={this.pendDetailsFormCallback}
                  userType={userType}
                  timestamp={timestamp}
                  {...(userType === 'employee' && { currentEmployeeCanUpdate })}
                  userData={userData}
                />
              </div>
              <FlexBoxWrap style={{ width: '100%' }}>
                <DataBoxExternalCommunication
                  type="white"
                  guid={pendId}
                  userType={userType}
                  canAdd={isReadOnlyBankUser ? false : !isReadOnlyInternalUser}
                  userEmail={userData.userEmail}
                  timestamp={externalCommTimestamp}
                  requestGuidType="applicationPendId"
                  siteModal={siteModal}
                  axiosRequest={axiosRequest}
                  options={options}
                  callback={this.externalCommunicationCallback}
                  communicationEndpoint={endpoint.crab.v1.application.externalCommunication}
                />
                <DataBoxFilesWithTags
                  type="white"
                  guid={pendId}
                  galleryView
                  guidType="applicationPendId"
                  requestGuid={{ applicationPendId: pendId }}
                  wrapperStyle={{ flex: '100%' }}
                  axiosRequest={axiosRequest}
                  {...options?.dataBoxFilesEndpoints}
                  importFiles={originalBackendPend?.fileList || []}
                  userType={userType}
                  callback={this.pendDetailsFormCallback}
                  disableAdd={disableAddFile || isReadOnlyBankUser || isReadOnlyInternalUser}
                  hasEditAccess={
                    hasEditAccess && (isReadOnlyBankUser ? false : !isReadOnlyInternalUser)
                  }
                  disableDelete={disableDeleteFile || isReadOnlyBankUser || isReadOnlyInternalUser}
                  {...(userType === 'employee' && {
                    displayedFields: ['tagList'],
                    allowEditFileName: true,
                    defaultTagsOnAdd: ['employee_group_internal_employees']
                  })}
                  {...(userType === 'partner' && { displayedFields: ['selectFileType'] })}
                />
              </FlexBoxWrap>
            </div>
          )}
        </div>
      </>
    );
  }
}

PendDetails.propTypes = {
  pendId: PropTypes.string,
  appStatus: PropTypes.string,
  timestamp: PropTypes.number,
  applicationId: PropTypes.string,
  currentEmployeeCanUpdate: PropTypes.bool,
  originalBackendPend: PropTypes.oneOfType([PropTypes.object]),
  axiosRequest: PropTypes.func, // REQUIRED for apiCalls
  options: PropTypes.shape({
    // REQUIRED for External Communications Props
    utils: PropTypes.shape({
      isTokenValid: PropTypes.func,
      handleApiError: PropTypes.func,
      createCsrfHeader: PropTypes.func,
      employeeGroupsInclude: PropTypes.func
    }),
    getMockData: PropTypes.func,
    dataBoxFilesEndpoints: PropTypes.shape({
      getEndpoint: PropTypes.string,
      deleteEndpoint: PropTypes.string,
      uploadEndpoint: PropTypes.string
    })
  }),
  userData: PropTypes.shape({
    userEmail: PropTypes.string
  }),
  siteModal: PropTypes.func,
  isFormDirty: PropTypes.func,
  callback: PropTypes.func,
  userType: PropTypes.oneOf(['partner', 'employee']),
  isReadOnlyInternalUser: PropTypes.bool,
  isReadOnlyBankUser: PropTypes.bool,
  updateSidebarAlert: PropTypes.func
};

PendDetails.defaultProps = {
  pendId: '',
  appStatus: '',
  timestamp: 0,
  applicationId: '',
  currentEmployeeCanUpdate: false, // employee only
  originalBackendPend: {}, // load existing taskPend data into the pend details form
  axiosRequest: () => {},
  options: {
    // If/When we refactor ExternalComms we can prob just use axios
    utils: {
      isTokenValid: () => {},
      handleApiError: () => {},
      createCsrfHeader: () => {},
      employeeGroupsInclude: () => {}
    },
    getMockData: null,
    dataBoxFilesEndpoints: {
      getEndpoint: '',
      deleteEndpoint: '',
      uploadEndpoint: ''
    }
  },
  userData: {
    userEmail: ''
  },
  siteModal: () => {},
  isFormDirty: () => {},
  callback: null,
  userType: 'partner',
  isReadOnlyInternalUser: false,
  isReadOnlyBankUser: false,
  updateSidebarAlert: () => {}
};

export default PendDetails;
