import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
// import { processorNameList } from '../../pages/components/forms/formHelpers';

export const mockAvailableMidsGet = () => {
  const mockProcessorName = 'REPAY'; // || // currently only support Repay processor
  // getMockValue({ type: 'list', list: processorNameList });
  const mockDiscoverMids = Array.from({ length: getMockValue({ min: 1 }) }).map(() =>
    getMockValue({ type: 'mid' })
  );
  const mockVisaOrMastercardMids = Array.from({ length: getMockValue({ min: 1 }) }).map(() =>
    getMockValue({ type: 'mid' })
  );
  return {
    processorName: mockProcessorName,
    discoverMids: mockDiscoverMids,
    visaOrMastercardMids: mockVisaOrMastercardMids
  };
};

export const mockAvailableMidsPost = () => {
  const newAmountOfMidsAvailable = getMockValue();
  const amountOfDuplicateMidsNotUsed = getMockValue();
  return { newAmountOfMidsAvailable, amountOfDuplicateMidsNotUsed };
};
