// TODO: BIRB-8338
/* istanbul ignore file */
import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import '../css/animations.css';
import SuspenseComponent from './SuspenseComponent';

const ToggleSwitch = lazy(() => import('./ToggleSwitch'));
export class ShowHide extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      hidden: false
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    const { hideChild } = this.props;
    if (hideChild !== null || prevProps.hideChild !== hideChild) {
      this.handleToggleSwitch(hideChild);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  handleToggleSwitch = (toggled) => this.updateState({ hidden: toggled });

  render() {
    const { hidden } = this.state;
    const { children, componentName, toggleId, useToggle } = this.props;
    const hide = { animation: 'hideChild 1.25s ease-out forwards' };
    const show = { animation: 'showChild 0.75s ease forwards' };
    return (
      <>
        {useToggle && (
          <SuspenseComponent>
            <ToggleSwitch
              name={`${toggleId}-toggler`}
              id={`${toggleId}-toggler`}
              label={`${hidden ? 'Show' : 'Hide'} ${componentName || 'Graph'}`}
              callback={this.handleToggleSwitch}
              wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5em' }}
            />
          </SuspenseComponent>
        )}
        <div style={{ ...(hidden ? hide : show) }}>
          {/*
            This `hidden` check is needed specifically for ChartWrapper,
            which won't render the chart if you toggle ON hidden,
            pass new data, then toggle ON show, nothing appears.
          */}
          {hidden ? null : children}
        </div>
      </>
    );
  }
}

ShowHide.propTypes = {
  children: PropTypes.node,
  toggleId: PropTypes.string,
  componentName: PropTypes.string,
  useToggle: PropTypes.bool,
  hideChild: PropTypes.bool
};

ShowHide.defaultProps = {
  children: null,
  toggleId: '', // camelCase
  componentName: '', // Title Case
  useToggle: false,
  hideChild: null
};

export default ShowHide;
