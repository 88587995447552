import { getMockValue } from '../_mockDataHelpers';

export const createMockPartherHealth = (guid, options) => {
  const { staticMap } = options || {};
  return [
    { countKey: 'healthyCount', dataKey: 'healthyMerchants' },
    { countKey: 'inDangerCount', dataKey: 'inDangerMerchants' },
    { countKey: 'onProgramCount', dataKey: 'onProgramMerchants' },
    { countKey: 'unhealthyCount', dataKey: 'unhealthyMerchants' }
  ].reduce((acc, item) => {
    const mockCount = getMockValue({ type: 'number', min: 1, max: 10 });
    return {
      ...acc,
      [item.countKey]: mockCount,
      [item.dataKey]: Array.from({ length: mockCount }).map(() => ({
        legalName: getMockValue({ type: 'businessName' }),
        mids: Array.from({ length: getMockValue({ type: 'number', min: 5, max: 30 }) }).map(() => ({
          mid: getMockValue({ type: 'mid' }),
          dbaName: getMockValue({ type: 'businessName' }),
          merchantEmail: getMockValue({ type: 'email' }),
          merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
          partnerName: getMockValue({ type: 'businessName' }),
          partnerEmail: getMockValue({ type: 'email' }),
          partnerPhoneNumber: getMockValue({ type: 'bePhone' }),
          relationshipCode: getMockValue({
            type: 'number',
            stringify: true,
            min: 10000,
            max: 29999
          })
        })),
        visa: {
          expectedSalesCountGivenCurrentRunRate: getMockValue({ type: 'number' }),
          expectedChargebackCountGivenCurrentRunRate: getMockValue({ type: 'number' }),
          expectedChargebackRatioGivenCurrentRunRate: getMockValue({ type: 'ratio' })
        },
        mastercard: {
          expectedSalesCountGivenCurrentRunRate: getMockValue({ type: 'number' }),
          expectedChargebackCountGivenCurrentRunRate: getMockValue({ type: 'number' }),
          expectedChargebackRatioGivenCurrentRunRate: getMockValue({ type: 'ratio' })
        },
        total: {
          expectedSalesCountGivenCurrentRunRate: getMockValue({ type: 'number' }),
          expectedChargebackCountGivenCurrentRunRate: getMockValue({ type: 'number' }),
          expectedChargebackRatioGivenCurrentRunRate: getMockValue({ type: 'ratio' })
        }
      })),
      ...(staticMap && staticMap[guid])
    };
  }, {});
};

export default createMockPartherHealth;
