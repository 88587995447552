import { isEmpty, formatDateForFEView, sortData } from '@f1/shared/src/_helpers';

export const giactTemplate = {
  frontend: (schema, version) => {
    // POST - format api response
    if (version === '1.0') {
      if (isEmpty(schema?.giactResponse)) {
        return {};
      }
      const formatted = {
        ...(schema?.giactRequest && { giactRequest: schema?.giactRequest }),
        dataPullGroupId: schema.dataPullGroupId,
        recommendation: !isEmpty(schema.giactResponse.metadata.recommendation)
          ? {
              decision: schema.giactResponse.metadata.recommendation.decision,
              explanation: schema.giactResponse.metadata.recommendation.explanation
            }
          : {}, // empty if getting data for specific person (owner)
        dataFetchTimestamp: formatDateForFEView(schema.giactResponse.metadata.dataFetchTimestamp, {
          includeTime: true
        }),
        queryDetails: {
          gIdentify: {
            accountResponseCode: schema.giactResponse.gIdentify.accountResponseCode,
            customerResponseCode: schema.giactResponse.gIdentify.customerResponseCode,
            verificationResponse: schema.giactResponse.gIdentify.verificationResponse
          },
          gAuthenticate: !isEmpty(schema.giactResponse.gAuthenticate)
            ? {
                accountResponseCode: schema.giactResponse.gAuthenticate.accountResponseCode,
                customerResponseCode: schema.giactResponse.gAuthenticate.customerResponseCode,
                verificationResponse: schema.giactResponse.gAuthenticate.verificationResponse
              }
            : {} // empty if getting data for specific person (owner)
        },
        matchedBusinesses: matchedBusinesses(
          schema.giactResponse.gIdentify.matchedBusinessDataList || []
        ),
        matchedPeople: matchedPeople(schema.giactResponse.gIdentify.matchedPersonDataList || [])
      };
      return formatted;
    }
    if (version === '2.0') {
      const { backendData, isSubCard } = schema || {};
      const { resultList } = backendData || {};
      if (!isEmpty(resultList)) {
        const groupedResults = resultList.reduce((acc, result) => {
          const formattedResult = giactTemplate.frontend(result, '1.0');
          const { dataFetchTimestamp, dataPullGroupId } = formattedResult || {};
          return {
            ...acc,
            [dataPullGroupId]: !isEmpty(acc[dataPullGroupId])
              ? {
                  ...acc[dataPullGroupId],
                  data: [...(acc[dataPullGroupId].data ?? []), formattedResult]
                }
              : {
                  ...(isSubCard && {
                    dedupeKey: dataPullGroupId,
                    sortTimestamp: dataFetchTimestamp
                  }),
                  dataPullGroupId,
                  data: [formattedResult]
                }
          };
        }, {});
        const sortedGroupedResults = sortData(Object.values(groupedResults), 'dataPullGroupId', {
          direction: 'desc'
        });
        return sortedGroupedResults;
      }
      return [];
    }
    return schema;
  }
};

const matchedBusinesses = (businessData) => {
  const businesses = [];
  businessData.forEach((business) =>
    businesses.push({
      bankruptcyCreditorRecordCount: business.bankruptcyCreditorRecordCount,
      bankruptcySubjectRecordCount: business.bankruptcySubjectRecordCount,
      businessReportKey: business.businessReportKey,
      corporationType: business.corporationType,
      domains: business.domains.join(','),
      dunsNumber: business.dunsNumber,
      fein: business.fein,
      filingNumber: business.filingNumber,
      incorporationDate: business.incorporationDate,
      incorporationState: business.incorporationState,
      industries: business.industries.join(','),
      registrationType: business.registrationType,
      addressRecords: listAddresses(business.giactAddressRecordList),
      contacts: listContacts(business.giactBusinessContactList),
      nameRecords: listBusinessNameRecords(business.giactBusinessNameRecordList),
      phoneNumberRecords: listPhoneNumbers(business.giactPhoneNumberRecordList)
    })
  );
  return businesses;
};

const matchedPeople = (personList) => {
  const formattedPeople = !isEmpty(personList)
    ? personList.map((person) => ({
        personData: {
          firstName: person.firstName,
          lastName: person.lastName,
          dateOfBirth: person.dateOfBirth,
          ssnIssueState: person.ssnIssueState,
          ssnIssueStartYear: person.ssnIssueStartYear,
          ssnIssueEndYear: person.ssnIssueEndYear
        },
        addressRecords: listAddresses(person.giactAddressRecordList),
        phoneNumberRecords: listPhoneNumbers(person.giactPhoneNumberRecordList)
      }))
    : [];
  return formattedPeople;
};

const listAddresses = (addressData) => {
  const businesses = [];
  addressData.forEach((address) =>
    businesses.push({
      address: address.address,
      city: address.city,
      state: address.state,
      zip: address.zip
    })
  );
  return businesses;
};

const listContacts = (contactData) => {
  const contacts = [];
  contactData.forEach((contact) =>
    contacts.push({
      firstName: contact.firstName,
      lastName: contact.lastName,
      title: contact.title
    })
  );
  return contacts;
};

const listBusinessNameRecords = (BusinessNameRecords) => {
  const businessNames = [];
  BusinessNameRecords.forEach((businessName) =>
    businessNames.push({
      businessName: businessName.businessName,
      businessType: businessName.businessType
    })
  );
  return businessNames;
};

const listPhoneNumbers = (phoneNumberData) => {
  const phoneNumbers = [];
  phoneNumberData.forEach((phoneNumber) =>
    phoneNumbers.push({
      classification: phoneNumber.classification,
      numberType: phoneNumber.numberType,
      phoneNumber: phoneNumber.phoneNumber
    })
  );
  return phoneNumbers;
};

export default giactTemplate;
