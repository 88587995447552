export const mockTransactionReportRefundGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      header: {
        label: 'label',
        lines: [],
        biaxial: [],
        currency: true,
        biaxialCurrency: null
      },
      data: [
        { date: 'Feb', volume: 0, count: 0 },
        { date: 'Mar', volume: 0, count: 0 },
        { date: 'Apr', volume: 0, count: 0 },
        { date: 'May', volume: 0, count: 0 },
        { date: 'Jun', volume: 0, count: 0 },
        { date: 'Jul', volume: 1500, count: 30 },
        { date: 'Aug', volume: 0, count: 0 }
      ]
    }
  };
};

export default mockTransactionReportRefundGet;
