import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { closestCenter, DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import DragAndDropItem from './DragAndDropItem';
import { isEmpty } from './_helpers';
import { getParsedJson } from './_templateHelpers';

const DragAndDrop = ({
  children = null,
  callback = () => {},
  currentList = [],
  disabled = false,
  useHandle = false,
  id = null,
  handleStyles = {}
}) => {
  const [items, setItems] = useState([]);
  const [itemIds, setItemIds] = useState([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => { setIsMounted(false); };
  }, []);

  useEffect(() => {
    // on mount add a custom ID to each child to use for sorting
    if (isMounted) {
      const newIds = [];
      const newitems = [];
      children.forEach((child) => {
        const propId = !isEmpty(child?.props?.item?.uniqueId)
          ? child?.props?.item?.uniqueId
          : uuidv4();
        const newitem = {
          ...child,
          props: {
            ...child.props,
            id: propId
          }
        };
        newitems.push(newitem);
        newIds.push(propId);
      });
      setItemIds(newIds);
      setItems(newitems);
    }
  }, [isMounted, currentList]); // do NOT use `children` as a dependency - causes infinite loop

  const resetItemIds = (array) => {
    if (isMounted) {
      const result = array.map(i => i.props.id);
      setItemIds(result);
    }
  };

  const onDragEnd = (event) => {
    if (isMounted) {
      const { active, over } = event;
      const oldIndex = items.findIndex(item => item.props.id === active.id);
      const newIndex = items.findIndex(item => item.props.id === over.id);
      const newArray = arrayMove(items, oldIndex, newIndex);
      setItems(newArray);
      // since we are using passed in children, we need to also update the array order for the id's
      resetItemIds(newArray);
      const cbData = newArray.reduce((acc, elem) => {
        const { key } = elem || {};
        const data = currentList.find(cItem => cItem.uniqueId === key) || getParsedJson(key);
        return [...acc, ...(!isEmpty(data) ? [data] : [])];
      }, []);
      callback && callback(cbData);
    }
  };

  return (
    <div id={id} className="DraggableItems">
      <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} id="test-dnd-context">
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {items.map((child, i) => (
            <DragAndDropItem
              key={itemIds[i]}
              disabled={disabled}
              id={itemIds[i]}
              item={child}
              useHandle={useHandle}
              handleStyles={handleStyles}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

DragAndDrop.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.node,
  // `currentList` - same list data that gets rendered as `children` elements,
  // and is required to track updates on child and/or drag & drop changes
  currentList: PropTypes.oneOfType([PropTypes.array]).isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  useHandle: PropTypes.bool,
  handleStyles: PropTypes.oneOfType([PropTypes.object])
};

export default DragAndDrop;
