import {
  mockCrabV1ApplicationGet,
  createMockApplications,
  createMockApplicationDetails
} from '@f1/shared/src/data/crab/mockCrabV1Application';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockEmployeeList } from '../employee/root';
import {
  mockRelationshipGetFormatted,
  mockPreferredRelationships,
  mockElevatedRelationships,
  mockStandardRelationships
} from '../relationship/root';
import { filterEmployeeList } from '../_templateHelpers';
import { partnerGuids } from '../guids';

const getMockRelationship = (relationshipCode, riskProfile) => {
  const relationshipMap = {
    preferred: mockPreferredRelationships,
    standard: mockStandardRelationships,
    elevated: mockElevatedRelationships
  };
  const relationshipMatch = relationshipMap[riskProfile]
    ? relationshipMap[riskProfile].find((r) => r.relationshipCode === relationshipCode)
    : {};
  return {
    relationshipId: relationshipMatch.value,
    relationshipCode: relationshipMatch.relationshipCode,
    relationshipName: relationshipMatch.title,
    processName: relationshipMatch.processorName,
    bankName: relationshipMatch.bankName,
    riskProfile: relationshipMatch.riskProfile,
    childPartnerId: relationshipMatch.childPartnerId
  };
};

// Mock Preferred Risk - Repay Relationship
const mockPreferredRepayRelationship = getMockRelationship('20010', 'preferred');
const mockPreferredRepayNoCrabConfigRelationship = getMockRelationship('22365', 'preferred');

// Mock Elevated Risk - Repay Relationship
export const mockElevatedRepayRelationship = getMockRelationship('20013', 'elevated');

// Mock Elevated Risk - Fluid Pay (Repay) Relationship
export const mockElevatedFluidPayRelationship = getMockRelationship('20020', 'elevated');

export const mockElevatedMicampRelationship = getMockRelationship('20021', 'elevated');

// Mock Elevated Risk - Netevia Relationship
export const elevatedNeteviaRelationship = mockElevatedRelationships.find(
  (r) => r.relationshipCode === '11116'
);
const mockElevatedNeteviaRelationship = getMockRelationship('11116', 'elevated');

// Mock Elevated Risk - Priority Relationship
export const mockElevatedPriorityRelationship = getMockRelationship('232425', 'elevated');

const mockDefaultProcessorRelationships = {
  preferred: getMockRelationship('20000', 'preferred'),
  standard: getMockRelationship('20004', 'standard'),
  elevated: getMockRelationship('20003', 'elevated')
};

const mockAppReviewEmployees = filterEmployeeList({
  list: mockEmployeeList,
  allowedGroups: ['APP REVIEW', 'APP_REVIEW']
});
const mockAppReviewManagers = filterEmployeeList({
  list: mockEmployeeList,
  allowedGroups: ['APP REVIEW MANAGER', 'APP_REVIEW_MANAGER']
});

export const defaultAppReviewEmployee = mockAppReviewEmployees.find(
  (employee) => employee.firstName === 'Fred'
);
const defaultAppReviewManager = mockAppReviewManagers.find(
  (employee) => employee.firstName === 'Barney'
);

export const defaultAssignedAppReviewEmployee = {
  employeeId: defaultAppReviewEmployee.value,
  department: 'APP_REVIEW',
  firstName: defaultAppReviewEmployee.firstName, // Fred
  email: defaultAppReviewEmployee.email
};

export const assignedAppReviewManager = {
  employeeId: defaultAppReviewManager.value,
  department: 'APP_REVIEW',
  firstName: defaultAppReviewManager.firstName, // Barney
  email: defaultAppReviewManager.email
};

const staticApps = {
  // Apps that have specific static fields - used for FTs
  // GET /v1/application, GET /v1/application/detail
  2: {
    // Completed app (preferred risk)
    applicationStatus: 'approved',
    applicationName: 'App 02 - Completed - Preferred Risk',
    dbaNameList: ['App 02 - Completed - Preferred Risk'],
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-30T20:22:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:23:00Z',
    applicationCompletedTimestamp: '2019-12-30T20:24:00Z',
    signatureType: 'wet_signature'
  },
  3: {
    // FE - Repay/Preferred - no crab config options
    applicationStatus: 'waiting_on_app_review',
    applicationName: 'App 03 - FE - Repay/Preferred - no crab config options',
    dbaNameList: ['App 03 - FE - Repay/Preferred - no crab config options'],
    relationship: mockPreferredRepayNoCrabConfigRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:25:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:25:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  4: {
    // Preferred Repay App
    applicationStatus: 'waiting_on_app_review',
    applicationName: 'App 04 - Preferred Repay App',
    dbaNameList: ['App 04 - Preferred Repay App'],
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:21:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:21:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  5: {
    applicationStatus: 'waiting_on_initial_signature',
    applicationName: 'App 05 - Uncompleted - Preferred Risk - Signature',
    dbaNameList: ['App 05 - Uncompleted - Preferred Risk - Signature'],
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:20:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:20:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  6: {
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 06 - Repay/Preferred - Has Pend'],
    applicationName: 'App 06 - Repay/Preferred - Has Pend',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:18:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:17:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  9: {
    // Unassigned Employee - Preferred Risk
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 09 - Unassigned Employee - Preferred Risk'],
    applicationName: 'App 09 - Unassigned Employee - Preferred Risk',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:22:00Z',
    applicationSubmittedTimestamp: '2019-12-31T20:23:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: null,
    signatureType: 'wet_signature'
  },
  13: {
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 13 - All tasks approved but missing signature on at least one MPA'],
    applicationName: 'App 13 - All tasks approved but missing signature on at least one MPA',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T19:15:30Z',
    applicationSubmittedTimestamp: '2019-12-30T19:15:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: assignedAppReviewManager, // App Review Manager
    signatureType: 'wet_signature',
    requiresNewSignature: true
  },
  15: {
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 15 - Repay/Elevated'],
    applicationName: 'App 15 - Repay/Elevated',
    relationship: mockElevatedRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:12:30Z',
    applicationSubmittedTimestamp: '2019-12-30T21:12:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  16: {
    // Netevia/Elevated - Ready to Approve
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 16 - Netevia (Ready to Approve) - Elevated risk'],
    applicationName: 'App 16 - Netevia (Ready to Approve) - Elevated risk',
    relationship: mockElevatedNeteviaRelationship,
    applicationCreatedTimestamp: '2019-12-31T19:13:30Z',
    applicationSubmittedTimestamp: '2019-12-30T19:13:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  19: {
    // Waiting On Partner - Preferred Risk
    applicationStatus: 'waiting_on_partner',
    dbaNameList: ['App 19 - Electronic - Waiting on Partner'],
    applicationName: 'App 19 - Electronic - Waiting on Partner',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:12:00Z',
    applicationSubmittedTimestamp: '2019-12-31T20:23:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: assignedAppReviewManager, // App Review Manager
    signatureType: 'electronic_signature'
  },
  21: {
    // Completed app - Withdrawn (preferred risk)
    applicationStatus: 'withdrawn',
    dbaNameList: ['App 21 - Completed (Withdrawn) - Preferred Risk'],
    applicationName: 'App 21 - Completed (Withdrawn) - Preferred Risk',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-30T20:19:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:20:00Z',
    applicationCompletedTimestamp: '2019-12-30T20:21:00Z',
    signatureType: 'wet_signature'
  },
  23: {
    applicationStatus: 'waiting_on_bank',
    dbaNameList: ['App 23 - Repay/Elevated'],
    applicationName: 'App 23 - Repay/Elevated',
    relationship: mockElevatedRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:30:30Z',
    applicationSubmittedTimestamp: '2019-12-30T21:31:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  24: {
    // can Reclassify all pends ADDRESSED - FOR FT
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 24 - Can Reclassify - Wet'],
    applicationName: 'App 24 - Can Reclassify - Wet',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T20:30:30Z',
    applicationSubmittedTimestamp: '2019-12-30T21:31:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: assignedAppReviewManager, // App Review Manager
    signatureType: 'wet_signature'
  },
  25: {
    // Underwriting field blank
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 25 - Underwriting empty'],
    applicationName: 'App 25 - Underwriting empty',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T18:30:30Z',
    applicationSubmittedTimestamp: '2019-12-30T18:31:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature',
    employeeOnlyFields: {
      assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
      underwritingRiskLevel: null
    }
  },
  26: {
    // Netevia/Elevated Risk - Waiting on Bank
    applicationStatus: 'waiting_on_bank',
    dbaNameList: ['App 26 - Netevia/Elevated Risk - Waiting On Bank'],
    applicationName: 'App 26 - Netevia/Elevated Risk - Waiting On Bank',
    relationship: mockElevatedNeteviaRelationship,
    applicationCreatedTimestamp: '2019-12-31T19:17:30Z',
    applicationSubmittedTimestamp: '2019-12-30T19:17:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  28: {
    // Default Processor - Preferred
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 28 - Default Processor - Preferred'],
    applicationName: 'App 28 - Default Processor - Preferred',
    relationship: mockDefaultProcessorRelationships.preferred,
    applicationCreatedTimestamp: '2019-12-30T20:30:00Z',
    applicationSubmittedTimestamp: '2019-12-31T20:31:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  29: {
    // Decline reason present
    applicationStatus: 'declined',
    dbaNameList: ['App 29 - Decline reason'],
    applicationName: 'App 29 - Decline reason',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T18:31:30Z',
    applicationSubmittedTimestamp: '2019-12-30T18:32:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  30: {
    // Fluid Pay (Repay) - Elevated
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 30 - Fluid Pay (Repay) - Elevated'],
    applicationName: 'App 30 - Fluid Pay (Repay) - Elevated',
    relationship: mockElevatedFluidPayRelationship,
    applicationCreatedTimestamp: '2019-12-29T20:11:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:11:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  32: {
    // Micamp (MERRICK) - Elevated
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 32 - Micamp (MERRICK) - Elevated'],
    applicationName: 'App 32 - Micamp (MERRICK) - Elevated',
    relationship: mockElevatedMicampRelationship,
    applicationCreatedTimestamp: '2019-12-28T20:11:00Z',
    applicationSubmittedTimestamp: '2019-12-29T20:11:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  34: {
    // App for testing Application Files Only (for FT)
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 34 - App for testing Application Files Only (for FT)'],
    applicationName: 'App 34 - App for testing Application Files Only (for FT)',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T21:28:00Z',
    applicationSubmittedTimestamp: '2019-12-30T21:27:00Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'electronic_signature'
  },
  35: {
    applicationStatus: 'waiting_on_bank_allotment',
    dbaNameList: ['App 35 - allotment test app'],
    applicationName: 'App 35 - allotment test app',
    relationship: mockElevatedRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T22:30:30Z',
    applicationSubmittedTimestamp: '2019-12-30T22:31:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  36: {
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 36 - Priority App for approval'],
    applicationName: 'App 36 - Priority App for approval',
    relationship: mockElevatedPriorityRelationship,
    applicationCreatedTimestamp: '2019-12-31T22:10:30Z',
    applicationSubmittedTimestamp: '2019-12-30T22:31:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  37: {
    applicationStatus: 'api_boarded_to_processor',
    dbaNameList: ['App 37 - Boarded to Processor'],
    applicationName: 'App 37 - Boarded to Processor',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T22:00:30Z',
    applicationSubmittedTimestamp: '2019-12-30T22:31:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  },
  38: {
    applicationStatus: 'waiting_on_app_review',
    dbaNameList: ['App 38 - Will not complete tasks'],
    applicationName: 'App 38 - Will not complete tasks',
    relationship: mockPreferredRepayRelationship,
    applicationCreatedTimestamp: '2019-12-31T21:00:30Z',
    applicationSubmittedTimestamp: '2019-12-30T21:31:30Z',
    applicationCompletedTimestamp: null,
    assignedAppReviewEmployee: defaultAssignedAppReviewEmployee,
    signatureType: 'wet_signature'
  }
};

// Create guid id (index) to relationship id map so GET application and
// GET application detail uses the same relationship id
export const mockGuidToRelationshipMap = Array.from({ length: 100 }).reduce((acc, item, index) => {
  const idNum = index + 1;
  const mockRiskProfile = getMockValue({
    type: 'list',
    list: ['elevated', 'standard', 'preferred']
  });
  const mockRelationshipMap = {
    preferred: mockPreferredRelationships[0].value,
    standard: mockStandardRelationships[0].value,
    elevated: mockElevatedRelationships[0].value
  };
  const defaultMockGuid = mockRelationshipMap[mockRiskProfile];
  return {
    ...acc,
    // if relationship is assigned in `staticApps`, use it
    [idNum]: staticApps[idNum]
      ? staticApps[idNum].relationship?.relationshipId || defaultMockGuid
      : defaultMockGuid
  };
}, {});

const mockGuidToPartnerMap = {
  // apps with specific `partner` details
  3: {
    partnerId: partnerGuids.partner09,
    partnerBusinessCode: '1596',
    partnerName: 'FE - Partner with no crab config options'
  }
};

// Don't include the default assigned employee for random mock apps
export const mockAppReviewEmployeesRandomApps = mockAppReviewEmployees.filter(
  (employee) => employee.employeeId !== defaultAppReviewEmployee.employeeId
);

// Create list of ALL mock applications ONCE so paged data doesn't get recreated multiple times
const mockAllApps = createMockApplications({
  staticApps,
  userType: 'employee',
  employeeList: {
    appReview: mockAppReviewEmployeesRandomApps
  },
  relationshipList: mockRelationshipGetFormatted,
  mockGuidToPartnerMap,
  mockGuidToRelationshipMap
});

export const mockEmployeeCrabV1ApplicationGet = (body, params, options) => {
  const { guid } = options || {};
  const mockData = mockCrabV1ApplicationGet(body, params, { ...options, mockAllApps, staticApps });
  return { [guid]: mockData };
};

export const mockEmployeeCrabV1ApplicationDetailsGet = (body, params, options) => {
  const { guid } = options || {};
  const appsWithStaticMpas = {
    // Map of appId (from `staticApps` above) to { mpaIndex: mpaMetadata }
    30: {
      0: { dbaName: 'DBA Name 1' },
      1: { dbaName: 'DBA Name 2' }
    }
  };
  const appMatch = mockAllApps.find((app) => app.applicationId === guid);
  const mockData = createMockApplicationDetails(guid, {
    mockAppMatch: appMatch,
    relationshipList: mockRelationshipGetFormatted,
    mockGuidToRelationshipMap,
    mockGuidToPartnerMap,
    appsWithStaticMpas,
    staticApps
  });
  return { [guid]: mockData };
};
