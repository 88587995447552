import { partnerGuids } from '../guids';
import { createMockOnboardingData } from '../relationship/boarding';

const mockPartnerBoardingData = {
  [partnerGuids.default]: {
    header: {
      label: 'month',
      lines: ['total'],
      biaxial: ['total'],
      currency: null,
      biaxialCurrency: null
    },
    data: [
      {
        total: 0,
        month: '2020-02',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-03',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-04',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-05',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-06',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-07',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 1,
        month: '2020-08',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 1
      }
    ]
  }
};

export const mockPartnerBoardingGet = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: mockPartnerBoardingData[guid] || createMockOnboardingData('partner') };
};

export default mockPartnerBoardingGet;
