// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Spinner, AlertBar, Button, ComboBox, Dropzone, Input } from '../index';
import {
  isBool,
  toCamelCase,
  fileTagValues,
  ignoreCase,
  uploadCacheFilesToS3,
  handleLoadedFiles,
  sortData,
  transformData,
  isEmpty,
  sharedGetInnerAlertBarState
} from './_helpers';
import { inlineFilesFormSectionCSS } from './_styles';
import { filePreloadTemplate } from './data/templates/filePreloadTemplate';
import { fileAttachToResourceTemplate } from './data/templates/fileAttachToResourceTemplate';
import filesWithTagsTemplate, {
  getTagObjects
} from './data/sharedBoarding/templates/filesWithTagsTemplate';
import { FilesActionBar } from './FilesActionBar';
import { ErrorBox } from '../css/_styledFormComponents';

class FilesArea extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { displayedFields = [] } = props;
    this.allowSelectFileType = displayedFields.includes('selectFileType');
    this.allowTagList = displayedFields.includes('tagList');
    this.hasFileOptions = this.allowSelectFileType || this.allowTagList;
    this.systemGeneratedTags = fileTagValues
      .filter((t) => t.visible === false || t.isFixed === true)
      .reduce(
        (acc, t) =>
          acc.concat(
            ignoreCase(t.title),
            ignoreCase(t.title.replace(/[-]/g, ' ')),
            ignoreCase(t.value),
            ignoreCase(t.value.replace(/[_]/g, ' '))
          ),
        []
      );
    this.state = {
      spinnerLoading: false,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      fileRemoved: [],
      selectTagList: [],
      selectFileTypeList: [],
      currentDroppedFiles: [],
      invalidTagFileNames: [],
      dupeFileNames: [],
      allFilesAddedSuccess: false,
      enableSubmit: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { requiredFiles } = this.props;
    if (this.allowSelectFileType && requiredFiles.length !== prevProps.requiredFiles.length) {
      this.loadSelectFileTypeList();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  initialize = () => {
    const { allFilesAddedSuccess } = this.state;
    if (!allFilesAddedSuccess) {
      if (this.allowTagList) {
        this.loadTagList();
      }
      if (this.allowSelectFileType) {
        this.loadSelectFileTypeList();
      }
    }
  };

  loadTagList = () => {
    const { userType } = this.props;
    const tagListItems =
      userType === 'employee'
        ? fileTagValues.filter((t) => !t.isFixed)
        : fileTagValues.filter((t) => !t.isFixed && !t.isInternal);
    this.updateState({ selectTagList: sortData(tagListItems, 'title') });
  };

  loadSelectFileTypeList = () => {
    const { forceReplaceFileName, customFileTypeList, requiredFiles, userType } = this.props;
    const startingList = !isEmpty(customFileTypeList) ? customFileTypeList : fileTagValues;
    const fileTypeList = startingList
      .filter((t) => (userType === 'employee' ? !t.isFixed : !t.isInternal && !t.isFixed))
      .map((t) => ({
        displayText: t.title,
        backendKey: toCamelCase(t.value, '_'),
        replaceFileName: isBool(t.replaceFileName) ? t.replaceFileName : forceReplaceFileName,
        tagList: [t.value],
        useFileNameInput: isBool(t.useFileNameInput) ? t.useFileNameInput : userType === 'employee',
        ...(t.value === 'other' && { useFileNameInput: true })
      }));
    const mappedRequiredFilesList = !isEmpty(requiredFiles)
      ? fileTypeList.reduce(
          (acc, fileTypeItem) => {
            const optionMatch = acc.find(
              (accItem) =>
                toCamelCase(accItem.backendKey, '_') === fileTypeItem.backendKey ||
                (accItem.tagList || []).some((t) => (fileTypeItem.tagList || []).includes(t))
            );
            return !isEmpty(optionMatch) ? acc : acc.concat(fileTypeItem);
          },
          [...requiredFiles]
        )
      : [];
    const listToMap = !isEmpty(requiredFiles) ? mappedRequiredFilesList : fileTypeList;
    const formattedFileTypeList = [...listToMap].reduce((acc, file) => {
      const { allowedOptions } = file || {};
      if (!isEmpty(allowedOptions)) {
        const formattedOptions = allowedOptions.map((opt) => this.formatListOption(opt));
        return acc.concat(...formattedOptions);
      }
      return acc.concat(this.formatListOption(file));
    }, []);
    this.updateState({ selectFileTypeList: sortData(formattedFileTypeList, 'title') });
  };

  handleDropzone = (options) => {
    const { files = [], fileRemoved, error } = options || {};
    const { isFormDirty, requiredFiles, existingFiles } = this.props;
    this.updateState({ allFilesAddedSuccess: false, alertBarType: 'closed', alertBarMessage: '' });
    !isEmpty(isFormDirty) && isFormDirty(true);
    const startingFiles = Array.isArray(files) ? files : Object.values(files);
    const filesToCheck = this.getStartingFiles(startingFiles);
    const newDropzoneFiles = filesToCheck.filter((newFile) => {
      const latestDroppedFiles = this.getLatestDroppedFiles();
      const existingFile = latestDroppedFiles.find(
        (currentFile) => newFile.name === currentFile.name
      );
      return isEmpty(existingFile);
    });
    const dupeFiles = [...requiredFiles, ...existingFiles].filter((currentFile) => {
      // file names must be unique
      const dupeFile = newDropzoneFiles.find((addedFile) => addedFile?.name === currentFile?.name);
      return !isEmpty(dupeFile);
    });
    if (!isEmpty(dupeFiles)) {
      dupeFiles
        .filter((dupe) => !isEmpty(dupe.name))
        .forEach((dupe) => {
          this.updateState({ fileRemoved: dupe.name });
        });
      this.handleError({ error: 'Sorry, no duplicate filenames allowed' });
    } else if (isEmpty(this.getLatestDroppedFiles())) {
      // Adding all new non-duped files
      const formattedFiles = !isEmpty(filesToCheck) ? this.formatCurrentFiles(filesToCheck) : [];
      this.updateState(
        {
          ...(fileRemoved !== undefined && { fileRemoved }),
          currentDroppedFiles: formattedFiles
        },
        this.validateFiles
      );
    } else {
      const newFiles = newDropzoneFiles.filter((newFile) => {
        const latestDroppedFiles = this.getLatestDroppedFiles();
        // This file was already added to dropzone
        const existingFile = latestDroppedFiles.find(
          (dropFile) => dropFile?.originalFileName === newFile?.name
        );
        return isEmpty(existingFile);
      });
      const newFormattedFiles = !isEmpty(newFiles) ? this.formatCurrentFiles(newFiles) : [];
      const latestDroppedFiles = this.getLatestDroppedFiles();
      this.updateState(
        {
          ...(fileRemoved !== undefined && { fileRemoved }),
          currentDroppedFiles: latestDroppedFiles.concat(newFormattedFiles)
        },
        this.validateFiles
      );
    }
    if (!isEmpty(error?.message)) {
      // handle error received from Dropzone
      this.handleError({ error: error.message });
    }
  };

  getLatestDroppedFiles = () => {
    // to always ensure we get the latest currentDroppedFiles in state
    const { currentDroppedFiles } = this.state;
    return !isEmpty(currentDroppedFiles) ? [...currentDroppedFiles] : [];
  };

  getStartingFiles = (files) => {
    const { maxAddFileCount } = this.props;
    const { currentDroppedFiles } = this.state;
    const hasMaxUploadCount = !isEmpty(maxAddFileCount) && maxAddFileCount > 0;
    const filesArray = Array.isArray(files) ? files : Object.values(files);
    const startingFiles = hasMaxUploadCount
      ? [...filesArray].reverse() // show newest files first
      : [...filesArray];
    if (
      !hasMaxUploadCount ||
      (hasMaxUploadCount && startingFiles.length <= maxAddFileCount && isEmpty(currentDroppedFiles))
    ) {
      return startingFiles;
    }
    if (hasMaxUploadCount) {
      if (startingFiles.length > maxAddFileCount) {
        // User trying to add more files than allowed
        this.updateState(
          (prevState) => ({
            fileRemoved: [
              ...(!isEmpty(prevState.currentDroppedFiles) ? prevState.currentDroppedFiles : []),
              ...(!isEmpty(startingFiles)
                ? startingFiles.slice(maxAddFileCount, startingFiles.length)
                : [])
            ].map((f) => f.originalFileName || f.fileName || f.name),
            currentDroppedFiles: [] // clear existing files in state
          }),
          this.resetDropzone
        );
        const newFiles = startingFiles.slice(0, maxAddFileCount);
        return newFiles;
      }
      if (!isEmpty(startingFiles) && !isEmpty(currentDroppedFiles)) {
        // User has existing dropped files in dropzone
        const combinedFiles = [
          ...(!isEmpty(startingFiles)
            ? startingFiles.map((f) => ({ ...f, isStartingFile: true }))
            : []),
          ...(!isEmpty(currentDroppedFiles)
            ? currentDroppedFiles.map((f) => ({ ...f, isDroppedFile: true }))
            : [])
        ];
        if (combinedFiles.length > maxAddFileCount) {
          // New files + existing dropped files exceeds max number of files
          let currentCount = 0;
          const startingAcc = {
            newFiles: [],
            newExistingDroppedFiles: [],
            filesToRemove: []
          };
          const { newFiles, newExistingDroppedFiles, filesToRemove } = combinedFiles.reduce(
            (acc, file) => {
              if (currentCount < maxAddFileCount) {
                if (file.isStartingFile) {
                  currentCount += 1;
                  return {
                    ...acc,
                    newFiles: (acc.newFiles || []).concat(file)
                  };
                }
                if (file.isDroppedFile) {
                  currentCount += 1;
                  return {
                    ...acc,
                    newExistingDroppedFiles: (acc.newExistingDroppedFiles || []).concat(file)
                  };
                }
              }
              return currentCount === maxAddFileCount
                ? {
                    ...acc,
                    filesToRemove: (acc.filesToRemove || []).concat(file)
                  }
                : acc;
            },
            startingAcc
          );
          this.updateState(
            {
              fileRemoved: !isEmpty(filesToRemove)
                ? filesToRemove.map((f) => f.originalFileName || f.fileName || f.name)
                : [],
              currentDroppedFiles: !isEmpty(newExistingDroppedFiles) ? newExistingDroppedFiles : []
            },
            this.resetDropzone
          );
          return newFiles;
        }
      }
    }
    return startingFiles;
  };

  formatCurrentFiles = (files) => {
    const { defaultTagsOnAdd, fileNameCustomValidation, userType } = this.props;
    const { currentDroppedFiles } = this.state;
    const filteredFiles = (!isEmpty(files) ? files : []).filter((dropFile) => {
      const existingFile = (currentDroppedFiles || []).find(
        (fileInState) => fileInState?.name === dropFile?.name
      );
      return isEmpty(existingFile);
    });
    const filesToFormat = filteredFiles.map((newFile) => {
      const fileNameDetails = this.getFileNameDetails(newFile, { setFileExtension: true });
      return {
        ...newFile,
        fileName: fileNameDetails.name,
        fileOptions: {
          ...(newFile.path && {
            customPath: newFile.customPath,
            customPathPrefix: newFile.customPathPrefix,
            path: newFile.path
          }),
          fileData: newFile?.data || {},
          encoded: newFile?.encoded || '',
          originalFileName: fileNameDetails.name,
          fileExtension: fileNameDetails.fileExtension,
          fileNameOnly: fileNameDetails.fileNameOnly,
          uniqueId: uuidv4()
        }
      };
    });
    const formattedFiles = transformData({
      data: {
        filesList: filesToFormat.map((file) => ({
          ...file,
          tags: getTagObjects({
            userType,
            addingNewFiles: true,
            tags: !isEmpty(defaultTagsOnAdd) ? defaultTagsOnAdd : []
          })
        })),
        fileNameCustomValidation,
        noSort: true,
        userType
      },
      toSchema: 'frontend',
      template: filesWithTagsTemplate
    });
    return formattedFiles;
  };

  getFileNameDetails = (file, options) => {
    const { originalFileName, setFileExtension } = options || {};
    const { maxAddFileCount, fileNameOverride } = this.props;
    const fileName = !isEmpty(originalFileName)
      ? originalFileName
      : file?.fileName || file?.name || '';
    const newFileName =
      !isEmpty(fileNameOverride) && maxAddFileCount === 1 ? fileNameOverride : fileName;
    const fileExtension = setFileExtension ? newFileName.split('.').pop() : file.fileExtension;
    const fileNameOnly = newFileName.split(`.${fileExtension}`).join('');
    const fileDetails = {
      name: newFileName,
      displayName: newFileName,
      fileExtension,
      fileNameOnly
    };
    return fileDetails;
  };

  formatListOption = (option) => {
    if (option.title) {
      return option;
    }
    return {
      ...option,
      title: option.displayText,
      value: option.backendKey,
      tagList: option.tagList || []
    };
  };

  handleEnterFileName = (id, value, valid) => {
    this.updateState(
      (prevState) => ({
        currentDroppedFiles: (prevState.currentDroppedFiles || []).reduce((acc, dropFile) => {
          const { uniqueId: dropFileId } = dropFile || {};
          if (dropFileId === id) {
            const newDropFile = { ...dropFile, name: `${value}.${dropFile.fileExtension}` };
            const fileNameDetails = this.getFileNameDetails(newDropFile);
            return acc.concat({ ...dropFile, fileNameValid: valid, ...fileNameDetails });
          }
          return acc.concat(dropFile);
        }, [])
      }),
      this.validateFiles
    );
  };

  handleSelectFileType = (options) => {
    const {
      tagList, // don't include this at file level as it will override tagList dropdown selections
      id,
      useFileNameInput,
      ...rest
    } = options || {};
    const { allowEditFileName } = this.props;
    this.updateState(
      (prevState) => ({
        currentDroppedFiles: (prevState.currentDroppedFiles || []).reduce((acc, dropFile) => {
          const fileNameDetails = this.getFileNameDetails(dropFile, {
            ...(useFileNameInput !== true &&
              allowEditFileName !== true && { originalFileName: dropFile.originalFileName })
          });
          if (dropFile?.uniqueId === id) {
            const newFile = {
              ...dropFile,
              id,
              ...rest,
              useFileNameInput: useFileNameInput ?? allowEditFileName ?? false,
              ...fileNameDetails,
              selectedFileType: options
            };
            return acc.concat(newFile);
          }
          return acc.concat(dropFile);
        }, [])
      }),
      this.validateFiles
    );
  };

  handleTags = (options) => {
    const { id, value } = options;
    this.updateState(
      (prevState) => ({
        currentDroppedFiles: (prevState.currentDroppedFiles || []).reduce(
          (acc, dropFile) =>
            dropFile?.uniqueId === id
              ? acc.concat({ ...dropFile, tagList: value })
              : acc.concat(dropFile),
          []
        )
      }),
      this.validateFiles
    );
  };

  handleDeleteFile = (options) => {
    const { file: removedFile } = options || {};
    this.updateState(
      (prevState) => ({
        fileRemoved: removedFile.originalFileName,
        currentDroppedFiles: (prevState.currentDroppedFiles || []).filter(
          (f) => f?.uniqueId !== removedFile?.uniqueId
        )
      }),
      () => this.validateFiles({ hasFilesRemoved: true })
    );
  };

  validateFiles = (options) => {
    const { hasFilesRemoved } = options || {};
    const { allFilesAddedSuccess, currentDroppedFiles } = this.state;
    const { isFormDirty } = this.props;
    const {
      invalidTagFileNames,
      invalidFileNames,
      dupeFileNames,
      filesMissingTags,
      allFilesValid
    } = this.filesValid(currentDroppedFiles);
    if (!allFilesAddedSuccess) {
      isEmpty(currentDroppedFiles) && !isEmpty(isFormDirty) && isFormDirty(false);
      this.updateState(
        {
          enableSubmit: allFilesValid,
          dupeFileNames,
          invalidFileNames,
          filesMissingTags,
          invalidTagFileNames
        },
        () =>
          this.handleValidateFilesCallback({
            hasFilesRemoved,
            filesInProgress: true
          })
      );
    }
  };

  handleValidateFilesCallback = (options) => {
    const { hasFilesRemoved } = options || {};
    const { validateFilesCallback } = this.props;
    const { currentDroppedFiles } = this.state;
    if (validateFilesCallback) {
      const cbOptions = {
        ...options,
        ...(hasFilesRemoved &&
          isEmpty(currentDroppedFiles) && {
            // If all dropped files were removed, files section is no longer in progress
            filesInProgress: false
          })
      };
      validateFilesCallback(cbOptions);
    }
  };

  filesValid = (files) => {
    const { existingFiles } = this.props;
    const fileNames = [...files, ...existingFiles].map((file) => ignoreCase(file.name || ''));
    const dupeFileNames = [];
    const invalidFileNames = [];
    const invalidTagFileNames = [];
    const valid = files.every((file) => {
      const hasNoTags = this.allowTagList ? isEmpty(file?.tagList) : false;
      const hasInvalidTags =
        this.allowTagList && !isEmpty(file?.tagList)
          ? file.tagList.some((t) => this.systemGeneratedTags.includes(ignoreCase(t.label)))
          : false;
      const hasMissingFileType = this.allowSelectFileType ? isEmpty(file?.selectedFileType) : false;
      const hasEmptyFileNames = fileNames.filter((name) => isEmpty(name)).length > 0;
      const isDupeFileName =
        fileNames.filter((name) => name === ignoreCase(file.name || '')).length > 1;
      isDupeFileName && dupeFileNames.push(file.name);
      const isInvalidFileName = isBool(file.fileNameValid) ? !file.fileNameValid : false;
      isInvalidFileName && invalidFileNames.push(file.name);
      hasInvalidTags && invalidTagFileNames.push(file.name);
      return (
        !hasEmptyFileNames &&
        !isDupeFileName &&
        !hasMissingFileType &&
        !hasInvalidTags &&
        !hasNoTags &&
        !isInvalidFileName
      );
    });
    return {
      dupeFileNames,
      invalidTagFileNames,
      invalidFileNames,
      allFilesValid: valid
    };
  };

  shouldReplaceFileName = (file) => {
    // replace the user's dropped file name with the `backendKey` file name
    const replace =
      file.replaceFileName === true && file.useFileNameInput !== true && !isEmpty(file.backendKey);
    return replace;
  };

  handleCacheFiles = async (filesToUpload) => {
    const {
      axiosRequest,
      cacheUploadEndpoint,
      isPublicRequest,
      useParentCacheErrorSwal, // If true, then parent component must handle cache/s3 upload errors
      requiredFiles
    } = this.props;
    const cacheResults = await uploadCacheFilesToS3(filesToUpload, {
      useUploadErrorSwal: !useParentCacheErrorSwal,
      axiosRequest,
      cacheUploadEndpoint,
      filePreloadTemplate,
      isPublicRequest,
      requiredFiles,
      updateState: this.updateState
    });
    const { originalFilesToUpload, cacheAndS3ErrorFiles, filesWithS3Key } = cacheResults || {};
    this.handleFilesAddedSuccess({
      cacheAndS3ErrorFiles,
      originalFilesToUpload: !isEmpty(originalFilesToUpload)
        ? originalFilesToUpload
        : filesToUpload || [],
      filesValid: true,
      formattedUploadFiles: filesWithS3Key,
      formattedFilesToAdd: filesWithS3Key
    });
  };

  handleAddFiles = async () => {
    const { currentDroppedFiles } = this.state;
    const {
      requiredFiles,
      existingFiles,
      axiosRequest,
      preloadEndpoint,
      useCacheUpload,
      useNewFormat,
      requestGuid,
      onlyEncodeData
    } = this.props;
    const sendTagList = useNewFormat || this.allowSelectFileType || this.allowTagList;
    const filesToUpload = currentDroppedFiles
      .filter((file) => !isEmpty(file?.fileData))
      .reduce((acc, file) => {
        const overrideFileName = this.shouldReplaceFileName(file);
        let fileName = `${file.name}`;
        if (overrideFileName) {
          // If adding multiple files with the same 'Select File Type' option
          const accFileMatches = !isEmpty(acc)
            ? acc.filter((f) => f?.name?.startsWith(file.backendKey))
            : [];
          // Find all existing files that start with the same `backendKey`
          const fileNumbers = [...existingFiles, ...accFileMatches]
            .filter((eFile) => eFile.name.startsWith(file.backendKey))
            .map((eFile) => {
              // Standard naming convention: `backendKey` with underscore - ie, `${backendKey}_`
              // eg, driversLicense_1
              const fileWithExt = eFile.name.split(`${file.backendKey}_`).pop();
              const fileNumOnly = fileWithExt.split('.').shift();
              return typeof fileNumOnly === 'string' ? Number(fileNumOnly) : fileNumOnly;
            });
          /**
           * Get the highest fileNumber to increment off of. NOT using array length
           * to prevent appending a duplicate fileNumber if user deletes an existing file
           */
          const highestNum = fileNumbers.reduce((currentNum, fileNumber) => {
            const numberToTest = typeof fileNumber === 'string' ? Number(fileNumber) : fileNumber;
            return numberToTest > currentNum ? numberToTest : currentNum;
          }, 0);
          const increment = !isEmpty(highestNum) ? highestNum + 1 : 1;
          const newFileName = `${file.backendKey}_${increment}`;
          const replaceKey = fileName.startsWith(file.backendKey)
            ? file.backendKey
            : file.fileNameOnly;
          fileName = fileName.replace(replaceKey, newFileName);
        }
        const fileNameDetails = overrideFileName
          ? this.getFileNameDetails({ ...file, name: fileName })
          : { name: fileName };
        const fileTags = sendTagList
          ? [
              ...new Set([
                // remove dupes
                ...(this.allowTagList && !isEmpty(file.tagList)
                  ? file.tagList.map((t) => (!isEmpty(t.value) ? t.value : t))
                  : []),
                ...(this.allowSelectFileType && !isEmpty(file?.selectedFileType?.tagList)
                  ? file.selectedFileType.tagList.map((t) => (!isEmpty(t.value) ? t.value : t))
                  : [])
              ])
            ]
          : [];
        const newFile = {
          ...file.fileData,
          data: file.fileData,
          localFileUrl: URL.createObjectURL(file.fileData),
          encoded: file.encoded,
          originalFileName: file.name,
          ...fileNameDetails,
          ...(file.path && {
            customPath: file.customPath,
            customPathPrefix: file.customPathPrefix,
            path: file.path
          }),
          ...(this.allowSelectFileType &&
            !isEmpty(file.selectedFileType) && {
              backendKey: file.selectedFileType.backendKey
            }),
          ...(sendTagList && {
            tagList: !isEmpty(fileTags) ? fileTags : []
          })
        };
        return acc.concat(newFile);
      }, []);
    const useV2 = useNewFormat && sendTagList;
    const filesRequestBody = transformData({
      data: useV2 ? { files: filesToUpload } : filesToUpload,
      toSchema: 'backend',
      template: useNewFormat ? fileAttachToResourceTemplate : filePreloadTemplate,
      version: useV2 ? '2.0' : '1.0'
    });
    if (!isEmpty(filesToUpload) && useCacheUpload) {
      this.handleCacheFiles(filesToUpload);
    } else if (!isEmpty(filesRequestBody?.files) && !isEmpty(preloadEndpoint)) {
      // preload is currently only being used by endpoints that return S3 upload locations
      // then handleLoadedFiles() takes those S3 links and actually uploads them to the bucket
      // then, finally, this returns the file data so you complete the file upload process in parent
      // If using Shared DropzoneModal, please use uploadEndpoint for the endpoint that will
      // return S3 formatted data
      this.updateState({ spinnerLoading: true });
      const apiRes = await axiosRequest(
        {
          fullPageLoad: false,
          url: preloadEndpoint,
          method: 'put',
          ...(!isEmpty(requestGuid) && { requestGuid })
        },
        filesRequestBody
      );
      this.updateState({ spinnerLoading: false, allFilesAddedSuccess: false });
      if (apiRes?.errorDetails instanceof Error) {
        this.handleError({
          error: apiRes.errorDetails,
          ...(apiRes?.state?.status === 409 && {
            error:
              'Sorry, file name(s) already exist. Please update your file name(s) and try again.'
          })
        });
      } else {
        const filesResArray = apiRes?.data?.files || apiRes?.data || [];
        const mappedFilesWithBackendKeys = filesResArray.map((resFile) => {
          const dropFileMatch =
            currentDroppedFiles.find((dropFile) => resFile?.fileName === dropFile?.name) || {};
          return {
            ...resFile,
            backendKey: dropFileMatch?.backendKey,
            fileData: dropFileMatch?.fileData
          };
        });
        const formattedUploadFiles = transformData({
          data: {
            responseArray: mappedFilesWithBackendKeys,
            requiredFilesList: requiredFiles || []
          },
          toSchema: 'frontend',
          template: filePreloadTemplate,
          version: '1.0'
        });
        this.updateState({ spinnerLoading: true });
        const results = await handleLoadedFiles({
          axiosRequest,
          encodedFiles: filesToUpload,
          uploadFiles: formattedUploadFiles,
          hideFullPageAlertBar: true
        });
        this.updateState({ spinnerLoading: false });
        const { filesValid, error } = results;
        if (filesValid) {
          this.handleFilesAddedSuccess({
            apiRes,
            results,
            formattedUploadFiles,
            formattedFilesToAdd: filesToUpload
          });
        } else {
          this.handleError({ error });
        }
      }
    } else if (onlyEncodeData) {
      this.handleFilesAddedSuccess({
        formattedFilesToAdd: filesToUpload
      });
    } else {
      this.handleError({ error: 'Failed to preload files' });
    }
  };

  handleFilesAddedSuccess = (options) => {
    const { useCacheUpload, useNewFormat, isFormDirty } = this.props;
    const {
      cacheAndS3ErrorFiles, // cacheUploads only
      originalFilesToUpload, // cacheUploads only
      apiRes,
      results,
      formattedFilesToAdd,
      formattedUploadFiles
    } = options || {};
    !isEmpty(isFormDirty) && isFormDirty(false);
    this.updateState(
      {
        enableSubmit: false,
        allFilesAddedSuccess: true,
        fileRemoved: (useCacheUpload ? originalFilesToUpload || [] : formattedFilesToAdd || []).map(
          (f) => f.originalFileName ?? f.fileName
        ),
        currentDroppedFiles: []
      },
      () => {
        this.resetDropzone();
        const filesLoaded = formattedFilesToAdd.map((addedFile) => {
          const uploadFileMatch = !isEmpty(formattedUploadFiles)
            ? formattedUploadFiles.find((uploadFile) => uploadFile.fileName === addedFile.name) ||
              {}
            : {};
          const { backendKey: uploadBackendKey, ...restOfUploadFileMatch } = uploadFileMatch || {};
          const formattedFile = {
            ...addedFile,
            ...(!isEmpty(uploadBackendKey) && { backendKey: uploadBackendKey }),
            ...restOfUploadFileMatch,
            // Override upload response url with local file url so user can open file
            ...(addedFile.localFileUrl && { url: addedFile.localFileUrl })
          };
          return formattedFile;
        });
        const cbOptions = {
          ...results,
          allFilesLoaded: true,
          formattedFilesToAdd,
          formattedUploadFiles,
          filesLoaded,
          ...(useCacheUpload && { cacheAndS3ErrorFiles }),
          ...(useNewFormat && { preloadRes: apiRes })
        };
        this.handleCallback(cbOptions);
      }
    );
  };

  resetDropzone = () => {
    this.updateState({ fileRemoved: [] });
  };

  handleError = (options) => {
    const { error } = options || {};
    const { axiosRequest } = this.props;
    const alertBarState = sharedGetInnerAlertBarState({
      type: 'warning',
      data: error,
      axiosRequest
    });
    this.updateState(alertBarState);
  };

  handleCallback = (options) => {
    const { callback } = this.props;
    callback && callback(options);
  };

  render() {
    const {
      spinnerLoading,
      alertBarType,
      alertBarMessage,
      alertBarTimeout,
      fileRemoved,
      enableSubmit,
      currentDroppedFiles,
      dupeFileNames,
      invalidTagFileNames,
      selectTagList,
      selectFileTypeList
    } = this.state;
    const {
      id,
      fileRequirements,
      userType,
      fileNameErrorMessage,
      fileNameCustomValidation,
      maxAddFileCount,
      allowEditFileName,
      existingFiles,
      disableFormFields,
      enableDropzone,
      disableCustomTags,
      wrapperStyle,
      dropzoneSize,
      requiredFiles,
      loadMessage
    } = this.props;
    const displayDropzone = enableDropzone && !disableFormFields;
    return (
      <div id={id} className="drop-files-area" style={{ position: 'relative', ...wrapperStyle }}>
        <AlertBar
          options={{
            barStyle: alertBarType,
            message: alertBarMessage,
            timeout: alertBarTimeout,
            customWarnStyle: { zIndex: '8', width: '100%', height: '100%' }
          }}
          callback={this.updateState}
          useInnerAlertBar
        />
        <Spinner loading={spinnerLoading} />
        {(!isEmpty(fileRequirements) || (!isEmpty(maxAddFileCount) && maxAddFileCount > 0)) && (
          <div style={inlineFilesFormSectionCSS.fileReqWrapper}>
            <strong>File Requirements</strong>
            <ul style={inlineFilesFormSectionCSS.fileReqUl}>
              <li style={inlineFilesFormSectionCSS.fileReqLi}>
                Files must be less than 4.9 GB in size.
              </li>
              {!isEmpty(maxAddFileCount) && (
                <>
                  {maxAddFileCount > 1 && (
                    <li style={inlineFilesFormSectionCSS.fileReqLi}>File names must be unique.</li>
                  )}
                  {maxAddFileCount > 0 && (
                    <li
                      style={
                        inlineFilesFormSectionCSS.fileReqLi
                      }>{`Only ${maxAddFileCount} file${maxAddFileCount === 1 ? '' : 's'} may be uploaded.`}</li>
                  )}
                </>
              )}
              {fileRequirements.map((fileReq) => (
                <li key={fileReq} style={inlineFilesFormSectionCSS.fileReqLi}>
                  {fileReq}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div
          className="dropzoneWrapper"
          style={{ ...inlineFilesFormSectionCSS.dropzoneWrapper, flex: '100%' }}>
          {displayDropzone && (
            <Dropzone
              id="dropzoneSection"
              callback={this.handleDropzone}
              fileRemoved={fileRemoved}
              customDropAreaText="Paste or drag files here, or click to add files"
              {...((!isEmpty(requiredFiles) || !isEmpty(dropzoneSize)) && {
                size: dropzoneSize ?? 'small'
              })}
              styles={inlineFilesFormSectionCSS.dropzone}
              existingFiles={existingFiles}
            />
          )}
          <div
            className="current-drop-files"
            style={{ display: 'flex', flexDirection: 'column', flex: '1' }}
            role="article"
            aria-label="Current files">
            {loadMessage && currentDroppedFiles.length <= 0 && (
              <div style={{ color: 'var(--color-warning)' }}>
                <strong>Please note: </strong>
                {loadMessage}
              </div>
            )}
            {currentDroppedFiles.map((file, i) => {
              const canEditFileName =
                file?.selectedFileType?.useFileNameInput === true || allowEditFileName;
              return (
                <div
                  key={file.uniqueId}
                  id={file.name}
                  className="drop-file-row"
                  role="listitem"
                  aria-label={`${file.name} file row`}
                  style={{
                    ...(i % 2 === 0 && { backgroundColor: 'var(--color-air)' }),
                    padding: '0.3em',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                  }}>
                  <div
                    className="drop-file-name-wrapper"
                    style={{ display: 'flex', margin: '0 0.1em', flex: '1' }}>
                    {!disableFormFields && (
                      <FilesActionBar
                        userType={userType}
                        /* User should always be able to remove a dropped file */
                        file={{ ...file, userCanDelete: true }}
                        wrapperStyle={{ display: 'block', margin: '0 0.2em', paddingTop: '24px' }}
                        deleteFileCallback={this.handleDeleteFile}
                        noSwal
                      />
                    )}
                    <div style={{ display: 'flex', flex: '2', fontSize: '1.2rem' }}>
                      <Input
                        id={file.uniqueId}
                        className="file-name-input"
                        label="File Name"
                        type="text"
                        value={file.fileNameOnly}
                        callback={this.handleEnterFileName}
                        disabled={disableFormFields || !canEditFileName}
                        required
                        wrapperStyle={{ flex: '1' }}
                        customValidation={fileNameCustomValidation}
                        errorMessage={
                          !isEmpty(fileNameCustomValidation)
                            ? fileNameErrorMessage || 'Invalid file name format'
                            : ''
                        }
                        height={30}
                        inputStyle={{ fontSize: '1.2rem', minWidth: '150px' }}
                      />
                      <div
                        style={{
                          marginTop: '24px',
                          marginRight: '0.3em'
                        }}>{`.${file.fileExtension}`}</div>
                    </div>
                  </div>
                  {this.hasFileOptions && (
                    <div
                      className="file-options"
                      style={{
                        display: 'flex',
                        gap: '0.2em',
                        flexWrap: 'wrap',
                        flex: '1'
                      }}>
                      {this.allowSelectFileType && (
                        <ComboBox
                          id={file.uniqueId}
                          className="select-file-type"
                          label="Select File Type"
                          list={selectFileTypeList}
                          type="selectFileType"
                          disabled={disableFormFields}
                          wrapperStyle={{ flex: '1', minWidth: '150px' }}
                          callback={this.handleSelectFileType}
                          required
                          displaySearch
                        />
                      )}
                      {this.allowTagList && (
                        <ComboBox
                          id={file.uniqueId}
                          className="add-tags"
                          label="Tags"
                          type="addTags"
                          useTagColors={userType === 'employee'}
                          list={selectTagList}
                          selected={file.tagList}
                          disabled={disableFormFields}
                          wrapperStyle={{ flex: '1', minWidth: '150px' }}
                          callback={this.handleTags}
                          editable={!disableCustomTags}
                          validationActivated
                          required
                          isMulti
                          displaySearch
                          formField
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
            {!disableFormFields && !isEmpty(currentDroppedFiles) && (
              <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <ErrorBox
                  $error={!isEmpty(dupeFileNames) || !isEmpty(invalidTagFileNames)}
                  style={{ width: 'auto' }}>
                  {`No ${[
                    ...(!isEmpty(dupeFileNames) ? ['duplicate file names'] : []),
                    ...(!isEmpty(invalidTagFileNames) ? ['system-generated tags'] : [])
                  ].join(', ')} allowed`}
                </ErrorBox>
                <Button
                  className="add-all-files-button"
                  data-testid="add-all-files-button"
                  style={inlineFilesFormSectionCSS.setFileTypeButton}
                  disabled={!enableSubmit}
                  onClick={this.handleAddFiles}>
                  Add
                  {!isEmpty(maxAddFileCount) && maxAddFileCount > 0
                    ? `${maxAddFileCount === 1 ? ' File' : ' Files'}`
                    : ' Files'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FilesArea.propTypes = {
  axiosRequest: PropTypes.func.isRequired,
  fileRequirements: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string,
  userType: PropTypes.string,
  maxAddFileCount: PropTypes.number,
  fileNameOverride: PropTypes.string,
  customFileTypeList: PropTypes.oneOfType([PropTypes.array]),
  dropzoneSize: PropTypes.string,
  displayedFields: PropTypes.oneOfType([PropTypes.array]),
  forceReplaceFileName: PropTypes.bool,
  allowEditFileName: PropTypes.bool,
  disableFormFields: PropTypes.bool,
  disableCustomTags: PropTypes.bool,
  callback: PropTypes.func,
  useNewFormat: PropTypes.bool,
  preloadEndpoint: PropTypes.string,
  requestGuid: PropTypes.oneOfType([PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  defaultTagsOnAdd: PropTypes.oneOfType([PropTypes.array]),
  existingFiles: PropTypes.oneOfType([PropTypes.array]),
  enableDropzone: PropTypes.bool,
  requiredFiles: PropTypes.oneOfType([PropTypes.array]),
  isFormDirty: PropTypes.func,
  validateFilesCallback: PropTypes.func,
  useParentCacheErrorSwal: PropTypes.bool,
  useCacheUpload: PropTypes.bool,
  cacheUploadEndpoint: PropTypes.string,
  fileNameErrorMessage: PropTypes.string,
  fileNameCustomValidation: PropTypes.func,
  loadMessage: PropTypes.string,
  isPublicRequest: PropTypes.bool,
  onlyEncodeData: PropTypes.bool
};

FilesArea.defaultProps = {
  id: '',
  fileRequirements: [], // list of file req strings, only use if NOT using InlineFilesFormSection
  userType: '',
  maxAddFileCount: 0, // max number of files user can upload
  fileNameOverride: null, // should only be used `maxAddFileCount` === 1
  customFileTypeList: [], // custom list for "select file type"; array of { title, value }
  dropzoneSize: null, // one of: 'default', 'small'
  displayedFields: [], // fields to display in each file row
  forceReplaceFileName: false, // globally force renaming all user-added files
  allowEditFileName: false,
  disableFormFields: false,
  disableCustomTags: false, // if user is not allowed to add free-form tags
  callback: null,
  useNewFormat: false,
  preloadEndpoint: '',
  requestGuid: null,
  wrapperStyle: {},
  defaultTagsOnAdd: [], // Any default tags we want added under the covers for new files
  existingFiles: [], // optional, show existing files in dropzone file list
  enableDropzone: false,
  requiredFiles: [],
  isFormDirty: null,
  validateFilesCallback: null, // if parent needs to know if the dropped files are valid
  useParentCacheErrorSwal: false, // Don't show upload errors swal (cache/s3 upload errors)
  useCacheUpload: false, // To generate s3 upload link to cache bucket (does NOT create/upload file)
  cacheUploadEndpoint: '', // Required if `useCacheUpload` is true
  fileNameErrorMessage: null, // custom error message for `fileNameCustomValidation`
  fileNameCustomValidation: null, // if user can rename files, apply validation for all file names
  loadMessage: null,
  isPublicRequest: false, // if token is NOT required to make api calls
  onlyEncodeData: false
};

export default FilesArea;
