import { getMockValue } from '../_mockDataHelpers';

export const mockACHDetailsGet = (body, params, options) => {
  const { guid = `"{}"` } = options || {};
  const { achType = 'ach_rejects' } = params || {};
  return {
    [guid]: {
      ...(achType === 'ach_rejects' && {
        achRejects: {
          header: [
            {
              order: 1,
              key: 'mid',
              englishName: 'MID'
            },
            {
              order: 2,
              key: 'eventDate',
              englishName: 'Event Date'
            },
            {
              order: 3,
              key: 'rejectAmount',
              englishName: 'Reject Amount'
            },
            {
              order: 4,
              key: 'reasonCode',
              englishName: 'Reason Code'
            },
            {
              order: 5,
              key: 'reasonCodeDescription',
              englishName: 'Reason Code Description'
            },
            {
              order: 6,
              key: 'originalTraceNumber',
              englishName: 'Original Trace Number'
            },
            {
              order: 7,
              key: 'returnTraceNumber',
              englishName: 'Return Trace Number'
            },
            {
              order: 8,
              key: 'coEntryDescription',
              englishName: 'Co Entry Description'
            }
          ],
          data: [
            {
              achRejectId: getMockValue({ type: 'string' }),
              mid: getMockValue({ type: 'mid' }),
              eventDate: getMockValue({ type: 'date' }),
              rejectAmount: getMockValue({ type: 'number', stringify: true }),
              reasonCode: 'dww91',
              reasonCodeDescription: getMockValue({ type: 'description' }),
              originalTraceNumber: getMockValue({ type: 'number', stringify: true }),
              returnTraceNumber: getMockValue({ type: 'number', stringify: true }),
              coEntryDescription: getMockValue({ type: 'description' })
            }
          ]
        }
      }),
      ...(achType === 'reserve_payouts' && {
        reservePayouts: {
          header: [
            {
              order: 1,
              key: 'reservePayoutId',
              englishName: 'Reserve Payout ID'
            },
            {
              order: 2,
              key: 'mid',
              englishName: 'MID'
            },
            {
              order: 3,
              key: 'releaseAmount',
              englishName: 'Release Amount'
            },
            {
              order: 4,
              key: 'autoreleaseRollingDays',
              englishName: 'Autorelease Rolling Days'
            },
            {
              order: 5,
              key: 'isAutorelease',
              englishName: 'Is Autorelease'
            }
          ],
          data: [
            {
              reservePayoutId: getMockValue({ type: 'number', stringify: true }),
              mid: getMockValue({ type: 'mid' }),
              releaseAmount: getMockValue({ type: 'number', stringify: true }),
              autoreleaseRollingDays: 'some days',
              isAutorelease: getMockValue({ type: 'bool' })
            }
          ]
        }
      }),
      pagingInfo: {
        totalNumberOfRecords: 1,
        pageIndex: 0,
        pageSize: 1
      }
    }
  };
};

export default mockACHDetailsGet;
