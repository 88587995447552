import { riskCategoryGuids } from '../../../../guids';

export const mockRiskCategories = {
  riskCategory01: { riskCategory: 'riskType1', riskCategoryId: riskCategoryGuids.default },
  riskCategory02: { riskCategory: 'riskType2', riskCategoryId: riskCategoryGuids.riskCategory01 }
};

export const mockRiskCategoriesGetRes = {
  riskCategories: [mockRiskCategories.riskCategory01, mockRiskCategories.riskCategory02]
};

export const mockRiskCategoriesGetResFormatted = [
  {
    title: mockRiskCategories.riskCategory01.riskCategory,
    value: mockRiskCategories.riskCategory01.riskCategoryId
  },
  {
    title: mockRiskCategories.riskCategory02.riskCategory,
    value: mockRiskCategories.riskCategory02.riskCategoryId
  }
];

export const mockCategoryPutRes = (_body, _params, _options) => ({
  riskCategoryId: mockRiskCategories.riskCategory02.riskCategoryId
});

export default mockRiskCategories;
