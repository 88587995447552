import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const setData = (options) => {
  const {
    timeType = 'year_month',
    riskFormulaType = getMockValue({ type: 'list', list: ['corvia', 'priority', 'repay'] }),
    yearMonth = timeType !== 'all_time' ? getMockValue({ type: 'yearMonth' }) : null,
    merchantDataStatus = getMockValue({
      type: 'list',
      list: ['data_ready', 'not_yet_approved', 'closed_more_than_six_months']
    }),
    functionalTestValue = null
  } = options;
  return {
    timeType: timeType === 'all_time' ? 'all_time' : yearMonth,
    riskFormulaType,
    yearMonth,
    merchantDataStatus,
    data:
      merchantDataStatus === 'data_ready'
        ? {
            returnsRiskExposure:
              functionalTestValue || getMockValue({ type: 'number', min: 1000, max: 99999 }),
            chargebackRiskExposure: getMockValue({ type: 'number', min: 1000, max: 99999 }),
            deliveryRiskExposure: getMockValue({ type: 'number', min: 1000, max: 99999 }),
            feeRiskExposure: getMockValue({ type: 'number', min: 1000, max: 99999 }),
            grossRiskExposure: getMockValue({ type: 'number', min: 1000, max: 99999 })
          }
        : null
  };
};

const exposureData = () => [
  setData({
    timeType: 'all_time',
    riskFormulaType: 'corvia',
    merchantDataStatus: 'data_ready'
  }),
  setData({
    timeType: 'all_time',
    riskFormulaType: 'priority',
    merchantDataStatus: 'data_ready',
    functionalTestValue: 11111
  }),
  setData({
    timeType: 'all_time',
    riskFormulaType: 'repay',
    merchantDataStatus: 'data_ready',
    functionalTestValue: 22222
  }),
  setData({
    yearMonth: '2020-01',
    riskFormulaType: 'corvia',
    merchantDataStatus: 'data_ready'
  }),
  setData({
    yearMonth: '2020-01',
    riskFormulaType: 'priority',
    merchantDataStatus: 'data_ready'
  }),
  setData({
    yearMonth: '2020-01',
    riskFormulaType: 'repay',
    merchantDataStatus: 'data_ready',
    functionalTestValue: 202001
  }),
  setData({
    yearMonth: '2020-02',
    riskFormulaType: 'corvia',
    merchantDataStatus: 'not_yet_approved'
  }),
  setData({
    yearMonth: '2020-02',
    riskFormulaType: 'priority',
    merchantDataStatus: 'closed_more_than_six_months'
  }),
  setData({
    yearMonth: '2020-02',
    riskFormulaType: 'repay',
    merchantDataStatus: 'data_ready',
    functionalTestValue: 202002
  })
];

export const riskExposureOutputs = exposureData();

export const mockRiskExposureGet = (body, params, options) => {
  const { guid } = options || {};
  return {
    [guid]: { riskExposureOutputs }
  };
};

export default mockRiskExposureGet;
