import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { createMockFileList } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { getResidualTaskList } from './task';
import mockFiles from '../file/mockFiles';

const createMockTaskDetail = (options) => {
  const { guid } = options || {};
  const mockTaskList = getResidualTaskList();
  const mockTaskData = mockTaskList.find((task) => task.residualTaskId === guid) || mockTaskList[0];
  return {
    ...mockTaskData,
    residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
    residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' })
  };
};

export const mockResidualsTaskDetailGet = (body, params, options) => {
  const { guid } = options || {};
  const mockTaskData = createMockTaskDetail(options);
  const mockData = {
    residualTask: {
      ...mockTaskData,
      inputFiles: [
        {
          fileName: 'chargeTypes.xls',
          tags: [{ tagObject: 'residual_input' }],
          guidType: null,
          guidValue: null,
          url: mockFiles.file01.url,
          fileCreationTimestamp: getMockValue({ min: 2023, max: 2023, type: 'isoDate' })
        },
        ...createMockFileList({ length: 4 })
      ],
      outputFiles: [
        {
          fileName: 'chargeTypes_2020-08-02.xls',
          tags: [{ tagObject: 'residual_output' }],
          guidType: null,
          guidValue: null,
          url: mockFiles.file01.url,
          fileCreationTimestamp: getMockValue({ min: 2023, max: 2023, type: 'isoDate' })
        },
        ...createMockFileList({ length: 4 })
      ]
    }
  };
  return { [guid]: mockData };
};

export default mockResidualsTaskDetailGet;
