// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { isBool, isEmpty } from './_helpers';
import { dropzoneFileListCSS, filesWithTagsCSS, uploadCSS } from './_styles';
import { Button } from './Button';
import { FileListWrapper, FileRowWrapper, FileRow, Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';

import { FilesTagList } from './FilesTagList';
import { FilesActionBar } from './FilesActionBar';

export class DropzoneFileList extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      fileList: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { addedFiles } = this.props;
    !isEmpty(addedFiles) && this.setFiles();
  }

  componentDidUpdate(prevProps) {
    const { addedFiles, timestamp } = this.props;
    if (
      JSON.stringify(addedFiles) !== JSON.stringify(prevProps.addedFiles) ||
      timestamp > prevProps.timestamp
    ) {
      this.setFiles();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  setFiles = () => {
    const { addedFiles, displayPath, canDelete } = this.props;
    this.updateState({
      fileList: addedFiles.map((file) => ({
        ...file,
        displayName: displayPath ? `${file.path || ''}${file.name}` : file.name,
        ...(canDelete && {
          userCanDelete: isBool(file.userCanDelete) ? file.userCanDelete : true
        })
      }))
    });
  };

  handleCallback = (options) => {
    const { callback } = this.props;
    callback && callback(options);
  };

  handleRemoveFileWithTags = (options) => {
    // If using files with tags
    const { file: fileToDelete } = options || {};
    this.handleRemoveFile(null, fileToDelete.name, fileToDelete);
  };

  handleRemoveFile = (e, fileName, fileRemovedObject = {}) => {
    // If not using files with tags
    e && e.preventDefault();
    const { fileList } = this.state;
    const { hasHidden } = this.props;
    const newFileList = hasHidden
      ? fileList.map((file) => ({
          ...file,
          ...(!isEmpty(file.fileUniqueId) && !isEmpty(fileRemovedObject.fileUniqueId)
            ? { ...(file.fileUniqueId === fileRemovedObject.fileUniqueId && { hidden: true }) }
            : { ...(file.name === fileName && { hidden: true }) })
        }))
      : fileList.filter((file) =>
          !isEmpty(file.fileUniqueId) && !isEmpty(fileRemovedObject.fileUniqueId)
            ? file.fileUniqueId !== fileRemovedObject.fileUniqueId
            : file.name !== fileName
        );
    this.updateState({ fileList: newFileList });
    this.handleCallback({ files: newFileList, fileRemoved: fileName, file: fileRemovedObject });
  };

  render() {
    const { fileList } = this.state;
    const {
      id,
      userType,
      type,
      galleryView,
      canDelete,
      hideTags,
      showEditButton,
      editFileCallback,
      hasEditAccess,
      editFileRequestGuid,
      axiosRequest,
      useTags,
      maxTagsVisible,
      hasHidden,
      size,
      title,
      styles,
      noSwal
    } = this.props;
    return (
      <div
        id={id}
        className="file-list"
        role="article"
        aria-label="Files List"
        style={{
          ...(size === 'small' && { ...dropzoneFileListCSS.wrapperSmall }),
          ...(styles?.wrapper && { ...styles.wrapper })
        }}>
        {!isEmpty(fileList) && (
          <div
            className="content-wrapper"
            style={{
              ...uploadCSS.content,
              display: 'flex',
              ...(size === 'default' && { flexDirection: 'column' }),
              ...(size === 'small' && { ...dropzoneFileListCSS.contentWrapperSmall }),
              ...(useTags && { display: 'flex', flexFlow: 'row wrap', paddingBottom: 0 }),
              ...(styles?.contentWrapper && { ...styles.contentWrapper })
            }}>
            {title && (
              <div
                className="title"
                style={{
                  ...uploadCSS.entryTitle,
                  ...(size === 'small' && { ...dropzoneFileListCSS.titleSmall }),
                  ...(styles?.title && { ...styles.title })
                }}>
                {title}
              </div>
            )}
            <FileListWrapper className="file-list-wrapper" size={size} type={type}>
              {fileList.map((file, index) => (
                <FileRowWrapper
                  key={`${file.name}_${index.toString()}`}
                  className="file-row"
                  data-testid={`${file.name}-file-row`}
                  $canEdit={hasEditAccess}
                  $canDelete={canDelete && file.userCanDelete}
                  timestamp={file.uploadTimestamp}
                  style={{
                    ...(size === 'small' && { ...dropzoneFileListCSS.fileRowWrapperSmall }),
                    ...(styles?.fileRowWrapper && { ...styles.fileRowWrapper }),
                    ...(hasHidden && file.hidden && { display: 'none' })
                  }}>
                  <FileRow className="filename-wrapper">
                    {useTags && !isEmpty(file.url) ? (
                      <a
                        href={file.url}
                        className="file-link"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          ...filesWithTagsCSS.fileLink,
                          ...(styles?.fileLink && { ...styles.fileLink })
                        }}
                        download>
                        <Icon
                          icon={
                            !galleryView && file.isImage
                              ? icons.imageIcon.src_color
                              : icons.documentIcon.src_color
                          }
                          color={
                            file.uploadedByMe
                              ? 'var(--color-stoplight-yellow)'
                              : 'var(--color-forest)'
                          }
                          {...(file.uploadedByMe && { title: 'You uploaded this file' })}
                          $useMask
                          style={{ width: '13px', height: '13px', marginRight: '0.1em' }}
                        />
                        <div
                          className="file-name"
                          title={file.displayName}
                          style={{
                            ...(useTags && {
                              flex: 1,
                              lineHeight: '1.7',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                            }),
                            ...(styles?.fileName && { ...styles.fileName })
                          }}>
                          {file.displayName}
                        </div>
                      </a>
                    ) : (
                      <span
                        className="filename"
                        style={{
                          ...uploadCSS.filename,
                          ...(size === 'small' && { ...dropzoneFileListCSS.fileNameSmall }),
                          ...(styles?.fileName && { ...styles.fileName })
                        }}>
                        {file.displayName}
                      </span>
                    )}
                    {/* If using tags and they should be displayed */}
                    {useTags && !hideTags && !isEmpty(file.tagList) && (
                      <FilesTagList
                        file={file}
                        hasEditAccess={hasEditAccess}
                        maxTagsVisible={maxTagsVisible}
                        wrapperStyle={{
                          flex: 1,
                          minWidth: 'fit-content',
                          margin: '0.2em 0',
                          ...(styles?.tagList && { ...styles.tagList })
                        }}
                      />
                    )}
                  </FileRow>
                  {useTags &&
                    ((canDelete && file.userCanDelete) ||
                      showEditButton ||
                      !isEmpty(file.uploadTimestamp)) && (
                      <>
                        <div className="hover" data-testid={`${file.name}-hover-file-row`} />
                        <FilesActionBar
                          userType={userType}
                          file={file}
                          hasEditAccess={hasEditAccess && file.userCanEdit}
                          {...(canDelete &&
                            file.userCanDelete && {
                              deleteFileCallback: this.handleRemoveFileWithTags
                            })}
                          {...((hasEditAccess || showEditButton) &&
                            file.userCanEdit && {
                              editFileCallback,
                              axiosRequest,
                              editFileRequestGuid:
                                file.guidType && file.guidValue
                                  ? { [file.guidType]: file.guidValue }
                                  : editFileRequestGuid
                            })}
                          noSwal={noSwal}
                          galleryView={galleryView}
                          wrapperStyle={{ position: 'absolute', right: 0 }}
                        />
                      </>
                    )}
                  {/* `canDelete` means user has delete access,
                  `userCanDelete` means they can delete the specific file */}
                  {!useTags && canDelete && file.userCanDelete && (
                    <Button
                      id={`delete-file-button-${file.name}`}
                      aria-label={`Delete ${file.name}`}
                      className="delete-file"
                      type="text"
                      onClick={(e) => this.handleRemoveFile(e, file.name, file)}
                      icon={{ ...dropzoneFileListCSS.deleteItemIcon, color: 'var(--color-link)' }}
                      style={{
                        ...dropzoneFileListCSS.deleteItemWrapper,
                        ...(styles?.deleteWrapper && { ...styles.deleteWrapper })
                      }}
                    />
                  )}
                </FileRowWrapper>
              ))}
            </FileListWrapper>
          </div>
        )}
      </div>
    );
  }
}

DropzoneFileList.propTypes = {
  id: PropTypes.string,
  userType: PropTypes.string,
  type: PropTypes.oneOf(['default', 'white']),
  size: PropTypes.oneOf(['default', 'small']),
  displayPath: PropTypes.bool,
  title: PropTypes.string,
  addedFiles: PropTypes.oneOfType([PropTypes.array]),
  callback: PropTypes.func,
  canDelete: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
  editFileCallback: PropTypes.func,
  editFileRequestGuid: PropTypes.oneOfType([PropTypes.object]),
  axiosRequest: PropTypes.func,
  useTags: PropTypes.bool,
  maxTagsVisible: PropTypes.number,
  hideTags: PropTypes.bool,
  showEditButton: PropTypes.bool,
  hasHidden: PropTypes.bool,
  styles: PropTypes.shape({
    wrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    contentWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fileLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    filesWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fileRowWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fileNameWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fileName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    deleteWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tagList: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  noSwal: PropTypes.bool,
  galleryView: PropTypes.bool,
  timestamp: PropTypes.number
};

DropzoneFileList.defaultProps = {
  id: '',
  userType: '',
  type: 'default',
  size: 'default',
  displayPath: true,
  title: '',
  addedFiles: [],
  callback: null,
  hasHidden: false,
  canDelete: true,
  hasEditAccess: false,
  editFileRequestGuid: {}, // pass this prop if user can edit file
  editFileCallback: () => {}, // pass this prop if user can edit file
  axiosRequest: () => {}, // pass this prop if user can edit file
  useTags: false, // pass true if using a files endpoint that supports tagList
  maxTagsVisible: null, // if not using dynamic width for tags display
  hideTags: false, // pass true if using endpoint that supports tags but we don't want to show them
  showEditButton: false,
  styles: {
    wrapper: {},
    contentWrapper: {},
    title: {},
    fileLink: {},
    filesWrapper: {},
    fileRowWrapper: {},
    fileNameWrapper: {},
    fileName: {},
    deleteWrapper: {},
    tagList: {}
  },
  noSwal: false,
  galleryView: false,
  timestamp: 0
};

export default DropzoneFileList;
