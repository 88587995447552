import { createMockPartherHealth } from '@f1/shared/src/data/partner/partnerHealth';
import { partnerGuids } from '../guids';

export const mockPartnerHealthGet = {
  [partnerGuids.default]: {
    healthyCount: 1,
    healthyMerchants: [
      {
        legalName: 'testLegalName',
        mids: [
          {
            mid: 'yrLSuhxhE8sDhqd',
            dbaName: 'Merchant with Transaction Report Data',
            merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
            merchantPhoneNumber: '+15125536624',
            partnerName: 'Partner with Transaction Report Data',
            partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
            partnerPhoneNumber: '+15125536624',
            relationshipCode: '12345'
          }
        ],
        visa: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        mastercard: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        total: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        }
      }
    ],
    inDangerCount: 1,
    inDangerMerchants: [
      {
        legalName: 'testLegalName',
        mids: [
          {
            mid: 'yrLSuhxhE8sDhqd',
            dbaName: 'Merchant with Transaction Report Data',
            merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
            merchantPhoneNumber: '+15125536624',
            partnerName: 'Partner with Transaction Report Data',
            partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
            partnerPhoneNumber: '+15125536624',
            relationshipCode: '12345'
          }
        ],
        visa: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        mastercard: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        total: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        }
      }
    ],
    onProgramCount: 1,
    onProgramMerchants: [
      {
        legalName: 'testLegalName',
        mids: [
          {
            mid: 'yrLSuhxhE8sDhqd',
            dbaName: 'Merchant with Transaction Report Data',
            merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
            merchantPhoneNumber: '+15125536624',
            partnerName: 'Partner with Transaction Report Data',
            partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
            partnerPhoneNumber: '+15125536624',
            relationshipCode: '12345'
          }
        ],
        visa: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        mastercard: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        total: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        }
      }
    ],
    unhealthyCount: 1,
    unhealthyMerchants: [
      {
        legalName: 'testLegalName',
        mids: [
          {
            mid: 'yrLSuhxhE8sDhqd',
            dbaName: 'Merchant with Transaction Report Data',
            merchantEmail: 'f1paymentsengineering+yrlsuhxhe8sdhqd@gmail.com',
            merchantPhoneNumber: '+15125536624',
            partnerName: 'Partner with Transaction Report Data',
            partnerEmail: 'createtestusershandler_partnertransactionreportdata@gmail.com',
            partnerPhoneNumber: '+15125536624',
            relationshipCode: '12345'
          }
        ],
        visa: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        mastercard: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        },
        total: {
          expectedSalesCountGivenCurrentRunRate: 2,
          expectedChargebackCountGivenCurrentRunRate: 2,
          expectedChargebackRatioGivenCurrentRunRate: 1
        }
      }
    ]
  }
};

export const mockPartnerHealthGetRes = (body, params, options) => {
  const { guid = `"{}"` } = options || {};
  const mockData = createMockPartherHealth(guid, { staticMap: mockPartnerHealthGet });
  // defaults to the "all" tickets data if the guid isn't in the map
  return { [guid]: mockData };
};
