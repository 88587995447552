import { envIsDevOrLess, isEmpty, useStubData } from './_helpers';

// For FTs / using mock data locally only
export const useLocalOnly = () =>
  envIsDevOrLess() &&
  ((localStorage && localStorage.getItem('localToken') === 'mockFrontendCsrfToken') || useStubData);

export const addressAutocompleteIdMap = {
  // Key (that AddressAutocomplete uses) to array of IDss that map to that key
  // in the component.
  // Add other ID variations of the below fields into the corresponding array.
  addressLine1: [
    'streetAddress', // crab
    'addressLine1',
    'homeStreetAddress', // Meco merchant ownerList
    'contactAddress', // Meco Merchant Form
    'businessAddressLine1', // PublicWebForm
    'billingAddressLine1', // Merchant Maintenance Form
    'address' // Partner Form
  ],
  addressLine2: [
    'address2',
    'addressLine2',
    'contactAddress2', // Meco Merchant Form
    'businessAddressLine2', // PublicWebForm
    'billingAddressLine2' // Merchant Maintenance Form
  ],
  city: [
    'city',
    'contactCity', // Meco Merchant Form
    'businessAddressCity', // PublicWebForm
    'billingAddressCity' // Merchant Maintenance Form
  ],
  state: [
    'state',
    'contactState', // Meco Merchant Form
    'businessAddressState', // PublicWebForm
    'billingAddressState' // Merchant Maintenance Form
  ],
  zipCode: [
    'zip', // crab
    'zipCode',
    'postalCode',
    'contactZip', // Meco Merchant Form
    'businessAddressPostalCode', // PublicWebForm
    'billingAddressPostalCode' // Merchant Maintenance Form
  ],
  country: [
    'country',
    'contactCountry', // Meco Merchant Form
    'businessAddressCountry', // PublicWebForm
    'billingAddressCountry' // Merchant Maintenance Form
  ]
};

export const getAddressAutocompleteData = (options) => {
  const { addressFields, data, searchResult } = options || {};
  const {
    // Must be the address fields initially created in `AddressAutocomplete`
    addressLine1FormField,
    addressLine2FormField,
    cityFormField,
    stateFormField,
    zipCodeFormField,
    countryFormField
  } = addressFields || {};
  const defaultEmptyFields = {
    ...(!isEmpty(addressLine1FormField) && { [addressLine1FormField.id]: '' }),
    ...(!isEmpty(addressLine2FormField) && { [addressLine2FormField.id]: '' }),
    ...(!isEmpty(cityFormField) && { [cityFormField.id]: '' }),
    ...(!isEmpty(stateFormField) && { [stateFormField.id]: '' }),
    ...(!isEmpty(zipCodeFormField) && { [zipCodeFormField.id]: '' }),
    ...(!isEmpty(countryFormField) && { [countryFormField.id]: '' })
  };
  let formattedAddressData = { ...defaultEmptyFields };
  if (searchResult !== null) {
    try {
      const { [addressLine1FormField.id]: address = '' } = data || {};
      // fallback in cases where there is no house number in google result
      const houseNumberRegex = /^(\d+).*/;
      const fallbackMatch = (address || '').match(houseNumberRegex);
      const fallbackNum = (fallbackMatch && fallbackMatch[1]) || '';
      // end fallback
      const place = searchResult.getPlace();
      const getPlaceMatch = (compType) =>
        place.address_components.find((comp) => comp.types.includes(compType));
      const getShortName = (placeComp) => {
        const { short_name: shortName } = placeComp;
        return shortName;
      };
      const streetNumber = getPlaceMatch('street_number');
      const number = getPlaceMatch(streetNumber) || fallbackNum;
      const route = getPlaceMatch('route');
      const street = getShortName(route);
      const locality = getPlaceMatch('locality');
      const localityCity = getShortName(locality);
      const areaLevel1 = getPlaceMatch('administrative_area_level_1');
      const localityState = getShortName(areaLevel1);
      const countryCode = getPlaceMatch('country');
      const localityCountry = getShortName(countryCode);
      const postalCode = getPlaceMatch('postal_code');
      const zipCode = getShortName(postalCode);
      formattedAddressData = {
        ...(!isEmpty(addressLine1FormField) && {
          [addressLine1FormField.id]: [
            ...(!isEmpty(number) ? [number] : []),
            ...(!isEmpty(street) ? [street] : [])
          ].join(' ')
        }),
        ...(!isEmpty(addressLine2FormField) && { [addressLine2FormField.id]: '' }),
        ...(!isEmpty(cityFormField) && { [cityFormField.id]: localityCity }),
        ...(!isEmpty(stateFormField) && { [stateFormField.id]: localityState }),
        ...(!isEmpty(zipCodeFormField) && { [zipCodeFormField.id]: zipCode }),
        ...(!isEmpty(countryFormField) && { [countryFormField.id]: localityCountry })
      };
    } catch (err) {
      // On any part of getting autocomplete data, just return nothing,
      // this will keep the user's current input in state (and will not clear out fields)
      formattedAddressData = !isEmpty(err) ? {} : null;
    }
  }
  return formattedAddressData;
};
