import { sharedFormFields } from '../../_formFields';
import { isEmpty, monthNames } from '../../_helpers';
import { isPriorityFirstDataBank, isPriorityTsysBank } from '../../_templateHelpers';
import { getMockValue } from '../_mockDataHelpers';
import { staticAddendums } from './mockBoardingApplicationMpaList';

const mockAddress = () => {
  const mockAddressFields = getMockValue({ type: 'address' });
  return {
    addressLine1: mockAddressFields.fullAddress,
    addressLine2: getMockValue({ type: 'string' }),
    city: mockAddressFields.cityName,
    state: mockAddressFields.stateCode,
    postalCode: mockAddressFields.zipCode,
    country: 'US' // FE default (string) 2 Chars CountryCode
  };
};

// Creates a single mock general boarding application json object
export const mockBoardingApplicationJson = (mpaIndex, options = {}) => {
  const { relationshipId } = options || {};
  const appType = 'lowRisk';
  const ownersObjects = buildOwners(appType);
  const mockLegalBusinessName = getMockValue({ type: 'businessName' });
  const mockApplicationJson = {
    businessInformation: buildBusinessInfo({ mpaIndex, mockLegalBusinessName }),
    paymentProcessingAndSales: buildPaymentProcessingAndSales(appType, { mpaIndex }),
    ...ownersObjects, // builds owners section
    ...buildAchInfo(), // builds achInfo section
    digitizedAddendums: buildDigitizedAddendums(mpaIndex),
    repayFields: buildRepayFields(mpaIndex, { relationshipId }),
    neteviaFields: buildNeteviaFields(mpaIndex, { relationshipId }),
    priorityFields: buildPriorityFields(mpaIndex, { relationshipId }),
    applicationLevelFields: {
      legalBusinessName: mockLegalBusinessName,
      ...ownersObjects
    }
  };
  return mockApplicationJson;
};

// Builds the `businessInformation` object within an applicationJson object
export const buildBusinessInfo = (options) => {
  // Business tab
  const { mpaIndex, mockLegalBusinessName } = options || {};
  const mockIsPublicCompany = getMockValue({ type: 'boolean' });
  const mockSharedBusinessFields = {
    // common fields between all appTypes
    dbaName: `DBA Name ${mpaIndex + 1}`,
    descriptor: `DBA Name ${mpaIndex + 1} descriptor`,
    telephoneNumber: getMockValue({ type: 'bePhone' }),
    faxNumber: getMockValue({ type: 'bePhone' }),
    businessStreetAddress: { ...mockAddress() },
    merchantWebsite: getMockValue({ type: 'urlRequiredProtocol' }),
    typeOfProductsOrServicesSold: getMockValue({ type: 'string' }),
    legalBusinessName: mockLegalBusinessName || getMockValue({ type: 'string' }),
    taxpayerIdentificationNumber: getMockValue({ type: 'ssn' }),
    contactEmailAddress: getMockValue({ type: 'email', userType: 'external' }),
    billingAddress: { ...mockAddress() },
    organizationalStructure: getMockValue({
      type: 'list',
      list: ['corporation', 'llc', 'soleProprietor', 'partnership', 'government', '501C']
    })
  };
  const mockBusinessInfo = {
    ...mockSharedBusinessFields,
    contactFullName: getMockValue({ type: 'user' }).fullName,
    establishedYear: '2000',
    publicCompany: mockIsPublicCompany,
    publicCompanyInfo: mockIsPublicCompany
      ? {
          stockSymbol: getMockValue({ type: 'string' }),
          market: getMockValue({ type: 'string' })
        }
      : null,
    preferredMccCode: getMockValue({ type: 'list', list: sharedFormFields.mccCode.list })
  };
  return mockBusinessInfo;
};

// Builds the `paymentProcessingAndSales` object within an applicationJson object
export const buildPaymentProcessingAndSales = (appType, options) => {
  // Business Tab
  const { mpaIndex } = options || {};
  const staticApp = mpaIndex === 1; // Specific values for FTs
  const mockIsSeasonalBusiness = staticApp ? true : getMockValue({ type: 'boolean' });
  const mockSharedPaymentFields = {
    // common fields between all appTypes
    seasonalBusiness: mockIsSeasonalBusiness,
    seasonMonths: mockIsSeasonalBusiness ? buildSeasonMonths({ isStaticApp: staticApp }) : null,
    averageTransactionAmount: getMockValue({ type: 'number' }),
    highestTransactionAmount: getMockValue({ type: 'number' }),
    orderChannel: {
      // Total must equal 1
      emvOrCardPresentSwiped: 1,
      telephoneOrder: 0,
      eCommerce: 0
    }
  };
  if (appType === 'lowRisk') {
    const mockLowRiskPaymentFields = {
      ...mockSharedPaymentFields,
      monthlyTotalProcessingVolume: getMockValue({ type: 'number' }),
      monthlyAmexProcessingVolume: getMockValue({ type: 'number' })
    };
    return mockLowRiskPaymentFields;
  }
  if (appType === 'highRisk') {
    const mockHighRiskPaymentFields = {
      ...mockSharedPaymentFields,
      averageMonthlyVolume: getMockValue({ type: 'number' }),
      highestMonthlyVolume: getMockValue({ type: 'number' }),
      currentlyAllowedMonthlyValue: getMockValue({ type: 'number' })
    };
    return mockHighRiskPaymentFields;
  }
  return {};
};

export const buildSeasonMonths = (options) => {
  const { isStaticApp } = options || {};
  const seasonMonths = monthNames
    .filter((mon) => mon !== 'N/A')
    .reduce(
      (acc, mon) => ({
        ...acc,
        [`${mon.toLowerCase()}`]: getMockValue({ type: 'boolean' }),
        ...(isStaticApp && {
          // Specific values for FTs
          january: true,
          february: true,
          march: false
        })
      }),
      {}
    );
  return seasonMonths;
};

// Builds the owners section within an applicationJson object
export const buildOwners = (appType) => {
  // Business tab
  const mockOwner = (ownerType) => ({
    firstName: getMockValue({ type: 'user' }).firstName,
    lastName: getMockValue({ type: 'user' }).lastName,
    dob: getMockValue({ type: 'date' }),
    ssn: getMockValue({ type: 'ssn' }),
    ownershipPercentage: 1,
    ...(appType === 'highRisk' && ownerType === 'beneficial'
      ? { homeStreetAddress: getMockValue({ type: 'address' }).fullAddress }
      : { streetAddress: getMockValue({ type: 'address' }).fullAddress }),
    city: getMockValue({ type: 'address' }).cityName,
    state: getMockValue({ type: 'address' }).stateCode,
    zip: getMockValue({ type: 'address' }).zipCode,
    emailAddress: getMockValue({ type: 'email', allowNull: true, userType: 'external' }),
    businessPhoneNumber: getMockValue({ type: 'phone', allowNull: true }),
    mobileNumber: getMockValue({ type: 'phone', allowNull: true }),
    county: getMockValue({ type: 'string', allowNull: true }),
    type:
      appType === 'highRisk' && ownerType === 'beneficial'
        ? getMockValue({ type: 'list', list: ['Primary', 'Beneficiary'], allowNull: true })
        : getMockValue({
            type: 'list',
            list: [
              'Beneficial Owner',
              'Officer',
              'Principal',
              'Government',
              'Municipality Controlling Owner'
            ],
            allowNull: true
          }),
    ...(ownerType === 'control' && {
      ownershipPercentage: 0.75,
      title: 'Owner'
    }),
    ...(ownerType === 'beneficial' && {
      ownershipPercentage: 0.25,
      ...(appType === 'lowRisk' && {
        title: getMockValue({ type: 'user' }).jobTitle.replace(/ /g, '') // no spaces allowed
      })
    }),
    ...(appType === 'highRisk' &&
      ownerType === 'beneficial' && {
        fullName: getMockValue({ type: 'user' }).fullName
      }),
    priorityOwnerFields: {
      controlPerson: getMockValue({ type: 'boolean' }),
      guarantor: getMockValue({ type: 'boolean' }),
      pullCreditReport: getMockValue({ type: 'boolean' }),
      usCitizen: getMockValue({ type: 'boolean' }),
      country: 'US',
      governmentIssuedId: {
        idType: getMockValue({
          type: 'list',
          list: ['drivers_license', 'photo_id', 'passport', 'resident_alien']
        }),
        stateIssued: getMockValue({ type: 'address' }).stateCode,
        idNumber: getMockValue({ type: 'accountNumber' })
      }
    }
  });
  if (appType === 'lowRisk') {
    const mockLowRiskOwners = {
      owners: {
        controllingOwner: { ...mockOwner('control') },
        beneficialOwners: [{ ...mockOwner('beneficial') }]
      }
    };
    return mockLowRiskOwners;
  }
  if (appType === 'highRisk') {
    const mockHighRiskOwners = {
      beneficialOwnerList: {
        controlOwner: { ...mockOwner('control') },
        beneficialOwners: [{ ...mockOwner('beneficial') }]
      }
    };
    return mockHighRiskOwners;
  }
  return {};
};

const getMockAccountInfo = () => ({
  nameOnAccount: getMockValue({ type: 'user' }).fullName,
  routingNumber: getMockValue({
    min: 100000000,
    max: 999999999,
    stringify: true
  }),
  accountNumber: getMockValue({
    min: 100000, // 6 numeric chars
    max: 99999999999999, // 17 numeric chars
    stringify: true
  }),
  bankName: getMockValue({ type: 'string' })
});

// Builds the achInfo section within an applicationJson object
export const buildAchInfo = () => {
  // Business tab
  const mockFundingChoice = getMockValue({ type: 'list', list: ['standard', 'next_day_funding'] });
  const mockAchInfo = {
    achInfoAndFunding: {
      creditAndDebit: { ...getMockAccountInfo() },
      fundingChoices: {
        fundingChoice: mockFundingChoice
      }
    }
  };
  return mockAchInfo;
};

// Builds the payment cards accepted section within an applicationJson object
export const buildPaymentCards = (appType, options) => {
  // Business tab
  const { mpaIndex } = options || {};
  const staticApp = mpaIndex === 1; // Specific values for FTs
  const mockAcceptEbt = getMockValue({ type: 'boolean' });
  const mockAcceptAmexRetained = getMockValue({ type: 'boolean' });
  const mockAmexOptBlue = staticApp ? true : getMockValue({ type: 'boolean' });
  const mockSharedPaymentCards = () => ({
    // common cards between all appTypes
    visa: staticApp ? true : getMockValue({ type: 'boolean' }),
    mastercard: staticApp ? true : getMockValue({ type: 'boolean' }),
    discover: staticApp ? false : getMockValue({ type: 'boolean' }),
    amexOptBlue: mockAmexOptBlue,
    debitCardsOnly: staticApp ? false : getMockValue({ type: 'boolean' }),
    electronicBenefitsTransfer: mockAcceptEbt,
    amexRetainedESA: mockAcceptAmexRetained
  });
  if (appType === 'lowRisk') {
    const mockLowRiskPaymentCards = {
      paymentCardAcceptance: {
        ...mockSharedPaymentCards(),
        fnsEbtNumber: mockAcceptEbt ? getMockValue({ type: 'string' }) : null,
        amexNumber: mockAmexOptBlue ? '1234567890' : null
      }
    };
    return mockLowRiskPaymentCards;
  }
  if (appType === 'highRisk') {
    const mockAmexOptOut = getMockValue({ type: 'boolean' });
    const mockHighRiskPaymentCards = {
      paymentMethodsAccepted: {
        ...mockSharedPaymentCards(),
        amexExistingMerchantNumber: getMockValue({ type: 'string' }),
        amexRetainedEsaPerTransaction: getMockValue({ type: 'number' }),
        foodAndNutritionServiceNumber: getMockValue({
          min: 1000000,
          max: 9999999,
          stringify: true
        }),
        electronicBenefitsTransferPerTransaction: getMockValue({ type: 'number' }),
        pinDebitPerTransaction: getMockValue({ type: 'number' }),
        additionalDiscount: getMockValue({ type: 'ratio' }),
        debitMonthlyAccess: getMockValue({ type: 'number' }),
        wrightExpressExistingMerchantNumber: getMockValue({ type: 'string' }),
        wrightExpressPerTransaction: getMockValue({ type: 'number' }),
        amexOptBlueDetails: {
          optOut: mockAmexOptOut,
          optOutDate: mockAmexOptOut ? getMockValue({ type: 'date' }) : null
        }
      }
    };
    return mockHighRiskPaymentCards;
  }
  return {};
};

// Builds the `ratesAndFees` object
export const buildFeesAndRates = (options) => {
  // Fees tab
  const { mpaIndex, processName, bankName, staticIds } = options || {};
  const staticMpaIds = [1, ...(!isEmpty(staticIds) ? staticIds : [])];
  const staticApp = staticMpaIds.includes(mpaIndex); // Specific values for FTs
  const mockHasAccountUpdater = getMockValue({ type: 'boolean' });
  const mockHasMobileProcessing = getMockValue({ type: 'boolean' });
  const mockHasWirelessProcessing = getMockValue({ type: 'boolean' });
  const mockHasGateway = getMockValue({ type: 'boolean' });
  const mockPricingRates = (pricingRateOptions) => {
    const { isStaticApp = false } = pricingRateOptions || {};
    return {
      volumeRatio: isStaticApp ? 0.00001 : getMockValue({ type: 'ratio' }),
      perTransaction: isStaticApp ? 4.44 : getMockValue({ type: 'number' })
    };
  };
  if (processName === 'priority') {
    const mockRelationship = { processName, bankName };
    const isPriorityTsys = isPriorityTsysBank(mockRelationship);
    const mockPricingTypePriorityTsys = getMockValue({
      type: 'list',
      list: ['flat_pricing', 'interchange_plus', 'tiered_rates']
    });
    const mockFeesPriorityTsys = isPriorityTsys
      ? {
          transactionFees: {
            authorization: {
              amexIpDialMxmIp: getMockValue({ type: 'number' }),
              voice: getMockValue({ type: 'number' }),
              pinDebitIpDialMxmIp: getMockValue({ type: 'number' }),
              ebtIpDialMxmIp: getMockValue({ type: 'number' }),
              visaMasterCardDiscoverIpDialMxmIp: getMockValue({ type: 'number' })
            },
            authorizationOther: {
              avs: getMockValue({ type: 'number' }),
              batch: getMockValue({ type: 'number' })
            },
            sale: {
              visaMasterCarAmexPinDebitEbt: getMockValue({ type: 'number' }),
              pinDebit: getMockValue({ type: 'ratio' })
            },
            refund: {
              visaMasterCardDiscoverAmex: getMockValue({ type: 'number' })
            }
          },
          otherProcessingFees: {
            recurring: {
              industryComplianceMonthly: getMockValue({ type: 'number' }),
              wirelessMonthly: getMockValue({ type: 'number' }),
              miscellaneousMonthly: getMockValue({ type: 'number' }),
              minimumFeeMonthly: getMockValue({ type: 'number' }),
              serviceMonthly: getMockValue({ type: 'number' }),
              pinDebitMonthly: getMockValue({ type: 'number' }),
              serviceAnnually: getMockValue({ type: 'number' }),
              industryComplianceAnnually: getMockValue({ type: 'number' })
            },
            cardException: {
              chargeback: getMockValue({ type: 'number' }),
              chargebackRetrievalRequest: getMockValue({ type: 'number' })
            },
            achExceptions: {
              achReturn: getMockValue({ type: 'number' })
            },
            nonCompliance: {
              tin: getMockValue({ type: 'number' }),
              pciDss: getMockValue({ type: 'number' })
            },
            thirdPartyFees: {
              micros: {
                transaction: getMockValue({ type: 'number' })
              }
            },
            cancellation: {
              earlyTermination: getMockValue({ type: 'number' })
            }
          },
          pricingType: mockPricingTypePriorityTsys,
          ...(mockPricingTypePriorityTsys === 'flat_pricing' && {
            flatRate: {
              transactionFees: {
                sale: {
                  amex: getMockValue({ type: 'ratio' }),
                  visaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' }),
                  visaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' })
                }
              }
            }
          }),
          ...(mockPricingTypePriorityTsys === 'interchange_plus' && {
            interchangePlus: {
              transactionFees: {
                sale: {
                  amex: getMockValue({ type: 'ratio' }),
                  visaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' }),
                  visaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' })
                }
              }
            }
          }),
          ...(mockPricingTypePriorityTsys === 'tiered_rates' && {
            tieredRates: {
              transactionFees: {
                sale: {
                  qualifiedAmex: getMockValue({ type: 'ratio' }),
                  midQualifiedAmex: getMockValue({ type: 'ratio' }),
                  nonQualifiedAmex: getMockValue({ type: 'ratio' }),
                  qualifiedVisaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  midQualifiedVisaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  nonQualifiedVisaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  qualifiedVisaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' }),
                  midQualifiedVisaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' }),
                  nonQualifiedVisaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' })
                }
              }
            }
          })
        }
      : {};
    const isPriorityFirstData = isPriorityFirstDataBank(mockRelationship);
    const mockPricingTypePriorityFirstData = getMockValue({
      type: 'list',
      list: ['interchange_plus', 'tiered_rates', 'flat_pricing']
    });
    const mockFeesPriorityFirstData = isPriorityFirstData
      ? {
          pricingType: mockPricingTypePriorityFirstData,
          transactionFees: {
            authorization: {
              visaMasterCardDiscoverIpDialMxmIp: getMockValue({ type: 'number' }),
              ebtIpDialMxmIp: getMockValue({ type: 'number' }),
              americanExpressIpDialMxmIp: getMockValue({ type: 'number' }),
              pinDebitIpDialMxmIp: getMockValue({ type: 'number' }),
              voice: getMockValue({ type: 'number' }),
              voyagerIpDialMxmIp: getMockValue({ type: 'number' })
            },
            authorizationOther: {
              avs: getMockValue({ type: 'number' }),
              batch: getMockValue({ type: 'number' })
            },
            sale: {
              voyager: getMockValue({ type: 'ratio' }),
              pinDebit: getMockValue({ type: 'ratio' }),
              visaMasterCardDiscoverAmexPinDebitEbtVoyagerWex: getMockValue({ type: 'number' }),
              ...(mockPricingTypePriorityFirstData === 'flat_pricing' && {
                flatPricingRates: {
                  visaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  americanExpress: getMockValue({ type: 'ratio' }),
                  visaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' })
                }
              }),
              ...(mockPricingTypePriorityFirstData === 'interchange_plus' && {
                interchangePlusRates: {
                  visaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  americanExpress: getMockValue({ type: 'ratio' }),
                  visaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' })
                }
              }),
              ...(mockPricingTypePriorityFirstData === 'tiered_rates' && {
                tieredRates: {
                  nonQualifiedVisaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  midQualifiedVisaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  qualifiedVisaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' }),
                  nonQualifiedAmericanExpress: getMockValue({ type: 'ratio' }),
                  midQualifiedVisaMasterCardDiscoverCredit: getMockValue({ type: 'ratio' }),
                  midQualifiedAmericanExpress: getMockValue({ type: 'ratio' }),
                  qualifiedVisaMasterCardDiscoverDebit: getMockValue({ type: 'ratio' }),
                  qualifiedAmericanExpress: getMockValue({ type: 'ratio' })
                }
              })
            },
            refund: {
              visaMasterCardDiscoverAmexPinDebit: getMockValue({ type: 'number' })
            }
          },
          otherProcessingFees: {
            serviceMonthly: getMockValue({ type: 'number' }),
            miscellaneousMonthly: getMockValue({ type: 'number' }),
            minimumFeeMonthly: getMockValue({ type: 'number' }),
            industryComplianceMonthly: getMockValue({ type: 'number' }),
            wirelessMonthly: getMockValue({ type: 'number' }),
            pinDebitMonthly: getMockValue({ type: 'number' }),
            serviceAnnually: getMockValue({ type: 'number' }),
            chargebackRetrievalRequest: getMockValue({ type: 'number' }),
            chargeback: getMockValue({ type: 'number' }),
            achReturn: getMockValue({ type: 'number' }),
            pciDss: getMockValue({ type: 'number' }),
            tin: getMockValue({ type: 'number' }),
            earlyTermination: getMockValue({ type: 'number' })
          }
        }
      : {};
    return { ...mockFeesPriorityTsys, ...mockFeesPriorityFirstData };
  }
  const mockHasDeclineRecovery = getMockValue({ type: 'boolean' });
  const mockTieredRates = () => ({
    qualifiedRate: { ...mockPricingRates() },
    midQualifiedRate: { ...mockPricingRates() },
    nonQualifiedRate: { ...mockPricingRates() }
  });
  const mockSetupMonthlyFees = () => ({
    setup: getMockValue({ type: 'number' }),
    monthly: getMockValue({ type: 'number' }),
    perTransaction: getMockValue({ type: 'number' })
  });
  const masterPricingTypeList = [
    'interchange_plus',
    'bundled',
    'tiered_rates',
    'cash_discount_pricing',
    'flat_pricing'
  ];
  const mockPricingType = staticApp
    ? 'flat_pricing'
    : getMockValue({
        type: 'list',
        list: [
          ...(processName === 'netevia' ? ['interchange_plus', 'bundled'] : []),
          ...(processName === 'repay' ? masterPricingTypeList : []),
          ...(isEmpty(processName) ? masterPricingTypeList : [])
        ]
      });
  const mockRatesAndFees = {
    pricing: {
      pricingType: mockPricingType,
      visaMastercardRate: { ...mockPricingRates() },
      amexRate: { ...mockPricingRates() },
      discoverRate: { ...mockPricingRates() },
      pinDebitFees: {
        ...mockPricingRates(),
        monthlyFee: getMockValue({ type: 'number' })
      },
      tieredRates: {
        visaMastercard: { ...mockTieredRates() },
        amex: { ...mockTieredRates() },
        discover: { ...mockTieredRates() },
        debit: { ...mockTieredRates() }
      },
      cashDiscountPricing: {
        ...mockPricingRates(),
        waiveCardBrandFees: staticApp ? false : getMockValue({ type: 'boolean' }),
        waiveDuesAndAssessments: staticApp ? false : getMockValue({ type: 'boolean' })
      },
      flatPricing: {
        ...mockPricingRates({ isStaticApp: staticApp }),
        waiveCardBrandFees: staticApp ? false : getMockValue({ type: 'boolean' }),
        waiveDuesAndAssessments: staticApp ? false : getMockValue({ type: 'boolean' })
      }
    },
    declineRecoveryEnabled: mockHasDeclineRecovery,
    declineRecovery: mockHasDeclineRecovery
      ? {
          recoveryFee: getMockValue({ type: 'ratio' }),
          transactionFee: getMockValue({ type: 'number' }),
          monthlyFee: getMockValue({ type: 'number' }),
          other: getMockValue({ type: 'number' })
        }
      : null,
    accountUpdaterEnabled: mockHasAccountUpdater,
    accountUpdater: mockHasAccountUpdater ? { ...mockSetupMonthlyFees() } : null,
    mobileProcessingEnabled: mockHasMobileProcessing,
    mobileProcessing: mockHasMobileProcessing
      ? { ...mockSetupMonthlyFees(), perUser: getMockValue({ type: 'number' }) }
      : null,
    gatewayEnabled: mockHasGateway,
    gateway: mockHasGateway
      ? {
          ...mockSetupMonthlyFees(),
          gatewayName: getMockValue({ type: 'string' }),
          gatewayVaultTokenizationMonthly: getMockValue({ type: 'number' }),
          gatewayVaultTokenizationPerTransaction: getMockValue({ type: 'number' })
        }
      : null,
    wirelessProcessingEnabled: mockHasWirelessProcessing,
    wirelessProcessing: mockHasWirelessProcessing ? { ...mockSetupMonthlyFees() } : null,
    bankSponsorFee: getMockValue({ type: 'ratio' }),
    statementFee: getMockValue({ type: 'number' }),
    verifiAlert: getMockValue({ type: 'number' }),
    retrievals: getMockValue({ type: 'number' }),
    batchFee: getMockValue({ type: 'number' }),
    onlinePortalAccessMonthly: getMockValue({ type: 'number' }),
    amexSponsorFee: getMockValue({ type: 'number' }),
    minimumMonthlyFee: getMockValue({ type: 'number' }),
    rapidDisputeResolution: getMockValue({ type: 'number' }),
    chargebacks: getMockValue({ type: 'number' }),
    declineTransaction: getMockValue({ type: 'number' }),
    terminalFee: getMockValue({ type: 'number' }),
    governmentCompliance: getMockValue({ type: 'number' }),
    setupFee: getMockValue({ type: 'number' }),
    verifiedByVisa: getMockValue({ type: 'number' }),
    reversal: getMockValue({ type: 'number' }),
    refundTransaction: getMockValue({ type: 'number' }),
    posFee: getMockValue({ type: 'number' }),
    pciNonComplianceFeeMonthly: getMockValue({ type: 'number' }),
    annualFee: getMockValue({ type: 'number' }),
    ethocaAlert: getMockValue({ type: 'number' }),
    preArbitration: getMockValue({ type: 'number', min: 35, max: 40 }),
    avsTransaction: getMockValue({ type: 'number' }),
    perAchReject: getMockValue({ type: 'number' }),
    pciComplianceMonthly: getMockValue({ type: 'number' }),
    monthlyFee: getMockValue({ type: 'number' }),
    mastercardSecureCode: getMockValue({ type: 'number' }),
    arbitration: getMockValue({ type: 'number' }),
    voiceAuthorizationTransaction: getMockValue({ type: 'number' }),
    tinMismatchMonthly: getMockValue({ type: 'number' }),
    cardBrandRegistration: getMockValue({ type: 'number' }),
    monthlyRegistrationFee: getMockValue({ type: 'number' }),
    webMonitoring: getMockValue({ type: 'number' }),
    riskAnalysis: getMockValue({ type: 'number' }),
    nextDayFundingBatchFee: getMockValue({ type: 'number' })
  };
  return mockRatesAndFees;
};

export const buildRepayFields = (mpaIndex, options) => {
  const mpaNumber = !isEmpty(mpaIndex) ? Number(mpaIndex) : mpaIndex;
  const staticMpaRepayFields = {
    // Static fields by mpaIndex
    0: { chargebackNotificationEmailAddress: 'mockMpaCbEmail@test.com' }
  };
  const staticFields = staticMpaRepayFields[mpaNumber];
  const mockPaymentCardAcceptance = buildPaymentCards('lowRisk', { ...options, mpaIndex });
  const mockRatesAndFees = buildFeesAndRates({ mpaIndex, processName: 'repay' });
  const mockRepayFields = {
    chargebackNotificationEmailAddress:
      staticFields && !isEmpty(staticFields.chargebackNotificationEmailAddress)
        ? staticFields.chargebackNotificationEmailAddress
        : undefined, // will default to value set on signin
    statementDistributionMethod: getMockValue({
      type: 'list',
      list: ['email', 'letter', 'both'],
      allowNull: true
    }),
    statementDestination: getMockValue({ type: 'list', list: ['corporation', 'merchant'] }),
    combinedAch: true,
    suppressStatement: false,
    discountType: getMockValue({ type: 'list', list: ['monthly', 'daily'] }),
    netAch: null,
    terminalName: getMockValue({
      type: 'list',
      list: [
        'dejavoo_qd2',
        'dejavoo_qd4',
        'dejavoo_z1',
        'dejavoo_z8',
        'dejavoo_z9',
        'dejavoo_z11',
        'ingenico_ict220',
        'ingenico_ict220ctl',
        'ingenico_ict250',
        'ingenico_iwl220',
        'ingenico_iwl250',
        'multipass',
        'pax_a920',
        'pax_s80',
        'tranzpay',
        'valor_vl100',
        'valor_vl110',
        'software_other',
        'terminal_other'
      ]
    }),
    serviceLevel: getMockValue({ type: 'list', list: ['stage_only_var', 'multipass'] }),
    paymentCardAcceptance: {
      ...mockPaymentCardAcceptance.paymentCardAcceptance,
      ...(staticFields && {
        ...staticFields.paymentCardAcceptance
      })
    },
    achInfoAndFunding: {
      debitOnly: getMockAccountInfo(),
      chargebackOnly: getMockAccountInfo()
    },
    ratesAndFees: {
      ...mockRatesAndFees,
      ...(staticFields && { ...staticFields.ratesAndFees })
    }
  };
  return mockRepayFields;
};

const buildDigitizedAddendums = (mpaIndex) => {
  const mockAddendums = {
    netevia: {
      neteviaReserveFormFields: {
        initialCheckAmount: getMockValue({ type: 'number' }),
        withholdingRatio: getMockValue({ type: 'ratio' }),
        ownershipType: getMockValue({ type: 'list', list: ['president', 'owner'] })
      },
      ...(staticAddendums &&
        staticAddendums?.[mpaIndex]?.netevia && { ...staticAddendums[mpaIndex]?.netevia })
    },
    corvia: {
      usingNobAddendum: getMockValue({ type: 'boolean' }),
      usingCorviaProductCountryCompliance: getMockValue({ type: 'boolean' }),
      usingOutsideLegalReviewForm: getMockValue({ type: 'boolean' }),
      outsideLegalReviewFormFields: {
        nameOfAttorney: getMockValue({ type: 'string' }),
        nameOfLawFirm: getMockValue({ type: 'string' }),
        name: getMockValue({ type: 'string' }),
        title: getMockValue({ type: 'string' })
      },
      ...(staticAddendums &&
        staticAddendums?.[mpaIndex]?.corvia && { ...staticAddendums[mpaIndex]?.corvia })
    }
  };
  return mockAddendums;
};

export const buildNeteviaFields = (mpaIndex, options) => {
  const staticMpaNeteviaFields = {
    // Static fields by mpaIndex
  };
  const mockPaymentCardAcceptance = buildPaymentCards('lowRisk', { ...options, mpaIndex });
  const mockRatesAndFees = buildFeesAndRates({ mpaIndex, processName: 'netevia' });
  const mockInternetBusinessType = {
    webHosting: getMockValue({ type: 'boolean' }),
    domainRegistration: getMockValue({ type: 'boolean' }),
    webPageDesign: getMockValue({ type: 'boolean' }),
    auction: getMockValue({ type: 'boolean' }),
    internetServiceGateway: getMockValue({ type: 'boolean' }),
    sellingDigitalService: getMockValue({ type: 'boolean' }),
    advertisement: getMockValue({ type: 'boolean' }),
    sellingHardGoods: getMockValue({ type: 'boolean' }),
    other: true // Set at least one option to true for FTs
  };
  const mockNeteviaFields = {
    paymentCardAcceptance: mockPaymentCardAcceptance.paymentCardAcceptance,
    ratesAndFees: mockRatesAndFees,
    cardNotPresent: {
      internetBusinessType: mockInternetBusinessType,
      internetBusinessTypeOther: mockInternetBusinessType.other
        ? getMockValue({ type: 'description' })
        : null,
      encryptionVendorsAndControls: getMockValue({ type: 'description', length: 'long' }),
      advertisingAndPromotionMethod: getMockValue({ type: 'description', length: 'long' }),
      billingMethods: {
        // Total should equal 1
        monthly: 0.1,
        yearly: 0.4,
        quarterly: 0.2,
        oneTime: 0.2,
        hourly: 0.1
      },
      vendors: getMockValue({ type: 'description', length: 'long' }),
      fulfillmentVendor: `${getMockValue({ type: 'user', userType: 'external' }).fullName}\n${getMockValue({ type: 'address' }).fullAddress}\n${getMockValue({ type: 'bePhone' })}`,
      saleProcessDescription: getMockValue({ type: 'description', length: 'long' }),
      ...(staticMpaNeteviaFields[mpaIndex] && {
        ...staticMpaNeteviaFields[mpaIndex].cardNotPresent
      })
    },
    fees: {
      authVisaMastercardDiscover: getMockValue({ type: 'number' }),
      authAmex: getMockValue({ type: 'number' }),
      pciAnnual: getMockValue({ type: 'number' }),
      reporting1099K: getMockValue({ type: 'number' }),
      earlyTermination: getMockValue({ type: 'number' }),
      ebtPerTxn: getMockValue({ type: 'number' }),
      regulatoryAssistancePerMonth: getMockValue({ type: 'number' }),
      webMonitoringSetup: getMockValue({ type: 'number' }),
      ...(staticMpaNeteviaFields[mpaIndex] && { ...staticMpaNeteviaFields[mpaIndex].fees })
    }
  };
  return mockNeteviaFields;
};

export const buildPriorityFields = (mpaIndex, _options) => {
  const staticMpaFields = {
    // Static fields by mpaIndex
  };

  // TSYS banks
  const axiomMpaIds = ['0']; // For FTs
  const synovusMpaIds = [];
  const pbtMpaIds = [];

  // First Data banks
  const wellsFargoMpaIds = ['1']; // For FTs

  const isPriorityFirstData = wellsFargoMpaIds.includes(`${mpaIndex}`);

  const mockPriorBankruptcy = getMockValue({ type: 'boolean' });
  const mockAcceptMajorCardNetwork = getMockValue({ type: 'boolean' });
  const mockPriorBreach = getMockValue({ type: 'boolean' });
  const mockEverTerminated = getMockValue({ type: 'boolean' });
  const mockPriorProcessor = getMockValue({ type: 'boolean' });
  const mockRatesAndFeesTsys = buildFeesAndRates({
    mpaIndex,
    processName: 'priority',
    bankName: 'axiom'
  });
  const mockRatesAndFeesFirstData = buildFeesAndRates({
    mpaIndex,
    processName: 'priority',
    bankName: 'wells_fargo'
  });
  const mockPriorityFields = {
    businessInformation: {
      entityFormationDate: getMockValue({ type: 'date' }),
      lengthOfCurrentOwnership: getMockValue({ type: 'number', min: 1, max: 50 }),
      priorBankruptcy: mockPriorBankruptcy,
      dischargeDate: mockPriorBankruptcy ? getMockValue({ type: 'date' }) : null
    },
    location: {
      locationDetails: {
        marketSegment: getMockValue({
          type: 'list',
          list: [
            'travel',
            'services',
            'retail',
            'government',
            'charity_or_religious_orgs',
            'passenger_trnasport',
            'account_funding',
            'health_care',
            'hotel_or_car_rental',
            'insurance',
            'automated_fuel_dispensers',
            'utility',
            'restaurant',
            'recurring_payment',
            'real_estate',
            'advertising',
            'super_market',
            'taxi'
          ]
        }),
        businessEstablished: getMockValue({ type: 'date' })
      }
    },
    achInfoAndFunding: {
      ...(!isPriorityFirstData && {
        // this is required if this is for TSYS and this is not allowed if this is for First Data
        billingAccount: {
          ...getMockAccountInfo(),
          accountType: getMockValue({ type: 'list', list: ['checking', 'saving'] }),
          customDescription: getMockValue({ type: 'boolean' })
            ? getMockValue({ type: 'description' })
            : null
        }
      }),
      fundingAccount: {
        accountType: getMockValue({ type: 'list', list: ['checking', 'saving'] }),
        customDescription: getMockValue({ type: 'boolean' })
          ? getMockValue({ type: 'description' })
          : null
      }
    },
    features: {
      basic: {
        visaDebit: true, // make at least 1 true for FTs
        visaCredit: getMockValue({ type: 'boolean' }),
        mastercardDebit: getMockValue({ type: 'boolean' }),
        mastercardCredit: getMockValue({ type: 'boolean' }),
        amexOptBlue: getMockValue({ type: 'boolean' }),
        discoverFullAcquiring: getMockValue({ type: 'boolean' }),
        pinDebit: getMockValue({ type: 'boolean' }),
        ebtCashBenefit: getMockValue({ type: 'boolean' }),
        ebtFoodStamp: getMockValue({ type: 'boolean' })
      },
      advanced: {
        americanExpressDirect: true, // make at least 1 true for FTs
        discoverDirect: getMockValue({ type: 'boolean' }),
        voyager: getMockValue({ type: 'boolean' }),
        wrightExpress: getMockValue({ type: 'boolean' }),
        optOutAmexCommercialMarketingCommunication: getMockValue({ type: 'boolean' })
      },
      partnerOnly: {
        requestDailyDiscount: true, // make at least 1 true for FTs
        requestDailyInterchange: getMockValue({ type: 'boolean' }),
        requestNextDayFunding: getMockValue({ type: 'boolean' }),
        debtRepayment: getMockValue({ type: 'boolean' }),
        commercialTransactions: getMockValue({ type: 'boolean' }),
        requestAchMethodChange: getMockValue({ type: 'boolean' }),
        useDepositsAccountForChargeback: getMockValue({ type: 'boolean' })
      },
      sponsorBanks: {
        // should correlate to relationship.bankName the mpa is associated with
        axiomBank: axiomMpaIds.includes(`${mpaIndex}`),
        puebloBank: pbtMpaIds.includes(`${mpaIndex}`),
        synovusBank: synovusMpaIds.includes(`${mpaIndex}`),
        wellsFargoBank: wellsFargoMpaIds.includes(`${mpaIndex}`)
      }
    },
    paymentProcessingAndSales: {
      futureDelivery: getMockValue({
        type: 'list',
        list: ['days_1_7', 'days_8_14', 'days_15_30', 'days_31_60', 'over_60_days']
      }),
      ebtFoodStFnsNumber: getMockValue({ type: 'accountNumber' }),
      amexDirectEsaNumber: getMockValue({ type: 'accountNumber' }),
      welcomeKit: getMockValue({
        type: 'list',
        list: [
          'global_welcome_kit_tsys',
          'welcome_email_tsys_corvia',
          'global_welcome_kit_fd',
          'welcome_email_fd_corvia'
        ]
      }),
      statementBoarding: getMockValue({ type: 'list', list: ['f1_payments_lp'] }),
      acceptMajorCardNetwork: mockAcceptMajorCardNetwork,
      priorBreach: mockPriorBreach,
      terminationInformation: {
        everTerminated: mockEverTerminated,
        terminationReason: mockEverTerminated ? getMockValue({ type: 'description' }) : null
      },
      priorProcessorInformation: {
        processorName: mockPriorProcessor ? getMockValue({ type: 'string' }) : null,
        reasonForLeaving: mockPriorProcessor ? getMockValue({ type: 'description' }) : null
      }
    },
    cardNotPresentQuestionnaire: {
      internetBusinessType: getMockValue({ type: 'string' }),
      advertisingMethod: getMockValue({ type: 'string' }),
      billingMethod: {
        monthly: 0.2,
        yearly: 0.3,
        oneTime: 0.5
      },
      supplyFulfillmentVendor: {
        type: getMockValue({ type: 'string' }),
        name: getMockValue({ type: 'string' }),
        phoneNumber: getMockValue({ type: 'bePhone' })
      }
    },
    tsysRatesAndFees: {
      ratesAndFees: {
        ...mockRatesAndFeesTsys,
        ...(staticMpaFields[mpaIndex] && { ...staticMpaFields[mpaIndex].tsysRatesAndFees })
      }
    },
    firstDataRatesAndFees: {
      ratesAndFees: {
        ...mockRatesAndFeesFirstData,
        ...(staticMpaFields[mpaIndex] && { ...staticMpaFields[mpaIndex].firstDataRatesAndFees })
      }
    }
  };
  return mockPriorityFields;
};
