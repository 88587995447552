import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Input';
import { Button } from '../../Button';

class RequestHelpModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      requestHelpMessageValid: false,
      requestHelpMessage: ''
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  setInput = (id, value, valid) => {
    this.updateState({
      [id]: value,
      [`${id}Valid`]: valid
    });
  };

  render() {
    const { requestHelpMessageValid, requestHelpMessage } = this.state;
    const { callback } = this.props;
    return (
      <div id="request-help-modal" style={{ width: '100%', display: 'flex' }}>
        <form id="request-help-form" style={{ width: '100%', margin: '2em 1.5em 1em' }}>
          <Input
            required
            type="textarea"
            id="requestHelpMessage"
            label="Message"
            callback={this.setInput}
            wrapperStyle={{ minWidth: '150px' }}
          />
          <Button
            id="help-submit"
            onClick={() => callback(requestHelpMessage)}
            disabled={!requestHelpMessageValid}
            style={{ margin: '20px 0', flex: 1 }}>
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

RequestHelpModal.propTypes = {
  callback: PropTypes.func
};

RequestHelpModal.defaultProps = {
  callback: null
};

export default RequestHelpModal;
