// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { AlertBar } from './AlertBar';
import { icons } from '../images/_icons';
import { Button } from './Button';
import { sidebarCSS } from './_styles';
import { sharedGetInnerAlertBarState, isEmpty } from './_helpers';

export class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      childrenWithProps: null,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      open: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('keyup', this.handleKeyUp);
    this.prepChildren();
  }

  componentDidUpdate(prevProps) {
    const { sidebarOpen, children } = this.props;
    if (prevProps.sidebarOpen !== sidebarOpen) {
      this.updateState({ open: sidebarOpen }, this.handleResetAlertBar);
    }
    if (prevProps.children !== children) {
      this.prepChildren();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  handleResetAlertBar = () => {
    const { open } = this.state;
    !open &&
      this.updateState({
        alertBarType: 'closed',
        alertBarMessage: '',
        alertBarTimeout: true
      });
  };

  prepChildren = () => {
    const { children } = this.props;
    const childrenWithProps = React.Children.map(children, (child) => {
      if (
        React.isValidElement(child) &&
        (typeof child.type === 'object' || typeof child.type === 'function')
      ) {
        return React.cloneElement(child, { updateSidebarAlert: this.updateSidebarAlert });
      }
      return child;
    });
    this.updateState({ childrenWithProps });
  };

  handleKeyUp = (event) => {
    if (event.keyCode === 27) {
      this.handleClose();
    }
  };

  handleClose = () => {
    const { callback } = this.props;
    callback();
  };

  handleOutsideClick = (e) => {
    const { closeOnOutsideClick } = this.props;
    if (e.target.id === 'sidebarContentWrapper' && closeOnOutsideClick) {
      this.handleClose();
    }
  };

  updateSidebarAlert = (data) => {
    const { preText, alertBarType, alertBarMessage, alertBarTimeout } = data || {};
    const { axiosRequest } = this.props;
    if (alertBarType === 'warning') {
      const { alertBarMessage: errorMessage } = sharedGetInnerAlertBarState({
        type: 'warning',
        data: alertBarMessage,
        axiosRequest
      });
      const finalPreText = !isEmpty(preText) ? `${preText}: ` : '';
      this.updateState({
        alertBarType: 'warning',
        alertBarMessage: `${finalPreText}${errorMessage}`
      });
    } else if (
      alertBarType === 'success' ||
      alertBarType === 'closed' ||
      alertBarType === 'notice'
    ) {
      this.updateState({
        ...(alertBarType === 'success' && {
          alertBarTimeout: alertBarTimeout !== undefined ? alertBarTimeout : true
        }),
        alertBarType,
        alertBarMessage
      });
    }
  };

  render() {
    const { childrenWithProps, alertBarType, alertBarMessage, alertBarTimeout, open } = this.state;
    const { width, position, wrapperStyle, contentStyle, contentWrapperStyle, customCloseText } =
      this.props;
    return (
      <div
        id="sidebarContentWrapper"
        style={{
          width: open ? '100%' : '0',
          ...sidebarCSS.wrapper,
          ...(position === 'right' ? { right: '0' } : { left: '0' }),
          backgroundColor: open ? 'rgba(0,0,0,0.25)' : 'rgba(0,0,0,0)',
          ...wrapperStyle
        }}
        onMouseDown={this.handleOutsideClick}
        onKeyDown={() => {}}
        tabIndex="0"
        role="button">
        <div
          id="sidebarContent"
          role="article"
          aria-label="Sidebar Content"
          style={{
            width: open ? width : 0,
            minWidth: open ? '320px' : '0',
            ...sidebarCSS.contentWrapper,
            ...(open && { ...contentWrapperStyle }),
            ...(position === 'right' ? { right: '0' } : { left: '0' })
          }}>
          <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
            {open && (
              <React.Fragment>
                {alertBarType !== 'closed' && (
                  <AlertBar
                    options={{
                      barStyle: alertBarType,
                      message: alertBarMessage,
                      timeout: alertBarTimeout
                    }}
                    wrapperStyle={{
                      width: 'calc(100% - 40px)',
                      left: '20px',
                      top: '20px'
                    }}
                    callback={this.updateSidebarAlert}
                    useInnerAlertBar
                  />
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: position === 'right' ? 'flex-start' : 'flex-end'
                  }}>
                  <Button
                    aria-label="Close Sidebar"
                    className="close"
                    type="text"
                    {...(isEmpty(customCloseText) && {
                      icon: icons.close
                    })}
                    onClick={this.handleClose}
                    title="close"
                    $iconSize="2em"
                    style={{
                      position: 'absolute',
                      left: '-40px',
                      background: 'var(--color-sidebar-bg)',
                      borderRadius: '50% 0 0 50%'
                    }}>
                    {!isEmpty(customCloseText) && <div style={sidebarCSS.back} />}
                    {customCloseText}
                  </Button>
                </div>
                <div
                  className="content sidebarContent"
                  style={{
                    ...sidebarCSS.content,
                    ...(open && { ...contentStyle, ...sidebarCSS.contentAnimation })
                  }}>
                  {childrenWithProps}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  children: PropTypes.node,
  position: PropTypes.string,
  width: PropTypes.string,
  axiosRequest: PropTypes.func.isRequired,
  callback: PropTypes.func,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentWrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  closeOnOutsideClick: PropTypes.bool,
  customCloseText: PropTypes.string
};

Sidebar.defaultProps = {
  sidebarOpen: false,
  children: null,
  position: 'right',
  width: '50%',
  callback: () => {},
  wrapperStyle: {},
  contentWrapperStyle: {},
  contentStyle: {},
  closeOnOutsideClick: true,
  customCloseText: ''
};

export default Sidebar;
