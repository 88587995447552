import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import { isEmpty } from './_helpers';

const { Menu } = components;

export class ComboBoxMenu extends React.PureComponent {
  render() {
    const { children, selectProps } = this.props;
    const { editable, size, searchKeys, useBlockForm, menuPlacement } = selectProps || {};
    return (
      <Menu {...this.props}>
        <div
          className="search-by-wrapper"
          style={{
            backgroundColor:
              'hsl(var(--color-quinary-hue), calc(var(--color-quinary-saturation) + 40%), var(--color-quinary-lightness))',
            borderBottom: '1px solid var(--color-hr)',
            ...(!useBlockForm && {
              ...(menuPlacement === 'bottom' && {
                borderTopLeftRadius: 'var(--radius-main)',
                borderTopRightRadius: 'var(--radius-main)',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0'
              }),
              ...(menuPlacement === 'top' && {
                borderRadius: 'var(--radius-main)',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0'
              })
            }),
            display: 'flex',
            flexWrap: 'wrap',
            padding: '5px 8px'
          }}>
          <div
            style={{
              alignContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
              flex: '100%',
              flexWrap: 'wrap'
            }}>
            <div
              style={{
                color: 'var(--color-light-label)',
                flex: '1',
                fontSize: size === 'sm' ? '0.8rem' : '1rem',
                fontWeight: 'normal',
                textAlign: 'left'
              }}>
              {size === 'sm' ? null : (
                <Icon
                  icon={icons.info.src_color}
                  $useMask
                  color="var(--color-light-label)"
                  style={{ height: '14px', marginTop: '-2px', width: '14px' }}
                />
              )}
              <span
                className="type-to-text-info-text"
                style={{
                  textTransform: 'uppercase'
                }}>{`Type to search${editable ? ', or add a new entry' : ''}...`}</span>
            </div>
            {!isEmpty(searchKeys) && (
              <div
                className="search-keys-wrapper"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: '0.3em'
                }}>
                <div
                  style={{
                    color: 'var(--color-light-label)',
                    fontSize: '1rem',
                    textTransform: 'uppercase'
                  }}>
                  Can search by:
                </div>
                {searchKeys.map((item, index) => (
                  <span
                    key={`${item.value}_${index.toString()}`}
                    className="search-key"
                    id={`${item.value}SearchKeyId`}
                    style={{ fontSize: '1.1rem' }}>
                    {`${item.title}${searchKeys.length > 1 && index !== searchKeys.length - 1 ? ',' : ''}`}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        {children}
      </Menu>
    );
  }
}

ComboBoxMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  selectProps: PropTypes.oneOfType([PropTypes.object])
};

ComboBoxMenu.defaultProps = {
  children: null,
  selectProps: {}
};

export default ComboBoxMenu;
