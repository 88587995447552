// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { StyledTableRow } from '../css/_styledTable';
import {
  download,
  edit,
  view,
  deleteItem,
  checkmark,
  signedDocument,
  upload
} from '../images/_icons';
import { cellActions, getTextContent, isEmpty } from './_helpers';

class DataTableRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { checkboxState, data, highlightActive, highlightColumn } = this.props;
    return (
      JSON.stringify(checkboxState) !== JSON.stringify(nextProps.checkboxState) ||
      JSON.stringify(data) !== JSON.stringify(nextProps.data) ||
      highlightActive !== nextProps.highlightActive ||
      highlightColumn !== nextProps.highlightColumn
    );
  }

  TableCell = (options) => {
    const { cell, rowIndex, value } = options || {};
    const { primaryKey, highlightColumn } = this.props;
    const textContent = getTextContent(value);
    return (
      <td
        data-testid={`${cell}-cell-row-${rowIndex}`}
        className={`${cell === primaryKey ? `locked ${cell}` : `${cell}`}${highlightColumn === cell ? ' highlight' : ''}`}
        style={{ verticalAlign: 'top' }}>
        <div
          className="cell"
          style={{
            // ~70 characters is ~400 px wide, which seems good as a general max width for cells
            ...(!isEmpty(textContent) &&
              textContent.length > 70 && {
                whiteSpace: 'break-spaces',
                lineHeight: '1.2'
              }),
            ...(typeof value === 'boolean' &&
              value && {
                backgroundImage: checkmark.src_color,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              })
          }}>
          {
            /* istanbul ignore next */
            !isEmpty(value?.action) ? (
              <Button
                type="text"
                style={{ fontSize: '1.3rem', margin: '0', padding: '0' }}
                onClick={() => this.handleCellClick(value)}>
                {this.displayValue(value.value)}
              </Button>
            ) : (
              this.displayValue(value)
            )
          }
        </div>
      </td>
    );
  };

  displayValue = (item) => {
    if (isEmpty(item)) return null;
    if (typeof item === 'object') {
      if (item.renderComponent && typeof item.renderComponent === 'function') {
        return item.renderComponent(item.originalData);
      }
      if (item !== null) {
        return item.key || item;
      }
    }
    // default case - string
    return item;
  };

  handleCheckbox = (id, checked) => {
    const { actionCallback } = this.props;
    actionCallback && actionCallback('select', checked, id);
  };

  handleClick = (type, value) => {
    const { actionCallback, data } = this.props;
    actionCallback && actionCallback(type, value, data);
  };

  /**
   * To have an individual cell react to a click (show a text button):
   * @param {*} cellData instead of just a simple value,
   *    pass in an object with structure {
   *      action: clickCallback,
   *      value: whatToDisplay,
   *      ...otherData to return with callback
   *    }
   */
  handleCellClick = (cellData) => {
    const clickAction = cellData?.action;
    clickAction && clickAction(cellData);
  };

  handleRowClick = (e, data) => {
    const { disabled, rowCallback } = this.props;
    if (!disabled) {
      const targetNodeName = e?.target?.nodeName || '';
      const targetType = e?.target?.type || '';
      const targetClassList = e?.target?.classList;
      if (
        rowCallback &&
        targetNodeName !== 'A' &&
        targetNodeName !== 'BUTTON' &&
        targetNodeName !== 'SPAN' &&
        targetNodeName !== 'LABEL' &&
        targetType !== 'checkbox' &&
        !targetClassList.contains('checkbox_span')
      ) {
        rowCallback(data, e);
      }
    }
  };

  rowClass = (rc, active, rowCallback, disabled) => {
    const classes = [];
    if (!isEmpty(rc)) {
      classes.push(rc);
    }
    if (active) {
      classes.push('active');
    }
    if (rowCallback) {
      classes.push('hasCallback');
    }
    if (disabled) {
      classes.push('disabled');
    }
    return classes.join(' ');
  };

  render() {
    const {
      rowIndex,
      checkboxState,
      data,
      visible,
      primaryKey,
      rowCallback,
      highlightActive,
      disabled
    } = this.props;
    const actionSet = Object.keys(data).filter((element) => cellActions.includes(element)) || [];
    const actionIcons = {
      download,
      edit,
      view,
      deleteDelegate: deleteItem,
      deleteApp: deleteItem,
      requestSignature: signedDocument,
      upload
    };
    const actionsAltText = {
      download: 'Download',
      edit: 'Edit',
      view: 'View',
      deleteDelegate: 'Delete',
      deleteApp: 'Delete',
      requestSignature: 'Request new signature',
      upload: 'Upload'
    };
    const classes = this.rowClass(data.rowClass, highlightActive, rowCallback, disabled);
    return (
      <StyledTableRow
        aria-label={`Data table row ${Number(rowIndex || 0) + 1}`}
        data-rowid={data[primaryKey] || data.email}
        onClick={(e) => this.handleRowClick(e, data)}
        onKeyDown={(e) => this.handleRowClick(e, data)}
        {...(!isEmpty(classes) && {
          className: classes
        })}>
        {actionSet.length ? (
          <td
            data-testid={`actions-cell-row-${rowIndex}`}
            className="actions"
            style={{ display: 'flex', minWidth: '80px' }}>
            {actionSet.map((key) =>
              data[key] ? (
                key === 'select' ? (
                  <Checkbox
                    key={key}
                    id={data[key]}
                    wrapperStyle={{
                      marginTop: '5px',
                      width: '100%',
                      height: '100%'
                    }}
                    height="100%"
                    width="100%"
                    type="mini"
                    callback={this.handleCheckbox}
                    checked={checkboxState[data[key]]}
                  />
                ) : (
                  <Button
                    key={key}
                    type="text"
                    className={key}
                    style={{
                      marginTop: '3px',
                      float: 'right',
                      backgroundColor: 'transparent'
                    }}
                    icon={{ ...actionIcons[key](), color: 'var(--color-link)' }}
                    size="sm"
                    {...(!isEmpty(data.href) && { href: data.href })}
                    onClick={() => this.handleClick(key, data[key])}
                    {...(actionsAltText[key] && {
                      'aria-label': actionsAltText[key],
                      title: actionsAltText[key]
                    })}
                  />
                )
              ) : (
                <div key={key} style={{ width: '20px', height: '20px', float: 'right' }} />
              )
            )}
          </td>
        ) : null}
        {visible.map((key) => (
          <this.TableCell key={key} cell={key} value={data[key]} rowIndex={rowIndex} />
        ))}
      </StyledTableRow>
    );
  }
}

DataTableRow.propTypes = {
  rowIndex: PropTypes.string,
  checkboxState: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  primaryKey: PropTypes.string,
  visible: PropTypes.arrayOf(PropTypes.string),
  toggleLoader: PropTypes.func,
  alertBar: PropTypes.func,
  dataUpdate: PropTypes.func,
  setCurrentApplication: PropTypes.func,
  actionCallback: PropTypes.func,
  rowCallback: PropTypes.func,
  highlightActive: PropTypes.bool,
  disabled: PropTypes.bool,
  highlightColumn: PropTypes.string
};

DataTableRow.defaultProps = {
  rowIndex: '0',
  checkboxState: {},
  data: {},
  primaryKey: '',
  visible: [],
  toggleLoader: () => {},
  alertBar: () => {},
  dataUpdate: () => {},
  setCurrentApplication: () => {},
  actionCallback: null,
  rowCallback: null,
  highlightActive: false,
  disabled: false,
  highlightColumn: null
};

export default DataTableRow;
