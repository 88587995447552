import { mockBoardingApplicationJson } from '../sharedBoarding/mockBoardingApplicationJson';
import {
  createMockFileList,
  staticMpaFilesMap
} from '../sharedBoarding/mockBoardingApplicationFiles';
import { staticMpas } from '../sharedBoarding/mockBoardingApplicationMpaList';
import { getMockValue } from '../_mockDataHelpers';
import { sharedFormFields } from '../../_formFields';

export const createMockMpaDetail = (guid, _options) => {
  const guidNumber = typeof guid === 'string' ? Number(guid) : guid;
  const mockMpa = mockBoardingApplicationJson(guidNumber);
  const mockAppDetails = {
    employeeOnlyMpaMetadata: {
      policyException: {
        description: getMockValue({ type: 'description', length: 'long' })
      },
      riskExposureInputs: mockRiskExposureInputs()
    },
    mpaDetails: {
      applicationMpaId: `${guid}`,
      dbaName: mockMpa.businessInformation.dbaName,
      mpa: mockMpa,
      fileList: createMockFileList({ staticFiles: staticMpaFilesMap[guidNumber] || [] }),
      ...(staticMpas && staticMpas[guidNumber] && { ...staticMpas[guidNumber] })
    }
  };
  return mockAppDetails;
};

export const mockCrabApplicationMpaDetailGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const mockDetails = createMockMpaDetail(guid, options);
  return { [guid]: mockDetails };
};

export const mockRiskExposureInputs = () => ({
  creditReturnRatio: getMockValue({ type: 'ratio' }),
  chargebackRatio: getMockValue({ type: 'ratio' }),
  nonDeliveryRiskDays: getMockValue({ type: 'number' }),
  creditTime: getMockValue({ type: 'number' }),
  monthlyVolume: getMockValue({ type: 'number' }),
  percentKeyed: getMockValue({ type: 'ratio' }),
  mccCode: getMockValue({ type: 'list', list: sharedFormFields.mccCode.list })
});

export default mockCrabApplicationMpaDetailGet;
