import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';

const DragAndDropItem = ({
  disabled = false,
  item = {},
  id = '',
  useHandle = false,
  handleStyles = {}
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = disabled ? {} : useSortable({ id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    position: 'relative'
  };
  return (
    <div
      role="article"
      aria-label="Draggable item"
      ref={setNodeRef}
      style={style}
      {...!useHandle && ({
        ...attributes,
        ...listeners
      })}
      className="draggableItem"
    >
      {useHandle && (
        <Icon
          className="dragHandle"
          icon={icons.draggable.default}
          color={disabled ? 'var(--color-light-label)' : 'var(--color-dark-grey)'}
          $useMask
          {...attributes}
          {...listeners}
          style={{
            margin: '0',
            cursor: disabled ? 'not-allowed' : 'move',
            position: 'absolute',
            zIndex: '1',
            height: '100%',
            width: '31px',
            padding: '0 4px',
            ...handleStyles
          }}
        />
      )}
      {item}
    </div>
  );
};

DragAndDropItem.propTypes = {
  disabled: PropTypes.bool,
  item: PropTypes.oneOfType([PropTypes.object]),
  id: PropTypes.string,
  useHandle: PropTypes.bool,
  handleStyles: PropTypes.oneOfType([PropTypes.object])
};

export default DragAndDropItem;
