import { sortData } from '@f1/shared/src/_helpers';
import {
  getElevatedRelationships,
  getPreferredRelationships,
  getStandardRelationships
} from '@f1/shared/src/_templateHelpers';
import { mockRelationships } from './mockRelationships';

export const mockRelationshipGet = [
  // api response
  mockRelationships.relationship01.response,
  mockRelationships.relationship02.response,
  mockRelationships.relationship03.response,
  mockRelationships.relationship04.response,
  mockRelationships.relationship05.response,
  mockRelationships.relationship06.response,
  mockRelationships.relationship07.response,
  mockRelationships.relationship08.response,
  mockRelationships.relationship09.response,
  mockRelationships.relationship10.response,
  mockRelationships.relationship11.response,
  mockRelationships.relationship12.response,
  mockRelationships.relationship13.response,
  mockRelationships.relationship14.response,
  mockRelationships.relationship15.response,
  mockRelationships.relationship20.response,
  mockRelationships.relationship21.response,
  mockRelationships.relationship22.response,
  mockRelationships.relationship23.response,
  mockRelationships.relationship24.response
];

export const mockGatewayInfoList = [
  { gatewayName: 'NMI', gatewayAffiliateIdList: ['f1_house', 'ace_10758'] },
  {
    gatewayName: 'FLUID_PAY',
    gatewayAffiliateIdList: ['reddex_cbpd9846lr8qk82v43u0', 'medsnow_inc_cfqiohc6lr8ujj237n10']
  }
];

export const mockRelationshipGetRes = {
  relationships: mockRelationshipGet,
  gatewayInfoList: mockGatewayInfoList
};

export const mockRelationshipGetFormatted = sortData(
  [
    // deserialized
    mockRelationships.relationship01.deserialized,
    mockRelationships.relationship02.deserialized,
    mockRelationships.relationship03.deserialized,
    mockRelationships.relationship04.deserialized,
    mockRelationships.relationship05.deserialized,
    mockRelationships.relationship06.deserialized,
    mockRelationships.relationship07.deserialized,
    mockRelationships.relationship08.deserialized,
    mockRelationships.relationship09.deserialized,
    mockRelationships.relationship10.deserialized,
    mockRelationships.relationship11.deserialized,
    mockRelationships.relationship12.deserialized,
    mockRelationships.relationship13.deserialized,
    mockRelationships.relationship14.deserialized,
    mockRelationships.relationship15.deserialized,
    mockRelationships.relationship20.deserialized,
    mockRelationships.relationship21.deserialized,
    mockRelationships.relationship22.deserialized,
    mockRelationships.relationship23.deserialized,
    mockRelationships.relationship24.deserialized,
    mockRelationships.relationship25.deserialized
  ],
  'title'
);

export const mockPreferredRelationships = getPreferredRelationships(mockRelationshipGetFormatted);
export const mockStandardRelationships = getStandardRelationships(mockRelationshipGetFormatted);
export const mockElevatedRelationships = getElevatedRelationships(mockRelationshipGetFormatted);
