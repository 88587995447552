import { sortData } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { getMockEmployee } from '../../_mockHelpers';
import { ticketGuids } from '../../guids';

const getMockHistoryEvent = (item, index) => {
  const isOptional = index % 2 === 0;
  const mockChangeType = getMockValue({
    type: 'list',
    list: [
      'created',
      'edited',
      'closed',
      'assessment_event',
      'risk_monitoring_event',
      'on_hold_event',
      'manually_triggered_auto_board_to_gateway',
      'note_added',
      'file_added',
      'file_renamed',
      'file_deleted',
      'file_tag_altered',
      'ticket_added',
      'risk_rule_ticket_added'
    ]
  });
  const isTicket = index === 0;
  const isNote = index === 1;
  const isFile = index === 2;
  const isFixed = isTicket || isNote || isFile;
  const mockRelatedGuidType = getMockValue({
    type: 'list',
    list: ['ticketId', 'noteId', 'fileId'],
    ...(isTicket && { list: ['ticketId'] }),
    ...(isNote && { list: ['noteId'] }),
    ...(isFile && { list: ['fileId'] })
  });
  const guidTypeToValueMap = {
    ticketId: `${ticketGuids.default}`,
    noteId: `mock-note-id-${index}`,
    fileId: `mock-file-id-${index}`
  };
  return {
    changeType: mockChangeType,
    changeDescription:
      isOptional && !isFixed
        ? null
        : Array.from({ length: 5 })
            .map(() => getMockValue({ type: 'description' }))
            .join('\n'),
    changeTimestamp: getMockValue({
      type: 'isoDate',
      ...(isTicket && { min: 2023, max: 2023 }), // For FTs
      ...(isNote && { min: 2022, max: 2022 }), // For FTs
      ...(isFile && { min: 2021, max: 2021 }) // For FTs
    }),
    employee: isFixed
      ? getMockEmployee()
      : (getMockValue({ type: 'boolean' }) && getMockEmployee()) || null,
    relatedGuidValue: isOptional && !isFixed ? null : guidTypeToValueMap[mockRelatedGuidType],
    relatedGuidType: isOptional && !isFixed ? null : mockRelatedGuidType
  };
};

const getMockHistoryList = () => {
  const mockHistoryList = Array.from({ length: 20 }).map(getMockHistoryEvent);
  return sortData(mockHistoryList, 'changeTimestamp', { direction: 'desc' });
};

export const getMockMerchantV2History = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: { historyEventList: getMockHistoryList() } };
};

export default getMockMerchantV2History;
