import React from 'react';
import globalFormProps from './_formHelpers';
import { countryCodes, ignoreCase, sortData, stateCodes, monthNames, isEmpty } from './_helpers';
import { sharedFormFields } from './_formFields';

export const customValidations = {
  establishedYear: (value) => RegExp(/^[0-9]{4}$/).test(value),
  amexNumber: (value) => RegExp(/^[0-9]{10}$/).test(value),
  dbaName: (value) => !isEmpty(value) && !RegExp(/[^A-Za-z0-9 ]/g).test(value),
  legalBusinessName: (value) => !isEmpty(value) && !RegExp(/[^A-Za-z0-9 ]/g).test(value),
  descriptor: (value) => !isEmpty(value) && value.length <= 25,
  preArbitrationHigh: (value) => !isEmpty(value) && value >= 35,
  preArbitrationLow: (value) => !isEmpty(value) && value >= 25
};

export const seasonalBusinessMonthFields = monthNames
  .filter((mon) => mon !== 'N/A')
  .map((mon) => ({
    checked: false,
    title: mon,
    value: mon.toLowerCase()
  }));

export const toggleFieldOptions = {
  fieldType: 'checkbox',
  type: 'mini',
  height: 'auto',
  wrapperStyle: {
    width: '100%',
    backgroundColor: 'var(--color-form-subheader-option-bg)',
    padding: '3px 4px'
  },
  labelStyle: { fontWeight: 'bold' }
};

export const baseFormFields = {
  // businessInformationSection
  dbaName: {
    id: 'dbaName',
    label: 'Doing Business As (DBA) Name',
    type: 'text',
    fieldType: 'input'
  },
  descriptor: {
    id: 'descriptor',
    label: 'Descriptor',
    type: 'text',
    fieldType: 'input',
    customValidation: customValidations.descriptor,
    errorMessage: 'Must be 25 characters or less'
  },
  telephoneNumber: {
    id: 'telephoneNumber',
    label: 'Telephone Number',
    type: 'tel',
    telPrefixRequired: true, // to include +1 for BE
    fieldType: 'input'
  },
  establishedYear: {
    id: 'establishedYear',
    label: 'Established Year',
    type: 'text',
    fieldType: 'input',
    customValidation: customValidations.establishedYear,
    errorMessage: 'Must be a valid year'
  },
  faxNumber: {
    id: 'faxNumber',
    label: 'Fax Number',
    type: 'tel',
    telPrefixRequired: true, // to include +1 for BE
    fieldType: 'input',
    required: false
  },
  contactFullName: {
    id: 'contactFullName',
    label: 'Contact Full Name',
    type: 'text',
    fieldType: 'input'
  },
  contactEmailAddress: {
    id: 'contactEmailAddress',
    label: 'Contact Email Address',
    type: 'email',
    fieldType: 'input',
    wrapperStyle: {
      ...globalFormProps.input.wrapperStyle
    }
  },
  merchantWebsite: {
    id: 'merchantWebsite',
    label: 'Merchant Website (URL)',
    type: 'urlRequiredProtocol',
    fieldType: 'input'
  },
  legalBusinessName: {
    id: 'legalBusinessName',
    label: 'Legal Business Name',
    type: 'text',
    fieldType: 'input'
  },
  taxpayerIdentificationNumber: {
    id: 'taxpayerIdentificationNumber',
    label: 'Taxpayer Identification Number (EIN)',
    type: 'ssn',
    fieldType: 'input'
  },
  organizationalStructure: {
    id: 'organizationalStructure',
    label: 'Organizational Structure',
    type: 'text',
    list: [
      { title: 'Corporation', value: 'corporation' },
      { title: 'LLC', value: 'llc' },
      { title: 'Sole Proprietor', value: 'soleProprietor' },
      { title: '501C', value: '501C' },
      { title: 'Partnership', value: 'partnership' },
      { title: 'Government/Municipality', value: 'government' }
    ],
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true
  },
  publicCompany: {
    id: 'publicCompany',
    label: 'Public Company',
    name: 'publicCompany',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },
  stockSymbol: {
    id: 'stockSymbol',
    label: 'Stock Symbol',
    type: 'text',
    fieldType: 'input'
  },
  market: {
    id: 'market',
    label: 'Market',
    type: 'text',
    fieldType: 'input'
  },
  typeOfProductsOrServicesSold: {
    id: 'typeOfProductsOrServicesSold',
    label: 'Type of Product/Services Sold',
    type: 'text',
    fieldType: 'input'
  },
  preferredMccCode: {
    ...sharedFormFields.mccCode,
    wrapperStyle: { flex: '100%' },
    formField: false, // don't allow clearing of required field
    required: true,
    id: 'preferredMccCode',
    label: 'Preferred MCC Code'
  },

  // billingAddressSection
  billingAddressSameAs: {
    id: 'billingAddressSameAs',
    name: 'billingAddressSameAs',
    label: 'Billing Address Same as Business Address',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },

  // paymentProcessingAndSalesSection
  monthlyTotalProcessingVolume: {
    id: 'monthlyTotalProcessingVolume',
    label: 'Monthly Total Processing Volume',
    type: 'price',
    priceToNumber: true, // convert from string to number type for BE value
    prefix: '$',
    fieldType: 'input'
  },
  monthlyAmexProcessingVolume: {
    id: 'monthlyAmexProcessingVolume',
    label: 'Monthly Amex Processing Volume',
    type: 'price',
    priceToNumber: true, // convert from string to number type for BE value
    prefix: '$',
    fieldType: 'input'
  },
  averageTransactionAmount: {
    id: 'averageTransactionAmount',
    label: 'Average Transaction Amount',
    type: 'price',
    priceToNumber: true, // convert from string to number type for BE value
    prefix: '$',
    fieldType: 'input'
  },
  highestTransactionAmount: {
    id: 'highestTransactionAmount',
    label: 'Highest Transaction Amount',
    type: 'price',
    priceToNumber: true, // convert from string to number type for BE value
    prefix: '$',
    fieldType: 'input'
  },
  // orderChannelSection (sub-section of paymentProcessingAndSalesSection)
  emvOrCardPresentSwiped: {
    // orderChannel.emvOrCardPresentSwiped
    id: 'emvOrCardPresentSwiped',
    label: 'EMV/Card Present Swiped',
    type: 'percent',
    isRatio: true,
    required: true,
    suffix: '%',
    fieldType: 'input'
  },
  telephoneOrder: {
    // orderChannel.telephoneOrder
    id: 'telephoneOrder',
    label: 'Telephone Order',
    type: 'percent',
    isRatio: true,
    required: true,
    suffix: '%',
    fieldType: 'input'
  },
  eCommerce: {
    // orderChannel.eCommerce
    id: 'eCommerce',
    label: 'eCommerce',
    type: 'percent',
    isRatio: true,
    required: true,
    suffix: '%',
    fieldType: 'input'
  },
  // SUB-SECTION (of paymentProcessingAndSales)
  seasonalBusiness: {
    id: 'seasonalBusiness',
    name: 'seasonalBusiness',
    label: 'Seasonal Business?',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ],
    controls: {
      yes: {
        seasonMonths: {
          // turns into seasonMonths.january , *.february, ...etc
          id: 'seasonMonths',
          type: 'mini',
          labelPos: 'top',
          boxStyle: 'inside',
          label: 'Seasonal Months of Operation',
          fieldType: 'checkboxList',
          isCheckboxList: true,
          list: seasonalBusinessMonthFields,
          required: true,
          containerStyle: {
            flex: '100%'
          },
          backendFormat: (array) => {
            // Custom method to format for BE value
            const dataValueMap = array.reduce((acc, item) => ({ ...acc, ...item }), {});
            const customValue = monthNames
              .filter((month) => month !== 'N/A')
              .reduce((acc, month) => {
                const monthValue = month.toLowerCase();
                return { ...acc, [monthValue]: dataValueMap[monthValue] || false };
              }, {});
            return customValue;
          }
        }
      }
    }
  },

  // ownerListSection
  firstName: {
    id: 'firstName',
    label: 'First Name',
    type: 'text',
    fieldType: 'input'
  },
  lastName: {
    id: 'lastName',
    label: 'Last Name',
    type: 'text',
    fieldType: 'input'
  },
  dob: {
    id: 'dob',
    label: 'Date of Birth',
    type: 'date',
    fieldType: 'input'
  },
  ssn: {
    id: 'ssn',
    label: 'SSN',
    type: 'ssn',
    fieldType: 'input'
  },
  ownershipPercentage: {
    id: 'ownershipPercentage',
    label: 'Ownership Percentage',
    suffix: '%',
    type: 'percentOwnership',
    isRatio: true,
    fieldType: 'input',
    hasCustomFieldValidation: true
  },
  streetAddress: {
    id: 'streetAddress',
    label: 'Street Address',
    type: 'text',
    fieldType: 'input'
  },
  city: {
    id: 'city',
    label: 'City',
    type: 'text',
    fieldType: 'input'
  },
  state: {
    id: 'state',
    label: 'State',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    list: stateCodes.map((code) => ({ title: code, value: code }))
  },
  zip: {
    id: 'zip',
    label: 'Zip Code',
    type: 'zipcode',
    fieldType: 'input'
  },
  emailAddress: {
    id: 'emailAddress',
    label: 'Email Address',
    type: 'email',
    fieldType: 'input',
    required: false
  },
  businessPhoneNumber: {
    id: 'businessPhoneNumber',
    label: 'Business Phone Number',
    type: 'tel',
    fieldType: 'input',
    required: true
  },
  mobileNumber: {
    id: 'mobileNumber',
    label: 'Mobile Number',
    type: 'tel',
    fieldType: 'input',
    required: true
  },
  county: {
    id: 'county',
    label: 'County',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  type: {
    id: 'type',
    label: 'Type',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    formField: true,
    list: [
      { title: 'Beneficial Owner', value: 'Beneficial Owner' },
      { title: 'Officer', value: 'Officer' },
      { title: 'Principal', value: 'Principal' },
      { title: 'Government', value: 'Government' },
      { title: 'Municipality Controlling Owner', value: 'Municipality Controlling Owner' }
    ],
    required: false
  },
  title: {
    id: 'title',
    label: 'Title',
    type: 'onlyAlpha',
    fieldType: 'input'
  },

  // achInformationAndFundingChoicesSection
  bankName: {
    id: 'bankName',
    label: 'Bank Name',
    type: 'text',
    fieldType: 'input'
  },
  nameOnAccount: {
    id: 'nameOnAccount',
    label: 'Name on Bank Account',
    type: 'text',
    fieldType: 'input'
  },
  routingNumber: {
    id: 'routingNumber',
    label: 'Transit (ABA Routing) Number',
    type: 'bankrouting',
    required: true,
    fieldType: 'input'
  },
  accountNumber: {
    id: 'accountNumber',
    label: 'Account Number',
    type: 'bankaccount',
    required: true,
    fieldType: 'input'
  },
  fundingChoice: {
    id: 'fundingChoice',
    label: 'Funding Choice',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    list: [
      { title: 'Standard', value: 'standard' },
      { title: 'Next Day Funding', value: 'next_day_funding' }
    ]
  },

  // paymentMethodsAcceptedSection
  debitCardsOnly: {
    id: 'debitCardsOnly',
    name: 'debitCardsOnly',
    label: 'PIN Based Debit Only?',
    type: 'radio',
    fieldType: 'radio',
    wrapperStyle: { flex: '100%' }
    // DO NOT include `fields` here, must be its own property for each form field
  },
  debitCardsOnlyWarningDiv: {
    fieldType: 'div',
    id: 'debitCardsOnlyWarningDiv',
    style: {
      // Should appear inline with the debitCardsOnly radio
      backgroundColor: 'var(--color-healthy-bg)',
      borderBottom: '1px solid var(--color-text)',
      flex: '100%',
      fontSize: '1.3rem',
      lineHeight: '1.7',
      marginTop: '-10px',
      padding: '0 0.5em',
      zIndex: '2'
    },
    children: (
      <span style={{ color: 'var(--color-warning)' }}>
        <strong>WARNING: </strong>
        {}
        Selecting this means the merchant will only take pin-based debit cards and will NOT accept
        any credit cards.
      </span>
    )
  },
  paymentCardsAccepted: {
    // FE-only key, converts BE data to FE checkboxList
    id: 'paymentCardsAccepted',
    label: 'Payment Cards Accepted',
    fieldType: 'checkboxList',
    list: [
      { checked: false, title: 'Visa', value: 'visa' },
      { checked: false, title: 'Mastercard', value: 'mastercard' },
      { checked: false, title: 'Discover', value: 'discover' },
      { checked: false, title: 'Amex OptBlue', value: 'amexOptBlue' }
    ],
    boxStyle: 'inside',
    isCheckboxList: true, // if BE value is in the format that FE already expects
    controllerStateOverrides: {
      amexOptBlue: {
        // when amexOptBlue is checked, reset amexNumber
        amexNumber: '',
        amexNumberIsValid: false
      }
    },
    controls: {
      amexOptBlue: {
        amexNumber: {
          id: 'amexNumber',
          label: 'Amex Number',
          type: 'text',
          fieldType: 'input',
          customValidation: customValidations.amexNumber,
          errorMessage: 'Must be 10 digits',
          required: false
        }
      }
    },
    backendFormat: (array, options) => {
      // Custom method to format for BE valuesForBackend
      const { list = [] } = options || {};
      const dataValueMap = array.reduce((acc, item) => ({ ...acc, ...item }), {});
      const customValue = list.reduce((acc, item) => {
        const itemValue = item.value;
        return { ...acc, [itemValue]: dataValueMap[itemValue] || false };
      }, {});
      return customValue;
    }
  },
  amexRetainedESA: {
    id: 'amexRetainedESA',
    name: 'amexRetainedESA',
    label: 'Amex (Retained/ESA)',
    type: 'radio',
    fieldType: 'radio'
    // DO NOT include `fields` here, must be its own property for each form field
  },
  electronicBenefitsTransfer: {
    id: 'electronicBenefitsTransfer',
    name: 'electronicBenefitsTransfer',
    label: 'Electronic Benefits Transfer',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },
  ebtTextDiv: {
    fieldType: 'div',
    id: 'ebtTextDiv',
    style: {
      backgroundColor: 'var(--color-healthy-bg)',
      borderBottom: '1px solid var(--color-text)',
      flex: '100%',
      fontSize: '1.3rem',
      lineHeight: '1.7',
      marginTop: '-10px',
      padding: '0 0.5em',
      zIndex: '2'
    },
    children: (
      <span style={{ color: 'var(--color-text)' }}>
        EBT will use the debit rates if you select Tiered pricing or will use the auth rate and per
        tran fee associated with your selected Visa/MC rate if you select any other pricing
      </span>
    )
  },
  fnsEbtNumber: {
    id: 'fnsEbtNumber',
    label: 'FNS / EBT Number',
    type: 'text',
    fieldType: 'input'
  },

  additionalDiscount: {
    // Rates & Fees
    id: 'additionalDiscount',
    label: 'Additional Discount',
    type: 'percent',
    isRatio: true,
    suffix: '%',
    fieldType: 'input'
  },
  debitMonthlyAccess: {
    // Rates & Fees
    id: 'debitMonthlyAccess',
    label: 'Debit Monthly Access',
    type: 'rationalNumber',
    fieldType: 'input'
  },

  // ratesAndFeesSection
  pricingType: {
    id: 'pricingType',
    label: 'Pricing Type',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    wrapperStyle: {
      flex: '100%'
    }
  },
  // LR uses rate & authorization (possibly pricing too but that looks like a header)
  // HR uses volumeRatio & perTransation
  // Will prob move to _highRiskFields.js if these end up being not usable on both forms
  volumeRatio: {
    // maybe Rate or Authorization on LR?
    id: 'volumeRatio',
    label: 'Volume Ratio',
    type: 'percent',
    isRatio: true,
    suffix: '%',
    fieldType: 'input'
  },
  perTransaction: {
    id: 'perTransaction',
    label: 'Per Transaction',
    type: 'price',
    prefix: '$',
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  },

  accountUpdaterEnabled: {
    // accountUpdaterSection
    id: 'accountUpdaterEnabled',
    name: 'accountUpdaterEnabled',
    label: 'Account Updater',
    ...toggleFieldOptions
  },
  setup: {
    // accountUpdaterSection
    id: 'setup',
    label: 'One-time Setup Fee',
    type: 'price',
    prefix: '$',
    priceToNumber: true, // if BE expects value as number type but FE uses string type
    fieldType: 'input'
  },
  monthly: {
    // accountUpdaterSection: accountUpdater.monthly
    id: 'monthly',
    label: 'Monthly',
    type: 'price',
    priceToNumber: true,
    prefix: '$',
    fieldType: 'input'
  },
  mobileProcessingEnabled: {
    // mobileProcessing
    id: 'mobileProcessingEnabled',
    name: 'mobileProcessingEnabled',
    label: 'Mobile Processing',
    ...toggleFieldOptions
  },
  perUser: {
    // mobileProcessing.perUser
    id: 'perUser',
    label: 'Per User',
    type: 'price',
    prefix: '$',
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  },

  gatewayEnabled: {
    // gatewaySection
    id: 'gatewayEnabled',
    name: 'gatewayEnabled',
    label: 'Gateway',
    ...toggleFieldOptions
  },
  gatewayName: {
    // gatewaySection
    id: 'gatewayName',
    label: 'Gateway Name',
    type: 'text',
    fieldType: 'input'
  },
  gatewayMonthly: {
    // gatewaySection
    id: 'gatewayMonthly',
    label: 'Gateway Monthly',
    type: 'price',
    prefix: '$',
    fieldType: 'input'
  },
  gatewayVaultTokenizationMonthly: {
    // gatewaySection
    id: 'gatewayVaultTokenizationMonthly',
    label: 'Vault Tokenization Monthly',
    type: 'price',
    prefix: '$',
    labelStyle: { whiteSpace: 'break-spaces' },
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  },
  gatewayVaultTokenizationPerTransaction: {
    // gatewaySection
    id: 'gatewayVaultTokenizationPerTransaction',
    label: 'Vault Tokenization Per Transaction',
    type: 'price',
    prefix: '$',
    labelStyle: { whiteSpace: 'break-spaces' },
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  },

  wirelessProcessingEnabled: {
    // wirelessProcessing
    id: 'wirelessProcessingEnabled',
    name: 'wirelessProcessingEnabled',
    label: 'Wireless Processing',
    ...toggleFieldOptions
  },

  annualFee: {
    // otherFees
    id: 'annualFee',
    label: 'Annual Fee',
    type: 'price',
    prefix: '$',
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  },
  transactionFee: {
    // declineRecovery
    id: 'transactionFee',
    label: 'Transaction Fee',
    type: 'price',
    prefix: '$',
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  },
  monthlyFee: {
    // declineRecovery
    id: 'monthlyFee',
    label: 'Monthly Fee',
    type: 'price',
    prefix: '$',
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  },
  // uploads
  signatureType: {
    id: 'signatureType',
    name: 'signatureType',
    label: 'Signature Type',
    type: 'radio',
    fieldType: 'radio',
    fields: [
      { label: 'Electronic Signature', value: 'electronic_signature' },
      {
        label: 'Wet Signature',
        value: 'wet_signature',
        tooltip: 'Requires a completed and signed MPA to be uploaded'
      }
    ]
  }
};

export const baseRepayFields = {
  chargebackNotificationEmailAddress: {
    ...baseFormFields.emailAddress,
    id: 'chargebackNotificationEmailAddress',
    label: 'Chargeback Notification Email',
    tooltip: `If set, this is where email notifications will be sent.\nIf empty, no chargeback emails will be sent (they will all be physically mailed).`,
    required: false
  },
  statementDistributionMethod: {
    id: 'statementDistributionMethod',
    label: 'Statement Distribution Method',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    required: false,
    list: [
      { title: 'Email', value: 'email' },
      { title: 'Letter', value: 'letter' },
      { title: 'Both', value: 'both' }
    ]
  },
  statementDestination: {
    id: 'statementDestination',
    label: 'Statement Destination',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    required: true,
    list: [
      { title: 'Corporation', value: 'corporation' },
      { title: 'Merchant', value: 'merchant' }
    ]
  },
  suppressStatement: {
    id: 'suppressStatement',
    name: 'suppressStatement',
    label: 'Suppress Statement',
    type: 'radio',
    fieldType: 'radio',
    required: false,
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },
  combinedAch: {
    id: 'combinedAch',
    name: 'combinedAch',
    label: 'Combined ACH',
    type: 'radio',
    fieldType: 'radio',
    required: true,
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  },

  discountType: (riskLevel) => ({
    id: 'discountType',
    label: 'Discount Type',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    required: true,
    ...(['elevated', 'standard'].includes(riskLevel) && { disabled: true }),
    list: [
      { title: 'Daily', value: 'daily' },
      { title: 'Monthly', value: 'monthly' }
    ],
    ...(['elevated', 'standard'].includes(riskLevel) && { initialValue: 'daily' })
  }),
  netAch: (riskLevel) => ({
    id: 'netAch',
    name: 'netAch',
    label: 'Net ACH',
    type: 'radio',
    fieldType: 'radio',
    ...(['elevated', 'standard'].includes(riskLevel) && { disabled: true }),
    fields: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' }
    ]
  }),
  terminalName: {
    id: 'terminalName',
    label: 'Terminal Name',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    required: true,
    list: sortData(
      [
        { title: 'accept.blue', value: 'accept_blue' },
        { title: 'Dejavoo QD2', value: 'dejavoo_qd2' },
        { title: 'Dejavoo QD4', value: 'dejavoo_qd4' },
        { title: 'Dejavoo Z1', value: 'dejavoo_z1' },
        { title: 'Dejavoo Z8', value: 'dejavoo_z8' },
        { title: 'Dejavoo Z9', value: 'dejavoo_z9' },
        { title: 'Dejavoo Z11', value: 'dejavoo_z11' },
        { title: 'Ingenico iCT220', value: 'ingenico_ict220' },
        { title: 'Ingenico iCT220 CTL', value: 'ingenico_ict220ctl' },
        { title: 'Ingenico iCT250', value: 'ingenico_ict250' },
        { title: 'Ingenico iWL220', value: 'ingenico_iwl220' },
        { title: 'Ingenico iWL250', value: 'ingenico_iwl250' },
        { title: 'Multipass', value: 'multipass' },
        { title: 'Pax A920', value: 'pax_a920' },
        { title: 'Pax S80', value: 'pax_s80' },
        { title: 'Tranzpay', value: 'tranzpay' },
        { title: 'Valor VL100', value: 'valor_vl100' },
        { title: 'Valor VL110', value: 'valor_vl110' },
        { title: 'Software - Other', value: 'software_other' },
        { title: 'Swipe Simple', value: 'swipe_simple' },
        { title: 'Terminal - Other', value: 'terminal_other' }
      ],
      'title'
    )
  },
  serviceLevel: (riskLevel) => ({
    id: 'serviceLevel',
    label: 'Service Level',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    required: true,
    list: [
      ...(['elevated', 'standard'].includes(riskLevel)
        ? // for mid/high risk apps only "stage_only_var" is allowed
          []
        : [{ title: 'Multipass', value: 'multipass' }]),
      { title: 'Stage Only VAR', value: 'stage_only_var' }
    ]
  }),
  selectAchAccountRadio: {
    id: 'selectAchAccountRadio',
    name: 'selectAchAccountRadio',
    label: 'Use a separate bank for',
    type: 'radio',
    fieldType: 'radio',
    wrapperStyle: { margin: 'unset' }
    // DO NOT include `fields` here, must be its own property for each form field
  },
  repayUseDebitCheckbox: {
    id: 'repayUseDebitCheckbox',
    name: 'repayUseDebitCheckbox',
    label: 'Use a separate bank for debit transactions?',
    ...toggleFieldOptions,
    required: false
  }
};

const neteviaFormFieldProps = {
  description: {
    type: 'textarea',
    fieldType: 'input',
    inputStyle: {
      overflow: 'auto',
      height: 'auto',
      resize: 'vertical',
      minHeight: '65px'
    },
    wrapperStyle: { margin: 'unset', marginBottom: '-2px', flex: '100%' }
  },
  ratio: {
    // (NOT basis points) Percent fields where total should equal 100%
    type: 'percent',
    isRatio: true,
    suffix: '%',
    fieldType: 'input'
  },
  price: {
    type: 'price',
    prefix: '$',
    priceToNumber: true, // convert from string to number type for BE value
    fieldType: 'input'
  }
};

export const neteviaCustomBackendFormats = {
  // Custom methods to format for BE valuesForBackend
  internetBusinessType: (array, options) => {
    const { list = [] } = options || {};
    const dataValueMap = array.reduce((acc, item) => ({ ...acc, ...item }), {});
    const customValue = list.reduce((acc, item) => {
      const itemValue = item.value;
      return { ...acc, [itemValue]: dataValueMap[itemValue] || false };
    }, {});
    return customValue;
  }
};

export const baseNeteviaFields = {
  // neteviaCardNotPresentSection
  internetBusinessType: {
    id: 'internetBusinessType',
    label: 'Internet Business Type',
    fieldType: 'checkboxList',
    list: [
      { checked: false, title: 'Web Hosting', value: 'webHosting' },
      { checked: false, title: 'Domain Registration', value: 'domainRegistration' },
      { checked: false, title: 'Web Page Design', value: 'webPageDesign' },
      { checked: false, title: 'Auction', value: 'auction' },
      { checked: false, title: 'Internet Service Gateway', value: 'internetServiceGateway' },
      { checked: false, title: 'Selling Digital Service', value: 'sellingDigitalService' },
      { checked: false, title: 'Advertisement', value: 'advertisement' },
      { checked: false, title: 'Selling Hard Goods', value: 'sellingHardGoods' },
      { checked: false, title: 'Other', value: 'other' }
    ],
    containerStyle: { flex: '100%' },
    backendFormat: neteviaCustomBackendFormats.internetBusinessType,
    isCheckboxList: true // if BE value is in the format that FE already expects
  },
  internetBusinessTypeOther: {
    id: 'internetBusinessTypeOther',
    label: 'Internet Business Type (Other)',
    type: 'text',
    fieldType: 'input',
    required: true
  },
  encryptionVendorsAndControls: {
    ...neteviaFormFieldProps.description,
    id: 'encryptionVendorsAndControls',
    label: 'Encryption Vendors & Controls',
    tooltip:
      'List the encryption methods, vendors, and controls used to secure transaction information',
    required: true
  },
  advertisingAndPromotionMethod: {
    ...neteviaFormFieldProps.description,
    id: 'advertisingAndPromotionMethod',
    label: 'Advertising & Promotion Method',
    tooltip: 'Describe how will the product be advertised/promoted',
    required: true
  },
  vendors: {
    ...neteviaFormFieldProps.description,
    id: 'vendors',
    label: 'Vendors',
    tooltip: 'List the name(s) and address(es) of the vendor(s) from which supplies are purchased',
    required: true
  },
  fulfillmentVendor: {
    ...neteviaFormFieldProps.description,
    id: 'fulfillmentVendor',
    label: 'Fulfillment Vendor',
    tooltip:
      'Who performs product/service fulfillment? If direct from vendor, please provide vendor name, address and phone number in full',
    required: true
  },
  saleProcessDescription: {
    ...neteviaFormFieldProps.description,
    id: 'saleProcessDescription',
    label: 'Sales Process Description',
    tooltip:
      'Describe how a sale takes place from beginning of order until completion of fulfillment',
    required: true
  },

  // neteviaBillingMethodsSection
  monthly: {
    ...neteviaFormFieldProps.ratio,
    id: 'monthly',
    label: 'Monthly',
    required: true
  },
  yearly: {
    ...neteviaFormFieldProps.ratio,
    id: 'yearly',
    label: 'Yearly',
    required: true
  },
  quarterly: {
    ...neteviaFormFieldProps.ratio,
    id: 'quarterly',
    label: 'Quarterly',
    required: true
  },
  oneTime: {
    ...neteviaFormFieldProps.ratio,
    id: 'oneTime',
    label: 'One-Time',
    required: true
  },
  hourly: {
    ...neteviaFormFieldProps.ratio,
    id: 'hourly',
    label: 'Hourly',
    required: true
  },

  // neteviaFeesSection
  authVisaMastercardDiscover: {
    ...neteviaFormFieldProps.price,
    id: 'authVisaMastercardDiscover',
    label: 'Visa/Mastercard/Discover Authorizations',
    required: true
  },
  authAmex: {
    ...neteviaFormFieldProps.price,
    id: 'authAmex',
    label: 'Amex Authorizations',
    required: true
  },
  pciAnnual: {
    ...neteviaFormFieldProps.price,
    id: 'pciAnnual',
    label: 'Annual PCI',
    required: true
  },
  reporting1099K: {
    ...neteviaFormFieldProps.price,
    id: 'reporting1099K',
    label: '1099K Reporting',
    required: true
  },
  earlyTermination: {
    ...neteviaFormFieldProps.price,
    id: 'earlyTermination',
    label: 'Early Termination',
    required: true
  },
  ebtPerTxn: {
    ...neteviaFormFieldProps.price,
    id: 'ebtPerTxn',
    label: 'EBT Per Transaction',
    required: true
  },
  regulatoryAssistancePerMonth: {
    ...neteviaFormFieldProps.price,
    id: 'regulatoryAssistancePerMonth',
    label: 'Monthly Regulatory Assistance',
    required: true
  },
  webMonitoringSetup: {
    ...neteviaFormFieldProps.price,
    id: 'webMonitoringSetup',
    label: 'Web Monitoring Setup',
    required: true
  }
};

export const ownerFieldsBundle = (options, ownerType) => {
  const { signatureType, relationship, useAddressAutocomplete } = options || {};
  const processName = ignoreCase(relationship?.processName || relationship?.processorName || '');
  const riskProfile = ignoreCase(relationship?.riskProfile || relationship?.riskLevel || '');
  const isPriority = processName === 'priority';
  const isControlOwner = ownerType === 'controllingOwner';
  const isElevatedRisk = riskProfile === 'elevated';
  const baseAddressFields = {
    streetAddress: { ...baseFormFields.streetAddress, required: true },
    city: { ...baseFormFields.city, required: true },
    state: { ...baseFormFields.state, required: true },
    zip: { ...baseFormFields.zip, required: true }
  };
  const addressFields = useAddressAutocomplete
    ? {
        // beneficialOwnersAddressAutocomplete, controllingOwnerAddressAutocomplete
        [`${ownerType}AddressAutocomplete`]: {
          id: `${ownerType}AddressAutocomplete`,
          type: 'text',
          isAddressAutocomplete: true,
          fieldType: 'addressAutocomplete',
          baseAddressFields,
          boxStyle: 'inside',
          required: true
        }
      }
    : { ...baseAddressFields };
  return {
    firstName: baseFormFields.firstName,
    lastName: baseFormFields.lastName,
    dob: baseFormFields.dob,
    ssn: baseFormFields.ssn,
    ownershipPercentage: baseFormFields.ownershipPercentage,
    ...addressFields,
    emailAddress: {
      ...baseFormFields.emailAddress,
      ...(isControlOwner &&
        ignoreCase(signatureType) === 'electronic_signature' && {
          tooltip: 'A signature request will be sent to this email upon application submission'
        }),
      required: true
    },
    phoneNumber: {
      // FE only radio
      id: 'phoneNumber',
      name: 'phoneNumber',
      label: 'Phone Number',
      type: 'radio',
      fieldType: 'radio',
      fields: [
        { label: 'Business', value: 'businessPhoneNumberOnly' },
        { label: 'Mobile', value: 'mobileNumberOnly' },
        { label: 'Both', value: 'businessAndMobileNumber' }
      ],
      controllerStateOverrides: {
        businessPhoneNumberOnly: {
          // when selected, make mobileNumber valid
          mobileNumber: '',
          mobileNumberIsValid: true,
          businessPhoneNumber: '', // reset businessPhoneNumber
          businessPhoneNumberIsValid: false
        },
        mobileNumberOnly: {
          // when selected, make businessPhoneNumber valid
          businessPhoneNumber: '',
          businessPhoneNumberIsValid: true,
          mobileNumber: '', // reset mobileNumber
          mobileNumberIsValid: false
        },
        businessAndMobileNumber: {
          // reset both
          businessPhoneNumber: '',
          businessPhoneNumberIsValid: false,
          mobileNumber: '',
          mobileNumberIsValid: false
        }
      },
      controls: {
        businessPhoneNumberOnly: {
          businessPhoneNumber: baseFormFields.businessPhoneNumber
        },
        mobileNumberOnly: {
          mobileNumber: baseFormFields.mobileNumber
        },
        businessAndMobileNumber: {
          businessPhoneNumber: baseFormFields.businessPhoneNumber,
          mobileNumber: baseFormFields.mobileNumber
        }
      }
    },
    county: baseFormFields.county,
    type: baseFormFields.type,
    title: baseFormFields.title,
    ...(isPriority && {
      controlPersonPriority: {
        id: 'controlPersonPriority',
        name: 'controlPersonPriority',
        label: 'Control Person',
        type: 'radio',
        fieldType: 'radio',
        fields: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ],
        ...(isControlOwner && isElevatedRisk && { disabled: true, initialValue: true })
      },
      guarantorPriority: {
        id: 'guarantorPriority',
        name: 'guarantorPriority',
        label: 'Guarantor',
        type: 'radio',
        fieldType: 'radio',
        fields: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ],
        ...(isControlOwner && isElevatedRisk && { disabled: true, initialValue: true })
      },
      pullCreditReportPriority: {
        id: 'pullCreditReportPriority',
        name: 'pullCreditReportPriority',
        label: 'Pull Credit Report',
        type: 'radio',
        fieldType: 'radio',
        fields: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ],
        ...(isElevatedRisk && { disabled: true, initialValue: true })
      },
      usCitizenPriority: {
        id: 'usCitizenPriority',
        name: 'usCitizenPriority',
        label: 'US Citizen',
        type: 'radio',
        fieldType: 'radio',
        fields: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ]
      },
      countryPriority: {
        id: 'countryPriority',
        label: 'Country',
        type: 'text',
        fieldType: 'input'
      },
      govtIssuedIdPriorityHeader: {
        id: 'govtIssuedIdPriorityHeader',
        fieldType: 'formSectionHeader',
        title: 'Government Issued ID',
        isNestedSubSection: true
      },
      idTypePriority: {
        id: 'idTypePriority',
        label: 'ID Type',
        type: 'text',
        fieldType: 'combobox',
        useBlockForm: true,
        list: [
          { title: 'Drivers License', value: 'drivers_license' },
          { title: 'Photo ID', value: 'photo_id' },
          { title: 'Passport', value: 'passport' },
          { title: 'Resident Alien', value: 'resident_alien' }
        ]
      },
      stateIssuedPriority: {
        ...sharedFormFields.state,
        useBlockForm: true,
        id: 'stateIssuedPriority',
        label: 'State Issued'
      },
      idNumberPriority: {
        id: 'idNumberPriority',
        label: 'ID Number',
        type: 'text',
        fieldType: 'input'
      }
    })
  };
};

export const addressFormFields = {
  addressLine1: {
    id: 'addressLine1',
    label: 'Address Line 1',
    type: 'text',
    fieldType: 'input'
  },
  addressLine2: {
    id: 'addressLine2',
    label: 'Address Line 2',
    type: 'text',
    fieldType: 'input',
    required: false
  },
  city: {
    ...baseFormFields.city,
    required: true
  },
  state: {
    ...baseFormFields.state,
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    required: true
  },
  postalCode: {
    ...baseFormFields.zip,
    id: 'postalCode',
    required: true
  },
  countryComboBox: {
    // ComboBox
    id: 'country',
    label: 'Country',
    type: 'text',
    fieldType: 'combobox',
    initialValue: 'US',
    list: countryCodes.filter((item) => ['us', 'ca', 'mx'].includes(ignoreCase(item.value))),
    errorMessage: 'Must be a valid country code (US, CA, MX)',
    required: false
  },
  country: {
    // Input
    id: 'country',
    label: 'Country',
    type: 'text',
    fieldType: 'input',
    value: 'US',
    valid: true, // Must be set so FormAssistant registers default value as valid
    customValidation: (value) => !isEmpty(value) && ['us', 'ca', 'mx'].includes(ignoreCase(value)),
    errorMessage: 'Must be a valid country code (US, CA, MX)',
    required: false
  }
};

// Call formDivDivider to render a div between fields when we need to divide sections in a form
export const formDivDivider = () => ({
  [`formDivDivider_${Math.random()}`]: {
    fieldType: 'div',
    id: `formDivDivider_${Math.random()}`,
    style: {
      height: '0',
      flex: '100%'
    }
  }
});
