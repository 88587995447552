import { merchantGuids, partnerGuids, relationshipGuids } from '../guids';

export const mockRelationshipForestGet = [
  {
    relationshipId: 'RelationshipIdType1_v2',
    relationshipName: 'eaaNeyyxYi',
    relationshipCode: null,
    parentRelation: null,
    merchantGuidToDba: {
      'dc098dea-3230-4325-9f2a-a14c8d0b6e17': '634'
    },
    childPartner: 'fad980ab-d562-44cf-b142-9e0d0f2716f5',
    payingChild: false,
    showToChild: false,
    downlines: [
      {
        relationshipId: '6265d4b8-f259-4425-bfe2-7d26a35283b4',
        relationshipName: 'ufiHqFshpF',
        relationshipCode: null,
        parentRelation: 'RelationshipIdType1_v2',
        merchantGuidToDba: {
          '4b521a9b-d299-4d2c-bc4f-7a901d6439af': '820'
        },
        childPartner: partnerGuids.partner02,
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: null,
        processorName: null,
        repaySortCode: null
      }
    ],
    bankName: null,
    processorName: null,
    repaySortCode: null,
    inactive: false
  },
  {
    relationshipId: relationshipGuids.default,
    relationshipName: 'Relationship with Transaction Report Data',
    relationshipCode: null,
    parentRelation: null,
    merchantGuidToDba: {
      [merchantGuids.default]: 'Merchant with Transaction Report Data'
    },
    childPartner: partnerGuids.default,
    payingChild: false,
    showToChild: false,
    downlines: [],
    bankName: 'CYNERGY',
    processorName: 'FIRST_DATA',
    repaySortCode: null,
    inactive: false
  },
  {
    relationshipId: '96be2bda-7249-46af-88db-c4d7822aef8c',
    relationshipName: '0CsKQFgXSYEb4XH',
    relationshipCode: '814532',
    parentRelation: null,
    merchantGuidToDba: {
      GuidType_sqlTest_merchant: 'ZL2xIwX4Du9R9Bl'
    },
    childPartner: partnerGuids.partner04,
    payingChild: false,
    showToChild: false,
    downlines: [],
    bankName: 'MVB',
    processorName: 'REPAY',
    repaySortCode: 'G0012001001001',
    inactive: false
  },
  {
    relationshipId: relationshipGuids.relationship12,
    relationshipName: 'Mock Netevia Evolve Relationship',
    relationshipCode: '20011',
    parentRelation: null,
    merchantGuidToDba: {
      [merchantGuids.merchant05]: 'Mock Netevia Merchant elevated'
    },
    childPartner: partnerGuids.partner04,
    payingChild: false,
    showToChild: false,
    downlines: [],
    bankName: 'EVOLVE',
    processorName: 'NETEVIA',
    repaySortCode: null,
    inactive: false
  },
  {},
  {
    relationshipId: relationshipGuids.relationship23,
    relationshipName: 'FE - Priority / Axiom / Elevated',
    relationshipCode: '244201',
    parentRelation: null,
    merchantGuidToDba: {
      [merchantGuids.merchant06]: 'mock-priority-axiom-elevated-merchant'
    },
    childPartner: partnerGuids.partner08,
    payingChild: false,
    showToChild: false,
    downlines: [],
    bankName: 'AXIOM',
    processorName: 'PRIORITY',
    repaySortCode: null,
    inactive: false
  },
  {},
  {
    relationshipId: relationshipGuids.relationship24,
    relationshipName: 'FE - Priority / Wells Fargo / Elevated',
    relationshipCode: '232426',
    parentRelation: null,
    merchantGuidToDba: {
      [merchantGuids.merchant07]: 'mock-priority-wells-fargo-elevated-merchant'
    },
    childPartner: partnerGuids.partner08,
    payingChild: false,
    showToChild: false,
    downlines: [],
    bankName: 'WELLS_FARGO',
    processorName: 'PRIORITY',
    repaySortCode: null,
    inactive: false
  },
  {
    relationshipId: 'ft_createRelationship_rid_2',
    relationshipName: 'VLtC1vgFn7OpZFR',
    relationshipCode: null,
    parentRelation: null,
    merchantGuidToDba: {},
    childPartner: 'ft_createRelationship_ppid_2',
    payingChild: false,
    showToChild: false,
    downlines: [
      {
        relationshipId: '6179b932-db4a-4ffe-8727-079cb6b9395d',
        relationshipName: 'CrmCreateRelationshipFT',
        relationshipCode: '121729',
        parentRelation: 'ft_createRelationship_rid_2',
        merchantGuidToDba: {},
        childPartner: 'ft_createRelationship_pid_2',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: 'CROSS_RIVER',
        processorName: 'PAYROC',
        repaySortCode: null
      }
    ],
    bankName: 'CROSS_RIVER',
    processorName: 'PAYROC',
    repaySortCode: null,
    inactive: false
  },
  {
    relationshipId: 'FT_CDD_VALID_PARTNER',
    relationshipName: 'w9Y6BtpO5p5C2bW',
    relationshipCode: null,
    parentRelation: null,
    merchantGuidToDba: {
      'f3dd1a71-56e8-4dba-8f2a-0c9b3755a6fe': 'not_null_dba',
      '6a32f03b-0582-473e-9cca-0ac83c47c200': 'not_null_dba'
    },
    childPartner: 'b64113c9-d4e4-48f2-a359-04707a22419e',
    payingChild: false,
    showToChild: false,
    downlines: [
      {
        relationshipId: 'fd84b7a8-408f-40e4-b996-47803896681c',
        relationshipName: 'oSCtr5wnhFjkZbz',
        relationshipCode: null,
        parentRelation: 'FT_CDD_VALID_PARTNER',
        merchantGuidToDba: {},
        childPartner: 'bebf50f8-411b-4395-ab23-e0756b8b0b67',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: 'CYNERGY',
        processorName: 'FIRST_DATA',
        repaySortCode: null
      }
    ],
    bankName: 'CYNERGY',
    processorName: 'FIRST_DATA',
    repaySortCode: null,
    inactive: false
  },
  {
    relationshipId: 'c7f99ba1-ab36-4d0a-a638-ea80e0564d84',
    relationshipName: 'CTUH_ADD_2',
    relationshipCode: null,
    parentRelation: null,
    merchantGuidToDba: {
      '66c9c005-e67e-4b66-8c74-33470250ab1b': 'CTUH_ADD_2_opened-6',
      '16360978-715f-4e85-bd35-22c3539600b2': 'CTUH_ADD_2_closed-1',
      '51f94b3e-ddf1-46bc-a576-2c4dc736a2cb': 'CTUH_ADD_2_closed-3',
      'f0c4a921-5dd3-4b95-adca-92f7852db0df': 'CTUH_ADD_2_closed-4',
      '18a2a63d-1742-4799-8ef6-4bba1f65d6c1': 'CTUH_ADD_2_TPPS_txnBarGraph',
      '10a8005a-dcb6-474f-99f0-2dce6f69d6f0': 'CTUH_ADD_2_opened-2',
      'a46f0195-3be1-4445-953f-1c6b78d120d8': 'CTUH_ADD_2_closed-6',
      'f680866d-8393-471c-ae7f-98284b60ab66': 'CTUH_ADD_2_closed-2',
      'e18291cf-759e-4e42-8a65-6c3945683320': 'CTUH_ADD_2_closed-4',
      '3b103038-7a07-498a-9a7f-9c528ffc6797': 'CTUH_ADD_2_TPPS_txnBarGraph',
      '4fc8f6da-06e9-47d7-b7e5-63cf754f209a': 'CTUH_ADD_2_opened-4',
      '2bfc6521-3f0b-4e46-ab1f-bd193005bd54': 'CTUH_ADD_2_shutdown',
      '98c1b001-598c-4364-a9e7-8e09f6513b22': 'CTUH_ADD_2_opened-4',
      'c3edde32-3696-4894-814b-43f7baadf40e': 'CTUH_ADD_2_closed-1',
      'f6d29a7b-0618-486b-97d4-1a7a3eb8a4bb': 'CTUH_ADD_2_opened-3',
      'ffe6add5-aa9d-4257-8513-aeb45173ef8d': 'CTUH_ADD_2_opened-6',
      '1f0b2083-8c66-4574-bdb0-b25a19712fdc': 'CTUH_ADD_2_TPPS_income',
      'e04b4105-eedf-4a0f-8e01-bca76a40fa8c': 'CTUH_ADD_2_opened-0',
      'd229718d-9960-4e53-b0dd-3e4cf4c1c0df': 'CTUH_ADD_2_opened-1',
      'b2164c74-662f-4e92-8a69-a7046e04bb1f': 'CTUH_ADD_2_shutdown',
      'cef60ea9-d046-4336-a21f-54e4e4a47721': 'CTUH_ADD_2_testTxn',
      '13370db1-fee4-4b68-95a2-ff8de8f17d7b': 'CTUH_ADD_2_stopped',
      '6b2ca5c6-e76d-4eb5-82b2-ff664ee0e209': 'CTUH_ADD_2_closed-5',
      '26d44ab3-f393-4ef9-a9ed-d7927a5a177f': 'CTUH_ADD_2_closed-5',
      '105c2c01-e537-4608-872f-37666fc4f42a': 'CTUH_ADD_2_closed-3',
      'b4b4a37f-5eb6-44ee-b73f-5b519ecc40c3': 'CTUH_ADD_2_opened-3',
      '492ca204-76c2-424b-9f51-5c491f0c38a6': 'CTUH_ADD_2_opened-5',
      '2f158d23-0730-4b13-bfed-ec4737ba8170': 'CTUH_ADD_2_closed-2',
      'c7e33651-4c24-4e4e-bc8b-a4c1fa977da1': 'CTUH_ADD_2_testTxn',
      '1eaf6d1d-7661-4c1b-94cf-65da03010589': 'CTUH_ADD_2_stopped',
      '822bbb27-9671-4b6a-8040-5b9788075249': 'CTUH_ADD_2_closed-0',
      '726fad40-e3e4-43cf-b16d-f6012620e23a': 'CTUH_ADD_2_TPPS_income',
      'ab6bc83b-f107-4eda-a686-9efccbd6f780': 'fB31ksdPB3CEkBe',
      '05c30d6b-1eaa-4dea-8d28-73ac6cbcf285': 'CTUH_ADD_2_opened-5',
      '041417d0-1b83-4a02-823b-f786944a5fae': 'CTUH_ADD_2_noStart',
      'b0d42870-41d7-476a-9ee5-b631c3e55f2f': 'CTUH_ADD_2_closed-0',
      '566dc791-c69d-451e-bd4b-e661d5dde29d': 'CTUH_ADD_2_opened-0',
      '3c1c45c3-992d-46cb-96b9-aa64106fb3ca': 'CTUH_ADD_2_opened-2',
      'd70bf380-3a4a-4622-9670-5f3a6cf81c49': 'CTUH_ADD_2_opened-1',
      '47f2780f-a4aa-4e11-9d32-2224ad5abe30': 'CTUH_ADD_2_noStart',
      'b1d09bfb-06cb-46e3-9937-11fe14f0e7d6': 'CTUH_ADD_2_closed-6'
    },
    childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
    payingChild: false,
    showToChild: false,
    downlines: [
      {
        relationshipId: 'cb6034d6-52a8-41ab-a3f8-1ef6cfb5c3ff',
        relationshipName: 'TPPS_leaderboard',
        relationshipCode: null,
        parentRelation: 'c7f99ba1-ab36-4d0a-a638-ea80e0564d84',
        merchantGuidToDba: {
          'e83814eb-9c0c-43f3-95ea-60461732ff28': 'TPPS_leaderboard_mid'
        },
        childPartner: '8d400d25-6fee-402a-8d55-f4dc5eb0b3a1',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: 'CYNERGY',
        processorName: 'FIRST_DATA',
        repaySortCode: null
      },
      {
        relationshipId: '3fc9a5e6-e256-4a27-8597-4da37683bfbc',
        relationshipName: 'TPPS_leaderboard',
        relationshipCode: null,
        parentRelation: 'c7f99ba1-ab36-4d0a-a638-ea80e0564d84',
        merchantGuidToDba: {
          '526b2463-7458-4d5c-9a56-c97375d48d9c': 'TPPS_leaderboard_mid'
        },
        childPartner: '827126b7-07cd-498e-97f7-5bd4c253523b',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: 'CYNERGY',
        processorName: 'FIRST_DATA',
        repaySortCode: null
      }
    ],
    bankName: 'CYNERGY',
    processorName: 'FIRST_DATA',
    repaySortCode: null,
    inactive: false
  },
  {
    relationshipId: 'RelationshipIdType3_v2',
    relationshipName: 'DcflaovtlK',
    relationshipCode: null,
    parentRelation: null,
    merchantGuidToDba: {
      'a6999b79-0395-47b3-867a-37b47b19d5cd': '241'
    },
    childPartner: 'd9c2cc01-5631-4cda-8e67-286f169c06eb',
    payingChild: false,
    showToChild: false,
    downlines: [
      {
        relationshipId: '1211ff58-c3a2-474b-b485-82f267c0d79d',
        relationshipName: 'zjCrGRMexP',
        relationshipCode: null,
        parentRelation: 'RelationshipIdType3_v2',
        merchantGuidToDba: {
          'e8f0138a-8dcf-42c5-ac68-7e7ccea121fb': '527'
        },
        childPartner: 'f772343e-848c-4fc1-89ea-309f1d54b503',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: null,
        processorName: null,
        repaySortCode: null
      },
      {
        relationshipId: '02b25750-9da4-4f50-8a0b-010d44739774',
        relationshipName: 'tROKylCWzj',
        relationshipCode: null,
        parentRelation: 'RelationshipIdType3_v2',
        merchantGuidToDba: {
          '33d51814-e770-44f6-bf94-af97ea7374ca': '553'
        },
        childPartner: '1cc3b644-a821-4413-ac8a-c5b81ad9831a',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: null,
        processorName: null,
        repaySortCode: null
      }
    ],
    bankName: null,
    processorName: null,
    repaySortCode: null,
    inactive: false
  },
  {
    relationshipId: '72acd17c-3e31-4983-81bd-422fa0877829',
    relationshipName: 'QTLUCTbzOIIE89U',
    relationshipCode: null,
    parentRelation: null,
    merchantGuidToDba: {
      'b3f28dc3-3e7b-4a4d-aba0-967d059e478e': 'yHLizO0ElDFZvdB',
      '674ec0ae-8bab-4ad9-a650-dd56cdec4918': 'tZYhxZZ3A9jLRT8',
      'a8c0532a-954b-4c68-9dbf-d5e7759dc8c7': 'ppj6eVzDiOGqUs2'
    },
    childPartner: 'd8d9dd72-15ee-45bb-bbd7-bd4d657021a0',
    payingChild: false,
    showToChild: false,
    downlines: [
      {
        relationshipId: '72ad8f35-48f2-4e12-a49c-c66d78714621',
        relationshipName: 'Gh39jjh6JdZBifo',
        relationshipCode: null,
        parentRelation: '72acd17c-3e31-4983-81bd-422fa0877829',
        merchantGuidToDba: {
          'cd1382ec-1341-45c3-8e9e-1173cd794406': 'ohBDo4OVRKhSu6L',
          '7ff70bfb-e0c0-4871-8ced-4bc4083578c5': 'kWGCTxBNFef7S4a'
        },
        childPartner: '5f686336-1fc3-4e0a-9e89-43ef3e86170b',
        payingChild: false,
        showToChild: false,
        downlines: [
          {
            relationshipId: '64e7c4b9-525e-4a96-8f3a-edf48c773be4',
            relationshipName: 'tlkVFkfA4BLARQs',
            relationshipCode: null,
            parentRelation: '72ad8f35-48f2-4e12-a49c-c66d78714621',
            merchantGuidToDba: {
              '3be287ca-adaa-4151-bb50-547df0298648': 'EU6cex5b8xxPp4a'
            },
            childPartner: 'edea460a-9474-46ef-9688-407afa0f0b33',
            payingChild: false,
            showToChild: false,
            downlines: [
              {
                relationshipId: '6a65d3d7-fce7-4cf7-9e05-17482db7f671',
                relationshipName: '9DDq7UKv7fwViS2',
                relationshipCode: null,
                parentRelation: '64e7c4b9-525e-4a96-8f3a-edf48c773be4',
                merchantGuidToDba: {
                  '280836b2-df29-4a21-87c6-21e8f5cb1f5f': 'ryHXdi4gr6BMyYb'
                },
                childPartner: '65f9899c-24c6-45da-a414-10dd2ef18648',
                payingChild: false,
                showToChild: false,
                downlines: [
                  {
                    relationshipId: '40b3d6f1-af1f-4f1e-b2a0-972358898184',
                    relationshipName: 'OGfbRjTO5yw1mqf',
                    relationshipCode: null,
                    parentRelation: '6a65d3d7-fce7-4cf7-9e05-17482db7f671',
                    merchantGuidToDba: {
                      '3d45d0e7-ceae-4fc5-bff1-44206680074e': 'eyfaFUifToHe2vQ'
                    },
                    childPartner: 'c81f5e62-afc2-44e3-9b7f-0ce5530c72c9',
                    payingChild: false,
                    showToChild: false,
                    downlines: [
                      {
                        relationshipId: 'd46f3315-dc76-4b3b-9b2d-592bd5b1e840',
                        relationshipName: 'dLqQvBrU1oBjZYr',
                        relationshipCode: null,
                        parentRelation: '40b3d6f1-af1f-4f1e-b2a0-972358898184',
                        merchantGuidToDba: {
                          'a00d7e9a-abb8-4b4e-9594-b20090b48844': 'wVkTz9xTYT5LpOc'
                        },
                        childPartner: 'c78e141f-e280-4f3c-a089-2b9f80c9f9c8',
                        payingChild: false,
                        showToChild: false,
                        downlines: [],
                        bankName: 'CYNERGY',
                        processorName: 'FIRST_DATA',
                        repaySortCode: null
                      }
                    ],
                    bankName: 'CYNERGY',
                    processorName: 'FIRST_DATA',
                    repaySortCode: null
                  }
                ],
                bankName: 'CYNERGY',
                processorName: 'FIRST_DATA',
                repaySortCode: null
              }
            ],
            bankName: 'CYNERGY',
            processorName: 'FIRST_DATA',
            repaySortCode: null
          }
        ],
        bankName: 'CYNERGY',
        processorName: 'FIRST_DATA',
        repaySortCode: null
      }
    ],
    bankName: 'CYNERGY',
    processorName: 'FIRST_DATA',
    repaySortCode: null,
    inactive: false
  }
];

export default mockRelationshipForestGet;
