import { mockFileAttachToResourcePut } from '../mockFileAttachToResourcePut';
import { domainName, transformData } from '../../_helpers';
import lessonPlanTemplate from './templates/lessonPlanTemplate';

export const lessonPlanGuids = {
  default: 'lp000000-0000-0000-0000-000000000000',
  lessonPlan01: 'lp000000-0000-0000-0000-000000000001',
  lessonPlan02: 'lp000000-0000-0000-0000-000000000002',
  lessonPlan03: 'lp000000-0000-0000-0000-000000000003',
  lessonPlan04: 'lp000000-0000-0000-0000-000000000004',
  lessonPlan05: 'lp000000-0000-0000-0000-000000000005',
  lessonPlan06: 'lp000000-0000-0000-0000-000000000006',
  lessonPlan07: 'lp000000-0000-0000-0000-000000000007',
  lessonPlan08: 'lp000000-0000-0000-0000-000000000008',
  lessonPlan09: 'lp000000-0000-0000-0000-000000000009',
  lessonPlan10: 'lp000000-0000-0000-0000-000000000010'
};

export const mockLessonPlanGet = {
  lessonPlans: [
    {
      lessonPlanId: lessonPlanGuids.default,
      name: 'Default Lesson Plan',
      description: 'A lesson plan for all!',
      published: true,
      permissions: {
        audience: 'COMMON',
        group: 'COMMON'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://d6u22qyv3ngwz.cloudfront.net/ad/wmTX/zte-usa-axon-m-business-cat-small-2.jpg'
      },
      videos: {
        files: [
          {
            fileName:
              'videoDefault_but_with_a_long_name_because_I_Felt_Like_It-and-you-know-someone-will-do-it.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault01.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault02.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault03.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault04.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault05.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault06.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault07.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault08.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          },
          {
            fileName: 'videoDefault09.mp4',
            relativePath: '/lessonPlans/default',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'defaultLessonPlan.pdf',
            relativePath: '/lessonPlans/default',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'defaultLessonPlanWorksheet.pdf',
            relativePath: '/lessonPlans/default',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'defaultLessonPlanImage.jpg',
            relativePath: '/lessonPlans/default',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan01,
      name: 'Lesson Plan 01',
      description: 'A lesson plan for only internal employees',
      published: true,
      permissions: {
        audience: 'internal',
        group: 'COMMON'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: null
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan01',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan01',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan01.pdf',
            relativePath: '/lessonPlans/lessonPlan01',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet01.pdf',
            relativePath: '/lessonPlans/lessonPlan01',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan02,
      name: 'Lesson Plan 02',
      description: 'A lesson plan for all internal employees',
      published: true,
      permissions: {
        audience: 'internal',
        group: 'COMMON'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/1600x1900/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan02',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan02',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan02.pdf',
            relativePath: '/lessonPlans/lessonPlan02',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet02.pdf',
            relativePath: '/lessonPlans/lessonPlan02',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan03,
      name: 'Lesson Plan 03',
      description: 'A lesson plan for only risk internal employees',
      published: true,
      permissions: {
        audience: 'internal',
        group: 'RISK'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/1900x1600/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan03',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan03',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan03.pdf',
            relativePath: '/lessonPlans/lessonPlan03',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet03.pdf',
            relativePath: '/lessonPlans/lessonPlan03',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan04,
      name: 'Lesson Plan 04',
      description: 'A lesson plan for only operations internal employees',
      published: true,
      permissions: {
        audience: 'internal',
        group: 'OPERATIONS'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/1920x1080/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan04',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan04',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan04.pdf',
            relativePath: '/lessonPlans/lessonPlan04',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet04.pdf',
            relativePath: '/lessonPlans/lessonPlan04',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan05,
      name: 'Lesson Plan 05',
      description: 'A lesson plan for only operations legacy internal employees',
      published: true,
      permissions: {
        audience: 'internal',
        group: 'OPERATIONS_LEGACY'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/180x180/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan05',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan05',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan05.pdf',
            relativePath: '/lessonPlans/lessonPlan05',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet05.pdf',
            relativePath: '/lessonPlans/lessonPlan05',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan06,
      name: 'Lesson Plan 06',
      description: 'A lesson plan for only operations internal employees, not published',
      published: false,
      permissions: {
        audience: 'internal',
        group: 'OPERATIONS'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/500x1600/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan06',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan06',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan06.pdf',
            relativePath: '/lessonPlans/lessonPlan06',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet06.pdf',
            relativePath: '/lessonPlans/lessonPlan06',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan07,
      name: 'Lesson Plan 07',
      description: 'A lesson plan for only compliance internal employees, not published',
      published: false,
      permissions: {
        audience: 'internal',
        group: 'COMPLIANCE'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/1800x500/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan07',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan07',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan07.pdf',
            relativePath: '/lessonPlans/lessonPlan07',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet07.pdf',
            relativePath: '/lessonPlans/lessonPlan07',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan08,
      name: 'Lesson Plan 08',
      description: 'A lesson plan for external merchant employees',
      published: true,
      permissions: {
        audience: 'external',
        group: 'merchant'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/1800x1600/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan08',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan08',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan08.pdf',
            relativePath: '/lessonPlans/lessonPlan08',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet08.pdf',
            relativePath: '/lessonPlans/lessonPlan08',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan09,
      name: 'Lesson Plan 09',
      description: 'A lesson plan for external partner employees',
      published: true,
      permissions: {
        audience: 'external',
        group: 'partner'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/180x160/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan09',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan09',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: [
          {
            fileName: 'lessonPlan09.pdf',
            relativePath: '/lessonPlans/lessonPlan09',
            url: `http://local.${domainName}.com:3000`
          },
          {
            fileName: 'lessonPlanWorksheet09.pdf',
            relativePath: '/lessonPlans/lessonPlan09',
            url: `http://local.${domainName}.com:3000`
          }
        ]
      }
    },
    {
      lessonPlanId: lessonPlanGuids.lessonPlan10,
      name: 'Lesson Plan 10',
      description: 'A lesson plan for external partner employees, not published',
      published: false,
      permissions: {
        audience: 'external',
        group: 'partner'
      },
      thumbnail: {
        fileName: 'thumbnail.png',
        relativePath: '/thumbnail',
        url: 'https://via.placeholder.com/180x160/01AF66/FFF?text=Thumbnail'
      },
      videos: {
        files: [
          {
            fileName: 'video01.mp4',
            relativePath: '/lessonPlans/lessonPlan10',
            url: `/video_file.mp4`
          },
          {
            fileName: 'video02.mp4',
            relativePath: '/lessonPlans/lessonPlan10',
            url: `/video_file.mp4`
          }
        ]
      },
      trainingFiles: {
        files: []
      }
    }
  ]
};

const formatGetData = (data) =>
  transformData({
    data,
    toSchema: 'get',
    template: lessonPlanTemplate,
    version: '1.0'
  });

export const formattedLessonPlanGet = formatGetData(mockLessonPlanGet);

export const mockLessonPlanPUT = {
  lessonPlan: {
    name: mockLessonPlanGet.lessonPlans[0].name,
    published: mockLessonPlanGet.lessonPlans[0].published,
    permissions: {
      audience: mockLessonPlanGet.lessonPlans[0].permissions.audience,
      group: mockLessonPlanGet.lessonPlans[0].permissions.group
    },
    description: mockLessonPlanGet.lessonPlans[0].description
  }
};

export const mockLessonPlanPUTRes = {
  lessonPlanId: lessonPlanGuids.default
};
// just returns the lessonPlanId

export const mockLessonPlanPOST = {
  [lessonPlanGuids.default]: {
    lessonPlan: {
      name: mockLessonPlanGet.lessonPlans[0].name,
      published: mockLessonPlanGet.lessonPlans[0].published,
      permissions: {
        audience: mockLessonPlanGet.lessonPlans[0].permissions.audience,
        group: mockLessonPlanGet.lessonPlans[0].permissions.group
      },
      description: mockLessonPlanGet.lessonPlans[0].description
    }
  }
};

export const mockLessonPlanPOSTRes = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: {}
  };
};
// POST just returns 204

export const mockLessonPlanAttachToResource = {
  thumbnail: [
    {
      name: 'testThumbnail.jpg',
      path: '/COMMON/',
      customPath: ''
    }
  ],
  videos: {
    files: mockLessonPlanGet.lessonPlans[0].videos.files
  },
  trainingFiles: {
    files: mockLessonPlanGet.lessonPlans[0].trainingFiles.files
  }
};

export const mockLessonPlanAttachToResourceFormatted = transformData({
  data: mockLessonPlanAttachToResource,
  toSchema: 'attachToResource',
  template: lessonPlanTemplate,
  version: '1.0'
});

export const mockLessonPlanAttachToResourceRes = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: {
      thumbnail: mockFileAttachToResourcePut(body, params, options)[guid].files[0],
      videos: {
        files: mockFileAttachToResourcePut(body, params, options)[guid].files
      },
      trainingFiles: {
        files: mockFileAttachToResourcePut(body, params, options)[guid].files
      }
    }
  };
};

export default mockLessonPlanGet;
