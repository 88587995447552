import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { getType, isBool, isEmpty, isEqual } from './_helpers';
import DragAndDrop from './DragAndDrop';
import { FormDragAndDropRankedListItem, NoData } from '../index';
import { ErrorBox, RequiredStar } from '../css/_styledFormComponents';
import { StyledDragAndDropRankedList } from '../css/_styledComponents';

export const FormDragAndDropRankedList = ({
  allowDuplicates = false,
  boxStyle = null,
  callback = null,
  disabled = false,
  list = [],
  id = null,
  label = null,
  required = false,
  value = null, // list of existing data values
  wrapperStyle = null
}) => {
  const didMount = useRef(false);
  const wrapperId = uuidv4();
  const elemId = uuidv4();
  const [currentValues, setValues] = useState(null);
  const [formattedList, setList] = useState(null);
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    !isEmpty(list) && createFormattedList(list, { initialize: true });
  }, []);

  useEffect(() => {
    if (didMount.current && getType(formattedList) === 'array') {
      handleCallback();
    } else {
      didMount.current = true;
    }
  }, [currentValues]);

  const createFormattedList = (startingList, options) => {
    const { initialize } = options || {};
    const newFormattedList = !isEmpty(startingList)
      ? startingList.map((item) => ({
          ...item,
          ...((initialize || typeof item.uniqueId === 'undefined') && {
            ...(initialize &&
              !isEmpty(value) &&
              value.includes(item.initialValue) && {
                initialValue: item.initialValue
              }),
            uniqueId: uuidv4(), // Internal id to keep track of item's position/updates
            itemIsValid: true // assumes all items valid on load
          })
        }))
      : [];
    setList(newFormattedList);
    const newValues = newFormattedList.map((item) => item.initialValue);
    setValues(newValues);
  };

  const handleChangeItem = (options) => {
    const { itemIndex, updatedValue, updatedIsValid } = options || {};
    if (!isEqual(updatedValue, currentValues[itemIndex])) {
      const newList = [...formattedList];
      const newItem = {
        ...newList[itemIndex],
        initialValue: updatedValue,
        itemIsValid: updatedIsValid
      };
      newList[itemIndex] = newItem;
      setList(newList);
      const newValues = newList.map((item) => item.initialValue);
      setValues(newValues);
    }
  };

  const handleCallback = () => {
    if (callback) {
      const nonEmptyValues = !isEmpty(currentValues)
        ? currentValues.filter((v) => !isEmpty(v) && !isBool(v))
        : [];
      const hasDupes = allowDuplicates
        ? false
        : !isEqual([...new Set([...nonEmptyValues])].length, nonEmptyValues.length);
      const newIsValid = formattedList.every((item) => item.itemIsValid) && !hasDupes;
      setIsValid(newIsValid);
      const cbOptions = { value: currentValues, id, valid: newIsValid };
      callback(cbOptions);
    }
  };

  return (
    <StyledDragAndDropRankedList
      id={wrapperId}
      $boxStyle={boxStyle}
      disabled={disabled}
      className={
        disabled
          ? []
          : [
              ...(isValid ? ['list-valid'] : []),
              ...(!isValid && (required || !isEmpty(currentValues)) ? ['list-invalid'] : [])
            ].join(' ')
      }
      role="article"
      aria-label="Drag and drop list"
      style={wrapperStyle}>
      {label && (
        <div className="component-label">
          {required && <RequiredStar />}
          <label htmlFor={elemId}>{label}</label>
        </div>
      )}
      {getType(formattedList) === 'array' ? (
        <>
          <DragAndDrop
            id={elemId}
            callback={createFormattedList}
            currentList={formattedList}
            disabled={disabled}
            useHandle>
            {formattedList.map((item, itemIndex) => (
              <FormDragAndDropRankedListItem
                key={item.uniqueId}
                boxStyle={boxStyle}
                callback={handleChangeItem}
                disabled={disabled}
                required={required}
                item={item}
                rankedItemIndex={itemIndex}
              />
            ))}
          </DragAndDrop>
          <ErrorBox
            className="errors"
            id={`${id}-error`}
            $error={isBool(isValid) && !isValid} // so error doesn't appear before list loads
            $boxStyle={boxStyle}>
            {isBool(isValid) && !isValid
              ? `All entries must be valid${!allowDuplicates ? ', with no duplicates' : ''}`
              : null}
          </ErrorBox>
        </>
      ) : (
        <NoData customMessage="No list found." />
      )}
    </StyledDragAndDropRankedList>
  );
};

FormDragAndDropRankedList.propTypes = {
  allowDuplicates: PropTypes.bool,
  boxStyle: PropTypes.string,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      componentType: PropTypes.string,
      props: PropTypes.shape({
        fieldType: PropTypes.string
      })
    })
  ),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object])
};

export default FormDragAndDropRankedList;
