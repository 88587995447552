// TODO: BIRB-8338
/* istanbul ignore file */
import * as d3 from 'd3';
import React from 'react';
import { PropTypes } from 'prop-types';
import { isEmpty } from '../_helpers';
import { ToolTip } from '../ToolTip';
import { Spinner } from '../Spinner';

class D3PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      dataExists: false,
      spinnerLoading: false,
      initWidth: 400,
      initHeight: 200,
      baseFontSize: 14,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      hovered: {
        type: '',
        data: null
      },
      tooltipStyle: {
        x: 0,
        y: 0
      },
      outerRadius: 0,
      arc: null
    };
    this.element = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.updateState({ spinnerLoading: true });
    setTimeout(() => {
      this.createD3PieChart();
    }, 3000); // to allow axes to render
  }

  componentDidUpdate(prevProps) {
    const { chartData } = this.props;
    if (
      !isEmpty(prevProps.chartData) &&
      !isEmpty(chartData) &&
      JSON.stringify(chartData) !== JSON.stringify(prevProps.chartData)
    ) {
      this.updateState({ spinnerLoading: true });
      this.createD3PieChart();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  handleResize = () => {
    const { current: currentElement } = this.element;
    if (currentElement) {
      const { initHeight, initWidth } = this.state;
      const aspect = initWidth / initHeight;
      const svg = d3.select(currentElement);
      const targetWidth = parseInt(currentElement.offsetWidth || 0, 10);
      svg.attr('width', targetWidth);
      svg.attr('height', Math.round(targetWidth / aspect));
      this.updateState({
        width: targetWidth,
        height: parseInt(currentElement.offsetHeight || 0, 10)
      });
    }
  };

  setFontSize = () => {
    // method to keep font size the same as it scales
    const { baseFontSize } = this.state;
    const { current: currentElement } = this.element;
    const newFontSize = baseFontSize * (375 / currentElement.offsetWidth || 0);
    currentElement &&
      d3.select(currentElement).selectAll('.tick text').style('font-size', `${newFontSize}px`);
  };

  handleMouseMove = (e) => {
    const { tooltipStyle } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        x: e.clientX,
        y: e.clientY
      }
    });
  };

  arcTween = (elem, oRadius, delay) => {
    d3.select(elem)
      .transition()
      .duration(150)
      .ease(d3.easeSinOut)
      .delay(delay)
      .attrTween('d', this.tween);
  };

  tween = (d) => {
    const { arc, outerRadius } = this.state;
    const dCopy = d;
    const to = dCopy.outerRadius >= outerRadius ? outerRadius - 5 : outerRadius;
    const i = d3.interpolate(dCopy.outerRadius, to);
    return (t) => {
      dCopy.outerRadius = i(t);
      return arc(dCopy);
    };
  };

  handleMouseOver = (e, d) => {
    const { index } = d || {};
    const { current: currentElement } = this.element;
    const { outerRadius, tooltipStyle } = this.state;
    const svg = d3.select(currentElement);
    const current = d[index];
    const others = svg.selectAll('.arc').filter((el, i, n) => n[i] !== current);
    others.style('opacity', 0.6);
    this.arcTween(current, outerRadius, 0);
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        revealed: true
      },
      hovered: {
        type: 'pie',
        data: {
          ...d.data
        }
      }
    });
  };

  handleMouseOut = (e, d) => {
    const { index } = d || {};
    const { current: currentElement } = this.element;
    const { outerRadius } = this.state;
    const svg = d3.select(currentElement);
    const current = d[index];
    const others = svg.selectAll('.arc').filter((el, i, n) => n[i] !== current);
    others.style('opacity', 1);
    this.arcTween(current, outerRadius - 5, 150);
    this.updateState({
      hovered: {
        type: '',
        data: null
      }
    });
  };

  handleClick = (e, d) => {
    const { callback } = this.props;
    callback && callback(d.data);
    this.updateState({
      hovered: {
        type: '',
        data: null
      }
    });
  };

  createD3PieChart = () => {
    const { current: currentElement } = this.element;
    const { initWidth, initHeight, baseFontSize, margin } = this.state;
    const { options = {}, chartData = {} } = this.props;
    const {
      showTicks,
      tickContent = [],

      // d3 automatically sorts by value. pass false to unsort/view pie data by color
      sortByValue = true
    } = options || {};
    const { data = [] } = chartData;
    const dataExists = Array.isArray(data) && data.some((item) => item.value);
    if (currentElement !== null) {
      this.handleResize();
      const width = initWidth - margin.left - margin.right;
      const height = initHeight - margin.top - margin.bottom;
      const outerRadius = Math.min(width, height) / 2 - 10;
      const innerRadius = 0;
      this.updateState({ outerRadius });
      const svg = d3
        .select(currentElement)
        .html(null)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMinYMin')
        .append('g');

      const pie = sortByValue
        ? d3.pie().value((d) => d.value)
        : d3
            .pie()
            .value((d) => d.value)
            .sort(null);

      const arc = d3.arc().padRadius(outerRadius).innerRadius(innerRadius);
      this.updateState({ arc });
      const dataReady = pie(dataExists ? data : [{ name: 'no data', value: 100 }]);

      svg
        .selectAll('.arc')
        .data(dataReady)
        .enter()
        .append('path')
        .each((d) => {
          const dCopy = d;
          dCopy.outerRadius = outerRadius - 5;
        })
        .attr('class', 'arc')
        .attr('d', arc)
        .attr('fill', dataExists ? (d, i) => data[i].fill : 'var(--color-data-border)')
        .attr('stroke', 'white')
        .style('stroke-width', '2px')
        .on('mousemove', dataExists ? this.handleMouseMove : null)
        .on('mouseover', dataExists ? this.handleMouseOver : null)
        .on('mouseout', dataExists ? this.handleMouseOut : null)
        .on('click', dataExists ? this.handleClick : null);

      if (!dataExists) {
        svg
          .append('text')
          .attr('class', 'centerText')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .style('font-size', '2.5rem')
          .text('No Data');
      }

      if (showTicks) {
        const arcLabel = () => {
          const radius = (Math.min(width, height) / 2) * 0.8;
          return d3.arc().innerRadius(radius).outerRadius(radius);
        };
        svg
          .append('g')
          .attr('font-family', 'sans-serif')
          .style('font-size', `${baseFontSize}px`)
          .attr('text-anchor', 'middle')
          .selectAll('text')
          .data(dataReady)
          .join('text')
          .attr('class', 'tick')
          .attr('transform', (d) => `translate(${arcLabel().centroid(d)})`)
          .call(
            (text) =>
              tickContent.includes('label') &&
              text
                .append('tspan')
                .attr('y', '-0.4em')
                .attr('font-weight', 'bold')
                .text(/* istanbul ignore next */ (d) => d.data.name)
          )
          .call(
            (text) =>
              tickContent.includes('value') &&
              text
                .filter((d) => d.endAngle - d.startAngle > 0.25)
                .append('tspan')
                .attr('x', 0)
                .attr('y', '0.7em')
                .attr('fill-opacity', 0.7)
                .text(/* istanbul ignore next */ (d) => d.data.value.toLocaleString())
          );
      }
      d3.select(window).on(`resize.${currentElement}`, this.handleResize);
      this.setFontSize();
    }
    this.updateState({ spinnerLoading: false, dataExists });
  };

  render() {
    const { chartData } = this.props;
    const { dataExists, tooltipStyle, hovered, spinnerLoading } = this.state;
    return (
      <div
        data-testid="d3-pie-chart"
        style={{ width: '100%' }}
        className="d3wrapper"
        ref={this.element}>
        <Spinner loading={spinnerLoading} />
        {dataExists && !isEmpty(hovered.data) && (
          <ToolTip
            d3Data={hovered}
            d3Position={tooltipStyle}
            element={this.element.current}
            options={{ ...chartData.header, colorMap: chartData.colors }}
          />
        )}
      </div>
    );
  }
}

D3PieChart.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]),
  chartData: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func
};

D3PieChart.defaultProps = {
  options: {},
  chartData: {},
  callback: () => {}
};

export default D3PieChart;
