import { formDivDivider } from './_boardingToolFields';
import { sharedFormFields } from './_formFields';

const formProps = {
  inputText: {
    type: 'text',
    fieldType: 'input'
  },
  inputNumber: {
    type: 'number',
    fieldType: 'input'
  },
  inputRationalNumber: {
    type: 'rationalNumber',
    fieldType: 'input'
  },
  inputPercent: {
    type: 'percent',
    fieldType: 'input',
    isRatio: true,
    suffix: '%'
  },
  inputPrice: {
    type: 'price',
    fieldType: 'input',
    priceToNumber: true
  }
};

export const customValidations = {
  monthAndYearStarted: (value) => RegExp(/^\d{4}-?\d{2}/g).test(value)
};

export const prevetAppFormFields = {
  generalSection: {
    id: 'generalSection',
    prevetName: {
      ...formProps.inputText,
      required: true,
      id: 'prevetName',
      label: 'Prevet Application Name'
    }
  },
  businessInfoSection: {
    id: 'businessInfoSection',
    legalName: {
      ...formProps.inputText,
      required: true,
      id: 'legalName',
      label: 'Legal Name'
    },
    dbaName: {
      ...formProps.inputText,
      required: true,
      id: 'dbaName',
      label: 'DBA Name'
    },
    physicalAddress: {
      ...formProps.inputText,
      required: false,
      id: 'physicalAddress',
      label: 'Physical Address',
      wrapperStyle: { flex: '100%' }
    },
    city: {
      ...formProps.inputText,
      required: false,
      id: 'city',
      label: 'City'
    },
    state: {
      ...sharedFormFields.state,
      required: false,
      id: 'state',
      label: 'State',
      useBlockForm: true
    },
    zip: { ...sharedFormFields.zip, required: false },
    businessEmailAddress: {
      id: 'businessEmailAddress',
      label: 'Business Email Address',
      type: 'email',
      fieldType: 'input',
      required: false
    },
    url: {
      id: 'url',
      label: 'URL',
      type: 'urlRequiredProtocol',
      fieldType: 'input',
      required: true
    },
    productsAndServicesSold: {
      ...formProps.inputText,
      required: true,
      id: 'productsAndServicesSold',
      label: 'Products & Services Sold'
    },
    preferredMcc: {
      ...sharedFormFields.mccCode,
      required: false,
      id: 'preferredMcc',
      label: 'Preferred MCC'
    },
    averageTimeToDelivery: {
      ...formProps.inputNumber,
      required: true,
      id: 'averageTimeToDelivery',
      label: 'Average Time to Delivery (Days)'
    }
  },
  customFieldsSection: {
    id: 'customFieldsSection',
    monthAndYearStarted: {
      id: 'monthAndYearStarted',
      label: 'Month/Year Started',
      required: true,
      useBlockForm: true,
      type: 'text',
      fieldType: 'combobox'
    }
  },
  additionalInfoSection: {
    id: 'additionalInfoSection',
    stateIncorporated: {
      ...sharedFormFields.state,
      required: false,
      useBlockForm: true,
      id: 'stateIncorporated',
      label: 'State Incorporated'
    },
    ownershipType: {
      id: 'ownershipType',
      label: 'Ownership Type',
      type: 'text',
      list: [
        { title: 'Sole Proprietorship', value: 'soleProprietorship' },
        { title: 'Partnership', value: 'partnership' },
        { title: 'Non-Profit', value: 'nonProfit' },
        { title: 'Public Corp', value: 'publicCorp' },
        { title: 'Private Corp', value: 'privateCorp' },
        { title: 'LLC', value: 'llc' },
        { title: 'Government', value: 'government' }
      ],
      fieldType: 'combobox',
      required: true,
      useBlockForm: true
    },
    ...formDivDivider(),
    averageMonthlySalesVolume: {
      ...formProps.inputPrice,
      required: true,
      id: 'averageMonthlySalesVolume',
      label: 'Average Monthly Sales Volume'
    },
    averageTicket: {
      ...formProps.inputPrice,
      required: true,
      id: 'averageTicket',
      label: 'Average Ticket'
    },
    highestTicketAmount: {
      ...formProps.inputPrice,
      required: true,
      id: 'highestTicketAmount',
      label: 'Highest Ticket Amount'
    },
    ...formDivDivider(),
    equipmentOrECommPlatform: {
      ...formProps.inputText,
      required: false,
      id: 'equipmentOrECommPlatform',
      label: 'Equipment/E-Commerce Platform'
    },
    ...formDivDivider(),
    averagePercentageOfRefunds: {
      ...formProps.inputPercent,
      required: true,
      id: 'averagePercentageOfRefunds',
      label: 'Average Refunds'
    },
    averagePercentageOfChargebacks: {
      ...formProps.inputPercent,
      required: true,
      id: 'averagePercentageOfChargebacks',
      label: 'Average Chargebacks'
    },
    averagePercentageOfInternationalSales: {
      ...formProps.inputPercent,
      required: true,
      id: 'averagePercentageOfInternationalSales',
      label: 'Average International Sales'
    },
    ...formDivDivider(),
    recurringTransactions: {
      id: 'recurringTransactions',
      name: 'recurringTransactions',
      label: 'Recurring Transactions',
      type: 'radio',
      fieldType: 'radio',
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      required: true
    }
  },
  salesMakeupSection: {
    id: 'salesMakeupSection',
    swipedPercentage: {
      ...formProps.inputPercent,
      required: true,
      id: 'swipedPercentage',
      label: 'Swiped'
    },
    keyedPercentage: {
      ...formProps.inputPercent,
      required: true,
      id: 'keyedPercentage',
      label: 'Keyed'
    },
    mailOrderPercentage: {
      ...formProps.inputPercent,
      required: true,
      id: 'mailOrderPercentage',
      label: 'Mail Order'
    },
    internetPercentage: {
      ...formProps.inputPercent,
      required: true,
      id: 'internetPercentage',
      label: 'Internet'
    }
  }
};

export const prevetStatusList = {
  id: 'prevetStatus',
  label: 'Status',
  type: 'text',
  list: [
    {
      title: 'Waiting On Credit',
      value: 'waiting_on_credit'
    },
    {
      title: 'Waiting On App Review',
      value: 'waiting_on_app_review'
    },
    {
      title: 'Proceed',
      value: 'proceed',
      isReviewed: true,
      isCompleted: true
    },
    {
      title: 'Decline',
      value: 'decline',
      isReviewed: true,
      isCompleted: true
    },
    {
      title: 'Abandon',
      value: 'abandoned',
      isReviewed: false,
      isCompleted: true
    }
  ],
  fieldType: 'combobox'
};
