import { crabMasterTaskList } from '../../_crabFields';
import { isEmpty } from '../../_helpers';
import { getMockValue } from '../_mockDataHelpers';
import { createMockFileList, staticPendFilesMap } from './mockBoardingApplicationFiles';

const staticPends = {
  // For FTs
  0: {
    // Open Pend
    description: '01 - Mock Pend Description - Open Pend',
    pendStatus: 'initial',
    requiresNewSignature: true,
    pendCreatedTimestamp: `2019-12-31T20:21:00Z`,
    pendClosedTimestamp: null,
    published: true
  },
  1: {
    // Closed Pend
    description: '02 - Mock Pend Description - Closed Pend',
    pendStatus: 'resolved',
    pendCreatedTimestamp: `2019-12-31T20:20:00Z`,
    pendClosedTimestamp: `2019-12-31T21:20:00Z`,
    published: true
  },
  2: {
    // Open Pend 2
    description: '03 - Mock Pend Description - Open Pend 2',
    pendStatus: 'initial',
    requiresNewSignature: true,
    pendCreatedTimestamp: `2019-12-31T20:20:00Z`,
    pendClosedTimestamp: null,
    published: true
  },
  3: {
    // Open Pend 3
    description:
      '04 - Mock Pend Description - Open Pend 3 - very very long description very very long description very very long description very very long description very very long description very very long description very very long description very very long description very very long description',
    pendStatus: 'initial',
    requiresNewSignature: true,
    pendCreatedTimestamp: `2019-12-31T20:19:00Z`,
    pendClosedTimestamp: null,
    published: true
  },
  4: {
    // Open Pend 4 - status test
    description: '05 - Mock Pend Description - Open Pend 4 - test for REVISIT status',
    pendStatus: 'revisit',
    requiresNewSignature: true,
    pendCreatedTimestamp: `2019-12-31T20:20:00Z`,
    pendClosedTimestamp: null,
    published: true
  }
};

export const createMockPendList = (options) => {
  const {
    assignedAppReviewEmployeeId,
    pendStatus, // override - all pends will have this status
    length,
    userType, // one of: partner, employee
    employeeList, // employee only
    getMockTasks, // employee only
    defaultAppReviewEmployee, // employee only
    overrideGuidType,
    guidType,
    guid
  } = options || {};
  const pendList = Array.from({ length: length || 26 }).map((item, index) => {
    // to create the `employeeWhoCreated` object
    const staticPendIds = ['0', '1', '2', '3']; // should match keys of `staticPends` (for FTs)
    const isStaticPend = staticPendIds.includes(`${index}`);

    const randomEmployeeId =
      userType === 'employee'
        ? assignedAppReviewEmployeeId ||
          getMockValue({
            type: 'list',
            list: [...(isStaticPend ? [defaultAppReviewEmployee] : employeeList || [])]
          })
        : null;
    /* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
    const employeeMatch =
      userType === 'employee'
        ? employeeList?.find((emp) => emp?.employeeId === randomEmployeeId) || {}
        : {};
    const appsWithAllPendsAddressed = ['9'];
    const appsWithAllPendsResolved = ['24'];
    const { [`mock-application-pend-id-1`]: defaultStaticMockFiles } = staticPendFilesMap || {};
    let mockPendStatus =
      pendStatus ||
      getMockValue({
        type: 'list',
        list: ['initial', 'resolved', 'addressed', 'needs_help', 'repended', 'revisit']
      });
    if (appsWithAllPendsAddressed.includes(guid)) {
      /* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
      mockPendStatus = 'addressed';
    }
    if (appsWithAllPendsResolved.includes(guid)) {
      /* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
      mockPendStatus = 'resolved';
    }
    const mockPendIsClosed =
      mockPendStatus === 'resolved' ? getMockValue({ type: 'isoDate' }) : null;
    const mockTaskId =
      overrideGuidType === 'applicationTaskId' ? guid : `mock-application-task-id-${index + 1}`;
    const mockTaskList = getMockTasks && guidType === 'applicationId' ? getMockTasks(guid) : [];
    const mockTaskMatch = !isEmpty(mockTaskList)
      ? mockTaskList.find((t) => t.applicationTaskId === mockTaskId) || {}
      : {};
    const mockTaskName =
      (mockTaskMatch && mockTaskMatch.applicationTaskName) ||
      getMockValue({ type: 'list', list: crabMasterTaskList });
    return {
      description: getMockValue({ type: 'description' }),
      requiresNewSignature: getMockValue({ type: 'boolean' }),
      pendStatus: mockPendStatus,
      pendCreatedTimestamp: getMockValue({ type: 'isoDate' }),
      pendClosedTimestamp: mockPendIsClosed,
      negativeActionList: Array.from({
        length: getMockValue({ min: 3, max: 10 }) // Min of 3 for FTs
      }).map((nItem, nIndex) => ({
        categoryValue: `Mock negative action category ${nIndex + 1}`,
        subCategoryValue: `Mock negative action sub-category ${nIndex + 1}`
      })),
      ...(userType === 'partner' &&
        appsWithAllPendsAddressed.includes(guid) &&
        index % 2 === 0 && {
          // Mark some as resolved
          pendStatus: 'resolved',
          pendClosedTimestamp: getMockValue({ type: 'isoDate' })
        }),
      applicationPendId: `mock-application-pend-id-${index + 1}`,
      applicationTaskId: mockTaskId,
      applicationTaskName: mockTaskName,
      published: userType === 'partner' ? true : getMockValue({ type: 'boolean' }),
      employeeWhoCreated: {
        employeeId:
          userType === 'partner' ? getMockValue({ type: 'guid' }) : employeeMatch.employeeId,
        department: 'app_review',
        firstName: userType === 'partner' ? null : employeeMatch.firstName,
        email: userType === 'partner' ? null : employeeMatch.email
      },
      fileList: createMockFileList({ ...(index === 0 && { staticFiles: defaultStaticMockFiles }) }),
      // send first pend special files for FTs
      ...(!appsWithAllPendsAddressed.includes(guid) &&
        !appsWithAllPendsResolved.includes(guid) &&
        staticPends[index])
    };
  });
  return pendList;
};

export const mockBoardingApplicationTaskPendGet = (body, params, options) => {
  const { guid } = options || {};
  const mockPends = createMockPendList({
    ...options,
    ...(guid.includes('task') && { overrideGuidType: 'applicationTaskId' }),
    ...(guid.includes('pend') && { overrideGuidType: 'applicationPendId' })
  });
  return { [guid]: { pends: mockPends } };
};

export const mockBoardingApplicationTaskPendPut = (body, params, options) => {
  // employee only
  const { guid } = options || {};
  const staticPendIdMap = {
    // Pended tasks in task cards mock data
    'mock-application-task-id-10': 'mock-application-pend-id-2',
    'mock-application-task-id-1': 'mock-application-pend-id-2'
  };
  return { [guid]: { applicationPendId: staticPendIdMap[guid] || 'mock-application-pend-id-1' } };
};
