import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { giactTemplate } from './templates/giactTemplate';

// MOCK API RESPONSE DATA HELPERS
const createMockBusinessNameRecordList = () => {
  const length = getMockValue({ type: 'number', min: 1, max: 6 });
  const mockNameList = Array.from({ length }).map((item, index) => {
    const mockNameItem = {
      businessName: `business name ${index + 1}`,
      businessType: `business type ${index + 1}`
    };
    return mockNameItem;
  });
  return mockNameList;
};

const createMockPhoneRecordList = () => {
  const length = getMockValue({ type: 'number', min: 1, max: 6 });
  const mockPhoneList = Array.from({ length }).map(() => {
    const mockPhoneNum = `+1${getMockValue({ type: 'phone' })}`;
    const classificationEnums = ['Personal', 'Business', 'ResidentialBusiness', 'Other']; // BE enums
    const numberTypeEnums = ['Standard', 'Mobile', 'VoIP', 'Other']; // BE enums
    const mockPhoneItem = {
      classification: getMockValue({ type: 'list', list: classificationEnums }),
      numberType: getMockValue({ type: 'list', list: numberTypeEnums }),
      phoneNumber: mockPhoneNum.replace(/[-]/g, '')
    };
    return mockPhoneItem;
  });
  return mockPhoneList;
};

const createMockContactList = () => {
  const length = getMockValue({ type: 'number', min: 1, max: 6 });
  const mockContactList = Array.from({ length }).map(() => {
    const { firstName, lastName } = getMockValue({ type: 'user' });
    const mockContactItem = {
      firstName,
      lastName,
      title: getMockValue({ type: 'string' })
    };
    return mockContactItem;
  });
  return mockContactList;
};

const createMockAddressRecordList = (body) => {
  const { businessState, businessAddress, businessCity, businessZip } = body || {};
  const length = getMockValue({ type: 'number', min: 1, max: 6 });
  const mockAddressList = Array.from({ length }).map((item, index) => {
    if (index === 0) {
      return {
        address: businessAddress,
        city: businessCity,
        state: businessState,
        zip: businessZip
      };
    }
    const { fullAddress, stateCode, zipCode, cityName } = getMockValue({ type: 'address' });
    return {
      address: fullAddress,
      city: cityName,
      state: stateCode,
      zip: zipCode
    };
  });
  return mockAddressList;
};

const createMockMatchedBusinessDataList = (body) => {
  const { businessState } = body || {};
  const length = getMockValue({ type: 'number', min: 1, max: 6 });
  const mockBusinessDataList = Array.from({ length }).map(() => {
    const mockNumberOptions = {
      type: 'number',
      stringify: true,
      min: 10000,
      max: 999999
    };
    const mockDataListItem = {
      giactBusinessNameRecordList: createMockBusinessNameRecordList(),
      fein: getMockValue({ ...mockNumberOptions }),
      dunsNumber: getMockValue({ ...mockNumberOptions }),
      corporationType: getMockValue({ type: 'string' }),
      registrationType: getMockValue({ type: 'string' }),
      incorporationState: businessState,
      incorporationDate: getMockValue({ type: 'date' }),
      filingNumber: getMockValue({ ...mockNumberOptions }),
      industries: Array.from({ length: getMockValue({ type: 'number', min: 1, max: 6 }) }).map(
        (industryItem, industryIndex) => `industry ${industryIndex + 1}`
      ),
      domains: Array.from({ length: getMockValue({ type: 'number', min: 1, max: 6 }) }).map(
        (domainItem, domainIndex) => `domain ${domainIndex + 1}`
      ),
      giactAddressRecordList: createMockAddressRecordList(body),
      giactPhoneNumberRecordList: createMockPhoneRecordList(),
      giactBusinessContactList: createMockContactList(),
      bankruptcySubjectRecordCount: getMockValue({ type: 'number' }),
      bankruptcyCreditorRecordCount: getMockValue({ type: 'number' }),
      businessReportKey: getMockValue({ type: 'string' })
    };
    return mockDataListItem;
  });
  return mockBusinessDataList;
};

const createMockMatchedPersonDataList = (body) => {
  const { businessState } = body || {};
  const length = getMockValue({ type: 'number', min: 1, max: 6 });
  const mockPersonDataList = Array.from({ length }).map(() => {
    const mockYearOptions = {
      type: 'number',
      stringify: true,
      min: 1950,
      max: 2022
    };
    const mockUser = getMockValue({ type: 'user' });
    const mockDataListItem = {
      firstName: mockUser.firstName,
      lastName: mockUser.lastName,
      dateOfBirth: getMockValue({ type: 'date' }),
      ssnIssueState: businessState,
      ssnIssueStartYear: getMockValue({ ...mockYearOptions }),
      ssnIssueEndYear: getMockValue({ ...mockYearOptions }),
      giactAddressRecordList: createMockAddressRecordList(body),
      giactPhoneNumberRecordList: createMockPhoneRecordList()
    };
    return mockDataListItem;
  });
  return mockPersonDataList;
};

export const createMockGiactRes = (body, overrides) => {
  const { dataFetchTimestamp } = overrides || {};
  const decisionEnums = ['proceed', 'manual_review', 'pend']; // BE enums
  const mockResponse = {
    giactResponse: {
      metadata: {
        dataFetchTimestamp: dataFetchTimestamp || new Date().toISOString(),
        recommendation: {
          decision: getMockValue({ type: 'list', list: decisionEnums }),
          explanation: getMockValue({ type: 'description', length: 'long' })
        }
      },
      gIdentify: {
        verificationResponse: getMockValue({ type: 'description', length: 'short' }),
        accountResponseCode: getMockValue({ type: 'number', stringify: true }),
        customerResponseCode: getMockValue({ type: 'number', stringify: true }),
        matchedBusinessDataList: createMockMatchedBusinessDataList(body),
        matchedPersonDataList: createMockMatchedPersonDataList(body)
      },
      gAuthenticate: {
        verificationResponse: getMockValue({ type: 'description', length: 'short' }),
        accountResponseCode: getMockValue({ type: 'number', stringify: true }),
        customerResponseCode: getMockValue({ type: 'number', stringify: true })
      }
    }
  };
  return mockResponse;
};

export const mockFeResponseFormatted = giactTemplate.frontend(createMockGiactRes(), '1.0');
