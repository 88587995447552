// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import globalFormProps from '../../_formHelpers';
import { crabTaskPendFieldsInfo, crabTaskPendFieldsPost } from '../../_crabFields';
import pendListTemplate, {
  createPendDetailsFrontend
} from '../../data/sharedBoarding/templates/pendListTemplate';
import { AlertBar, Button, Input, ComboBox, Radio, InputTimestamp, Spinner } from '../../../index';
import {
  sortData,
  isEqual,
  isEmpty,
  transformData,
  endpoint,
  sharedGetInnerAlertBarState,
  isBool,
  ignoreCase
} from '../../_helpers';
import { flexboxCSS } from '../../_globals';
import { formStylesCSS } from '../../_formStyles';
import { ModalV2 } from '../../ModalV2';
import { ChangeStatusForm } from './ChangeStatusForm';

class PendDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { userType } = props;
    this.partnerCanEditPendStatuses = [
      'draft',
      'waiting_on_initial_signature',
      'waiting_on_partner',
      'waiting_on_signature_post_pends'
    ];
    this.appIsClosedStatuses = ['approved', 'declined', 'withdrawn'];
    this.initialFormState = {
      disabledFormFields: userType === 'partner',
      spinnerLoading: false,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      formInProgress: false,
      formValid: false, // Default Validation
      appStatus: '',
      appIsClosed: false,
      partnerAddressedPend: false,
      pendStatusList: [],
      formFields: {
        ...crabTaskPendFieldsPost
      },
      infoFields: {
        ...crabTaskPendFieldsInfo
      },
      otherData: {}
    };
    this.resetModalData = {
      modalAction: '',
      modalTitle: null,
      showModal: false
    };
    this.state = {
      ...this.resetModalData,
      ...this.initialFormState,
      showModal: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { pendData } = this.props;
    if (!isEmpty(pendData)) {
      this.setFieldValues();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userType, currentEmployeeCanUpdate, pendData, isFormDirty, timestamp, appStatus } =
      this.props;
    const { formInProgress } = this.state;
    if (
      timestamp > prevProps.timestamp ||
      !isEqual(appStatus, prevProps.appStatus) ||
      !isEqual(pendData, prevProps.pendData) ||
      (userType === 'employee' &&
        !isEqual(currentEmployeeCanUpdate, prevProps.currentEmployeeCanUpdate))
    ) {
      this.setFieldValues();
      this.updateState({ formInProgress: false });
      isFormDirty(false);
    }
    if (prevState.formInProgress !== formInProgress) {
      isFormDirty(formInProgress);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  setFieldValues = () => {
    const { currentEmployeeCanUpdate, pendData, userType, appStatus } = this.props;
    const { formFields = {}, infoFields = {}, otherData = {} } = pendData || {};
    const initialPendStatus = ignoreCase(formFields[crabTaskPendFieldsPost.pendStatus.id] || '');
    const newPendStatusList = crabTaskPendFieldsPost.pendStatus.list.filter((item) => {
      if (item.agentCanEdit) {
        return item.value === 'repended' && initialPendStatus !== item.value
          ? ['needs_help', 'addressed'].includes(initialPendStatus)
          : true;
      }
      return initialPendStatus === item.value;
    });
    this.updateState(
      (prevState) => {
        const newFormFields = Object.entries(formFields).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: {
              ...acc[key],
              ...(crabTaskPendFieldsPost[key]?.fieldType === 'dropdown' && {
                title: value
              }),
              value,
              valid:
                !isEmpty(value) &&
                !isBool(value) &&
                !(key === 'pendStatus' && (value === 'addressed' || value === 'needs_help'))
            }
          }),
          { ...prevState.formFields }
        );
        const newInfoFields = Object.entries(infoFields).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: { ...acc[key], value }
          }),
          { ...prevState.infoFields }
        );
        const newOtherData = Object.entries(otherData).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: { ...acc[key], value }
          }),
          { ...prevState.otherData }
        );
        return {
          pendStatusList: sortData(newPendStatusList, 'title'),
          formFields: newFormFields,
          infoFields: newInfoFields,
          otherData: newOtherData,
          ...(userType === 'employee' && {
            disabledFormFields:
              currentEmployeeCanUpdate !== true || appStatus === 'waiting_on_partner'
          }),
          appStatus,
          appIsClosed: this.appIsClosedStatuses.includes(appStatus)
        };
      },
      userType === 'employee' ? this.isFormValid : null
    );
  };

  handleChangeStatus = () => {
    // partner only
    this.updateState({ showModal: true, modalAction: 'pendDetailsForm' });
  };

  handleChangeStatusSuccess = (options) => {
    // partner only
    // On success, should refresh applications (to update applicationStatus), and should
    // refresh pendList, and refresh external comms (if applicable)
    const {
      needToValidateSubmit,
      updateAppDetailsScrollPos,
      partnerRequestedHelp,
      updatedPendDetails,
      refreshPendDetails,
      refreshExternalComm,
      refreshAppDetails,
      refreshAppList
    } = options || {};
    this.updateState({ ...this.resetModalData });
    this.handleInnerAlertBar({
      type: 'success',
      data: `Success! Pend updated.${partnerRequestedHelp ? ' You may now Resubmit the application.' : ''}`
    });
    const cbOptions = {
      needToValidateSubmit,
      updateAppDetailsScrollPos,
      partnerRequestedHelp,
      updatedPendDetails,
      refreshPendDetails,
      refreshExternalComm,
      refreshAppDetails,
      refreshAppList
    };
    this.handleCallback(cbOptions);
    const partnerAddressedPend = updatedPendDetails?.pendStatus === 'addressed';
    partnerAddressedPend && this.updateState({ partnerAddressedPend });
  };

  handleSubmit = async () => {
    // employee only
    const { isFormDirty, axiosRequest, userType, pendData } = this.props;
    const { formFields, infoFields } = this.state;
    const { pendId = {}, taskId = {} } = infoFields || {};
    if (isEmpty(pendId?.value)) return;
    this.handleInnerAlertBar({ type: 'closed', data: '' });
    this.updateState({ spinnerLoading: true });
    const requestBody = transformData({
      data: {
        userType,
        description: formFields.description.value,
        requiresNewSignature: formFields.requiresNewSignature.value,
        pendStatus: formFields.pendStatus.value
      },
      toSchema: 'backendPost',
      template: pendListTemplate,
      version: '1.0'
    });
    const requestOptions = {
      fullPageLoad: false,
      method: 'post',
      url: `${endpoint.crab.v1.application.taskPend}`,
      requestGuid: { applicationPendId: pendId.value }
    };
    const apiRes = await axiosRequest(requestOptions, requestBody);
    this.updateState({ ...apiRes?.state });
    if (apiRes?.errorDetails instanceof Error) {
      this.handleInnerAlertBar({ type: 'warning', data: apiRes.errorDetails });
    } else {
      this.updateState({ formInProgress: false });
      this.handleInnerAlertBar({ type: 'success', data: 'Success! Pend Updated.' });
      await isFormDirty(false);
      const cbOptions = {
        refreshPendDetails: true,
        updatedPendDetails: createPendDetailsFrontend({
          taskId: taskId.value,
          pendId: pendId.value,
          requiresNewSignature: formFields.requiresNewSignature.value,
          ...(formFields.pendStatus.value !== pendData.formFields?.pendStatus && {
            pendStatus: formFields.pendStatus.value
          })
        })
      };
      this.handleCallback(cbOptions);
    }
  };

  handleCallback = (options) => {
    const { callback } = this.props;
    callback && callback(options);
  };

  isFormValid = () => {
    const { formFields } = this.state;
    const invalidFields = Object.entries(formFields).filter(([_key, field]) => !field.valid);
    const isStatusValid = ['initial', 'repended', 'resolved', 'revisit'].includes(
      ignoreCase(formFields.pendStatus.value)
    );
    this.updateState({ formValid: isEmpty(invalidFields) && isStatusValid });
  };

  handleInnerAlertBar = (options) => {
    const { type, data, additionalState = {} } = options || {};
    const { axiosRequest } = this.props;
    const alertBarState = sharedGetInnerAlertBarState({ type, data, axiosRequest });
    this.updateState({ ...alertBarState, ...additionalState });
  };

  handleChangeInput = (id, value, valid) => {
    this.updateState(
      (prevState) => ({
        ...prevState,
        formFields: {
          ...prevState.formFields,
          [id]: {
            ...prevState.formFields[id],
            value,
            valid
          }
        },
        formInProgress: true
      }),
      this.isFormValid
    );
  };

  handleChangeDropdown = (options) => {
    const { id, title, value } = options || {};
    this.updateState(
      (prevState) => ({
        ...prevState,
        formFields: {
          ...prevState.formFields,
          [id]: {
            ...prevState.formFields[id],
            value,
            title,
            valid: true
          }
        },
        formInProgress: true
      }),
      this.isFormValid
    );
  };

  handleChangeRadio = (id, value, checked) => {
    this.updateState(
      (prevState) => ({
        ...prevState,
        formFields: {
          ...prevState.formFields,
          [id]: {
            ...prevState.formFields[id],
            value,
            valid: checked
          }
        },
        formInProgress: true
      }),
      this.isFormValid
    );
  };

  render() {
    const {
      disabledFormFields,
      appIsClosed,
      alertBarType,
      alertBarMessage,
      alertBarTimeout,
      spinnerLoading,
      formValid,
      formFields,
      infoFields,
      otherData,
      appStatus,
      partnerAddressedPend,
      pendStatusList,
      formInProgress,
      modalAction,
      showModal
    } = this.state;
    const { axiosRequest, userType, pendData } = this.props;
    const initialPendStatus = pendData?.formFields[crabTaskPendFieldsPost.pendStatus.id];
    const { pendId = {}, closed = {} } = infoFields || {};
    const pendIsClosed = initialPendStatus === 'resolved' || !isEmpty(closed?.value) || appIsClosed;
    const isPublished = otherData?.published?.value;
    const disableForm = pendIsClosed || disabledFormFields;
    const partnerButtonDisabled =
      userType === 'partner' &&
      (pendIsClosed ||
        !this.partnerCanEditPendStatuses.includes(appStatus) ||
        partnerAddressedPend);
    const formFieldStyle = {
      partner: { flex: `calc(100% / ${Object.keys(formFields).length} - 133px)` },
      employee: { flex: `calc(100% / ${Object.keys(formFields).length} - 75px)` }
    };
    const pendStatusSelection = crabTaskPendFieldsPost.pendStatus.list.find(
      (item) => item.value === 'addressed'
    );
    return (
      <div
        id="pend-details-form"
        data-testid="pend-details-form"
        className="card"
        style={formStylesCSS.formWrapper}>
        <AlertBar
          options={{
            barStyle: alertBarType,
            message: alertBarMessage,
            timeout: alertBarTimeout,
            customWarnStyle: { width: '100%', height: '100%' }
          }}
          callback={this.updateState}
          useInnerAlertBar
        />
        <Spinner loading={spinnerLoading} />
        <ModalV2
          axiosRequest={axiosRequest}
          showModal={showModal}
          modalTitle="Submit the form below to complete this pend"
          closeModalCallback={this.updateState}
          resetModalData={this.resetModalData}>
          {modalAction === 'pendDetailsForm' ? (
            <ChangeStatusForm
              pendId={pendId?.value}
              axiosRequest={axiosRequest}
              callback={this.handleChangeStatusSuccess}
              existingTaskPendFields={{
                ...formFields,
                pendStatus: { ...formFields.pendStatus, ...pendStatusSelection }
              }}
            />
          ) : (
            <></>
          )}
        </ModalV2>
        <div style={formStylesCSS.form}>
          <div className="formSection" style={flexboxCSS.wrap}>
            <Input
              {...globalFormProps.input}
              {...crabTaskPendFieldsPost.description}
              wrapperStyle={{
                ...crabTaskPendFieldsPost.description.wrapperStyle,
                ...formFieldStyle[userType]
              }}
              value={formFields[crabTaskPendFieldsPost.description.id]?.value}
              callback={this.handleChangeInput}
              disabled={disableForm || isPublished}
              height={null} // To show full height of textarea
              required={userType !== 'partner'}
            />
          </div>
          <div className="formSection" style={{ ...flexboxCSS.wrap, marginTop: '-1px' }}>
            <Input
              {...globalFormProps.input}
              {...crabTaskPendFieldsInfo.createdBy}
              value={infoFields[crabTaskPendFieldsInfo.createdBy.id]?.value}
              noValidate
              disabled
            />
            <InputTimestamp
              {...globalFormProps.input}
              {...crabTaskPendFieldsInfo.created}
              value={infoFields[crabTaskPendFieldsInfo.created.id]?.value}
              noValidate
              disabled
            />
            <InputTimestamp
              {...globalFormProps.input}
              {...crabTaskPendFieldsInfo.closed}
              value={infoFields[crabTaskPendFieldsInfo.closed.id]?.value}
              noValidate
              disabled
            />
          </div>
          <div
            className="formSection"
            style={{
              ...flexboxCSS.wrap,
              marginTop: '-1px',
              ...(userType === 'partner' && { justifyContent: 'end' })
            }}>
            {userType === 'employee' && (
              <>
                <Input
                  {...globalFormProps.input}
                  {...crabTaskPendFieldsInfo.negativeActionList}
                  value={infoFields.negativeActionList?.value}
                  disabled
                />
                <Radio
                  {...globalFormProps.radio}
                  {...crabTaskPendFieldsPost.requiresNewSignature}
                  wrapperStyle={{
                    ...crabTaskPendFieldsPost.requiresNewSignature.wrapperStyle,
                    ...formFieldStyle[userType]
                  }}
                  name={crabTaskPendFieldsPost.requiresNewSignature.id}
                  selected={formFields?.[crabTaskPendFieldsPost.requiresNewSignature.id]?.value}
                  callback={this.handleChangeRadio}
                  disabled={disableForm || isPublished}
                />
                <ComboBox
                  {...globalFormProps.comboBox}
                  {...crabTaskPendFieldsPost.pendStatus}
                  wrapperStyle={{
                    ...crabTaskPendFieldsPost.pendStatus.wrapperStyle,
                    ...formFieldStyle[userType]
                  }}
                  list={pendStatusList}
                  selected={formFields[crabTaskPendFieldsPost.pendStatus.id]?.value}
                  callback={this.handleChangeDropdown}
                  disabled={disableForm}
                  required={userType !== 'partner'}
                  useBlockForm
                />
                <Button
                  id="submit"
                  onClick={this.handleSubmit}
                  disabled={disableForm || !formValid || !(formValid && formInProgress)}
                  style={{ margin: '5px 0 0 5px' }}
                  size="lg"
                  {...(appStatus === 'waiting_on_partner' && {
                    title: 'Pends cannot be edited while in Waiting on Partner status'
                  })}>
                  Submit
                </Button>
              </>
            )}
            {userType === 'partner' && !partnerButtonDisabled && (
              <Button
                id="submit"
                onClick={this.handleChangeStatus}
                style={{ margin: '5px 0 0 5px' }}
                size="lg">
                I am done with this pend
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PendDetailsForm.propTypes = {
  userType: PropTypes.oneOf(['partner', 'employee']),
  currentEmployeeCanUpdate: PropTypes.bool,
  appStatus: PropTypes.string.isRequired,
  pendData: PropTypes.shape({
    formFields: PropTypes.oneOfType([PropTypes.object]),
    infoFields: PropTypes.oneOfType([PropTypes.object]),
    otherData: PropTypes.oneOfType([PropTypes.object])
  }),
  timestamp: PropTypes.number,
  callback: PropTypes.func,
  isFormDirty: PropTypes.func,
  axiosRequest: PropTypes.func
};

PendDetailsForm.defaultProps = {
  userType: 'partner',
  currentEmployeeCanUpdate: false, // employee only
  pendData: {
    formFields: {},
    infoFields: {},
    otherData: {}
  },
  timestamp: 0,
  callback: () => {},
  // ^^ Callbacks dont really seem like they are needed
  // However I could see wanting a shared alertbar to display
  // display requestHelp feedback to the user
  isFormDirty: () => {},
  axiosRequest: () => {}
};

export default PendDetailsForm;
