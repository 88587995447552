import { ignoreCase, isEmpty } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { utilizeDefaultProcessor } from '@f1/shared/src/data/sharedBoarding/templates/crabV1ApplicationTemplate';
import { processorNameList } from '../../pages/components/forms/formHelpers';

const mockGetAllTaskConfig = (params) => {
  const mockProcessList = [{ title: 'Default', value: 'DEFAULT' }, ...processorNameList];
  const employeeGroups = ['app_review', 'operations_legacy', 'credit'];
  const allRiskProfiles = ['preferred', 'standard', 'elevated'];
  const mockAllTaskConfig = employeeGroups.reduce((taskConfigAcc, deptEnum) => {
    const mockRiskProfileData = allRiskProfiles.reduce((riskProfileAcc, riskProfileEnum) => {
      const filteredProcessors = mockProcessList.filter((processItem) =>
        processItem.value === 'DEFAULT'
          ? true
          : !utilizeDefaultProcessor({
              riskProfile: riskProfileEnum,
              processName: ignoreCase(processItem.value)
            })
      );
      const mockProcessData = filteredProcessors.reduce((processAcc, processItem) => {
        const mockDataRow = {
          processorNameOrDefault: processItem.value,
          riskProfile: riskProfileEnum,
          department: deptEnum,
          applicationTaskNames: {
            additionalDocumentReview: true, // make at least one true for FT
            bankingValidation: getMockValue({ type: 'boolean' }),
            businessFinancialReview: getMockValue({ type: 'boolean' }),
            mpaPostNewSignatureVerification: getMockValue({ type: 'boolean' }),
            riskExposure: getMockValue({ type: 'boolean' }),
            corviaDocumentsReview: getMockValue({ type: 'boolean' }),
            dbaCreditBureauData: getMockValue({ type: 'boolean' }),
            dueDiligenceReview: getMockValue({ type: 'boolean' }),
            kybKnowYourBusiness: getMockValue({ type: 'boolean' }),
            kycKnowYourCustomer: getMockValue({ type: 'boolean' }),
            match: getMockValue({ type: 'boolean' }),
            mpaValidation: getMockValue({ type: 'boolean' }),
            negativeOptionBillingRequirements: getMockValue({ type: 'boolean' }),
            ofac: getMockValue({ type: 'boolean' }),
            ownerCreditBureauData: getMockValue({ type: 'boolean' }),
            prohibitedEntity: getMockValue({ type: 'boolean' }),
            relatedPerson: getMockValue({ type: 'boolean' }),
            riskScore: getMockValue({ type: 'boolean' }),
            illicitActivityReview: getMockValue({ type: 'boolean' }),
            whoisReview: getMockValue({ type: 'boolean' }),
            websiteHtmlReview: getMockValue({ type: 'boolean' })
          }
        };
        return processAcc.concat(mockDataRow);
      }, []);
      return riskProfileAcc.concat(...mockProcessData);
    }, []);
    return taskConfigAcc.concat(...mockRiskProfileData);
  }, []);
  const mockFilteredTaskData = !isEmpty(params)
    ? mockAllTaskConfig.filter((item) => {
        const paramKeys = Object.keys(params);
        return paramKeys.every((paramKey) => item[paramKey] === params[paramKey]);
      })
    : mockAllTaskConfig;
  return mockFilteredTaskData;
};

export const mockApplicationTaskConfigGet = (body, params, _options) => {
  const mockData = mockGetAllTaskConfig(params);
  return { applicationTaskConfig: mockData };
};

export default mockApplicationTaskConfigGet;
