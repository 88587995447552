// TODO: BIRB-8338
/* istanbul ignore file */

import React from 'react';
import PropTypes from 'prop-types';
import {
  AddressAutocomplete,
  Checkbox,
  CheckboxList,
  ComboBox,
  ComboBoxSelectMonthYear,
  FormDragAndDropRankedList,
  FormCustomSection,
  FormSectionHeader,
  Radio,
  Input
} from '../index';
import { dataExists, isBool, isEmpty, isEqual } from './_helpers';
import globalFormProps, {
  getCheckedItemKeys,
  getValueChangedData,
  isInitialValueValid,
  valueExists
} from './_formHelpers';
import { formStylesCSS } from './_formStyles';
import { toBackendValue } from './_templateHelpers';

/**
 * FormAssistant will autobuild a form and manage its state
 *
 * @param {*} ref - If you define a ref for this component, (i.e. `this.form = React.createRef()`)
 * and pass it to the ref prop of FormAssistant, then you can call this.form.current.updateValues
 * from the parent to update form values after the component has loaded.
 *
 * @param {*} callback - This can just be the parent's updateState()/setState() function.
 * When form fields change in FormAssistant,
 * it will call the callback with the changes as a state object.
 *
 * @param {*} formComponents - Pass it an array of objects to describe the fields it should produce.
 * Usage should look like:
 *
 * const formComponents = [
 * {
        componentType: 'dropdown',
        props: {
          label: 'interactive test',
          id: 'emailResponse',
          required: false,
          list: [{ value: 'yes', title: 'yes' }, { value: 'no', title: 'no' }],
          controlledBy: {
            controllerId: 'newEmail',

            // If there is only one controllerValue that has overrides
            controllerValue: 'fake@test.gov',
            overrides: {
              list: [{ title: 'seriously?', value: 'seriously?' }],
              label: 'This component has been overridden!'
            },

            // OR, if multiple controllerValue options has different overrides
            controllerValueMap: {
              'fake@test.gov': {
                overrides: {
                  list: [{ title: 'seriously?', value: 'seriously?' }],
                  label: 'This component has been overridden for fake!'
                }
              },
              'real@test.gov': {
                overrides: {
                  list: [{ title: 'for real?', value: 'for real?' }],
                  label: 'This component has been overridden for real!'
                }
              },
            }
          }
        }
      },
      {
        componentType: 'input',
        props: {
          label: 'New Email',
          type: 'email',
          id: 'newEmail',
          required: true
        }
      },
      {
        componentType: 'div',
        props: {
          id: 'checkboxListInstructions',
          style: { fontSize: '0.8em', lineHeight: '1.33em', marginTop: '1.5em' }
        },
        children: (
          <p style={{ margin: '0' }}>
            <span>The following merchants have the same email address currently. </span>
            <span>Would you like to update any of these merchants as well? </span>
            <br />
            <span style={{ fontWeight: '800' }}>N.B. -</span>
            <span> Any merchants you don&apos;t update will remain associated </span>
            <span>with the current email address.</span>
          </p>)
      }
 * ];
 *
 *    <FormAssistant
 *      ref={this.formChild}
 *      callback={this.handleFormChange}
 *      id="emailForm"
 *      formComponents={formComponents} />
 */
export class FormAssistant extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { formComponents = [] } = props;
    this.timeout = undefined;
    this.nonFormFieldTypes = ['div', 'formSectionHeader', 'formCustomSection'];
    // Checks if there are any components that control visibility of other fields
    this.hasControls = formComponents?.some((comp) => !isEmpty(comp?.props?.controls));
    // Checks if there are any components that are controlled by other fields
    this.hasControlledBy = formComponents?.some((comp) => !isEmpty(comp?.props?.controlledBy));
    // Checks if there are any components that have `customOverrides`

    this.hasOverrides = formComponents?.some((comp) => !isEmpty(comp?.props?.customOverrides));
    this.originalComponents = this.renderComponents();
    this.components = this.renderComponents();
    this.state = {
      componentsChanged: false,
      formInProgress: false,
      validationActivated: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.initializeForm();
  }

  componentDidUpdate(prevProps, prevState) {
    const { componentsChanged } = this.state;
    const { allowEmpty } = this.props;
    /**
     * DO NOT USE !isEqual(prevProps.formComponents, formComponents) here
     * this will cause an infinite loop
     */
    if (prevState.componentsChanged !== componentsChanged && componentsChanged) {
      this.updateState({ componentsChanged: false });
    }
    if (prevProps.allowEmpty !== allowEmpty) {
      this.isFormValid();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  };

  /**
   * Call this method from the parent by accessing the ref of this component
   * to update selected values or valid state after initial component load.
   * This is necessary if the parent receives form data after its initial load
   * (e.g. when editing a form, etc)
   * @example this.ref.current.updateValues({...state})
   * @param {*} newState - should be a key/value pairs object like { id: selectedValue }
   */
  /**
   * If testing this in RTL, your tests *MAY* stall if the `newState` being passed into
   * `updateValues` has empty values.
   * eg, `this.ref.current.updateValues({ firstName: '' })`
   * It's unclear why this happens, but to resolve this, your test should update the field(s)
   * that get passed in `updateValues` FIRST, so they have a value.
   * eg, `await user.type(firstNameInput, 'name')` THEN do other form field updates.
   * Note: This is only an issue with tests, not in real usage
   */
  updateValues = (newState) =>
    Object.entries(newState).map(([key, value]) =>
      this.components.map((comp) => {
        if (comp?.props?.id === key) {
          switch (comp?.componentType) {
            case 'checkbox':
              this.handleCheckboxChange(key, value);
              break;
            case 'checkboxList':
              this.handleCheckboxListChange(value, key, null, null, !isEmpty(value));
              break;
            case 'dropdown':
            case 'combobox':
            case 'selectYearMonth':
            case 'rankedList': // initial set-up uses `handleDropdownChange`. if needed, move to its own case
              this.handleDropdownChange({ value, id: key, valid: !isEmpty(value) });
              break;
            case 'input':
              this.handleInputChange(key, value, !isEmpty(value));
              break;
            case 'radio':
              this.handleRadioChange(key, value, !isEmpty(value));
              break;
            default:
              break;
          }
        }
        return true;
      })
    );

  /**
   * Call this method from the parent by accessing the ref of this component
   * to update selected values or valid state after initial component load.
   * This is necessary if the parent receives form data after its initial load
   * (e.g. when editing a form, etc)
   * @example this.ref.current.forceValidation()
   */
  forceValidation = () => {
    this.updateState({ validationActivated: true }, this.isFormValid);
  };

  initializeForm = () => {
    const tempState = this.getTempState(this.components);
    this.updateState(tempState, this.isFormValid);
  };

  handleValueChange = (id, value, prevState) => {
    const currentComp = this.components?.find((c) => id === c?.props?.id) || {};
    if (this.hasOverrides) {
      // TODO BIRB-4355: make this work with original `controlledBy` methodology
      const currentCompControllerId = currentComp?.props?.controlledBy?.controllerId;
      const overrideComp = this.components?.find((c) => {
        const comp = currentCompControllerId === c?.props?.id;
        return comp;
      });
      if (!isEmpty(overrideComp && currentCompControllerId !== undefined)) {
        const controlledByValue = prevState[overrideComp?.props?.controlledBy?.controllerId];
        let propOverrides = {};
        const currentCompHasValue = valueExists(value, currentComp?.props);
        const overrideCompHasValue = valueExists(controlledByValue, overrideComp?.props);
        // Get original components props in order to override current components
        const originalCurrentComp = this.originalComponents?.find((c) => id === c?.props?.id) || {};
        const originalOverrideComp = this.originalComponents?.find((c) => {
          const comp = currentCompControllerId === c?.props?.id;
          return comp;
        });
        if (currentCompHasValue) {
          // current field is not empty
          propOverrides = {
            [currentComp.props.id]: { ...originalCurrentComp.props }, //
            [overrideComp.props.id]: {
              // set overrideComp overrides
              ...originalOverrideComp.props.controlledBy.overrides,
              ...(!isEmpty(originalOverrideComp.props.controlledBy.controllerValueMap) && {
                ...originalOverrideComp.props.controlledBy.controllerValueMap[controlledByValue]
                  .overrides
              })
            }
          };
        } else if (!currentCompHasValue && overrideCompHasValue) {
          // current value is empty but controlledBy value is not empty
          propOverrides = {
            [overrideComp.props.id]: { ...originalOverrideComp.props },
            [currentComp.props.id]: {
              // set current comp overrides
              ...originalCurrentComp.props.controlledBy.overrides,
              ...(!isEmpty(originalCurrentComp.props.controlledBy.controllerValueMap) && {
                ...originalCurrentComp.props.controlledBy.controllerValueMap[controlledByValue]
                  .overrides
              })
            }
          };
        } else if (
          (!currentCompHasValue && !overrideCompHasValue) || // both empty
          (currentCompHasValue && overrideCompHasValue)
        ) {
          // or, both NOT empty
          propOverrides = {
            // reset both props
            [overrideComp.props.id]: { ...originalOverrideComp.props },
            [currentComp.props.id]: { ...originalCurrentComp.props }
          };
        }
        this.components = this.renderComponents({ propOverrides });
      }
    }
    if (this.hasControls || this.hasControlledBy) {
      const controlComponent =
        this.components?.find((c) => {
          const controls = id === c?.props?.id && c?.props?.controls;
          return controls;
        }) || {};
      const controlledByCurrent = this.components?.filter(
        (c) => c?.props?.controlledBy?.controllerId === currentComp.props.id
      );
      const prevValue = prevState[controlComponent?.props?.id];
      const hasCurrentValue =
        !valueExists(prevValue, controlComponent?.props) && valueExists(value, currentComp?.props); // from empty to any value
      const hasNoValue =
        valueExists(prevValue, controlComponent?.props) && !valueExists(value, currentComp?.props); // from any value to empty
      const isDynamicControl =
        controlComponent?.props?.controls?.shouldAlwaysRender && (hasCurrentValue || hasNoValue); // Only rerender if value changed from none to any/vice versa
      // Only renderComponents if the `value` changed & is the control field
      if (
        ((!isEmpty(controlComponent) && !controlComponent?.props?.controls?.shouldAlwaysRender) ||
          (!isEmpty(controlComponent) && isDynamicControl) ||
          !isEmpty(controlledByCurrent)) &&
        // Deep equality check to account for array/object values
        JSON.stringify(prevState[id]) !== JSON.stringify(value)
      ) {
        const newComponents = this.components.reduce((acc, comp) => {
          const isToggleField = id === comp?.props?.toggledBy?.toggleId;
          const toggledByComp = this.originalComponents.find(
            (originalComp) => originalComp?.props?.id === comp?.props?.toggledBy?.toggleId
          );
          const parentFieldValue =
            toggledByComp?.componentType === 'checkboxList'
              ? this.state[comp?.props?.toggledBy?.toggleId]
              : undefined;
          const newParentComp =
            !isEmpty(parentFieldValue) &&
            !isEmpty(acc) &&
            acc.find((c) => comp?.props?.toggledBy?.toggleId === c?.props?.id);
          const hasHiddenParent = !isEmpty(newParentComp)
            ? newParentComp?.visible === false
            : false;
          let controlledByOverrides = {};
          const isControlled = controlledByCurrent.find((c) => c.props.id === comp.props.id);
          if (isControlled) {
            controlledByOverrides =
              isControlled.props?.controlledBy?.controllerValue === value
                ? isControlled.props?.controlledBy?.overrides || {}
                : isControlled.props?.controlledBy?.defaults || {};
          }
          const newComp = {
            ...comp,
            ...(isToggleField && {
              visible: `${value}` === `${comp?.props?.toggledBy?.toggleValue}`
            }),
            ...(parentFieldValue !== undefined && {
              visible: Array.isArray(parentFieldValue)
                ? !isEmpty(
                    parentFieldValue.find(
                      (item) => item?.[comp?.props?.toggledBy?.toggleValue] === true
                    )
                  )
                : !isEmpty(parentFieldValue)
            }),
            ...(hasHiddenParent && !isToggleField && { visible: !hasHiddenParent }),
            props: {
              ...comp.props,
              ...controlledByOverrides
            }
          };
          return acc.concat(newComp);
        }, []);
        this.components = newComponents;
        this.updateState({ componentsChanged: true });
      }
    }
    const controllerField =
      this.originalComponents.find(
        (comp) => !isEmpty(comp.props?.controllerStateOverrides) && comp.props?.id === id
      ) || {};
    const valueChangedData = getValueChangedData(value, id, currentComp);
    const formValidOptions = {
      hasControlledBy: this.hasControlledBy,
      valueChangedData
    };
    if (!isEmpty(controllerField)) {
      /**
       * For fields that use the `controlledBy` feature, the state does not get updated for the
       * controller field itself. If this is needed, then in the controller field helper, add a
       * `controllerStateOverrides` property with key/value pairs of
       * { controlledBy_field_id: controlledBy_field_new_state_value }
       */
      const resetStateValues = this.getControlStateValues({ value, prevState, controllerField });
      this.updateState({ ...resetStateValues }, () => this.isFormValid(formValidOptions));
    } else {
      this.isFormValid(formValidOptions);
    }
  };

  getBackendValue = (frontendValue, comp) => {
    const formField = !isEmpty(comp) ? { ...comp.props, componentType: comp.componentType } : {};
    return toBackendValue(frontendValue, formField);
  };

  // Handles setting state values for fields with controls when a field is updated
  getControlStateValues = (options) => {
    const { value, prevState, controllerField } = options || {};
    const { componentType, props } = controllerField || {};
    const { controllerStateOverrides, list, id } = props || {};
    const defaultOverrides = controllerStateOverrides?.[value] || {};
    let controlState = { ...defaultOverrides };
    if (componentType === 'checkboxList') {
      // Resetting state values for a checkboxList component
      const currentChecked = getCheckedItemKeys(value);
      const prevChecked = getCheckedItemKeys(prevState[id]);
      const checkboxOptions = list.map((item) => item.value);
      controlState = checkboxOptions.reduce((optionsAcc, controlKey) => {
        const controlOverrides = { ...controllerStateOverrides?.[controlKey] };
        let newStateOverrides = {};
        if (!isEmpty(controlOverrides)) {
          if (
            // Control was and is still checked
            currentChecked.includes(controlKey) &&
            prevChecked.includes(controlKey)
          ) {
            newStateOverrides = Object.entries(controlOverrides).reduce(
              (overrideAcc, [overrideKey, overrideValue]) => {
                const overrideComp =
                  this.originalComponents.find((c) => c?.props?.id === overrideKey) || {};
                const prevOverrideStateValue = prevState?.[overrideKey];
                return valueExists(prevOverrideStateValue, overrideComp?.props || {})
                  ? // If there's already a state value for the override component, don't reset it
                    overrideAcc
                  : { ...overrideAcc, ...overrideValue };
              },
              {}
            );
          } else if (
            // Control was checked, but is now unchecked
            !currentChecked.includes(controlKey) &&
            prevChecked.includes(controlKey)
          ) {
            newStateOverrides = Object.entries(controlOverrides).reduce(
              (overrideAcc, [overrideKey, overrideValue]) => {
                const overrideComp =
                  this.originalComponents.find((c) => c?.props?.id === overrideKey) || {};
                const currentOverrideStateValue = this.state?.[overrideKey];
                return {
                  ...overrideAcc,
                  ...(valueExists(currentOverrideStateValue, overrideComp?.props || {})
                    ? {
                        /**
                         * Control was previously checked but is now unchecked,
                         * (which should no longer show the control component as visible),
                         * reset its current property in state and isValid property.
                         */
                        [overrideKey]: overrideValue,
                        ...(overrideKey.includes('IsValid') && { [overrideKey]: !overrideValue })
                      }
                    : { ...overrideValue })
                };
              },
              {}
            );
          } else if (
            // Control was unchecked, but is now checked
            currentChecked.includes(controlKey) &&
            !prevChecked.includes(controlKey)
          ) {
            newStateOverrides = { ...controlOverrides };
          }
        }
        return { ...optionsAcc, ...newStateOverrides };
      }, {});
    }
    return controlState;
  };

  getTempState = (components) => {
    const tempState = components.reduce((acc, comp) => {
      const { initialValue, props } = comp || {};
      const { id, value, valid, required, controls, fieldType } = props || {};
      if (this.nonFormFieldTypes.includes(comp?.componentType) || comp.visible === false) {
        return acc;
      }
      const componentKey = id;
      const componentValue = !valueExists(value, props) ? initialValue : value;
      const isValidKey = `${id}IsValid`;
      const isValidValue = typeof valid === 'undefined' ? !required : valid;

      const hasInitValueOnly = valueExists(initialValue, props) && !valueExists(value, props);

      // If there are additional components to render, this will include their state too
      let additionalTempState = {};
      if (controls && (valueExists(initialValue, props) || valueExists(value, props))) {
        // To include fields that have `controls` fields with initial values, so the value populates
        // in the component in render
        const controlVal = valueExists(value, props) ? value : initialValue;
        const controlFields = controls?.shouldAlwaysRender || controls?.[controlVal];
        const controlsWithValues = controlFields?.some((c) =>
          valueExists(c?.initialValue, c?.props)
        );
        if (controlsWithValues) {
          additionalTempState = this.getTempState(controlFields);
        }
      }
      return {
        ...acc,
        [isValidKey]: isValidValue || comp.visible === false || fieldType === 'checkbox',
        ...(hasInitValueOnly && { [isValidKey]: isInitialValueValid(comp, componentValue) }),
        ...(valueExists(initialValue, props) && {
          [componentKey]: componentValue
        }),
        ...additionalTempState
      };
    }, {});
    return tempState;
  };

  renderComponents = (options) => {
    const { propOverrides = {} } = options || {};
    const { formComponents } = this.props;
    if (this.hasControls) {
      const newFormComponents = formComponents.reduce((formCompsAcc, comp) => {
        const currentComp = {
          ...comp,
          visible: true,
          props: {
            ...comp.props,
            ...(!isEmpty(propOverrides?.[comp?.props?.id]) && {
              ...propOverrides[comp.props.id]
            })
          }
        };

        if (!isEmpty(currentComp?.props?.controls)) {
          /**
         * Example component of a field that controls other fields' visibility:
            {
              componentType: 'dropdown',
              props: {
                id: 'returnPolicy',
                list: [
                  { title: 'None', value: 'none' },
                  { title: 'Under 30 Days', value: 'under30Days' }
                ],
                controls: {
                  none: [
                    {
                      componentType: 'input',
                      props: {
                        id: 'returnPolicyNoneReason'
                      }
                    }
                  ]
                }
              }
            }
         * This method will check if there are any new fields that should render based
         * on the dropdown selection. In this example, when 'none' is selected in the dropdown,
         * fields under `controls.none` will appear. If the dropdown selection changes to a field
         * that does NOT have a `controls` property (in this example, `under30Days`),
         * the component will just render as usual.
         */
          const newControllerComps = this.mergeControllerComponents({
            propOverrides,
            currentComp
          });
          return formCompsAcc.concat(currentComp, newControllerComps);
        }
        return formCompsAcc.concat(currentComp);
      }, []);
      return newFormComponents;
    }
    return formComponents;
  };

  mergeControllerComponents = (options) => {
    const { propOverrides, currentComp } = options || {};
    const newControllerComps = Object.entries(currentComp.props.controls).reduce(
      (controlCompsAcc, [controllerValue, controllerComps]) => {
        const newControlComps = controllerComps.reduce((currentControlAcc, controlComp) => {
          const hasControls = !isEmpty(controlComp?.props?.controls);
          const moreFields = hasControls
            ? this.mergeControllerComponents({ ...options, currentComp: controlComp })
            : [];
          const newControlField = {
            ...controlComp,
            visible: this.isControlCompVisibleOnLoad({ currentComp, controllerValue }),
            props: {
              ...controlComp.props,
              toggledBy: {
                // this field is toggled by
                toggleId: currentComp.props.id, // id of the field
                toggleValue: controllerValue // value that toggles the field
              },
              ...(!isEmpty(propOverrides?.[controlComp?.props?.id]) && {
                ...propOverrides[controlComp.props.id]
              })
            }
          };
          return currentControlAcc.concat(newControlField, ...moreFields);
        }, []);
        return controlCompsAcc.concat(newControlComps);
      },
      []
    );
    return newControllerComps;
  };

  isControlCompVisibleOnLoad = (options) => {
    const { currentComp, controllerValue } = options || {};
    const { componentType, initialValue, props } = currentComp || {};
    let isVisible = false;
    const isCheckboxList =
      componentType === 'checkboxList' || controllerValue === 'shouldAlwaysRender';
    const currentStateValue = this.state?.[props?.id];
    const currentValueIsEmpty = !valueExists(currentStateValue, props);
    if (isCheckboxList) {
      const isItemChecked = (v) => {
        const checkedKeys = getCheckedItemKeys(v);
        return checkedKeys.includes(controllerValue);
      };
      const hasInitialValue = valueExists(initialValue, props);
      isVisible = currentValueIsEmpty
        ? hasInitialValue && isItemChecked(initialValue)
        : !currentValueIsEmpty && isItemChecked(currentStateValue);
    } else {
      isVisible = currentValueIsEmpty
        ? `${initialValue}` === `${controllerValue}`
        : `${currentStateValue}` === `${controllerValue}`;
    }
    return isVisible;
  };

  isFormValid = (options) => {
    const { hasControlledBy } = options || {};
    const { allowEmpty, id } = this.props;
    const { validationActivated } = this.state;
    const formIsValid = this.components
      .filter(
        (comp) =>
          !this.nonFormFieldTypes.includes(comp?.componentType) &&
          comp?.componentType !== 'checkbox' &&
          comp.visible !== false
      )
      .every((comp) => {
        const required = comp?.props?.required;
        const empty = !valueExists(this.state[comp?.props?.id], comp?.props, { allowEmpty });
        const notRequiredAndEmpty = (!required && empty) || (allowEmpty && empty);
        const isValid =
          (validationActivated || hasControlledBy) && required
            ? !empty && this.state[`${comp?.props?.id}IsValid`]
            : notRequiredAndEmpty || this.state[`${comp?.props?.id}IsValid`];
        return isValid;
      });
    this.updateState(
      {
        [id]: formIsValid
      },
      () => this.handleCallbackTimeout(options)
    );
  };

  getBackendValues = () => {
    // Format FE values to BE to be used in transforms
    const backendValues = this.components
      .filter((comp) => !this.nonFormFieldTypes.includes(comp?.componentType))
      .reduce((acc, comp) => {
        const compId = comp?.props?.id;
        const valueToFormat = this.state[compId];
        const backendValue = this.getBackendValue(valueToFormat, comp);
        return { ...acc, [compId]: backendValue };
      }, {});
    return backendValues;
  };

  handleCallbackTimeout = (options) => {
    /**
     * Using a timeout to improve form performance when quickly typing in input/textarea fields,
     * specifically in crm/TicketForm, which is visually very slow in showing
     * updated input text when typing quickly or even at a normal speed.
     *
     * Ideally we handle timeouts in the callback of the Input component, but that has been
     * attempted & has proven to cause other issues. (see JIRA BIRB 6781, BIRB 6595)
     */
    this.timeout && clearTimeout(this.timeout);
    this.timeout = setTimeout(this.handleCallback, 200, options);
  };

  handleCallback = (options) => {
    const { valueChangedData } = options || {};
    const { callback, id } = this.props;
    const { componentChanged, ...restOfState } = this.state;
    const valuesForBackend = this.getBackendValues();
    const cbOptions = {
      ...(!isEmpty(valueChangedData) && { valueChangedData }),
      valuesForBackend
    };
    callback && callback(restOfState, id, cbOptions);
  };

  handleCheckboxChange = (id, checked) => {
    const prevStateCopy = { ...this.state };
    this.updateState(
      {
        [id]: checked,
        [`${id}IsValid`]: true,
        formInProgress: true
      },
      () => this.handleValueChange(id, checked, prevStateCopy)
    );
  };

  handleCheckboxListChange = (list, id, checkedMap, fileCounter, valid) => {
    const prevStateCopy = { ...this.state };
    this.updateState(
      {
        [id]: list,
        [`${id}IsValid`]: valid,
        formInProgress: true
      },
      () => this.handleValueChange(id, list, prevStateCopy)
    );
  };

  handleDropdownChange = ({ value, id, valid }) => {
    const prevStateCopy = { ...this.state };
    this.updateState(
      {
        [id]: value,
        [`${id}IsValid`]: valid,
        formInProgress: true
      },
      () => this.handleValueChange(id, value, prevStateCopy)
    );
  };

  handleAddressAutocompleteChange = (options) => {
    const { id, value, valid } = options || {};
    const prevStateCopy = { ...this.state };
    const { valuesForBackend: newValuesForBackend } = value || {};
    const { valuesForBackend: prevValuesForBackend } = prevStateCopy[id] || {};
    /**
     * On load, if there are initial values (eg, country), `prevValuesForBackend` keys
     * will only include the key/value pairs with data. this may cause a mismatch
     * with `newValuesForBackend` object's key/values, which sends back ALL data keys,
     * with null values if empty
     */
    const prevKeys = !isEmpty(prevValuesForBackend) ? Object.keys(prevValuesForBackend) : [];
    const newKeys = !isEmpty(newValuesForBackend) ? Object.keys(newValuesForBackend) : [];
    const sameKeys = isEqual(newKeys, prevKeys);
    const nextValuesForBackendDataOnly = !isEmpty(newValuesForBackend)
      ? // Only include key/values with data
        Object.entries(newValuesForBackend).reduce(
          (acc, [key, val]) => ({
            ...acc,
            ...(dataExists(val) && { [key]: val })
          }),
          {}
        )
      : {};
    if (
      typeof prevValuesForBackend === 'undefined' ||
      !isEqual(
        prevValuesForBackend,
        // if keys ARE the same, compare the entire `newValuesForBackend` object
        // if keys AREN'T the same, compare only values that exist in `newValuesForBackend`
        sameKeys ? newValuesForBackend : nextValuesForBackendDataOnly
      )
    ) {
      this.updateState(
        {
          [id]: value,
          [`${id}IsValid`]: valid,
          ...(typeof prevValuesForBackend !== 'undefined' && { formInProgress: true })
        },
        () => this.handleValueChange(id, value, prevStateCopy)
      );
    }
  };

  handleInputChange = (id, value, valid) => {
    const prevStateCopy = { ...this.state };
    this.updateState(
      {
        [id]: value,
        [`${id}IsValid`]: valid,
        formInProgress: true
      },
      () => this.handleValueChange(id, value, prevStateCopy)
    );
  };

  handleRadioChange = (id, value, checked) => {
    const prevStateCopy = { ...this.state };
    this.updateState(
      {
        [id]: value,
        [`${id}IsValid`]: checked,
        formInProgress: true
      },
      () => this.handleValueChange(id, value, prevStateCopy)
    );
  };

  render() {
    const { ariaLabel, id, wrapperStyle, componentLabelInside, validateFields } = this.props;
    const { validationActivated } = this.state;
    return (
      <div
        id={id}
        role="article"
        aria-label={ariaLabel}
        style={{
          ...(componentLabelInside && {
            display: 'flex',
            flexWrap: 'wrap',
            ...formStylesCSS.formContentWrapper
          }),
          ...wrapperStyle
        }}>
        {this.components
          .filter((comp) => comp.visible !== false)
          .map((comp) => {
            if (isEmpty(comp?.props?.id) || isEmpty(comp?.componentType)) {
              return (
                <div
                  key={`badComponent-${Math.random()}`}
                  style={{ color: 'var(--color-warning)' }}>
                  {`Missing componentType (${comp?.componentType}) or id (${comp?.props?.id})`}
                </div>
              );
            }
            const compType = comp?.componentType?.toLowerCase();
            const requiredProp = {
              // by default is true if required prop is not defined in component props
              required: isBool(comp.props.required) ? comp.props.required : true
            };
            const checkboxValueConverted =
              isBool(this.state[comp.props.id]) && (this.state[comp.props.id] ? 'yes' : 'no');
            const controllerValue = this.state[comp.props?.controlledBy?.controllerId];
            const componentOverrides = {
              ...(comp.props.customOverrides !== true &&
                comp.props.controlledBy && {
                  ...(controllerValue === comp.props.controlledBy?.controllerValue && {
                    ...comp.props.controlledBy?.overrides
                  }),
                  ...(!isEmpty(comp.props.controlledBy?.controllerValueMap) && {
                    ...comp.props.controlledBy.controllerValueMap[controllerValue]?.overrides
                  })
                }),
              ...((validationActivated || validateFields) &&
                comp.props.notRequiredOnEditIfEmpty && {
                  required: !isEmpty(this.state[comp.props.id])
                })
            };
            const customDropdownProps = {
              ...(componentLabelInside && {
                ...globalFormProps.dropdown,
                ...requiredProp,
                formField: comp.props && isBool(comp.props.formField) ? comp.props.formField : true,
                wrapperStyle: {
                  ...globalFormProps.dropdown.wrapperStyle,
                  ...(comp.props && comp.props.wrapperStyle && { ...comp.props.wrapperStyle }),
                  margin: '-1px'
                }
              })
            };
            const customInputProps = {
              ...(componentLabelInside && {
                ...globalFormProps.input,
                ...requiredProp,
                ...(comp.props &&
                  comp.props.type === 'textarea' && {
                    infoTipDisplay: {
                      margin: '0 5px'
                    }
                  }),
                wrapperStyle: {
                  ...globalFormProps.input.wrapperStyle,
                  ...(comp.props && comp.props.wrapperStyle && { ...comp.props.wrapperStyle }),
                  margin: '-1px'
                }
              })
            };
            switch (compType) {
              // Use componentType = 'div' to pass in section headers, descriptive text, etc
              case 'div':
                return (
                  <div key={comp.props.id} id={comp.props.id} style={comp.props.style}>
                    {!isEmpty(comp.children) ? comp.children : ''}
                  </div>
                );
              case 'formsectionheader':
                return (
                  <FormSectionHeader
                    key={`${comp.props.id}-${Math.random()}`}
                    {...comp.props}
                    required={false}
                  />
                );
              case 'formcustomsection':
                return (
                  <FormCustomSection
                    key={`${comp.props.id}-${Math.random()}`}
                    type="formWithSections"
                    {...comp.props}
                    wrapperStyle={{
                      borderTop: '1px solid var(--color-text)',
                      fontSize: '1.3rem',
                      padding: '0.3em',
                      lineHeight: '1.7',
                      ...comp.props?.wrapperStyle
                    }}
                    required={false}>
                    {comp.children}
                  </FormCustomSection>
                );
              case 'addressautocomplete': // Set componentType, fieldType to 'addressAutocomplete'`
                return (
                  <AddressAutocomplete
                    key={comp.props?.id}
                    {...comp.props}
                    {...requiredProp}
                    callback={this.handleAddressAutocompleteChange}
                    data={this.state[comp.props.id]}
                    compOverrides={{ dropdown: customDropdownProps, input: customInputProps }}
                    componentLabelInside={componentLabelInside}
                  />
                );
              case 'checkbox':
                return (
                  <Checkbox
                    key={comp.props?.id}
                    callback={this.handleCheckboxChange}
                    {...comp.props}
                    checked={
                      isBool(this.state[comp.props.id])
                        ? this.state[comp.props.id]
                        : this.state[comp.props.id] === 'yes'
                    }
                    value={
                      !isBool(this.state[comp.props.id])
                        ? this.state[comp.props.id]
                        : checkboxValueConverted
                    }
                    {...(!isEmpty(componentOverrides) && componentOverrides)}
                    validationActivated={validationActivated || validateFields}
                  />
                );
              case 'checkboxlist':
                // Please note that this check is converted toLowerCase, and we SHOULD still
                // pass in checkboxList when defining the componentType
                return (
                  <CheckboxList
                    key={comp.props?.id}
                    callback={this.handleCheckboxListChange}
                    checkedItems={this.state[comp.props.id]}
                    {...comp.props}
                    {...(componentLabelInside && {
                      ...globalFormProps.checkboxList,
                      ...requiredProp,
                      containerStyle: {
                        ...globalFormProps.checkboxList.containerStyle,
                        ...(comp.props.containerStyle && { ...comp.props.containerStyle })
                      },
                      wrapperStyle: {
                        ...globalFormProps.checkboxList.wrapperStyle,
                        ...(comp.props.wrapperStyle && { ...comp.props.wrapperStyle })
                      },
                      checkboxStyle: {
                        ...globalFormProps.checkbox
                      }
                    })}
                    {...(!isEmpty(componentOverrides) && componentOverrides)}
                    validationActivated={validationActivated || validateFields}
                  />
                );
              case 'combobox':
              case 'dropdown':
                return (
                  <ComboBox
                    key={comp.props?.id}
                    callback={this.handleDropdownChange}
                    selected={this.state[comp.props.id]}
                    type="formDropdown"
                    {...comp.props}
                    {...requiredProp}
                    {...customDropdownProps}
                    {...(!isEmpty(componentOverrides) && componentOverrides)}
                    validationActivated={validationActivated || validateFields}
                  />
                );
              case 'selectyearmonth':
                return (
                  <ComboBoxSelectMonthYear
                    key={comp.props?.id}
                    callback={this.handleDropdownChange}
                    value={this.state[comp.props.id]}
                    {...comp.props}
                    {...requiredProp}
                    {...(componentLabelInside && {
                      ...globalFormProps.dropdown,
                      ...requiredProp,
                      wrapperStyle: {
                        ...(comp.props.wrapperStyle && { ...comp.props.wrapperStyle }),
                        margin: '-1px'
                      }
                    })}
                    {...(!isEmpty(componentOverrides) && componentOverrides)}
                    validationActivated={validationActivated || validateFields}
                  />
                );
              case 'input':
                return (
                  <Input
                    key={comp.props?.id}
                    callback={this.handleInputChange}
                    value={this.state[comp.props.id]}
                    {...comp.props}
                    {...customInputProps}
                    {...(!isEmpty(componentOverrides) && componentOverrides)}
                    validationActivated={validationActivated || validateFields}
                  />
                );
              case 'radio':
                return (
                  <Radio
                    key={comp.props?.id}
                    callback={this.handleRadioChange}
                    selected={this.state[comp.props.id]}
                    {...comp.props}
                    {...(componentLabelInside && {
                      ...globalFormProps.radio,
                      ...requiredProp,
                      ...(comp.props.tooltip && { infoTipDisplay: { margin: '0 5px' } }),
                      wrapperStyle: {
                        ...globalFormProps.radio.wrapperStyle,
                        ...(comp.props.wrapperStyle && { ...comp.props.wrapperStyle }),
                        margin: '-1px'
                      }
                    })}
                    {...(!isEmpty(componentOverrides) && componentOverrides)}
                    validationActivated={validationActivated || validateFields}
                  />
                );
              case 'rankedlist':
                return (
                  <FormDragAndDropRankedList
                    key={comp.props?.id}
                    value={this.state[comp.props.id]}
                    // initial set-up uses `handleDropdownChange`. if needed, move to its own case
                    callback={this.handleDropdownChange}
                    {...comp.props}
                    {...requiredProp}
                    {...(componentLabelInside && {
                      boxStyle: 'inside',
                      ...requiredProp,
                      wrapperStyle: {
                        ...(comp.props.wrapperStyle && { ...comp.props.wrapperStyle }),
                        flex: '100%',
                        margin: '0 -1px'
                      }
                    })}
                    {...(!isEmpty(componentOverrides) && componentOverrides)}
                    validationActivated={validationActivated || validateFields}
                  />
                );
              default:
                return (
                  <div key={`defaultComponent-${Math.random()}`}>
                    unrecognized component passed to FormAssistant
                  </div>
                );
            }
          })}
      </div>
    );
  }
}

FormAssistant.propTypes = {
  allowEmpty: PropTypes.bool,
  callback: PropTypes.func,
  componentLabelInside: PropTypes.bool,
  formComponents: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  validateFields: PropTypes.bool
};

FormAssistant.defaultProps = {
  allowEmpty: false,
  callback: () => {},
  componentLabelInside: false,
  formComponents: [],
  id: '',
  ariaLabel: null,
  wrapperStyle: {},
  validateFields: false
};

export default FormAssistant;
