import { ignoreCase, sortData } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockEmployeesDetails } from '../employee/mockEmployees';
import { residualTaskGuids } from '../guids';

export const mockV2TaskPutRes = { residualTaskId: residualTaskGuids.default };

export const mockV2TaskGetRes = (body, params, _options) => {
  const { yearMonth } = params || {};
  if (yearMonth === '2022-01') {
    return {
      residualTaskList: [],
      pagingInfo: {
        totalNumberOfRecords: 0,
        pageIndex: 0,
        pageSize: 1
      }
    };
  }
  const mockData = {
    pagingInfo: {
      totalNumberOfRecords: 12,
      pageIndex: 0,
      pageSize: 3000
    },
    residualTaskList: getResidualTaskList(params)
  };
  return mockData;
};

export const getResidualTaskList = (params) => {
  const { yearMonth } = params || {};
  const mockReportingYearMonth = yearMonth || '2020-07';
  const mockResidualEmployees = Object.values(mockEmployeesDetails)
    .filter((item) =>
      (item.groups || []).some((g) => ignoreCase(g.employeeGroupName) === 'residual')
    )
    .map((item) => ({
      ...item,
      ...item.employee,
      title: `${item.employee.firstName} ${item.employee.lastName}`,
      value: item.employee.employeeId
    }));
  const getMockEmployeeWhoTriggered = () => {
    const mockEmployeeId = getMockValue({ type: 'list', list: mockResidualEmployees });
    const mockEmployeeMatch = mockResidualEmployees.find((emp) => emp.value === mockEmployeeId);
    return {
      employeeId: mockEmployeeMatch.employeeId,
      firstName: mockEmployeeMatch.firstName,
      lastName: mockEmployeeMatch.lastName,
      email: mockEmployeeMatch.email
    };
  };
  const mockTaskList = [
    {
      residualTaskId: residualTaskGuids.default,
      residualTaskType: 'find_missing_charge_types',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask01,
      residualTaskType: 'find_missing_charge_types',
      residualTaskStatus: 'complete',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask02,
      residualTaskType: 'find_missing_charge_types',
      residualTaskStatus: 'failed',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask03,
      residualTaskType: 'find_missing_mids_and_mpas',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask04,
      residualTaskType: 'find_missing_mids_and_mpas',
      residualTaskStatus: 'complete',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask05,
      residualTaskType: 'find_missing_mids_and_mpas',
      residualTaskStatus: 'failed',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask06,
      residualTaskType: 'find_missing_schedule_a_content',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask07,
      residualTaskType: 'create_preparation_files',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask08,
      residualTaskType: 'create_standard_input_file',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask09,
      residualTaskType: 'compute_residual_calculation',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask10,
      residualTaskType: 'compute_bank_validation_calculation',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask11,
      residualTaskType: 'generate_partner_reports',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    },
    {
      residualTaskId: residualTaskGuids.residualTask12,
      residualTaskType: 'upload_schedule_a',
      residualTaskStatus: 'in_progress',
      reportingYearMonth: mockReportingYearMonth,
      residualTaskStartTimestamp: getMockValue({ type: 'isoDate' }),
      residualTaskCompletionTimestamp: getMockValue({ type: 'isoDate' }),
      employeeWhoTriggered: getMockEmployeeWhoTriggered()
    }
  ];
  return sortData(mockTaskList, 'residualTaskStartTimestamp', { direction: 'desc' });
};
