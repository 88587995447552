export const mockProcessingHistory = {
  settlementHistory: {
    salesVolume: {
      dataType: 'dollar',
      yesterday: 11000,
      lastSevenDays: 77000,
      monthToDate: 81000,
      previousMonth: 121000,
      twoMonthsAgo: 143000,
      threeMonthsAgo: 211000,
      fourMonthsAgo: 97000,
      fiveMonthsAgo: 168000,
      sixMonthsAgo: 66000
    },
    averageTicket: {
      dataType: 'dollar',
      yesterday: 11000,
      lastSevenDays: 7700,
      monthToDate: 8100,
      previousMonth: 12100,
      twoMonthsAgo: 14300,
      threeMonthsAgo: 21100,
      fourMonthsAgo: 9700,
      fiveMonthsAgo: 16800,
      sixMonthsAgo: 6600
    },
    refundVolume: {
      dataType: 'dollar',
      yesterday: 1000,
      lastSevenDays: 7000,
      monthToDate: 1000,
      previousMonth: 21000,
      twoMonthsAgo: 43000,
      threeMonthsAgo: 11000,
      fourMonthsAgo: 7000,
      fiveMonthsAgo: 68000,
      sixMonthsAgo: 6000
    },
    refundVolumeRatio: {
      dataType: 'ratio',
      yesterday: 0.1,
      lastSevenDays: 0.1,
      monthToDate: 0.1,
      previousMonth: 0.1,
      twoMonthsAgo: 0.1,
      threeMonthsAgo: 0.1,
      fourMonthsAgo: 0.0001,
      fiveMonthsAgo: 0.0,
      sixMonthsAgo: 0.0
    },
    salesCount: {
      dataType: 'number',
      yesterday: 4000,
      lastSevenDays: 10000,
      monthToDate: 9000,
      previousMonth: 11000,
      twoMonthsAgo: 12000,
      threeMonthsAgo: 18000,
      fourMonthsAgo: 6500,
      fiveMonthsAgo: 16000,
      sixMonthsAgo: 8000
    },
    refundsCount: {
      dataType: 'number',
      yesterday: 100,
      lastSevenDays: 700,
      monthToDate: 100,
      previousMonth: 2100,
      twoMonthsAgo: 4300,
      threeMonthsAgo: 1100,
      fourMonthsAgo: 700,
      fiveMonthsAgo: 6800,
      sixMonthsAgo: 600
    }
  }
};

export const mockProcessingHistoryGetRes = (body, params, options) => {
  const { guid = ['empty'] } = options;
  return {
    [guid]: mockProcessingHistory
  };
};

export default mockProcessingHistory;
