import { pageSettingsKeys, globalSettingsKeys } from '@f1/shared/src/_customSettings';

// In meco, go to /designs/custom-settings to see expected JSON structure

// Key/value pairs - the value is the top-level data key for api call
// DO NOT EXPORT THIS. Use `mecoPageKeys` instead to get a global mapping.
const pageKeys = {
  // Page-specific. Add any new page keys here
  dashboardPage: 'dashboardPage',
  merchantPage: 'merchantPage',
  relationshipPage: 'relationshipPage',
  partnerPage: 'partnerPage',
  accountPage: 'accountPage',
  itPage: 'itPage',
  supportPage: 'supportPage',
  ticketsPage: 'ticketsPage',
  crabApplicationPage: 'crabApplicationPage',
  crabApplicationConfigPage: 'crabApplicationConfigPage',
  crabPendReportPage: 'crabPendReportPage',
  crabPrevetPage: 'crabPrevetPage',
  matchPage: 'matchPage',
  stickyPage: 'stickyPage',
  repayPage: 'repayPage',
  prohibitedEntitiesPage: 'prohibitedEntitiesPage',
  riskDashboardPage: 'riskDashboardPage',
  riskRulesPage: 'riskRulesPage',
  riskBinStatusPage: 'riskBinStatusPage',
  riskRepayTransactionHoldPage: 'riskRepayTransactionHoldPage',
  reportReservesPage: 'reportReservesPage',
  reportBatchDetailsPage: 'reportBatchDetailsPage',
  actionSuggestedPage: 'actionSuggestedPage',
  achReportPage: 'achReportPage',
  trainingPage: 'trainingPage',
  residualsV1Page: 'residualsV1Page',
  residualsV2Page: 'residualsV2Page',
  residualsV3Page: 'residualsV3Page',
  nachaPage: 'nachaPage',
  profitabilityPage: 'profitabilityPage',
  repayMidListPage: 'repayMidListPage',
  merchantLedgerPage: 'merchantLedgerPage'
};

export const mecoPageKeys = { ...globalSettingsKeys, ...pageKeys };

const pageKeyValues = Object.values(pageKeys).sort();

export const mecoSettingsStructure = {
  [mecoPageKeys.globalPreferences]: {
    // Set any defaults here
    theme: 'light' // One of ['light', 'dark']
  },
  ...pageKeyValues.reduce(
    (pageKeysAcc, pageKey) => ({
      ...pageKeysAcc,
      [pageKey]: Object.values(pageSettingsKeys).reduce(
        (settingsAcc, settingObj) => ({
          ...settingsAcc,
          [settingObj.name]: settingObj.dataTypeValue
        }),
        {}
      )
    }),
    {}
  )
};
