import React from 'react';
import { PropTypes } from 'prop-types';
import { AlertBar, Spinner, Button, FormAssistant } from '../../../index';
import {
  ignoreCase,
  sortData,
  isEqual,
  sharedGetInnerAlertBarState,
  endpoint,
  transformData,
  isEmpty
} from '../../_helpers';
import { crabFileTagsPost } from '../../_crabFields';
import filesWithTagsTemplate, {
  sortSelectedTags
} from '../../data/sharedBoarding/templates/filesWithTagsTemplate';
import { ErrorBox } from '../../../css/_styledFormComponents';

export class EditFileForm extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { userType, file } = props;
    const { name = '' } = file || {};
    this.availableTagList = sortData(
      crabFileTagsPost.tagList.list.filter((t) => t.visible !== false && !t.isFixed),
      'title'
    );
    this.systemGeneratedTags = crabFileTagsPost.tagList.list
      .filter((t) => t.visible === false || t.isFixed === true)
      .reduce(
        (acc, t) =>
          acc.concat(
            ignoreCase(t.title),
            ignoreCase(t.title.replace(/[-]/g, ' ')),
            ignoreCase(t.value),
            ignoreCase(t.value.replace(/[_]/g, ' '))
          ),
        []
      );
    this.uploadedByUserTags = ['uploaded_by_employee', 'uploaded_by_partner', 'uploaded_by_system'];
    this.fileExtension = name.split('.').pop();
    this.fileNameOnly = name.split(`.${this.fileExtension}`).join('');
    this.formId = 'editFileForm';
    this.state = {
      tagsMissing: false, // true if file only has "uploaded_by_<userType>" tag
      fileNameChangeSuccess: false,
      tagListChangeSuccess: false,
      spinnerLoading: false,
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true
    };
    this.sortedSelectedTags = sortSelectedTags(file.tagList, { showFixedFirst: true, userType });
    this.formComponents = [
      {
        componentType: 'input',
        initialValue: this.fileNameOnly,
        props: {
          label: 'File Name',
          type: 'text',
          id: 'newFileName',
          wrapperStyle: { flex: 1 },
          required: true
        }
      },
      {
        componentType: 'div',
        props: {
          id: 'fileExtension',
          style: { height: '40px', margin: '24px 0 0 3px', paddingTop: '5px' }
        },
        children: <span>{`.${this.fileExtension}`}</span>
      },
      {
        componentType: 'combobox',
        initialValue: this.sortedSelectedTags,
        props: {
          label: 'Tags',
          ...crabFileTagsPost.tagList,
          ...(!isEmpty(this.sortedSelectedTags) && { selected: this.sortedSelectedTags }),
          list: this.availableTagList,
          useTagColors: userType === 'employee',
          required: userType === 'employee',
          wrapperStyle: { flex: '100%', minHeight: '40px' }
        }
      }
    ];
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  handleFormChange = (newFormState, id, options) => {
    const { [id]: currentSectionState = {} } = this.state;
    const sectionChanged = !isEqual(currentSectionState, newFormState);
    if (sectionChanged) {
      const { valuesForBackend } = options || {};
      const { tagList = [] } = valuesForBackend || {};
      const notAddingSystemTags = !isEmpty(tagList) ? this.tagsValid(tagList) : true;
      if (notAddingSystemTags) {
        const onlyUploadedByUserTag =
          (tagList || []).length === 1 &&
          (tagList || []).some(
            (tagItem) => !isEmpty(tagItem) && this.uploadedByUserTags.includes(tagItem.value)
          );
        this.updateState((prevState) => ({
          ...prevState[id],
          ...newFormState,
          // If form is not in progress (on mount), disable submit
          [id]: !newFormState.formInProgress
            ? false
            : (newFormState[id] && !onlyUploadedByUserTag) || false,
          tagsMissing: onlyUploadedByUserTag || false,
          ...(valuesForBackend && { valuesForBackend })
        }));
      } else {
        this.updateState(
          {
            [id]: false // disable submit
          },
          () =>
            this.handleError(
              {},
              {
                customMessage:
                  'Sorry, system-generated tags cannot be added. Please update your tags and try again.'
              }
            )
        );
      }
    }
  };

  tagsValid = (addedTagList = []) => {
    const { file: originalFile } = this.props;
    const { tagList: originalTags = [] } = originalFile || {};
    const newTags = !isEmpty(addedTagList)
      ? addedTagList.filter((addedTag) => {
          const match = !isEmpty(originalTags)
            ? originalTags.find(
                (originalTag) =>
                  ignoreCase(originalTag.value) === ignoreCase(addedTag.value) ||
                  ignoreCase(originalTag.title) === ignoreCase(addedTag.label)
              )
            : null;
          return isEmpty(match);
        })
      : [];
    const isValid = newTags.every(
      (addedTag) => !this.systemGeneratedTags.includes(ignoreCase(addedTag.label))
    );
    return isValid;
  };

  didFileNameChange = () => {
    const { valuesForBackend } = this.state;
    return valuesForBackend?.newFileName?.trim() !== this.fileNameOnly;
  };

  didTagsChange = () => {
    const { valuesForBackend } = this.state;
    const { file: originalFile } = this.props;
    const originalTagValues = originalFile?.tagList?.map((t) => t?.value) || [];
    const newTagValues = valuesForBackend?.tagList?.map((t) => t?.value) || [];
    if (isEmpty(originalTagValues) && isEmpty(newTagValues)) {
      return false;
    }
    if (originalTagValues.length !== newTagValues.length) {
      return true;
    }
    const tagListChanged = !isEmpty(originalTagValues)
      ? !originalTagValues.every((originalTagValue) => newTagValues.includes(originalTagValue))
      : !isEmpty(valuesForBackend?.tagList);
    return tagListChanged;
  };

  handleSubmit = () => {
    const fileNameChanged = this.didFileNameChange();
    const tagsChanged = this.didTagsChange();
    if (tagsChanged) {
      // If tags and file name changed, update tags first
      this.handleTagListChange({ fileNameChanged });
    } else if (fileNameChanged) {
      this.handleFileNameChange();
    } else {
      // TODO: BIRB-8338 - remove ignore
      /* istanbul ignore next */
      this.handleError({}, { customMessage: 'Please make a change to submit.' });
    }
  };

  handleFileNameChange = async () => {
    const { file, axiosRequest, requestGuid } = this.props;
    const { valuesForBackend, fileNameChangeSuccess } = this.state;
    if (!isEmpty(requestGuid)) {
      if (!fileNameChangeSuccess) {
        this.updateState({ spinnerLoading: true });
        const requestBody = transformData({
          data: {
            existingFileName: file.name,
            newFileName: `${valuesForBackend?.newFileName?.trim()}.${this.fileExtension}`
          },
          toSchema: 'backendPost',
          template: filesWithTagsTemplate,
          version: '1.0'
        });
        const fileSentGuid = transformData({
          data: { ...file },
          toSchema: 'backendGetGuid',
          template: filesWithTagsTemplate,
          version: '1.0'
        });
        const requestOptions = {
          fullPageLoad: false,
          method: 'post',
          url: `${endpoint.file.v3.root}`,
          ...(isEmpty(fileSentGuid) ? { requestGuid } : { requestGuid: fileSentGuid })
        };
        const apiRes = await axiosRequest(requestOptions, requestBody);
        this.updateState({ ...apiRes?.state });
        if (apiRes?.errorDetails instanceof Error) {
          const { status } = apiRes?.state || {};
          const errorMap = {
            409: 'Sorry, that file name already exists. Please update the file name try again.'
          };
          const errorOptions = {
            type: 'fileName',
            ...(errorMap[status] && { customMessage: errorMap[status] })
          };
          this.updateState(
            {
              fileNameChangeSuccess: false
            },
            () => this.handleError(apiRes.errorDetails, errorOptions)
          );
        } else {
          this.updateState({ fileNameChangeSuccess: true }, this.handleSuccess);
        }
      } else {
        // TODO: BIRB-8338 - remove ignore
        /* istanbul ignore next */
        this.handleSuccess();
      }
    } else {
      this.handleError({}, { customMessage: 'Missing request ID' });
    }
  };

  handleTagListChange = async (options) => {
    const { fileNameChanged } = options || {};
    const { file, axiosRequest, requestGuid } = this.props;
    const { valuesForBackend, tagListChangeSuccess } = this.state;
    if (!isEmpty(requestGuid)) {
      if (!tagListChangeSuccess) {
        this.updateState({ spinnerLoading: true });
        const requestBody = transformData({
          data: {
            fileName: file.name, // original file name
            tagList: valuesForBackend?.tagList,
            originalTags: file?.originalTags || []
          },
          toSchema: 'backendEditTagList',
          template: filesWithTagsTemplate,
          version: '1.0'
        });
        const fileSentGuid = transformData({
          data: { ...file },
          toSchema: 'backendGetGuid',
          template: filesWithTagsTemplate,
          version: '1.0'
        });
        const requestOptions = {
          fullPageLoad: false,
          method: 'post',
          url: `${endpoint.file.v3.tags}`,
          ...(isEmpty(fileSentGuid) ? { requestGuid } : { requestGuid: fileSentGuid })
        };
        const apiRes = await axiosRequest(requestOptions, requestBody);
        this.updateState({ ...apiRes?.state });
        if (apiRes?.errorDetails instanceof Error) {
          const { status } = apiRes?.state || {};
          const errorMap = {
            409: 'Sorry, no duplicate tags allowed. Please update your tags try again.',
            412: 'Sorry, system-generated tags cannot be changed. Please update your tags and try again.'
          };
          const errorOptions = {
            type: 'tagList',
            ...(errorMap[status] && { customMessage: errorMap[status] })
          };
          this.updateState(
            {
              tagListChangeSuccess: false
            },
            () => this.handleError(apiRes.errorDetails, errorOptions)
          );
        } else {
          this.updateState(
            {
              tagListChangeSuccess: true
            },
            fileNameChanged ? this.handleFileNameChange : this.handleSuccess
          );
        }
      } else if (fileNameChanged) {
        // tagList change was already a success
        this.handleFileNameChange();
      }
    } else {
      this.handleError({}, { customMessage: 'Missing request ID' });
    }
  };

  handleError = (apiErrorDetails, options) => {
    const { type, customMessage } = options || {};
    const { axiosRequest } = this.props;
    const alertBarState = sharedGetInnerAlertBarState({
      type: 'warning',
      data: apiErrorDetails,
      axiosRequest
    });
    this.updateState({
      ...alertBarState,
      alertBarMessage:
        customMessage ||
        `Error updating ${type === 'tagList' ? 'tags' : 'file name'}: ${alertBarState.alertBarMessage}`
    });
  };

  handleSuccess = () => {
    const { valuesForBackend } = this.state;
    const { callback, file, userType } = this.props;
    const tagsChanged = this.didTagsChange();
    const fileNameChanged = this.didFileNameChange();
    const newFileName = fileNameChanged
      ? `${valuesForBackend?.newFileName?.trim()}.${this.fileExtension}`
      : '';
    const newTagList = tagsChanged
      ? sortSelectedTags(
          valuesForBackend?.tagList?.map((tagItem) => ({ ...tagItem, title: tagItem.label })) || [],
          { userType }
        )
      : [];
    const cbOptions = {
      tagsChanged,
      fileNameChanged,
      originalFileName: file.name,
      originalTagList: file.tagList || [],
      ...(fileNameChanged && { newFileName }),
      ...(tagsChanged && { newTagList }),
      updatedFile: {
        ...file,
        originalFileName: file.name,
        ...(fileNameChanged && { displayName: newFileName, name: newFileName }),
        ...(tagsChanged && { tagList: newTagList })
      }
    };
    callback && callback(cbOptions);
  };

  render() {
    const {
      [this.formId]: formValid,
      tagsMissing,
      spinnerLoading,
      alertBarType,
      alertBarMessage,
      alertBarTimeout
    } = this.state;
    const { userType } = this.props;
    const alertBarOptions = {
      barStyle: alertBarType,
      message: alertBarMessage,
      timeout: alertBarTimeout,
      customWarnStyle: { width: '100%', height: '100%' }
    };
    return (
      <div
        id={this.formId}
        style={{
          minHeight: '55vh',
          position: 'relative',
          width: '100%',
          padding: '0 1em'
        }}>
        <AlertBar options={alertBarOptions} callback={this.updateState} useInnerAlertBar />
        <Spinner loading={spinnerLoading} />
        <FormAssistant
          id={this.formId}
          formComponents={this.formComponents}
          callback={this.handleFormChange}
          wrapperStyle={{ display: 'flex', flexWrap: 'wrap', marginBottom: '1em' }}
          validateFields={userType === 'employee'}
        />
        <ErrorBox $error={tagsMissing} style={{ width: 'auto' }}>
          Please add at least one non-system tag
        </ErrorBox>
        <Button
          id="submit"
          onClick={this.handleSubmit}
          disabled={!formValid}
          style={{ margin: '20px 0', flex: 1 }}>
          Submit
        </Button>
      </div>
    );
  }
}

EditFileForm.propTypes = {
  userType: PropTypes.string,
  requestGuid: PropTypes.oneOfType([PropTypes.object]),
  axiosRequest: PropTypes.func,
  callback: PropTypes.func,
  file: PropTypes.shape({
    name: PropTypes.string,
    originalTags: PropTypes.oneOfType([PropTypes.array]),
    tagList: PropTypes.oneOfType([PropTypes.array])
  })
};

EditFileForm.defaultProps = {
  userType: '',
  requestGuid: {},
  axiosRequest: () => {},
  callback: null,
  file: {
    name: '',
    originalTags: [],
    tagList: []
  }
};

export default EditFileForm;
