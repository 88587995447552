export const mockTransactionReportChargebackGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      header: {
        label: 'date',
        lines: ['count'],
        biaxial: ['count'],
        currency: true,
        biaxialCurrency: false
      },
      data: [
        {
          date: 'Feb',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        },
        {
          date: 'Mar',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        },
        {
          date: 'Apr',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        },
        {
          date: 'May',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        },
        {
          date: 'Jun',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        },
        {
          date: 'Jul',
          discover: 1,
          other: 24,
          americanExpress: 0,
          visa: 3,
          dinerClub: 0,
          mastercard: 2,
          ratio: 1.0
        },
        {
          date: 'Aug',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.0
        }
      ]
    }
  };
};

export default mockTransactionReportChargebackGet;
