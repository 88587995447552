import { getRandomNumber } from '../../_helpers';
import {
  generateRandomSentence,
  getMockPageData,
  getMockValue,
  mockPagingInfo
} from '../_mockDataHelpers';

export const getMockMerchantLedgerData = () => {
  const ledger = [
    {
      merchantLedgerId: '12345678',
      merchant: {
        merchantGuid: getMockValue({ type: 'string' }),
        dbaName: 'Merchant with Transaction Report Data',
        mid: getMockValue({ type: 'mid' })
      },
      relationship: {
        relationshipId: getMockValue({ type: 'string' }),
        relationshipCode: getMockValue({ type: 'string' }),
        relationshipName: getMockValue({ type: 'string' }),
        bankName: getMockValue({ type: 'string' }),
        processorName: getMockValue({ type: 'string' })
      },
      timestamp: '2020-08-01T06:06:06.950',
      merchantBankAccountNumber: getMockValue({
        min: 1111111111,
        max: 9999999999,
        type: 'number'
      }).toString(),
      description: generateRandomSentence(),
      achId: 12345678,
      batchId: 34291131,
      otherId: getMockValue({ type: 'string' }),
      merchantFboCredit: getMockValue({ type: 'number' }),
      merchantFboBalance: getMockValue({ type: 'number' }),
      merchantReserveCredit: getMockValue({ type: 'number' }),
      merchantReserveBalance: getMockValue({ type: 'number' }),
      notes: generateRandomSentence()
    }
  ];
  const randomNumber = getRandomNumber({ min: 5, max: 15 });
  for (let i = 0; i < randomNumber; i += 1) {
    const fiftyFifty = () => Math.random() < 0.5;
    ledger.push({
      merchantLedgerId: getMockValue({ type: 'string' }),
      merchant: {
        merchantGuid: getMockValue({ type: 'guid' }),
        dbaName: getMockValue({ type: 'string' }),
        mid: getMockValue({ type: 'mid' })
      },
      relationship: {
        relationshipId: getMockValue({ type: 'string' }),
        relationshipCode: getMockValue({ type: 'string' }),
        relationshipName: getMockValue({ type: 'string' }),
        bankName: getMockValue({ type: 'string' }),
        processorName: getMockValue({ type: 'string' })
      },
      timestamp: getMockValue({ type: 'isoDate' }),
      merchantBankAccountNumber: getMockValue({
        min: 1111111111,
        max: 9999999999,
        type: 'number'
      }).toString(),
      description: generateRandomSentence(),
      achId: getMockValue({ type: 'string' }),
      batchId: getMockValue({ type: 'string' }),
      otherId: getMockValue({ type: 'string' }),
      merchantFboCredit: getMockValue({ type: 'number' }) * (fiftyFifty() ? -1 : 1),
      merchantFboBalance: getMockValue({ type: 'number' }),
      merchantReserveCredit: getMockValue({ type: 'number' }) * (fiftyFifty() ? -1 : 1),
      merchantReserveBalance: getMockValue({ type: 'number' }),
      notes: generateRandomSentence()
    });
  }
  return ledger;
};

export const mockMerchantLedgerGet = (body, params, options) => {
  const { guid = `"{}"` } = options || {};
  const {
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex,
    pageSize = mockPagingInfo.pageSize
  } = params || {};
  const pageData = getMockPageData({
    allData: getMockMerchantLedgerData(),
    pageIndex,
    pageSize,
    optionsCopy: { ...params }
  });
  const mockData = {
    merchantLedgerEntries: pageData,
    pagingInfo: {
      totalNumberOfRecords,
      pageIndex,
      pageSize
    }
  };
  return { [guid]: mockData };
};

export default mockMerchantLedgerGet;
