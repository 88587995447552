import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { loader } from './_styles';

export const SuspenseComponent = ({ children = null, fallback = null }) => {
  const fallbackComponent = fallback ? (
    fallback
  ) : (
    <div style={{ minHeight: '40px', height: '100%', width: '100%' }}>
      <div className="boxLoader" data-testid="boxLoader" style={loader.loader} />
    </div>
  );
  return <Suspense fallback={fallbackComponent}>{children}</Suspense>;
};

SuspenseComponent.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node
};

export default SuspenseComponent;
