import { pad } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { relationshipGuids } from '../guids';
import { mockRelationshipGetFormatted } from './root';
import { mockPartnerGetFormatted } from '../partner/root';

const mockRelationshipBoardingData = {
  [`["${relationshipGuids.default}"]`]: {
    header: {
      label: 'month',
      lines: ['total'],
      biaxial: ['total'],
      currency: null,
      biaxialCurrency: null
    },
    data: [
      {
        total: 0,
        month: '2020-02',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-03',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-04',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-05',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-06',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 0,
        month: '2020-07',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 0
      },
      {
        total: 1,
        month: '2020-08',
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Closed': 0,
        'PostCreateTestUsersHandler_8bXtYjnh_16 Relationship with Transaction Report Data Onboarded': 1
      }
    ]
  }
};
const createMockOnboardingEntries = (type) =>
  (type === 'partner' ? mockPartnerGetFormatted : mockRelationshipGetFormatted).reduce(
    (acc, item, index) => {
      const { title } = item || {};
      const signClosed = index % 3 === 0 ? 1 : -1;
      const signOnboarded = index % 4 === 0 ? 1 : -1;
      const closedValue = getMockValue({ type: 'number', min: 1, max: 10 });
      const onboardedValue = getMockValue({ type: 'number', min: 1, max: 10 });
      return {
        ...acc,
        [`${title} Closed`]: signClosed * closedValue || 0,
        [`${title} Onboarded`]: signOnboarded * onboardedValue || 0
      };
    },
    {}
  );

export const createMockOnboardingData = (type) => ({
  header: {
    label: 'month',
    lines: ['total'],
    biaxial: ['total'],
    currency: null,
    biaxialCurrency: null
  },
  data: Array.from({ length: 7 }).map((item, index) => ({
    total: getMockValue({ type: 'number', min: 1 }),
    month: `2020-${pad(index + 2)}`, // 2020-02 to 2020-08
    ...createMockOnboardingEntries(type)
  }))
});

export const mockRelationshipBoardingGet = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: mockRelationshipBoardingData[guid] || createMockOnboardingData('relationship') };
};

export default mockRelationshipBoardingGet;
