import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { externalIntegrationFormFields } from '../../pages/components/forms/_partnerFormFields';

export const mockExternalIntegrationGet = (body, params, options) => {
  const { guid = '' } = options;
  const partner = {
    partnerId: guid,
    partnerName: getMockValue({ type: 'string' }),
    partnerBusinessCode: getMockValue({
      type: 'number',
      min: 1000,
      max: 9999,
      stringify: true
    })
  };
  const mockData = {
    partner,
    integrationList: externalIntegrationFormFields.integrationTarget.list.map(
      (aIntegrationValue) => {
        const { value } = aIntegrationValue;
        return {
          integrationTarget: value,
          apiKeyLastFour: getMockValue({ type: 'string' }).slice(0, 4),
          apiClientUrl: getMockValue({ type: 'url' }),
          ...(value === 'iris' && {
            irisConfig: {
              group: getMockValue({ type: 'string' }),
              processor: getMockValue({ type: 'string' })
            }
          })
        };
      }
    )
  };
  return { [guid]: mockData };
};

export default mockExternalIntegrationGet;
