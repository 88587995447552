// This holds All the actions that change state as they get fire when a related event happens.
export function authenticate(user) {
  return {
    type: 'AUTHENTICATE',
    payload: user
  };
}

export function resetStore() {
  return {
    type: 'RESET_STORE'
  };
}

export function toggleLoader(loading) {
  return {
    type: 'TOGGLE_LOADER',
    loading
  };
}

export function alertBar(barStyle, message, timeout) {
  return {
    type: 'ALERT_BAR',
    barStyle,
    message,
    timeout
  };
}

export function siteModal(showModal, modalTitle, modalContent, options) {
  return {
    type: 'MODAL',
    showModal,
    modalTitle,
    modalContent,
    options
  };
}

export function resetPassword(bool) {
  return {
    type: 'RESET_PASSWORD',
    payload: bool
  };
}

export function appState(actions) {
  const { nestedMenus } = actions;
  return {
    type: 'APP_STATE',
    nestedMenus: nestedMenus || {}
  };
}

export function guidUpdate(options) {
  const {
    relationshipId,
    relationshipIdList,
    partnerId,
    ticketId,
    currentBankName,
    currentProcessorName,
    currentStatus,
    currentRiskCategory,
    currentRiskSubcategory,
    currentRiskEmployee,
    currentRiskRule,
    currentPriority,
    currentName,
    currentDbaName,
    currentRequiresExtraMonitoring
  } = options;
  return {
    type: 'GUID_UPDATE',
    relationshipId,
    relationshipIdList,
    partnerId,
    ticketId,
    currentBankName,
    currentProcessorName,
    currentStatus,
    currentRiskCategory,
    currentRiskSubcategory,
    currentRiskEmployee,
    currentRiskRule,
    currentPriority,
    currentName,
    currentDbaName,
    currentRequiresExtraMonitoring
  };
}

export function dataUpdate(options) {
  const {
    employeeData,
    merchantData,
    partnerData,
    relationshipData,
    riskData,
    residualsData,
    apiVersion,
    varListPolling,
    ticketData
  } = options;
  return {
    type: 'DATA_UPDATE',
    employeeData,
    merchantData,
    partnerData,
    relationshipData,
    riskData,
    residualsData,
    apiVersion,
    varListPolling,
    ticketData
  };
}
