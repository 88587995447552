import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isBool, isEmpty, isEqual } from './_helpers';
import { FormAssistant } from '../index';
import { buildFormComponents, valueExists } from './_formHelpers';
import validations from './_validations';

export const FormDragAndDropRankedListItem = ({
  boxStyle = null,
  callback = null,
  disabled = false,
  item = {},
  rankedItemIndex = 0,
  required = false
}) => {
  const { initialValue, uniqueId } = item || {};
  const { customValidation, fieldType, id, type } = item ? item.props || {} : {};
  const compId = !isEmpty(id) ? id : 'formCompId';
  const compType = item.componentType || fieldType;
  const hasInitialValue = valueExists(initialValue, item?.props);
  const validationMethod = !isEmpty(customValidation)
    ? customValidation
    : validations[type] && validations[type].test;
  const initialValueValid =
    hasInitialValue && !isEmpty(validationMethod)
      ? validationMethod(initialValue)
      : (!required && !hasInitialValue) || false;
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(
    isBool(initialValueValid) ? initialValueValid : hasInitialValue
  );
  const formBuildProps = {
    ...item.props,
    disabled,
    componentType: compType,
    id: compId
  };
  const formComps = buildFormComponents(
    { ...(initialValue !== undefined && { [compId]: initialValue }) },
    { [compId]: { ...formBuildProps } },
    {}
  );

  const handleFormChange = (newFormState, formId, options) => {
    const { valuesForBackend } = options || {};
    const { [compId]: newValue } = valuesForBackend || {};
    if (!isEqual(currentValue, newValue)) {
      const hasValue = !isEmpty(newValue) || isBool(newValue);
      const newIsValid = hasValue
        ? newFormState[`${compId}IsValid`]
        : (!required && isEmpty(newValue) && !isBool(newValue)) || false;
      setCurrentValue(newValue);
      setIsValid(newIsValid);
    }
  };

  useEffect(() => {
    const cbOptions = {
      itemIndex: rankedItemIndex,
      updatedValue: currentValue,
      updatedIsValid: isValid
    };
    callback && callback(cbOptions);
  }, [currentValue, isValid]);

  return (
    <div
      id={uniqueId}
      className={[
        'ranked-list-item',
        ...(disabled
          ? []
          : [...(isValid ? ['valid'] : []), ...(!isValid && required ? ['invalid'] : [])])
      ].join(' ')}>
      <div className="rank">{`${rankedItemIndex + 1}.`}</div>
      <div className="component-wrapper">
        <FormAssistant
          componentLabelInside={boxStyle === 'inside'}
          id={uniqueId}
          formComponents={formComps}
          callback={handleFormChange}
        />
      </div>
    </div>
  );
};

FormDragAndDropRankedListItem.propTypes = {
  boxStyle: PropTypes.string,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  item: PropTypes.PropTypes.shape({
    componentType: PropTypes.string,
    initialValue: PropTypes.oneOfType([PropTypes.any]),
    props: PropTypes.oneOfType([PropTypes.object]),
    uniqueId: PropTypes.string
  }),
  rankedItemIndex: PropTypes.number,
  required: PropTypes.bool
};

export default FormDragAndDropRankedListItem;
