// HOW TO USE

// ## 1 ##
// checkHolidays() with NO param, checks TODAY, and returns an object with all teh info you need:
// it will state if it's a holiday or not, when the next one is, how far away it is, etc.
// {
//   daysUntilNextHoliday: "30"  // how much longer till next holiday
//   isWeekend: false // is it a weekend.. :shrug
//   listHolidays: Function // called like listHolidays(), returns all the holidays we are tracking.
//   nextFive: (5) [{…}, {…}, {…}, {…}, {…}] // the next 5 holidays coming up
//   nextHoliday: {
//     name: 'Columbus Day', className: 'columbusDay', type: 'federal', occurrence: 2, weekDay: 1...
//   }
//   today: {
//     className: String // (this is the css class we can ue to do custom styling for the holiday)
//     companyHoliday: bool // (is it a holiday the company observes)
//     date: Date // date object for the current day
//     day: Number // numerical day i.e. Sunday - Saturday : 0 - 6
//     month: Number // numerical Month 0-11 (Jan is 0, so you'll see +1 to make it human readable)
//     name: String // name of hte holiday if there is one, null otherwise
//     type: Bool // type of holiday. I only added "federal" observed holidays,
//                // but ready to take any we want to add
//   }
// }

// ## 2 ##
// checkHolidays() can be passed a specific date, and will tell you if that date is a holiday.
// i.e.:
// const testDate = new Date(2021, 11, 2);
// checkHolidays({ date: testDate }) // its an OBJECT of all options.

// ## 3 ##
// You can get something specific if you only care about one thing, and don't want the entire object
// checkHolidays().daysUntilNextHoliday
// checkHolidays().isWeekend
// checkHolidays().listHolidays()
// checkHolidays().nextFive
// checkHolidays().nextHoliday
// checkHolidays().today
// ///////////////////

export const checkHolidays = (options) => {
  const { date = new Date(), callback } = options || {};
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  // public: public holiday
  // bank: bank holiday, banks and offices are closed
  // school: school holiday, schools are closed
  // optional: majority of people take a day off
  // observance: optional festivity, no paid day off
  // companyHoliday: observed by the company (day off)
  const holidays = [
    // For floating holidays, the day is in range 0 Sunday to 6 Saturday
    // 1 = first, 2 = second, 3 = third, 4 = fourth, -1 = last.
    {
      // Jan 1st
      name: `New Year's Day`,
      className: 'newYearsDay',
      type: 'federal',
      companyHoliday: true,
      month: 1,
      day: 1
    },
    {
      // Third Monday in Jan
      name: `Martin Luther King, Jr. Day`,
      className: `mlkDay`,
      type: 'federal',
      occurrence: 3,
      weekDay: 1,
      month: 1
    },
    {
      // 3rd monday in Feb
      name: `Presidents's Day`, // Washington's Birthday
      className: 'presidentsDay',
      type: 'federal',
      companyHoliday: true,
      occurrence: 3,
      weekDay: 1,
      month: 2
    },
    {
      // set in code below, not an easy calculation
      name: `Good Friday`,
      className: `goodFriday`,
      companyHoliday: true
    },
    {
      // set in code below, not an easy calculation
      name: `Easter`,
      className: `easter`
    },
    {
      // Last Monday in May
      name: `Memorial Day`,
      className: `memorialDay`,
      type: 'federal',
      companyHoliday: true,
      occurrence: -1,
      weekDay: 1,
      month: 5
    },
    {
      // Juneteenth
      name: `Juneteenth`,
      className: 'juneteenth',
      type: 'federal',
      companyHoliday: true,
      month: 6,
      day: 19
    },
    {
      // July 4th
      name: `Independence Day`,
      className: 'independenceDay',
      type: 'federal',
      companyHoliday: true,
      month: 7,
      day: 4
    },
    {
      // First Monday in Sept
      name: `Labor Day`,
      className: `laborDay`,
      type: 'federal',
      companyHoliday: true,
      occurrence: 1,
      weekDay: 1,
      month: 9
    },
    {
      // Second Monday in Oct
      name: `Columbus Day`,
      className: `columbusDay`,
      type: 'federal',
      occurrence: 2,
      weekDay: 1,
      month: 10
    },
    {
      // Nov 11th
      name: `Veterans Day`,
      className: `veteransDay`,
      type: 'federal',
      month: 11,
      day: 11
    },
    {
      // Last Thursday in Nov
      name: `Thanksgiving Day`,
      className: `thanksgiving`,
      type: 'federal',
      companyHoliday: true,
      occurrence: -1,
      weekDay: 4,
      month: 11
    },
    {
      // Last Friday in Nov ? is thursday ever the last day in Nov?
      name: `Day After Thanksgiving Day`,
      className: `thanksgiving`,
      companyHoliday: true,
      occurrence: -1,
      weekDay: 5,
      month: 11
    },
    {
      // Dec 24th
      name: `Christmas Eve`,
      className: `christmas`,
      companyHoliday: true,
      month: 12,
      day: 24
    },
    {
      // Dec 25th
      name: `Christmas Day`,
      className: `christmas`,
      type: 'federal',
      companyHoliday: true,
      month: 12,
      day: 25
    }
  ];

  const setDateObject = (opt) => {
    const {
      occurrence = null,
      weekDay = null,
      month = null,
      day = null,
      year = date.getFullYear()
    } = opt || {};
    if (month && day) {
      const theMonth = months[month - 1];
      return new Date(`${theMonth} ${day}, ${year} 0:0:0`);
    }
    if (occurrence && weekDay && month) {
      const theMonth = month - 1;
      return nthDayInMonth({
        n: occurrence,
        day: weekDay,
        m: theMonth,
        y: year
      });
    }
    // It will never get here, but need to add it for required return
    /* istanbul ignore next */
    return undefined;
  };

  const firstDayInMonth = (opt) => {
    // day is in range 0 Sunday to 6 Saturday
    const {
      day,
      m = new Date(date.now()).getMonth(),
      y = new Date(date.now()).getFullYear()
    } = opt || {};
    return new Date(y, m, 1 + ((day - new Date(y, m, 1).getDay() + 7) % 7));
  };

  const nthDayInMonth = (opt) => {
    // day is in range 0 Sunday to 6 Saturday
    const {
      n,
      day,
      m = new Date(date.now()).getMonth(),
      y = new Date(date.now()).getFullYear()
    } = opt || {};
    const d = firstDayInMonth({ day, m, y });
    if (n === -1) {
      const nd = new Date(y, m + 1, 0);
      do {
        // Roll the days backwards until Monday.
        nd.setDate(nd.getDate() - 1);
      } while (nd.getDay() !== day);
      return nd;
    }
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (n - 1) * 7);
  };

  // set actual date objects, AND add dates for next year
  holidays.forEach((holiday) => {
    const updatedHoliday = holiday;
    const year = date.getFullYear();
    const nextYear = date.getFullYear() + 1;
    if (holiday.name === 'Good Friday' || holiday.name === 'Easter') {
      const easter = getEaster(year);
      const nextEaster = getEaster(nextYear);
      if (holiday.name === 'Easter') {
        updatedHoliday.date = easter;
        holidays.push({
          ...updatedHoliday,
          date: nextEaster
        });
      }
      if (holiday.name === 'Good Friday') {
        easter.setDate(easter.getDate() - 2);
        nextEaster.setDate(nextEaster.getDate() - 2);
        const goodFriday = easter;
        const nextGoodFriday = nextEaster;
        updatedHoliday.date = goodFriday;
        holidays.push({
          ...updatedHoliday,
          date: nextGoodFriday
        });
      }
    } else {
      updatedHoliday.date = setDateObject(holiday);
      // HANDLE IF HOLIDAY LANDS ON A SAT OR SUNDAY, when avg. companies observe them
      if (!updatedHoliday.occurrence && updatedHoliday.companyHoliday) {
        if (updatedHoliday.date.getDay() === 0 || updatedHoliday.date.getDay() === 6) {
          const updatedHolidayObserved = Object.assign({}, updatedHoliday);
          updatedHolidayObserved.name = `${updatedHolidayObserved.name} (observed)`;
          // SPECIAL CASES for CHRISTMAS
          if (updatedHoliday.date.getDay() === 0) {
            // SUNDAY
            // IF christmas EVE lands on Sunday, companies observe on previous FRIDAY
            if (updatedHoliday.name === 'Christmas Eve') {
              updatedHolidayObserved.day -= 2;
            } else {
              updatedHolidayObserved.day += 1;
            }
          }
          if (updatedHoliday.date.getDay() === 6) {
            // SATURDAY
            // IF christmas DAY lands on SATURDAY, companies observe (work) on Monday
            if (updatedHoliday.name === 'Christmas Day') {
              updatedHolidayObserved.day += 2;
            } else {
              updatedHolidayObserved.day -= 1;
            }
          }
          const d = new Date(
            updatedHolidayObserved.date.getFullYear(),
            updatedHolidayObserved.date.getMonth(),
            updatedHolidayObserved.day
          );
          updatedHolidayObserved.date = d;
          holidays.push(updatedHolidayObserved);
        }
      }
      // END HANDLE IF HOLIDAY LANDS ON A SAT OR SUNDAY, ADD IN OBSERVED DAY TO FRI or MON
      holidays.push({
        ...updatedHoliday,
        date: setDateObject({ ...holiday, year: year + 1 })
      });
    }
  });
  // now sort them by date.
  holidays.sort((a, b) => a.date - b.date);

  const daysBetweenDates = (date1 = date, date2) => {
    // Calc time difference between the two dates
    const DifferenceInTime = date2.getTime() - date1.getTime();
    // Calc days between two dates
    const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
    return DifferenceInDays.toFixed(0);
  };

  const getDayInfo = () => {
    const isHoliday = holidays.find((day) => {
      if (
        day.date.getDay() === date.getDay() &&
        day.date.getMonth() === date.getMonth() &&
        day.date.getFullYear() === date.getFullYear()
      ) {
        return day;
      }
      return false;
    });
    if (isHoliday) {
      return isHoliday;
    }
    return {
      className: null,
      companyHoliday: null,
      date,
      day: date.getDay(),
      month: date.getMonth(),
      name: null,
      type: null
    };
  };

  const nextHoliday = () => {
    const closest = holidays.reduce((a, b) => {
      const adiff = a.date - date;
      return adiff > 0 && adiff < b.date - date ? a : b;
    });
    const nextIndex = holidays.findIndex((day) => day.date === closest.date);
    return {
      next: closest,
      nextFive: [
        holidays[nextIndex],
        holidays[nextIndex + 1],
        holidays[nextIndex + 2],
        holidays[nextIndex + 3],
        holidays[nextIndex + 4]
      ]
    };
  };

  const isWeekend = () => date.getDay() === 6 || date.getDay() === 0;

  const listHolidays = () => holidays;

  callback && callback();

  return {
    today: getDayInfo(date) || 'Nothing special about today',
    isWeekend: isWeekend(),
    nextHoliday: nextHoliday().next,
    daysUntilNextHoliday: daysBetweenDates(date, nextHoliday().next.date),
    nextFive: nextHoliday().nextFive,
    listHolidays
  };
};

/// ///////////////
// All the below junk is just to find stoopid easter and good friday. :grimmace
// taken from the bowels of the interwebs, not sure who authored it originally
const epoch = 2444238.5;
const elonge = 278.83354;
const elongp = 282.596403;
const eccent = 0.016718;
const sunsmax = 149598500;
const sunangsiz = 0.533128;
const mmlong = 64.975464;
const mmlongp = 349.383063;
const mlnode = 151.950429;
const minc = 5.145396;
const mecc = 0.0549;
const mangsiz = 0.5181;
const msmax = 384401;
const synmonth = 29.53058868;
const PI = 3.141592653589793;
const epsilon = 1e-6;

function abs(x) {
  return x < 0 ? -x : x;
}

function fixAngle(a) {
  return a - 360 * Math.floor(a / 360);
}

function toRad(d) {
  return d * (PI / 180);
}

function toDeg(d) {
  return d * (180 / PI);
}

function toJulianTime(date) {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const m = month > 2 ? month : month + 12;
  const y = month > 2 ? year : year - 1;
  const d =
    day +
    date.getHours() / 24 +
    date.getMinutes() / 1440 +
    (date.getSeconds() + date.getMilliseconds() / 1e3) / 86400;
  const b = isJulianDate(year, month, day) ? 0 : 2 - y / 100 + y / 100 / 4;
  return Math.floor(365.25 * (y + 4716) + Math.floor(30.6001 * (m + 1)) + d + b - 1524.5);
}

function isJulianDate(year, month, day) {
  if (year < 1582) return !0;
  if (year > 1582) return !1;
  /* it will never fall into this block that I can tell. */
  /* leaving though, since this is copied code */
  /* istanbul ignore next */
  if (month < 10) return !0;
  /* istanbul ignore next */
  if (month > 10) return !1;
  /* istanbul ignore next */
  if (day < 5) return !0;
  /* istanbul ignore next */
  if (day > 14) return !1;
  /* istanbul ignore next */
  throw new Error(['Any date in the range 10/5/1582 to 10/14/1582 is invalid!']);
}

function kepler(m, ecc) {
  let eRad = toRad(m);
  const mRad = toRad(m);
  let delta;
  do {
    eRad -= (delta = eRad - ecc * Math.sin(eRad) - mRad) / (1 - ecc * Math.cos(eRad));
  } while (abs(delta) > epsilon);
  return eRad;
}

function getMoonPhase(julianDate) {
  let Day;
  let M;
  let Ec;
  let Ev;
  let Ae;
  let mEc;
  let lP;
  const N = fixAngle((360 / 365.2422) * (Day = julianDate - epoch));
  Ec = kepler((M = fixAngle(N + elonge - elongp)), eccent);
  Ec = Math.sqrt((1 + eccent) / (1 - eccent)) * Math.tan(Ec / 2);
  const Lambdasun = fixAngle((Ec = 2 * toDeg(Math.atan(Ec))) + elongp);
  const F = (1 + eccent * Math.cos(toRad(Ec))) / (1 - eccent * eccent);
  const SunDist = sunsmax / F;
  const SunAng = F * sunangsiz;
  const ml = fixAngle(13.1763966 * Day + mmlong);
  const MM = fixAngle(ml - 0.1114041 * Day - mmlongp);
  const MN = fixAngle(mlnode - 0.0529539 * Day);
  const MmP =
    MM +
    (Ev = 1.2739 * Math.sin(toRad(2 * (ml - Lambdasun) - MM))) -
    (Ae = 0.1858 * Math.sin(toRad(M))) -
    0.37 * Math.sin(toRad(M));
  const lPP =
    (lP = ml + Ev + (mEc = 6.2886 * Math.sin(toRad(MmP))) - Ae + 0.214 * Math.sin(toRad(2 * MmP))) +
    0.6583 * Math.sin(toRad(2 * (lP - Lambdasun)));
  const NP = MN - 0.16 * Math.sin(toRad(M));
  const y = Math.sin(toRad(lPP - NP)) * Math.cos(toRad(minc));
  const x = Math.cos(toRad(lPP - NP));
  toDeg(Math.atan2(y, x));
  toDeg(Math.asin(Math.sin(toRad(lPP - NP)) * Math.sin(toRad(minc))));
  const MoonAge = lPP - Lambdasun;
  const MoonPhase = (1 - Math.cos(toRad(MoonAge))) / 2;
  const MoonDist = (msmax * (1 - mecc * mecc)) / (1 + mecc * Math.cos(toRad(MmP + mEc)));
  const MoonDFrac = MoonDist / msmax;
  const MoonAng = mangsiz / MoonDFrac;
  return {
    moonPhase: fixAngle(MoonAge) / 360,
    moonIllumination: MoonPhase,
    moonAgeInDays: synmonth * (fixAngle(MoonAge) / 360),
    distanceInKm: MoonDist,
    angularDiameterInDeg: MoonAng,
    distanceToSun: SunDist,
    sunAngularDiameter: SunAng
  };
}

function getMoonInfo(date) {
  return date == null
    ? {
        moonPhase: 0,
        moonIllumination: 0,
        moonAgeInDays: 0,
        distanceInKm: 0,
        angularDiameterInDeg: 0,
        distanceToSun: 0,
        sunAngularDiameter: 0
      }
    : getMoonPhase(toJulianTime(date));
}

function getEaster(year) {
  let previousMoonInfo;
  let moonInfo;
  const fullMoon = new Date(year, 2, 21);

  let gettingDarker = void 0;
  do {
    previousMoonInfo = getMoonInfo(fullMoon);
    fullMoon.setDate(fullMoon.getDate() + 1);
    moonInfo = getMoonInfo(fullMoon);
    gettingDarker === void 0
      ? (gettingDarker = moonInfo.moonIllumination < previousMoonInfo.moonIllumination)
      : gettingDarker &&
        moonInfo.moonIllumination > previousMoonInfo.moonIllumination &&
        (gettingDarker = !1);
  } while (
    (gettingDarker && moonInfo.moonIllumination < previousMoonInfo.moonIllumination) ||
    (!gettingDarker && moonInfo.moonIllumination > previousMoonInfo.moonIllumination)
  );
  for (fullMoon.setDate(fullMoon.getDate() - 1); fullMoon.getDay() !== 0; ) {
    fullMoon.setDate(fullMoon.getDate() + 1);
  }
  return fullMoon;
}
// END find easter and good friday.
/// ///////////////

export default checkHolidays;
