// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForFEView, isEmpty, isEqual } from './_helpers';
import { Button, NoData } from '../index';
import { HistoryListWrapper } from '../css/_styledComponents';
import { HistoryDescription } from './HistoryDescription';

export class HistoryList extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      history: [],
      showMore: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.setData();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (!isEqual(data, prevProps.data)) {
      this.setData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  setData = () => {
    const { data } = this.props;
    this.updateState(
      {
        dataLoaded: false,
        history: data || []
      },
      () => this.updateState({ dataLoaded: true })
    );
  };

  toggleShowMore = () => this.updateState((prevState) => ({ showMore: !prevState.showMore }));

  render() {
    const { dataLoaded, history, showMore } = this.state;
    const { id, maxNumberOfItems } = this.props;
    const numberOfItemsToShow =
      dataLoaded && history.length > maxNumberOfItems && !showMore
        ? maxNumberOfItems
        : (dataLoaded && history.length) || 0;
    return (
      <HistoryListWrapper id={id} className="history-list-wrapper">
        {dataLoaded && !isEmpty(history) ? (
          <>
            <div className="history-list" role="article" aria-label="History list">
              {history.slice(0, numberOfItemsToShow).map((item, index) => (
                <div className="history-item" key={`${index.toString()}`}>
                  <div className="history-title-wrapper">
                    <div className="history-timestamp">
                      {formatDateForFEView(item.timestamp, { includeTime: true })}
                    </div>
                    {!isEmpty(item.typeTitle) && (
                      <div className="history-title">{item.typeTitle}</div>
                    )}
                    {!isEmpty(item.urlPath) && (
                      <a
                        className={`${item.guidType}Link history-link`}
                        href={item.urlPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ lineHeight: '1.5' }}>
                        {item.urlTitle}
                      </a>
                    )}
                  </div>
                  <div
                    className="history-arrow-container"
                    style={{ ...(index === history.length - 1 && { border: 'none' }) }}>
                    <div
                      className="history-arrow"
                      style={{ ...(index === history.length - 1 && { display: 'none' }) }}>
                      &#9650;
                    </div>
                    {(!isEmpty(item.description) || !isEmpty(item.from)) && (
                      <div
                        className="history-description"
                        style={{
                          ...(item.markAsYou && {
                            backgroundColor: 'var(--color-sage)',
                            border: '1px solid var(--color-granite-green)'
                          }),
                          ...(isEmpty(item.description) && { width: 'fit-content' })
                        }}>
                        {!isEmpty(item.from) && (
                          <span className="history-owner">
                            {item.markAsYou ? 'You' : item.from}
                          </span>
                        )}
                        {!isEmpty(item.from) && !isEmpty(item.description) && <>{` - `}</>}
                        {!isEmpty(item.description) && (
                          <HistoryDescription data={{ description: item.description }} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {history.length > maxNumberOfItems && (
              <Button
                id="show-more-items-button"
                type="text"
                onClick={this.toggleShowMore}
                style={{ fontSize: '1.3rem', height: '20px' }}>
                {showMore ? 'Show Less' : 'Show More'}
              </Button>
            )}
          </>
        ) : (
          <NoData />
        )}
      </HistoryListWrapper>
    );
  }
}

HistoryList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string,
  maxNumberOfItems: PropTypes.number
};

HistoryList.defaultProps = {
  data: [],
  id: null,
  maxNumberOfItems: 5
};

export default HistoryList;
