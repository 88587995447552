import { riskCategoryGuids } from '../../../../guids';

export const mockRiskSubcategories = {
  riskSubcategory01: { riskSubcategory: 'riskSub1', riskSubcategoryId: 'rsc000-00000-00' },
  riskSubcategory02: { riskSubcategory: 'riskSub2', riskSubcategoryId: 'rsc000-00000-01' },
  riskSubcategory03: { riskSubcategory: 'riskSubA', riskSubcategoryId: 'rsc000-00000-02' },
  riskSubcategory04: { riskSubcategory: 'riskSubB', riskSubcategoryId: 'rsc000-00000-03' },
  riskSubcategory05: { riskSubcategory: 'riskSubC', riskSubcategoryId: 'rsc000-00000-04' },
  riskSubcategory06: { riskSubcategory: 'riskSubD', riskSubcategoryId: 'rsc000-00000-05' }
};

export const mockRiskSubcategoryGetRes = {
  [riskCategoryGuids.default]: {
    riskSubcategories: [
      mockRiskSubcategories.riskSubcategory01,
      mockRiskSubcategories.riskSubcategory02
    ]
  },
  [riskCategoryGuids.riskCategory01]: {
    riskSubcategories: [
      mockRiskSubcategories.riskSubcategory03,
      mockRiskSubcategories.riskSubcategory04
    ]
  },
  [riskCategoryGuids.riskCategory02]: {
    riskSubcategories: [
      mockRiskSubcategories.riskSubcategory05,
      mockRiskSubcategories.riskSubcategory06
    ]
  }
};

export const mockRiskSubcategoryPutRes = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: { riskSubcategoryId: mockRiskSubcategories.riskSubcategory02.riskSubcategoryId }
  };
};

export default mockRiskSubcategories;
