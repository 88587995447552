import { isEmpty, isBool } from '@f1/shared/src/_helpers';
import { toFrontendValue } from '@f1/shared/src/_templateHelpers';
// Returns parts of address stings into individual parts
export const addressParts = (addressString, options) => {
  // option to indicate id address includes the country
  const { includeCountry = false } = options || {};
  const addressArray = addressString.split(', ');
  // When country is included (Merchant form)
  if (addressArray.length === 6) {
    return {
      address: addressArray[0],
      address2: addressArray[1],
      city: addressArray[2],
      state: addressArray[3],
      zip: addressArray[4],
      country: addressArray[5]
    };
  }
  if (addressArray.length === 5) {
    // when the country is included in the addressString (merchant Form)
    // or when address2 is included in the addressString without country (partner Form)
    if (includeCountry) {
      if (!Number.isNaN(Number(addressArray[4]))) {
        // if the country is omitted, but address2 is included.
        // Then the zip would be index 4 and it would return a number (merchant form)
        return {
          address: addressArray[0],
          address2: addressArray[1],
          city: addressArray[2],
          state: addressArray[3],
          zip: addressArray[4],
          country: null
        };
      }
      // if the address2 is omitted, but there is a country included.
      // then index 4 would be a string and not a number (merchant form)
      return {
        address: addressArray[0],
        address2: null,
        city: addressArray[1],
        state: addressArray[2],
        zip: addressArray[3],
        country: addressArray[4]
      };
    }
    // when there is no country included in the addressString (partner form)
    return {
      address: addressArray[0],
      address2: addressArray[1],
      city: addressArray[2],
      state: addressArray[3],
      zip: addressArray[4]
    };
  }
  // otherwise just return the address, but address2 is not included. (partner form)
  return {
    address: addressArray[0],
    address2: null,
    city: addressArray[1],
    state: addressArray[2],
    zip: addressArray[3]
  };
};

// FE form - helps format backend value to formField format
export const toFormField = (backendValue, formField) => ({
  ...formField,
  value: toFrontendValue(backendValue, formField),
  required: isBool(formField.required) ? formField.required : true,
  valid: isFieldValid(backendValue, formField),
  ...(formField.fieldType === 'dropdown'
    ? { selected: toFrontendValue(backendValue, formField) }
    : {}),
  ...(formField.fieldType === 'checkbox'
    ? { checked: toFrontendValue(backendValue, formField) }
    : {})
});

// FE form - checks that the field is valid
export const isFieldValid = (value, formField) => {
  const required = isBool(formField.required) ? formField.required : true;
  const hasValue = !isEmpty(value) || (formField.fieldType === 'radio' && isBool(value));
  if (
    (required && hasValue) || // required & not empty
    !required
  ) {
    // or not required and not empty
    return true;
  }
  return false;
};

export const nullIfOptional = (field) => (!field && field !== false ? null : field);

export const toBool = (field) => !(isEmpty(field) || field === false);

// GETTING NAME FROM GUID
export const getEmployeeNameFromGuid = (guid, list) => {
  if (isEmpty(guid) || isEmpty(list)) return 'Unknown Employee Name';
  const employeeMatch = list.find(
    (employee) => employee.value === guid || employee.employeeId === guid
  );
  const employeeName = employeeMatch?.title
    ? employeeMatch.title
    : `${employeeMatch?.firstName || 'Unknown First'} ${employeeMatch?.lastName || 'Unknown Last'}`;
  return employeeName;
};

export const toReadFriendlyCron = (cronString) => {
  /* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
  if (isEmpty(cronString)) {
    return '';
  }
  const stringFields = cronString.trim().split(' ');
  const cronStateFields = {
    scheduleType: null,
    scheduleDayOfWeek: null,
    scheduleDayOfMonth: null,
    timeOfDay: null
  };
  const [min, hour, dayOfMonth, month, dayOfWeek] = stringFields;
  // identify schedule type: daily, weekly, monthly
  if (dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
    cronStateFields.scheduleType = 'daily';
  } else if (dayOfMonth === '*' && month === '*' && dayOfWeek !== '*') {
    cronStateFields.scheduleType = 'week';
  } else if (dayOfMonth !== '*' && month === '*' && dayOfWeek === '*') {
    cronStateFields.scheduleType = 'month';
  }
  // identify day to run
  if (cronStateFields.scheduleType === 'week') {
    cronStateFields.scheduleDayOfWeek = numToWeek(parseInt(dayOfWeek, 10));
  } else if (cronStateFields.scheduleType === 'month') {
    cronStateFields.scheduleDayOfMonth = numToMonth(parseInt(dayOfMonth, 10));
  }
  // set time of day
  const timeString = `${parseInt(hour, 10) < 10 ? '0' : ''}${hour}:${parseInt(min, 10) < 10 ? '0' : ''}${min}`;
  cronStateFields.timeOfDay = numToDay(timeString);
  const cronFriendlyString =
    cronStateFields.scheduleType === 'daily'
      ? `Run every day at ${cronStateFields.timeOfDay?.title}`
      : `Run every ${cronStateFields.scheduleType} ${cronStateFields.scheduleType === 'week' ? `on ${cronStateFields.scheduleDayOfWeek?.title}` : `on the ${cronStateFields.scheduleDayOfMonth?.title}`} at ${cronStateFields.timeOfDay?.title}`;
  return cronFriendlyString;
};

export const numToWeek = (weekNum) => dayOfWeekList.find((day) => day.value === weekNum);
export const numToMonth = (monthNum) => dayOfMonthList.find((month) => month.value === monthNum);
export const numToDay = (dayNum) => timeOfDayList.find((time) => time.value === dayNum);

export const dayOfWeekList = [
  { title: 'Monday', value: 1 },
  { title: 'Tuesday', value: 2 },
  { title: 'Wednesday', value: 3 },
  { title: 'Thursday', value: 4 },
  { title: 'Friday', value: 5 },
  { title: 'Saturday', value: 6 },
  { title: 'Sunday', value: 0 }
];

export const dayOfMonthList = [
  { title: '1st', value: 1 },
  { title: '2nd', value: 2 },
  { title: '3rd', value: 3 },
  { title: '4th', value: 4 },
  { title: '5th', value: 5 },
  { title: '6th', value: 6 },
  { title: '7th', value: 7 },
  { title: '8th', value: 8 },
  { title: '9th', value: 9 },
  { title: '10th', value: 10 },
  { title: '11th', value: 11 },
  { title: '12th', value: 12 },
  { title: '13th', value: 13 },
  { title: '14th', value: 14 },
  { title: '15th', value: 15 },
  { title: '16th', value: 16 },
  { title: '17th', value: 17 },
  { title: '18th', value: 18 },
  { title: '19th', value: 19 },
  { title: '20th', value: 20 },
  { title: '21st', value: 21 },
  { title: '22nd', value: 22 },
  { title: '23rd', value: 23 },
  { title: '24th', value: 24 },
  { title: '25th', value: 25 },
  { title: '26th', value: 26 },
  { title: '27th', value: 27 },
  { title: '28th', value: 28 },
  { title: '29th', value: 29 },
  { title: '30th', value: 30 },
  { title: '31st', value: 31 }
];

export const timeOfDayList = [
  { title: '12:00 AM', value: '00:00' },
  { title: '1:00 AM', value: '01:00' },
  { title: '2:00 AM', value: '02:00' },
  { title: '3:00 AM', value: '03:00' },
  { title: '4:00 AM', value: '04:00' },
  { title: '5:00 AM', value: '05:00' },
  { title: '6:00 AM', value: '06:00' },
  { title: '7:00 AM', value: '07:00' },
  { title: '8:00 AM', value: '08:00' },
  { title: '9:00 AM', value: '09:00' },
  { title: '10:00 AM', value: '10:00' },
  { title: '11:00 AM', value: '11:00' },
  { title: '12:00 PM', value: '12:00' },
  { title: '1:00 PM', value: '13:00' },
  { title: '2:00 PM', value: '14:00' },
  { title: '3:00 PM', value: '15:00' },
  { title: '4:00 PM', value: '16:00' },
  { title: '5:00 PM', value: '17:00' },
  { title: '6:00 PM', value: '18:00' },
  { title: '7:00 PM', value: '19:00' },
  { title: '8:00 PM', value: '20:00' },
  { title: '9:00 PM', value: '21:00' },
  { title: '10:00 PM', value: '22:00' },
  { title: '11:00 PM', value: '23:00' }
];

export const filterEmployeeList = (options) => {
  const { list = [], allowedGroups = [] } = options;
  if (isEmpty(allowedGroups)) {
    return list;
  }
  const groupsIgnoreCase = allowedGroups.map((g) => g.toUpperCase());
  const filteredList = list.filter((employee) =>
    employee.groups?.some((group) =>
      groupsIgnoreCase.includes(group?.employeeGroupName?.toUpperCase())
    )
  );
  return filteredList;
};
