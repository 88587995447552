import {
  dedupeList,
  getFormattedRelationship,
  getLocaleString,
  ignoreCase,
  isEmpty,
  isEqual,
  snakeToTitle,
  transformData
} from '../../../_helpers';
import { prevetStatusList } from '../../../_prevetFields';
import { formatAssignedEmployee } from './crabV1ApplicationTemplate';
import filesWithTagsTemplate, { getTagObjects } from './filesWithTagsTemplate';

const masterPrevetStatusList = [...prevetStatusList.list];

export const prevetStatuses = {
  // Title/value list
  uncompleted_only: masterPrevetStatusList.filter((item) => !item.isCompleted && !item.isReviewed),
  reviewed: masterPrevetStatusList.filter((item) => item.isReviewed),
  all: masterPrevetStatusList
};

// Enums only
export const prevetOpenStatuses = prevetStatuses.uncompleted_only.map((item) => item.value);
export const prevetReviewedStatuses = prevetStatuses.reviewed.map((item) => item.value);
export const prevetAllStatuses = prevetStatuses.all.map((item) => item.value);
export const prevetCompletedStatuses = masterPrevetStatusList.reduce((acc, aStatus) => {
  if (aStatus.isCompleted) {
    return acc.concat(aStatus.value);
  }
  return acc;
}, []);
export const allStatusEnums = {
  uncompleted_only: prevetOpenStatuses,
  completed_only: prevetCompletedStatuses,
  all: prevetAllStatuses
};

export const typeToBeEnumStatus = {
  waitingOnAppReview: 'waiting_on_app_review',
  proceed: 'proceed',
  decline: 'decline',
  declined: 'decline',
  abandoned: 'abandoned',
  abandon: 'abandoned',
  // not a BE state, FE value only
  riskProfile: 'riskProfile'
};

export const prevetTemplate = {
  frontend: (schema, version) => {
    // GET /v1/prevet
    if (version === '1.0') {
      const {
        prevets, // BE api response
        userType, // one of: partner, employee
        allRelationships,
        assignedEmployeeList, // employee only: for Assigned To dropdown in meco
        userEmail,
        appCompletionStatus = 'uncompleted_only',
        allowedToEdit
      } = schema;
      const primaryKey = 'prevetId';
      const hiddenColumns = getAppListHiddenColumns({ appCompletionStatus });
      const filterStatuses = prevets.reduce((acc, aPrevet) => {
        const { prevet = {} } = aPrevet || {};
        const { prevetStatus = {} } = prevet;
        if (
          appCompletionStatus === 'uncompleted_only' &&
          prevetOpenStatuses.includes(ignoreCase(prevetStatus || ''))
        ) {
          return acc.concat(aPrevet);
        }
        if (
          appCompletionStatus === 'reviewed' &&
          prevetReviewedStatuses.includes(ignoreCase(prevetStatus || ''))
        ) {
          return acc.concat(aPrevet);
        }
        if (appCompletionStatus === 'all') {
          return acc.concat(aPrevet);
        }
        return acc;
      }, []);
      const allTableData = filterStatuses.map((aPrevet) => {
        const { prevet = {}, filesList = {} } = aPrevet || {};
        const { employeeOnly = {} } = prevet || {};
        const {
          riskProfile,
          assignedAppReviewEmployee = {},
          assignedCreditEmployee = {} // Keep for viewing legacy apps
        } = employeeOnly || {};
        const appStatus = ignoreCase(prevet.prevetStatus || '');
        const formattedAssignedAppReviewEmployee = !isEmpty(assignedAppReviewEmployee)
          ? formatAssignedEmployee(assignedAppReviewEmployee)
          : {};
        const formattedAssignedCreditEmployee = !isEmpty(assignedCreditEmployee)
          ? formatAssignedEmployee(assignedCreditEmployee) // Keep for viewing legacy apps
          : {};
        const { email: assignedAppReviewUserEmail } = formattedAssignedAppReviewEmployee || {};
        const relationshipMatch =
          allRelationships?.find((r) => r.value === prevet.relationship?.relationshipId) ||
          prevet.relationship ||
          {};
        const appRiskProfile = ignoreCase(riskProfile || '');
        const formattedRelationship = getFormattedRelationship(relationshipMatch || {});
        const isCompleted = prevetCompletedStatuses.includes(appStatus);
        const disableDeleteFile = userType === 'employee' ? isCompleted : true;
        const emailMatch =
          userType === 'employee' &&
          !isEmpty(userEmail) &&
          isEqual(userEmail, assignedAppReviewUserEmail);
        const currentEmployeeCanUpdate =
          userType === 'employee' &&
          allowedToEdit &&
          emailMatch &&
          prevetOpenStatuses.includes(ignoreCase(appStatus));
        const assignedToSelf = emailMatch || false;
        const tableFields = {
          // fields visible in the table
          prevetNumber: prevet.prevetBusinessNumber,
          prevetId: prevet[primaryKey],
          prevetName: prevet.prevetName,
          status: snakeToTitle(appStatus),
          ...(userType === 'employee' && {
            'Assigned To (App Review)': formattedAssignedAppReviewEmployee?.firstName || '-',
            // Keep for viewing legacy apps
            'Assigned To (Credit)': formattedAssignedCreditEmployee?.firstName || '-',
            // Keep for viewing legacy apps
            assignedCreditEmployeeId: formattedAssignedCreditEmployee?.employeeId, // Table filter
            assignedAppReviewEmployeeId: formattedAssignedAppReviewEmployee?.employeeId,
            relationshipId: formattedRelationship?.relationshipId, // Table filter
            relationship: `${formattedRelationship.relationshipCode} - ${formattedRelationship?.relationshipName}` // Table filter
          }),
          submitted: getLocaleString(prevet.prevetSubmittedTimestamp) || '-',
          completed: getLocaleString(prevet.prevetCompletedTimestamp) || '-',
          relationshipCode: formattedRelationship.relationshipCode || '-',
          ...(userType === 'employee' && { partnerName: prevet.partner?.partnerName || '-' }),
          otherData: {
            // remaining fields NOT visible in table but may be used in other components
            canEdit: !(userType === 'partner' && !isEmpty(appStatus)),
            ...(userType === 'employee' && {
              currentEmployeeCanUpdate,
              assignedToSelf
            }),
            relationshipId: formattedRelationship?.relationshipId,
            relationshipName: formattedRelationship?.relationshipName,
            riskProfile: appRiskProfile,
            prevetStatusEnum: appStatus,
            ...(userType === 'employee' && {
              employeeOnly: {
                riskProfile: appRiskProfile,
                assignedAppReviewEmployee: formattedAssignedAppReviewEmployee,
                // Keep for viewing legacy apps
                assignedCreditEmployee: formattedAssignedCreditEmployee
              }
            }),
            formData: getFrontendFormData({
              userType,
              prevetId: prevet.prevetId,
              prevetStatusEnum: appStatus,
              prevetJson: prevet.prevetJson,
              filesList,
              prevetName: prevet.prevetName,
              relationship: formattedRelationship
            }),
            formattedFilesData: !isEmpty(filesList) // DataBoxFilesWithTags props
              ? filesList.map((f) => ({
                  ...f,
                  guidKey: 'prevetId',
                  guidValue: prevet.prevetId,
                  disableDelete:
                    disableDeleteFile ||
                    (userType === 'employee' && !currentEmployeeCanUpdate) ||
                    false,
                  ...(userType === 'employee' && {
                    disableEdit: isCompleted || !currentEmployeeCanUpdate
                  })
                }))
              : [],
            partner: {
              partnerId: prevet.partner?.partnerId,
              partnerBusinessCode: prevet.partner?.partnerBusinessCode,
              partnerName: prevet.partner?.partnerName
            },
            relationship: formattedRelationship,
            completedData: prevetCompletedStatuses.includes(appStatus)
              ? {
                  recommendation: prevet.prevetRecommendation || '-',
                  eligibleBanks:
                    !isEmpty(prevet.eligibleBanks) && Array.isArray(prevet.eligibleBanks)
                      ? prevet.eligibleBanks
                          .map((bankName) => snakeToTitle(bankName || ''))
                          .sort()
                          .join(', ')
                      : '-'
                }
              : {}
          }
        };
        return { ...tableFields };
      });
      const allAppsData = {
        tableData: allTableData,
        hiddenColumns,
        tablePrimaryKey: primaryKey,
        dropLists: {
          ...(userType === 'employee' && {
            assignedEmployeeList: dedupeList(assignedEmployeeList || []),
            relationshipList: allRelationships
          })
        }
      };
      return {
        allAppsData
      };
    }
    return schema;
  },
  backendPrevetFormPut: (schema, version) => {
    // Creating new prevet
    if (version === '1.0') {
      const { userType, formData, formFiles } = schema || {};
      const filesFormatted = formFiles.map((f) => ({
        fileName: f.name || f.fileName,
        s3Key: f.s3Key,
        tags: getTagObjects({ userType, tags: f.tagList, addingNewFiles: true })
      }));
      const formattedPrevetFields = formatPrevetFieldsBackend(formData);
      return {
        prevetFields: formattedPrevetFields,
        prevetName: formData.generalSection.prevetName,
        filesList: filesFormatted
      };
    }
    return schema || {};
  },
  backendPrevetFormPost: (schema, version) => {
    // Updating form data for existing `prevetJson`
    if (version === '1.0') {
      const { formData, riskProfile } = schema || {};
      const formattedPrevetJson = formatPrevetFieldsBackend(formData);
      return {
        dataUpdating: {
          ...(!isEmpty(riskProfile) && { riskProfile }),
          prevetJson: formattedPrevetJson
        }
      };
    }
    return schema;
  },
  notifications: (schema, version) => {
    // Updating form data for existing `prevetJson`
    if (version === '1.0') {
      const { prevets, options, email } = schema;
      const { isAppReview } = options || {};
      const count = !isEmpty(prevets)
        ? prevets.filter((aPrevet) => {
            const { prevet } = aPrevet || {};
            const { employeeOnly, prevetStatus } = prevet || {};
            const { assignedAppReviewEmployee } = employeeOnly || {};
            const { email: assignedAppReviewEmail } = assignedAppReviewEmployee || {};
            const status = ignoreCase(prevetStatus);
            const includeAppReview =
              isAppReview &&
              status === 'waiting_on_app_review' &&
              (isEqual(email, assignedAppReviewEmail) || isEmpty(assignedAppReviewEmail));
            return includeAppReview;
          }).length || null
        : null;
      return count;
    }
    return schema;
  },
  backendAssignToMe: (schema, version) => {
    if (version === '1.0') {
      const { employeeId = '' } = schema;
      return {
        employeeAssignment: {
          expectedCurrentlyAssignedEmployeeId: employeeId || ''
        }
      };
    }
    return schema;
  },
  backendPrevetStatusPost: (schema, version) => {
    // Updating the status
    if (version === '1.0') {
      const { type, valuesForBackend } = schema || {};
      const { eligibleBanks = [], recommendation = {} } = valuesForBackend || {};
      return {
        prevetStatusChange: {
          prevetStatus: type,
          ...(!isEmpty(recommendation) && { prevetRecommendation: recommendation }),
          ...(type === 'proceed' && { eligibleBanks: eligibleBanks.map((aBank) => aBank.value) })
        }
      };
    }
    return schema;
  }
};

const getAppListHiddenColumns = (options) => {
  const { appCompletionStatus } = options || {};
  const tableFilters = [
    'relationship',
    'relationshipId',
    'assignedCreditEmployeeId',
    'assignedAppReviewEmployeeId'
  ];
  const defaultHidden = ['otherData', 'completedData', 'prevetId', ...tableFilters];
  return [...(appCompletionStatus === 'uncompleted_only' ? ['completed'] : []), ...defaultHidden];
};

export const getFrontendFormData = (options) => {
  const { userType, prevetId, prevetStatusEnum, prevetJson, filesList, prevetName, relationship } =
    options || {};
  const { businessInfo, additionalInfo } = prevetJson || {};
  const formattedGeneralInfo = {
    prevetName,
    relationshipId: relationship?.relationshipId
  };
  const formattedBusinessInfo = {
    legalName: businessInfo?.legalName,
    dbaName: businessInfo?.dbaName,
    physicalAddress: businessInfo?.physicalAddress,
    city: businessInfo?.city,
    state: businessInfo?.state,
    zip: businessInfo?.zip,
    businessEmailAddress: businessInfo?.businessEmailAddress,
    url: businessInfo?.url,
    productsAndServicesSold: businessInfo?.productsAndServicesSold,
    preferredMcc: businessInfo?.preferredMcc,
    averageTimeToDelivery: businessInfo?.averageTimeToDelivery
  };
  const formattedAdditionalInfo = {
    stateIncorporated: additionalInfo?.stateIncorporated,
    ownershipType: getOwnershipTypeFrontend(additionalInfo?.ownershipType),
    averageMonthlySalesVolume: additionalInfo?.averageMonthlySalesVolume,
    averageTicket: additionalInfo?.averageTicket,
    highestTicketAmount: additionalInfo?.highestTicketAmount,
    equipmentOrECommPlatform: additionalInfo?.equipmentOrECommPlatform,
    averagePercentageOfRefunds: additionalInfo?.averagePercentageOfRefunds,
    averagePercentageOfChargebacks: additionalInfo?.averagePercentageOfChargebacks,
    averagePercentageOfInternationalSales: additionalInfo?.averagePercentageOfInternationalSales,
    recurringTransactions: additionalInfo?.recurringTransactions
  };
  const formattedCustomFields = {
    monthAndYearStarted: additionalInfo?.monthAndYearStarted
  };
  const formattedSalesMakeup = {
    swipedPercentage: additionalInfo?.salesMakeup?.swipedPercentage,
    keyedPercentage: additionalInfo?.salesMakeup?.keyedPercentage,
    mailOrderPercentage: additionalInfo?.salesMakeup?.mailOrderPercentage,
    internetPercentage: additionalInfo?.salesMakeup?.internetPercentage
  };
  const isCompleted = prevetCompletedStatuses.includes(prevetStatusEnum);
  const disableDeleteFile = userType === 'employee' ? isCompleted : true;
  const formattedFilesData = !isEmpty(filesList)
    ? transformData({
        data: {
          filesList: filesList.map((f) => ({ ...f, guidKey: 'prevetId', guidValue: prevetId })),
          userType,
          disableDelete: disableDeleteFile || false,
          ...(userType === 'employee' && { disableEdit: isCompleted })
        },
        toSchema: 'frontend',
        template: filesWithTagsTemplate
      })
    : [];
  const frontendFormData = {
    prevetId,
    generalSection: formattedGeneralInfo,
    businessInfoSection: formattedBusinessInfo,
    additionalInfoSection: formattedAdditionalInfo,
    customFieldsSection: formattedCustomFields,
    salesMakeupSection: formattedSalesMakeup,
    formattedFiles: formattedFilesData
  };
  return frontendFormData;
};

export const getOwnershipTypeFrontend = (backendData) => {
  const { soleProprietorship, partnership, nonProfit, publicCorp, privateCorp, llc, government } =
    backendData || {};
  if (soleProprietorship) {
    return 'soleProprietorship';
  }
  if (partnership) {
    return 'partnership';
  }
  if (nonProfit) {
    return 'nonProfit';
  }
  if (publicCorp) {
    return 'publicCorp';
  }
  if (privateCorp) {
    return 'privateCorp';
  }
  if (llc) {
    return 'llc';
  }
  if (government) {
    return 'government';
  }
  return null;
};

const formatPrevetFieldsBackend = (frontendData) => {
  const {
    businessInfoSection = {},
    additionalInfoSection = {},
    salesMakeupSection = {},
    customFieldsSection = {}
  } = frontendData || {};
  const formattedPrevetFields = {
    businessInfo: {
      legalName: businessInfoSection.legalName,
      dbaName: businessInfoSection.dbaName,
      physicalAddress: businessInfoSection.physicalAddress,
      city: businessInfoSection.city,
      state: businessInfoSection.state,
      zip: businessInfoSection.zip,
      businessEmailAddress: businessInfoSection.businessEmailAddress,
      url: businessInfoSection.url,
      productsAndServicesSold: businessInfoSection.productsAndServicesSold,
      preferredMcc: businessInfoSection.preferredMcc,
      averageTimeToDelivery: businessInfoSection.averageTimeToDelivery
    },
    additionalInfo: {
      stateIncorporated: additionalInfoSection.stateIncorporated,
      monthAndYearStarted: customFieldsSection.monthAndYearStarted,
      ownershipType: {
        soleProprietorship: false,
        partnership: false,
        nonProfit: false,
        publicCorp: false,
        privateCorp: false,
        llc: false,
        government: false,
        ...(!isEmpty(additionalInfoSection.ownershipType) && {
          [`${additionalInfoSection.ownershipType}`]: true
        })
      },
      averageMonthlySalesVolume: additionalInfoSection.averageMonthlySalesVolume,
      averageTicket: additionalInfoSection.averageTicket,
      highestTicketAmount: additionalInfoSection.highestTicketAmount,
      equipmentOrECommPlatform: additionalInfoSection.equipmentOrECommPlatform,
      averagePercentageOfRefunds: additionalInfoSection.averagePercentageOfRefunds,
      averagePercentageOfChargebacks: additionalInfoSection.averagePercentageOfChargebacks,
      averagePercentageOfInternationalSales:
        additionalInfoSection.averagePercentageOfInternationalSales,
      recurringTransactions: additionalInfoSection.recurringTransactions,
      salesMakeup: {
        swipedPercentage: salesMakeupSection.swipedPercentage,
        keyedPercentage: salesMakeupSection.keyedPercentage,
        mailOrderPercentage: salesMakeupSection.mailOrderPercentage,
        internetPercentage: salesMakeupSection.internetPercentage
      }
    }
  };
  return formattedPrevetFields;
};

export default prevetTemplate;
