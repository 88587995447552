import React from 'react';
import PropTypes from 'prop-types';

export class Page extends React.PureComponent {
  render() {
    const { children, style, id, 'data-testid': dataTestId } = this.props;
    return (
      <div
        id={id}
        data-testid={dataTestId}
        className="page mainSitePage"
        style={{
          flex: '1',
          overflow: 'auto',
          position: 'relative',
          ...style
        }}>
        {children}
      </div>
    );
  }
}

Page.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  'data-testid': PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object])
};

Page.defaultProps = {
  children: null,
  id: null,
  'data-testid': null,
  style: {}
};

export default Page;
