import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { AlertBar, Button, Input, Spinner } from '../index';
import { apiCall } from './_api';
import { isEmpty, envIsLocalOnly, sharedGetInnerAlertBarState, transformData } from './_helpers';
import { getMockData } from './data/stubDataMap';

export default class AddExternalCommunicationForm extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true,
      externalCommunicationValid: false,
      externalCommunication: '',
      spinnerLoading: false
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  setInput = (id, value, valid) => {
    this.updateState({
      [id]: value,
      [`${id}Valid`]: valid
    });
  };

  handleCloseAlertBar = (data) => {
    this.updateState(data);
  };

  showConfirmSwalEmployee = () => {
    swal({
      title: 'Send to Partner?',
      text: `You are about to send an external communication. This will be seen by the partner. Are you sure?`,
      buttons: ['Cancel', 'Send External Communication to Partner'],
      className: 'swal-corvia-default',
      icon: 'warning'
    }).then((result) => {
      if (result) {
        this.handleSubmit();
      }
    });
  };

  handleSubmit = async () => {
    const {
      axiosRequest,
      requestGuid,
      callback,
      options: { utils, getMockData: optionsGetMockData }, // Required for apiCall
      communicationEndpoint,
      template
    } = this.props;
    const { externalCommunication } = this.state;
    this.updateState({ spinnerLoading: true });
    const requestBody = transformData({
      data: {
        // crab uses `description`
        description: externalCommunication,
        // tickets page uses `externalCommunicationDescription`
        externalCommunicationDescription: externalCommunication
      },
      toSchema: 'backend',
      version: '1.0',
      template
    });
    const sharedOptions = {
      fullPageLoad: false,
      url: communicationEndpoint,
      method: 'put',
      tokenRequired: true,
      requestGuid
    };
    const stubData = {
      ...(envIsLocalOnly() && {
        stubData: !isEmpty(optionsGetMockData)
          ? await optionsGetMockData({ ...sharedOptions })
          : await getMockData({ ...sharedOptions })
      })
    };
    const apiRes = await apiCall(
      {
        ...sharedOptions,
        ...stubData
      },
      utils,
      requestBody
    );
    if (apiRes?.errorDetails instanceof Error) {
      const alertBarState = sharedGetInnerAlertBarState({
        type: 'warning',
        data: apiRes.errorDetails,
        axiosRequest
      });
      this.updateState(alertBarState);
    } else {
      callback && callback(requestBody);
    }
  };

  render() {
    const {
      alertBarType,
      alertBarMessage,
      alertBarTimeout,
      spinnerLoading,
      externalCommunicationValid
    } = this.state;
    const { userType } = this.props;
    return (
      <div style={{ width: '100%', display: 'flex' }}>
        <div>
          <AlertBar
            useInnerAlertBar
            options={{
              barStyle: alertBarType,
              message: alertBarMessage,
              timeout: alertBarTimeout,
              customWarnStyle: {
                width: '100%',
                height: '100%'
              }
            }}
            callback={this.handleCloseAlertBar}
          />
        </div>
        <Spinner loading={spinnerLoading} />
        <form
          id="add-external-communication-form"
          style={{ width: '100%', margin: '1em 1.5em 1em' }}>
          {userType === 'employee' && (
            <span style={{ color: 'var(--color-warning)', fontSize: '1.4rem' }}>
              This is an external communication and <b>will be seen by the partner</b>.
            </span>
          )}
          <Input
            required
            type="textarea"
            id="externalCommunication"
            label="External Communication"
            callback={this.setInput}
            wrapperStyle={{ minWidth: '150px' }}
          />
          <Button
            id="submit"
            onClick={userType === 'employee' ? this.showConfirmSwalEmployee : this.handleSubmit}
            disabled={!externalCommunicationValid}
            style={{ margin: '20px 0', flex: 1 }}>
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

AddExternalCommunicationForm.propTypes = {
  requestGuid: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func,
  axiosRequest: PropTypes.func.isRequired,
  options: PropTypes.shape({
    // Options is required from ( CRM / WEB )
    utils: PropTypes.shape({
      // REQUIRED, utils to handle the store
      handleApiError: PropTypes.func, // REQUIRED, to handle api error in the store
      createCsrfHeader: PropTypes.func // REQUIRED, to check user's token from the store
    }),
    getMockData: PropTypes.func
  }),
  userType: PropTypes.string,
  communicationEndpoint: PropTypes.string,
  template: PropTypes.oneOfType([PropTypes.object])
};
AddExternalCommunicationForm.defaultProps = {
  requestGuid: null,
  callback: null,
  options: {
    utils: {
      handleApiError: null,
      createCsrfHeader: null
    },
    getMockData: null // If using in a crm/web-specific component
  },
  userType: '',
  communicationEndpoint: '',
  template: {}
};
