// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, isEmpty } from './_helpers';
import { Button } from '../index';
import { icons } from '../images/_icons';
import { formatDescriptionText } from './_templateHelpers';

export class HistoryDescription extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      previewText: '',
      fullText: '',
      viewFull: false,
      showButton: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.setText();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  setText = () => {
    const { data } = this.props;
    const { description } = data || {};
    const maxLength = 70;
    let previewWords = '';
    let remainingWords = '';
    const wordsArray = !isEmpty(description)
      ? description.split(' ').reduce((acc, word) => {
          if (previewWords.length < maxLength + 1) {
            previewWords += word;
            return acc.concat(word);
          }
          remainingWords += word;
          return acc;
        }, [])
      : [];
    const showButton = !isEmpty(remainingWords) && previewWords.length > maxLength;
    const formattedText = (wordsArray || []).join(' ');
    const previewText = !isEmpty(formattedText)
      ? `${formattedText}${isEqual(formattedText, description) ? '' : '...'}`
      : '';
    this.updateState({
      previewText: showButton ? previewText : description,
      fullText: description,
      viewFull: showButton,
      showButton
    });
  };

  toggleFullDescription = () => {
    this.updateState((prevState) => ({ viewFull: !prevState.viewFull }));
  };

  render() {
    const { previewText, fullText, showButton, viewFull } = this.state;
    return (
      <>
        {viewFull
          ? formatDescriptionText(previewText, { useSingleLineBreak: true })
          : formatDescriptionText(fullText, { useSingleLineBreak: true })}
        {showButton && (
          <Button
            className="show-full-description-button"
            type="text"
            icon={viewFull ? icons.listMore : icons.listLess}
            onClick={this.toggleFullDescription}
            style={{
              fontSize: '1.3rem',
              margin: '0',
              width: '100%',
              height: '20px'
            }}
          />
        )}
      </>
    );
  }
}

HistoryDescription.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string
  })
};

HistoryDescription.defaultProps = {
  data: {
    description: ''
  }
};

export default HistoryDescription;
