import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { dateStringToIso } from '@f1/shared/src/_helpers';
import { createMockFile } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { mockEmployees } from '../employee/mockEmployees';

const mockRepayTransactionHoldGet = (body, params, _options) => {
  const { eventDate = '2020-08-02' } = params;
  const isItToday = eventDate === '2020-08-02';
  const isItYesterday = eventDate === '2020-08-01';
  const fileName = `HoldForReview-G001-${eventDate.replace(/-/g, '')}.xlsx`;
  const queryDate = dateStringToIso(eventDate);
  const mockFile1 = createMockFile(1, {
    staticFile: {
      fileName,
      tags: [{ tagObject: 'uploaded_by_system' }]
    }
  });
  const mockFile2 = createMockFile(1, {
    staticFile: {
      fileName,
      tags: [{ tagObject: 'uploaded_by_employee' }]
    }
  });
  const originalFile = {
    fileArrivalTime: queryDate,
    fileEntries: getMockValue({ type: 'number' }),
    fileData: {
      ...mockFile1,
      fileCreationTimestamp: queryDate,
      guidType: '',
      guidValue: ''
    }
  };
  const processedFile = {
    fileUploadTime: queryDate,
    uploadedByEmployee: {
      email: mockEmployees.employee15.email,
      firstName: mockEmployees.employee15.firstName,
      lastName: mockEmployees.employee15.lastName,
      manager: mockEmployees.employee15.manager,
      employeeId: mockEmployees.employee15.employeeId
    },
    doNotProcessEntries: getMockValue({ type: 'number' }),
    fileData: {
      ...mockFile2,
      fileCreationTimestamp: queryDate
    }
  };
  return {
    eventDate,
    originalFile: isItYesterday ? {} : originalFile,
    processedFile: isItToday || isItYesterday ? {} : processedFile
  };
};

export default mockRepayTransactionHoldGet;
