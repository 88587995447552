// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { rs } from './_styles';

export class RadioSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.switchWrap = React.createRef();
    this.state = {
      checked: null,
      indicatorWidth: 0,
      indicatorPos: 0,
      indicatorActive: false
    };
    this.inputRefs = {};
  }

  componentDidMount() {
    this.mounted = true;
    this.setSelected();
    window.addEventListener('resize', this.resizeHighlight);
    window.addEventListener('animationend', this.resizeHighlight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.resizeHighlight);
    window.addEventListener('animationend', this.resizeHighlight);
  }

  setSelected = () => {
    const { radio } = this.props;
    radio.options.forEach((elem, index) => {
      if (elem.checked) {
        setTimeout(() => {
          // Need to give the radio time to render so the selector can find its size.
          this.updateSelected(this.setId(elem, index));
        }, 200);
      }
    });
  };

  updateSelected = (radioId) => {
    this.mounted && this.setState({ checked: radioId });
    this.updateIndicator(radioId);
  };

  resizeHighlight = () => {
    const { checked } = this.state;
    this.updateIndicator(checked);
  };

  handleChange = (event) => {
    const { callback } = this.props;
    const { currentTarget: { checked = null, id = null, name, value } = event } = event || {};
    this.mounted && this.updateSelected(id);
    if (callback) {
      callback(name, value === 'none' ? null : value, checked);
    }
  };

  setId = (radio, index) => {
    const {
      radio: { name }
    } = this.props;
    return `${name}${radio.text.replace(/\s+/g, '-').toLowerCase()}${index}`;
  };

  updateIndicator(radioId) {
    this.mounted && this.setState({ indicatorActive: true });
    if (
      this.switchWrap &&
      this.inputRefs[radioId] &&
      this.switchWrap.current &&
      this.inputRefs[radioId].current
    ) {
      const parentCoords = this.switchWrap.current.getBoundingClientRect();
      const targetCoords = this.inputRefs[radioId].current.getBoundingClientRect();
      const coords = {
        width: targetCoords.width,
        height: targetCoords.height,
        top: targetCoords.top - parentCoords.top,
        left: targetCoords.left - parentCoords.left
      };
      this.mounted && this.setState({ indicatorWidth: coords.width, indicatorPos: coords.left });
    }
  }

  render() {
    const { indicatorActive, indicatorWidth, indicatorPos, checked } = this.state;
    const { id, radio, wrapStyle, theme, disabled } = this.props;
    const indicatorStyle = indicatorActive
      ? rs.radioSwitchCSSIndicatorActive
      : rs.radioSwitchCSSIndicator;
    const transform = {
      WebkitTransform: `translate(${indicatorPos}px,0)`,
      MozTransform: `translate(${indicatorPos}px,0)`,
      transform: `translate(${indicatorPos}px,0)`
    };
    return (
      <div
        id={id}
        ref={this.switchWrap}
        className="radioSwitchCSS switch_language"
        style={{ ...rs.radioSwitchCSS, ...wrapStyle }}
        role="article"
        aria-label="Radio switch wrapper">
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {radio.options.map((radioInput, i) => {
            const radioId = this.setId(radioInput, i);
            const newRef = React.createRef();
            this.inputRefs[radioId] = newRef;
            return (
              <div
                ref={newRef}
                className="radio"
                style={{ ...rs.radioSwitcCSSWrap, ...(disabled && { cursor: 'default' }) }}
                key={radioId}>
                {radioInput.badge > 0 && (
                  <div
                    id={`${radioInput.value}Badge`}
                    style={rs.badge}
                    {...(radioInput.badgeInfoText && { title: radioInput.badgeInfoText })}>
                    {radioInput.badge}
                  </div>
                )}
                <input
                  type="radio"
                  style={rs.radio}
                  id={radioId}
                  name={radio.name}
                  value={radioInput.value}
                  checked={checked === radioId}
                  data-checked={checked === radioId}
                  onChange={!disabled ? this.handleChange : () => {}}
                />
                <label
                  className="radio-toolbar"
                  style={{
                    ...(checked === radioId ? rs.labelCSSActive : rs.label),
                    ...(disabled && { cursor: 'default' }),
                    ...(theme?.highlightColor &&
                      checked !== radioId && {
                        color: theme.highlightColor
                      }),
                    ...(disabled &&
                      checked !== radioId && {
                        color: 'var(--color-disabled)'
                      })
                  }}
                  htmlFor={radioId}>
                  {radioInput.text}
                </label>
              </div>
            );
          })}
        </div>
        <div
          className={indicatorActive ? 'active radioSwitchCSSIndicator' : 'radioSwitchCSSIndicator'}
          style={{
            ...indicatorStyle,
            width: `${indicatorWidth}px`,
            ...transform,
            ...(indicatorActive &&
              theme?.highlightColor && {
                backgroundColor: theme.highlightColor
              }),
            ...(disabled && {
              backgroundColor: 'var(--color-disabled)'
            })
          }}
        />
        <div
          style={{
            ...rs.radioSwitchCSSBackground,
            ...(theme?.backgroundColor && {
              backgroundColor: theme.backgroundColor
            })
          }}
        />
      </div>
    );
  }
}

RadioSwitch.propTypes = {
  id: PropTypes.string,
  callback: PropTypes.func,
  wrapStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  radio: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        checked: PropTypes.bool,
        badge: PropTypes.number
      })
    ).isRequired
  }),
  theme: PropTypes.shape({
    backgroundColor: PropTypes.string,
    highlightColor: PropTypes.string
  }),
  disabled: PropTypes.bool
};

RadioSwitch.defaultProps = {
  id: null,
  callback: null,
  wrapStyle: {},
  radio: {},
  theme: null,
  disabled: false
};

export default RadioSwitch;
