import { getMockValue } from '../_mockDataHelpers';

export const mockAchFundingSummeryGet = (body, params, options) => {
  const { guid } = options || {};
  const { yearMonth } = params || {};
  return {
    [guid]: {
      achFundingSummaryList: Array.from({ length: 30 }).map(() =>
        achFundingSummaryRow({ guid, yearMonth })
      )
    }
  };
};

const achFundingSummaryRow = (options) => ({
  merchant: {
    dbaName: getMockValue({ type: 'string' }),
    merchantGuid: options.guid,
    mid: getMockValue({ type: 'mid' })
  },
  totalDepositAmount: getMockValue({ type: 'number' }),
  totalWithdrawAmount: getMockValue({ type: 'number' }),
  yearMonth: options.yearMonth
});

export default mockAchFundingSummeryGet;
