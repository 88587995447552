import { snakeToTitle } from '@f1/shared/src/_helpers';

const fileOutputTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0' && Array.isArray(schema)) {
      return schema?.map((file) => ({
        fileName: file?.file?.fileName,
        type: snakeToTitle(file?.fileType),
        fileType: file?.fileType,
        timestamp: file?.file?.fileCreationTimestamp,
        relativePath: file?.file?.relativePath,
        url: file?.file?.url
      }));
    }
    return schema;
  }
};

export default fileOutputTemplate;
