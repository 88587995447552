import { domainName, getUrlObject, isEmpty, getParams } from './_helpers';

export const localUrl = `http://local.${domainName}.com:3000`;

export const devUrlBase = `https://portal.dev.${domainName}.com`;

// api url to use when we need to refresh dev data
export const devCreateTestUserApiUrl = `https://mock.dev.${domainName}.com/v2`;

export const devUrlCrm = `https://crm.dev.${domainName}.com`;
export const devApiUrlCrm = `https://api.dev.${domainName}.com/crm`;

export const statusCodes = {
  success: {
    GET: 200,
    POST: 204,
    PUT: 200,
    DELETE: 204,
    OPTIONS: 204
  }
};

export const isTokenRequired = (apiUrl, reqsWithNoTokenRequired = []) =>
  !isEmpty(apiUrl) && !reqsWithNoTokenRequired.some((p) => apiUrl === p);

export const isSignIn = (apiUrl) => !isEmpty(apiUrl) && apiUrl.includes('/user/signIn');

export const parseRequestGuid = (headers) => {
  const { requestguid = null } = !isEmpty(headers) ? headers : {};
  const getGuid = (guidString) => {
    if (guidString === null) return null;
    return guidString === '{}' ? { emptyObject: '{}' } : JSON.parse(guidString);
  };
  const mergeGuids = (guidList, allGuidList) => (allGuidList || []).concat(...guidList);
  const guid = getGuid(requestguid);
  const parsed = guid !== null ? guid : {};
  /**
   * @DEPRECATED - Do not add to guidStringKeys, these were added to initially handle
   * hard-coded mock data. We now generate dynamic data for arrays of guids.
   */
  const guidStringKeys = [
    'relationshipIdList',
    'emptyObject',
    'relationshipIdExcludeDownlinesList',
    'relationshipIdExcludeDownlines'
  ];
  return Object.entries(parsed).reduce(
    (acc, [key, value]) => ({
      ...acc,
      type: key,
      guid: !guidStringKeys.includes(key) ? value : JSON.stringify(value),
      ...(!guidStringKeys.includes(key) &&
        Array.isArray(value) && {
          // If there are multiple guid types with arrays of guids, merge the guids
          guid: mergeGuids(value, acc.guid || [])
        })
    }),
    {}
  );
};

export const parseRequestBody = (body) => {
  const requestBody = !isEmpty(body) ? body.toString() : null;
  return JSON.parse(requestBody) || {};
};

export const setResponseHeaders = (options) => {
  const { req = {}, res = {}, reqsWithNoTokenRequired = [] } = options || {};
  const { headers: requestHeaders, url } = req;
  const { headers: responseHeaders } = res;
  responseHeaders['access-control-allow-origin'] = requestHeaders.origin;
  responseHeaders['access-control-allow-headers'] =
    'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,RequestGuid,Set-Cookie,set-cookie,x-csrf-token';
  responseHeaders['access-control-allow-methods'] =
    'GET,HEAD,POST,PUT,DELETE,CONNECT,OPTIONS,TRACE,PATCH';
  if (isTokenRequired(url, reqsWithNoTokenRequired)) {
    responseHeaders['access-control-allow-credentials'] = true;
  }
  return true;
};

export const getResponseStatus = (options) => {
  const { url, method, customMock = {}, postReqsWithResBody = [] } = options || {};
  if (url === customMock.endpoint && customMock.mockStatus && method !== 'OPTIONS') {
    return customMock.mockStatus;
  }
  if (method === 'POST') {
    const isSignInUrl = isSignIn(url);
    const hasResponseBody = Array.isArray(postReqsWithResBody)
      ? postReqsWithResBody.some((r) => url === r)
      : false;
    if (isSignInUrl || hasResponseBody) {
      // POST requests that return a response body need to return the GET status code
      return statusCodes.success.GET;
    }
  }
  return statusCodes.success[method];
};

export const getResponseBody = (options) => {
  const { req, mockData = null } = options || {};
  const { headers: requestHeaders, url, body } = req;
  if (isSignIn(url) && mockData) {
    const { email = null } = parseRequestBody(body);
    return mockData[email] || null;
  }
  const { guid = null } = parseRequestGuid(requestHeaders);
  const responseBody = mockData && guid ? mockData[guid] : mockData;
  return responseBody || null;
};

export const setMockResponse = (options) => {
  const { req, res, mockData = null, customMock = {}, customRequests = {} } = options || {};
  const { url, method } = req;
  const responseBody = getResponseBody({ req, mockData });
  setResponseHeaders({
    req,
    res,
    reqsWithNoTokenRequired: customRequests.reqsWithNoTokenRequired
  });
  res.statusCode = getResponseStatus({
    url,
    method,
    customMock,
    postReqsWithResBody: customRequests.postReqsWithResBody
  });
  if (responseBody) {
    res.setBody(responseBody);
  }
  return true;
};

const logColors = {
  magenta: '\x1b[35m%s\x1b[0m',
  red: '\x1b[31m%s\x1b[0m',
  green: '\x1b[32m%s\x1b[0m',
  cyan: '\x1b[36m%s\x1b[0m',
  yellow: '\x1b[33m%s\x1b[0m',
  redBg: '\x1b[41m%s\x1b[0m',
  greenBg: '\x1b[42m%s\x1b[0m',
  blueBg: '\x1b[44m%s\x1b[0m',
  magentaBg: '\x1b[45m%s\x1b[0m'
};

export const logger = (options) => {
  const {
    log = '',
    color = null,
    testName = false,
    testPassed = null,
    t: testController = null,
    apiCall = false,
    apiSuccess = false,
    apiError = false,
    signInUser = '',
    signInSuccess = false,
    signInError = false
  } = options;
  if (typeof testPassed === 'boolean' && !isEmpty(testController)) {
    const logColor = testPassed ? 'green' : 'red';
    const icon = testPassed ? '✓' : '✖';
    const failedText = !testPassed ? ' (failed or unstable)' : '';
    console.log(logColors[logColor], ` ${icon} ${testController.testRun.test.name}${failedText}`);
  }
  if (testName && !isEmpty(testController)) {
    // logging test name
    if (!isEmpty(signInUser) && isEmpty(log) && !signInSuccess && !signInError) {
      // TODO: ENG-2893 remove this if block for existing logs for sign-in user
      console.log(
        logColors.magenta,
        `\nSigned in as ${signInUser.email} for the ${testController.testRun.test.name}`
      );
    } else if (!isEmpty(signInUser) && signInSuccess) {
      // new log for successful sign in
      console.log(
        logColors.magenta,
        `\n[FIXTURE] ${testController.testRun.test.testFile.currentFixture.name}`
      );
      console.log(logColors.magenta, `[TEST]    ${testController.testRun.test.name}`);
      console.log(logColors.magenta, `[USER]    ${signInUser.email}`);
      console.log(logColors.magenta, `[LOG]     ✓ SIGN IN SUCCESS. ${log}`);
    } else if (!isEmpty(signInUser) && signInError) {
      // new log for error on sign in
      console.log(
        logColors.red,
        `\n[FIXTURE] ${testController.testRun.test.testFile.currentFixture.name}`
      );
      console.log(logColors.red, `[TEST]    ${testController.testRun.test.name}`);
      console.log(logColors.red, `[USER]    ${signInUser.email}`);
      console.log(logColors.red, `[LOG]     ✖ SIGN IN ERROR. ${log}`);
    } else {
      const logColor = !isEmpty(color) ? logColors[color] : '';
      console.log(
        logColor,
        `\n[FIXTURE] ${testController.testRun.test.testFile.currentFixture.name}`
      );
      console.log(logColor, `[TEST]    ${testController.testRun.test.name}`);
      if (!isEmpty(signInUser)) {
        console.log(logColor, `[USER]    ${signInUser.email}`);
      }
      if (!isEmpty(log)) {
        console.log(logColor, `[LOG]     ${log}`);
      }
    }
  } else if (apiCall) {
    // logging when an api call is being made
    const logColor = !isEmpty(color) ? color : 'magenta';
    console.log(logColors[logColor], `\n ${log}`);
  } else if (apiSuccess || apiError) {
    // logging api call error or success
    const logColor = apiSuccess ? 'green' : 'red';
    const icon = apiSuccess ? '✓' : '✖';
    console.log(logColors[logColor], ` ${icon} ${log}`);
  } else if (!isEmpty(color) && !isEmpty(logColors[color])) {
    // logging with a specified color
    console.log(logColors[color], log);
  } else {
    console.log(log);
  }
  return log;
};

export const getMockDataFromMap = async (options) => {
  // returns mock data by METHOD - eg. { GET: <mock_data> }
  const {
    url,
    method = 'get',
    body = {},
    config = { params: {} },
    map,
    isFuncTest = false,
    requestHeaders = {}
  } = options || {};
  const formattedMethod = method.toUpperCase();
  if (isFuncTest && formattedMethod === 'OPTIONS') {
    // for functional tests ONLY, the OPTIONS request only needs to exist
    return {};
  }
  const { urlWithNoParams, queryStringParams } = getUrlObject(url);
  const mapMatch = map[urlWithNoParams];
  if (isFuncTest && mapMatch && mapMatch[formattedMethod] instanceof Function) {
    // for functional tests ONLY, if the value is a functon,
    // the function needs to be CALLED with the necessary args in order to return data
    const params = await getParams({
      getApiParams: true,
      apiParams: queryStringParams || config.params
    });
    const { guid = null, type: guidKey = '' } = parseRequestGuid(requestHeaders);
    const mockOptions = {
      // need to mock the options passed in mock apiCall here
      guid,
      guidKey,
      isFuncTest
    };
    const formattedBody =
      !isEmpty(body) && !isEmpty(body.toString()) ? JSON.parse(body.toString()) : {};
    return {
      [formattedMethod]: mapMatch[formattedMethod](formattedBody, params, mockOptions)
    };
  }
  return mapMatch || null;
};

export const filterByYearMonth = (data, options) => {
  const { yearMonthFrom, yearMonthTo } = options || {};
  let filteredData = data;
  if (yearMonthFrom) {
    filteredData = filteredData.filter(
      (itm) => itm.month.replace('-', '') >= yearMonthFrom.replace('-', '')
    );
  }
  if (yearMonthTo) {
    filteredData = filteredData.filter(
      (itm) => itm.month.replace('-', '') <= yearMonthTo.replace('-', '')
    );
  }
  return filteredData;
};
