import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HamburgerSVG from './HamburgerSVG';
import { hamburgerCSS, hamburgerSVGCSS } from './_styles';

class Hamburger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
  }

  toggleMenu = () => {
    const { isActive } = this.state;
    const menu = document.querySelector('#siteNav.header');
    const subLinks = document.querySelectorAll('#siteNav.header .navUl .subLink');
    if (isActive) {
      menu.classList.remove('show');
      if (subLinks) {
        /* istanbul ignore next */
        subLinks.forEach((subLink) => {
          subLink.classList.add('hidden');
        });
      }
    } else {
      menu.classList.add('show');
      if (subLinks) {
        /* istanbul ignore next */
        subLinks.forEach((subLink) => {
          subLink.classList.remove('hidden');
        });
      }
    }
  };

  onClick = () => {
    const { isActive } = this.state;
    this.setState({
      isActive: !isActive
    });
    this.toggleMenu();
  };

  render() {
    const { isActive } = this.state;
    const { SECONDARY, DEFAULT } = hamburgerCSS;
    const destination = isActive ? SECONDARY : DEFAULT;
    const { userWidget } = this.props;
    return (
      <div
        role="button"
        tabIndex="0"
        onKeyDown={this.onClick}
        className={`Hamburger${isActive ? ' isActive' : ''}`}
        onClick={this.onClick}>
        <HamburgerSVG
          defaultStyle={DEFAULT}
          style={hamburgerSVGCSS(destination)}
          width={20}
          height={20}
          color={(delta) =>
            `rgba(${Math.round(userWidget ? delta.cR2 : delta.cR)},${Math.round(userWidget ? delta.cG2 : delta.cG)},${Math.round(userWidget ? delta.cB2 : delta.cB)},${userWidget ? delta.cA2 : delta.cA})`
          }
          menuTop={(delta) =>
            `M${delta.mt1},${delta.mt2}c0,0 ${delta.mt3},${delta.mt4} ${delta.mt5},${delta.mt6}c${delta.mt7},${delta.mt8} ${delta.mt9},${delta.mt10} ${delta.mt11},${delta.mt12}c${delta.mt13},${delta.mt14} ${delta.mt15},${delta.mt16} ${delta.mt17},${delta.mt18}c${delta.mt19},${delta.mt20} ${delta.mt21},${delta.mt22} ${delta.mt23},${delta.mt24}c${delta.mt25},${delta.mt26} ${delta.mt27},${delta.mt28} ${delta.mt29},${delta.mt30}c${delta.mt31},${delta.mt32} ${delta.mt33},${delta.mt34} ${delta.mt35},${delta.mt36}c${delta.mt37},${delta.mt38} ${delta.mt39},${delta.mt40} ${delta.mt41},${delta.mt42}c${delta.mt43},${delta.mt44} ${delta.mt45},${delta.mt46} ${delta.mt47},${delta.mt48}Z`
          }
          menuMid={(delta) =>
            `M${delta.mm1},${delta.mm2}c0,0 ${delta.mm3},0.003 ${delta.mm4},0c2.416,-0.002 2.416,-3.996 0,-3.991c-2.499,0.004 ${delta.mm5},0 ${delta.mm6},0l${delta.mm7},0c${delta.mm8},0.008 ${delta.mm9},3.97 0,3.991c2.227,0.02 ${delta.mm10},0 ${delta.mm11},0Z`
          }
          menuBtm={(delta) =>
            `M${delta.mb1},${delta.mb2}c0,0 ${delta.mb3},${delta.mb4} ${delta.mb5},${delta.mb6}c${delta.mb7},${delta.mb8} ${delta.mb9},${delta.mb10} ${delta.mb11},${delta.mb12}c${delta.mb13},${delta.mb14} ${delta.mb15},${delta.mb16} ${delta.mb17},${delta.mb18}c${delta.mb19},${delta.mb20} ${delta.mb21},${delta.mb22} ${delta.mb23},${delta.mb24}c0,0 ${delta.mb25},${delta.mb26} ${delta.mb27},${delta.mb28}c${delta.mb29},${delta.mb30} ${delta.mb31},${delta.mb32} ${delta.mb33},${delta.mb34}c${delta.mb35},${delta.mb36} ${delta.mb37},${delta.mb38} ${delta.mb39},${delta.mb40}c${delta.mb41},${delta.mb42} ${delta.mb43},${delta.mb44} ${delta.mb45},${delta.mb46}l0,0Z`
          }
        />
      </div>
    );
  }
}

Hamburger.propTypes = {
  userWidget: PropTypes.bool
};

Hamburger.defaultProps = {
  userWidget: false
};

export default Hamburger;
