import { isEmpty } from '@f1/shared/src/_helpers';
import {
  buildPriorityFields,
  buildNeteviaFields,
  buildRepayFields
} from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationJson';
import { merchantGuids } from '../../guids';

export const mockMpaFeesGet = (body, params, options) => {
  const { guid } = options || {};
  const mockNeteviaGuids = [merchantGuids.merchant04, merchantGuids.merchant05];
  const mockRepayGuids = [merchantGuids.merchant01];
  const mockPriorityAxiomGuids = [merchantGuids.merchant06];
  const mockPriorityWellsFargoGuids = [merchantGuids.merchant07];
  const mockPriorityTsysFields = mockPriorityAxiomGuids.includes(guid)
    ? buildPriorityFields(0, {})
    : {};
  const mockPriorityFirstDataFields = mockPriorityWellsFargoGuids.includes(guid)
    ? buildPriorityFields(1, {})
    : {};
  const mockNeteviaFields = mockNeteviaGuids.includes(guid) ? buildNeteviaFields(1, {}) : {};
  const mockRepayFields = mockRepayGuids.includes(guid) ? buildRepayFields(1) : {};
  const mockMerchantFees = {
    ...(!isEmpty(mockRepayFields) && {
      repayFields: {
        ratesAndFees: mockRepayFields.ratesAndFees
      }
    }),
    ...(!isEmpty(mockNeteviaFields) && {
      neteviaFields: {
        ratesAndFees: mockNeteviaFields.ratesAndFees,
        fees: mockNeteviaFields.fees
      }
    }),
    ...(!isEmpty(mockPriorityTsysFields) && {
      priorityFields: {
        tsysRatesAndFees: mockPriorityTsysFields.tsysRatesAndFees
      }
    }),
    ...(!isEmpty(mockPriorityFirstDataFields) && {
      priorityFields: {
        firstDataRatesAndFees: mockPriorityFirstDataFields.firstDataRatesAndFees
      }
    })
  };
  return {
    [guid]: mockMerchantFees
  };
};

export default mockMpaFeesGet;
