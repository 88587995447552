import { isEmpty, fileTagValues, sortData } from '@f1/shared/src/_helpers';

const fileV2Template = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      if (!(schema instanceof Array)) return [];
      return sortData(
        schema.map((file, index) => {
          const cleanedPath = cleanPath(file);
          const tags = !isEmpty(file?.tags) ? file.tags : [];
          return {
            name: !isEmpty(file?.customFileName)
              ? file?.customFileName
              : file?.fileName || 'unknown',
            path: cleanedPath,
            tags,
            selectedTagList: tags.map((selectedTag) => {
              const tagItem = fileTagValues.find(
                (fileTag) => fileTag.value === selectedTag.tagObject
              );
              return !isEmpty(tagItem)
                ? tagItem
                : { value: selectedTag.tagObject, title: selectedTag.tagObject };
            }),
            url: file?.url || '',
            displayName: `${isEmpty(cleanedPath) || cleanedPath === '/' ? '' : cleanedPath}${!isEmpty(file?.customFileName) ? file?.customFileName : file?.fileName || 'unknown'}`,
            key: index
          };
        }),
        'displayName'
      );
    }
    return schema || [];
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      return {
        file: {
          fileName: schema?.name,
          relativePath: schema?.path,
          tags: schema?.tags
        }
      };
    }
    return schema || {};
  }
};

// The file.path should not include the file.name, so clean it if it does
// This separation is important for display purposes and for the DELETE request
const cleanPath = (file) => {
  const tempPath = file?.relativePath || file?.absolutePath || '';
  const tempName = file?.fileName || '';
  if (tempPath.endsWith(tempName)) {
    return tempPath.substring(0, tempPath.lastIndexOf(tempName));
  }
  return tempPath;
};

export default fileV2Template;
