import React from 'react';
import PropTypes from 'prop-types';
import { Motion } from '@serprex/react-motion';

const DEFAULT_PRESERVEASPECTRATIO = 'xMinYMax slice';

const HamburgerSVG = ({
  defaultStyle = {},
  style = {},
  color = () => {},
  menuTop = () => {},
  menuMid = () => {},
  menuBtm = () => {},
  width = 20,
  height = 20,
  preserveAspectRatio = DEFAULT_PRESERVEASPECTRATIO
}) => (
  <Motion defaultStyle={defaultStyle} style={style}>
    {(delta) => (
      <svg viewBox={`0 0 ${width} ${height}`} preserveAspectRatio={preserveAspectRatio}>
        <path fill={color(delta)} d={menuTop(delta)} />
        <path fill={color(delta)} d={menuMid(delta)} />
        <path fill={color(delta)} d={menuBtm(delta)} />
      </svg>
    )}
  </Motion>
);

HamburgerSVG.propTypes = {
  defaultStyle: PropTypes.oneOfType([PropTypes.object]),
  style: PropTypes.oneOfType([PropTypes.object]),
  color: PropTypes.func,
  menuTop: PropTypes.func,
  menuMid: PropTypes.func,
  menuBtm: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  preserveAspectRatio: PropTypes.string
};

export default HamburgerSVG;
