// TODO: BIRB-8338
/* istanbul ignore file */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty, scrollToComponent, isInViewport } from './_helpers';
import { SideNavigation, Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import { Button } from './Button';

export function SideNav ({ links = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [activeSection, setActiveSection] = useState(false);
  const [isClickScroll, setIsClickScroll] = useState(false);
  const sideNavWrap = useRef();
  const navHeight = 82;

  useEffect(() => {
    const handleScroll = () => {
      const elem = sideNavWrap.current;
      if (elem) {
        const clientRect = sideNavWrap.current.getBoundingClientRect();
        const top = clientRect.top + document.body.scrollTop;
        setSticky(top <= navHeight);
        if (isOpen && !isClickScroll) {
          // only update on user scrolling, not if scrolling from a click event
          checkInView();
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    // Remove listener on cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen, isClickScroll]);
  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = async (elem) => {
    await setIsClickScroll(true);
    scrollToComponent(elem, { callback: () => setIsClickScroll(false) });
    setActiveSection(elem);
  };

  const checkInView = () => {
    let active = null;
    links.forEach((link) => {
      const elem = document.querySelector(link.targetId);
      if (isInViewport(elem, { offsetY: 100 }).isVisible) {
        active = link.targetId;
      }
    });
    setActiveSection(active);
  };

  return (
    <SideNavigation
      $isOpen={isOpen}
      className="SideNavigation"
      $type="white"
      $sticky={sticky.toString()}
      ref={sideNavWrap}
    >
      <div className="sideNavNav">
        <div className={`navWrap${sticky ? ' sticky' : ''}`}>
          <nav>
            {!isEmpty(links) && (
              <ul>
                {links.map(link => (
                  <li
                    key={uuidv4()}
                    {...activeSection === link.targetId && { className: 'active' }}
                  >
                    <Button type="text" onClick={() => handleClick(link.targetId)}>{link.text}</Button>
                    {!isEmpty(link.subLinks) && (
                      <ul>
                        {link.subLinks.map(subLink => (
                          <li key={uuidv4()}>
                            <Button type="text" onClick={() => handleClick(subLink.targetId)}>{subLink.text}</Button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </nav>
          <button
            aria-label="sideNavToggle"
            className="sideNavToggle"
            type="button"
            icon={icons.menu}
            onClick={toggleSideNav}
          >
            <Icon
              className="btnIcon"
              icon={icons.menu.src_color}
              style={{
                left: '-2px',
                width: '20px',
                height: '20px',
                top: '1px',
                position: 'relative'
              }}
              $useMask
            />
          </button>
        </div>
        <div className="spacer" />
      </div>
    </SideNavigation>
  );
}

SideNav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    targetId: PropTypes.string,
    subLinks: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      targetId: PropTypes.string
    }))
  }))
};

export default SideNav;
