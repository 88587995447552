export const externalIntegrationFormFields = {
  integrationTarget: {
    id: 'integrationTarget',
    label: 'Integration Target',
    type: 'text',
    fieldType: 'combobox',
    useBlockForm: true,
    displaySearch: true,
    list: [{ title: 'Iris', value: 'iris' }]
  },
  apiKey: {
    id: 'apiKey',
    label: 'API Key (Full Key)',
    type: 'text',
    fieldType: 'input',
    useBlockForm: true
  },
  apiClientUrl: {
    id: 'apiClientUrl',
    label: `Client's API Url`,
    type: 'url',
    fieldType: 'input',
    useBlockForm: true
  }
};

export const customIntegrationTargetObjects = {
  iris: {
    group: {
      id: 'group',
      label: `Group`,
      type: 'text',
      fieldType: 'input',
      useBlockForm: true
    },
    processor: {
      id: 'processor',
      label: `Processor`,
      type: 'text',
      fieldType: 'input',
      useBlockForm: true
    }
  }
};

export default externalIntegrationFormFields;
