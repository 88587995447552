import React from 'react';
import PropTypes from 'prop-types';
import { noDataCSS } from './_styles';

export class NoData extends React.PureComponent {
  render() {
    const { loading, err, wrapperStyles, customMessage, detailsText } = this.props;
    return (
      <div className="noData" style={{ ...noDataCSS.wrap, ...wrapperStyles }} aria-label="No Data">
        {loading ? (
          <span style={noDataCSS.message}>Loading...</span>
        ) : (
          <>
            <span style={err ? noDataCSS.errorMessage : noDataCSS.successMessage}>
              {customMessage || 'No data available'}
            </span>
            {detailsText && <span style={noDataCSS.details}>{` (${detailsText})`}</span>}
          </>
        )}
      </div>
    );
  }
}

NoData.propTypes = {
  loading: PropTypes.bool,
  err: PropTypes.bool,
  wrapperStyles: PropTypes.oneOfType([PropTypes.object]),
  customMessage: PropTypes.string,
  detailsText: PropTypes.string
};

NoData.defaultProps = {
  loading: false,
  err: false,
  wrapperStyles: {},
  customMessage: null,
  detailsText: null
};

export default NoData;
