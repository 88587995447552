import { isEqual } from '@f1/shared/src/_helpers';
import { getMockPageData, mockPagingInfo } from '@f1/shared/src/data/_mockDataHelpers';
import { merchantGuids, ticketGuids } from '../guids';

import mockTickets, {
  openTicketStatuses,
  closedTicketStatuses,
  createMockTickets
} from './mockTickets';

export const mockTicketsGetRes = {
  [ticketGuids.default]: {
    tickets: [mockTickets.ticket01.response],
    pagingInfo: {
      totalNumberOfRecords: 1,
      pageIndex: 1,
      pageSize: 1
    }
  },
  [ticketGuids.all]: {
    // "{}"
    tickets: [
      mockTickets.ticket01.response,
      mockTickets.ticket02.response,
      mockTickets.ticket03.response,
      mockTickets.ticket04.response,
      mockTickets.ticket05.response,
      mockTickets.ticket06.response,
      mockTickets.ticket07.response,
      mockTickets.ticket08.response,
      mockTickets.ticket09.response,
      mockTickets.ticket10.response,
      mockTickets.ticket11.response,
      mockTickets.ticket12.response,
      mockTickets.ticket13.response,
      mockTickets.ticketSnoozed01.response,
      mockTickets.ticketSnoozed02.response,
      mockTickets.ticketWithDeprecatedPriority.response,
      mockTickets.systemGeneratedTicket.response
    ],
    pagingInfo: {
      totalNumberOfRecords: 14,
      pageIndex: 1,
      pageSize: 1
    }
  },
  [ticketGuids.merchantGuid]: {
    tickets: [mockTickets.ticketMerchant01.response],
    pagingInfo: {
      totalNumberOfRecords: 1,
      pageIndex: 1,
      pageSize: 1
    }
  },
  [ticketGuids.partnerId]: {
    tickets: [mockTickets.ticketPartner01.response],
    pagingInfo: {
      totalNumberOfRecords: 1,
      pageIndex: 1,
      pageSize: 1
    }
  },
  [ticketGuids.relationshipId]: {
    tickets: [mockTickets.ticketRelationship01.response],
    pagingInfo: {
      totalNumberOfRecords: 1,
      pageIndex: 1,
      pageSize: 1
    }
  }
};

const staticGuidToTicketMap = {
  // Only return the tickets with these names for these guids
  [merchantGuids.merchant01]: [
    { name: mockTickets.ticket11.response.name } // For merchant "approve/deny" FT
  ]
};

// Create all mock tickets once so paged data isn't duplicated
const allMockTickets = createMockTickets();

export const mockTicketGet = (body, params, options) => {
  const {
    ticketId,
    totalNumberOfRecords = allMockTickets.length,
    pageIndex = mockPagingInfo.pageIndex
  } = params || {};
  const { ticketStatus } = params || {};
  const { guid } = options || {};
  const getOnlyClosed = ticketStatus === 'all_closed';
  const getOnlyOpen = ticketStatus === 'all_open';
  let filteredTickets = [...allMockTickets];
  if (ticketId) {
    filteredTickets = allMockTickets.filter((t) => isEqual(ticketId, t.ticketId));
  } else if (getOnlyClosed) {
    filteredTickets = allMockTickets.filter((t) => closedTicketStatuses.includes(t.status));
  } else if (getOnlyOpen) {
    filteredTickets = allMockTickets.filter((t) => openTicketStatuses.includes(t.status));
    const staticTickets = staticGuidToTicketMap[guid] || null;
    if (staticTickets) {
      filteredTickets = filteredTickets.filter((t) =>
        staticTickets.find((staticTicket) => isEqual(staticTicket.name, t.name))
      );
    }
  }
  const pageData = getMockPageData({
    allData: filteredTickets,
    pageIndex,
    pageSize: 25,
    optionsCopy: { ...params, pageSize: 25 }
  });
  return {
    [guid]: {
      tickets: pageData,
      pagingInfo: {
        totalNumberOfRecords,
        pageIndex,
        pageSize: 25
      }
    }
  };
};

export const mockTicketsPut = {
  ticketId: mockTickets.ticket01.response.ticketId,
  ticketBusinessCode: mockTickets.ticket01.response.ticketBusinessCode
}; // create a NEW ticket

export const mockTicketsPost = {
  ticketFields: {
    ...mockTickets.ticket11.response,
    status: 'approved'
  }
};
// approving/denying a request

export const returnTicketPostBody = (body, params, options) => {
  const { guid = 'empty' } = options;
  // POST /ticket only returns a 204
  return {
    [guid]: {}
  };
};

export const returnApprovalRequestPutBody = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: {
      ticketId: 't000-fake-ticket-for-approval-request',
      ticketBusinessCode: 'ticketToRide'
    }
  };
};

export const returnBulkUpdateRequestPatchRes = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: {}
  };
};

export const deserializedTicketForm = {
  category: { required: false, valid: true, value: 'riskUnqueued' },
  description: { required: false, valid: true, value: 'A desription' },
  createdBy: { required: true, valid: true, value: '123-123-123-123' },
  classification: { required: false, valid: true, value: 'True Positive' },
  employeeGroupIds: {
    required: true,
    valid: true,
    value: ['2c444626-0a55-44cb-a431-e0ca5b56c734']
  },
  merchantGuid: { required: false, valid: true, value: 'ft_merchantGuidForRREROLHD' },
  name: { required: true, valid: true, value: 'simple select statement violation' },
  partnerId: { required: false, valid: true, value: 'ft_partnerIdForRREROLHD' },
  priority: { required: true, valid: true, value: 'severity_2' },
  relationshipId: { required: false, valid: true, value: 'ft_relationshipIdForRREROLHD' },
  status: { required: false, valid: true, value: 'open' },
  wakeUpDate: { required: false, valid: true, value: '-' },
  snoozeActivatedDate: { required: false, valid: true, value: '-' },
  ticketBusinessCode: { required: false, valid: true, value: 'someCode' },
  ticketId: { required: false, valid: true, value: 'c4d768ec-3456-4d08-882f-bc65dde39812' }
};
