import { crmEndpoint } from '../../utilsEndpoints';

const mockAchDetailsPostRes = (_body, _params, _options) => ({
  fileInfo: {
    fileName: 'mockAchFile_timestampHere',
    absolutePath: '/root/newMerchant',
    url: crmEndpoint.file.v2DummyUploadUrl
  }
});

export default mockAchDetailsPostRes;
