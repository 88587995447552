import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockProfitabilityGet = (body, params, _options) => {
  if (params.yearMonth === '2022-01') {
    return {
      merchantProfitabilityList: [],
      pagingInfo: {
        totalNumberOfRecords: 0,
        pageIndex: 0,
        pageSize: 1
      }
    };
  }
  const mockData = createMockProfitabilityData(params);
  return {
    merchantProfitabilityList: mockData,
    pagingInfo: {
      totalNumberOfRecords: mockData.length,
      pageIndex: 0,
      pageSize: mockData.length
    }
  };
};

const createMockProfitabilityData = (params) => {
  const { yearMonth } = params || {};
  const mockMonthData = Array.from({ length: 26 }).map(() => ({
    mid: getMockValue({ type: 'mid' }),
    yearMonth: yearMonth || '2020-08',
    residualFromProcessor: getMockValue({ type: 'price' }),
    terminalIncome: getMockValue({ type: 'price' }),
    otherIncomeItems: getMockValue({ type: 'price' }),
    incomeAdjustments: getMockValue({ type: 'price' }),
    parentPartnerResiduals: getMockValue({ type: 'price' }),
    partnerUpFrontBonus: getMockValue({ type: 'price' }),
    terminalCost: getMockValue({ type: 'price' }),
    otherCostItems: getMockValue({ type: 'price' }),
    merchantLosses: getMockValue({ type: 'price' }),
    fines: getMockValue({ type: 'price' }),
    downlinePartnerResiduals: getMockValue({ type: 'price' }),
    rmCommissions: getMockValue({ type: 'price' })
  }));
  return mockMonthData;
};

export default mockProfitabilityGet;
