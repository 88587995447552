const jokes = [
  { joke: `Orion's Belt is a big WAIST of space!`, punchline: 'Terrible joke.  Only three stars.' },
  { joke: `I have an inferiority complex but it's not a very good one.` },
  { joke: `What is Bruce Lee's favorite drink?`, punchline: `Wataaaaah!` },
  { joke: `I'm a social vegan, I avoid meet.` },
  { joke: `Clapping is literally hitting yourself because you like something.` },
  { joke: `I'm not passive aggressive, unlike "some" people.` },
  { joke: `Don't worry password, I'm insecure too.` },
  { joke: `Warning: going to sleep on Sunday will cause Monday.` },
  { joke: `Do dogs see police dogs and think "oh no it's the cops!"?` },
  { joke: `No, I don't need a Fitbit. I can count to 45 by myself.` },
  { joke: `The rotation of the earth really makes my day.` },
  { joke: `Dear naps, I'm sorry I was a jerk to you as a kid.` },
  { joke: `I wonder what my dog named me.` },
  { joke: `90% of a relationship is figuring out where to eat.` },
  { joke: `What Do you call a Dog that can do magic tricks?`, punchline: `A Labracadabrador` },
  { joke: `I'd like to give a big shout out to all the sidewalks for keeping me off the streets.` },
  {
    joke: `I totally understand how batteries feel because I'm rarely ever included in things either.`
  },
  {
    joke: `How did the hipster burn his tongue?`,
    punchline: `He drank his coffee before it was cool.`
  },
  { joke: `I wrote a song about a tortilla. Well actually, it's more of a wrap.` },
  { joke: `So, I met an Egyptian, and they walk just like us.` },
  { joke: `I wondered why the baseball was getting bigger. Then it hit me.` },
  { joke: `Two drums and a cymbal fall off a truck.`, punchline: `Badum TSSS.` },
  { joke: `Lack of general knowledge is my Achille's knee.` },
  { joke: `Wanna hear two short jokes and a long joke?`, punchline: `Joke joke jooooke.` },
  { joke: `What do you do when you see a spaceman? `, punchline: `You park your car, man.` },
  { joke: `I told my friend that she drew her eyebrows on too high... She looked surprised.` },
  { joke: `Knock knock. Who's there? To. To who? No, to whom.` },
  { joke: `Cartoonist found dead in home. Details are sketchy.` },
  { joke: `What do you call two crows on a branch?`, punchline: `Attempted murder.` },
  { joke: `I used to be a banker, but then I lost interest.` },
  { joke: `I always learn from mistakes of others who take my advice.` },
  { joke: `Broken pencils are pointless.` },
  { joke: `I didn't like my beard at first. Then it grew on me.` },
  { joke: `I'm reading a book about anti-gravity. I can't put it down.` },
  { joke: `If cats could text you back, they wouldn't.` },
  { joke: `What's orange and sounds like a parrot?`, punchline: `A carrot.` },
  { joke: `knock knock, who's there? cows go.  Cows go who?  no that's owls, cows go moo.` },
  {
    joke: `I saw a man at the beach yelling "Help, shark! Help!"  I just laughed, I knew that shark wasn't going to help him.`
  },
  { joke: `Hard work pays off in the future, laziness pays off now.` },
  { joke: `Apparently, someone in New York gets stabbed every 52 seconds. That poor guy.` },
  { joke: `Son: Dad, What's a forklift?`, punchline: `Dad: Food usually` },
  { joke: `If mediums can talk to the dead, imagine what a large can do.` },
  {
    joke: `Dr: I'm here to deliver your baby.`,
    punchline: `Dad: We'd like him to keep his liver please.`
  },
  { joke: `My Hawaiian pizza is burnt!`, punchline: `Should have cooked it at aloha temperature.` },
  { joke: `If you want the secret to your guitar sounding better, stay tuned.` },
  { joke: `Directions for falling down stairs:  1) Skip the first step` },
  { joke: `If a dolphin bites you, he did it on porpoise` },
  {
    joke: `I won the most secretive co-worker award`,
    punchline: `I can't tell you how much it means to me.`
  },
  { joke: `How would you describe yourself?`, punchline: `Verbally` },
  { joke: `Made a graph of my relationships.`, punchline: `There's an ex axis and a why axis.` },
  { joke: `A more advanced version of a psychopath is a psychoroad.` },
  {
    joke: `Thief stole tires off of police cars.`,
    punchline: `Police worked tirelessly to catch him.`
  },
  { joke: `How do snakes fight?`, punchline: `Unarmed combat.` },
  { joke: `I dig, you dig, we dig, she dig.`, punchline: `It's not a good poem, but it's deep.` },
  {
    joke: `As a child, my dad rolled me down hills in old tires.`,
    punchline: `Those were goodyears.`
  },
  { joke: `My friend David lost his ID. So now we just call him Dav.` },
  { joke: `I pulled a muscle digging for gold.`, punchline: `It was a miner injury` },
  { joke: `If you're being chased by a killer, you're both running for your life.` },
  { joke: `My spouse calls me cheap, but I'm not buying it.` },
  { joke: `I keep getting run over by the same bike.`, punchline: `It's a vicious cycle` },
  { joke: `Someone stole my limbo stick.`, punchline: `How low can you go?` },
  { joke: `I always say "mucho" around my Spanish speaking friends. It means a lot to them.` },
  { joke: `What makes a joke a dad joke?`, punchline: `When it's apparent.` },
  { joke: `I was addicted to the hokey pokey, but I turned myself around.` },
  { joke: `My spouse told to stop impersonating a flamingo.  I had to to put my foot down` },
  { joke: `I suffer from kleptomania.  But if it gets bad I take something for it.` },
  { joke: `If you're bored, do your makeup. Then you'll be pretty bored.` },
  {
    joke: `If your spouse is mad, put a sheet over their shoulders like a cape. Then they'll be super mad.`
  },
  { joke: `It hurts me to say this, but I have a sore throat.` },
  { joke: `Just to make things perfectly clear between us I wear glasses` },
  { joke: `A dad joke hasn't matured until it's full groan.` },
  { joke: `Energizer bunny arrested:`, punchline: `Charged with battery` },
  { joke: `Put grandma on speeddial`, punchline: `Then you'll have Instagram` },
  { joke: `The umbrella was going to be called a brella.  but the inventor hesitated.` },
  { joke: `I may be going crazy, but at least I have each other.` },
  { joke: `Googled "missing medieval servant", but it came up "page not found"` },
  { joke: `Still mad at my classmates for voting me "most likely to hold a grudge"` },
  { joke: `Lance isn't a common name, but in medieval times people were called Lance a lot` },
  { joke: `I sleep in PJ's so when I wake up I'm already dressed for work.` },
  { joke: `I hate how funerals are always at 9 a.m., I'm not a mourning person.` },
  { joke: `I may not like mushrooms, but I'm still a fungi` },
  { joke: `To the person who invented zero,thanks for nothing.` },
  { joke: `Bad puns make me numb, math puns make me number.` },
  { joke: `I bought a large python, and my friend asked how many feet. None, it's a snake.` },
  { joke: `I tried donating blood today, it was in vein.` },
  {
    joke: `What do you get when you cross the meanest kid in school with an itchy sweater?`,
    punchline: `A wooly bully`
  },
  {
    joke: `My teacher said I was average. I thought that was mean. Turns out that's just their mode.`
  },
  { joke: `Kangaroos can grow up to 6 feet!`, punchline: `But most only have 2.` },
  { joke: `What's the internal temperature of a Tauntaun?`, punchline: `Luke warm` },
  { joke: `He was the Knight no one expected on the battlefield!`, punchline: `Sir Prise.` },
  { joke: `I'd like to visit Napa Valley, but I'd get too tired.` },
  { joke: `People call my puppy fat.  But, he's just a little husky.` },
  { joke: `I wanted to be a monk, but never got the chants.` },
  { joke: `Have you noticed "the" and "IRS" spells "theirs"` },
  { joke: `Due to quarantine, I only do inside jokes.` },
  { joke: `Man injured in Peek-a-boo accident. He's in ICU.` },
  { joke: `If you know how to fix broken Hinges, my door is always open.` },
  { joke: `A perfectionist walked into a bar. It wan't set high enough` },
  { joke: `Hear about the new Corduroy pillow?`, punchline: `It's making headlines.` },
  { joke: `If Dinosaurs were still alive, would we call them livnosaurs?` },
  { joke: `The Boomerang is Australia's largest export.`, punchline: `And import as well.` },
  { joke: `Dad jokes are how eye roll` },
  { joke: `"Open Mike Night" sounded fun, until I learned it was an autopsy.` },
  { joke: `I bought a wig for $1.  It was a small price toupee` },
  {
    joke: `As I get older, I remember those I lost along the way. Yeah, I was a lousy a tour guide.`
  },
  { joke: `A recent study concluded that six out of seven dwarves are not Happy.` },
  { joke: `You know what really makes me smile?`, punchline: `Face muscles.` },
  { joke: `Why can't a T-rex clap?`, punchline: `Because they are all dead.` },
  {
    joke: `What did Batman say to Robin before they got in the car?`,
    punchline: `"Robin, get in the car".`
  },
  { joke: `If your name is Ian, and you have a daughter. Consider naming her Lilian.` },
  {
    joke: `Never thought I'd be good at poetry due to my dyslexia, but so far I've made a mug and 2 vases.`
  },
  {
    joke: `A vegan said people who sell meat are disgusting.  I say people that sell veggies are grocer.`
  },
  {
    joke: `Family were all in vests, so I said "vest day ever".  I took mine off to say "I'm not as invested as you guys"`
  },
  { joke: `I won $10,000 and gave a quarter of it to charity. Now I have $9,999.75.` },
  { joke: `With great power comes great electricity bills` },
  { joke: `When everything's coming your way, you're in the wrong lane.` },
  { joke: `I, for one, like Roman numerals.` },
  { joke: `Always give 100%`, punchline: `unless you're donating blood.` },
  { joke: `I invented a new word, "Plagiarism".` },
  { joke: `Is there another word for synonym?` },
  { joke: `I really hate peer pressure, and you should too!` },
  { joke: `My ceiling isn't the greatest, but it's up there.` },
  { joke: `I can't count the number of times I failed math.` },
  { joke: `I've told you a million times to stop exaggerating.` },
  { joke: `I wrote a book on penguins.  Paper would have been better.` },
  { joke: `The evening news is where they say "good evening" then tell you why it isn't.` },
  {
    joke: `I watched a show "50 things to do before you die".  I expected "call for help" to be one of them.`
  },
  { joke: `If you look closely, mirrors look like eyeballs.` },
  { joke: `Wife asked if I'd seen the cat's bowl.  I didn't even know they could do that!` },
  { joke: `The first french fries weren't made in France, they were made in Greece.` },
  { joke: `What's E.T. short for?`, punchline: `He has small legs.` },
  { joke: `Bicycles can't stand on their own, they're two tired.` },
  { joke: `What's brown and sticky?`, punchline: `A stick.` },
  { joke: `I would avoid the sushi if I was you. It's a little fishy.` },
  { joke: `Hey son did you know that My recliner and I go way back.` },
  { joke: `If you see a robbery at an Apple Store does that make you an iWitness?` },
  { joke: `You can't trust atoms. They make up everything!` },
  { joke: `5 out of 4 of people admit that they're bad with fractions.` },
  { joke: `I don't often tell dad jokes, but when I do he laughs.` },
  { joke: `I went to throw my boomerang but forgot how to use it. Then it came back to me.` },
  {
    joke: `The first time I saw a universal remote control. I thought 'well this changes everything'.`
  },
  { joke: `I stayed up all night wondering where the sun went. Then it dawned on me.` },
  { joke: `I used to be a narcissist.`, punchline: `But now look at me.` },
  {
    joke: `I got my best friend a fridge for his birthday. I can't wait to see his face light up when he opens it!`
  },
  { joke: `I asked my dog what's two minus two. He said nothing.` },
  {
    joke: `Store clerk: "Want that milk in a bag?"  Me: "No thanks, I'd rather keep it in the carton."`
  },
  { joke: `My wife says nothing rhymes with "orange." And I said, "No, it doesn't!"` },
  { joke: `What do you call a man with a rubber toe?`, punchline: `Roberto.` },
  { joke: `A rancher had 48 cows on his property, but when he rounded them up he had 50.` },
  {
    joke: `My wife said I ruined her birthday. I dunno how, I didn't even KNOW it was her birthday!`
  },
  {
    joke: `My wife said, "You're NOT even listening!" And I thought, "That's a weird way to start a conversation."`
  },
  { joke: `Did you hear about the kidnapping at school? He woke up.` },
  { joke: `What do you call a fish with no eye?`, punchline: `A fsh.` },
  {
    joke: `Why are pediatricians always so angry?`,
    punchline: `Because they have little patients.`
  },
  { joke: `What's the leading cause of dry skin?`, punchline: `Towels.` },
  { joke: `Whoever said "out of sight out of mind", never had a roach disappear in their room` },
  { joke: `I used to work at a calendar factory. But got fired for taking a couple days off.` },
  { joke: `Have you heard the joke about paper?`, punchline: `It's Tearable.` },
  { joke: `What do you call someone with no body and no nose?`, punchline: `Nobody knows.` },
  { joke: `A steak pun is a rare medium well done.` },
  {
    joke: `Did you hear about the guy who had his left side cut off?`,
    punchline: `He's all right now.`
  },
  { joke: `I don't trust stairs because they're always up to something.` },
  { joke: `Thanks for explaining the word "many" to me.`, punchline: `It means a lot.` },
  { joke: `What did the police officer say to his chest?`, punchline: `You're under a vest.` },
  { joke: `My boss told me to have a good day, so I went home.` },
  { joke: `I only know 25 letters of the alphabet. I don't know y.` },
  { joke: `I had a dream I was a muffler and woke up exhausted.` },
  { joke: `Son: Dad, have you seen my sunglasses? Dad: No, have you seen my dad glasses?` },
  { joke: `A man is washing his car with his son. The son asks, "Can't you just use a sponge?"` },
  { joke: `My password required eight characters, so I picked Snow White and the Seven Dwarfs.` },
  {
    joke: `Found a magic lamp and said, "For my first wish, I'd like to be rich." "Okay, Rich, what's your second wish?"`
  },
  {
    joke: `Why do chicken coops only have two doors?`,
    punchline: `If they had four they'd be a chicken sedan!`
  },
  { joke: `How many apples grow on a tree?`, punchline: `All of them.` },
  { joke: `Why can't you eat Wookie meat?`, punchline: `Because it's too chewy.` },
  { joke: `What do you call a factory that makes okay products.`, punchline: `A satisfactory.` },
  { joke: `I just stepped on a grape. It let out a little whine.` },
  { joke: `I Woke up for work and realized I ran out of coffee.  Had a depresso.` },
  { joke: `Welcome to Kaupa Cannibal tribe, tourists are our bread and butter.` },
  { joke: `I will never forget my dad's last words: "Stop playing with that bow!"` },
  { joke: `Are the birds singing in the morning because they don't have to go to work?` },
  {
    joke: `What do you call the soft tissue between a shark's teeth?`,
    punchline: `A slow swimmer.`
  },
  {
    joke: `Women shouldn't be allowed to have kids after 40. 40 kids is way too many by any standard.`
  },
  {
    joke: `I saw a poor old lady fall down the stairs. At least I assume she was poor, I only found 3.50 in her wallet.`
  },
  { joke: `The worst thing about parallel parking are the eyewitnesses.` },
  { joke: `Here, I bought you a calendar. Your days are numbered now.` },
  { joke: `Talk is cheap, yeah? Have you ever talked to a lawyer?!` },
  { joke: `Making a typo on a tombstone is a grave mistake.` },
  { joke: `If you ever feel cold, just go to the corner where it's 90 degrees.` },
  { joke: `People eat more bananas than monkeys. I can't remember the last time I ate a monkey.` },
  { joke: `I was looking for books on paranoia, the librarian said "Shh. look behind you."` },
  { joke: `How did Moses split the sea in half?`, punchline: `With a seasaw.` },
  { joke: `My wife told me I'm crazy. That's just silly, I don't even have a wife.` },
  { joke: `Milk is good... but it could be butter` },
  { joke: `The guy who stole my iPad should Face Time` },
  { joke: `The packaging lied, this tape tastes nothing like Scotch.` },
  {
    joke: `Do caterpillars know they turn into butterflies, or do they make a coffin only to be presently surprised days later.`
  },
  { joke: `The most dangerous kind of canoes are volcanoes` },
  { joke: `How do you kill a circus?  Go for the juggler` }
];

const jokeGenerator = () => jokes[Math.floor(Math.random() * jokes.length)];

export default jokeGenerator;
