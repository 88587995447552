import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { getNow, getRandomNumber } from '@f1/shared/src/_helpers';
import mockFiles from '../file/mockFiles';

export const mockNacha = {
  nachaSubmissionList: [
    {
      nachaSubmissionId: '00001',
      nachaFileId: '11111',
      createdTimestamp: '2023-01-16 00:23:04Z',
      createdFromExcelFileId: '444444',
      status: 'draft',
      submittedToBankTimestamp: null,
      bankName: 'MVB',
      fileHeaderReferenceCode: null,
      creditItemCount: 3,
      debitItemCount: 1,
      creditAmountTotal: 1152.24,
      debitAmountTotal: 57.54
    },
    {
      nachaSubmissionId: '00002',
      nachaFileId: '11112',
      createdTimestamp: '2023-01-17 00:23:04Z',
      createdFromExcelFileId: '444445',
      status: 'draft',
      submittedToBankTimestamp: null,
      bankName: 'MVB',
      fileHeaderReferenceCode: null,
      creditItemCount: 3,
      debitItemCount: 1,
      creditAmountTotal: 1152.24,
      debitAmountTotal: 57.54
    },
    {
      nachaSubmissionId: '00003',
      nachaFileId: '11113',
      createdTimestamp: '2023-01-15 00:23:04Z',
      createdFromExcelFileId: '444446',
      status: 'submitted_to_bank',
      submittedToBankTimestamp: null,
      bankName: 'MVB',
      fileHeaderReferenceCode: null,
      creditItemCount: 3,
      debitItemCount: 1,
      creditAmountTotal: 1152.24,
      debitAmountTotal: 57.54
    }
  ],
  pagingInfo: {
    totalNumberOfRecords: 3,
    pageIndex: 0,
    pageSize: 3
  }
};

const classCodes = ['PPD', 'CCD', 'CTX', 'WEB'];
const transactionCodes = [
  'checking_credit_22',
  'checking_debit_27',
  'savings_credit_32',
  'savings_debit_37'
];

const humanReadableTranslationMock = () => ({
  batchId: getMockValue({ type: 'string' }),
  standardEntryClassCode: classCodes[getRandomNumber({ max: 3 })],
  amount: getMockValue({ type: 'number', max: 100 }),
  transactionCode: transactionCodes[getRandomNumber({ max: 3 })],
  receivingRoutingNumber: getMockValue({
    type: 'number',
    min: 10000000,
    max: 99999999,
    stringify: true
  }),
  receivingAccountNumber: getMockValue({ type: 'accountNumber', stringify: true }),
  ...(Math.round(Math.random()) === 0
    ? { individualName: getMockValue({ type: 'user' }).fullName }
    : { companyName: getMockValue({ type: 'businessName' }) }),
  companyDiscretionaryData: getMockValue({
    type: 'number',
    min: 1000000000,
    max: 9999999999,
    stringify: true
  }),
  companyEntryDescription: getMockValue({
    type: 'number',
    min: 1000000000,
    max: 9999999999,
    stringify: true
  })
});

export const mockNachaDetails = {
  nacha: {
    ...mockNacha.nachaSubmissionList[0],
    humanReadableTranslation: [
      {
        batchId: '1234',
        standardEntryClassCode: classCodes[0],
        amount: 55,
        transactionCode: transactionCodes[0],
        receivingRoutingNumber: '99999999',
        receivingAccountNumber: '123456790',
        individualName: 'Testing Testman',
        companyDiscretionaryData: getMockValue({
          type: 'number',
          min: 1000000000,
          max: 9999999999,
          stringify: true
        }),
        companyEntryDescription: getMockValue({
          type: 'number',
          min: 1000000000,
          max: 9999999999,
          stringify: true
        })
      },
      ...Array.from({ length: getRandomNumber({ min: 3, max: 10 }) }).map(() =>
        humanReadableTranslationMock()
      )
    ]
  }
};

export const mockNachaGet = (body, params, _options) => {
  const { status } = params || 'draft';
  if (status === 'all') {
    return mockNacha;
  }
  return {
    nachaSubmissionList: mockNacha.nachaSubmissionList.filter((entry) => entry?.status === status)
  };
};

export const mockNachaDetailsGet = (body, params, options) => {
  const { guid = 'testGuid' } = options || {};
  return {
    [guid]: mockNachaDetails
  };
};

export const mockNachaReturnsGet = (body, params, _options) => {
  const { yearMonth = '2020-08' } = params || {};
  return {
    nachaReturnList:
      yearMonth === '2020-08'
        ? [
            {
              nachaReturnId: '0987654321',
              bankName: 'MVB',
              processorSyncTimestamp: getNow().toUTCString(),
              file: mockFiles.file03
            }
          ]
        : [],
    pagingInfo: {
      totalNumberOfRecords: 1,
      pageIndex: 0,
      pageSize: 1
    }
  };
};
