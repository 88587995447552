import React from 'react';
import PropTypes from 'prop-types';
import { isEqual, ignoreCase, isBool, isEmpty } from '../../_helpers';
import { buildFormComponents } from '../../_formHelpers';
import { Spinner, FormAssistant, FormContainer } from '../../../index';
import FormSectionHeader from '../../FormSectionHeader';
import FormCustomSectionConfirmFields from '../../FormCustomSectionConfirmFields';
import FormCustomSection from '../../FormCustomSection';

class RepayTab extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const {
      relationship,
      appStatus,
      userType,
      otherTabData,
      validateFields,
      onlyUseFeeData,
      fields,
      isPublicRequest,
      data,
      disableFormFields
    } = props;
    this.tabKey = isPublicRequest ? 'processorFieldsTab' : 'repayTab';
    this.defaultBuildOptions = { disableFormFields };
    this.isStandardOrElevatedRisk =
      !isEmpty(relationship) &&
      ['elevated', 'standard'].includes(ignoreCase(relationship.riskProfile || ''));
    this.showPaymentMethodsAccepted =
      !onlyUseFeeData && !isEmpty(fields?.paymentMethodsAcceptedSection);
    this.paymentMethodsAcceptedSection = this.showPaymentMethodsAccepted
      ? buildFormComponents(
          data?.paymentMethodsAcceptedSection || {},
          fields?.paymentMethodsAcceptedSection,
          this.defaultBuildOptions
        )
      : {};
    this.showRepayAchInfoAndFunding =
      !onlyUseFeeData && !isEmpty(fields?.repayAchInfoAndFundingSection);
    this.repayAchInfoAndFundingSection = this.showRepayAchInfoAndFunding
      ? buildFormComponents(
          data?.repayAchInfoAndFundingSection || {},
          fields?.repayAchInfoAndFundingSection,
          this.defaultBuildOptions
        )
      : [];
    this.showRepayAchInfoConfirm = !onlyUseFeeData && !isEmpty(fields?.repayAchInfoConfirmSection);
    this.repayAchInfoConfirmSection = this.showRepayAchInfoConfirm
      ? data?.repayAchInfoConfirmSection
      : {};
    this.showRatesAndFees = !isEmpty(fields?.ratesAndFeesSection);
    this.ratesAndFeesSection = this.showRatesAndFees
      ? buildFormComponents(
          data?.ratesAndFeesSection || {},
          fields?.ratesAndFeesSection,
          this.defaultBuildOptions
        )
      : {};
    this.showPinDebitFees = !isEmpty(fields?.pinDebitFeesSection);
    this.pinDebitFeesSection = this.showPinDebitFees
      ? buildFormComponents(
          data?.pinDebitFeesSection || {},
          fields?.pinDebitFeesSection,
          this.defaultBuildOptions
        )
      : {};
    this.showOtherFees = !isEmpty(fields?.otherFeesSection);
    this.otherFeesSection = this.showOtherFees
      ? buildFormComponents(
          data?.otherFeesSection || {},
          {
            ...fields?.otherFeesSection,
            ...(appStatus === 'Draft' &&
              userType === 'partner' &&
              !this.isStandardOrElevatedRisk && {
                nextDayFundingBatchFee: {
                  ...fields?.otherFeesSection?.nextDayFundingBatchFee,
                  initialValue: '0' // Only set for new apps
                }
              })
          },
          {
            ...this.defaultBuildOptions,
            customProps: {
              ...(validateFields &&
                otherTabData?.achInformationAndFundingChoicesSection?.fundingChoice ===
                  'next_day_funding' &&
                !this.isStandardOrElevatedRisk && {
                  nextDayFundingBatchFee: { required: true }
                })
            }
          }
        )
      : {};
    this.showAccountUpdater = !isEmpty(fields?.accountUpdaterSection);
    this.accountUpdaterSection = this.showAccountUpdater
      ? buildFormComponents(
          data?.accountUpdaterSection || {},
          fields?.accountUpdaterSection,
          this.defaultBuildOptions
        )
      : {};
    this.showMobileProcessing = !isEmpty(fields?.mobileProcessingSection);
    this.mobileProcessingSection = this.showMobileProcessing
      ? buildFormComponents(
          data?.mobileProcessingSection || {},
          fields?.mobileProcessingSection,
          this.defaultBuildOptions
        )
      : {};
    this.showGateway = !isEmpty(fields?.gatewaySection);
    this.gatewaySection = this.showGateway
      ? buildFormComponents(
          data?.gatewaySection || {},
          fields?.gatewaySection,
          this.defaultBuildOptions
        )
      : {};
    this.showWirelessProcessing = !isEmpty(fields?.wirelessProcessingSection);
    this.wirelessProcessingSection = this.showWirelessProcessing
      ? buildFormComponents(
          data?.wirelessProcessingSection || {},
          fields?.wirelessProcessingSection,
          this.defaultBuildOptions
        )
      : {};
    this.showTieredRates = !isEmpty(fields?.tieredRatesSection);
    this.tieredRatesSection = this.showTieredRates
      ? buildFormComponents(
          data?.tieredRatesSection || {},
          fields?.tieredRatesSection,
          this.defaultBuildOptions
        )
      : {};
    this.showDeclineRecovery = !isEmpty(fields?.declineRecoverySection);
    this.declineRecoverySection = this.showDeclineRecovery
      ? buildFormComponents(
          data?.declineRecoverySection || {},
          fields?.declineRecoverySection,
          this.defaultBuildOptions
        )
      : {};

    this.showGeneralSection = !onlyUseFeeData && !isEmpty(fields?.generalSection);
    this.repayGeneralData = this.showGeneralSection
      ? {
          ...data?.generalSection,
          netAch:
            isBool(data?.generalSection?.netAch) && !this.isStandardOrElevatedRisk
              ? // if value already set by user, use the existing boolean
                data?.generalSection?.netAch
              : true
        }
      : {};
    this.generalSection = this.showGeneralSection
      ? buildFormComponents(this.repayGeneralData, fields?.generalSection, this.defaultBuildOptions)
      : [];
    this.state = {};
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  handleFormChange = (newFormState, id, options) => {
    const { valueChangedData, valuesForBackend } = options || {};
    const { [id]: currentSectionState = {}, ratesAndFeesSection } = this.state;
    const sectionChanged =
      isEmpty(currentSectionState) || // on load, or if a field changed
      (!isEmpty(valueChangedData) &&
        !isEqual(valueChangedData.frontendValue, currentSectionState?.[valueChangedData.id]));
    if (sectionChanged) {
      this.handleEbtChange(id, newFormState);
      const isTieredRates =
        id === 'ratesAndFeesSection'
          ? valuesForBackend?.pricingType === 'tiered_rates'
          : ratesAndFeesSection?.valuesForBackend?.pricingType === 'tiered_rates';
      const useDebitsCheckboxChanged =
        id === 'repayAchInfoAndFundingSection' &&
        !isEqual(currentSectionState?.repayUseDebitCheckbox, newFormState?.repayUseDebitCheckbox);
      const selectAchRadioChanged =
        id === 'repayAchInfoAndFundingSection' &&
        !isEqual(currentSectionState?.selectAchAccountRadio, newFormState?.selectAchAccountRadio);
      this.updateState(
        (prevState) => ({
          [id]: {
            ...prevState[id],
            ...newFormState,
            ...(valuesForBackend && { valuesForBackend })
          },
          sectionsValid: {
            ...prevState?.sectionsValid,
            [id]: isBool(newFormState?.fullSectionValid)
              ? newFormState.fullSectionValid
              : newFormState[id],
            ...(!isTieredRates && { tieredRatesSection: true }),
            ...(((useDebitsCheckboxChanged && newFormState?.repayUseDebitCheckbox === false) ||
              (selectAchRadioChanged && isEmpty(newFormState?.selectAchAccountRadio))) && {
              repayAchInfoAndFundingSection: true,
              repayAchInfoConfirmSection: true
            })
          },
          sectionsInProgress: {
            ...prevState?.sectionsInProgress,
            ...(typeof prevState[id] !== 'undefined' && { [id]: newFormState.formInProgress })
          }
        }),
        () => this.handleCallback(id)
      );
    }
  };

  handleEbtChange = (id, newFormState) => {
    const { fields } = this.props;
    if (id === 'paymentMethodsAcceptedSection' && !isEmpty(fields?.pinDebitFeesSection)) {
      this.showPinDebitFees = newFormState?.electronicBenefitsTransfer === 'no';
      if (!this.showPinDebitFees) {
        this.updateState(
          (prevState) => ({
            pinDebitFeesSection: {
              ...prevState.pinDebitFeesSection,
              pinBasedDebitEnabled: false,
              pinDebitFees_volumeRatio: null,
              pinDebitFees_perTransaction: null,
              pinDebitFees_monthlyFee: null,
              valuesForBackend: {
                pinBasedDebitEnabled: false,
                pinDebitFees_volumeRatio: null,
                pinDebitFees_perTransaction: null,
                pinDebitFees_monthlyFee: null
              }
            }
          }),
          () => this.handleCallback('pinDebitFeesSection')
        );
      }
    }
  };

  handleCallback = (sectionId) => {
    const { callback } = this.props;
    const { [sectionId]: currentSectionState, sectionsValid, sectionsInProgress } = this.state;
    if (!isEmpty(currentSectionState)) {
      const cbOptions = {
        sectionChangedData: { sectionId, sectionState: currentSectionState },
        tabKey: this.tabKey,
        tabData: { [sectionId]: currentSectionState },
        tabValid:
          !isEmpty(sectionsValid) && Object.values(sectionsValid).every((val) => val === true),
        tabInProgress:
          !isEmpty(sectionsInProgress) &&
          Object.values(sectionsInProgress).some((val) => val === true)
      };
      callback && callback(cbOptions);
    }
  };

  render() {
    const { fields, allowEmpty, isPublicRequest, wrapperStyle, validateFields, spinnerLoading } =
      this.props;
    const { ratesAndFeesSection, repayAchInfoAndFundingSection } = this.state;
    const showTieredRates =
      this.showTieredRates && ratesAndFeesSection?.pricingType === 'tiered_rates';
    const { selectAchAccountRadio, repayUseDebitCheckbox } = repayAchInfoAndFundingSection || {};
    return (
      <FormContainer id={this.tabKey} type="formWithSections" wrapperStyle={wrapperStyle}>
        <Spinner loading={spinnerLoading} />
        {this.showPaymentMethodsAccepted && (
          <>
            <FormSectionHeader title="Payment Card Acceptance *You may select any card type in accordance with Card Networks Operating Regulations" />
            <FormAssistant
              componentLabelInside
              id={fields?.paymentMethodsAcceptedSection?.id}
              ariaLabel="Payment card acceptance section"
              formComponents={this.paymentMethodsAcceptedSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showGeneralSection && (
          <>
            <FormSectionHeader title="General" />
            <FormAssistant
              componentLabelInside
              id={fields?.generalSection?.id}
              ariaLabel="Processor general section"
              formComponents={this.generalSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {this.showRepayAchInfoAndFunding && (
          <>
            <FormSectionHeader title="ACH Info & Funding" />
            {isPublicRequest ? (
              <FormCustomSection
                isInfoSection
                wrapperStyle={{ fontSize: '1.2rem', padding: '0 0.2em' }}>
                <em>Note: Only debit ACH information is supported here.</em>
              </FormCustomSection>
            ) : null}
            <FormAssistant
              componentLabelInside
              id={fields?.repayAchInfoAndFundingSection?.id}
              ariaLabel="ACH info & funding section"
              formComponents={this.repayAchInfoAndFundingSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
            {(!isEmpty(selectAchAccountRadio) || repayUseDebitCheckbox) && (
              <FormCustomSectionConfirmFields
                componentLabelInside
                id={fields?.repayAchInfoConfirmSection?.id}
                ariaLabel="Confirm ACH info section"
                fields={fields?.repayAchInfoConfirmSection}
                callback={this.handleFormChange}
                data={this.repayAchInfoConfirmSection}
                defaultBuildOptions={this.defaultBuildOptions}
                allowEmpty={allowEmpty}
                validateFields={validateFields}
              />
            )}
          </>
        )}
        {this.showRatesAndFees && (
          <>
            <FormSectionHeader title="Fees" />
            <FormAssistant
              componentLabelInside
              id={fields?.ratesAndFeesSection?.id}
              ariaLabel="General fees section"
              formComponents={this.ratesAndFeesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
        {showTieredRates ? (
          <>
            <FormSectionHeader title="Tiered Rates" />
            <FormAssistant
              componentLabelInside
              id={fields?.tieredRatesSection?.id}
              ariaLabel="Tiered rates section"
              formComponents={this.tieredRatesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        ) : (
          <>
            {this.showPinDebitFees && (
              <FormAssistant
                componentLabelInside
                id={fields?.pinDebitFeesSection?.id}
                ariaLabel="Pin debit section"
                formComponents={this.pinDebitFeesSection}
                callback={this.handleFormChange}
                allowEmpty={allowEmpty}
                validateFields={validateFields}
              />
            )}
          </>
        )}
        {this.showAccountUpdater && (
          <FormAssistant
            componentLabelInside
            id={fields?.accountUpdaterSection?.id}
            ariaLabel="Account updater section"
            formComponents={this.accountUpdaterSection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showMobileProcessing && (
          <FormAssistant
            componentLabelInside
            id={fields?.mobileProcessingSection?.id}
            ariaLabel="Mobile processing section"
            formComponents={this.mobileProcessingSection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showGateway && (
          <FormAssistant
            componentLabelInside
            id={fields?.gatewaySection?.id}
            ariaLabel="Gateway section"
            formComponents={this.gatewaySection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showWirelessProcessing && (
          <FormAssistant
            componentLabelInside
            id={fields?.wirelessProcessingSection?.id}
            ariaLabel="Wireless processing section"
            formComponents={this.wirelessProcessingSection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showDeclineRecovery && (
          <FormAssistant
            componentLabelInside
            id={fields?.declineRecoverySection?.id}
            ariaLabel="Decline recovery section"
            formComponents={this.declineRecoverySection}
            callback={this.handleFormChange}
            allowEmpty={allowEmpty}
            validateFields={validateFields}
          />
        )}
        {this.showOtherFees && (
          <>
            <FormSectionHeader title="Other Fees" />
            <FormAssistant
              componentLabelInside
              id={fields?.otherFeesSection?.id}
              ariaLabel="Other fees section"
              formComponents={this.otherFeesSection}
              callback={this.handleFormChange}
              allowEmpty={allowEmpty}
              validateFields={validateFields}
            />
          </>
        )}
      </FormContainer>
    );
  }
}

RepayTab.propTypes = {
  relationship: PropTypes.oneOfType([PropTypes.object]),
  otherTabData: PropTypes.oneOfType([PropTypes.object]),
  userType: PropTypes.string,
  appStatus: PropTypes.string,
  onlyUseFeeData: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]),
  fields: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func,
  validateFields: PropTypes.bool,
  spinnerLoading: PropTypes.bool,
  disableFormFields: PropTypes.bool,
  isPublicRequest: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.object])
};

RepayTab.defaultProps = {
  relationship: {},
  otherTabData: {},
  userType: '',
  appStatus: '',
  onlyUseFeeData: false, // MECO -> Merchant -> Fees, Partner -> Merchant Details -> Fees
  allowEmpty: false,
  data: {},
  fields: {},
  callback: () => {},
  validateFields: false,
  spinnerLoading: false,
  disableFormFields: false,
  isPublicRequest: false,
  wrapperStyle: {}
};

export default RepayTab;
