import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { pad } from '@f1/shared/src/_helpers';
import { getMockEmployee } from '../_mockHelpers';

const mockRepayTransactionHoldConfig = (_body, _params) => ({
  expectedFileArrivalTime: `${pad(getMockValue({ type: 'number', min: 1, max: 12 }))}:10`,
  expectedFileProcessedTime: `${pad(getMockValue({ type: 'number', min: 1, max: 12 }))}:10`,
  maxDoNotProcessEntriesCount: getMockValue({ type: 'number' }),
  employee: {
    ...getMockEmployee({ includeManager: true })
  }
});

export default mockRepayTransactionHoldConfig;
