import { getMockValue } from '../_mockDataHelpers';

export const mockBoardingApplicationEventHistoryFull = (options) => {
  const { length = 1 } = options || {};
  const fullEventHistory = Array.from({ length }).map(() => mockApplicationEventHistoryRow());
  return fullEventHistory;
};

const mockApplicationEventHistoryRow = () => ({
  type: getMockValue({
    type: 'list',
    list: [
      'application_received',
      'initial_signature_received',
      'initial_review_start',
      'pends_sent_to_partner',
      'partner_returned_pended_app_to_corvia',
      'credit_underwriting_start',
      'post_review_signature_received',
      'approved',
      'boarded'
    ]
  }),
  timestamp: getMockValue({ type: 'isoDate' })
});

export default mockBoardingApplicationEventHistoryFull;
