// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { isEmpty, customizedTooltip, yearMonthToString } from './_helpers';
import { tooltipCSS } from './_styles';

export class ToolTipD3 extends React.Component {
  constructor(props) {
    super(props);
    this.tip = React.createRef();
    this.state = {
      formatted: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.formatData();
  }

  componentWillUnmount() {
    const { element } = this.props;
    const tooltipWrapper = element.querySelector('.d3Tooltip');
    const svgContainer = d3.select(tooltipWrapper);
    const tooltip = svgContainer;
    tooltip.style('height', null);
    tooltip.style('width', null);
    // needed for mobile when you tap outside of the tooltip
    document.querySelector('body').style.overflow = null;
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  formatData = () => {
    // DEPRECATED SERIALIZE, should use global transformData() method.
    // should look at how this can be updated.
    const { d3Data, options } = this.props;
    const { data = null, type = '' } = d3Data;
    const { lines = [], label = '' } = options;
    const lineData = Object.entries(data).filter(([key, _value]) => lines.includes(key));
    let newData;
    if (type === 'donut' || type === 'radial' || type === 'pie') {
      newData = [[data.name, data.value]];
    } else if (type === 'bar') {
      newData = Object.entries(data)
        .filter(
          ([key, _value]) =>
            key !== 'header' &&
            !isEmpty(label) &&
            key !== label &&
            (!isEmpty(lines) ? !lines.includes(key) : true)
        )
        .sort(this.sortByValue)
        .concat(lineData); // moves 'total' field to the bottom
    } else if (type === 'tree') {
      newData = Object.entries(data).filter(
        ([key, _value]) => key !== 'header' && !isEmpty(label) && key !== label
      );
    } else {
      newData = Object.entries(data).filter(([key, _value]) => key === 'lineValue');
    }
    this.updateState({
      formatted: newData
    });
  };

  sortByValue = (a, b) => {
    const first = a?.[1] || 0;
    const second = b?.[1] || 0;
    if (first > second) {
      return 1;
    }
    if (first < second) {
      return -1;
    }
    return 0;
  };

  render() {
    const { d3Data, options, tooltipStyle, revealed } = this.props;
    const { formatted } = this.state;
    const colorMap = options.colorMap || {};
    const { type, data } = d3Data;
    const tooltipData = !isEmpty(formatted) ? formatted : Object.entries(data);
    return (
      <div
        ref={this.tip}
        className="d3Tooltip"
        style={{
          ...tooltipCSS().base,
          ...tooltipCSS()[tooltipStyle.type],
          ...(revealed ? tooltipCSS().on : tooltipCSS().off),
          ...(tooltipStyle.height && { width: `${tooltipStyle.height}px` }),
          ...(tooltipStyle.width && { width: `${tooltipStyle.width}px` }),
          ...(tooltipStyle.x && { left: `${tooltipStyle.x}px` }),
          ...(tooltipStyle.y && { top: `${tooltipStyle.y}px` }),
          overflow: 'visible'
        }}>
        <div
          style={{
            ...tooltipCSS().arrow.base,
            ...tooltipCSS().arrow[tooltipStyle.type],
            position: 'absolute'
          }}
        />
        {(type === 'bar' || type === 'circle' || type === 'tree') &&
          !isEmpty(data[data.header]) && (
            <div className="tipHeader">
              {data[data.header].match(/^(\d{4}-\d{2})$/)
                ? yearMonthToString(data[data.header])
                : data[data.header]}
            </div>
          )}
        <div className="tipContent">
          <table>
            <tbody>
              {tooltipData.map(([key, value], i) => (
                <tr key={`${key}_${i.toString()}`}>
                  <td className="key" style={{ color: colorMap[key] }}>
                    {['tree', 'bar', 'donut', 'pie'].includes(type) && key}
                    {(type === 'circle' || type === 'radial') && data.name}
                  </td>
                  <td className="value">{customizedTooltip({ value })}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

ToolTipD3.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipStyle: PropTypes.shape({
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string
  }),
  revealed: PropTypes.bool,
  d3Data: PropTypes.oneOfType([PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.object]),
  element: PropTypes.oneOfType([PropTypes.object])
};
ToolTipD3.defaultProps = {
  width: null,
  tooltipStyle: {
    x: 0,
    y: 0,
    width: null,
    height: null,
    type: null
  },
  revealed: false,
  d3Data: {},
  options: {},
  element: {}
};

export default ToolTipD3;
