import React from 'react';
import { dinoDabCSS } from './_styles';

export class DinoDab extends React.PureComponent {
  render() {
    return (
      <div style={dinoDabCSS.bg}>
        <div style={dinoDabCSS.wrap}>
          <div style={dinoDabCSS.graphic} />
        </div>
      </div>
    );
  }
}

export default DinoDab;
