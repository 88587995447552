// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { isBool, isEmpty, getType } from './_helpers';
import { toggleSwitch } from './_styles';

export class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { checked, startState } = this.props;
    this.state = {
      checked,
      toggleState: startState
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    const { checked } = this.props;
    if (prevProps.checked !== checked && isBool(checked)) {
      this.handleCheck(checked);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  handleFocus = () => {
    this.updateState({ toggleState: 'focus' });
  };

  handleBlur = () => {
    this.updateState({ toggleState: 'default' });
  };

  handleMouseIn = () => {
    this.updateState({ toggleState: 'hover' });
  };

  handleMouseOut = () => {
    this.updateState({ toggleState: 'default' });
  };

  handleCheck = (event) => {
    const { callback, id } = this.props;
    const checkedState =
      getType(event) === 'object' && event !== null
        ? ((event.target && event.target.checked) ?? false)
        : event;
    this.updateState({ checked: checkedState });
    if (callback) {
      callback(checkedState, id);
    }
  };

  render() {
    const { wrapperStyle, label, disabled, id } = this.props;
    const { toggleState, checked } = this.state;
    return (
      <div className="toggleSwitch" style={wrapperStyle}>
        <label
          htmlFor={!isEmpty(id) ? id : label.replace(/ /g, '_')}
          style={{
            ...toggleSwitch.label_default,
            ...toggleSwitch[`label_${toggleState}`],
            ...(disabled && toggleSwitch.label_disabled)
          }}>
          {label}
          <input
            id={!isEmpty(id) ? id : label.replace(/ /g, '_')}
            style={toggleSwitch.input}
            type="checkbox"
            disabled={disabled}
            onChange={this.handleCheck}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onMouseEnter={this.handleMouseIn}
            onMouseLeave={this.handleMouseOut}
            checked={checked || false}
          />
          <div
            style={{
              ...(checked ? toggleSwitch.trackChecked : toggleSwitch.track),
              ...toggleSwitch[toggleState]
            }}>
            <div
              style={{
                ...(checked ? toggleSwitch.knobChecked : toggleSwitch.knob),
                ...toggleSwitch[`knob_${toggleState}`]
              }}
            />
          </div>
        </label>
      </div>
    );
  }
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  startState: PropTypes.string,
  label: PropTypes.string,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  id: PropTypes.string
};

ToggleSwitch.defaultProps = {
  checked: false,
  startState: 'default',
  label: '',
  wrapperStyle: {},
  disabled: false,
  callback: () => {},
  id: ''
};

export default ToggleSwitch;
