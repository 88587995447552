import React from 'react';
import PropTypes from 'prop-types';
import bigDecimal from 'js-big-decimal';
import { ToolTip } from './ToolTip';
import {
  arrayToString,
  formatNumber,
  isEmpty,
  pad,
  sanitizeHTML,
  getType,
  getTextDimensions,
  replaceControlCharacters
} from './_helpers';
import validations from './_validations';
import {
  InputWrap,
  Inputprefix,
  InputSuffix,
  RequiredStar,
  Textarea,
  ErrorBox,
  TextAreaShadow
} from '../css/_styledFormComponents';
import { Icon } from '../css/_styledComponents';
import { input } from './_styles';

export class Input extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.dateTypes = ['date', 'futureDate'];
    this.timeTypes = ['time', 'futureTime'];
    this.dateAndTimeTypes = [...this.dateTypes, ...this.timeTypes];
    this.inputFieldRef = React.createRef();
    this.inputSuffixRef = React.createRef();
    this.textareaShadowRef = React.createRef();
    this.typingTimer = undefined;
    this.state = {
      // the value of this input
      inputValue: arrayToString(props.value),
      originalValue: arrayToString(props.value),
      // testOnChange is just used to avoid validating imediately into
      // the error state as you type IF the input started out as empty.
      testOnChange: false,
      // empty, unless there IS an error
      errorText: ''
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { type, value, validationActivated } = this.props;
    const { inputValue } = this.state;
    if (type.includes('percent')) {
      this.setSuffixPosition();
    }
    if (value || validationActivated) {
      // if it loads WITH a VALUE passed in, or validationActivated as true, validate the field
      this.setFieldValidity(this.inputFieldRef.current);
      this.updateState(
        {
          inputValue: this.getNewInputValue(inputValue).newInputValue,
          testOnChange: true
        },
        value && type === 'textarea' ? this.resizeTextArea : null
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { testOnChange } = this.state;
    const { type, value, setError, validationActivated } = this.props;
    const { inputValue } = this.state;
    // IF setError is passed, we trigger the input in INVALID state no matter what
    // OR, if validationActivated is true, we mark empty 'required' fields as being in error state.
    if (prevProps.setError !== setError || prevProps.validationActivated !== validationActivated) {
      testOnChange && this.setFieldValidity(this.inputFieldRef.current);
    }
    if (prevProps.value !== value) {
      const propInputValue = arrayToString(value);
      // When `inputValue` gets passed back as the `value` prop via callback,
      // we don't need to run validation
      inputValue !== propInputValue &&
        this.updateState(
          {
            originalValue: this.getNewInputValue(propInputValue).newInputValue,
            inputValue: this.getNewInputValue(propInputValue).newInputValue
          },
          () => {
            // if the value prop updates. update the input, THEN validate the field
            (testOnChange || this.checkValidation(propInputValue)) &&
              this.setFieldValidity(this.inputFieldRef.current);
            if (type.includes('percent')) {
              this.setSuffixPosition();
            }
            type === 'textarea' && this.resizeTextArea();
          }
        );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.typingTimer);
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  };

  checkValidation = (value) => {
    const { allowToday, businessHoursOnly, dateInput, roundMinutes, type } = this.props;
    const hasValidationType = !isEmpty(validations[type]);
    const options = {
      ...(this.timeTypes.includes(type) && {
        allowToday,
        businessHoursOnly,
        date: dateInput,
        roundMinutes
      }),
      ...(type === 'futureDate' && { allowToday })
    };
    return hasValidationType ? validations[type].test(value, options) : true;
  };

  setFieldValidity = (field) => {
    const { id, type, setError, errorMessage, useValidationMessage, customValidation, required } =
      this.props;
    // check if setError.nessage was sent, or a custom errorMessage
    // if not, use the message provided in validation object
    // fall back too the brower default messages.
    const errorText =
      (useValidationMessage && field.validationMessage) ||
      errorMessage ||
      validations[type]?.message ||
      field.validationMessage;
    const customValidationValid =
      customValidation instanceof Function ? customValidation(field.value, { id }) : null;
    let fieldIsValid = customValidationValid;
    if (customValidationValid === null) {
      fieldIsValid = this.checkValidation(field.value);
    }
    const hideErrorOnEmpty = !required && isEmpty(field.value);
    this.updateState({ testOnChange: true });
    if (!isEmpty(setError)) {
      // If we passed in setError, we are forcing the error state and error message.
      this.updateState({ errorText: setError.message });
      field.setAttribute('aria-invalid', 'true');
      field.removeAttribute('aria-valid');
    } else if (!fieldIsValid && !hideErrorOnEmpty) {
      /* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
      this.updateState({
        errorText:
          getType(errorText) === 'array'
            ? errorText.map((m) => (
                <div style={{ height: '14px', lineHeight: '1.5' }} key={m}>
                  {m}
                </div>
              ))
            : errorText
      });
      field.setAttribute('aria-invalid', 'true');
      field.removeAttribute('aria-valid');
    } else {
      this.updateState({ errorText: '' });
      field.setAttribute('aria-valid', 'true');
      field.removeAttribute('aria-invalid');
    }
  };

  requiredNullCheck = (e) => {
    // If user cleared a required field, that HAD a value on load, reset to original value
    // we do not allow users to empty out already existing values on required fields.
    const { originalValue } = this.state;
    if (e.target.required && isEmpty(e.target.value) && !isEmpty(originalValue)) {
      e.target.value = originalValue;
      e.target.dispatchEvent(new Event('change'));
      this.handleChange(e);
    }
  };

  handleBlur = (e) => {
    // onblur, always trim the value, and update state to be the trimmed value.
    // need to manually set value as well as state since onChange won't fire on state change.
    const inputValue = (e?.target?.value || '').trim();
    e.target.value = inputValue;
    this.updateState({ inputValue: `${inputValue}` });
    this.requiredNullCheck(e);
    this.setFieldValidity(e.target);
    this.handleCallback(e);
  };

  handleWheel = (e, type) => {
    if (this.setType(type) === 'number') {
      e.target.blur();
    }
  };

  handlePaste = (e) => {
    const { disablePaste } = this.props;
    if (disablePaste) {
      e.preventDefault();
    } else {
      this.handleChange(e);
    }
  };

  getNewInputValue = (value) => {
    const { type, weekdaysOnly } = this.props;
    const formattedValue =
      !isEmpty(value) && type !== 'textarea'
        ? replaceControlCharacters(value, { noSequentialSpaces: true })
        : value;
    if (
      weekdaysOnly &&
      this.dateTypes.includes(type) &&
      RegExp(/^\d{4}-?\d{2}-?\d{2}$/g).test(formattedValue)
    ) {
      const newInputValue = this.getNextWeekday(formattedValue);
      return { newInputValue };
    }
    if (type === 'tel' && RegExp(/^(\+1|1|0)/).test(formattedValue)) {
      return { newInputValue: `${formattedValue}`.replace(/^(\+1|1|0)/, '') };
    }

    return { newInputValue: formattedValue };
  };

  isWeekend = (dayIndex) => [0, 6].includes(dayIndex);

  getNextWeekday = (value) => {
    const timestampString = !isEmpty(value) ? `${value}T00:00:00.000` : '';
    const dateObj = !isEmpty(timestampString) ? new Date(timestampString) : new Date();
    const dayIndex = !isEmpty(dateObj) ? dateObj.getDay() : null;
    const isWeekend = this.isWeekend(dayIndex);
    const yearComplete = !isEmpty(value) && !value.startsWith('0');
    if (yearComplete && isWeekend) {
      const daysToAdd = dayIndex === 6 ? 2 : 1;
      dateObj.setDate(dateObj.getDate() + daysToAdd);
      const newTimestampString = dateObj.toLocaleDateString();
      const [month, day, year] = newTimestampString.split('/');
      const formattedDate = `${year}-${pad(month)}-${pad(day)}`;
      return formattedDate;
    }
    return value;
  };

  handleChange = (e) => {
    const { testOnChange } = this.state;
    const { type } = this.props;
    const { newInputValue } = this.getNewInputValue(e.target.value);
    this.updateState({ inputValue: newInputValue });
    if (type.includes('percent')) {
      this.setSuffixPosition();
    }
    if (type === 'textarea') {
      this.resizeTextArea();
    }

    if (!e.target.value) {
      e.target.removeAttribute('aria-empty');
      e.target.removeAttribute('aria-valid');
      e.target.removeAttribute('aria-invalid');
      this.updateState({ testOnChange: false });
    } else {
      e.target.setAttribute('aria-empty', 'true');
      if (testOnChange || e.target.validity.valid) {
        this.setFieldValidity(e.target);
      }
    }
    // this will only fire if a field was changed in a way other than typing in it
    this.typingTimer === undefined && this.handleCallback(e);
  };

  getIsValid = (newInputValue) => {
    const { customValidation, id } = this.props;
    return customValidation !== null
      ? customValidation(newInputValue, { id })
      : this.checkValidation(newInputValue);
  };

  setType = (t) => {
    // html5 url input, has built in validation, so we dont NEED to supply a pattern,
    // but if we do it seems we need to set it as a text type
    // otherwise it always requires a protocol despite the regex pattern
    switch (t) {
      case 'stateCode':
        return 'text';
      case 'price':
        return 'number';
      case 'percent':
        return 'number';
      case 'percentFivePrecision':
        return 'number';
      case 'ratioSevenPrecision':
      case 'ratioTwoPrecision':
        return 'number';
      case 'percentOwnership':
        return 'number';
      case 'url':
        return 'text';
      case 'mid':
        return 'text';
      case 'futureDate':
        return 'date';
      case 'futureTime':
        return 'time';
      case 'anyTime':
        return 'time';
      case 'onlyAlpha':
        return 'text';
      case 'rationalNumber':
        return 'number';
      case 'dollarAndSevenPrecision':
        return 'number';
      default:
        return t;
    }
  };

  setSuffixPosition = () => {
    const inputField = this.inputFieldRef.current;
    const suffix = this.inputSuffixRef.current;
    if (inputField && suffix) {
      suffix.style.left = `${getTextDimensions(inputField).width}px`;
    }
  };

  resizeTextArea = () => {
    const target = this.inputFieldRef.current;
    if (target) {
      /**
       * This validates the textarea field is visible before setting its height,
       * which is needed if the field has an existing value, loads in tabbed content,
       * and the tab is not yet visible - the parent element seems to throw off
       * the textarea scrollHeight value
       */
      const observer = new IntersectionObserver(this.intersectionCallback);
      observer.observe(target);
    }
  };

  intersectionCallback = (entries) => {
    if (entries[0].isIntersecting) {
      const target = this.inputFieldRef.current;
      const text = target ? `${target.value.replace(/\n/g, '<br/>')}&nbsp;` : '';
      this.textareaShadowRef.current &&
        (this.textareaShadowRef.current.innerHTML = sanitizeHTML(text));
      // timeout is needed here because the field renders before the value is set on mount
      setTimeout(this.setTextareaHeight, 300);
    }
  };

  setTextareaHeight = () => {
    const target = this.inputFieldRef.current;
    if (target) {
      const newHeight = Math.max(
        target.scrollHeight,
        this.textareaShadowRef.current.scrollHeight,
        40
      );
      target.style.height = `${Math.min(newHeight, window.innerHeight)}px`;
    }
  };

  handleCallback = (e, key) => {
    const { id, callback } = this.props;
    if (callback) {
      const { newInputValue } = this.getNewInputValue(e?.target?.value);
      const finalValue = newInputValue ? newInputValue.trim() : null;
      const isValid = this.getIsValid(finalValue);
      callback(id, newInputValue, isValid, {
        ...(key === 'Enter' && { submitOnEnter: true })
      });
    }
  };

  togglePassword = (e) => {
    const icon = e.target;
    const pwField = this.inputFieldRef.current;
    if (pwField && icon) {
      // Toggle input between PW and text
      pwField.type === 'text' ? (pwField.type = 'password') : (pwField.type = 'text');
      // Toggle icon between view/hidden
      icon.classList.contains('alt') ? icon.classList.remove('alt') : icon.classList.add('alt');
    }
  };

  handleKeyUp = (e) => {
    const { allowSubmitOnEnter } = this.props;
    clearTimeout(this.typingTimer);
    if (e && e.key === 'Enter' && allowSubmitOnEnter) {
      this.handleCallback(e, e.key);
    } else {
      this.typingTimer = setTimeout(() => {
        // for performance:  only run callback after user has finished typing
        // instead of after every key action
        this.handleCallback(e);
      }, 300);
    }
  };

  handleKeyDown = (e) => {
    const { onKeyDown } = this.props;
    if (e.target && e.target.type === 'text') {
      onKeyDown(e);
    }
    clearTimeout(this.typingTimer);
  };

  render() {
    const { errorText, inputValue } = this.state;
    const {
      id,
      label,
      type,
      height,
      disabled,
      required,
      minLength,
      maxDate,
      minDate,
      step,
      inputStyle,
      labelStyle,
      boxStyle,
      icon,
      altIcon,
      tooltip,
      tooltipWidth,
      infoTipDisplay,
      wrapperStyle,
      placeholder,
      className,
      clearAutofillOnMount,
      noValidate,
      requireProtocol,
      customValidation,
      suffixType,
      businessHoursOnly,
      roundMinutes,
      suggestedTimeList
    } = this.props;
    return (
      <InputWrap
        type={type}
        height={height}
        $hasLabel={label}
        $hasIcon={!isEmpty(icon)}
        htmlFor={id}
        $boxStyle={boxStyle}
        {...(wrapperStyle && {
          style: wrapperStyle
        })}>
        <div style={{ minHeight: height, position: 'relative' }}>
          {type === 'textarea' ? (
            <Textarea
              $boxStyle={boxStyle}
              data-gramm={false}
              onKeyUp={this.handleKeyUp}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              id={id}
              {...(isEmpty(label) && { 'aria-label': id })}
              ref={this.inputFieldRef}
              disabled={disabled || false}
              spellCheck="true"
              value={inputValue}
              // error={valid !== null && !valid && !isEmpty(errorMessage)}
              // valid={valid && isEmpty(errorMessage)}
              placeholder={placeholder}
              required={required}
              onBlur={this.handleBlur}
              onClick={this.handleClick}
              {...(validations[type]?.minlength && {
                minLength: validations[type].minlength
              })}
              {...(validations[type]?.maxlength && {
                maxLength: validations[type].maxlength
              })}
              {...(!isEmpty(minLength) && { minLength: `${minLength}` })}
              {...(inputStyle && { style: inputStyle })}
            />
          ) : (
            <input
              id={id}
              name={id}
              value={inputValue}
              {...(className && {
                className
              })}
              aria-describedby={`${id}-error`}
              {...(isEmpty(label) && { 'aria-label': id })}
              type={this.setType(type)}
              required={required}
              disabled={disabled}
              ref={this.inputFieldRef}
              onWheel={(e) => this.handleWheel(e, type)}
              {...(!noValidate &&
                customValidation === null && {
                  // text inputs can have a min and max length set
                  ...(validations[type]?.minlength && {
                    minLength: validations[type].minlength
                  }),
                  ...(validations[type]?.maxlength && {
                    maxLength: validations[type].maxlength
                  }),
                  // number inputs can have min and max values set
                  // use whatever was manually passed in if it exists,
                  // otherwise use whats in validation object
                  ...((minDate || validations[type]?.min) && {
                    min: minDate || validations[type].min
                  }),
                  ...((maxDate || validations[type]?.max) && {
                    max: maxDate || validations[type].max
                  }),
                  ...(type === 'anyTime' &&
                    step && {
                      step
                    }),
                  ...(validations[type]?.step && {
                    step: validations[type].step
                  }),
                  // textarea does NOT suport pattern
                  ...(validations[type]?.pattern &&
                    !requireProtocol && {
                      pattern: noValidate
                        ? validations.noValidate.pattern
                        : validations[type].pattern
                    }),
                  ...(requireProtocol && {
                    pattern: noValidate
                      ? validations.noValidate.pattern
                      : validations.urlRequiredProtocol.pattern
                  }),
                  ...(this.timeTypes.includes(type) &&
                    businessHoursOnly && {
                      ...(roundMinutes && { step: 1800 }), // round to nearest 30-min increment
                      min: '09:00', // 9 AM - 5 PM
                      max: '17:00'
                    })
                })}
              {...(inputStyle && {
                style: {
                  ...inputStyle,
                  ...(this.dateAndTimeTypes.includes(type) && { fontSize: '1.2rem' }),
                  ...(!isEmpty(errorText) && input.errorShake),
                  ...(type === 'tel' && { paddingLeft: '25px' })
                }
              })}
              placeholder={placeholder}
              {...(clearAutofillOnMount && { autoComplete: 'new-password' })}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onKeyUp={this.handleKeyUp}
              onKeyDown={this.handleKeyDown}
              onPaste={this.handlePaste}
              {...(!isEmpty(suggestedTimeList) && { list: `${id}TimeList` })}
            />
          )}
          {!isEmpty(suggestedTimeList) && (
            <datalist id={`${id}TimeList`}>
              {suggestedTimeList.map((item) => (
                <option key={`${item.value}`} value={item.value} />
              ))}
            </datalist>
          )}
          {label && (
            <span className="labelText" {...(labelStyle && { style: labelStyle })}>
              {required && <RequiredStar />}
              {label}
              {tooltip && (
                <ToolTip
                  infoTip
                  infoTipDisplay={{
                    top: '3px',
                    ...(infoTipDisplay && { infoTipDisplay })
                  }}
                  {...(tooltipWidth && { width: tooltipWidth })}>
                  {tooltip}
                </ToolTip>
              )}
            </span>
          )}
          {type === 'price' && (
            <Inputprefix data-testid="inputPrefix" $boxStyle={boxStyle}>
              $
            </Inputprefix>
          )}
          {type === 'tel' && (
            <Inputprefix data-testid="inputPrefix" $boxStyle={boxStyle}>
              +1
            </Inputprefix>
          )}
          {type.includes('percent') && (
            <InputSuffix
              data-testid="inputSuffix"
              ref={this.inputSuffixRef}
              label={label}
              $boxStyle={boxStyle}>
              %
              {suffixType === 'basisPoints' && !isEmpty(inputValue) && isEmpty(errorText) && (
                <span
                  className="basisPoints"
                  style={{
                    paddingLeft: '0.3em',
                    fontSize: '1.2rem',
                    color: 'var(--color-light-label)'
                  }}>{`(${formatNumber(bigDecimal.multiply(inputValue, '100'))} basis points)`}</span>
              )}
            </InputSuffix>
          )}
          {!isEmpty(icon) && (
            <Icon
              className="inputIcon"
              data-testid="inputIcon"
              icon={icon.src_color}
              color="var(--color-disabled)"
              {...(!isEmpty(altIcon) && { $altIcon: altIcon.src_color })}
              {...(type === 'password' && {
                onClick: this.togglePassword
              })}
              style={{
                ...(type !== 'password' && {
                  pointerEvents: 'none'
                }),
                position: 'absolute',
                ...(boxStyle !== 'inside'
                  ? {
                      top: '50%',
                      marginTop: '-10px'
                    }
                  : {
                      top: `${height / 2 + 18}px`,
                      marginTop: '-10px'
                    }),
                right: '5px'
              }}
              $useMask
            />
          )}
        </div>
        <ErrorBox id={`${id}-error`} $error={!isEmpty(errorText)} $boxStyle={boxStyle}>
          {errorText}
        </ErrorBox>
        {type === 'textarea' && <TextAreaShadow ref={this.textareaShadowRef} />}
      </InputWrap>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  // startState: PropTypes.string,
  callback: PropTypes.func,
  // reference: PropTypes.oneOfType([PropTypes.any]),
  type: PropTypes.string,
  dateInput: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  step: PropTypes.string,
  boxStyle: PropTypes.oneOf(['default', 'inside', 'animated']),
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  required: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  altIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  noValidate: PropTypes.bool,
  minLength: PropTypes.number,
  customValidation: PropTypes.func,
  setError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // addFilterBorder: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  tooltip: PropTypes.string,
  className: PropTypes.string,
  suffixType: PropTypes.string,
  clearAutofillOnMount: PropTypes.bool,
  tooltipWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  infoTipDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  requireProtocol: PropTypes.bool,
  validationActivated: PropTypes.bool,
  disablePaste: PropTypes.bool,
  allowToday: PropTypes.bool,
  businessHoursOnly: PropTypes.bool,
  roundMinutes: PropTypes.bool,
  suggestedTimeList: PropTypes.oneOfType([PropTypes.array]),
  useValidationMessage: PropTypes.bool,
  weekdaysOnly: PropTypes.bool,
  allowSubmitOnEnter: PropTypes.bool,
  onKeyDown: PropTypes.func
};

Input.defaultProps = {
  id: '',
  // startState: 'default',
  callback: () => {},
  // reference: null,
  type: 'text',
  dateInput: null, // required if type === 'time'/'futureTime'
  maxDate: null,
  minDate: null,
  step: null,
  boxStyle: 'default',
  wrapperStyle: {},
  inputStyle: {},
  labelStyle: {},
  height: 40,
  required: false,
  icon: {},
  altIcon: {},
  disabled: false,
  label: null,
  placeholder: null,
  errorMessage: '',
  noValidate: false,
  minLength: null,
  customValidation: null,
  setError: null,
  // addFilterBorder: false,
  value: '',
  tooltip: null,
  className: null,
  suffixType: null,
  clearAutofillOnMount: false,
  tooltipWidth: null,
  infoTipDisplay: {},
  requireProtocol: false,
  validationActivated: false,
  disablePaste: false,
  allowToday: false, // OPTIONAL for type="futureDate"
  businessHoursOnly: false, // OPTIONAL for type="time" || type="futureTime"
  roundMinutes: false, // OPTIONAL for type="time" || type="futureTime"
  suggestedTimeList: [],
  useValidationMessage: false, // user input generated error message
  weekdaysOnly: false,
  allowSubmitOnEnter: false,
  onKeyDown: () => {}
};

export default Input;
