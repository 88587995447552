import React from 'react';
import PropTypes from 'prop-types';
import { addToLocalDB, getFromLocalDB } from './_dataCompression';

import { isEmpty, logError, logAsWarn, setClickTrail } from './_helpers';
import { getAccessTokenTimestamps, isUserAuthenticated } from './_pollingUtils';

export class ErrorBoundary extends React.Component {
  componentDidMount() {
    window.addEventListener('click', this.handleSetClickTrail);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleSetClickTrail);
  }

  handleSetClickTrail = async (e) => {
    const { customOptions, store } = this.props;
    const reduxStore = !isEmpty(store) ? store.getState() : {};
    const { authenticate } = reduxStore || {};
    const { user } = authenticate || {};
    const isAuthenticated = await isUserAuthenticated({ store, customOptions });
    await setClickTrail(e, {
      ...customOptions,
      isAuthenticated,
      getFromLocalDB,
      addToLocalDB
    });
    if (!isAuthenticated) {
      !isEmpty(store) && store.dispatch({ type: 'AUTHENTICATE', payload: 'reset' });
    } else {
      // user is signed in
      const { lastClickTimestamp, exp } = await getAccessTokenTimestamps(customOptions);
      await store.dispatch({
        type: 'AUTHENTICATE',
        payload: {
          accessToken: {
            ...user.accessToken,
            lastClickTimestamp,
            exp
          }
        }
      });
    }
  };

  handleError = async (error, errorInfo) => {
    const { customOptions = {}, store } = this.props;
    const clickTrail = await getFromLocalDB('clickTrail', customOptions);
    const { userEmail } = customOptions || {};
    const reduxStore = !isEmpty(store) ? store.getState() : {};
    const { authenticate } = reduxStore || {};
    const { isAuthenticated } = authenticate || {};
    const errorToLog = error.message || error;
    const page = typeof window !== 'undefined' ? window.location.href : 'Unknown page';
    const customLogMessage = error.message
      ? `ON PAGE: ${page} USER: ${userEmail}${!isEmpty(clickTrail) ? `CLICKS: ${JSON.stringify(clickTrail)}` : ''} JAVASCRIPT ERROR NAME: ${error.name}: ${error.message} JAVASCRIPT ERROR TRACE: ${error.stack.toString()} JAVASCRIPT ERROR COMPONENT STACK: ${errorInfo?.componentStack}`
      : null;
    const options = {
      ...customOptions,
      ...(error.message && {
        // some random JS error.
        logLevel:
          logAsWarn(errorToLog, { customLogMessage }) ||
          (!isEmpty(customLogMessage) && !isAuthenticated)
            ? 'WARN'
            : 'ERROR',
        customLogMessage
      })
    };
    logError(errorToLog, options);
  };

  componentDidCatch(error, errorInfo) {
    this.handleError(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  customOptions: PropTypes.shape({
    axiosRequest: PropTypes.func,
    userEmail: PropTypes.string
  }),
  store: PropTypes.oneOfType([PropTypes.object])
};
ErrorBoundary.defaultProps = {
  children: null,
  customOptions: {
    axiosRequest: () => {},
    userEmail: ''
  },
  store: {}
};

export default ErrorBoundary;
