import { getRandomNumber } from '@f1/shared/src/_helpers';
import {
  getMockPageData,
  getMockValue,
  mockPagingInfo
} from '@f1/shared/src/data/_mockDataHelpers';
import { getMockMerchantLedgerData } from '@f1/shared/src/data/reports/merchantLedger';
import { bankNameList, processorNameList } from '../../pages/components/forms/formHelpers';

const getMECOMockMerchantLedgerData = () => {
  const ledgerData = getMockMerchantLedgerData();
  return ledgerData.map((entry) => [
    {
      ...entry,
      relationship: {
        ...entry.relationship,
        bankName: bankNameList[getRandomNumber({ min: 0, max: 10 })]?.value,
        processorName: processorNameList[getRandomNumber({ min: 0, max: 11 })]?.value,
        riskProfile: getMockValue({ type: 'string' })
      }
    }
  ]);
};

export const mockMerchantLedgerGet = (body, params, options) => {
  const { guid = `"{}"` } = options || {};
  const {
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex,
    pageSize = mockPagingInfo.pageSize
  } = params || {};
  const pageData = getMockPageData({
    allData: getMECOMockMerchantLedgerData(),
    pageIndex,
    pageSize,
    optionsCopy: { ...params }
  });
  const mockData = {
    merchantLedgerEntries: pageData,
    pagingInfo: {
      totalNumberOfRecords,
      pageIndex,
      pageSize
    }
  };
  return { [guid]: mockData };
};

export default mockMerchantLedgerGet;
