// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { Page } from '../Page';
import { PageHeader } from '../PageHeader';
import { PageContent } from '../PageContent';
import { Spinner } from '../Spinner';
import { DataTable } from '../DataTable';
import { ToggleSwitch } from '../ToggleSwitch';
import { NoData } from '../NoData';
import { DataBox } from '../DataBox';
import { pageHeaderCSS, pageContentCSS } from '../_styles';
import ACHReportFilterBar from './components/ACHReportFilterBar';
import achDetailsTemplate from '../data/reports/templates/achDetailsTemplate';
import { sharedGetInnerAlertBarState, isEmpty, isEqual, transformData } from '../_helpers';
import { FlexBoxWrap } from '../../css/_styledComponents';

export class BoundAchReport extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.achTypeEnumToCamel = {
      ach_rejects: 'achRejects',
      reserve_payouts: 'reservePayouts',
      achFundingDetailsList: 'achFundingDetailsList'
    };
    this.achTypeDefaultState = {
      tableData: [],
      allDataLoaded: false,
      spinnerLoading: false,
      errorMessage: null
    };
    this.state = {
      achRejects: { ...this.achTypeDefaultState },
      reservePayouts: { ...this.achTypeDefaultState },
      achFundingDetailsList: { ...this.achTypeDefaultState },
      isPartner: false, // portal (partner) only
      viewAsMerchant: false, // portal (partner) only
      restActiveFilters: {}, // portal (partner) only
      callMade: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { store } = this.props;
    store.subscribe(this.handleStoreChanges);
    this.handleStoreChanges();
  }

  componentWillUnmount() {
    const { store } = this.props;
    store && store.subscribe && store.subscribe(this.handleStoreChanges);
    this.mounted = false;
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  };

  handleStoreChanges = () => {
    const { store, location } = this.props;
    const {
      viewAsMerchant: prevViewAsMerchant,
      restActiveFilters: prevRestActiveFilters,
      isPartner: prevIsPartner
    } = this.state;
    const { authenticate } = store.getState();
    const { user } = authenticate || {};
    const { isPartner } = user || {};
    const isPortalUser = location === 'portal';
    if (isPortalUser && isPartner) {
      const { filterData } = store.getState();
      const { activeFilters } = filterData || {};
      const { viewAsMerchant = false, ...restActiveFilters } = activeFilters || {};
      if (
        !isEqual(prevIsPartner, isPartner) ||
        !isEqual(prevViewAsMerchant, viewAsMerchant) ||
        !isEqual(prevRestActiveFilters, restActiveFilters)
      ) {
        this.updateState({ isPartner, viewAsMerchant, restActiveFilters });
      }
    }
  };

  setMerchantView = (checked) => {
    const { store } = this.props;
    const { restActiveFilters } = this.state;
    store.dispatch({
      type: 'FILTER_DATA',
      activeFilters: { ...restActiveFilters, viewAsMerchant: checked }
    });
    this.updateState({ callMade: false });
  };

  getDataArray = (dataObj, achTypeCamel) => {
    if (!isEmpty(dataObj)) {
      if (Array.isArray(dataObj[achTypeCamel]?.[0]?.data)) {
        // eg. { achRejects: [{ data: [] }] }
        return dataObj[achTypeCamel]?.[0]?.data;
      }
      if (Array.isArray(dataObj[achTypeCamel]?.data)) {
        // eg: { achRejects: { data: [] } }
        return dataObj[achTypeCamel]?.data;
      }
      if (Array.isArray(dataObj[achTypeCamel])) {
        // eg: { achRejects: [] }
        return dataObj[achTypeCamel];
      }
    }
    return [];
  };

  getApiResponse = async (options) => {
    const { requestGuid, achType, yearMonth, firstPage = true, firstPageData = [] } = options || {};
    const { achFundingEndpoint, achDetailsEndpoint, axiosRequest, getAllPageData, location } =
      this.props;
    const achTypeCamel = this.achTypeEnumToCamel[achType];
    const isAchFunding = achTypeCamel === 'achFundingDetailsList';
    const reqOptions = {
      dataKey: achTypeCamel,
      axiosRequestOptions: {
        fullPageLoad: false,
        url: isAchFunding ? achFundingEndpoint : achDetailsEndpoint,
        method: 'get',
        requestGuid
      },
      customQueryParams: [
        ...(isAchFunding ? [] : [{ key: 'achType', value: achType }]),
        { key: 'yearMonth', value: yearMonth }
      ],
      firstPage,
      multiCall: true
    };
    firstPage &&
      this.updateState((prevState) => ({
        [achTypeCamel]: { ...prevState[achTypeCamel], spinnerLoading: true }
      }));
    const apiRes = await getAllPageData(reqOptions);
    if (apiRes?.errorDetails instanceof Error) {
      const statusCode = apiRes?.state?.status || apiRes?.errorDetails?.response?.status;
      let statusMessage;
      // state.status and response.status always return undefined if the request is too large
      // as the BE prevents us from sending a request that exceeds 10240 bytes
      // making sure we check the max length of items allowed (226) as well
      if (requestGuid?.relationshipIdList?.length > 226 && !statusCode) {
        statusMessage =
          'The limit for number of relationshipIds in a request has been exceeded. Please reduce the number and try again.';
      } else {
        const { alertBarMessage } = sharedGetInnerAlertBarState({
          type: 'warning',
          data: apiRes.errorDetails,
          axiosRequest
        });
        statusMessage = alertBarMessage;
      }
      this.updateState((prevState) => ({
        [achTypeCamel]: {
          ...prevState[achTypeCamel],
          tableData: [],
          allDataLoaded: true,
          spinnerLoading: false,
          errorMessage: statusMessage
        }
      }));
    } else {
      const currentPageData = this.getDataArray(apiRes?.data || {}, achTypeCamel);
      const { pagingInfo = {} } = apiRes?.data || {};
      const dataToFormat = [...(firstPageData ?? []), ...(currentPageData ?? [])];
      const dataFormatted = transformData({
        data: { achTypeCamel, location, data: dataToFormat },
        toSchema: 'frontend',
        template: achDetailsTemplate,
        version: '1.0'
      });
      const sharedSuccessState = {
        tableData: dataFormatted,
        allDataLoaded: false,
        spinnerLoading: false,
        errorMessage: null
      };
      if (firstPage) {
        const hasMoreRecords = pagingInfo?.totalNumberOfRecords > pagingInfo?.pageSize;
        this.updateState(
          (prevState) => ({
            [achTypeCamel]: {
              ...prevState[achTypeCamel],
              ...sharedSuccessState,
              ...(!hasMoreRecords && { allDataLoaded: true })
            }
          }),
          hasMoreRecords
            ? () =>
                this.getApiResponse({
                  ...options,
                  firstPageData: currentPageData || [],
                  firstPage: false
                })
            : null
        );
      } else {
        this.updateState((prevState) => ({
          [achTypeCamel]: {
            ...prevState[achTypeCamel],
            ...sharedSuccessState,
            allDataLoaded: true
          }
        }));
      }
    }
  };

  handleSubmit = async (data) => {
    const { yearMonth, merchOrRelationshipType, merchantGuid, selectedRelationshipIdList } =
      data || {};
    const requestGuid =
      merchOrRelationshipType === 'relationship'
        ? { relationshipIdList: selectedRelationshipIdList }
        : { merchantGuid };
    const requests = Object.keys(this.achTypeEnumToCamel).map(
      (achType) => () => this.getApiResponse({ requestGuid, achType, yearMonth })
    );
    this.updateState({ callMade: true });
    return Promise.allSettled(requests.map((req) => req()));
  };

  render() {
    const {
      store,
      location,
      getLocalDbNestedMerchants,
      getMerchantsWithPermissionList,
      getRelationshipsWithPermissionList
    } = this.props;
    const {
      achFundingDetailsList,
      achRejects,
      reservePayouts,
      isPartner,
      viewAsMerchant,
      callMade
    } = this.state;
    return (
      <Page id="ach-report-page" style={{ position: 'relative' }} portal={location === 'portal'}>
        <PageHeader childrenWrapperStyle={pageHeaderCSS.childrenWrapper}>
          <div style={pageHeaderCSS.titleWrapper}>
            <div style={pageHeaderCSS.heading}>
              <h2 style={{ ...pageHeaderCSS.title }}>ACH Report</h2>
            </div>
            {location === 'portal' && isPartner && (
              <ToggleSwitch
                id="view-as-merchant-toggle"
                label="Show Merchant View"
                wrapperStyle={{ margin: '0 10px 0 0' }}
                callback={this.setMerchantView}
                checked={viewAsMerchant}
              />
            )}
          </div>
          <ACHReportFilterBar
            store={store}
            userLocation={location}
            callback={this.handleSubmit}
            getLocalDbNestedMerchants={getLocalDbNestedMerchants}
            getMerchantsWithPermissionList={getMerchantsWithPermissionList}
            getRelationshipsWithPermissionList={getRelationshipsWithPermissionList}
          />
        </PageHeader>
        <PageContent
          fullWidth
          customStyle={{
            ...pageContentCSS,
            display: callMade,
            paddingTop: '0'
          }}>
          {callMade ? (
            <FlexBoxWrap style={{ flexDirection: 'column' }}>
              <DataBox title="ACH Funding" style={{ position: 'relative' }}>
                <Spinner loading={achFundingDetailsList.spinnerLoading} />
                {!isEmpty(achFundingDetailsList.errorMessage) ||
                isEmpty(achFundingDetailsList.tableData) ||
                achFundingDetailsList.spinnerLoading ? (
                  <NoData
                    loading={achFundingDetailsList.spinnerLoading}
                    err={!isEmpty(achFundingDetailsList.errorMessage)}
                    customMessage={achFundingDetailsList.errorMessage}
                  />
                ) : (
                  <DataTable
                    id="achFundingTable"
                    pageNum={1}
                    perPage={5}
                    passedData={achFundingDetailsList.tableData ?? []}
                    downloadFile={{ fileName: 'ACHFunding', sheetName: 'ACH Funding' }}
                    dataIsIncomplete={!achFundingDetailsList.allDataLoaded}
                  />
                )}
              </DataBox>
              <DataBox title="Rejections" style={{ position: 'relative' }}>
                <Spinner loading={achRejects.spinnerLoading} />
                {!isEmpty(achRejects.errorMessage) ||
                isEmpty(achRejects.tableData) ||
                achRejects.spinnerLoading ? (
                  <NoData
                    loading={achRejects.spinnerLoading}
                    err={!isEmpty(achRejects.errorMessage)}
                    customMessage={achRejects.errorMessage}
                  />
                ) : (
                  <DataTable
                    id="achRejectsTable"
                    pageNum={1}
                    perPage={5}
                    passedData={achRejects.tableData ?? []}
                    downloadFile={{ fileName: 'ACHRejects', sheetName: 'ACH Rejects' }}
                    dataIsIncomplete={!achRejects.allDataLoaded}
                  />
                )}
              </DataBox>
              <DataBox title="Reserve Payouts" style={{ position: 'relative' }}>
                <Spinner loading={reservePayouts.spinnerLoading} />
                {!isEmpty(reservePayouts.errorMessage) ||
                isEmpty(reservePayouts.tableData) ||
                reservePayouts.spinnerLoading ? (
                  <NoData
                    loading={reservePayouts.spinnerLoading}
                    err={!isEmpty(reservePayouts.errorMessage)}
                    customMessage={reservePayouts.errorMessage}
                  />
                ) : (
                  <DataTable
                    id="reservePayoutsTable"
                    pageNum={1}
                    perPage={5}
                    passedData={reservePayouts.tableData ?? []}
                    downloadFile={{ fileName: 'ReservePayouts', sheetName: 'Reserve Payouts' }}
                    dataIsIncomplete={!reservePayouts.allDataLoaded}
                  />
                )}
              </DataBox>
            </FlexBoxWrap>
          ) : (
            <div>Submit the form above to see the ACH Report for the selected time frame.</div>
          )}
        </PageContent>
      </Page>
    );
  }
}

BoundAchReport.propTypes = {
  store: PropTypes.oneOfType([PropTypes.object]),
  location: PropTypes.string,
  achDetailsEndpoint: PropTypes.string,
  achFundingEndpoint: PropTypes.string,
  axiosRequest: PropTypes.func.isRequired,
  getAllPageData: PropTypes.func,
  getLocalDbNestedMerchants: PropTypes.func,
  getMerchantsWithPermissionList: PropTypes.func,
  getRelationshipsWithPermissionList: PropTypes.func
};

BoundAchReport.defaultProps = {
  store: {},
  location: '',
  achDetailsEndpoint: '',
  achFundingEndpoint: '',
  getAllPageData: () => {},
  getLocalDbNestedMerchants: () => {}, // meco
  getMerchantsWithPermissionList: () => {}, // portal
  getRelationshipsWithPermissionList: () => {} // portal
};

const AchReport = BoundAchReport;

export default AchReport;
