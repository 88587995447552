import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { createMockFile } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { mockEmployee, mockV1ExperianResponse } from '../mockV1ExperianPost';

export const createMockBusinessOwner = () => {
  const mockUser = getMockValue({ type: 'user' });
  const mockAddress = getMockValue({ type: 'address' });
  return {
    firstName: mockUser.firstName,
    middleName: 'string',
    lastName: mockUser.lastName,
    generationCode: mockUser.generation,
    ssn: getMockValue({ type: 'ssn' }),
    dateOfBirth: {
      day: getMockValue({ min: 1, max: 28 }),
      month: getMockValue({ min: 1, max: 12 }),
      year: getMockValue({ min: 1960, max: 2000 })
    },
    title: mockUser.jobTitle,
    address: {
      street: mockAddress.fullAddress,
      city: mockAddress.cityName,
      state: mockAddress.stateCode,
      zip: mockAddress.zipCode
    },
    driverLicense: {
      state: mockAddress.stateCode,
      number: getMockValue({ min: 1000, max: 2000 })
    }
  };
};

const createMockExperianResult = (options) => {
  const mockBusinessSearchAddress = getMockValue({ type: 'address' });
  const mockBusinessSearchEmployee = mockEmployee();
  const mockResult = {
    businessOwnerProfile: {
      experianRequest: {
        subcode: '0552027',
        businessOwners: Array.from({
          length: getMockValue({ type: 'number', min: 1, max: 4 })
        }).map(createMockBusinessOwner)
      },
      ...mockV1ExperianResponse
    },
    businessSearch: {
      experianRequest: {
        subcode: '0552027',
        businessName: getMockValue({ type: 'businessName' }),
        city: mockBusinessSearchAddress.cityName,
        state: mockBusinessSearchAddress.stateCode,
        street: mockBusinessSearchAddress.fullAddress,
        zip: mockBusinessSearchAddress.zipCode,
        phone: getMockValue({ type: 'bePhone' }),
        geo: getMockValue({ type: 'boolean' })
      },
      experianResponse: {
        experianRequestId: getMockValue({ type: 'guid' }),
        timestampQueried: getMockValue({ type: 'isoDate' }),
        employee: {
          email: mockBusinessSearchEmployee.email,
          firstName: mockBusinessSearchEmployee.firstName,
          lastName: mockBusinessSearchEmployee.lastName,
          manager: mockBusinessSearchEmployee.manager,
          employeeId: mockBusinessSearchEmployee.employeeId
        },
        experianResultPdf: createMockFile(1, options),
        experianResultJson:
          '{"requestId":"ec55cca0-9342-11e7-bddf-ebd4476c3102","responseMessage":"Your search did not match any records. Suggestions try adding optional fields like street address and phone to your search, check your spelling, and remember, the absence of a match can be valuable information.","comments":"my_guid_for_tracking","success":true,"results":[{"bin":"800914632","reliabilityCode":91.02,"businessName":"EXPERIAN","phone":"+17148307000","address":{"street":"535 ANTON BLVD","city":"Costa Mesa","state":"CA","zip":"92626","zipExtension":"9262"},"numberOfTradelines":3,"financialStatementIndicator":true,"keyFactsIndicator":true,"inquiryIndicator":true,"bankDataIndicator":true,"governmentDataIndicator":true,"executiveSummaryIndicator":true,"uccIndicator":true,"matchingNameAndAddress":{"businessName":"EXPERIAN","address":{"street":"535 ANTON BLVD","city":"Costa Mesa","state":"CA","zip":"92626","zipExtension":"9262"}},"businessGeocode":{"latitudeLongitudeLevel":"Roof Top Level","latitude":33.690764,"longitude":-117.873551,"msaCode":"5945","censusBlkGrpCode":"2","censusTractCode":"063907","cottageIndicator":true,"congressionalDistrictCode":"48","dateLastReported":"01/01/1977"}}]}'
      }
    }
  };
  return mockResult;
};

export const mockV3ExperianApplicationPost = (body, params, options) => {
  const { guid } = options || {};
  const mockResultList = Array.from({ length: guid === '0' ? 1 : 2 }).map(() =>
    createMockExperianResult(options)
  );
  return {
    [guid]: { resultList: mockResultList }
  };
};
