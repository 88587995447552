// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from './Input';
import FormCustomSection from './FormCustomSection';
import { isEmpty } from './_helpers';
import { toFrontendValue } from './_templateHelpers';
import { ErrorBox } from '../css/_styledFormComponents';
import { getValueChangedData } from './_formHelpers';

class FormCustomSectionConfirmFields extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { id, fields } = props;
    this.sectionId = id;
    this.actualFieldKeys = !isEmpty(fields) ? Object.keys(fields).filter((k) => k !== 'id') : [];
    this.actualAndConfirmFields = !isEmpty(fields)
      ? Object.entries(fields)
          .filter(([key, _value]) => key !== 'id')
          .reduce((acc, [key, value]) => {
            const confirmFieldId = `${key}Confirm`;
            const actualField = { ...value, validateId: key };
            const newConfirmField = {
              ...actualField,
              id: confirmFieldId,
              label: `Re-enter ${value.label}`,
              errorMessage: `Must match ${value.label}`,
              customValidation: this.handleCustomValidation,
              isConfirmField: true
            };
            return { ...acc, [key]: actualField, [confirmFieldId]: newConfirmField };
          }, {})
      : {};
    this.state = {
      fullSectionValid: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { allowEmpty } = this.props;
    if (prevProps.allowEmpty !== allowEmpty) {
      this.handleValidate();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  initialize = () => {
    const { data } = this.props;
    const frontendFormValues = !isEmpty(data)
      ? Object.entries(data).reduce((acc, [key, value]) => {
          const frontendValue =
            !isEmpty(value) && this.actualAndConfirmFields?.[key]
              ? toFrontendValue(value, this.actualAndConfirmFields[key])
              : undefined;
          return { ...acc, [key]: frontendValue, [`${key}Confirm`]: frontendValue };
        }, {})
      : {};
    this.updateState(
      {
        ...frontendFormValues,
        ...(!isEmpty(data) && { valuesForBackend: data })
      },
      this.handleValidate
    );
  };

  handleInputChange = (id, value, valid) => {
    const field = this.actualAndConfirmFields[id] || {};
    const { isConfirmField } = field || {};
    const { [`${id}Confirm`]: confirmValue } = this.state;
    // When actualValue is updated, clear the confirm field
    const shouldClearConfirmField = (!isConfirmField && !isEmpty(confirmValue)) || false;
    const valueChangedData = getValueChangedData(value, id, this.actualAndConfirmFields[id]);
    const { backendValue } = valueChangedData || {};
    this.updateState(
      (prevState) => ({
        formInProgress: true,
        [id]: value,
        ...(!isConfirmField && { [`${id}IsValid`]: valid }),
        ...(shouldClearConfirmField && { [`${id}Confirm`]: '' }),
        valueChangedData,
        valuesForBackend: {
          ...prevState.valuesForBackend,
          [id]: backendValue
        }
      }),
      this.handleValidate
    );
  };

  handleCustomValidation = (value, options) => {
    const { id } = options || {};
    const field = this.actualAndConfirmFields[id] || {};
    const { isConfirmField, validateId } = field || {};
    const { [validateId]: actualFieldValue, [`${id}Confirm`]: confirmValue } = this.state;
    if (isEmpty(value)) {
      return false;
    }
    return isConfirmField ? value === actualFieldValue : value === confirmValue;
  };

  handleValidate = () => {
    const { allowEmpty, validateFields } = this.props;
    const isFullSectionValid = this.actualFieldKeys.every((key) => {
      const { [key]: actualValue, [`${key}Confirm`]: confirmValue } = this.state;
      const hasNoValues = isEmpty(actualValue) && isEmpty(confirmValue);
      const hasAllValues = !isEmpty(actualValue) && !isEmpty(confirmValue);
      const hasSomeValues = !isEmpty(actualValue) || !isEmpty(confirmValue);
      if (
        (hasNoValues && allowEmpty && !validateFields) ||
        (hasAllValues && actualValue === confirmValue)
      ) {
        return true;
      }
      /**
       * confirmValue MUST match actualValue if either value exists,
       * since confirmValue does not get stored, we must validate the match before allowing save
       */
      if (hasSomeValues && actualValue !== confirmValue) {
        return false;
      }
      const validForSave =
        (allowEmpty || validateFields) && (actualValue === confirmValue || hasNoValues);
      return validForSave;
    });
    this.updateState({ fullSectionValid: isFullSectionValid }, this.handleCallback);
  };

  handleCallback = () => {
    const { callback } = this.props;
    const { valueChangedData, valuesForBackend } = this.state;
    const options = { valueChangedData, valuesForBackend };
    callback && callback(this.state, this.sectionId, options);
  };

  render() {
    const { ariaLabel, isSubSection, validateFields, defaultBuildOptions, componentLabelInside } =
      this.props;
    const inputProps = {
      ...(componentLabelInside && { boxStyle: 'inside' }),
      validationActivated: validateFields,
      wrapperStyle: { ...(componentLabelInside && { flex: '50%' }), minWidth: '150px' },
      callback: this.handleInputChange,
      ...(!isEmpty(defaultBuildOptions) && { disabled: defaultBuildOptions.disableFormFields })
    };
    return (
      <FormCustomSection
        ariaLabel={ariaLabel}
        id={this.sectionId}
        isSubSection={isSubSection}
        wrapperStyle={{ marginTop: '0' }}>
        {this.actualFieldKeys.map((compKey) => {
          const actualCompProps = this.actualAndConfirmFields[compKey] || {};
          const confirmCompProps = this.actualAndConfirmFields[`${compKey}Confirm`] || {};
          const {
            [compKey]: actualValue,
            [`${compKey}IsValid`]: actualIsValid,
            [`${compKey}Confirm`]: confirmValue
          } = this.state;
          const showConfirmError = actualIsValid && confirmValue === undefined;
          return (
            <div id={`${compKey}Wrapper`} key={compKey} style={{ display: 'flex', width: '100%' }}>
              <Input
                {...inputProps}
                {...(!isEmpty(actualValue) && { value: actualValue })}
                {...actualCompProps}
              />
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '50%'
                }}>
                <Input
                  {...inputProps}
                  {...confirmCompProps}
                  wrapperStyle={{ ...inputProps.wrapperStyle, flex: '100%' }}
                  {...(!isEmpty(confirmValue) && { value: confirmValue })}
                  {...(showConfirmError && {
                    inputStyle: {
                      color: 'var(--color-warning)',
                      backgroundColor: 'var(--color-warning-bg)'
                    }
                  })}
                  disablePaste
                />
                <ErrorBox
                  id={`${compKey}-error`}
                  $error={showConfirmError}
                  $boxStyle={componentLabelInside ? 'inside' : null}
                  style={
                    showConfirmError
                      ? { position: 'absolute', bottom: '0', flex: '100%' }
                      : { height: '0' }
                  }>
                  {`Please re-enter ${actualCompProps.label}`}
                </ErrorBox>
              </div>
            </div>
          );
        })}
      </FormCustomSection>
    );
  }
}

FormCustomSectionConfirmFields.propTypes = {
  allowEmpty: PropTypes.bool,
  validateFields: PropTypes.bool,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  componentLabelInside: PropTypes.bool,
  fields: PropTypes.oneOfType([PropTypes.object]),
  isSubSection: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]),
  defaultBuildOptions: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func
};

FormCustomSectionConfirmFields.defaultProps = {
  validateFields: false,
  allowEmpty: false,
  id: '',
  ariaLabel: null,
  componentLabelInside: false,
  fields: {},
  isSubSection: true,
  data: {},
  defaultBuildOptions: {},
  callback: null
};

export default FormCustomSectionConfirmFields;
