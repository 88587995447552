export const mockChargebackHistory = {
  chargebackHistory: {
    chargebackVolume: {
      dataType: 'dollar',
      yesterday: 11000,
      lastSevenDays: 77000,
      monthToDate: 81000,
      previousMonth: 121000,
      twoMonthsAgo: 143000,
      threeMonthsAgo: 211000,
      fourMonthsAgo: 97000,
      fiveMonthsAgo: 168000,
      sixMonthsAgo: 66000
    },
    chargebackCount: {
      dataType: 'number',
      yesterday: 4000,
      lastSevenDays: 10000,
      monthToDate: 9000,
      previousMonth: 11000,
      twoMonthsAgo: 12000,
      threeMonthsAgo: 18000,
      fourMonthsAgo: 6500,
      fiveMonthsAgo: 16000,
      sixMonthsAgo: 8000
    },
    chargebackCountRatio: {
      dataType: 'ratio',
      yesterday: 0.1,
      lastSevenDays: 0.1,
      monthToDate: 0.1,
      previousMonth: 0.1,
      twoMonthsAgo: 0.1,
      threeMonthsAgo: 0.1,
      fourMonthsAgo: 0.1,
      fiveMonthsAgo: 0.1,
      sixMonthsAgo: 0.1
    },
    chargebackVolumeRatio: {
      dataType: 'ratio',
      yesterday: 0.1,
      lastSevenDays: 0.1,
      monthToDate: 0.1,
      previousMonth: 0.1,
      twoMonthsAgo: 0.1,
      threeMonthsAgo: 0.1,
      fourMonthsAgo: 0.1,
      fiveMonthsAgo: 0.1,
      sixMonthsAgo: 0.1
    },
    chargebackCountByCardBrand: {
      visa: {
        dataType: 'number',
        yesterday: 4000,
        lastSevenDays: 10000,
        monthToDate: 9000,
        previousMonth: 11000,
        twoMonthsAgo: 12000,
        threeMonthsAgo: 18000,
        fourMonthsAgo: 6500,
        fiveMonthsAgo: 16000,
        sixMonthsAgo: 8000
      },
      mastercard: {
        dataType: 'number',
        yesterday: 4000,
        lastSevenDays: 10000,
        monthToDate: 9000,
        previousMonth: 11000,
        twoMonthsAgo: 12000,
        threeMonthsAgo: 18000,
        fourMonthsAgo: 6500,
        fiveMonthsAgo: 16000,
        sixMonthsAgo: 8000
      },
      discover: {
        dataType: 'number',
        yesterday: 4000,
        lastSevenDays: 10000,
        monthToDate: 9000,
        previousMonth: 11000,
        twoMonthsAgo: 12000,
        threeMonthsAgo: 18000,
        fourMonthsAgo: 6500,
        fiveMonthsAgo: 16000,
        sixMonthsAgo: 8000
      },
      amex: {
        dataType: 'number',
        yesterday: 4000,
        lastSevenDays: 10000,
        monthToDate: 9000,
        previousMonth: 11000,
        twoMonthsAgo: 12000,
        threeMonthsAgo: 18000,
        fourMonthsAgo: 6500,
        fiveMonthsAgo: 16000,
        sixMonthsAgo: 8000
      }
    },
    chargebackVolumeByCardBrand: {
      visa: {
        dataType: 'dollar',
        yesterday: 11000,
        lastSevenDays: 77000,
        monthToDate: 81000,
        previousMonth: 121000,
        twoMonthsAgo: 143000,
        threeMonthsAgo: 211000,
        fourMonthsAgo: 97000,
        fiveMonthsAgo: 168000,
        sixMonthsAgo: 66000
      },
      mastercard: {
        dataType: 'dollar',
        yesterday: 11000,
        lastSevenDays: 77000,
        monthToDate: 81000,
        previousMonth: 121000,
        twoMonthsAgo: 143000,
        threeMonthsAgo: 211000,
        fourMonthsAgo: 97000,
        fiveMonthsAgo: 168000,
        sixMonthsAgo: 66000
      },
      discover: {
        dataType: 'dollar',
        yesterday: 11000,
        lastSevenDays: 77000,
        monthToDate: 81000,
        previousMonth: 121000,
        twoMonthsAgo: 143000,
        threeMonthsAgo: 211000,
        fourMonthsAgo: 97000,
        fiveMonthsAgo: 168000,
        sixMonthsAgo: 66000
      },
      amex: {
        dataType: 'dollar',
        yesterday: 11000,
        lastSevenDays: 77000,
        monthToDate: 81000,
        previousMonth: 121000,
        twoMonthsAgo: 143000,
        threeMonthsAgo: 211000,
        fourMonthsAgo: 97000,
        fiveMonthsAgo: 168000,
        sixMonthsAgo: 66000
      }
    },
    chargebackRatioByCardBrand: {
      visa: {
        dataType: 'ratio',
        yesterday: 0.1,
        lastSevenDays: 0.1,
        monthToDate: 0.1,
        previousMonth: 0.1,
        twoMonthsAgo: 0.1,
        threeMonthsAgo: 0.1,
        fourMonthsAgo: 0.1,
        fiveMonthsAgo: 0.1,
        sixMonthsAgo: 0.1
      },
      mastercard: {
        dataType: 'ratio',
        yesterday: 0.1,
        lastSevenDays: 0.1,
        monthToDate: 0.1,
        previousMonth: 0.1,
        twoMonthsAgo: 0.1,
        threeMonthsAgo: 0.1,
        fourMonthsAgo: 0.1,
        fiveMonthsAgo: 0.1,
        sixMonthsAgo: 0.1
      },
      discover: {
        dataType: 'ratio',
        yesterday: 0.1,
        lastSevenDays: 0.1,
        monthToDate: 0.1,
        previousMonth: 0.1,
        twoMonthsAgo: 0.1,
        threeMonthsAgo: 0.1,
        fourMonthsAgo: 0.1,
        fiveMonthsAgo: 0.1,
        sixMonthsAgo: 0.1
      },
      amex: {
        dataType: 'ratio',
        yesterday: 0.1,
        lastSevenDays: 0.1,
        monthToDate: 0.1,
        previousMonth: 0.1,
        twoMonthsAgo: 0.1,
        threeMonthsAgo: 0.1,
        fourMonthsAgo: 0.1,
        fiveMonthsAgo: 0.1,
        sixMonthsAgo: 0.1
      }
    },
    topMtdReasonDescription: [
      { reasonCode: 'code01', count: '500', volume: '10000', ratioOfTotal: 0.8 },
      { reasonCode: 'code02', count: '400', volume: '10000', ratioOfTotal: 0.8 },
      { reasonCode: 'code03', count: '300', volume: '10000', ratioOfTotal: 0.8 },
      { reasonCode: 'code04', count: '200', volume: '10000', ratioOfTotal: 0.8 },
      { reasonCode: 'code05', count: '100', volume: '10000', ratioOfTotal: 0.8 }
    ],
    remainingReasonCodes: {
      reasonCode: 'code06|code07',
      count: '99',
      volume: '9000',
      ratioOfTotal: 0.6
    }
  }
};

export const mockChargebackHistoryGetRes = (body, params, options) => {
  const { guid = ['empty'] } = options;
  return {
    [guid]: mockChargebackHistory
  };
};

export default mockChargebackHistory;
