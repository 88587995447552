// TODO: BIRB-8338
/* istanbul ignore file */
import * as d3 from 'd3';
import React from 'react';
import { PropTypes } from 'prop-types';
import { isEqual, isEmpty, formatNumber } from '../_helpers';
import { ToolTip } from '../../index';
import { Spinner } from '../Spinner';

class D3Chart extends React.Component {
  constructor(props) {
    super(props);
    const { chartData, preserveAspect } = this.props;
    const { header } = chartData || {};
    const { height } = header || {};
    this.mounted = false;
    this.defaultMargins = {
      top: 20,
      right: 30,
      bottom: 20,
      left: 0 // Gets calculated dynamically in this.adjustMargins
    };
    this.state = {
      spinnerLoading: false,
      initWidth: preserveAspect ? 340 : window.innerWidth || document.documentElement.clientWidth,
      initHeight: height,
      baseFontSize: 11,
      margin: {
        ...this.defaultMargins
      },
      hovered: {
        type: '',
        data: null
      },
      tooltipStyle: {
        x: 0,
        y: 0
      },
      hideToolTip: false
    };
    this.element = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.renderOnReady();
  }

  componentDidUpdate(prevProps, prevState) {
    const { chartData } = this.props;
    const { margin } = this.state;
    const dataChanged =
      !isEmpty(prevProps.chartData) &&
      !isEmpty(chartData) &&
      JSON.stringify(chartData) !== JSON.stringify(prevProps.chartData);
    const heightChanged =
      (typeof prevState.initHeight === 'undefined' && chartData?.header?.height) ||
      (prevProps?.chartData?.header?.height &&
        !isEqual(chartData?.header?.height, prevProps?.chartData?.header?.height));
    if (heightChanged || dataChanged || !isEqual(prevState.margin, margin)) {
      this.updateState(
        {
          spinnerLoading: true,
          ...(heightChanged && { initHeight: chartData?.header?.height || 200 })
        },
        () => this.initialize({ marginsSet: !isEqual(prevState.margin, margin), dataChanged })
      );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  renderOnReady = () => {
    const target = this.element.current;
    if (target) {
      const observer = new IntersectionObserver(this.intersectionCallback);
      observer.observe(target);
    }
  };

  intersectionCallback = (entries) => {
    if (entries[0].isIntersecting) {
      this.updateState({ spinnerLoading: true }, this.initialize);
    }
  };

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  initialize = (options) => {
    const { current: currentElement } = this.element;
    const rect = !isEmpty(currentElement) ? currentElement.getBoundingClientRect() : null;
    this.updateState(
      {
        ...(!isEmpty(rect) && { initWidth: rect.width })
      },
      () => this.createD3Chart(options)
    );
  };

  handleResize = () => {
    const { preserveAspect } = this.props;
    const { current: currentElement } = this.element;
    this.updateState({ hideToolTip: true });
    if (currentElement) {
      const { initHeight, initWidth } = this.state;
      const aspect = initWidth / initHeight;
      const svg = d3.select(currentElement);
      if (svg) {
        const targetWidth = parseInt(currentElement.offsetWidth || 0, 10);
        if (preserveAspect) {
          svg.attr('width', targetWidth);
          svg.attr('height', Math.round(targetWidth / aspect));
        } else {
          this.updateState(
            {
              initWidth: targetWidth
            },
            this.initialize
          );
        }
      }
    }
    this.updateState({ hideToolTip: false });
    this.adjustTicks();
  };

  setFontSize = () => {
    // method to keep font size the same as it scales
    const { baseFontSize, initWidth, updateGraph } = this.state;
    const { current: currentElement } = this.element;
    const newFontSize = baseFontSize * (initWidth / currentElement?.offsetWidth || 0);
    currentElement &&
      d3.select(currentElement).selectAll('.tick text').style('font-size', `${newFontSize}px`);
    typeof updateGraph === 'function' && updateGraph(); // refresh number of ticks to show on axis
  };

  getDataKeys = (data) => {
    const { chartData = {} } = this.props;
    const { header = {} } = chartData;
    const { label, lines = [] } = header || {};
    if (isEmpty(data)) return [];
    return Object.keys(data[0]).filter((key) => key !== label && !lines.includes(key));
  };

  handleClick = (e, d) => {
    const { callback } = this.props;
    callback && callback(d.data || d);
  };

  handleMouseMoveBar = (e) => {
    const { tooltipStyle } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        x: e.clientX,
        y: e.clientY
      }
    });
  };

  handleMouseOverBar = (e, d) => {
    const { chartData } = this.props;
    const { header = {} } = chartData;
    const { label, stacked = false } = header || {};
    const { tooltipStyle } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        revealed: true
      },
      hovered: {
        type: 'bar',
        data: {
          ...(stacked ? d.data : d),
          header: label
        }
      }
    });
  };

  handleMouseMoveCircle = (e) => {
    const { tooltipStyle } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        revealed: true,
        x: e.clientX,
        y: e.clientY
      }
    });
  };

  handleMouseOverCircle = (e, d) => {
    const { tooltipStyle } = this.state;
    this.updateState({
      tooltipStyle: {
        ...tooltipStyle,
        revealed: true
      },
      hovered: {
        type: 'circle',
        data: {
          ...d,
          header: 'key'
        }
      }
    });
  };

  handleMouseOut = () => {
    const { tooltipStyle } = this.state;
    this.mounted &&
      this.updateState({
        tooltipStyle: {
          ...tooltipStyle,
          revealed: false
        },
        hovered: {
          type: '',
          data: null
        }
      });
  };

  getKey = (d) => {
    const { chartData = {} } = this.props;
    const { header = {} } = chartData;
    const { lines = [], label } = header || {};
    return Object.keys(d).find((key) => key !== label && !lines.includes(key));
  };

  getChartValue = (value) => {
    // Prevents non-numbers from begin passed into graphBars / line
    const newValue = isEmpty(value) || (Number(`${value}`) === 'NaN' ? 0 : value); // use `${value}` here to handle null
    return newValue;
  };

  createD3Chart = (opts) => {
    const { current: currentElement } = this.element;
    const { initWidth, initHeight, margin } = this.state;
    const { chartData = {} } = this.props;
    const { data = [], colors = {}, header = {} } = chartData;
    const { stacked = false, lines = [], biaxial = false, negatives = false, label } = header || {};
    if (currentElement !== null) {
      // get width of container and resize svg to fit it
      const svg = d3
        .select(currentElement)
        .html(null)
        .append('svg')
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', `0 0 ${initWidth} ${initHeight}`);

      // get container + svg aspect ratio
      const dataKeys = this.getDataKeys(data);
      const width = initWidth - margin.left - margin.right;
      const height = initHeight;

      // Create the x Axis for bottom scale
      this.xScale = d3
        .scaleBand()
        .domain(data.map((d) => d[label]))
        .rangeRound([margin.left, width])
        .padding(0.3);
      this.xAxisGenerator = d3.axisBottom(this.xScale).tickSizeOuter(0);

      // calculate y-axis domains so the 0 line is centered
      let min = 0;
      let max = 0;
      const totalsByMonths = {};
      dataKeys.forEach((key) => {
        data.forEach((row) => {
          if (!isEmpty(row[key]) && row[key] !== 0) {
            max = Math.max(max, row[key]);
            min = Math.min(min, row[key]);
            if (isEmpty(totalsByMonths[row[label]])) {
              totalsByMonths[row[label]] = { min: 0, max: 0 };
            }
            totalsByMonths[row[label]] = {
              ...totalsByMonths[row[label]],
              ...(row[key] < 0
                ? { min: totalsByMonths[row[label]].min - row[key] }
                : { max: totalsByMonths[row[label]].max + row[key] })
            };
          }
        });
      });

      let yLeftScaleStacked;
      let series;
      if (stacked) {
        series = d3
          .stack()
          .order(d3.stackOrderDescending)
          .keys(dataKeys)
          .offset(d3.stackOffsetDiverging)(data);
        const seriesMax = d3.max(series, (d1) => d3.max(d1, (d) => Math.max(d[0], d[1])));
        const localMin = d3.min(series, (d1) => d3.min(d1, (d) => Math.min(d[0], d[1])));
        const seriesMin = localMin < 0 ? localMin : 0;
        yLeftScaleStacked = d3
          .scaleLinear()
          // multiply by 1.05 on domain, just to ensure the bars don't fall outside of graph lines
          .domain([negatives ? seriesMin * 1.05 : 0, seriesMax * 1.05])
          .rangeRound([height - margin.bottom, margin.top]);
      }

      // Create the left side Y Axis
      const yAxisMax = stacked
        ? d3.max(series, (d1) => d3.max(d1, (d) => Math.max(d[0], d[1])))
        : Math.max(Math.abs(min), Math.abs(max));
      const localMin = stacked
        ? d3.min(series, (d1) => d3.min(d1, (d) => Math.min(d[0], d[1])))
        : Math.min(min, max);
      const yAxisMin = localMin < 0 ? localMin : 0;
      this.yLeftScale = d3
        .scaleLinear()
        // multiply by 1.05 on domain, just to ensure the bars don't fall outside of graph lines
        .domain([negatives ? yAxisMin * 1.05 : 0, yAxisMax * 1.05])
        .rangeRound([height - margin.bottom, margin.top]);
      this.yAxisLeftGenerator = d3
        .axisLeft(this.yLeftScale)
        .ticks(currentElement.offsetHeight / 40)
        .tickSize(-width + margin.left)
        .tickSizeOuter(0);
      this.yAxisLeft = svg.append('g').attr('class', 'yAxisLeft').call(this.yAxisLeftGenerator);

      // ADD the x Axis for bottom scale
      this.xAxis = svg
        .append('g')
        .attr('class', 'xAxis')
        .call(this.xAxisGenerator.tickSize(-height + margin.top + margin.bottom).tickSizeOuter(0));
      // Customize the x Axis for bottom scale
      this.xAxis
        .style('color', '#6e6e6e')
        .attr('transform', `translate(${0},${height - 20})`)
        // style the grid line (made by extending tick width)
        .selectAll('.tick line')
        .attr('opacity', 0.15)
        .style('stroke-dasharray', '4 4');
      this.xAxis.selectAll('.tick text').attr('transform', `translate(0,5)`);

      if (negatives) {
        // Add center line for 0
        const centerLine = svg.append('line').attr('class', 'centerLine');

        centerLine
          .style('stroke', '#6e6e6e')
          .attr('x1', margin.left)
          .attr('y1', 0)
          .attr('x2', width)
          .attr('y2', 0);
        centerLine.attr('transform', `translate(0,${this.yLeftScale(0) || 0})`);
      }

      // Add the left Y Axis
      this.yAxisLeft.style('color', '#6e6e6e').attr('transform', `translate(${margin.left},0)`);

      // add graph bars
      const graphBars = svg
        .append('g')
        .attr('class', 'graphBars')
        .selectAll('g')
        .data(stacked ? series : data);

      if (stacked) {
        graphBars
          .enter()
          .append('g')
          .attr('class', 'stackedBars')
          .attr('fill', (d) => (stacked ? colors[d.key] : colors[this.getKey(d)]))
          .selectAll('rect')
          .data(Object)
          .enter()
          .append('rect');
      } else {
        graphBars
          .enter()
          .append('rect')
          .attr('fill', (d) => colors[this.getKey(d)]);
      }

      graphBars
        .enter()
        .selectAll('rect')
        .style('z-index', '2')
        .attr('width', this.xScale.bandwidth())
        .attr('x', (d) => this.xScale(stacked ? d.data[label] : d[label]))
        .attr('y', (d) => (stacked ? yLeftScaleStacked(d[1]) : this.yLeftScale(d[this.getKey(d)])))
        .attr('height', (d) => {
          const dMin = stacked ? yLeftScaleStacked(d[0]) : this.yLeftScale(0);
          const dMax = stacked ? yLeftScaleStacked(d[1]) : this.yLeftScale(d[this.getKey(d)]);
          const dHeight = dMin - dMax;
          return this.getChartValue(dHeight);
        })
        .on('click', this.handleClick)
        .on('mouseover', this.handleMouseOverBar)
        .on('mousemove', this.handleMouseMoveBar)
        .on('mouseout', this.handleMouseOut);

      // currently biaxial right side axis data is always for lines.
      if (biaxial && !isEmpty(lines)) {
        // format data by lines
        const dataByLines = lines.reduce((lineObject, lineName) => {
          const lineData = data.reduce((allData, item) => {
            const val = item[lineName];
            const lineValue = this.getChartValue(val);
            const newItem = {
              name: lineName,
              key: item[label],
              lineValue
            };
            return allData.concat(newItem);
          }, []);
          return {
            ...lineObject,
            [lineName]: lineData
          };
        }, {});

        const allLineData = Object.values(dataByLines).reduce((acc, arr) => acc.concat(arr));
        const y2AxisMax = d3.max(allLineData, (d) => Math.max(d.lineValue));
        const localRightMin = d3.min(allLineData, (d) => Math.min(d.lineValue));
        const y2AxisMin = localRightMin < 0 ? localRightMin : 0;
        let lineGenerator;
        if (!isEmpty(totalsByMonths)) {
          // has bar and line data
          // Create the Right side Y Axis
          this.yRightScale = d3
            .scaleLinear()
            .domain([y2AxisMin, y2AxisMax])
            .rangeRound([height - margin.bottom, margin.top]);
          this.yAxisRightGenerator = d3
            .axisRight(this.yRightScale)
            .ticks(currentElement.offsetHeight / 80)
            .tickSizeOuter(0);
          this.yAxisRight = svg
            .append('g')
            .attr('class', 'yAxisRight')
            .call(this.yAxisRightGenerator);

          lineGenerator = d3
            .line()
            .x((d) => this.xScale(d.key) + this.xScale.bandwidth() / 2)
            .y((d) => this.yRightScale(d.lineValue))
            .curve(d3.curveMonotoneX); // apply smoothing to the line
        } else if (isEmpty(totalsByMonths)) {
          // only line data
          // Create the Left side Y Axis
          this.yLeftScale = d3
            .scaleLinear()
            .domain([y2AxisMin, y2AxisMax])
            .rangeRound([height - margin.bottom, margin.top]);
          this.yAxisLeftGenerator = d3
            .axisLeft(this.yLeftScale)
            .ticks(currentElement.offsetHeight / 80)
            .tickSizeOuter(0);
          this.yAxisLeft = svg.append('g').attr('class', 'yAxisLeft').call(this.yAxisLeftGenerator);

          lineGenerator = d3
            .line()
            .x((d) => this.xScale(d.key) + this.xScale.bandwidth() / 2)
            .y((d) => this.yLeftScale(d.lineValue))
            .curve(d3.curveMonotoneX); // apply smoothing to the line
        }

        const findLineColor = (array) => {
          let color = 'var(--color-d3-chart-line)';
          if (!isEmpty(array)) {
            color = colors[array[0].name] || color;
          }
          return color;
        };

        // Add line paths
        !isEmpty(dataByLines) &&
          Object.values(dataByLines).forEach((lineDataSet) => {
            svg
              .append('path')
              .data([lineDataSet])
              .attr('class', 'line')
              .style('stroke', (d) => findLineColor(d))
              .style('stroke-width', '1px')
              .style('fill', 'none')
              .attr('d', lineGenerator);

            svg
              .selectAll('.circle')
              .data(lineDataSet)
              .join('circle')
              .style('stroke', (d) => colors[d.name])
              .style('fill', (d) =>
                colors[d.name] ? 'var(--color-bg)' : 'var(--color-d3-chart-line)'
              )
              .attr('r', 3)
              .attr('cx', (d) => this.xScale(d.key) + this.xScale.bandwidth() / 2)
              .attr('cy', (d) =>
                !isEmpty(totalsByMonths)
                  ? this.yRightScale(d.lineValue)
                  : this.yLeftScale(d.lineValue)
              )
              .on('mouseover', this.handleMouseOverCircle)
              .on('mousemove', this.handleMouseMoveCircle)
              .on('mouseout', this.handleMouseOut);
          });

        if (!isEmpty(totalsByMonths)) {
          // has bar and line data
          // Add the right Y Axis
          this.yAxisRight
            .style('color', () => findLineColor(Object.values(dataByLines)?.[0]))
            .attr('transform', `translate( ${width}, 0 )`);
        } else {
          // has only line data
          // Add the left Y Axis
          this.yAxisLeft
            .style('color', '#6e6e6e')
            .attr('transform', `translate(${margin.left}, 0)`);
        }
      }

      const getDivisor = (elem, options) => {
        const { padding = 4 } = options || {};
        const axis = this[elem];
        const selector = currentElement && currentElement.querySelector(`.${elem}`);
        const rect = selector && selector.getBoundingClientRect();
        if (!rect) {
          return 1;
        }
        const type = elem === 'xAxis' ? 'width' : 'height';
        const outerBound = rect[type];
        const tickLabels = axis.selectAll('.tick').selectAll('text');
        const { length } = tickLabels.nodes();
        const labelBounds = Math.max(
          ...tickLabels.nodes().map((o) => o.getBoundingClientRect()[type]),
          0
        );
        const totalThatWillFit = outerBound / (labelBounds + padding);
        const divisor = Math.ceil(length / totalThatWillFit);
        if (elem !== 'xAxis') {
          const result = Math.ceil(outerBound / (elem === 'yAxisRight' ? 40 : 30));
          return result;
        }
        return divisor < 1 ? 1 : divisor;
      };

      const updateGraph = () => {
        // on update/resize, we need to update the ticks to ensure they don't overlap
        this.xAxis.call(
          this.xAxisGenerator.tickFormat((d, i) => (i % getDivisor('xAxis') === 0 ? d : ''))
        );
        this.yAxisLeft
          .call(
            this.yAxisLeftGenerator
              .tickValues(this.yLeftScale.ticks(getDivisor('yAxisLeft')))
              .tickFormat(this.formatTicks)
          )
          .selectAll('.tick line')
          .attr('opacity', 0.15)
          .style('stroke-dasharray', '4 4');
        if (this.yAxisRight) {
          this.yAxisRight.call(
            this.yAxisRightGenerator
              .tickValues(this.yRightScale.ticks(getDivisor('yAxisRight')))
              .tickFormat(this.formatTicksRight)
          );
        }
      };

      this.updateState({
        updateGraph
      });

      // Settings for resizing as needed
      d3.select(window).on(`resize.${currentElement}`, this.handleResize);
      this.adjustTicks();
    }
    this.updateState({ spinnerLoading: false }, () => this.adjustMargins(opts));
  };

  formatTicks = (d) => {
    const { chartData } = this.props;
    const { header = {} } = chartData;
    const { currency = false } = header || {};
    return formatNumber(d, { currency, abbreviate: true });
  };

  formatTicksRight = (d) => formatNumber(d, { abbreviate: true });

  adjustMargins = (options) => {
    const { marginsSet, dataChanged } = options || {};
    const { chartData } = this.props;
    const { header = {} } = chartData || {};
    const { negatives = false } = header || {};
    const { current: currentElement } = this.element;
    if (currentElement && (!marginsSet || (marginsSet && dataChanged))) {
      const yAxisLeftTextElems = currentElement.querySelectorAll('.yAxisLeft text');
      // Get the widest width of all the left y-axis labels
      const widestTextWidth = !isEmpty(yAxisLeftTextElems)
        ? Array.from(yAxisLeftTextElems).reduce((acc, textElem) => {
            const textElemRect = textElem.getBoundingClientRect();
            const { width: textElemWidth = 0 } = textElemRect || {};
            return textElemWidth > acc ? textElemWidth : acc;
          }, 0)
        : 0;
      const yAxisRightElem = currentElement.querySelector('.yAxisRight');
      const yAxisRightRect = !isEmpty(yAxisRightElem)
        ? yAxisRightElem.getBoundingClientRect()
        : null;
      const yAxisRightWidth = !isEmpty(yAxisRightRect) ? yAxisRightRect.width : null;
      const offsetRight = !isEmpty(yAxisRightWidth) ? Math.max(yAxisRightWidth, 25) : 0;
      this.updateState((prevState) => {
        // When data changes for the same chart, need to reset the margins
        const startingLeftMargin = dataChanged ? this.defaultMargins.left : prevState.margin.left;
        const minLeftMargin = 40 + (negatives ? 5 : 0); // So labels don't fall outside the chart
        return {
          margin: {
            ...prevState.margin,
            left: startingLeftMargin,
            // Set new left y-axis margin so labels don't fall outside of chart
            ...(!isEmpty(widestTextWidth) &&
              widestTextWidth > startingLeftMargin && {
                left: Math.max(Math.round(minLeftMargin * 1.05), Math.round(widestTextWidth * 1.05))
              }),
            ...(!isEmpty(widestTextWidth) &&
              widestTextWidth <= minLeftMargin &&
              startingLeftMargin === this.defaultMargins.left && {
                left: Math.max(
                  Math.round(minLeftMargin * 1.05),
                  Math.round((startingLeftMargin + widestTextWidth + offsetRight) * 1.05)
                )
              })
          }
        };
      }, this.adjustTicks);
    }
  };

  adjustTicks = () => {
    const { updateGraph } = this.state;
    // call d3 method to update the graph
    typeof updateGraph === 'function' && updateGraph(); // need function check for when graph changes between renders
    // adjust font size
    this.setFontSize();
  };

  render() {
    const { chartData, legendPosition, preserveAspect } = this.props;
    const { tooltipStyle, hovered, spinnerLoading, hideToolTip } = this.state;
    const { header } = chartData || {};
    const { height } = header || {};
    return (
      <div
        data-testid="d3-chart"
        style={{
          ...(legendPosition !== 'right' && { width: '100%' }),
          ...(!preserveAspect && { height: `${height}px` }),
          ...(spinnerLoading && { minHeight: `${height || 320}px` }),
          ...(legendPosition === 'right' && {
            backgroundColor: 'var(--color-bg)',
            flex: '3',
            margin: '2px 0',
            borderRadius: 'var(--radius-main)',
            minWidth: '320px'
          })
        }}
        className="d3wrapper"
        ref={this.element}
        aria-hidden="true">
        <Spinner loading={spinnerLoading} />
        {!isEmpty(hovered.data) && !hideToolTip && (
          <ToolTip
            d3Data={hovered}
            d3Position={tooltipStyle}
            element={this.element.current}
            options={{ ...chartData.header, colorMap: chartData.colors }}
          />
        )}
      </div>
    );
  }
}

D3Chart.propTypes = {
  chartData: PropTypes.oneOfType([PropTypes.object]),
  legendPosition: PropTypes.string,
  preserveAspect: PropTypes.bool,
  callback: PropTypes.func
};

D3Chart.defaultProps = {
  chartData: {},
  legendPosition: 'bottom',
  preserveAspect: true,
  callback: () => {}
};

export default D3Chart;
