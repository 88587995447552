import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { modalCSS } from './_styles';
import { isEmpty, isEqual, isSidebarOpen, sharedGetInnerAlertBarState } from './_helpers';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import { AlertBar } from './AlertBar';

export const ModalV2 = ({
  axiosRequest = () => {},
  children = null,
  closeModalCallback = () => {},
  modalTitle = null,
  options = {},
  resetModalData = {},
  showModal = false
}) => {
  const defaultModalData = {
    modalTitle: null,
    modalContent: null,
    options: {},
    timestamp: 0
  };
  const defaultAlertBarData = {
    alertBarType: 'closed',
    alertBarMessage: '',
    alertBarTimeout: true
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenTimestamp, setModalOpenTimestamp] = useState(0);
  const [modalData, setModalData] = useState(defaultModalData);
  const [alertBarData, setAlertBarData] = useState(defaultAlertBarData);

  useEffect(() => {
    // on mount
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      // on unmount
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!isEqual(showModal, modalOpen)) {
      setModalOpenTimestamp(Date.now());
    }
  }, [showModal]);

  useEffect(() => {
    if (!isEmpty(modalOpenTimestamp) && modalOpenTimestamp > 0) {
      const modalVisible = showModal && !isEqual(modalTitle, modalData.modalTitle);
      const modalContentWithProps = modalVisible
        ? React.Children.map(children, (child) =>
            React.isValidElement(child) &&
            (typeof child.type === 'object' || typeof child.type === 'function')
              ? React.cloneElement(child, { updateModalAlert })
              : child
          )
        : null;
      setModalData((prevModalData) => ({
        ...prevModalData,
        modalTitle: modalVisible ? modalTitle : null,
        modalContent: modalContentWithProps,
        timestamp: Date.now(),
        options
      }));
    }
  }, [modalOpenTimestamp, modalTitle]);

  useEffect(() => {
    if (!isEmpty(modalData.timestamp) && modalData.timestamp > 0) {
      document.querySelector('body').style.overflow = showModal ? 'hidden' : 'auto';
      setModalOpen(showModal);
    }
  }, [modalData.timestamp]);

  const updateModalAlert = (data) => {
    const { preText, alertBarType, alertBarMessage, alertBarTimeout } = data || {};
    if (alertBarType === 'warning') {
      const { alertBarMessage: errorMessage } = sharedGetInnerAlertBarState({
        type: 'warning',
        data: alertBarMessage,
        axiosRequest
      });
      const finalPreText = !isEmpty(preText) ? `${preText}: ` : '';
      setAlertBarData((prevAlertBarData) => ({
        ...prevAlertBarData,
        alertBarType: 'warning',
        alertBarMessage: `${finalPreText}${errorMessage}`
      }));
    } else if (
      alertBarType === 'success' ||
      alertBarType === 'closed' ||
      alertBarType === 'notice'
    ) {
      setAlertBarData((prevAlertBarData) => ({
        ...prevAlertBarData,
        ...(alertBarType === 'success' && {
          alertBarTimeout: alertBarTimeout !== undefined ? alertBarTimeout : true
        }),
        alertBarType,
        alertBarMessage
      }));
    }
  };

  const handleOutsideClick = (e) => {
    const { closeOnOutsideClick = true } = options || {};
    e && e.target.getAttribute('id') === 'modal' && closeOnOutsideClick && handleClose(e);
  };

  const handleKeyUp = (event) => event && event.keyCode === 27 && handleClose();

  const handleClose = () => {
    document.querySelector('body').style.overflow = 'auto';
    setModalOpen(false);
    setModalOpenTimestamp(0);
    setAlertBarData(defaultAlertBarData);
    setModalData(defaultModalData);
    const cbData = {
      modalAction: '',
      modalTitle: null,
      showModal: false,
      ...resetModalData
    };
    closeModalCallback(cbData);
  };

  const classNames = [
    'siteModal',
    ...(isSidebarOpen() ? ['inSidebar'] : []),
    ...(modalOpen ? ['active'] : []),
    ...(!isEmpty(modalData.options) && modalData.options.size ? [modalData.options.size] : [])
  ];
  return (
    <>
      {modalOpen ? (
        <div
          id="modal"
          className={!isEmpty(classNames) ? classNames.join(' ') : null}
          style={{ ...modalCSS.outerWrap, ...(modalOpen && modalCSS.active) }}
          role="menuitem"
          aria-label="Modal"
          tabIndex="0"
          onMouseDown={handleOutsideClick}
          onKeyDown={handleOutsideClick}>
          <div
            className="innerWrap"
            style={{ ...modalCSS.innerWrap, ...(modalOpen && modalCSS.active) }}>
            <div className="modalHeader" style={modalCSS.header}>
              <Icon
                icon={icons.close.src_color}
                $useMask
                role="menuitem"
                tabIndex="0"
                className="modalClose"
                aria-label="Close Modal"
                style={modalCSS.close}
                onMouseDown={handleClose}
                onKeyDown={handleClose}
              />
              <span role="heading" aria-level="2">
                {modalTitle}
              </span>
            </div>
            <div className="modalContent" style={{ ...modalCSS.content, position: 'relative' }}>
              {alertBarData.alertBarType !== 'closed' && (
                <AlertBar
                  options={{
                    type: 'modal',
                    barStyle: alertBarData.alertBarType,
                    message: alertBarData.alertBarMessage,
                    timeout: alertBarData.alertBarTimeout
                  }}
                  wrapperStyle={{ width: 'calc(100% - 0.2em)', left: '0.2em', top: '0.2em' }}
                  callback={updateModalAlert}
                  useInnerAlertBar
                />
              )}
              {modalData.modalContent}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ModalV2.propTypes = {
  axiosRequest: PropTypes.func.isRequired, // For alert bar handling
  children: PropTypes.node,
  closeModalCallback: PropTypes.func.isRequired, // Close modal in parent's internal state
  modalTitle: PropTypes.string,
  options: PropTypes.shape({
    closeOnOutsideClick: PropTypes.bool,
    size: PropTypes.string
  }),
  // resetModalData - any additional modal state that needs to be cleared when closing
  resetModalData: PropTypes.oneOfType([PropTypes.object]), // Optional
  showModal: PropTypes.bool
};

export default ModalV2;
