import swal from 'sweetalert';
import {
  getLatestClickData,
  getNow,
  getTime,
  getType,
  handleCloseSwal,
  isEmpty,
  isPublicUrl,
  isSidebarOpen,
  msToMin,
  setClickTrail,
  getMockDbData,
  useStubData
} from './_helpers';
import { addToLocalDB, getFromLocalDB } from './_dataCompression';

export const sharedIsTokenValid = (store) => {
  // use this to check if user is authenticated & cookie still valid before attempting
  // an authenticated axiosRequest call
  const { authenticate } = store.getState();
  const { isAuthenticated = false, user: { accessToken: { exp } = {} } = {} } = authenticate || {};
  const expIsDate = exp instanceof Date;
  const currentTime = getCurrentTime(authenticate.user);
  const currentTimeIsDate = currentTime instanceof Date;
  const isValid =
    expIsDate && currentTimeIsDate
      ? exp >= currentTime
      : getNow(exp, { useRealTime: true }) >= getNow(currentTime, { useRealTime: true });
  return isAuthenticated && isValid;
};

const getCurrentTime = (user) => {
  // Returns date object
  const { accessToken: { exp } = {} } = user || {};
  const currentTime =
    getType(exp) === 'number'
      ? // If `exp` is a number (not a Date instance), convert current time
        // to previous way of checking time so it is in line with the `exp` number value.
        Math.ceil(Date.now() / 1000) // Date in millisconds (OLD)
      : // Else, `exp` is a date object
        getNow(null, { useRealTime: true }); // Date obj (NEW)
  return getNow(currentTime, { useRealTime: true });
};

export const getLatestClickTimestamp = async (options) => {
  // Returns date obj of latest click (if signed-in),
  // OR returns 0 (if signed-out)
  const {
    axiosRequest,
    justSignedIn,
    isAuthenticated,
    user: { accessToken: { lastClickTimestamp } = {} } = {}
  } = options || {};
  if (justSignedIn) {
    // When user JUST signed in, the clickTrail will still log
    // the latest click as sign-in "submit" (user is still signed-out at this point),
    // so we need to use NOW instead.
    const currentTime = getCurrentTime(options.user);
    // clickTrail should include sign-in click at this point
    const clickTrail = await getFromLocalDB('clickTrail', { axiosRequest });
    const signInClick =
      (clickTrail || []).find((item) => item.timestamp === 0 && item.id === 'signinSubmit') || {};
    const newSignInClick = {
      // If the signin click doesn't have a timestamp, use the current time
      ...(!isEmpty(signInClick) && { ...signInClick }),
      notes: 'User just signed in, this includes the timestamp',
      timestamp: currentTime
    };
    // Then add to the click trail. This will show 2 signin click events, but that is ok,
    // as long as the LAST signin click has the timestamp. This is needed so if other
    // tabs are open (and signed-in) AFTER user clicks sign-out, we will properly also
    // sign the user out of the other open tabs.
    !isEmpty(newSignInClick) &&
      (await setClickTrail(
        {},
        {
          newClickItem: newSignInClick,
          getFromLocalDB,
          addToLocalDB,
          isAuthenticated: true
        }
      ));
    return currentTime;
  }
  const isValidDateObj = isAuthenticated
    ? !isEmpty(lastClickTimestamp) &&
      (lastClickTimestamp instanceof Date || lastClickTimestamp !== 0)
    : false;
  return isValidDateObj ? getNow(lastClickTimestamp || null, { useRealTime: true }) : 0;
};

export const getDiffNowVsLastClick = async (options) => {
  // Returns diff in minutes between the current time & last click time
  const { axiosRequest, justSignedIn, isAuthenticated, user } = options || {};
  const currentTime = getCurrentTime(user);
  const currentTimeMs = getTime(currentTime);
  const lastClick = await getLatestClickTimestamp({
    axiosRequest,
    justSignedIn,
    isAuthenticated,
    user
  });
  const lastClickTimeMs = getTime(lastClick);
  const minDiff = msToMin(currentTimeMs - lastClickTimeMs);
  return Math.round(Math.abs(minDiff));
};

const startingWarnText = 'Your session will soon expire due to inactivity';

export const closeWarningAlert = (options) => {
  const { alertBar } = options || {};
  const hasWarnText = (selector) => {
    const elem = document.querySelector(selector);
    return `${(elem && elem.textContent) || ''}`.includes(startingWarnText);
  };
  const alertBarWarnOpen = hasWarnText(`#siteHeader #alertBar`);
  const sidebarWarnOpen = hasWarnText(`.swal-overlay--show-modal .swal-text`);
  const hasWarningOpen = alertBarWarnOpen || sidebarWarnOpen;
  return (
    hasWarningOpen &&
    ((sidebarWarnOpen && handleCloseSwal()) ||
      (alertBarWarnOpen && alertBar && alertBar('closed', '')))
  );
};

export const getRemainingMinLeft = async (options) => {
  const { axiosRequest, isAuthenticated, user } = options || {};
  const minDiff = await getDiffNowVsLastClick({ axiosRequest, isAuthenticated, user });
  const minLeft = Math.max(1, Math.round(60 - minDiff));
  return minLeft;
};

export const displayWarningAlert = async (options) => {
  const { alertBar, axiosRequest, isAuthenticated, user } = options || {};
  const minLeft = await getRemainingMinLeft({ axiosRequest, isAuthenticated, user });
  const warningText = `${startingWarnText}. Please perform an action on the page to remain signed in, or you will be automatically signed out in less than ${minLeft} minute${minLeft <= 1 ? '' : 's'}.`;
  return isSidebarOpen() ? showSwal(warningText) : alertBar('notice', warningText);
};

/* istanbul ignore next */ // TODO BIRB-8404 Get this line covered
const showSwal = (warningText) =>
  swal({
    text: warningText,
    className: 'swal-corvia-default',
    icon: 'info',
    closeOnClickOutside: false,
    closeOnEsc: false
  });

export const handleSignOut = async (options) => {
  const {
    axiosRequest, // Required
    signOutEndpoint, // Required
    store, // Required
    clearStore = true,
    isSignOutClick, // If user clicked sign out, don't show alertBar
    handleClearLocalDB, // Required
    navigate, // Required
    resetStore, // Required
    alertBar // Required
  } = options || {};
  const isAuth = await isUserAuthenticated({ store, customOptions: { axiosRequest } });
  // TODO: BIRB-8124 - once working, remove `useStubData`
  useStubData &&
    isAuth &&
    !isEmpty(signOutEndpoint) &&
    (await axiosRequest({
      fullPageLoad: true,
      url: signOutEndpoint,
      method: 'post',
      errorOptions: { alert40x: true }
    }));
  handleClearLocalDB();
  if (clearStore) {
    resetStore();
  }
  if (!isPublicUrl && !isSignOutClick) {
    handleCloseSwal();
    alertBar('notice', 'Your session has expired. Please sign in again.');
    navigate('/signin');
  }
};

export const getAccessTokenTimestamps = async (options) => {
  // Returns last click timestamp & its expiration, used for storing values in redux
  const { axiosRequest, isSignIn } = options || {};
  const latestClick = (await getLatestClickData({ axiosRequest, getFromLocalDB })) || {};
  const lastClickTimestamp =
    isSignIn ||
    isEmpty(latestClick.timestamp) ||
    latestClick.timestamp === 0 ||
    `${latestClick.timestamp}` === 'Invalid Date'
      ? // just use now if latest click is missing, or user just signed in
        getNow(null, { useRealTime: true })
      : (latestClick.timestamp instanceof Date && latestClick.timestamp) ||
        getNow(latestClick.timestamp);
  const lastClickExp = getNow(lastClickTimestamp);
  lastClickExp.setHours(lastClickExp.getHours() + 1);
  const timestamps = { lastClickTimestamp: getNow(lastClickTimestamp), exp: getNow(lastClickExp) };
  return timestamps;
};

export const isUserAuthenticated = async (options) => {
  // Handles using the store and last click to determine if user is still signed in.
  // Eg, If user has multiple tabs open and signs out of one tab,
  // using this check will allow other tabs to know & sign user out of the other tabs too.
  const {
    customOptions, // should include `axiosRequest`
    store
  } = options || {};
  const { authenticate } = store.getState();
  const { isAuthenticated: storeIsAuthenticated, user: { accessToken: { csrfToken } = {} } = {} } =
    authenticate || {};
  const useMockData = getMockDbData(csrfToken); // For FTs
  const prevClickTrail = useMockData ? [] : await getFromLocalDB('clickTrail', customOptions);
  // The last click upon user clicking sign-out SHOULD be the sign-out click.
  const lastClick = !isEmpty(prevClickTrail) ? [...prevClickTrail].pop() || {} : {};
  const isSignedOut = useMockData
    ? // For FTs, we need to use the store's `isAuthenticated` value only so tests can retain
      // autenticated state between page navigation/refreshes.
      !storeIsAuthenticated
    : !isEmpty(lastClick) &&
      (lastClick.timestamp === 0 || `${lastClick.textContent || ''}` === 'Sign Out');
  const nextIsAuthenticated = isSignedOut || !storeIsAuthenticated ? false : storeIsAuthenticated;
  return nextIsAuthenticated;
};
