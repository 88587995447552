import {
  getMockPageData,
  getMockValue,
  mockPagingTokenInfo
} from '@f1/shared/src/data/_mockDataHelpers';
import { getThisYearMonth, getType, isEmpty, isEqual } from '@f1/shared/src/_helpers';
import { createMockFile } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import { mockMerchantDetailsGetRes } from '../merchant/v2/mockMerchantsV2';
import { ticketGuids } from '../guids';
import { mockEmployees } from '../employee/mockEmployees';
import { mockPartnerV2DetailsGetRes } from '../partner/v2/mockPartnersV2Get';

const mockStatusList = ['approved_by_ops', 'completed_by_residual', 'rejected', 'needs_review'];

const getMockMerchant = (merchantGuid, mid) => {
  const mockMerchantDetails =
    (!isEmpty(mid)
      ? Object.values(mockMerchantDetailsGetRes).find((m) => isEqual(mid, m.merchant.mid))
      : mockMerchantDetailsGetRes[merchantGuid]) || Object.values(mockMerchantDetailsGetRes)[0];
  return {
    dbaName: mockMerchantDetails.merchant.dbaName,
    merchantGuid,
    mid: mockMerchantDetails.merchant.mid
  };
};

const getMockPartner = (partnerId) => {
  const mockPartnerDetails =
    mockPartnerV2DetailsGetRes[partnerId] || Object.values(mockPartnerV2DetailsGetRes)[0];
  return {
    partnerId,
    partnerName: mockPartnerDetails.partner.name,
    partnerBusinessCode: mockPartnerDetails.partner.partnerBusinessCode
  };
};

const mockAllPagedRefundRequest = [...mockStatusList, ...Array(30).keys()].map((status, index) => {
  const mockStatus =
    getType(status) === 'string' ? status : getMockValue({ type: 'list', list: mockStatusList });
  const useOpsDecision = ['completed_by_residual', 'approved_by_ops', 'rejected'].includes(
    mockStatus
  );
  const useResidualDecision = ['completed_by_residual'].includes(mockStatus);
  const isRejected = ['rejected'].includes(mockStatus);
  const mockMerchantGuid = getMockValue({
    type: 'list',
    list: Object.keys(mockMerchantDetailsGetRes)
  });
  const mockPartnerId = getMockValue({
    type: 'list',
    list: Object.keys(mockPartnerV2DetailsGetRes)
  });
  const mockPartner = getMockPartner(mockPartnerId);
  const mockOpsEmployee = mockEmployees.employee07;
  const mockResidualsEmployee = mockEmployees.employee15;
  const mockItem = {
    deniedReasonEnum: isRejected
      ? getMockValue({
          type: 'list',
          list: [
            'fee_type_not_eligible',
            'refund_past_90_days',
            'incorrect_statements_provided',
            'negative_balance_due',
            'unable_to_reconcile_request',
            'duplicate_request',
            'merchant_participated_in_fraudulent_activity'
          ]
        })
      : null,
    feeAmountInMpa: getMockValue({ type: 'price' }),
    feeNameOrRefundType: `Mock fee name ${index + 1}`,
    residualFeeNameOrRefundType: `Mock residual fee name ${index + 1}`,
    merchant: getMockMerchant(mockMerchantGuid),
    opsDecision: useOpsDecision
      ? {
          decisionedTimestamp: getMockValue({ type: 'isoDate' }),
          decisionedEmployee: {
            email: mockOpsEmployee.email,
            firstName: mockOpsEmployee.firstName,
            lastName: mockOpsEmployee.lastName,
            manager: mockOpsEmployee.manager,
            employeeId: mockOpsEmployee.employeeId
          },
          refundAmountApproved: getMockValue({ type: 'price' })
        }
      : null,
    partner: mockPartner,
    pricingAdjEntry: getMockValue({
      type: 'list',
      list: [
        'pricing_adj_1',
        'pricing_adj_2',
        'off_statement_refund',
        'mxc_pricing_adjustment',
        'mxc_pricing_adjustment_100_percent'
      ]
    }),
    refundAmountRequested: getMockValue({ type: 'price' }),
    refundRequestId: `mock-refund-request-id-${index + 1}`,
    residualDecision: useResidualDecision
      ? {
          decisionedTimestamp: getMockValue({ type: 'isoDate' }),
          decisionedEmployee: {
            email: mockResidualsEmployee.email,
            firstName: mockResidualsEmployee.firstName,
            lastName: mockResidualsEmployee.lastName,
            manager: mockResidualsEmployee.manager,
            employeeId: mockResidualsEmployee.employeeId
          },
          residualAdjustment: getMockValue({ type: 'price' })
        }
      : null,
    residualFlattenedHierarchy: {
      relationshipCode1: '20000',
      relationshipCode2: '20001',
      relationshipCode3: '20002',
      partnerCodeLvl1: '20000',
      partnerCodeLvl2: '1000',
      partnerCodeLvl3: '1001',
      partnerName: mockPartner.partnerName
    },
    status: mockStatus,
    submittedTimestamp: getMockValue({ type: 'isoDate' }),
    ticketId: getMockValue({ type: 'list', list: Object.values(ticketGuids) }),
    yearMonthOfOriginalCharge: getThisYearMonth(true),
    merchantStatement: createMockFile(index, {
      staticFile: {
        fileName: `mockMerchantStatement${index + 1}.pdf`,
        tags: [{ tagObject: 'merchant_statement' }]
      }
    })
  };
  return mockItem;
});

const filterDataByParams = (data, params) => {
  const { mid, partnerId, endYearMonth, startYearMonth, status } = params || {};
  return data.reduce((acc, item, index) => {
    const mockItem = {
      ...item,
      ...((!isEmpty(startYearMonth) || !isEmpty(endYearMonth)) && {
        submittedTimestamp:
          `${index >= 16 ? endYearMonth || startYearMonth : startYearMonth || endYearMonth}-01`.concat(
            'T14:35:16.950'
          ),
        yearMonthOfOriginalCharge:
          index >= 16 ? endYearMonth || startYearMonth : startYearMonth || endYearMonth
      }),
      ...(!isEmpty(partnerId) && { partner: getMockPartner(partnerId) }),
      ...(!isEmpty(mid) && { merchant: getMockMerchant(null, mid) })
    };
    return isEmpty(status) || (!isEmpty(status) && isEqual(status, mockItem.status))
      ? acc.concat(mockItem)
      : acc;
  }, []);
};

export const mockRefundRequestGet = (body, params, options) => {
  const { pageId, pageSize = mockPagingTokenInfo.pageSize } = params || {};
  const { length } = options || {};
  const mockAllData = filterDataByParams(
    length ? mockAllPagedRefundRequest.slice(0, length + 1) : mockAllPagedRefundRequest,
    params
  );
  const pageIndex = pageId || 0;
  const pageData = getMockPageData({
    allData: mockAllData,
    pageIndex,
    pageSize,
    optionsCopy: { ...params, pageIndex }
  });
  return {
    refundRequestList: pageData,
    pagingTokenInfo: {
      hasMoreRecords: mockAllData.length - (pageIndex + 1) * pageSize > 0,
      nextPageId: pageIndex + 1,
      pageSize
    }
  };
};

export default mockRefundRequestGet;
