import { dataExists, getFormattedRelationship, ignoreCase, isEmpty, sortData } from './_helpers';
import { baseFormFields, formDivDivider } from './_boardingToolFields';
import {
  cleanFieldsForBackend,
  isPriorityFirstDataBank,
  isPriorityTsysBank
} from './_templateHelpers';
import { formStylesCSS } from './_formStyles';

export const customBackendFormats = {
  // Custom methods to format for BE valuesForBackend
  checkboxList: (array, options) => {
    const { list = [] } = options || {};
    const dataValueMap = array.reduce((acc, item) => ({ ...acc, ...item }), {});
    const customValue = list.reduce((acc, item) => {
      const itemValue = item.value;
      return { ...acc, [itemValue]: dataValueMap[itemValue] || false };
    }, {});
    return customValue;
  }
};

const comboBoxProps = {
  type: 'text',
  fieldType: 'combobox',
  useBlockForm: true,
  formField: true,
  required: true
};

const priceProps = {
  type: 'price',
  prefix: '$',
  priceToNumber: true,
  fieldType: 'input',
  required: true
};

const checkboxListProps = {
  fieldType: 'checkboxList',
  required: true,
  containerStyle: { flex: '100%' },
  isCheckboxList: true, // if BE value is in the format that FE already expects
  backendFormat: customBackendFormats.checkboxList
};

const radioProps = {
  type: 'radio',
  fieldType: 'radio',
  required: true,
  fields: [] // DO NOT include `fields` here, must be its own property for each form field
};

const percentProps = {
  // percent on FE / ratio on BE
  type: 'percent',
  isRatio: true,
  required: true,
  suffix: '%',
  fieldType: 'input'
};

const textProps = { type: 'text', fieldType: 'input', required: true };

const dateOnlyProps = {
  type: 'date',
  dateOnly: true,
  fieldType: 'input',
  required: true
};

const textareaProps = {
  type: 'textarea',
  fieldType: 'input',
  wrapperStyle: { flex: '100%' },
  required: true
};

export const basePriorityFields = {
  accountType: {
    ...comboBoxProps,
    label: 'Account Type',
    list: [
      { title: 'Checking', value: 'checking' },
      { title: 'Savings', value: 'saving' }
    ],
    required: true
  },
  customDescription: { ...textareaProps, label: 'Custom Description' },
  entityFormationDate: {
    ...dateOnlyProps,
    id: 'entityFormationDate',
    label: 'Entity Formation Date'
  },
  lengthOfCurrentOwnership: {
    id: 'lengthOfCurrentOwnership',
    label: 'Length Of Current Ownership (Years)',
    type: 'number',
    fieldType: 'input',
    required: true
  },

  // priorityBusinessInformationSection
  priorBankruptcy: {
    ...radioProps,
    id: 'priorBankruptcy',
    name: 'priorBankruptcy',
    label: 'Prior Bankruptcy'
  },
  dischargeDate: {
    ...dateOnlyProps,
    id: 'dischargeDate',
    label: 'Prior Bankruptcy Discharge Date'
  },
  marketSegment: {
    ...comboBoxProps,
    id: 'marketSegment',
    label: 'Market Segment',
    list: sortData(
      [
        { title: 'Travel', value: 'travel' },
        { title: 'Services', value: 'services' },
        { title: 'Retail', value: 'retail' },
        { title: 'Government', value: 'government' },
        { title: 'Charity/Religious Organizations', value: 'charity_or_religious_orgs' },
        { title: 'Passenger Transport', value: 'passenger_trnasport' },
        { title: 'Account Funding', value: 'account_funding' },
        { title: 'Healthcare', value: 'health_care' },
        { title: 'Hotel/Car Rental', value: 'hotel_or_car_rental' },
        { title: 'Insurance', value: 'insurance' },
        { title: 'Automated Fuel Dispensers', value: 'automated_fuel_dispensers' },
        { title: 'Utility', value: 'utility' },
        { title: 'Restaurant', value: 'restaurant' },
        { title: 'Recurring Payment', value: 'recurring_payment' },
        { title: 'Real Estate', value: 'real_estate' },
        { title: 'Advertising', value: 'advertising' },
        { title: 'Supermarket', value: 'super_market' },
        { title: 'Taxi', value: 'taxi' }
      ],
      'title'
    )
  },
  businessEstablished: {
    ...dateOnlyProps,
    id: 'businessEstablished',
    label: 'Business Established Date'
  },

  // priorityFeaturesSection
  basic: {
    ...checkboxListProps,
    required: false,
    id: 'basic',
    label: 'Basic',
    list: [
      { checked: false, title: 'Visa Debit', value: 'visaDebit' },
      { checked: false, title: 'Visa Credit', value: 'visaCredit' },
      { checked: false, title: 'Mastercard Debit', value: 'mastercardDebit' },
      { checked: false, title: 'Mastercard Credit', value: 'mastercardCredit' },
      { checked: false, title: 'American Express (OptBlue)', value: 'amexOptBlue' },
      { checked: false, title: 'Discover Full Acquiring (MAP)', value: 'discoverFullAcquiring' },
      { checked: false, title: 'PIN Debit', value: 'pinDebit' },
      { checked: false, title: 'EBT Cash Benefit', value: 'ebtCashBenefit' },
      { checked: false, title: 'EBT Food Stamp', value: 'ebtFoodStamp' }
    ]
  },
  ebtFoodStFnsNumber: {
    ...textProps,
    id: 'ebtFoodStFnsNumber',
    label: 'EBT Food Stamp FNS Number'
  },
  advanced: (relationship) => ({
    ...checkboxListProps,
    required: false,
    id: 'advanced',
    label: 'Advanced',
    list: [
      { checked: false, title: 'American Express Direct', value: 'americanExpressDirect' },
      { checked: false, title: 'Discover Direct', value: 'discoverDirect' },
      ...(isPriorityFirstDataBank(relationship)
        ? [
            { checked: false, title: 'Voyager', value: 'voyager' },
            { checked: false, title: 'Wright Express', value: 'wrightExpress' }
          ]
        : []),
      {
        checked: false,
        title: 'Opt Out Of Amex Commercial Marketing Communication',
        value: 'optOutAmexCommercialMarketingCommunication'
      }
    ]
  }),
  partnerOnly: (relationship) => ({
    ...checkboxListProps,
    required: false,
    id: 'partnerOnly',
    label: 'Partner Only',
    list: sortData(
      [
        { checked: false, title: 'Request Daily Discount', value: 'requestDailyDiscount' },
        ...(isPriorityFirstDataBank(relationship)
          ? []
          : [
              {
                checked: false,
                title: 'Request Daily Interchange',
                value: 'requestDailyInterchange'
              }
            ]),
        { checked: false, title: 'Request Next Day Funding', value: 'requestNextDayFunding' },
        { checked: false, title: 'Debt Repayment', value: 'debtRepayment' },
        { checked: false, title: 'Commercial Transactions', value: 'commercialTransactions' },
        { checked: false, title: 'Request ACH Method Change', value: 'requestAchMethodChange' },
        {
          checked: false,
          title: 'Use Deposits Account For Chargebacks',
          value: 'useDepositsAccountForChargeback'
        }
      ],
      'title'
    )
  }),
  sponsorBanks: {
    ...checkboxListProps,
    id: 'sponsorBanks',
    label: 'Sponsor Banks',
    list: sortData(
      [
        { checked: false, title: 'Axiom', value: 'axiomBank' },
        { checked: false, title: 'PB&T', value: 'puebloBank' },
        { checked: false, title: 'Synovus', value: 'synovusBank' },
        { checked: false, title: 'Wells Fargo', value: 'wellsFargoBank' }
      ],
      'title'
    )
  },

  // priorityPaymentProcessingAndSalesSection
  futureDelivery: {
    ...comboBoxProps,
    id: 'futureDelivery',
    label: 'Future Delivery',
    list: [
      { title: 'None', value: 'none' },
      { title: '1-7 Days', value: 'days_1_7' },
      { title: '8-14 Days', value: 'days_8_14' },
      { title: '15-30 Days', value: 'days_15_30' },
      { title: '31-60 Days', value: 'days_31_60' },
      { title: '61+ Days', value: 'over_60_days' }
    ]
  },
  amexDirectEsaNumber: {
    ...textProps,
    id: 'amexDirectEsaNumber',
    label: 'Amex Direct ESA Number',
    required: false
  },
  welcomeKit: {
    ...comboBoxProps,
    id: 'welcomeKit',
    label: 'Welcome Kit',
    list: sortData(
      [
        { title: 'Global Welcome Kit (TSYS)', value: 'global_welcome_kit_tsys' },
        { title: 'Welcome Email (TSYS/Corvia)', value: 'welcome_email_tsys_corvia' },
        { title: 'Global Welcome Kit (First Data)', value: 'global_welcome_kit_fd' },
        { title: 'Welcome Email (First Data/Corvia)', value: 'welcome_email_fd_corvia' }
      ],
      'title'
    )
  },
  statementBoarding: {
    ...comboBoxProps,
    id: 'statementBoarding',
    label: 'Statement Boarding',
    list: [{ title: 'F1 Payments LP', value: 'f1_payments_lp' }]
  },
  acceptMajorCardNetwork: {
    ...radioProps,
    id: 'acceptMajorCardNetwork',
    name: 'acceptMajorCardNetwork',
    label: 'Accepts Major Card Network',
    tooltip: 'Includes Visa, Mastercard, Discover, and Amex'
  },
  priorBreach: {
    ...radioProps,
    id: 'priorBreach',
    name: 'priorBreach',
    label: 'Prior Breach'
  },
  everTerminated: {
    ...radioProps,
    id: 'everTerminated',
    name: 'everTerminated',
    label: 'Ever Terminated?'
  },
  terminationReason: { ...textProps, id: 'terminationReason', label: 'Termination Reason' },
  hadPriorProcessor: {
    // FE-only radio
    ...radioProps,
    id: 'hadPriorProcessor',
    name: 'hadPriorProcessor',
    label: 'Had Prior Processor'
  },
  processorName: { ...textProps, id: 'processorName', label: 'Prior Processor Name' },
  reasonForLeaving: {
    ...textProps,
    id: 'reasonForLeaving',
    label: 'Reason For Leaving Prior Processor'
  },

  // priorityCardNotPresentSection
  useCardNotPresent: {
    // FE-only checkbox
    fieldType: 'checkbox',
    height: 'auto',
    wrapperStyle: {
      width: '100%',
      background: formStylesCSS.formSectionHeader.background,
      padding: '3px 4px'
    },
    labelStyle: {
      color: formStylesCSS.formSectionHeader.color,
      fontWeight: 'bold',
      fontSize: '1.8rem'
    },
    id: 'useCardNotPresent',
    name: 'useCardNotPresent',
    label: 'Card Not Present',
    required: false
  },
  internetBusinessType: {
    ...textProps,
    id: 'internetBusinessType',
    label: 'Internet Business Type'
  },
  advertisingMethod: {
    ...textProps,
    id: 'advertisingMethod',
    label: 'Advertising Method'
  },
  // supplyFulfillmentVendor object
  supplyFulfillmentVendorType: {
    ...textProps,
    id: 'supplyFulfillmentVendorType',
    label: 'Type'
  },
  supplyFulfillmentVendorName: {
    ...textProps,
    id: 'supplyFulfillmentVendorName',
    label: 'Name'
  },
  supplyFulfillmentVendorPhoneNumber: {
    id: 'supplyFulfillmentVendorPhoneNumber',
    label: 'Phone Number',
    type: 'tel',
    telPrefixRequired: true, // to include +1 for BE
    fieldType: 'input'
  },

  // priorityBillingMethodSection
  monthly: {
    ...percentProps,
    id: 'monthly',
    label: 'Monthly'
  },
  yearly: {
    ...percentProps,
    id: 'yearly',
    label: 'Yearly'
  },
  oneTime: {
    ...percentProps,
    id: 'oneTime',
    label: 'One Time'
  }
};

const prioritySharedFormFields = {
  accountType: basePriorityFields.accountType,
  customDescription: basePriorityFields.customDescription
};

const priorityFormFields = (options = {}) => ({
  priorityBusinessInformationSection: {
    id: 'priorityBusinessInformationSection',
    entityFormationDate: basePriorityFields.entityFormationDate,
    lengthOfCurrentOwnership: basePriorityFields.lengthOfCurrentOwnership,
    ...formDivDivider(),
    priorBankruptcy: {
      ...basePriorityFields.priorBankruptcy,
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      controls: {
        yes: {
          dischargeDate: basePriorityFields.dischargeDate
        }
      }
    },
    ...formDivDivider(),
    marketSegment: basePriorityFields.marketSegment,
    businessEstablished: basePriorityFields.businessEstablished
  },
  priorityAchInfoAndFundingSection: {
    id: 'priorityAchInfoAndFundingSection',
    fundingAccountHeader: {
      // fundingAccount
      id: 'fundingAccountHeader',
      fieldType: 'formSectionHeader',
      title: 'Funding Account',
      isSubSection: true
    },
    fundingAccountAccountType: {
      ...prioritySharedFormFields.accountType,
      id: 'fundingAccountAccountType'
    },
    fundingAccountCustomDescription: {
      ...prioritySharedFormFields.customDescription,
      id: 'fundingAccountCustomDescription',
      required: false
    },
    ...(isPriorityTsysBank(options) && {
      // this is required if this is for TSYS and this is not allowed if this is for First Data
      // billingAccount
      billingAccountHeader: {
        id: 'billingAccountHeader',
        fieldType: 'formSectionHeader',
        title: 'Billing Account',
        isSubSection: true
      },
      billingAccountType: { ...prioritySharedFormFields.accountType, id: 'billingAccountType' },
      billingAccountCustomDescription: {
        ...prioritySharedFormFields.customDescription,
        id: 'billingAccountCustomDescription',
        required: false
      },
      billingSameAsPrimary: {
        id: 'billingSameAsPrimary',
        name: 'billingSameAsPrimary',
        label: 'Billing Account information same as ACH information (on the Business tab)?',
        type: 'radio',
        fieldType: 'radio',
        fields: [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' }
        ],
        wrapperStyle: { flex: '100%' },
        controls: {
          no: {
            billingAccountBankName: {
              ...textProps,
              id: 'billingAccountBankName',
              label: 'Bank Name'
            },
            billingAccountNameOnAccount: {
              ...textProps,
              id: 'billingAccountNameOnAccount',
              label: 'Name On Account'
            }
          }
        },
        controllerStateOverrides: {
          no: {
            billingAccountBankName: '',
            billingAccountNameOnAccount: ''
          }
        }
      }
    })
  },
  ...(isPriorityTsysBank(options) && {
    // this is required if this is for TSYS and this is not allowed if this is for First Data
    priorityConfirmBillingAccountSection: {
      // section to re-enter accountNumber, routingNumber fields
      id: 'priorityConfirmBillingAccountSection',
      routingNumber: {
        ...baseFormFields.routingNumber,
        id: 'routingNumber',
        label: 'Routing Number'
      },
      accountNumber: {
        ...baseFormFields.accountNumber,
        id: 'accountNumber',
        label: 'Account Number'
      }
    }
  }),
  priorityFeaturesSection: {
    id: 'priorityFeaturesSection',
    basic: {
      ...basePriorityFields.basic,
      controls: {
        ebtFoodStamp: {
          ebtFoodStFnsNumber: basePriorityFields.ebtFoodStFnsNumber
        }
      }
    },
    advanced: basePriorityFields.advanced(options),
    partnerOnly: basePriorityFields.partnerOnly(options),
    sponsorBanks: basePriorityFields.sponsorBanks
  },
  priorityPaymentProcessingAndSalesSection: {
    id: 'priorityPaymentProcessingAndSalesSection',
    futureDelivery: basePriorityFields.futureDelivery,
    amexDirectEsaNumber: basePriorityFields.amexDirectEsaNumber,
    welcomeKit: basePriorityFields.welcomeKit,
    statementBoarding: basePriorityFields.statementBoarding,
    acceptMajorCardNetwork: {
      ...basePriorityFields.acceptMajorCardNetwork,
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ]
    },
    priorBreach: {
      ...basePriorityFields.priorBreach,
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ]
    },
    ...formDivDivider(),
    everTerminated: {
      ...basePriorityFields.everTerminated,
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      controls: {
        yes: {
          terminationReason: basePriorityFields.terminationReason
        }
      }
    },
    ...formDivDivider(),
    hadPriorProcessor: {
      // FE-only radio
      ...basePriorityFields.hadPriorProcessor,
      fields: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      controls: {
        yes: {
          processorName: basePriorityFields.processorName,
          reasonForLeaving: basePriorityFields.reasonForLeaving
        }
      }
    }
  },
  priorityCardNotPresentSection: {
    id: 'priorityCardNotPresentSection',
    useCardNotPresent: {
      // FE-only checkbox
      ...basePriorityFields.useCardNotPresent,
      controls: {
        true: {
          internetBusinessType: basePriorityFields.internetBusinessType,
          advertisingMethod: basePriorityFields.advertisingMethod,
          // supplyFulfillmentVendor object
          supplyFulfillmentVendorHeader: {
            id: 'supplyFulfillmentVendorHeader',
            fieldType: 'formSectionHeader',
            title: 'Supply/Fulfillment Vendor',
            isSubSection: true
          },
          supplyFulfillmentVendorType: basePriorityFields.supplyFulfillmentVendorType,
          supplyFulfillmentVendorName: basePriorityFields.supplyFulfillmentVendorName,
          supplyFulfillmentVendorPhoneNumber: basePriorityFields.supplyFulfillmentVendorPhoneNumber
        }
      }
    }
  },
  // cardNotPresentQuestionnaire.billingMethod custom percents
  priorityBillingMethodSection: {
    id: 'priorityBillingMethodSection',
    monthly: basePriorityFields.monthly,
    yearly: basePriorityFields.yearly,
    oneTime: basePriorityFields.oneTime
  }
});

export const feesTabFormFieldsPriority = (options) => {
  const { bankName } = options || {};
  const processName = 'priority';
  const relationship = { bankName, processName };
  const isPriorityTsys = isPriorityTsysBank(relationship);
  const isPriorityFirstData = isPriorityFirstDataBank(relationship);
  const priorityFeesSectionTsys = isPriorityTsys
    ? {
        priorityRatesAndFeesSection: {
          id: 'priorityRatesAndFeesSection',
          // ratesAndFees.transactionFees
          transactionFeesHeader: {
            id: 'transactionFeesHeader',
            fieldType: 'formSectionHeader',
            title: 'Transaction Fees',
            isSubSection: true
          },
          // ratesAndFees.transactionFees.authorization
          transactionFeesAuthHeader: {
            id: 'transactionFeesAuthHeader',
            fieldType: 'formSectionHeader',
            title: 'Authorizations',
            isNestedSubSection: true
          },
          amexIpDialMxmIpAuth: {
            ...priceProps,
            id: 'amexIpDialMxmIpAuth',
            label: 'Amex IP, Dial, MXM IP'
          },
          voiceAuth: { ...priceProps, id: 'voiceAuth', label: 'Voice' },
          pinDebitIpDialMxmIpAuth: {
            ...priceProps,
            id: 'pinDebitIpDialMxmIpAuth',
            label: 'Pin Debit, IP, Dial, MXM IP'
          },
          ebtIpDialMxmIpAuth: {
            ...priceProps,
            id: 'ebtIpDialMxmIpAuth',
            label: 'EBT, IP, Dial, MXM IP'
          },
          visaMasterCardDiscoverIpDialMxmIpAuth: {
            ...priceProps,
            id: 'visaMasterCardDiscoverIpDialMxmIpAuth',
            label: 'Visa, Mastercard, Discover, IP, Dial, MXM IP'
          },

          // ratesAndFees.transactionFees.authorizationOther
          transactionFeesAuthOtherHeader: {
            id: 'transactionFeesAuthOtherHeader',
            fieldType: 'formSectionHeader',
            title: 'Authorizations (Other)',
            isNestedSubSection: true
          },
          avsAuthOther: { ...priceProps, id: 'avsAuthOther', label: 'AVS' },
          batchAuthOther: { ...priceProps, id: 'batchAuthOther', label: 'Batch' },

          // ratesAndFees.transactionFees.sale
          transactionFeesSaleHeader: {
            id: 'transactionFeesSaleHeader',
            fieldType: 'formSectionHeader',
            title: 'Sales',
            isNestedSubSection: true
          },
          visaMasterCarAmexPinDebitEbtSale: {
            ...priceProps,
            id: 'visaMasterCarAmexPinDebitEbtSale',
            label: 'Visa, Mastercard, Amex, Pin Debit, EBT'
          },
          pinDebitSale: { ...percentProps, id: 'pinDebitSale', label: 'Pin Debit' },

          // ratesAndFees.transactionFees.refund
          transactionFeesRefundHeader: {
            id: 'transactionFeesRefundHeader',
            fieldType: 'formSectionHeader',
            title: 'Refunds',
            isNestedSubSection: true
          },
          visaMasterCardDiscoverAmexRefund: {
            ...priceProps,
            id: 'visaMasterCardDiscoverAmexRefund',
            label: 'Visa, Mastercard, Discover, Amex'
          },

          pricingHeader: { id: 'pricingHeader', fieldType: 'formSectionHeader', title: 'Pricing' },
          pricingType: {
            ...comboBoxProps,
            id: 'pricingType',
            label: 'Pricing Type',
            wrapperStyle: { flex: '100%' },
            list: sortData(
              [
                { title: 'Flat Pricing', value: 'flat_pricing' },
                { title: 'Interchange Plus', value: 'interchange_plus' },
                { title: 'Tiered Rates', value: 'tiered_rates' }
              ],
              'title'
            ),
            controls: {
              flat_pricing: {
                // flatRate.transactionFees.sale
                flatRateTransactionFeesSaleHeader: {
                  id: 'flatRateTransactionFeesSaleHeader',
                  fieldType: 'formSectionHeader',
                  title: 'Sales (Flat Pricing)',
                  isSubSection: true
                },
                amexFlatRate: { ...percentProps, id: 'amexFlatRate', label: 'American Express' },
                visaMasterCardDiscoverCreditFlatRate: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverCreditFlatRate',
                  label: 'Visa, Mastercard, Discover (Credit)'
                },
                visaMasterCardDiscoverDebitFlatRate: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverDebitFlatRate',
                  label: 'Visa Mastercard Discover (Debit)'
                }
              },
              interchange_plus: {
                // interchangePlus.transactionFees.sale
                interchangePlusTransactionFeesSaleHeader: {
                  id: 'interchangePlusTransactionFeesSaleHeader',
                  fieldType: 'formSectionHeader',
                  title: 'Sales (Interchange Plus)',
                  isSubSection: true
                },
                amexInterchangePlus: {
                  ...percentProps,
                  id: 'amexInterchangePlus',
                  label: 'American Express'
                },
                visaMasterCardDiscoverCreditInterchangePlus: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverCreditInterchangePlus',
                  label: 'Visa, Mastercard, Discover (Credit)'
                },
                visaMasterCardDiscoverDebitInterchangePlus: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverDebitInterchangePlus',
                  label: 'Visa Mastercard Discover (Debit)'
                }
              },
              tiered_rates: {
                // tieredRates.transactionFees.sale
                tieredRatesTransactionFeesSaleHeader: {
                  id: 'tieredRatesTransactionFeesSaleHeader',
                  fieldType: 'formSectionHeader',
                  title: 'Sales (Tiered Rates)',
                  isSubSection: true
                },
                amexTieredRatesHeader: {
                  id: 'amexTieredRatesHeader',
                  fieldType: 'formSectionHeader',
                  title: 'American Express',
                  isNestedSubSection: true
                },
                qualifiedAmexTieredRates: {
                  ...percentProps,
                  id: 'qualifiedAmexTieredRates',
                  label: 'Qualified'
                },
                midQualifiedAmexTieredRates: {
                  ...percentProps,
                  id: 'midQualifiedAmexTieredRates',
                  label: 'Mid-Qualified'
                },
                nonQualifiedAmexTieredRates: {
                  ...percentProps,
                  id: 'nonQualifiedAmexTieredRates',
                  label: 'Non-Qualified'
                },
                visaMcDiscoverDebitTieredRatesHeader: {
                  id: 'visaMcDiscoverDebitTieredRatesHeader',
                  fieldType: 'formSectionHeader',
                  title: 'Visa, Mastercard, Discover (Debit)',
                  isNestedSubSection: true
                },
                qualifiedVisaMasterCardDiscoverDebitTieredRates: {
                  ...percentProps,
                  id: 'qualifiedVisaMasterCardDiscoverDebitTieredRates',
                  label: 'Qualified'
                },
                midQualifiedVisaMasterCardDiscoverDebitTieredRates: {
                  ...percentProps,
                  id: 'midQualifiedVisaMasterCardDiscoverDebitTieredRates',
                  label: 'Mid-Qualified'
                },
                nonQualifiedVisaMasterCardDiscoverDebitTieredRates: {
                  ...percentProps,
                  id: 'nonQualifiedVisaMasterCardDiscoverDebitTieredRates',
                  label: 'Non-Qualified'
                },
                visaMcDiscoverCreditTieredRatesHeader: {
                  id: 'visaMcDiscoverCreditTieredRatesHeader',
                  fieldType: 'formSectionHeader',
                  title: 'Visa, Mastercard, Discover (Credit)',
                  isNestedSubSection: true
                },
                qualifiedVisaMasterCardDiscoverCreditTieredRates: {
                  ...percentProps,
                  id: 'qualifiedVisaMasterCardDiscoverCreditTieredRates',
                  label: 'Qualified'
                },
                midQualifiedVisaMasterCardDiscoverCreditTieredRates: {
                  ...percentProps,
                  id: 'midQualifiedVisaMasterCardDiscoverCreditTieredRates',
                  label: 'Mid-Qualified'
                },
                nonQualifiedVisaMasterCardDiscoverCreditTieredRates: {
                  ...percentProps,
                  id: 'nonQualifiedVisaMasterCardDiscoverCreditTieredRates',
                  label: 'Non-Qualified'
                }
              }
            }
          }
        },
        priorityOtherFeesSection: {
          id: 'priorityOtherFeesSection',
          // otherProcessingFees.recurring
          otherFeesRecurringHeader: {
            id: 'otherFeesRecurringHeader',
            fieldType: 'formSectionHeader',
            title: 'Recurring',
            isSubSection: true
          },
          industryComplianceMonthly: {
            ...priceProps,
            id: 'industryComplianceMonthly',
            label: 'Industry Compliance (Monthly)'
          },
          wirelessMonthly: { ...priceProps, id: 'wirelessMonthly', label: 'Wireless (Monthly)' },
          miscellaneousMonthly: {
            ...priceProps,
            id: 'miscellaneousMonthly',
            label: 'Miscellaneous (Monthly)'
          },
          minimumFeeMonthly: {
            ...priceProps,
            id: 'minimumFeeMonthly',
            label: 'Minimum Fee (Monthly)'
          },
          serviceMonthly: { ...priceProps, id: 'serviceMonthly', label: 'Service (Monthly)' },
          pinDebitMonthly: { ...priceProps, id: 'pinDebitMonthly', label: 'Pin Debit (Monthly)' },
          serviceAnnually: { ...priceProps, id: 'serviceAnnually', label: 'Service (Annually)' },
          industryComplianceAnnually: {
            ...priceProps,
            id: 'industryComplianceAnnually',
            label: 'Industry Compliance (Annually)'
          },

          // otherProcessingFees.cardException
          otherFeesCardExceptionHeader: {
            id: 'otherFeesCardExceptionHeader',
            fieldType: 'formSectionHeader',
            title: 'Card Exception',
            isSubSection: true
          },
          chargeback: { ...priceProps, id: 'chargeback', label: 'Chargeback' },
          chargebackRetrievalRequest: {
            ...priceProps,
            id: 'chargebackRetrievalRequest',
            label: 'Chargeback Retrieval Request'
          },

          // otherProcessingFees.achExceptions
          otherFeesAchExceptionsHeader: {
            id: 'otherFeesAchExceptionsHeader',
            fieldType: 'formSectionHeader',
            title: 'ACH Exceptions',
            isSubSection: true
          },
          achReturn: { ...priceProps, id: 'achReturn', label: 'ACH Return' },

          // otherProcessingFees.nonCompliance
          otherFeesNonComplianceHeader: {
            id: 'otherFeesNonComplianceHeader',
            fieldType: 'formSectionHeader',
            title: 'Non-Compliance',
            isSubSection: true
          },
          tin: { ...priceProps, id: 'tin', label: 'TIN' },
          pciDss: { ...priceProps, id: 'pciDss', label: 'PCI DSS' },

          // otherProcessingFees.thirdPartyFees
          otherFeesThirdPartyFeesHeader: {
            id: 'otherFeesThirdPartyFeesHeader',
            fieldType: 'formSectionHeader',
            title: 'Third-Party Fees',
            isSubSection: true
          },
          micros: { ...priceProps, id: 'micros', label: 'Micros (Transaction)' },

          // otherProcessingFees.cancellation
          cancellationHeader: {
            id: 'otherFeesCancellationHeader',
            fieldType: 'formSectionHeader',
            title: 'Cancellation',
            isSubSection: true
          },
          earlyTermination: { ...priceProps, id: 'earlyTermination', label: 'Early Termination' }
        }
      }
    : {};
  const priorityFeesSectionFirstData = isPriorityFirstData
    ? {
        priorityRatesAndFeesSection: {
          id: 'priorityRatesAndFeesSection',
          // ratesAndFees.transactionFees
          transactionFeesHeader: {
            id: 'transactionFeesHeader',
            fieldType: 'formSectionHeader',
            title: 'Transaction Fees',
            isSubSection: true
          },
          // ratesAndFees.transactionFees.authorization
          transactionFeesAuthHeader: {
            id: 'transactionFeesAuthHeader',
            fieldType: 'formSectionHeader',
            title: 'Authorizations',
            isNestedSubSection: true
          },
          visaMasterCardDiscoverIpDialMxmIpAuth: {
            ...priceProps,
            id: 'visaMasterCardDiscoverIpDialMxmIpAuth',
            label: 'Visa, Mastercard, Discover, IP, Dial, MXM IP'
          },
          ebtIpDialMxmIpAuth: {
            ...priceProps,
            id: 'ebtIpDialMxmIpAuth',
            label: 'EBT, IP, Dial, MXM IP'
          },
          americanExpressIpDialMxmIpAuth: {
            ...priceProps,
            id: 'americanExpressIpDialMxmIpAuth',
            label: 'Amex, IP, Dial, MXM IP'
          },
          pinDebitIpDialMxmIpAuth: {
            ...priceProps,
            id: 'pinDebitIpDialMxmIpAuth',
            label: 'Pin Debit IP, Dial, MXM IP'
          },
          voiceAuth: { ...priceProps, id: 'voiceAuth', label: 'Voice' },
          voyagerIpDialMxmIpAuth: {
            ...priceProps,
            id: 'voyagerIpDialMxmIpAuth',
            label: 'Voyager IP, Dial, MXM IP'
          },

          // ratesAndFees.transactionFees.authorizationOther
          transactionFeesAuthOtherHeader: {
            id: 'transactionFeesAuthOtherHeader',
            fieldType: 'formSectionHeader',
            title: 'Authorization (Other)',
            isNestedSubSection: true
          },
          avsAuthOther: { ...priceProps, id: 'avsAuthOther', label: 'AVS' },
          batchAuthOther: { ...priceProps, id: 'batchAuthOther', label: 'Batch' },

          // ratesAndFees.transactionFees.sale
          transactionFeesSaleHeader: {
            id: 'transactionFeesSaleHeader',
            fieldType: 'formSectionHeader',
            title: 'Sales',
            isNestedSubSection: true
          },
          voyagerSale: { ...percentProps, id: 'voyagerSale', label: 'Voyager' },
          pinDebitSale: { ...percentProps, id: 'pinDebitSale', label: 'Pin Debit' },
          visaMasterCardDiscoverAmexPinDebitEbtVoyagerWexSale: {
            ...priceProps,
            id: 'visaMasterCardDiscoverAmexPinDebitEbtVoyagerWexSale',
            label: 'Visa, Mastercard, Discover, Amex, Pin Debit, EBT, Voyager, WEX',
            wrapperStyle: { flex: '100%' } // label is long
          },

          // ratesAndFees.transactionFees.refund
          transactionFeesRefundHeader: {
            id: 'transactionFeesRefundHeader',
            fieldType: 'formSectionHeader',
            title: 'Refunds',
            isNestedSubSection: true
          },
          visaMasterCardDiscoverAmexPinDebitRefund: {
            ...priceProps,
            id: 'visaMasterCardDiscoverAmexPinDebitRefund',
            label: 'Visa, Mastercard, Discover, Amex, Pin Debit'
          },

          pricingHeader: { id: 'pricingHeader', fieldType: 'formSectionHeader', title: 'Pricing' },
          pricingType: {
            ...comboBoxProps,
            id: 'pricingType',
            label: 'Pricing Type',
            wrapperStyle: { flex: '100%' },
            list: sortData(
              [
                { title: 'Interchange Plus', value: 'interchange_plus' },
                { title: 'Tiered Rates', value: 'tiered_rates' },
                { title: 'Flat Pricing', value: 'flat_pricing' }
              ],
              'title'
            ),
            controls: {
              flat_pricing: {
                americanExpressFlatPricing: {
                  ...percentProps,
                  id: 'americanExpressFlatPricing',
                  label: 'American Express'
                },
                visaMasterCardDiscoverDebitFlatPricing: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverDebitFlatPricing',
                  label: 'Visa, Mastercard, Discover (Debit)'
                },
                visaMasterCardDiscoverCreditFlatPricing: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverCreditFlatPricing',
                  label: 'Visa, Mastercard, Discover (Credit)'
                }
              },
              interchange_plus: {
                americanExpressInterchangePlus: {
                  ...percentProps,
                  id: 'americanExpressInterchangePlus',
                  label: 'American Express'
                },
                visaMasterCardDiscoverDebitInterchangePlus: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverDebitInterchangePlus',
                  label: 'Visa, Mastercard, Discover (Debit)'
                },
                visaMasterCardDiscoverCreditInterchangePlus: {
                  ...percentProps,
                  id: 'visaMasterCardDiscoverCreditInterchangePlus',
                  label: 'Visa Mastercard, Discover (Credit)'
                }
              },
              tiered_rates: {
                americanExpressTieredRatesHeader: {
                  // amex
                  id: 'americanExpressTieredRatesHeader',
                  fieldType: 'formSectionHeader',
                  title: 'American Express',
                  isSubSection: true
                },
                nonQualifiedAmericanExpressTieredRates: {
                  ...percentProps,
                  id: 'nonQualifiedAmericanExpressTieredRates',
                  label: 'Non-Qualified'
                },
                midQualifiedAmericanExpressTieredRates: {
                  ...percentProps,
                  id: 'midQualifiedAmericanExpressTieredRates',
                  label: 'Mid-Qualified'
                },
                qualifiedAmericanExpressTieredRates: {
                  ...percentProps,
                  id: 'qualifiedAmericanExpressTieredRates',
                  label: 'Qualified'
                },
                visaMcDiscoverDebitTieredRatesHeader: {
                  // debit
                  id: 'visaMcDiscoverDebitTieredRatesHeader',
                  fieldType: 'formSectionHeader',
                  title: 'Visa, Mastercard, Discover (Debit)',
                  isSubSection: true
                },
                nonQualifiedVisaMasterCardDiscoverDebitTieredRates: {
                  ...percentProps,
                  id: 'nonQualifiedVisaMasterCardDiscoverDebitTieredRates',
                  label: 'Non-Qualified'
                },
                midQualifiedVisaMasterCardDiscoverDebitTieredRates: {
                  ...percentProps,
                  id: 'midQualifiedVisaMasterCardDiscoverDebitTieredRates',
                  label: 'Mid-Qualified'
                },
                qualifiedVisaMasterCardDiscoverDebitTieredRates: {
                  ...percentProps,
                  id: 'qualifiedVisaMasterCardDiscoverDebitTieredRates',
                  label: 'Qualified'
                },
                visaMcDiscoverCreditTieredRatesHeader: {
                  // credit
                  id: 'visaMcDiscoverCreditTieredRatesHeader',
                  fieldType: 'formSectionHeader',
                  title: 'Visa, Mastercard, Discover (Credit)',
                  isSubSection: true
                },
                midQualifiedVisaMasterCardDiscoverCreditTieredRates: {
                  ...percentProps,
                  id: 'midQualifiedVisaMasterCardDiscoverCreditTieredRates',
                  label: 'Mid-Qualified'
                },
                qualifiedVisaMasterCardDiscoverCreditTieredRates: {
                  ...percentProps,
                  id: 'qualifiedVisaMasterCardDiscoverCreditTieredRates',
                  label: 'Qualified'
                }
              }
            }
          }
        },
        priorityOtherFeesSection: {
          id: 'priorityOtherFeesSection',
          serviceMonthly: { ...priceProps, id: 'serviceMonthly', label: 'Service (Monthly)' },
          miscellaneousMonthly: {
            ...priceProps,
            id: 'miscellaneousMonthly',
            label: 'Miscellaneous (Monthly)'
          },
          minimumFeeMonthly: {
            ...priceProps,
            id: 'minimumFeeMonthly',
            label: 'Minimum Fee (Monthly)'
          },
          industryComplianceMonthly: {
            ...priceProps,
            id: 'industryComplianceMonthly',
            label: 'Industry Compliance (Monthly)'
          },
          wirelessMonthly: { ...priceProps, id: 'wirelessMonthly', label: 'Wireless (Monthly)' },
          pinDebitMonthly: { ...priceProps, id: 'pinDebitMonthly', label: 'Pin Debit (Monthly)' },
          serviceAnnually: { ...priceProps, id: 'serviceAnnually', label: 'Service (Annually)' },
          chargebackRetrievalRequest: {
            ...priceProps,
            id: 'chargebackRetrievalRequest',
            label: 'Chargeback Retrieval Request'
          },
          chargeback: { ...priceProps, id: 'chargeback', label: 'Chargeback' },
          achReturn: { ...priceProps, id: 'achReturn', label: 'ACH Return' },
          pciDss: { ...priceProps, id: 'pciDss', label: 'PCI DSS' },
          tin: { ...priceProps, id: 'tin', label: 'TIN' },
          earlyTermination: { ...priceProps, id: 'earlyTermination', label: 'Early Termination' }
        }
      }
    : {};
  return {
    ...priorityFeesSectionTsys,
    ...priorityFeesSectionFirstData
  };
};

export const requireCardNotPresent = (backendData, options) => {
  const { onlyUseFeeData } = options || {};
  const { telephoneOrder, eCommerce } = backendData || {};
  if (!onlyUseFeeData) {
    // Business tab -> Payment Processing & Sales -> Orders section
    const ratioTotals = (telephoneOrder ?? 0) + (eCommerce ?? 0);
    return ratioTotals >= 0.21;
  }
  return false;
};

export const buildPriorityTabData = (backendData = {}, options) => {
  const { priorityFields } = backendData || {};
  const { onlyUseFeeData, relationship } = options || {};
  const formattedRelationship = getFormattedRelationship(relationship);
  const formatOptions = { ...options, relationship: formattedRelationship };
  const feesOptions = { ...formatOptions, feesKey: 'priority', appType: 'lowRisk' };
  const priorityOnlySections = onlyUseFeeData
    ? {}
    : getPrioritySectionsFrontend(
        {
          ...priorityFields,
          businessTabAchInfo: options?.businessData?.achInfo?.creditAndDebit
        },
        formatOptions
      );

  // Fees sections are always shown
  const priorityFeesOnlySections = getPriorityFeesSectionsFrontend(priorityFields, feesOptions);
  const tabData = {
    ...priorityOnlySections,
    ...priorityFeesOnlySections
  };
  return tabData;
};

const getPrioritySectionsFrontend = (backendData, options) => {
  // All non-fees sections
  const {
    businessInformation,
    location,
    achInfoAndFunding,
    features,
    paymentProcessingAndSales,
    cardNotPresentQuestionnaire,
    businessTabAchInfo
  } = backendData || {};
  const { businessData, relationship } = options || {};
  const { bankName } = relationship || {};
  const locationDetailsObject = location?.locationDetails;
  const billingAccountObject = achInfoAndFunding?.billingAccount;
  const fundingAccountObject = achInfoAndFunding?.fundingAccount;
  const featuresBasicObj = features?.basic;
  const featuresAdvancedObj = features?.advanced;
  const featuresPartnerOnlyObj = features?.partnerOnly;
  const terminationObject = paymentProcessingAndSales?.terminationInformation;
  const priorProcessorObject = paymentProcessingAndSales?.priorProcessorInformation;
  const supplyFulfillmentVendorObject = cardNotPresentQuestionnaire?.supplyFulfillmentVendor;
  const billingMethodObject = cardNotPresentQuestionnaire?.billingMethod;
  const billingSameAsPrimaryHasValues = dataExists(billingAccountObject);
  const billingSameAsPrimary = billingSameAsPrimaryHasValues
    ? businessTabAchInfo?.accountNumber === billingAccountObject?.accountNumber &&
      businessTabAchInfo?.nameOnAccount === billingAccountObject?.nameOnAccount &&
      businessTabAchInfo?.bankName === billingAccountObject?.bankName &&
      businessTabAchInfo?.routingNumber === billingAccountObject?.routingNumber
    : null;
  const formatCheckboxList = (dataObject) =>
    Object.entries(dataObject).map(([objKey, objValue]) => ({ [objKey]: objValue || false }));
  const isCardNotPresentRequired = requireCardNotPresent(
    businessData?.paymentProcessingAndSales?.orderChannel || {},
    options
  );
  const prioritySections = {
    priorityBusinessInformationSection: {
      entityFormationDate: businessInformation?.entityFormationDate,
      lengthOfCurrentOwnership: businessInformation?.lengthOfCurrentOwnership,
      priorBankruptcy: businessInformation?.priorBankruptcy,
      dischargeDate: businessInformation?.dischargeDate,
      marketSegment: locationDetailsObject?.marketSegment,
      businessEstablished: locationDetailsObject?.businessEstablished
    },
    priorityAchInfoAndFundingSection: {
      billingSameAsPrimary,
      billingAccountType: billingAccountObject?.accountType,
      billingAccountCustomDescription: billingAccountObject?.customDescription,
      billingAccountNameOnAccount: billingAccountObject?.nameOnAccount,
      billingAccountBankName: billingAccountObject?.bankName,
      fundingAccountAccountType: fundingAccountObject?.accountType,
      fundingAccountCustomDescription: fundingAccountObject?.customDescription
    },
    priorityConfirmBillingAccountSection: {
      routingNumber: billingAccountObject?.routingNumber,
      accountNumber: billingAccountObject?.accountNumber
    },
    priorityFeaturesSection: {
      basic:
        typeof featuresBasicObj !== 'undefined'
          ? formatCheckboxList({
              visaDebit: featuresBasicObj?.visaDebit,
              visaCredit: featuresBasicObj?.visaCredit,
              mastercardDebit: featuresBasicObj?.mastercardDebit,
              mastercardCredit: featuresBasicObj?.mastercardCredit,
              amexOptBlue: featuresBasicObj?.amexOptBlue,
              discoverFullAcquiring: featuresBasicObj?.discoverFullAcquiring,
              pinDebit: featuresBasicObj?.pinDebit,
              ebtCashBenefit: featuresBasicObj?.ebtCashBenefit,
              ebtFoodStamp: featuresBasicObj?.ebtFoodStamp
            })
          : undefined,
      ebtFoodStFnsNumber: paymentProcessingAndSales?.ebtFoodStFnsNumber,
      advanced:
        typeof featuresAdvancedObj !== 'undefined'
          ? formatCheckboxList({
              americanExpressDirect: featuresAdvancedObj?.americanExpressDirect,
              discoverDirect: featuresAdvancedObj?.discoverDirect,
              ...(bankName === 'wells_fargo' && {
                voyager: featuresAdvancedObj?.voyager,
                wrightExpress: featuresAdvancedObj?.wrightExpress
              }),
              optOutAmexCommercialMarketingCommunication:
                featuresAdvancedObj?.optOutAmexCommercialMarketingCommunication
            })
          : undefined,
      partnerOnly:
        typeof featuresPartnerOnlyObj !== 'undefined'
          ? formatCheckboxList({
              requestDailyDiscount: featuresPartnerOnlyObj?.requestDailyDiscount,
              ...(bankName === 'wells_fargo'
                ? {}
                : { requestDailyInterchange: featuresPartnerOnlyObj?.requestDailyInterchange }),
              requestNextDayFunding: featuresPartnerOnlyObj?.requestNextDayFunding,
              debtRepayment: featuresPartnerOnlyObj?.debtRepayment,
              commercialTransactions: featuresPartnerOnlyObj?.commercialTransactions,
              requestAchMethodChange: featuresPartnerOnlyObj?.requestAchMethodChange,
              useDepositsAccountForChargeback:
                featuresPartnerOnlyObj?.useDepositsAccountForChargeback
            })
          : undefined,
      sponsorBanks: formatCheckboxList({
        // should match relationship.bankName value associated with this mpa
        axiomBank: bankName === 'axiom',
        puebloBank: bankName === 'pueblo_bank_and_trust',
        synovusBank: bankName === 'synovus',
        wellsFargoBank: bankName === 'wells_fargo'
      })
    },
    priorityPaymentProcessingAndSalesSection: {
      futureDelivery: paymentProcessingAndSales?.futureDelivery,
      amexDirectEsaNumber: paymentProcessingAndSales?.amexDirectEsaNumber,
      welcomeKit: paymentProcessingAndSales?.welcomeKit,
      statementBoarding: paymentProcessingAndSales?.statementBoarding,
      acceptMajorCardNetwork: paymentProcessingAndSales?.acceptMajorCardNetwork,
      priorBreach: paymentProcessingAndSales?.priorBreach,
      everTerminated: terminationObject?.everTerminated,
      terminationReason: terminationObject?.terminationReason,
      hadPriorProcessor:
        typeof priorProcessorObject !== 'undefined' ? dataExists(priorProcessorObject) : undefined,
      processorName: priorProcessorObject?.processorName,
      reasonForLeaving: priorProcessorObject?.reasonForLeaving
    },
    priorityCardNotPresentSection: {
      // useCardNotPresent - if any fields exist, checkbox should be checked
      useCardNotPresent: isCardNotPresentRequired
        ? true
        : dataExists(cardNotPresentQuestionnaire) || undefined,
      internetBusinessType: cardNotPresentQuestionnaire?.internetBusinessType,
      advertisingMethod: cardNotPresentQuestionnaire?.advertisingMethod,
      supplyFulfillmentVendorType: supplyFulfillmentVendorObject?.type,
      supplyFulfillmentVendorName: supplyFulfillmentVendorObject?.name,
      supplyFulfillmentVendorPhoneNumber: supplyFulfillmentVendorObject?.phoneNumber
    },
    priorityBillingMethodSection: {
      monthly: billingMethodObject?.monthly,
      yearly: billingMethodObject?.yearly,
      oneTime: billingMethodObject?.oneTime
    }
  };
  return prioritySections;
};

const getPriorityFeesSectionsFrontend = (backendData, options) => {
  // All fees sections
  const { relationship } = options || {};
  const isPriorityTsys = isPriorityTsysBank(relationship);
  const isPriorityFirstData = isPriorityFirstDataBank(relationship);
  const { firstDataRatesAndFees, tsysRatesAndFees } = backendData || {};
  if (isPriorityTsys) {
    const {
      pricingType,
      flatRate,
      interchangePlus,
      tieredRates,
      transactionFees,
      otherProcessingFees
    } = tsysRatesAndFees?.ratesAndFees || {};
    const tsysPricingType = ignoreCase(pricingType || '');
    const tsysFlatRate = { ...flatRate };
    const tsysInterchangePlus = { ...interchangePlus };
    const tsysTieredRates = { ...tieredRates };

    const tsysTransactionFees = { ...transactionFees };
    const tsysFeesAuth = tsysTransactionFees?.authorization;
    const tsysFeesAuthOther = tsysTransactionFees?.authorizationOther;
    const tsysFeesSale = tsysTransactionFees?.sale;
    const tsysFeesRefund = tsysTransactionFees?.refund;

    const tsysOtherFees = { ...otherProcessingFees };
    const tsysOtherRecurring = tsysOtherFees?.recurring;
    const tsysOtherCardException = tsysOtherFees?.cardException;
    const tsysOtherAchExceptions = tsysOtherFees?.achExceptions;
    const tsysOtherNonCompliance = tsysOtherFees?.nonCompliance;
    const tsysOtherThirdParty = tsysOtherFees?.thirdPartyFees;
    const tsysOtherCancellation = tsysOtherFees?.cancellation;

    const tsysFeesSections = {
      priorityRatesAndFeesSection: {
        pricingType: tsysPricingType,

        // flatRate
        amexFlatRate: tsysFlatRate?.transactionFees?.sale?.amex,
        visaMasterCardDiscoverCreditFlatRate:
          tsysFlatRate?.transactionFees?.sale?.visaMasterCardDiscoverCredit,
        visaMasterCardDiscoverDebitFlatRate:
          tsysFlatRate?.transactionFees?.sale?.visaMasterCardDiscoverDebit,

        // interchangePlus
        amexInterchangePlus: tsysInterchangePlus?.transactionFees?.sale?.amex,
        visaMasterCardDiscoverCreditInterchangePlus:
          tsysInterchangePlus?.transactionFees?.sale?.visaMasterCardDiscoverCredit,
        visaMasterCardDiscoverDebitInterchangePlus:
          tsysInterchangePlus?.transactionFees?.sale?.visaMasterCardDiscoverDebit,

        // tieredRates
        qualifiedAmexTieredRates: tsysTieredRates?.transactionFees?.sale?.qualifiedAmex,
        midQualifiedAmexTieredRates: tsysTieredRates?.transactionFees?.sale?.midQualifiedAmex,
        nonQualifiedAmexTieredRates: tsysTieredRates?.transactionFees?.sale?.nonQualifiedAmex,
        qualifiedVisaMasterCardDiscoverDebitTieredRates:
          tsysTieredRates?.transactionFees?.sale?.qualifiedVisaMasterCardDiscoverDebit,
        midQualifiedVisaMasterCardDiscoverDebitTieredRates:
          tsysTieredRates?.transactionFees?.sale?.midQualifiedVisaMasterCardDiscoverDebit,
        nonQualifiedVisaMasterCardDiscoverDebitTieredRates:
          tsysTieredRates?.transactionFees?.sale?.nonQualifiedVisaMasterCardDiscoverDebit,
        qualifiedVisaMasterCardDiscoverCreditTieredRates:
          tsysTieredRates?.transactionFees?.sale?.qualifiedVisaMasterCardDiscoverCredit,
        midQualifiedVisaMasterCardDiscoverCreditTieredRates:
          tsysTieredRates?.transactionFees?.sale?.midQualifiedVisaMasterCardDiscoverCredit,
        nonQualifiedVisaMasterCardDiscoverCreditTieredRates:
          tsysTieredRates?.transactionFees?.sale?.nonQualifiedVisaMasterCardDiscoverCredit,

        // ratesAndFees.transactionFees.authorization
        amexIpDialMxmIpAuth: tsysFeesAuth?.amexIpDialMxmIp,
        voiceAuth: tsysFeesAuth?.voice,
        pinDebitIpDialMxmIpAuth: tsysFeesAuth?.pinDebitIpDialMxmIp,
        ebtIpDialMxmIpAuth: tsysFeesAuth?.ebtIpDialMxmIp,
        visaMasterCardDiscoverIpDialMxmIpAuth: tsysFeesAuth?.visaMasterCardDiscoverIpDialMxmIp,

        // ratesAndFees.transactionFees.authorizationOther
        avsAuthOther: tsysFeesAuthOther?.avs,
        batchAuthOther: tsysFeesAuthOther?.batch,

        // ratesAndFees.transactionFees.sale
        visaMasterCarAmexPinDebitEbtSale: tsysFeesSale?.visaMasterCarAmexPinDebitEbt,
        pinDebitSale: tsysFeesSale?.pinDebit,

        // ratesAndFees.transactionFees.refund
        visaMasterCardDiscoverAmexRefund: tsysFeesRefund?.visaMasterCardDiscoverAmex
      },
      priorityOtherFeesSection: {
        // recurring
        industryComplianceMonthly: tsysOtherRecurring?.industryComplianceMonthly,
        wirelessMonthly: tsysOtherRecurring?.wirelessMonthly,
        miscellaneousMonthly: tsysOtherRecurring?.miscellaneousMonthly,
        minimumFeeMonthly: tsysOtherRecurring?.minimumFeeMonthly,
        serviceMonthly: tsysOtherRecurring?.serviceMonthly,
        pinDebitMonthly: tsysOtherRecurring?.pinDebitMonthly,
        serviceAnnually: tsysOtherRecurring?.serviceAnnually,
        industryComplianceAnnually: tsysOtherRecurring?.industryComplianceAnnually,

        // cardException
        chargeback: tsysOtherCardException?.chargeback,
        chargebackRetrievalRequest: tsysOtherCardException?.chargebackRetrievalRequest,

        // achExceptions
        achReturn: tsysOtherAchExceptions?.achReturn,

        // nonCompliance
        tin: tsysOtherNonCompliance?.tin,
        pciDss: tsysOtherNonCompliance?.pciDss,

        // thirdPartyFees
        micros: tsysOtherThirdParty?.micros?.transaction,

        // cancellation
        earlyTermination: tsysOtherCancellation?.earlyTermination
      }
    };
    return tsysFeesSections;
  }
  if (isPriorityFirstData) {
    const { pricingType, transactionFees, otherProcessingFees } =
      firstDataRatesAndFees?.ratesAndFees || {};

    const firstDataTransactionFees = { ...transactionFees };
    const firstDataFeesAuth = firstDataTransactionFees?.authorization;
    const firstDataFeesAuthOther = firstDataTransactionFees?.authorizationOther;
    const firstDataFeesSale = firstDataTransactionFees?.sale;
    const firstDataFeesRefund = firstDataTransactionFees?.refund;

    const firstDataPricingType = ignoreCase(pricingType || '');
    const firstDataFlatPricing = { ...firstDataFeesSale?.flatPricingRates };
    const firstDataInterchangePlus = { ...firstDataFeesSale?.interchangePlusRates };
    const firstDataTieredRates = { ...firstDataFeesSale?.tieredRates };

    const firstDataOtherFees = { ...otherProcessingFees };

    const firstDataFeesSections = {
      priorityRatesAndFeesSection: {
        pricingType: firstDataPricingType,

        // flatPricingRates
        visaMasterCardDiscoverDebitFlatPricing: firstDataFlatPricing?.visaMasterCardDiscoverDebit,
        americanExpressFlatPricing: firstDataFlatPricing?.americanExpress,
        visaMasterCardDiscoverCreditFlatPricing: firstDataFlatPricing?.visaMasterCardDiscoverCredit,

        // interchangePlusRates
        visaMasterCardDiscoverDebitInterchangePlus:
          firstDataInterchangePlus?.visaMasterCardDiscoverDebit,
        americanExpressInterchangePlus: firstDataInterchangePlus?.americanExpress,
        visaMasterCardDiscoverCreditInterchangePlus:
          firstDataInterchangePlus?.visaMasterCardDiscoverCredit,

        // tieredRates
        nonQualifiedVisaMasterCardDiscoverDebitTieredRates:
          firstDataTieredRates?.nonQualifiedVisaMasterCardDiscoverDebit,
        midQualifiedVisaMasterCardDiscoverDebitTieredRates:
          firstDataTieredRates?.midQualifiedVisaMasterCardDiscoverDebit,
        qualifiedVisaMasterCardDiscoverCreditTieredRates:
          firstDataTieredRates?.qualifiedVisaMasterCardDiscoverCredit,
        nonQualifiedAmericanExpressTieredRates: firstDataTieredRates?.nonQualifiedAmericanExpress,
        midQualifiedVisaMasterCardDiscoverCreditTieredRates:
          firstDataTieredRates?.midQualifiedVisaMasterCardDiscoverCredit,
        midQualifiedAmericanExpressTieredRates: firstDataTieredRates?.midQualifiedAmericanExpress,
        qualifiedVisaMasterCardDiscoverDebitTieredRates:
          firstDataTieredRates?.qualifiedVisaMasterCardDiscoverDebit,
        qualifiedAmericanExpressTieredRates: firstDataTieredRates?.qualifiedAmericanExpress,

        // authorization
        visaMasterCardDiscoverIpDialMxmIpAuth: firstDataFeesAuth?.visaMasterCardDiscoverIpDialMxmIp,
        ebtIpDialMxmIpAuth: firstDataFeesAuth?.ebtIpDialMxmIp,
        americanExpressIpDialMxmIpAuth: firstDataFeesAuth?.americanExpressIpDialMxmIp,
        pinDebitIpDialMxmIpAuth: firstDataFeesAuth?.pinDebitIpDialMxmIp,
        voiceAuth: firstDataFeesAuth?.voice,
        voyagerIpDialMxmIpAuth: firstDataFeesAuth?.voyagerIpDialMxmIp,

        // authorizationOther
        avsAuthOther: firstDataFeesAuthOther?.avs,
        batchAuthOther: firstDataFeesAuthOther?.batch,

        // sale
        voyagerSale: firstDataFeesSale?.voyager,
        pinDebitSale: firstDataFeesSale?.pinDebit,
        visaMasterCardDiscoverAmexPinDebitEbtVoyagerWexSale:
          firstDataFeesSale?.visaMasterCardDiscoverAmexPinDebitEbtVoyagerWex,

        // refund
        visaMasterCardDiscoverAmexPinDebitRefund:
          firstDataFeesRefund?.visaMasterCardDiscoverAmexPinDebit
      },
      priorityOtherFeesSection: {
        serviceMonthly: firstDataOtherFees?.serviceMonthly,
        miscellaneousMonthly: firstDataOtherFees?.miscellaneousMonthly,
        minimumFeeMonthly: firstDataOtherFees?.minimumFeeMonthly,
        industryComplianceMonthly: firstDataOtherFees?.industryComplianceMonthly,
        wirelessMonthly: firstDataOtherFees?.wirelessMonthly,
        pinDebitMonthly: firstDataOtherFees?.pinDebitMonthly,
        serviceAnnually: firstDataOtherFees?.serviceAnnually,
        chargebackRetrievalRequest: firstDataOtherFees?.chargebackRetrievalRequest,
        chargeback: firstDataOtherFees?.chargeback,
        achReturn: firstDataOtherFees?.achReturn,
        pciDss: firstDataOtherFees?.pciDss,
        tin: firstDataOtherFees?.tin,
        earlyTermination: firstDataOtherFees?.earlyTermination
      }
    };
    return firstDataFeesSections;
  }
  return {};
};

export const priorityFieldsBackend = (frontendSections, options) => {
  const {
    priorityBusinessInformationSection,
    priorityAchInfoAndFundingSection,
    priorityConfirmBillingAccountSection,
    priorityFeaturesSection,
    priorityPaymentProcessingAndSalesSection,
    priorityCardNotPresentSection,
    priorityBillingMethodSection,
    achInformationAndFundingChoicesSection = {},
    businessAccountNumberSection = {},
    corporateInformationSection = {}
  } = frontendSections || {};
  const {
    isPublicRequest, // Excludes `firstDataRatesAndFees` & `tsysRatesAndFees` objects
    onlyUseFeeData,
    relationship
  } = options || {};
  const isTsys = isPriorityTsysBank(relationship);
  const businessInfoData = { ...priorityBusinessInformationSection?.valuesForBackend };
  const achInfoAndFundingData = { ...priorityAchInfoAndFundingSection?.valuesForBackend };
  const confirmFundingAccountData = { ...priorityConfirmBillingAccountSection?.valuesForBackend };
  const featuresData = { ...priorityFeaturesSection?.valuesForBackend };
  const cardNotPresentData = { ...priorityCardNotPresentSection?.valuesForBackend };
  const useCardNotPresent = cardNotPresentData?.useCardNotPresent === true;
  const billingMethodData = { ...priorityBillingMethodSection?.valuesForBackend };
  const achInformationAndFundingChoicesData = {
    ...achInformationAndFundingChoicesSection.valuesForBackend
  };
  const businessAccountNumberData = { ...businessAccountNumberSection.valuesForBackend };
  const corporateInformationData = { ...corporateInformationSection.valuesForBackend };
  const billingAccount = {
    ...(achInfoAndFundingData.billingSameAsPrimary
      ? {
          bankName: achInformationAndFundingChoicesData?.bankName,
          routingNumber: businessAccountNumberData?.routingNumber,
          accountNumber: businessAccountNumberData?.accountNumber,
          nameOnAccount: achInformationAndFundingChoicesData?.nameOnAccountSameAsLegalName
            ? corporateInformationData?.legalBusinessName
            : achInformationAndFundingChoicesData?.nameOnAccount
        }
      : {
          nameOnAccount: achInfoAndFundingData?.billingAccountNameOnAccount,
          routingNumber: confirmFundingAccountData?.routingNumber,
          accountNumber: confirmFundingAccountData?.accountNumber,
          bankName: achInfoAndFundingData?.billingAccountBankName
        })
  };
  const paymentProcessingAndSalesData = {
    ...priorityPaymentProcessingAndSalesSection?.valuesForBackend
  };
  const hasPriorBankruptcy = businessInfoData?.priorBankruptcy;
  const wasTerminated = paymentProcessingAndSalesData?.everTerminated;
  const includePriorProcessor = paymentProcessingAndSalesData?.hadPriorProcessor;
  const backendPriorityFees = isPublicRequest
    ? {}
    : getPriorityFeesBackend(frontendSections, options);
  if (onlyUseFeeData) {
    return { priorityFields: cleanFieldsForBackend(backendPriorityFees, options) };
  }
  const backendPriorityFields = {
    priorityFields: {
      businessInformation: {
        entityFormationDate: businessInfoData?.entityFormationDate,
        lengthOfCurrentOwnership: businessInfoData?.lengthOfCurrentOwnership,
        priorBankruptcy: hasPriorBankruptcy,
        dischargeDate: hasPriorBankruptcy ? businessInfoData?.dischargeDate : null
      },
      location: {
        locationDetails: {
          marketSegment: businessInfoData?.marketSegment,
          businessEstablished: businessInfoData?.businessEstablished
        }
      },
      achInfoAndFunding: {
        ...(isTsys && {
          // this is required if this is for TSYS and this is not allowed if this is for First Data
          billingAccount: {
            ...billingAccount,
            accountType: achInfoAndFundingData?.billingAccountType,
            customDescription: achInfoAndFundingData?.billingAccountCustomDescription
          }
        }),
        fundingAccount: {
          accountType: achInfoAndFundingData?.fundingAccountAccountType,
          customDescription: achInfoAndFundingData?.fundingAccountCustomDescription
        }
      },
      features: getPriorityFeaturesBackend(featuresData, options),
      paymentProcessingAndSales: {
        futureDelivery: paymentProcessingAndSalesData?.futureDelivery,
        ebtFoodStFnsNumber: featuresData?.basic?.ebtFoodStamp
          ? featuresData?.ebtFoodStFnsNumber
          : null,
        amexDirectEsaNumber: paymentProcessingAndSalesData?.amexDirectEsaNumber,
        welcomeKit: paymentProcessingAndSalesData?.welcomeKit,
        statementBoarding: paymentProcessingAndSalesData?.statementBoarding,
        acceptMajorCardNetwork: paymentProcessingAndSalesData?.acceptMajorCardNetwork,
        priorBreach: paymentProcessingAndSalesData?.priorBreach,
        terminationInformation: {
          everTerminated: wasTerminated,
          terminationReason: wasTerminated ? paymentProcessingAndSalesData?.terminationReason : null
        },
        ...(includePriorProcessor && {
          priorProcessorInformation: {
            processorName: paymentProcessingAndSalesData?.processorName,
            reasonForLeaving: paymentProcessingAndSalesData?.reasonForLeaving
          }
        })
      },
      cardNotPresentQuestionnaire: useCardNotPresent
        ? {
            internetBusinessType: cardNotPresentData?.internetBusinessType,
            advertisingMethod: cardNotPresentData?.advertisingMethod,
            billingMethod: {
              monthly: billingMethodData?.monthly,
              yearly: billingMethodData?.yearly,
              oneTime: billingMethodData?.oneTime
            },
            supplyFulfillmentVendor: {
              type: cardNotPresentData?.supplyFulfillmentVendorType,
              name: cardNotPresentData?.supplyFulfillmentVendorName,
              phoneNumber: cardNotPresentData?.supplyFulfillmentVendorPhoneNumber
            }
          }
        : undefined,
      ...backendPriorityFees
    }
  };
  return cleanFieldsForBackend(backendPriorityFields, options);
};

const getPriorityFeaturesBackend = (valuesForBackend, options) => {
  const { isPublicRequest, relationship } = options || {};
  /**
   * For public requests with all checkboxes unchecked (meaning the backend value is `null`),
   * we need to format these to an object with `false` values.
   * Eg. { basic: { visaCredit: false, visaDebit: false, ... } }
   */
  return {
    basic:
      isPublicRequest && isEmpty(valuesForBackend.basic)
        ? customBackendFormats.checkboxList([], basePriorityFields.basic)
        : valuesForBackend.basic,
    advanced:
      isPublicRequest && isEmpty(valuesForBackend.advanced)
        ? customBackendFormats.checkboxList([], basePriorityFields.advanced(relationship))
        : valuesForBackend.advanced,
    partnerOnly:
      isPublicRequest && isEmpty(valuesForBackend.partnerOnly)
        ? customBackendFormats.checkboxList([], basePriorityFields.partnerOnly(relationship))
        : valuesForBackend.partnerOnly,
    // sponsorBanks must have a valid selection (cannot send all values as `false`)
    sponsorBanks: valuesForBackend.sponsorBanks
  };
};

const getPriorityFeesBackend = (frontendSections, options) => {
  const { priorityRatesAndFeesSection, priorityOtherFeesSection } = frontendSections || {};
  const { relationship } = options || {};
  const isPriorityTsys = isPriorityTsysBank(relationship);
  const isPriorityFirstData = isPriorityFirstDataBank(relationship);
  if (isPriorityTsys) {
    const tsysRatesAndFeesData = { ...priorityRatesAndFeesSection?.valuesForBackend };
    const tsysOtherFeesData = { ...priorityOtherFeesSection?.valuesForBackend };
    const tsysPricingType = tsysRatesAndFeesData?.pricingType;
    const tsysPricingRatesData = {
      flatRate:
        tsysPricingType === 'flat_pricing'
          ? {
              transactionFees: {
                sale: {
                  amex: tsysRatesAndFeesData?.amexFlatRate,
                  visaMasterCardDiscoverCredit:
                    tsysRatesAndFeesData?.visaMasterCardDiscoverCreditFlatRate,
                  visaMasterCardDiscoverDebit:
                    tsysRatesAndFeesData?.visaMasterCardDiscoverDebitFlatRate
                }
              }
            }
          : null,
      interchangePlus:
        tsysPricingType === 'interchange_plus'
          ? {
              transactionFees: {
                sale: {
                  amex: tsysRatesAndFeesData?.amexInterchangePlus,
                  visaMasterCardDiscoverCredit:
                    tsysRatesAndFeesData?.visaMasterCardDiscoverCreditInterchangePlus,
                  visaMasterCardDiscoverDebit:
                    tsysRatesAndFeesData?.visaMasterCardDiscoverDebitInterchangePlus
                }
              }
            }
          : null,
      tieredRates:
        tsysPricingType === 'tiered_rates'
          ? {
              transactionFees: {
                sale: {
                  qualifiedAmex: tsysRatesAndFeesData?.qualifiedAmexTieredRates,
                  midQualifiedAmex: tsysRatesAndFeesData?.midQualifiedAmexTieredRates,
                  nonQualifiedAmex: tsysRatesAndFeesData?.nonQualifiedAmexTieredRates,
                  qualifiedVisaMasterCardDiscoverDebit:
                    tsysRatesAndFeesData?.qualifiedVisaMasterCardDiscoverDebitTieredRates,
                  midQualifiedVisaMasterCardDiscoverDebit:
                    tsysRatesAndFeesData?.midQualifiedVisaMasterCardDiscoverDebitTieredRates,
                  nonQualifiedVisaMasterCardDiscoverDebit:
                    tsysRatesAndFeesData?.nonQualifiedVisaMasterCardDiscoverDebitTieredRates,
                  qualifiedVisaMasterCardDiscoverCredit:
                    tsysRatesAndFeesData?.qualifiedVisaMasterCardDiscoverCreditTieredRates,
                  midQualifiedVisaMasterCardDiscoverCredit:
                    tsysRatesAndFeesData?.midQualifiedVisaMasterCardDiscoverCreditTieredRates,
                  nonQualifiedVisaMasterCardDiscoverCredit:
                    tsysRatesAndFeesData?.nonQualifiedVisaMasterCardDiscoverCreditTieredRates
                }
              }
            }
          : null
    };
    const backendFeesPriorityTsys = {
      tsysRatesAndFees: {
        ratesAndFees: {
          transactionFees: {
            authorization: {
              amexIpDialMxmIp: tsysRatesAndFeesData?.amexIpDialMxmIpAuth,
              voice: tsysRatesAndFeesData?.voiceAuth,
              pinDebitIpDialMxmIp: tsysRatesAndFeesData?.pinDebitIpDialMxmIpAuth,
              ebtIpDialMxmIp: tsysRatesAndFeesData?.ebtIpDialMxmIpAuth,
              visaMasterCardDiscoverIpDialMxmIp:
                tsysRatesAndFeesData?.visaMasterCardDiscoverIpDialMxmIpAuth
            },
            authorizationOther: {
              avs: tsysRatesAndFeesData?.avsAuthOther,
              batch: tsysRatesAndFeesData?.batchAuthOther
            },
            sale: {
              visaMasterCarAmexPinDebitEbt: tsysRatesAndFeesData?.visaMasterCarAmexPinDebitEbtSale,
              pinDebit: tsysRatesAndFeesData?.pinDebitSale
            },
            refund: {
              visaMasterCardDiscoverAmex: tsysRatesAndFeesData?.visaMasterCardDiscoverAmexRefund
            }
          },
          otherProcessingFees: {
            recurring: {
              industryComplianceMonthly: tsysOtherFeesData?.industryComplianceMonthly,
              wirelessMonthly: tsysOtherFeesData?.wirelessMonthly,
              miscellaneousMonthly: tsysOtherFeesData?.miscellaneousMonthly,
              minimumFeeMonthly: tsysOtherFeesData?.minimumFeeMonthly,
              serviceMonthly: tsysOtherFeesData?.serviceMonthly,
              pinDebitMonthly: tsysOtherFeesData?.pinDebitMonthly,
              serviceAnnually: tsysOtherFeesData?.serviceAnnually,
              industryComplianceAnnually: tsysOtherFeesData?.industryComplianceAnnually
            },
            cardException: {
              chargeback: tsysOtherFeesData?.chargeback,
              chargebackRetrievalRequest: tsysOtherFeesData?.chargebackRetrievalRequest
            },
            achExceptions: {
              achReturn: tsysOtherFeesData?.achReturn
            },
            nonCompliance: {
              tin: tsysOtherFeesData?.tin,
              pciDss: tsysOtherFeesData?.pciDss
            },
            thirdPartyFees: {
              micros: {
                transaction: tsysOtherFeesData?.micros
              }
            },
            cancellation: {
              earlyTermination: tsysOtherFeesData?.earlyTermination
            }
          },
          pricingType: tsysPricingType,
          ...tsysPricingRatesData
        }
      }
    };
    return backendFeesPriorityTsys;
  }
  if (isPriorityFirstData) {
    const firstDataRatesAndFeesData = { ...priorityRatesAndFeesSection?.valuesForBackend };
    const firstDataOtherFeesData = { ...priorityOtherFeesSection?.valuesForBackend };
    const firstDataPricingType = firstDataRatesAndFeesData?.pricingType;
    const firstDataPricingRatesData = {
      flatPricingRates:
        firstDataPricingType === 'flat_pricing'
          ? {
              visaMasterCardDiscoverDebit:
                firstDataRatesAndFeesData?.visaMasterCardDiscoverDebitFlatPricing,
              americanExpress: firstDataRatesAndFeesData?.americanExpressFlatPricing,
              visaMasterCardDiscoverCredit:
                firstDataRatesAndFeesData?.visaMasterCardDiscoverCreditFlatPricing
            }
          : null,
      interchangePlusRates:
        firstDataPricingType === 'interchange_plus'
          ? {
              visaMasterCardDiscoverDebit:
                firstDataRatesAndFeesData?.visaMasterCardDiscoverDebitInterchangePlus,
              americanExpress: firstDataRatesAndFeesData?.americanExpressInterchangePlus,
              visaMasterCardDiscoverCredit:
                firstDataRatesAndFeesData?.visaMasterCardDiscoverCreditInterchangePlus
            }
          : null,
      tieredRates:
        firstDataPricingType === 'tiered_rates'
          ? {
              nonQualifiedVisaMasterCardDiscoverDebit:
                firstDataRatesAndFeesData?.nonQualifiedVisaMasterCardDiscoverDebitTieredRates,
              midQualifiedVisaMasterCardDiscoverDebit:
                firstDataRatesAndFeesData?.midQualifiedVisaMasterCardDiscoverDebitTieredRates,
              qualifiedVisaMasterCardDiscoverCredit:
                firstDataRatesAndFeesData?.qualifiedVisaMasterCardDiscoverCreditTieredRates,
              nonQualifiedAmericanExpress:
                firstDataRatesAndFeesData?.nonQualifiedAmericanExpressTieredRates,
              midQualifiedVisaMasterCardDiscoverCredit:
                firstDataRatesAndFeesData?.midQualifiedVisaMasterCardDiscoverCreditTieredRates,
              midQualifiedAmericanExpress:
                firstDataRatesAndFeesData?.midQualifiedAmericanExpressTieredRates,
              qualifiedVisaMasterCardDiscoverDebit:
                firstDataRatesAndFeesData?.qualifiedVisaMasterCardDiscoverDebitTieredRates,
              qualifiedAmericanExpress:
                firstDataRatesAndFeesData?.qualifiedAmericanExpressTieredRates
            }
          : null
    };
    const backendFeesPriorityFirstData = {
      firstDataRatesAndFees: {
        ratesAndFees: {
          pricingType: firstDataPricingType,
          transactionFees: {
            authorization: {
              visaMasterCardDiscoverIpDialMxmIp:
                firstDataRatesAndFeesData?.visaMasterCardDiscoverIpDialMxmIpAuth,
              ebtIpDialMxmIp: firstDataRatesAndFeesData?.ebtIpDialMxmIpAuth,
              americanExpressIpDialMxmIp: firstDataRatesAndFeesData?.americanExpressIpDialMxmIpAuth,
              pinDebitIpDialMxmIp: firstDataRatesAndFeesData?.pinDebitIpDialMxmIpAuth,
              voice: firstDataRatesAndFeesData?.voiceAuth,
              voyagerIpDialMxmIp: firstDataRatesAndFeesData?.voyagerIpDialMxmIpAuth
            },
            authorizationOther: {
              avs: firstDataRatesAndFeesData?.avsAuthOther,
              batch: firstDataRatesAndFeesData?.batchAuthOther
            },
            sale: {
              voyager: firstDataRatesAndFeesData?.voyagerSale,
              pinDebit: firstDataRatesAndFeesData?.pinDebitSale,
              visaMasterCardDiscoverAmexPinDebitEbtVoyagerWex:
                firstDataRatesAndFeesData?.visaMasterCardDiscoverAmexPinDebitEbtVoyagerWexSale,
              ...firstDataPricingRatesData
            },
            refund: {
              visaMasterCardDiscoverAmexPinDebit:
                firstDataRatesAndFeesData?.visaMasterCardDiscoverAmexPinDebitRefund
            }
          },
          otherProcessingFees: {
            serviceMonthly: firstDataOtherFeesData?.serviceMonthly,
            miscellaneousMonthly: firstDataOtherFeesData?.miscellaneousMonthly,
            minimumFeeMonthly: firstDataOtherFeesData?.minimumFeeMonthly,
            industryComplianceMonthly: firstDataOtherFeesData?.industryComplianceMonthly,
            wirelessMonthly: firstDataOtherFeesData?.wirelessMonthly,
            pinDebitMonthly: firstDataOtherFeesData?.pinDebitMonthly,
            serviceAnnually: firstDataOtherFeesData?.serviceAnnually,
            chargebackRetrievalRequest: firstDataOtherFeesData?.chargebackRetrievalRequest,
            chargeback: firstDataOtherFeesData?.chargeback,
            achReturn: firstDataOtherFeesData?.achReturn,
            pciDss: firstDataOtherFeesData?.pciDss,
            tin: firstDataOtherFeesData?.tin,
            earlyTermination: firstDataOtherFeesData?.earlyTermination
          }
        }
      }
    };
    return backendFeesPriorityFirstData;
  }
  return {};
};

export const priorityTabFormFields = {
  axiom: { ...priorityFormFields({ processName: 'priority', bankName: 'axiom' }) },
  pbt: { ...priorityFormFields({ processName: 'priority', bankName: 'pueblo_bank_and_trust' }) },
  synovus: { ...priorityFormFields({ processName: 'priority', bankName: 'synovus' }) },
  wellsFargo: { ...priorityFormFields({ processName: 'priority', bankName: 'wells_fargo' }) }
};
