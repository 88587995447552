export const initialState = {
  barStyle: 'closed',
  message: '',
  timeout: true
};

function alertBar(state = initialState, action) {
  switch (action.type) {
    case 'ALERT_BAR':
      return {
        ...state,
        barStyle: action.barStyle,
        message: action.message,
        ...(typeof action.timeout === 'boolean' && {
          timeout: action.timeout
        })
      };
    default:
      return state;
  }
}

export default alertBar;
