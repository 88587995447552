import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { generatePossibleMatch, createMockMatchResults } from './mockMatchPost';

export const createMockPrincipals = (options) => {
  const { length } = options || {};
  const mockPrincipals = Array.from({ length: length || 3 }).map(() => {
    const mockUser = getMockValue({ type: 'user' });
    const mockDriversLicenseAddress = getMockValue({ type: 'address' });
    const mockAddress = getMockValue({ type: 'address' });
    return {
      firstName: mockUser.firstName,
      middleInitial: getMockValue({ type: 'boolean' }) ? 'A' : null,
      lastName: mockUser.lastName,
      phoneNumber: '2223334444',
      altPhoneNumber: '9998887777',
      taxId: getMockValue({ type: 'ssn' }),
      driversLicense: {
        number: getMockValue({ stringify: true }),
        stateCode: mockDriversLicenseAddress.stateCode,
        country: '840'
      },
      address: {
        addressLine1: mockAddress.fullAddress,
        addressLine2: getMockValue({ type: 'string' }),
        city: mockAddress.cityName,
        stateCode: mockAddress.stateCode,
        province: getMockValue({ type: 'string' }),
        postalCode: mockAddress.zipCode,
        country: 'US'
      }
    };
  });
  return mockPrincipals;
};

export const createMockMatchRequest = (merchant) => ({
  callMatch: getMockValue({ type: 'list', list: ['default', 'force', 'cache'] }),
  acquirerId: getMockValue({ min: 10, max: 100, stringify: true }),
  merchant
});

export const createMockMatchGetData = (guid) => {
  const mockSearchAll = getMockValue({ type: 'list', list: ['N', 'Y'] });
  const mockMerchantInput = {
    urlGroup: Array.from({ length: getMockValue({ min: 1, max: 3 }) }).map(() => ({
      exactMatchUrls: ['https://breakingTheLaw.com'],
      closeMatchUrls: ['https://breakingTheLaw2.com'],
      noMatchUrls: [getMockValue({ type: 'url' })]
    })),
    searchCriteria: {
      searchAll: mockSearchAll,
      region: mockSearchAll === 'N' ? undefined : ['NA'],
      country: mockSearchAll === 'N' ? undefined : ['US'],
      minPossibleMatchCount: getMockValue()
    },
    principal: createMockPrincipals({ length: 3 })
  };
  const mockMerchantResult = generatePossibleMatch(mockMerchantInput);
  const mockMatchResults = createMockMatchResults(mockMerchantResult);
  const mockMatchData = {
    applicationMpaId: guid,
    matchRequest: createMockMatchRequest(mockMerchantResult),
    matchResults: mockMatchResults
  };
  return mockMatchData;
};

export const mockMatchGetRes = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: createMockMatchGetData(guid) };
};

export const mockV2MatchPost = (body, params, options) => {
  const { guid, type } = options || {};
  const mockMatchList = Array.from({ length: guid === '1' ? 1 : 2 }).map((element, index) => {
    const mockSearchAll = getMockValue({ type: 'list', list: ['N', 'Y'] });
    const mockMerchantInput = {
      urlGroup: Array.from({ length: getMockValue({ min: 1, max: 3 }) }).map(() => ({
        exactMatchUrls: ['https://breakingTheLaw.com'],
        closeMatchUrls: ['https://breakingTheLaw2.com'],
        noMatchUrls: [getMockValue({ type: 'url' })]
      })),
      searchCriteria: {
        searchAll: mockSearchAll,
        region: mockSearchAll === 'N' ? undefined : ['NA'],
        country: mockSearchAll === 'N' ? undefined : ['US'],
        minPossibleMatchCount: getMockValue()
      },
      principal: createMockPrincipals({ length: 3 })
    };
    const mockMerchantResult = generatePossibleMatch(mockMerchantInput);
    const mockMatchResults = createMockMatchResults(mockMerchantResult);
    return {
      ...(type === 'merchant' && {
        retroMatchPull: index === 0,
        retroMatchReferenceNumber:
          index === 0
            ? getMockValue({
                type: 'number',
                min: 10000,
                max: 99999,
                stringify: true
              })
            : null
      }),
      matchRequest: createMockMatchRequest(mockMerchantResult),
      matchResults: mockMatchResults
    };
  });
  return {
    [guid]: {
      applicationMpaId: guid,
      resultList: mockMatchList
    }
  };
};

export const mockMatchMerchantV2 = (body, params, options) => {
  const { guid } = options || {};
  const { [guid]: mockData } = mockV2MatchPost(body, params, { ...options, type: 'merchant' });
  return { [guid]: { resultList: mockData.resultList } };
};
