import { merchantGuids, partnerGuids, relationshipGuids, ticketGuids } from '../guids';

const mockNotePut = (body, params, options) => {
  const { guid = 'empty' } = options;
  return {
    [guid]: {
      noteIds: [
        {
          // used in FTs
          noteDescription: 'this is a mock note 1',
          allowedEmployeeGroup: null,
          guidType: 'merchantGuid',
          guidValue: merchantGuids.default,
          noteId: 'mock-note-id-1'
        },
        {
          noteDescription: 'this is a mock note 2',
          allowedEmployeeGroup: null,
          guidType: 'relationshipId',
          guidValue: relationshipGuids.default,
          noteId: 'mock-note-id-2'
        },
        {
          noteDescription: 'this is a mock note 3',
          allowedEmployeeGroup: null,
          guidType: 'partnerId',
          guidValue: partnerGuids.default,
          noteId: 'mock-note-id-3'
        },
        {
          noteDescription: 'this is a mock note 4',
          allowedEmployeeGroup: null,
          guidType: 'ticketId',
          guidValue: ticketGuids.default,
          noteId: 'mock-note-id-4'
        }
      ]
    }
  };
};

export default mockNotePut;
