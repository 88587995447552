import React from 'react';
import PropTypes from 'prop-types';
import { modalCSS } from './_styles';
import { isEmpty, isEqual } from './_helpers';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      modalTitle: '',
      showModal: false,
      modalContent: null,
      options: undefined
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { store } = this.props;
    store.subscribe(this.handleStoreChanges);
    window.addEventListener('keyup', this.handleKeyUp);
    this.handleStoreChanges();
  }

  componentWillUnmount() {
    const { store } = this.props;
    store && store.subscribe && store.subscribe(this.handleStoreChanges);
    window.removeEventListener('keyup', this.handleKeyUp);
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  handleStoreChanges = () => {
    const { store } = this.props;
    const { modalTitle: prevModalTitle, showModal: prevShowModal } = this.state;
    const { siteModal: { modalTitle, showModal, modalContent, options } = {} } = store.getState();
    if (
      !isEqual(prevShowModal, showModal) ||
      // modal already open, but modal data changed
      (showModal &&
        !isEqual(prevModalTitle, modalTitle) &&
        (!isEmpty(prevModalTitle) || !isEmpty(modalTitle)))
    ) {
      this.updateState(
        {
          modalTitle,
          showModal,
          modalContent,
          options
        },
        () => {
          store.dispatch({
            type: 'MODAL',
            modalTitle,
            showModal,
            modalContent,
            options
          });
          document.querySelector('body').style.overflow = showModal ? 'hidden' : 'auto';
        }
      );
    }
  };

  handleOutsideClick = (e) => {
    const { options } = this.state;
    const { closeOnOutsideClick = true } = options || {};
    e && e.target.getAttribute('id') === 'siteModal' && closeOnOutsideClick && this.handleClose(e);
  };

  handleKeyUp = (event) => event && event.keyCode === 27 && this.handleClose();

  handleClose = () => {
    const { options } = this.state;
    const { store } = this.props;
    const { closeModalCallback } = options || {};
    document.querySelector('body').style.overflow = 'auto';
    store.dispatch({
      type: 'MODAL',
      showModal: false,
      modalTitle: '',
      modalContent: null
    });
    closeModalCallback && closeModalCallback();
  };

  render() {
    const { modalContent, showModal, modalTitle, options } = this.state;
    const classNames = [
      ...(showModal ? ['active'] : []),
      ...(!isEmpty(options) && options.size ? [options.size] : [])
    ];
    return (
      <div
        id="siteModal"
        className={classNames.length ? classNames.join(' ') : ''}
        style={{
          ...modalCSS.outerWrap,
          ...(showModal && modalCSS.active)
        }}
        role="menuitem"
        tabIndex="0"
        onMouseDown={this.handleOutsideClick}
        onKeyDown={this.handleOutsideClick}>
        <div
          className="innerWrap"
          style={{
            ...modalCSS.innerWrap,
            ...(showModal && modalCSS.active)
          }}>
          <div className="modalHeader" style={modalCSS.header}>
            <Icon
              icon={icons.close.src_color}
              $useMask
              role="menuitem"
              tabIndex="0"
              className="modalClose"
              aria-label="Close Modal"
              style={modalCSS.close}
              onMouseDown={this.handleClose}
              onKeyDown={this.handleClose}
            />
            <span role="heading" aria-level="2">
              {modalTitle}
            </span>
          </div>
          <div className="modalContent" style={modalCSS.content}>
            {!isEmpty(modalContent) && React.cloneElement(modalContent)}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func,
    getState: PropTypes.func,
    siteModal: PropTypes.shape({
      showModal: PropTypes.bool,
      modalContent: PropTypes.element,
      modalTitle: PropTypes.string,
      options: PropTypes.oneOfType([PropTypes.object])
    }),
    subscribe: PropTypes.func
  })
};

Modal.defaultProps = {
  store: {}
};

export default Modal;
