import { crabFeesOnlyFields } from '../../../_crabFields';
import { buildPriorityTabData } from '../../../_crabPriorityHelpers';
import { buildRepayTabData, buildNeteviaTabData } from './sharedBoardingTemplate';

export const merchantFeesTemplate = {
  frontendRepayFees: (schema, version) => {
    if (version === '1.0') {
      const { backendData, options } = schema || {};
      const { isEmployee, riskLevel } = options || {};
      const { ratesAndFees } = backendData || {};
      const dataToFormat = isEmployee ? backendData : { repayFields: { ratesAndFees } };
      const feesOnlyFormFields = crabFeesOnlyFields({
        riskProfile: riskLevel,
        processName: 'repay'
      });
      const formatOptions = {
        ...options,
        useNewFormat: true,
        onlyUseFeeData: true,
        tabFormFields: { repay: feesOnlyFormFields }
      };
      const repayData = buildRepayTabData(dataToFormat, formatOptions);
      return repayData;
    }
    return schema;
  },
  frontendNeteviaFees: (schema, version) => {
    if (version === '1.0') {
      const { backendData, options } = schema || {};
      const { isEmployee, riskLevel } = options || {};
      const { ratesAndFees, neteviaFees } = backendData || {};
      const dataToFormat = isEmployee
        ? backendData
        : { neteviaFields: { ratesAndFees, fees: neteviaFees } };
      const feesOnlyFormFields = crabFeesOnlyFields({
        riskProfile: riskLevel,
        processName: 'netevia'
      });
      const formatOptions = {
        ...options,
        onlyUseFeeData: true,
        tabFormFields: { netevia: feesOnlyFormFields }
      };
      const neteviaData = buildNeteviaTabData(dataToFormat, formatOptions);
      return neteviaData;
    }
    return schema;
  },
  frontendPriorityFees: (schema, version) => {
    if (version === '1.0') {
      const { backendData, options } = schema || {};
      const { bankName, riskLevel } = options || {};
      const dataToFormat = { ...backendData };
      const feesOnlyFormFields = crabFeesOnlyFields({
        bankName,
        riskProfile: riskLevel,
        processName: 'priority'
      });
      const formatOptions = {
        ...options,
        onlyUseFeeData: true,
        tabFormFields: { priority: feesOnlyFormFields }
      };
      const priorityData = buildPriorityTabData(dataToFormat, formatOptions);
      return priorityData;
    }
    return schema;
  }
};

export default merchantFeesTemplate;
