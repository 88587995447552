export const initialState = {
  merchantGuid: {
    guid: '',
    dba: '',
    legalName: '',
    partnerId: '',
    relationshipId: '',
    relationshipName: ''
  },
  relationshipId: {
    guid: '',
    dba: ''
  },
  relationshipIdList: [{ guid: '', dba: '' }],
  partnerId: {
    guid: '',
    dba: ''
  },
  currentBankName: {
    title: '',
    value: ''
  },
  currentProcessorName: {
    title: '',
    value: ''
  },
  currentStatus: {
    title: 'All Open',
    value: 'all_open'
  },
  currentRiskCategory: {
    title: '',
    value: ''
  },
  currentRiskSubcategory: {
    title: '',
    value: ''
  },
  currentRiskEmployee: {
    title: '',
    value: ''
  },
  currentRiskRule: {
    title: '',
    value: ''
  },
  currentPriority: {
    title: '',
    value: ''
  },
  currentName: {
    title: '',
    value: ''
  },
  currentDbaName: {
    title: '',
    value: ''
  },
  currentRequiresExtraMonitoring: {
    title: '',
    value: ''
  }
};

function guidUpdate(state = initialState, action) {
  switch (action.type) {
    case 'GUID_UPDATE':
      if (action.merchantGuid) {
        return {
          ...state,
          merchantGuid: {
            ...state.merchantGuid,
            ...action.merchantGuid
          }
        };
      }
      if (action.relationshipId) {
        return {
          ...state,
          relationshipId: {
            ...state.relationshipId,
            ...action.relationshipId
          }
        };
      }
      if (action.relationshipIdList) {
        return {
          ...state,
          relationshipIdList: action.relationshipIdList
        };
      }
      if (action.partnerId) {
        return {
          ...state,
          partnerId: {
            ...state.partnerId,
            ...action.partnerId
          }
        };
      }
      if (action.currentBankName) {
        return {
          ...state,
          currentBankName: {
            ...action.currentBankName
          }
        };
      }
      if (action.currentProcessorName) {
        return {
          ...state,
          currentProcessorName: {
            ...action.currentProcessorName
          }
        };
      }
      if (action.currentStatus) {
        return {
          ...state,
          currentStatus: {
            ...action.currentStatus
          }
        };
      }
      if (action.currentRiskCategory) {
        return {
          ...state,
          currentRiskCategory: {
            ...action.currentRiskCategory
          }
        };
      }
      if (action.currentRiskSubcategory) {
        return {
          ...state,
          currentRiskSubcategory: {
            ...action.currentRiskSubcategory
          }
        };
      }
      if (action.currentRiskEmployee) {
        return {
          ...state,
          currentRiskEmployee: {
            ...action.currentRiskEmployee
          }
        };
      }
      if (action.currentRiskRule) {
        return {
          ...state,
          currentRiskRule: {
            ...action.currentRiskRule
          }
        };
      }
      if (action.currentPriority) {
        return {
          ...state,
          currentPriority: {
            ...action.currentPriority
          }
        };
      }
      if (action.currentName) {
        return {
          ...state,
          currentName: {
            ...action.currentName
          }
        };
      }
      if (action.currentRequiresExtraMonitoring) {
        return {
          ...state,
          currentRequiresExtraMonitoring: {
            ...action.currentRequiresExtraMonitoring
          }
        };
      }
      if (action.currentDbaName) {
        return {
          ...state,
          currentDbaName: {
            ...action.currentDbaName
          }
        };
      }
      return {
        ...state,
        merchantGuid: {
          ...state.merchantGuid
        },
        relationshipId: {
          ...state.relationshipId
        },
        relationshipIdList: state.relationshipIdList,
        partnerId: {
          ...state.partnerId
        }
      };
    default:
      return state;
  }
}

export default guidUpdate;
