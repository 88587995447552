// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { isEqual, isEmpty, getValidatedFiles } from './_helpers';
import { cloud } from '../images/_icons';
import { uploadCSS, dropzoneCSS } from './_styles';

export class Dropzone extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.fileInputRef = React.createRef();
    this.allFiles = {};
    this.filesToUpload = {};
    this.state = {
      dropAreaText: null,
      subText: null,
      existingFileNames: [],
      highlight: false,
      uploading: false,
      successfulUploaded: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('paste', this.handlePaste, false);
    this.setDropAreaText();
    this.setExistingFiles();
  }

  componentDidUpdate(prevProps) {
    const { fileRemoved, existingFiles } = this.props;
    if (!isEqual(prevProps.fileRemoved, fileRemoved)) {
      this.handleRemoveFile();
    }
    if (JSON.stringify(existingFiles) !== JSON.stringify(prevProps.existingFiles)) {
      this.setExistingFiles();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this.handlePaste, false);
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  setDropAreaText = () => {
    const { customDropAreaText, customSubText, size } = this.props;
    let displayText = 'Paste or drag files here, or click here to find a file.';
    if (!isEmpty(customDropAreaText)) {
      displayText = customDropAreaText.slice();
    } else if (size === 'small') {
      displayText = 'Add File +';
    }
    if (!isEmpty(customSubText)) {
      this.updateState({ subText: customSubText });
    }
    this.updateState({ dropAreaText: displayText });
  };

  setExistingFiles = () => {
    const { existingFiles } = this.props;
    this.updateState({
      existingFileNames: (existingFiles || []).map((file) => file.name || file.fileName)
    });
  };

  handleRemoveFile = () => {
    const { fileRemoved } = this.props;
    const currentFiles = { ...this.filesToUpload };
    let newFilesObject = currentFiles[fileRemoved]
      ? Object.entries(currentFiles)
          .filter(([fileName, _file]) => fileName !== fileRemoved)
          .reduce((acc, [fileName, file]) => ({ ...acc, [fileName]: file }), {})
      : currentFiles;
    if (Array.isArray(fileRemoved)) {
      newFilesObject = Object.entries(currentFiles)
        .filter(([fileName, _file]) => fileRemoved.includes(fileName))
        .reduce((acc, [fileName, file]) => ({ ...acc, [fileName]: file }), {});
    }
    this.filesToUpload = newFilesObject;
    this.allFiles = newFilesObject;
    this.updateState({ uploadProgress: newFilesObject });
    this.handleFileChange({ removingMultipleFiles: Array.isArray(fileRemoved), fileRemoved: '' });
  };

  openFileDialog = () => {
    const { uploading, successfulUploaded } = this.state;
    if (uploading || successfulUploaded) return;
    this.fileInputRef?.current && this.fileInputRef.current.click();
  };

  fileListToArray = (list) => {
    const { singleFileUpload } = this.props;
    const array = [];
    if (singleFileUpload) {
      array.push(list[0]);
    } else {
      for (let i = 0; i < list.length; i += 1) {
        array.push(list.item(i));
      }
    }
    return array;
  };

  onDragOver = (e) => {
    const { uploading, successfulUploaded } = this.state;
    e.preventDefault();
    if (uploading || successfulUploaded) return;
    this.updateState({ highlight: true });
  };

  onDragLeave = () => {
    this.updateState({ highlight: false });
  };

  onDrop = (e) => {
    const { uploading, successfulUploaded } = this.state;
    e.preventDefault();
    if (uploading || successfulUploaded) return;
    const { files } = e.dataTransfer;
    const array = this.fileListToArray(files);
    this.filesAdded(array);
    this.updateState({ highlight: false });
  };

  onFilesAdded = (e) => {
    const { uploading, successfulUploaded } = this.state;
    if (uploading || successfulUploaded) return;
    const { files } = e.target;
    const array = this.fileListToArray(files);
    if (this.fileInputRef?.current) {
      this.fileInputRef.current.value = '';
    }
    this.filesAdded(array);
  };

  handlePaste = (e) => {
    const { files } = e.clipboardData || {};
    if (files?.length > 0) {
      const array = this.fileListToArray(files);
      this.filesAdded(array);
    }
  };

  validateFiles = (fileArray) => {
    const { existingFileNames } = this.state;
    const filesValidated = getValidatedFiles(fileArray, {
      existingFileNames,
      allFiles: this.allFiles
    });
    const { validFiles, filesWithErrors, swalMessage } = filesValidated || {};
    const defaultMessage = 'Sorry, there was an unexpected error adding these files.';
    const swalErrorMessage = swalMessage || defaultMessage;
    if (!isEmpty(filesWithErrors)) {
      this.showFilesErrorSwal(swalErrorMessage);
    }
    return { validFiles, ...(!isEmpty(filesWithErrors) && { swalErrorMessage }) };
  };

  filesAdded = async (fileArray) => {
    const { defaultUploadPath, singleFileUpload } = this.props;
    const { validFiles, swalErrorMessage } = await this.validateFiles(fileArray);
    if (!isEmpty(validFiles)) {
      // If only one file, set these objects back to their defaults before adding the new one
      if (singleFileUpload) {
        this.allFiles = {};
        this.filesToUpload = {};
      }
      validFiles
        .filter((file) => file && file.name)
        .forEach((file) => {
          this.allFiles[file.name] = {
            name: file.name,
            path: `${defaultUploadPath}/`,
            customPathPrefix: defaultUploadPath,
            customPath: '',
            data: file
          };
          this.filesToUpload[file.name] = {
            name: file.name,
            path: `${defaultUploadPath}/`,
            customPathPrefix: defaultUploadPath,
            customPath: '',
            data: file,
            status: 'added',
            percentage: 0
          };
          this.updateState({
            uploadProgress: this.filesToUpload
          });
        });
      this.handleFileChange({ swalErrorMessage });
    }
  };

  handleFileChange = (options = {}) => {
    const { removingMultipleFiles } = options || {};
    removingMultipleFiles !== true && this.prepareFilesForUpload(options);
  };

  formatFiles = () => {
    const filesObject = { ...this.filesToUpload };
    const filesArray = !isEmpty(filesObject)
      ? Object.values(filesObject).filter((file) => file && file.name)
      : [];
    return filesArray;
  };

  handleCallback = (options = {}) => {
    const { callback } = this.props;
    const cbOptions = {
      ...options,
      files: options?.encodedFiles || this.filesToUpload
      // must be sent as-is for encoded value to appear
    };
    callback && callback(cbOptions);
  };

  prepareFilesForUpload = async (options) => {
    const files = this.formatFiles();
    const filesPromiseList = await this.getEncodePromiseList(files);
    const successWithDataList = filesPromiseList?.filter(
      (eachResolvedPromise) =>
        eachResolvedPromise.status === 'fulfilled' && !isEmpty(eachResolvedPromise.value.data)
    );
    const failOrNoEncodeDataList = filesPromiseList?.filter(
      (eachResolvedPromise) =>
        eachResolvedPromise.status === 'rejected' || isEmpty(eachResolvedPromise.value.data)
    );
    !isEmpty(successWithDataList) && this.handleSuccessfulEncode(successWithDataList, options);
    !isEmpty(failOrNoEncodeDataList) && this.handleErrorOrNoEncode(failOrNoEncodeDataList, options);
  };

  getEncodePromiseList = (files) =>
    Promise.allSettled(
      files.map((file) => {
        this.allFiles[file.name] = {
          name: file.name,
          path: file.path,
          data: file
        };
        this.filesToUpload[file.name] = {
          ...file,
          path: file.path,
          status: 'uploading',
          percentage: 0
        };
        this.updateState({
          uploadProgress: this.filesToUpload
        });
        return this.handleBase64(file);
      })
    );

  handleSuccessfulEncode = (resolvedPromiseList, options) => {
    const encodedFiles = resolvedPromiseList.map((aResolvedPromise) => {
      const fileEncoded = {
        ...this.filesToUpload[aResolvedPromise?.value?.fileName],
        encoded: aResolvedPromise.value.data,
        percentage: 15
      };
      this.filesToUpload[aResolvedPromise?.value?.fileName] = fileEncoded;
      this.updateState({
        uploadProgress: this.filesToUpload
      });
      return fileEncoded;
    });
    this.handleCallback({ ...options, encodedFiles });
  };

  handleErrorOrNoEncode = (resolvedPromiseList, options) => {
    const { swalErrorMessage } = options || {};
    const errorLogs = resolvedPromiseList.map((aResolvedPromise) => {
      const actualFileName =
        aResolvedPromise?.value?.fileName || aResolvedPromise?.reason?.fileName;
      const updatedFiles = Object.entries({ ...this.filesToUpload }).reduce(
        (acc, [fileNameCopy, fileCopy]) =>
          fileNameCopy === actualFileName
            ? acc // remove file that errored
            : { ...acc, [fileNameCopy]: fileCopy },
        {}
      );
      this.filesToUpload = { ...updatedFiles };
      this.allFiles = { ...updatedFiles };
      this.updateState({
        uploadProgress: this.filesToUpload
      });
      const { currentTarget } = aResolvedPromise?.reason?.err || {};
      const { error } = currentTarget || {};
      const errorMessage =
        error?.name === 'NotFoundError'
          ? `Failed to add file (Invalid file type - ${actualFileName})`
          : `Failed to add file (${error?.name || 'Unknown Error'})`;
      return errorMessage;
    });
    const errorLogMessage = errorLogs.join('\n');
    const invalidFilesMessage = !isEmpty(swalErrorMessage) ? `\n${swalErrorMessage}` : '';
    const finalSwalMessage = `${errorLogMessage}${invalidFilesMessage}`;
    this.showFilesErrorSwal(finalSwalMessage);
  };

  showFilesErrorSwal = (swalMessage) => {
    swal({
      title: 'Error adding files',
      text: swalMessage,
      className: 'swal-corvia-default',
      icon: 'error',
      closeOnClickOutside: false,
      closeOnEsc: false
    });
  };

  handleBase64 = (file) =>
    new Promise((resolve, reject) => {
      this.toBase64(file, resolve, reject);
    });

  toBase64 = (file, resolve, reject) => {
    if (file?.data?.encoded) {
      resolve({ data: file.data.encoded, fileName: file.name });
      return true;
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer && reader.readAsArrayBuffer(file?.data);
    // dont modify the reader.result, dropzone will not be happy (⁎˃ᆺ˂)
    reader.onload = () => resolve({ data: reader.result, fileName: file.name });
    reader.onerror = (error) => reject({ err: error, fileName: file.name });
    return true;
  };

  render() {
    const { dropAreaText, subText, highlight, uploading, successfulUploaded } = this.state;
    const { id, size, styles } = this.props;
    return (
      <div
        id={id}
        style={{
          ...uploadCSS.wrap,
          ...(size === 'small' && {
            minWidth: '135px',
            height: '100%'
          }),
          ...(styles?.wrapper && { ...styles.wrapper })
        }}
        className="Upload">
        <div
          className="Dropzone"
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          onKeyDown={this.openFileDialog}
          tabIndex="0"
          role="button"
          style={{
            ...dropzoneCSS.wrap,
            ...(highlight && dropzoneCSS.highlight),
            cursor: uploading || successfulUploaded ? 'default' : 'pointer',
            ...(size === 'default' && {
              margin: '2em 1.5em 1em 1.5em'
            }),
            ...(size === 'small' && {
              ...dropzoneCSS.wrapSmall
            }),
            ...(styles?.dropBoxWrapper && { ...styles.dropBoxWrapper })
          }}>
          <div
            style={{
              ...dropzoneCSS.box,
              ...(highlight && dropzoneCSS.highlight),
              ...(size === 'small' && {
                ...dropzoneCSS.boxSmall
              }),
              ...(styles?.dropAreaWrapper && { ...styles.dropAreaWrapper })
            }}>
            {size === 'default' && (
              <div
                style={{
                  ...dropzoneCSS.icon,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '100%',
                  backgroundImage: cloud.src_color
                }}
              />
            )}
            <input
              ref={this.fileInputRef}
              style={dropzoneCSS.fileInput}
              className="FileInput"
              data-testid="input-dropzone"
              type="file"
              multiple
              onChange={this.onFilesAdded}
            />
            <div
              style={{
                textAlign: 'center',
                ...(size === 'small' && {
                  ...dropzoneCSS.textSmall
                }),
                ...(styles?.dropAreaText && { ...styles.dropAreaText })
              }}>
              <div>
                {dropAreaText}
                <div style={{ fontSize: '1.2rem', flex: '100%' }}>{subText}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dropzone.propTypes = {
  id: PropTypes.string,
  customDropAreaText: PropTypes.string,
  customSubText: PropTypes.string,
  callback: PropTypes.func,
  size: PropTypes.oneOf(['default', 'small']),
  styles: PropTypes.shape({
    wrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dropBoxWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dropAreaWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dropAreaText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  defaultUploadPath: PropTypes.string,
  existingFiles: PropTypes.oneOfType([PropTypes.array]),
  fileRemoved: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  singleFileUpload: PropTypes.bool
};

Dropzone.defaultProps = {
  id: '',
  customDropAreaText: null,
  customSubText: null,
  size: 'default',
  callback: null,
  styles: {
    wrapper: {},
    dropBoxWrapper: {},
    dropAreaWrapper: {},
    dropAreaText: {}
  },
  existingFiles: [], // reference existing files so component knows what cannot be added
  defaultUploadPath: '/COMMON',
  fileRemoved: '',
  singleFileUpload: false
};

export default Dropzone;
