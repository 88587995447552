import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import { alertBarCSS, innerAlertBarCSS } from './_styles';

export class AlertBar extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {};
  }

  componentDidMount() {
    this.mounted = true;
    const { options, useInnerAlertBar } = this.props;
    const { barStyle, timeout } = options;
    if (useInnerAlertBar && barStyle === 'success' && timeout) {
      // to handle Sidebar's alert bar that only renders when barStyle is not 'closed'
      this.clearSuccessTimeout();
      this.setSuccessTimeout();
    }
    window.addEventListener('keyup', (event) => this.handleKeyUp(event));
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    const { barStyle, timeout } = options;
    this.clearSuccessTimeout();
    if (
      (barStyle === 'success' && timeout) ||
      (prevProps.options.barStyle === barStyle && barStyle === 'success')
    ) {
      this.setSuccessTimeout();
    }
  }

  componentWillUnmount() {
    this.clearSuccessTimeout();
    window.removeEventListener('keyup', (event) => this.handleKeyUp(event));
    this.mounted = false;
  }

  handleKeyUp = (event) => {
    if (event.keyCode === 27) {
      this.handleClose();
    }
  };

  handleClose = () => {
    const { callback } = this.props;
    this.clearSuccessTimeout();
    const data = {
      alertBarType: 'closed',
      alertBarMessage: '',
      alertBarTimeout: true
    };
    if (callback) {
      callback(data);
    }
  };

  setSuccessTimeout = () => {
    this.successTimeout = setTimeout(this.handleClose, 6000);
  };

  clearSuccessTimeout = () => {
    if (this.successTimeout) {
      clearTimeout(this.successTimeout);
    }
  };

  render() {
    const { className, options, wrapperStyle, useInnerAlertBar } = this.props;
    const { message, barStyle, customWarnStyle, type } = options;
    const typeToIdMap = {
      default: 'alertBar',
      modal: 'alertBarModal'
    };
    const compId = typeToIdMap[type] || typeToIdMap.default;
    return useInnerAlertBar ? ( // to show the alert bar inside a container (instead of whole page)
      <div
        style={{ width: '100%' }}
        className={className}
        role="alertdialog"
        aria-labelledby={compId}>
        {barStyle === 'warning' && (
          <div
            style={{
              ...alertBarCSS.warningStyleInnerAlertBar,
              ...(type === 'modal' && { width: '100%' }),
              ...customWarnStyle
            }}
          />
        )}
        <div
          id={compId}
          className="innerAlertBar"
          style={{
            ...alertBarCSS[barStyle],
            width: 'auto',
            left: '10px',
            right: '10px',
            top: '10px',
            ...(type === 'card' && {
              // Viewing alert bar in a card
              fontSize: '1.2rem',
              lineHeight: '1.5',
              ...(barStyle !== 'closed' && { padding: '8px 35px' })
            }),
            ...wrapperStyle,
            zIndex: type === 'card' ? '9' : '12',
            backgroundPosition: 'left 10px center'
          }}>
          {barStyle === 'warning' && (
            <Icon
              style={alertBarCSS.icon}
              icon={icons.info.src_white}
              color="var(--color-white)"
              $useMask
            />
          )}
          {barStyle === 'notice' && (
            <Icon
              style={alertBarCSS.icon}
              icon={icons.hazard.src_white}
              color="var(--color-white)"
              $useMask
            />
          )}
          {barStyle === 'success' && (
            <Icon
              style={alertBarCSS.icon}
              icon={icons.checkmark.src_white}
              color="var(--color-white)"
              $useMask
            />
          )}
          {message}
          <Icon
            icon={icons.close.src_white}
            color="var(--color-white)"
            $useMask
            className="close"
            aria-label="Close Alert Bar"
            role="button"
            tabIndex="0"
            onKeyDown={this.handleClose}
            style={{
              ...innerAlertBarCSS.closeButton,
              ...(type === 'card' && { top: '32%', transform: 'translate(0px, -32%)' })
            }}
            onMouseDown={this.handleClose}
          />
        </div>
      </div>
    ) : (
      // full-page alert bar
      <div role="alertdialog" aria-labelledby={compId} className={className}>
        {barStyle === 'warning' && <div style={alertBarCSS.warningStyle} />}
        <div
          id={compId}
          style={{
            ...wrapperStyle,
            ...alertBarCSS[barStyle],
            zIndex: '3',
            backgroundPosition: 'left 10px center'
          }}>
          {barStyle === 'warning' && (
            <Icon
              style={alertBarCSS.icon}
              icon={icons.info.src_white}
              color="var(--color-white)"
              $useMask
            />
          )}
          {barStyle === 'notice' && (
            <Icon
              style={alertBarCSS.icon}
              icon={icons.hazard.src_white}
              color="var(--color-white)"
              $useMask
            />
          )}
          {barStyle === 'success' && (
            <Icon
              style={alertBarCSS.icon}
              icon={icons.checkmark.src_white}
              color="var(--color-white)"
              $useMask
            />
          )}
          {message}
          <Icon
            icon={icons.close.src_white}
            color="var(--color-white)"
            $useMask
            className="close"
            aria-label="Close Alert Bar"
            role="button"
            tabIndex="0"
            onKeyDown={this.handleClose}
            style={alertBarCSS.closeButton}
            onMouseDown={this.handleClose}
          />
        </div>
      </div>
    );
  }
}

AlertBar.propTypes = {
  className: PropTypes.string,
  useInnerAlertBar: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.shape({
    message: PropTypes.string,
    barStyle: PropTypes.string,
    timeout: PropTypes.bool,
    type: PropTypes.string, // ['card', 'modal']
    customWarnStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  callback: PropTypes.func
};

AlertBar.defaultProps = {
  className: null,
  useInnerAlertBar: false,
  wrapperStyle: {},
  options: {
    message: null,
    barStyle: 'closed',
    timeout: true,
    type: '',
    customWarnStyle: {}
  },
  callback: () => {}
};

export default AlertBar;
