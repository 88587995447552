import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { mockRelationshipGetFormatted } from './relationship/root';
import { mockEmployees } from './employee/mockEmployees';

// GET NAMES FROM GUIDS
export const findRelationshipName = (id) => {
  const match = mockRelationshipGetFormatted.find((r) => r.value === id);
  return match?.title || 'Unknown Relationship Name';
};

export const findEmployeeName = (id) => {
  const match = Object.values(mockEmployees).find((e) => e.employeeId === id);
  return `${match?.firstName || 'Unknown First'} ${match.lastName || 'Unknown Last'}`;
};

export const getMockEmployee = (options) => {
  // Creates standard mock employee object
  const { includeManager } = options || {};
  const mockEmployeesList = Object.values(mockEmployees);
  const mockEmployee = getMockValue({ type: 'list', list: mockEmployeesList });
  return {
    employeeId: mockEmployee.employeeId,
    firstName: mockEmployee.firstName,
    lastName: mockEmployee.lastName,
    email: mockEmployee.email,
    ...(includeManager && { manager: mockEmployee.manager || getMockValue({ type: 'guid' }) })
  };
};

// MISC HELPERS

export const mockGetActiveId = (key) => {
  if (key === 'activeRelationshipIdList') {
    return [
      {
        guid: 'guid-1',
        dba: 'dba-1'
      }
    ];
  }
  if (key === 'activePartnerId') {
    return {
      guid: 'guid-1',
      dba: 'dba-1'
    };
  }
  return {
    guid: 'guid-1',
    dba: 'dba-1'
  };
};
