import { endpoint, isEmpty } from '../../_helpers';
import { createMockFileList } from '../sharedBoarding/mockBoardingApplicationFiles';

export const mockFileV3CacheUploadLinkGet = (_body, _params, _options) => {
  const mockResponse = {
    url: endpoint.file.v3DummyUploadUrl,
    s3Key: '/mockS3Key'
  };
  return mockResponse;
};

export const mockFileV3Get = (body, params, options) => {
  const { guid, mockDataMap = {} } = options || {};
  const staticMockFiles = !isEmpty(mockDataMap) ? mockDataMap[guid] : null;
  return {
    [guid]: {
      files: createMockFileList({
        ...options,
        staticFiles: staticMockFiles
      })
    }
  };
};
