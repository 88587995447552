// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { formatNumber, isEmpty } from './_helpers';

export class SparklineChart extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {};
    this.chartInset = 80;
    this.cssChartStyles = this.getChartStyles();
    this.constants = this.getConstants();
    this.lineChartStyles = {
      listStyle: 'none',
      margin: 0,
      padding: 0
    };
    this.dataPointStyles = {
      backgroundColor: 'var(--color-primary)',
      border: '2px solid var(--color-primary)',
      borderRadius: '50%',
      bottom: 'calc(var(--y) - 1.5px)',
      height: '6px',
      left: 'calc(var(--x) - 2.5px)',
      position: 'absolute',
      width: '6px',
      zIndex: 1
    };
    this.lineSegmentStyles = {
      backgroundColor: 'var(--color-primary)',
      bottom: 'var(--y)',
      height: '2px',
      left: 'var(--x)',
      position: 'absolute',
      transform: 'rotate(calc(var(--angle) * 1deg))',
      transformOrigin: 'left bottom',
      width: 'calc(var(--hypotenuse) * 1px)'
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentDidUpdate(prevProps) {
    const { data, height, width } = this.props;
    if (
      prevProps.height !== height ||
      prevProps.width !== width ||
      prevProps.data?.length !== data?.length
    ) {
      this.constants = this.getConstants();
      this.cssChartStyles = this.getChartStyles();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getConstants = () => {
    const { data, height, width } = this.props;

    return {
      width,
      height,
      chartRun: (width - this.chartInset) / (data?.length || 7) // chartHeight / data.length
    };
  };

  getChartStyles = () => {
    const { height, width } = this.props;

    return {
      border: 'none',
      display: 'inline-block',
      height: `calc(${height} * 1px)`,
      margin: 0,
      padding: 0,
      position: 'relative',
      width: `calc(${width} * 1px)`,
      fontSize: '0.9em',
      color: 'var(--color-secondary)'
    };
  };

  findY = (idx) => {
    const { data = [], valueProp = 'value' } = this.props;
    const minMax = d3.extent(data.map((row) => row?.[valueProp]));
    const chartScaleY = minMax[1] - minMax[0];
    // const chartRun = this.constants.width / data.length;

    const y = (this.constants.height / chartScaleY) * (data[idx][valueProp] - minMax[0]);
    if (Number.isNaN(y)) {
      return 0;
    }
    return y;
  };

  findChartRise = (idx) => {
    const { data = [] } = this.props;
    if (idx > data?.length - 1) {
      return 0;
    }
    const chartRiseCurrentPoint = this.findY(idx);
    const chartRiseNextPoint = this.findY(idx + 1);

    return chartRiseCurrentPoint - chartRiseNextPoint;
  };

  findHypontenuse = (idx) => {
    const chartRise = this.findChartRise(idx);

    const hyp =
      chartRise === 0
        ? this.constants.chartRun
        : Math.sqrt(this.constants.chartRun * this.constants.chartRun + chartRise * chartRise);
    return hyp;
  };

  findAngle = (idx) => {
    const chartRise = this.findChartRise(idx);

    // (180 / Math.PI) to convert from radians to degrees
    return chartRise === 0
      ? 0
      : (180.0 / Math.PI) *
          Math.asin(
            chartRise /
              Math.sqrt(this.constants.chartRun * this.constants.chartRun + chartRise * chartRise)
          );
  };

  formatNumber = (value) => {
    const { useCurrency } = this.props;
    const valueToFormat =
      useCurrency && !isEmpty(value) && value !== 0 && value < 1 ? Number(value.toFixed(2)) : value;
    const formattedValue = formatNumber(valueToFormat, { currency: useCurrency, abbreviate: true });
    return formattedValue;
  };

  findMargin = (value) => (`${Math.abs(value)}`.length >= 6 ? '-10px' : '0px');

  render() {
    const { data, errorDetails, valueProp } = this.props;
    const dataExists = !isEmpty(data);
    return dataExists ? (
      <>
        <figure className="css-chart" style={this.cssChartStyles}>
          <div
            data-testid="startMonthValue"
            style={{
              position: 'absolute',
              bottom: this.constants.height / 2 - 16,
              left: this.findMargin(data?.[0]?.[valueProp])
            }}>
            {this.formatNumber(data?.[0]?.[valueProp])}
          </div>
          <ul className="line-chart" style={this.lineChartStyles}>
            {data.map((row, index) => (
              <li
                key={row?.date}
                style={{
                  '--x': `calc(${this.constants.chartRun} * ${index}px + 45px)`,
                  '--y': `${this.findY(index)}px`
                }}>
                <div className="data-point" data-value={row?.value} style={this.dataPointStyles} />
                <div
                  className="line-segment"
                  style={{
                    ...this.lineSegmentStyles,
                    '--hypotenuse':
                      index < data?.length - 1 ? `${this.findHypontenuse(index)}` : '0',
                    '--angle': index < data?.length - 1 ? `${this.findAngle(index)}` : '0'
                  }}
                />
              </li>
            ))}
          </ul>
          <div
            data-testid="endMonthValue"
            style={{
              position: 'absolute',
              bottom: this.constants.height / 2 - 16,
              right: this.findMargin(data?.[data?.length - 1]?.[valueProp])
            }}>
            {this.formatNumber(data?.[data?.length - 1]?.[valueProp])}
          </div>
        </figure>
      </>
    ) : (
      <div
        style={{
          alignSelf: 'center',
          justifySelf: 'center',
          ...(errorDetails instanceof Error && { color: 'var(--color-warning)' })
        }}>
        {errorDetails instanceof Error ? 'Error loading data' : 'No data'}
      </div>
    );
  }
}

SparklineChart.propTypes = {
  errorDetails: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.array]),
  height: PropTypes.number,
  useCurrency: PropTypes.bool,
  valueProp: PropTypes.string,
  width: PropTypes.number
};
SparklineChart.defaultProps = {
  errorDetails: null,
  data: [],
  height: 25,
  useCurrency: false,
  valueProp: 'value',
  width: 200
};

export default SparklineChart;
