import { ignoreCase, isEmpty, snakeToTitle, sortData } from '@f1/shared/src/_helpers';
import { paymentGatewayList } from '../components/forms/formHelpers';

export const relationshipTemplate = {
  frontendGet: (schema, version) => {
    if (version === '1.0') {
      const { backendData } = schema;
      const { relationships, gatewayInfoList } = backendData || {};
      const formattedRelationships = !isEmpty(relationships)
        ? relationships.map((r) => ({
            value: r.relationshipId,
            title: r.relationshipName,
            relationshipCode: r.relationshipCode,
            bankName: r.bankName,
            processorName: r.processorName,
            riskProfile: r.riskProfile,
            childPartnerId: r.childPartnerId,
            inactive: r.inactive
          }))
        : [];
      const formattedGatewayInfoList = !isEmpty(gatewayInfoList)
        ? gatewayInfoList.map((g) => {
            const match =
              paymentGatewayList.find(
                (item) => ignoreCase(g.gatewayName) === ignoreCase(item.value)
              ) || {};
            return {
              gatewayTitle: match.title || snakeToTitle(g.gatewayName),
              gatewayValue: (match.value || g.gatewayName || '').toUpperCase(),
              gatewayAffiliateIdList: !isEmpty(g.gatewayAffiliateIdList)
                ? sortData(
                    g.gatewayAffiliateIdList.map((affiliate) => ({
                      title: snakeToTitle(affiliate || ''),
                      value: ignoreCase(affiliate || ''),
                      gatewayAffiliateId: ignoreCase(affiliate || '')
                    })),
                    'title'
                  )
                : []
            };
          })
        : [];
      return {
        relationshipList: sortData(formattedRelationships, 'title'),
        gatewayInfoList: formattedGatewayInfoList
      };
    }
    return schema;
  }
};

export default relationshipTemplate;
