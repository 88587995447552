// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import bigDecimal from 'js-big-decimal';
import { Input } from './Input';
import FormSectionHeader from './FormSectionHeader';
import FormCustomSection from './FormCustomSection';
import { isEmpty } from './_helpers';
import { toFrontendValue } from './_templateHelpers';
import validations from './_validations';
import { getValueChangedData } from './_formHelpers';

class FormCustomSectionPercentages extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    const { id, fields } = props;
    this.sectionId = id;
    this.compKeys = !isEmpty(fields) ? Object.keys(fields).filter((k) => k !== 'id') : [];
    this.state = {
      shouldError: false,
      fullSectionValid: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { allowEmpty } = this.props;
    if (prevProps?.allowEmpty !== allowEmpty) {
      this.handleValidate();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  initialize = () => {
    const { data, fields } = this.props;
    const frontendFormValues = !isEmpty(data)
      ? Object.entries(data).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]:
              !isEmpty(value) && fields?.[key] ? toFrontendValue(value, fields[key]) : undefined
          }),
          {}
        )
      : {};
    this.updateState(
      {
        ...frontendFormValues,
        ...(!isEmpty(data) && { valuesForBackend: data })
      },
      this.handleValidate
    );
  };

  getTotals = () => {
    const total = !isEmpty(this.compKeys)
      ? this.compKeys.reduce((acc, key) => {
          const { [key]: compValue } = this.state;
          return Number(compValue) ? bigDecimal.add(`${acc}`, `${compValue || 0}`) : acc;
        }, 0)
      : 0;
    return parseFloat(total);
  };

  handleInputChange = (id, value, valid) => {
    const { fields } = this.props;
    const valueChangedData = getValueChangedData(value, id, fields[id]);
    const { backendValue } = valueChangedData || {};
    this.updateState(
      (prevState) => ({
        [id]: value,
        formInProgress: true,
        fullSectionValid: (valid && prevState.fullSectionValid) || false,
        valueChangedData,
        valuesForBackend: {
          ...prevState.valuesForBackend,
          [id]: backendValue
        }
      }),
      this.handleValidate
    );
  };

  handleValidate = () => {
    const { allowEmpty, validateFields } = this.props;
    const { valuesForBackend } = this.state;
    const hasAllValues = !isEmpty(this.compKeys)
      ? this.compKeys.every((compKey) => {
          const { [compKey]: currentKey } = this.state;
          return !isEmpty(currentKey);
        })
      : false;
    const totals = this.getTotals();
    const totalTooHigh = totals > 100;
    const totalNot100 = totals !== 100;
    const allCompletedAndNot100 = hasAllValues && totalNot100;
    const invalidTotal = totalTooHigh || allCompletedAndNot100;
    const shouldError = invalidTotal || (validateFields && totalNot100);
    const validForSave = validateFields
      ? totals === 100
      : allowEmpty && !totalTooHigh && !allCompletedAndNot100;
    let newState = {};
    const newValuesForBackend = {};
    if (!hasAllValues && totals === 100) {
      // Not all values filled but they already equal 100%
      newState = this.compKeys.reduce((acc, compKey) => {
        const { [compKey]: compFrontendValue } = this.state;
        const { [compKey]: compBackendValue } = valuesForBackend || {};
        if (isEmpty(compFrontendValue)) {
          // Set remaining empty fields to 0
          newValuesForBackend[compKey] = 0;
          return { ...acc, [compKey]: '0' }; // FE values are strings
        }
        if (!isEmpty(compBackendValue)) {
          newValuesForBackend[compKey] = compBackendValue;
        }
        return { ...acc, [compKey]: compFrontendValue };
      }, {});
    }
    this.updateState(
      (prevState) => ({
        ...newState,
        ...(!isEmpty(newValuesForBackend) && {
          valuesForBackend: {
            ...prevState.valuesForBackend,
            ...newValuesForBackend
          }
        }),
        shouldError,
        totalPercents: totals,
        fullSectionValid: validForSave || totals === 100
      }),
      this.handleCallback
    );
  };

  customValidateTotalPercent = (value) => {
    const { shouldError } = this.state;
    return shouldError ? value === '100' : validations.percent.test(value);
  };

  handleCallback = () => {
    const { callback } = this.props;
    const { valueChangedData, valuesForBackend } = this.state;
    const options = { valueChangedData, valuesForBackend };
    callback && callback(this.state, this.sectionId, options);
  };

  render() {
    const {
      title,
      isSubSection,
      tooltip,
      validateFields,
      fields,
      defaultBuildOptions,
      wrapperStyle,
      inputWrapperStyle,
      componentLabelInside
    } = this.props;
    const { shouldError, totalPercents } = this.state;
    const inputProps = {
      ...(componentLabelInside && { boxStyle: 'inside' }),
      validationActivated: validateFields,
      wrapperStyle: {
        flex: '1',
        minWidth: '150px',
        margin: '0 -1px',
        ...inputWrapperStyle
      },
      callback: this.handleInputChange,
      ...(!isEmpty(defaultBuildOptions) && { disabled: defaultBuildOptions.disableFormFields })
    };
    return (
      <FormCustomSection
        ariaLabel={`${title} section`}
        id={this.sectionId}
        isSubSection={isSubSection}
        wrapperStyle={{ margin: '0 -1px 0', ...wrapperStyle }}>
        <FormSectionHeader title={title} isSubSection={isSubSection} tooltip={tooltip} />
        {this.compKeys.map((compKey) => {
          const { [compKey]: stateValue } = this.state;
          return (
            <Input
              key={compKey}
              {...inputProps}
              {...(!isEmpty(stateValue) && { value: stateValue })}
              {...fields[compKey]}
            />
          );
        })}
        <Input
          {...inputProps}
          id="totalPercents"
          label="Total"
          type="percent"
          disabled
          customValidation={this.customValidateTotalPercent}
          setError={{ message: shouldError ? 'Total percentages must equal 100%' : '' }}
          value={totalPercents}
        />
      </FormCustomSection>
    );
  }
}

FormCustomSectionPercentages.propTypes = {
  allowEmpty: PropTypes.bool,
  validateFields: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  componentLabelInside: PropTypes.bool,
  fields: PropTypes.oneOfType([PropTypes.object]),
  isSubSection: PropTypes.bool,
  tooltip: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  defaultBuildOptions: PropTypes.oneOfType([PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  inputWrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  callback: PropTypes.func
};

FormCustomSectionPercentages.defaultProps = {
  validateFields: false,
  allowEmpty: false,
  title: '',
  id: '',
  componentLabelInside: false,
  fields: {},
  isSubSection: true,
  tooltip: 'Total percentages must equal 100%',
  data: {},
  defaultBuildOptions: {},
  wrapperStyle: {},
  inputWrapperStyle: {},
  callback: () => {}
};

export default FormCustomSectionPercentages;
