import React from 'react';
import PropTypes from 'prop-types';
import { formStylesCSS } from './_formStyles';
import { input } from './_styles';

import { ToolTip } from './ToolTip';

const FormSectionHeader = (props) => {
  const {
    required = false,
    title = '',
    children = null,
    tooltip = null,
    wrapperStyle = {},
    innerWrapperStyle = {},
    isNestedSubSection = false,
    isSubSection = false,
    isSubSubSection = false
  } = props || {};
  return (
    <div
      className="sectionHeader"
      style={{
        flex: '100%',
        ...formStylesCSS.formSectionHeader,
        ...(isSubSection && {
          ...formStylesCSS.formSubSection
        }),
        ...(isSubSubSection && {
          ...formStylesCSS.formSubSubSection
        }),
        ...(isNestedSubSection && {
          ...formStylesCSS.nestedSubSection
        }),
        ...wrapperStyle
      }}>
      <div
        style={{
          ...formStylesCSS.formSectionHeaderText,
          ...(isSubSection && {
            ...formStylesCSS.formSubSectionText
          }),
          ...(isSubSubSection && {
            ...formStylesCSS.formSubSubSectionText
          }),
          ...(isNestedSubSection && {
            color: 'var(--color-dark-grey)'
          }),
          ...(children && { display: 'flex' }),
          ...innerWrapperStyle
        }}>
        {children}
        {required && <span style={input.label_required}>* </span>}
        {title}
        {tooltip && (
          <ToolTip
            infoTip
            {...((isSubSection || isSubSubSection) && {
              infoTipDisplay: { margin: '2px 5px 0 5px' }
            })}>
            {tooltip}
          </ToolTip>
        )}
      </div>
    </div>
  );
};

FormSectionHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  innerWrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  isNestedSubSection: PropTypes.bool,
  isSubSection: PropTypes.bool,
  isSubSubSection: PropTypes.bool
};

export default FormSectionHeader;
