import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { bypassCaptcha, endpoint, getType, isEmpty } from './_helpers';
import { shield } from '../images/_svgs';
import { apiCall } from './_api';
import { Spinner } from './Spinner';
import { sharedStubDataMap } from './data/stubDataMap';

export class Captcha extends React.Component {
  constructor(props) {
    super(props);
    this.captcha = null;
    this.setCaptchaRef = (r) => (this.captcha = r);
    this.mounted = false;
    this.state = {
      captchaValid: false,
      siteKey: '',
      spinnerLoading: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getSiteKey();
  }

  componentDidUpdate(prevProps, prevState) {
    const { captchaValid } = this.state;
    const { codeValid } = this.props;
    if (
      (prevState.captchaValid !== captchaValid && !captchaValid) ||
      (prevProps.codeValid !== codeValid && !codeValid)
    ) {
      this.handleCaptcha();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  };

  getSiteKey = async () => {
    const { handleApiError } = this.props;
    if (this.mounted) {
      this.updateState({ spinnerLoading: true });
      const apiUrl = `${endpoint.parameter.captchaSiteKey}`;
      const options = {
        url: apiUrl,
        method: 'get',
        tokenRequired: false,
        stubData: sharedStubDataMap[apiUrl]
      };
      const utils = {
        handleApiError
      };
      const apiRes = await apiCall(options, utils);
      if (apiRes?.data) {
        this.updateState({
          siteKey: apiRes.data.captchaSiteKey
        });
        // bypass captcha for testCafe & jest
        this.allowCaptchaBypass();
        setTimeout(() => {
          this.setId();
        }, 500);
      } else {
        this.updateState({ spinnerLoading: false });
        handleApiError(apiRes?.errorDetails);
      }
    }
  };

  allowCaptchaBypass = () => {
    // bypass captcha for dev
    const { checkCaptcha } = this.props;
    let allowBypass = false;
    if (bypassCaptcha()) {
      allowBypass = true;
      this.updateState({ captchaValid: true });
      checkCaptcha(true, 'captchaCodeForTest');
    }
    return allowBypass;
  };

  // TODO: BIRB-8338 - remove ignore
  /* istanbul ignore next */
  setId = () => {
    setTimeout(async () => {
      const captchaIframe = await document.getElementsByTagName('iframe')[0];
      if (captchaIframe) {
        captchaIframe.id = 'captchaIframe';
      }
      this.updateState({ spinnerLoading: false });
    }, 1000);
  };

  handleCaptcha = (code) => {
    const { checkCaptcha } = this.props;
    const codeValid = !isEmpty(code) && getType(code) === 'string';
    !codeValid && this.captcha !== null && this.captcha.reset();
    const allowBypass = this.allowCaptchaBypass();
    const valid = codeValid || allowBypass;
    this.updateState({ captchaValid: valid });
    const captchaCode = (codeValid && code) || (allowBypass && 'captchaCodeForTest') || '';
    checkCaptcha(valid, captchaCode);
  };

  render() {
    const { siteKey, spinnerLoading } = this.state;
    return (
      <div
        id="captcha"
        role="article"
        aria-label="Captcha"
        style={{
          ...shield(),
          padding: '10px 10px 10px 50px',
          transform: 'scale(100%)',
          WebkitTransform: 'scale(100%)',
          transformOrigin: '0 0',
          WebkitTransformOrigin: '0 0',
          backgroundColor: '#d3d3d3',
          borderRadius: 'var(--radius-main)',
          minHeight: '40px',
          position: 'relative',
          overflow: 'hidden'
        }}>
        <Spinner loading={spinnerLoading} />
        {siteKey && (
          <ReCAPTCHA
            data-testid="reCAPTCHA"
            ref={this.setCaptchaRef}
            sitekey={siteKey}
            theme="light"
            size="normal"
            onChange={this.handleCaptcha}
            onExpired={this.handleCaptcha}
            onErrored={this.handleCaptcha}
          />
        )}
      </div>
    );
  }
}

Captcha.propTypes = {
  handleApiError: PropTypes.func,
  checkCaptcha: PropTypes.func,
  codeValid: PropTypes.bool
};

Captcha.defaultProps = {
  handleApiError: () => {},
  checkCaptcha: () => {},
  codeValid: false
};

export default Captcha;
