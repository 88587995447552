import {
  generateRandomAddress,
  generateRandomSentence,
  getMockValue
} from '@f1/shared/src/data/_mockDataHelpers';
import { isEmpty } from '@f1/shared/src/_helpers';
import { mockEmployees } from '../../../../employee/mockEmployees';

export const createMockMatchResults = (merchant, options) => ({
  employeeWhoQueried: {
    employeeId: mockEmployees.employee09.employeeId,
    firstName: mockEmployees.employee09.firstName,
    lastName: mockEmployees.employee09.lastName,
    email: mockEmployees.employee09.email
  },
  timestampQueried: getMockValue({
    type: 'isoDate',
    ...(options && options.resultIndex === 0 && { min: 2022, max: 2022 })
  }),
  matchResponse: {
    transactionReferenceNumber: getMockValue({ min: 10000, max: 9999999, stringify: true }),
    possibleMerchantMatches: merchant
      ? [merchant]
      : generateRandomMatches(merchant, isEmpty(merchant) ? 2 : null),
    possibleInquiryMatches: generateRandomMatches(merchant, isEmpty(merchant) ? 2 : null)
  }
});

export const mockMatchPostRes = (body, _params, _options) => {
  const merchant = body?.merchant;
  return {
    matchResults: createMockMatchResults(merchant)
  };
};

const generateRandomMatches = (merchant = {}, length) => {
  const numberOfMatches = length || Math.random() * 10;
  const arrayOfMatches = [];
  for (let i = 0; i < numberOfMatches; i += 1) {
    arrayOfMatches.push(generatePossibleMatch(merchant));
  }
  return arrayOfMatches;
};

export const generatePossibleMatch = (merchant) => ({
  name: getMockResult('name', merchant), // required
  dba: getMockResult('dba', merchant),
  address: {
    // required
    addressLine1: getMockResult(
      'addressLine1',
      merchant?.address,
      generateRandomAddress().fullAddress
    ), // required
    addressLine2: getMockResult(
      'addressLine2',
      merchant?.address,
      generateRandomAddress().fullAddress
    ),
    city: getMockResult('city', merchant?.address, generateRandomAddress().cityName), // required
    stateCode: getMockResult('stateCode', merchant?.address, generateRandomAddress().stateCode),
    province: getMockResult('province', merchant?.province),
    postalCode: getMockResult('postalCode', merchant?.address, generateRandomAddress().zipCode), // required
    country: merchant?.address?.country || 'OZ' // required
  },
  phoneNumber: getMockResult('phoneNumber', merchant, getMockValue({ type: 'phone' })),
  altPhoneNumber: getMockResult('altPhoneNumber', merchant, getMockValue({ type: 'phone' })),
  nationalTaxId: merchant?.nationalTaxId || '123456789',
  countrySubdivisionTaxId: merchant?.countrySubdivisionTaxId || '812',
  serviceProviderLegal: getMockResult('serviceProviderLegal', merchant?.serviceProviderLegal),
  serviceProviderDba: getMockResult('serviceProviderDba', merchant?.serviceProviderDba),
  url: merchant?.url || ['https://breakingTheLaw.com'],
  addedOnDate: getMockResult('addedOnDate', merchant, getMockValue({ type: 'date' })),
  terminationReasonCode: getMockResult(
    'terminationReasonCode',
    merchant,
    getMockValue({
      type: 'list',
      list: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14']
    })
  ),
  addedByAcquirerId: getMockResult(
    'addedByAcquirerId',
    merchant,
    getMockValue({ min: 1000, max: 9999, stringify: true })
  ),
  urlGroup: merchant?.urlGroup || [],
  searchCriteria: merchant?.searchCriteria || {
    searchAll: 'N',
    region: null,
    country: null,
    minPossibleMatchCount: null
  },
  principal: merchant?.principal || [
    {
      // required
      firstName: getMockValue({ type: 'string' }),
      middleInitial: null,
      lastName: getMockValue({ type: 'string' }),
      phoneNumber: getMockValue({ type: 'bePhone' }),
      altPhoneNumber: getMockValue({ type: 'bePhone' }),
      driversLicense: null,
      address: {
        addressLine1: getMockValue({ type: 'string' }),
        addressLine2: getMockValue({ type: 'string' }),
        city: getMockValue({ type: 'string' }),
        stateCode: 'AL',
        province: null,
        postalCode: '22222',
        country: 'US'
      }
    }
  ]
});

// ignore the following function for coverage because entering one of the paths is random
/* istanbul ignore next */
const getMockResult = (field, data, mockResult) => {
  // ~50% chance of using the query data--otherwise generate a random string
  const chance = Math.random();
  if (data && !isEmpty(data[field]) && chance < 0.5) {
    return data[field];
  }
  return mockResult || generateRandomSentence();
};
