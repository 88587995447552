// TODO: BIRB-8338
/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Legend } from '../../css/_styledComponents';
import { Button } from '../Button';
import { isEmpty, camelToTitle, sortData } from '../_helpers';
import { icons } from '../../images/_icons';

class D3Legend extends React.Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      allItems: [],
      currentItems: [],
      toggleText: 'View All'
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.parseLegendData();
  }

  componentDidUpdate(prevProps) {
    const { payload } = this.props;
    if (!isEmpty(payload) && JSON.stringify(payload) !== JSON.stringify(prevProps.payload)) {
      this.parseLegendData();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  };

  parseLegendData = () => {
    const { payload } = this.props;
    const { data = {}, header = {}, colors = {} } = payload;
    const { lines = [], label = '' } = header || {};
    const legendData = [];
    const usedKeys = [];
    !isEmpty(data) &&
      data.forEach((item) => {
        Object.keys(item).map((key) => {
          const used = usedKeys.indexOf(key) !== -1;
          const isLabel = key === label;
          const isLine = lines.indexOf(key) !== -1;
          if (!used && !isLabel && !isLine) {
            usedKeys.push(key);
            legendData.push({
              value: key,
              type: isLine ? 'line' : 'rect',
              color: colors[key]
            });
          }
          return true;
        });
      });
    !isEmpty(lines) &&
      lines.forEach((line) => {
        const used = usedKeys.indexOf(line) !== -1;
        if (!used) {
          usedKeys.push(line);
          legendData.push({
            value: line,
            type: 'line',
            color: colors[line] || 'var(--color-d3-chart-line)'
          });
        }
      });
    const lineItems = !isEmpty(legendData) ? legendData.filter((item) => item.type === 'line') : [];
    const nonLineItems = !isEmpty(legendData)
      ? sortData(
          legendData.filter((item) => item.type !== 'line'),
          'value'
        )
      : [];
    const allItems = [...lineItems, ...nonLineItems];
    const currentItems = this.filterPayload(allItems);
    this.updateState({
      allItems,
      currentItems: isEmpty(currentItems) ? allItems || [] : currentItems || []
    });
  };

  filterPayload = (payload) => payload.slice(0, 9);

  toggleViewAll = () => {
    this.updateState((prevState) => {
      const { allItems, currentItems } = prevState || {};
      const newCurrentItems =
        allItems.length === currentItems.length ? this.filterPayload(allItems) : allItems;
      return {
        toggleText: allItems.length === currentItems.length ? 'View All' : 'View Less',
        currentItems: isEmpty(newCurrentItems) ? allItems || [] : newCurrentItems || []
      };
    });
  };

  render() {
    const { allItems, currentItems, toggleText } = this.state;
    const { preText, legendPosition, postText } = this.props;
    return (
      <Legend
        className="d3Legend"
        data-testid="d3-legend"
        count={allItems.length}
        position={legendPosition}
        $preText={preText}>
        {!isEmpty(allItems) && (
          <>
            {preText && <div className="preText">{preText}</div>}
            <div className="legendWrap">
              {currentItems.map((entry, index) => (
                <div className="cell" key={`${index.toString()}`}>
                  {entry.type === 'line' ? (
                    <Icon
                      icon={icons.graphLine().src_color}
                      color={entry.color}
                      $useMask
                      style={{ height: '1em', width: '1.25em', marginRight: '3px' }}
                    />
                  ) : (
                    <div
                      className="legendIcon"
                      style={{
                        background: entry.color
                      }}
                    />
                  )}
                  <div className={entry.type === 'line' ? 'legendLineTitle' : 'legendItemTitle'}>
                    {`${entry.value}`.includes(' ') ? entry.value : camelToTitle(entry.value)}
                  </div>
                </div>
              ))}
              {postText && <div className="postText">{postText}</div>}
            </div>
          </>
        )}
        {allItems.length > 9 && (
          <Button id="toggleButton" type="text" size="sm" onClick={this.toggleViewAll}>
            {toggleText}
          </Button>
        )}
      </Legend>
    );
  }
}

D3Legend.propTypes = {
  legendPosition: PropTypes.oneOf(['bottom', 'right']),
  payload: PropTypes.oneOfType([PropTypes.object]),
  preText: PropTypes.string,
  postText: PropTypes.node
};
D3Legend.defaultProps = {
  legendPosition: 'bottom',
  payload: [],
  preText: null,
  postText: null
};

export default D3Legend;
