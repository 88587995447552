import React from 'react';
import PropTypes from 'prop-types';
import { NoData } from '../index';
import { formatNumber, isEmpty } from './_helpers';
import { StyledMiniBar } from '../css/_styledComponents';

export const MiniBar = ({
  callback = null,
  className = null,
  customNoDataMessage = null,
  data = null,
  fill = null,
  greyscale = false,
  id = null,
  style = {}
}) => {
  const classNames = `mini-report-bar ${!isEmpty(className) ? ' '.concat(className) : ''}`;
  return (
    <StyledMiniBar
      id={id}
      fill={fill}
      className={classNames}
      style={style}
      $greyscale={greyscale}
      $hasCallback={!isEmpty(callback)}>
      {!isEmpty(data) ? (
        <>
          {data.map((item) => (
            <div key={item.id || item.title} id={item.id} className="mini-report">
              <div className="title">{item.title}</div>
              <div className="value">
                {isEmpty(item.type) ? (
                  item.value
                ) : (
                  <>
                    {formatNumber(item.value, { ...(item.type === 'price' && { currency: true }) })}
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <NoData customMessage={customNoDataMessage} />
      )}
    </StyledMiniBar>
  );
};

MiniBar.propTypes = {
  callback: PropTypes.func,
  className: PropTypes.string,
  customNoDataMessage: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array]),
  fill: PropTypes.string,
  greyscale: PropTypes.bool,
  id: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object])
};

export default MiniBar;
